/*eslint-disable*/
import { Column, ColumnStore } from "@bryntum/scheduler";

class ColorColumn extends Column {
  static get type() {
    return "color";
  }
  static get defaults() {
    return {
      align: "center"
    };
  }
  onCellClick({ record, target }) {
    if (target.value && !target.onchange) {
      target.onchange = () => {
        record.set("eventColor", target.value);
        document.dispatchEvent(
          new CustomEvent("colorChanged", { detail: record })
        );
      };
    }
  }
  renderer({ column, record }) {
    return {
      class: "b-color-column",
      children: [
        {
          tag: "input",
          type: "color",
          value: record.eventColor || "#dc3545"
        }
      ]
    };
  }
}
ColumnStore.registerColumnType(ColorColumn);

export const schedulerConfig = {
  minHeight: "20em",
  // Scheduler config options

  features: {
    group: {
      field: "workZone",
      ascending: false,
      renderer: ({ isFirstColumn, count, groupRowFor, record, row }) => {
        record.rowHeight = 30;
        return isFirstColumn ? `${groupRowFor} (${count})` : "";
      },
      eventEdit: {
        disabled: true
      }
    },
    timeRanges: {
      showCurrentTimeLine: true
    },
    cellTooltip: {
      tooltipRenderer: ({ record, column }) => {
        if (record.latestClockEvent !== null) {
          if (
            record.latestEvent.wo !== null &&
            record.latestEvent.wo !== undefined
          ) {
            return (
              "" +
              (typeof record.latestEvent.cust !== "undefined"
                ? "<b>" + record.latestEvent.cust + "</b><br>"
                : "") +
              "W/O : " +
              record.latestEvent.wo +
              " " +
              (typeof record.latestEvent.task === "object" ? record.latestEvent.task.task : typeof record.latestEvent.task === "undefined" ? "" : record.latestEvent.task) +
              " " +
              (record.latestEvent.type
                ? `${record.latestEvent.type
                    .charAt(0)
                    .toUpperCase()}${record.latestEvent.type.slice(1)}`
                : "") +
              "<br><i><small>" +
              record.latestEvent.time +
              "</small></i>"
            );
          } else {
            return "Latest Clock Event: " + record.latestClockEvent;
          }
        } else {
          return "No Clock Events";
        }
      },
      textContent: true,
      hoverDelay: 200,
      hideDelay: 0
    }
  },
  columns: [
    {
      type: "resourceInfo",
      field: "name",
      text: "Techs",
      editor: false,
      autoWidth: true
    },
    {
      text: "Status",
      field: "latestClockEvent",
      editor: false,
      align: "center",
      autoWidth: true,
      renderer: ({ record }) => {
        if (record.jobStatus == "idle") {
          return {
            cls: "fas fa-truck fa-lg",
            class: "myClass",
            children: [
              {
                tag: "i",
                class: "fas fa-truck fa-lg",
                style: "color : red"
              }
            ]
          }; //END return
        } //END if
        else if (record.jobStatus == "working") {
          return {
            class: "myClass",
            children: [
              {
                tag: "i",
                class: "fas fa-truck fa-lg",
                style: "color : green"
              }
            ]
          }; //END return
        } //END else if
        else if (record.jobStatus == "enroute") {
          return {
            class: "myClass",
            children: [
              {
                tag: "i",
                class: "fas fa-truck fa-lg",
                style: "color : blue"
              }
            ]
          }; //END return
        } //END else if
        else {
          return {
            class: "myClass",
            children: [
              {
                tag: "i",
                class: "fas fa-truck fa-lg",
                style: "color : black"
              }
            ]
          }; //END return
        } //END else
      } //END renderer
    }, //END status column

    {
      text: "Color",
      field: "eventColor",
      type: "color"
    }
  ], //END Columns
  events: [
    {
      id: 1,
      resourceId: 1,
      name: "Test Event",
      startDate: new Date(2021, 6, 11, 9, 0),
      endDate: new Date(2021, 6, 30, 9, 0)
    }
  ],

  //Fields are below
  autoAdjustTimeAxis: true, // startDate & endDate will be adjusted to display a suitable range
  allowOverlap: true, // Events are allowed to overlap (overlays, stacks or packs depending on eventLayout)
  barMargin: 3, // Space above + below each event
  resourceMargin: 3,
  rowHeight: 75,
  createEventOnDblClick: false, // Allow creating new events by double clicking empty space
  enableDeleteKey: false, // Allow deleting events with delete / backspace keys
  enableEventAnimations: true, // Animate event changes
  eventBarTextField: "name", // Field on EventModel to display in events
  eventColor: "red", // Use green as default color for events
  eventLayout: { type: "pack" }, // Stack overlapping events by default
  eventStyle: "rounded", // Use plain as default style for events, border used
  managedEventSizing: false, // Calculate event sizes based on rowHeight & barMargin
  milestoneCharWidth: 10,
  milestoneLayoutMode: "default",
  removeUnassignedEvent: true, // Remove event when all assignments for it are removed
  resourceImagePath: "",
  showAvatars: true,
  useInitialAnimation: true, // Fade in events initially
  viewPreset: {
    base: "hourAndDay",
    tickWidth: 100,
    shiftIncrement: 1,
    shiftUnit: "Hour",
    headers: [
      {
        unit: "week", //week
        dateFormat: "DD MMM YYYY" //01 Jan 2017 DD MMM YYYY
      },
      {
        unit: "day", //day
        dateFormat: "d1" //d1
      }
    ]
  },
  zoomOnMouseWheel: true,
  zoomOnTimeAxisDoubleClick: true
};

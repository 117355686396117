<template>
	<div>
		<!-- Invenotry Home -->
		<div class="searching card card-danger" v-show="action === 'searching'">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Inventory</b></h3>
				</div>
				<div class="card-tools">
					<b-button v-if="canAccess.admin || canAccess.parts" style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw" @click="action = 'massMove'; searchReq=''">Mass Move</b-button>
					<b-button v-if="canAccess.admin || canAccess.sales" style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw" @click="action = 'sellFunctions'">Sell Functions</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw" @click="createItemType('Part')">Create Item</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw" @click="createItemType('Kit')">Create Kit</b-button>
				</div>
			</div>
			<div class="card-body">
				<form id="searchFormParts" style="margin-bottom: 20px; display: flex; align-items: center; justify-content: center">
					<input
						type="text"
						class="form-control w-50"
						v-model="searchReq"
						placeholder="Search Requests"
						style="text-align: center"
						@input="debounceParts"
					/>

					<multiselect
						ref="partTypeFilter"
						id="partType"
						track-by="type"
						label="type"
						:clearable="true"
						:show-labels="false"
						:searchable="true"
						:options="allPartTypes"
						v-model="filterPartType"
						placeholder="Type"
						style="width: 20%; display: inline-block; margin-left: 20px;"
					>
					</multiselect>
				</form>
				<vue-virtual-table
					ref="inventoryTable"
					class="danger textBlack"
					:config="config"
					:data="filteredParts"
					:enableExport="true"
					:selectable="false"
					:height="600"
					@click="handleSelection"
					language="en"
					style="z-index: 0"
				>
					<template slot-scope="scope" slot="totalStock">
						<div @mouseover="hoverEvent(scope)" @mouseleave="hoverEvent(scope)">
							{{
								hovered[scope.index] && scope.row.totalStock > 0
								? scope.row.otherLocations
								.map((location) => {
									return `${location.location}, ${location.bin}: ${location.stock}`;
								}).join(" | ")
								: `${
									scope.row.totalStock != undefined
									? scope.row.totalStock
									: ""
								}`
							}}
						</div>
						<br />
					</template>

					<template slot="itemid" slot-scope="props">
						<intersect v-if="props.row.itemid === filteredParts[filteredParts.length - 1].itemid" @enter="getMoreInventory">
							<span>{{props.row.itemid}}</span>
						</intersect>
						<span v-else>{{ props.row.itemid }}</span>
					</template>
				</vue-virtual-table>
			</div>
		</div>

		<!-- View Item Details Section -->
		<div v-if="action === 'viewItemDetails'">
			<viewItem :selectedItem="selectedItem" @return="action = 'searching'; $forceUpdate()"></viewItem>
		</div>

		<!-- New Inventory Section-->
		<div v-if="action === 'adding'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Inventory</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							@click.prevent="addItem()"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						>Add Item
					</b-button>
					<b-button
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						@click.prevent="action = 'searching'"
					>Return</b-button>
				</div>
			</div>
			<div class="card-body">
				<form style="margin-left: 10%">
					<div class="row d-flex">
						<div class="col">

						<label for="itemId" class="w-75 text-left ml-n5 mt-2">Item ID<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							id='itemId'
							class="form-control w-75"
							type="text"
							v-model="addId"
							placeholder="Item ID"
							required
						/>

						<label for="vendor" class="w-75 text-left ml-n5 mt-2">Vendor<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							ref="vendorSelector"
							id="vendor"
							class="w-75"
							track-by="company"
							label="company"
							:clearable="true"
							:show-labels="false"
							:searchable="true"
							:options="allVendors"
							v-model="addVendor"
							placeholder="Vendor"
							@search-change="searchVendors"
							:loading="vendorListIsLoading"
							required
						>
							<template slot="option" slot-scope="props">
								<intersect v-if="props.option.company === allVendors[allVendors.length - 1].company" @enter="getMoreVendors">
									<span>{{ props.option.company }}</span>
								</intersect>
							</template>
						</multiselect>

						<label for="itemThresh" class="w-75 text-left ml-n5 mt-2">Item Threshold</label>
						<input
							id="itemThresh"
							class="form-control w-75"
							type="text"
							v-model="addThreshold"
							placeholder="Item  Auto-Purchase Threshold"

						/>

						<label for="itemAmt" class="w-75 text-left ml-n5 mt-2">Item Amount<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							id="itemAmt"
							class="form-control w-75"
							type="text"
							v-model="addCount"
							placeholder="Item Amount"
							required
						/>

						<label for="itemCost" class="w-75 text-left ml-n5 mt-2">Cost<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							id="itemCost"
							class="form-control w-75"
							type="text"
							v-model="addCost"
							placeholder="Item Cost"
							required
						/>

					</div>
					<!-- COL split -->
					<div class="col">
						<label for="itemDesc" class="w-75 text-left ml-n5 mt-2">Item Description<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							id="itemDesc"
							class="form-control w-75"
							type="text"
							v-model="addDesc"
							placeholder="Item Description"
							required
						/>

						<label for="locations" class="w-75 text-left ml-n5 mt-2">Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<div class="d-flex" id="locations">
							<multiselect
								ref="locationSelector"
								label="name"
								track-by="name"
								:max-height="200"
								:clearable="true"
								:searchable="true"
								:options="allLocations"
								:show-labels="false"
								v-model="location"
								placeholder="Location"
								class="w-75"
								display: inline
								@select="getDistinctBins"
								required
							>
							</multiselect>
							<i
								class="fas fa-plus-circle text-left fa-lg"
								@click="action = 'createLocation'; lastAction= 'adding'"
								style="display: inline; margin-top: 2.5%"
							></i>
						</div>

						<label for="binLoc" class="w-75 text-left ml-n5 mt-2">Bin Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							v-model="newBin"
							class="w-75"
							:options = "binList"
							:show-labels="false"
							placeholder="Bin"
							required>
						</multiselect>
						<div v-if="newBin.includes('**NEW**')">
							<input type="text" class="form-control w-50" v-model = "tempBin" placeholder="New Bin" style="display: inline" />
							<i class="fas fa-plus-circle text-left fa-lg" @click="newBin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%">
							</i>
						</div>

						<label for="itemSell" class="w-75 text-left ml-n5 mt-2">Sell Markup</label>
						<input
							id="itemSell"
							class="form-control w-75"
							type="text"
							v-model="addSell"
							placeholder="Item Sell % Markup"
						/>

						<label for="type" class="w-75 text-left ml-n5 mt-2">Type of Item<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							ref="typeSelector"
							id="type"
							class="w-75"
							track-by="type"
							:max-height="200"
							:clearable="true"
							:show-labels="false"
							:searchable="true"
							:options="types"
							v-model="addType"
							placeholder="Type"
							required
						></multiselect>

					</div>


				</div>


	            <div class="row" style="display: flex; justify-content: center; width: 51.75%; margin-left: 13.5%;">
					<label for="formFileLg" class="w-75 text-left ml-n5 mt-2">Image</label>
	              <input
	                class="form-control form-control-file w-75"
	                id="formFileLg"
	                type="file"
	                @change="uploadPartImage($event)"
	                name="picture"
	              />
	            </div>
	          </form>
	        </div>
	      </div>
	    </div>

		<!-- Make a New Location -->
	    <div v-if="action === 'createLocation'">
	      <div class="card card-danger">
	        <div class="card-header">
	          <div class="card-title">
	            <h3><b>New Location</b></h3>
	          </div>
				<div class="card-tools">
					<b-button
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						@click.prevent="addLocation()"
					>Add Location
					</b-button>
					<b-button
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						@click.prevent="action = lastAction"
					>Return
					</b-button>
				</div>
	        </div>
	        <div class="card-body d-flex">
	          <form class="m-auto">
				<label>Location Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
	            <input
				  required
	              type="text"
	              class="form-control w-100"
	              v-model="newLocation.name"
	              placeholder="Location Name"
				  style="margin-bottom: 10px;"
				/>
				<label>Type of Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<multiselect
					required
					ref="locTypeSelector"
					class ="w-100"
					:max-height="200"
					:clearable="true"
					:show-labels="false"
					:searchable="false"
					track-by="key"
					:options="locationTypeList"
					v-model="newLocation.type"
					placeholder="Type of Location"
				></multiselect>
				<br />
	          </form>
	        </div>
	      </div>
	    </div>

		<!-- Create Kit -->
	    <div v-if="action === 'createKit'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Create Kit</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							@click.prevent="addKit()"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw">
							Add Kit
						</b-button>
						<b-button @click.prevent="changeState('searching')" style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw">
							Return
						</b-button>
					</div>
				</div>
				<div class="card-body">
					<form style="margin-left: 10%">
						<div class="row">
							<div class="col">
								<label for="kitID" class="w-75 ml-n5 mt-2">Kit ID<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<input
									id="kitID"
									class="form-control w-75"
									type="text"
									v-model="kitId"
									placeholder="Kit ID"
									style="width: 10vw; margin-right: 1vw"
									required
								/>
							</div>
							<div class="col">
								<label for="kitDesc" class="w-75 ml-n5 mt-2">Kit Description<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<input
									id="kitDesc"
									class="form-control w-75"
									type="text"
									v-model="kitDesc"
									placeholder="Kit Description"
									style="width: 26vw"
									required
								/>

							</div>
							<div class="col">
								<label for="kitType" class="w-75 ml-n5 mt-2">Kit Type<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<multiselect
									id="kitType"
									class="w-75"
									:clearable="true"
									:show-labels="false"
									:options="kitTypes"
									v-model="kitType"
									:multiple="false"
									placeholder="Type"
									required
									>
								</multiselect>
							</div>
						</div>
						<div class="row" style="display: flex; justify-content: center; width: 51.75%; margin-left: 13.5%;">
							<label for="formFileLg" class="w-75 text-left ml-n5 mt-2">Image</label>
							<input
								class="form-control form-control-file w-75"
								id="formFileLg"
								type="file"
								@change="uploadPartImage($event)"
								name="picture"
							/>
						</div>
						<hr>
						<!-- SECTION FOR COMPONENTS -->

						<div class="row">
							<div class="col">
								<label for="partQuantity" class="w-75 ml-n5 mt-2">Qty<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<input
									id="partQuantity"
									type="number"
									name="partQuantity"
									min="0"
									v-model="kitPartQty"
									class="form-control w-75"
									required
								/>
							</div>

							<div class="col">
								<label for="kitID" class="w-75 ml-n5 mt-2">Item<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<div class="d-flex">
									<multiselect
										:clearable="true"
										:show-labels="false"
										:preserveSearch="true"
										:options="allParts"
										:group-select="false"
										:custom-label="({itemid, itemdesc}) => {
                                            if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                        }"
										group-label="name"
										group-values="items"
										v-model="kitPart"
										:multiple="false"
										track-by="itemid"
										label="itemdesc"
										placeholder="Item"
										class="w-75"
										style="display: inline"
										@search-change="debounceParts"
										required
										>
										<template slot="option" slot-scope="props">
											<intersect v-if="props.option.itemid === partKitQuery[partKitQuery.length - 1].itemid" @enter="getMoreInventory">
												<span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
											</intersect>
										</template>
									</multiselect>
									<i
										class="fas fa-plus-circle fa-lg"
										@click="addNewKitPart(kitPart)"
										style="display: inline; margin-top: 2.5%"
									>
									</i>
								</div>
							</div>
						</div>
					</form>
					<div v-show="kitComponents.length > 0" style="margin-left: 10%">
						<div class="row">
							<div class="col">
								<label>Item ID</label>
							</div>
							<div class="col">
								<label>Description</label>
							</div>
							<div class="col">
								<label>Qty</label>
							</div>
							<div class="col"></div>
						</div>

						<div v-for="component in kitComponents" :key="component.itemid" class="row">

							<div class="col">
								{{component.itemid}}
							</div>

							<div class="col">
								{{component.itemdesc}}
							</div>

							<div class="col">
								{{component.qty}}
							</div>
							<div class="col">
								<i class="fas fa-minus-circle" @click="removeKitPart(component)"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="action === 'massMove'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Move Many Items</b></h3>
					</div>
					<div class="card-tools">
						<div style="float: right; margin-top: -2.5%">
							<b-button class="actbutton" @click.prevent="moveEnmass">
								Submit
							</b-button>
							<b-button class="actbutton" @click.prevent="action = 'searching'">
								Return
							</b-button>
						</div>
					</div>
				</div>
				<div class="card-body">
					<b-table striped :items="movementArray" :fields="[{key: 'item'}, {key: 'from'}, {key: 'to'}, {key: 'qty'}, {key: 'action', label: ''}]">
						<template #cell(item)="row">
							{{row.item.item.itemid}} - {{row.item.item.itemdesc}}
						</template>

						<template #cell(from)="row">
							{{row.item.from.location}}: {{row.item.from.bin}}
						</template>

						<template #cell(to)="row">
							{{row.item.to.location}}: {{row.item.to.bin}}
						</template>

						<template #cell(qty)="row">
							{{row.item.qty}}
						</template>

						<template #cell(action)="row">
							<i class="fas fa-trash" @click="movementArray.splice(row.index, 1)"></i>
						</template>

						<template #bottom-row>
							<td>
								<multiselect
									:clearable="true"
									:show-labels="false"
									:preserveSearch="true"
									:options="allParts"
									:group-select="false"
									:custom-label="({itemid, itemdesc}) => {
										if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
									}"
									group-label="name"
									group-values="items"
									v-model="newMoveItem.item"
									:multiple="false"
									track-by="itemid"
									label="itemdesc"
									placeholder="Item"
									@select = "selectMovement"
									@search-change="debounceParts"
									required
									v-b-tooltip.hover title="Required Field"
									>
									<template slot="option" slot-scope="props">
										<intersect v-if="props.option.itemid === partKitQuery[partKitQuery.length - 1].itemid" @enter="getMoreInventory">
											<span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
										</intersect>
									</template>
								</multiselect>
							</td>
							<td>
								<multiselect
									v-model="newMoveItem.from"
									:options="moveFromList"
									:group-select="false"
									group-label = "name"
									group-values = "items"
									:custom-label="({vendor, bin, stock, warrantyStock}) => {
										if(location !== undefined && bin !== undefined){
											return `${bin} - ${vendor} - Qty:${warrantyFlag ? warrantyStock : stock}`
										}
									}"
									:clearable="true"
									:show-labels="false"
									required
									v-b-tooltip.hover title="Required Field"
									>
								</multiselect>
								<br>
								<label for="warrantyCheckbox">Warranty Part:</label>
								<input id="warrantyCheckbox" type="checkbox" v-model="warrantyFlag"/>
							</td>
							<td>
								<multiselect
									ref="locationSelector"
									label="name"
									track-by="name"
									:max-height="200"
									:clearable="true"
									:searchable="true"
									:options="allLocations"
									:show-labels="false"
									v-model="location"
									placeholder="Location"
									class="w-75"
									display: inline
									@select="getDistinctBins"
									required
									v-b-tooltip.hover title="Required Field"
								>
								</multiselect>
							<multiselect
								v-model="newBin"
								class="w-75"
								:options = "binList"
								:show-labels="false"
								placeholder="Bin"
								required
								v-b-tooltip.hover title="Required Field">
							</multiselect>
							<div v-if="newBin.includes('**NEW**')">
								<input type="text" class="form-control w-50" v-model = "tempBin" placeholder="New Bin" style="display: inline"/>
								<i class="fas fa-plus-circle text-left fa-lg" @click="newBin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%">
								</i>
							</div>

							</td>
							<td>
								<input
								type="number"
								v-model="newMoveItem.qty"
								pattern="[0-9]{1,5}"
								class="form-control w-50"
								min="0"
								:max="warrantyFlag ? newMoveItem.from.warrantyStock : newMoveItem.from.stock"
								required
								v-b-tooltip.hover title="Required Field"
								/>
							</td>
							<td>
								<i class="fas fa-plus-circle fa-2x" @click="addMovePart"></i>
							</td>
						</template>

					</b-table>
				</div>
			</div>
		</div>

		<div v-if="action === 'sellFunctions'">
			<buildSellFunctions @return="action='searching'; $forceUpdate()"></buildSellFunctions>
		</div>

	</div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import { Socket } from "net";
import VueVirtualTable from "vue-virtual-table";
import Intersect from 'vue-intersect'
import { debounce } from 'lodash';
import viewItem from './Inventory/viewItem.vue';
import buildSellFunctions from './SellFunctions/buildSellFunction.vue';

var _ = require('lodash');


export default {
  name: "app",
  props: {
    socket: Socket,
	canAccess: Object,
  },
  components: {
    Multiselect,
    VueVirtualTable,
    Intersect,
	viewItem,
	buildSellFunctions
  },
  data() {
    return {
      config: [
        { prop: "_action", name: "Item ID", searchable: false, actionName: "itemid", },
        {
          prop: "itemdesc",
          name: "Description",
          searchable: false,
          eClass: { twoLines: "true"},
          alignItems: 'left',
          eTip: ["itemdesc"],
        },
        {
          prop: "_action",
          name: "Total Stock",
          numberFilter: true,
          eTip: ["itemid"],
          eTipWithProp: true,
          actionName: "totalStock",
          eClass: { twoLines: "true" },
        },
        { prop: "type", name: "Type", sortable: true, filterable: true },
      ],
      action: "searching",
      addCost: "",
      addCount: "",
      addDesc: "",
      addId: "",
      addImage: "",
      addSell: "",
      addThreshold: "",
      addType: "",
      addVendor: "",
      allLocations: [],
      allVendors: [],
	  allParts: [
		  {
			  name: 'Part',
			  items: []
		  },
		  {
			  name: 'Equipment',
			  items: []
		  },
		  {
			  name: 'Kit',
			  items: []
		  },
		  {
			  name: 'Unset',
			  items: []
		  }
	  ],
	  binList: [],
      hovered: [],
      isBusy: false,
      kitComponents: [],
      kitDesc: "",
      kitId: "",
      kitPart: "",
      kitPartQty: 0,
      kitType: "",
      kitTypes: ["Part", "Equipment"],
	  lastAction: "",
      location: "",
	  locationTypeList: [
		  'Warehouse',
		  'Truck'
	  ],
	  movementArray: [],
	  moveFromList: [],
	  namedPartLocations: [],
	  newMoveItem: {
		  item: '',
		  from: '',
		  to: '',
		  qty: 0
	  },
	  newBin: "",
      newLocation: {
		  name: "",
		  type: ""
	  },
      partKitQuery: [],
      selectedItem: {
        itemid: -1,
        totalCount: 0,
      },
      types: ["Part", "Equipment"],
	  tempBin: "",
      pageNumber: 0,
      maxPages: 0,
      searchReq: "",
      maxPagesVendors: 0,
      pageNumberVendors: 0,
      prevSearchVendor: "",
      vendorListIsLoading: false,
      allPartTypes: [],
      filterPartType: "",
      filteredParts: [],
	  warrantyFlag: false
    };
  },
  created() {
    this.$globals.socket.emit("joinRoom", `inventory${this.$globals.companyId}`);
    this.$globals.socket.on("connectToRoom", (data) => {
      //console.log(data);
    });
      this.$globals.socket.on("newDataCreated", async (message, data) => {
        await axios
        .get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${this.selectedItem.itemid}/${this.$globals.companyId}`, {
            headers: {
                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
            }
        }).then((response) => {
            this.selectedItem = response.data.queryResult[0]
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

        await axios
        .get(
            process.env.VUE_APP_API_TARGET + "/locations/all/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
            this.allLocations = response.data.queryResult;
        })
        .catch((response)=>{
            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })

        this.getVendors()
        this.$forceUpdate()
    });
    this.debounceParts = debounce(this.searchPart, 600);
  },

  watch: {
  	filterPartType(val, oldVal){
  		this.buildFilteredParts()
  	},
  },

	async mounted() {
		this.isBusy = true;

		this.getInventory()

		//get all locations
		await axios
		.get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then((response) => {
			this.allLocations = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		this.getVendors()

		this.isBusy = false;
	},

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `inventory${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed inventory")
    },

  computed: {
    inventoryTableData() {
      return this.filteredParts;
    },
  },

  methods: {
  	log(item){
  		console.log("HERE: ", item)
  	},

    // Vendors Paging
    async getVendors(){
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getMoreVendors(){
      if(this.pageNumberVendors >= this.maxPagesVendors){
        return
      }
      let text = this.prevSearchVendor === "" ? text = null : text = this.prevSearchVendor
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        for(var item in response.data.queryResult.results){
          this.allVendors.push(response.data.queryResult.results[item])
        }
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async searchVendors(searchValue){
      if(searchValue === ""){
        searchValue = null
      }
      this.allVendors = []
      this.vendorListIsLoading = true
      this.pageNumberVendors = 0
      this.prevSearchVendor = searchValue
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.vendorListIsLoading = false
    },

    // Inventory Paging
    async getInventory(){
    	await axios
		.get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		)
		.then((response) => {
			this.partKitQuery = response.data.queryResult.results;
			this.maxPages = response.data.queryResult.maxPages;
			this.allPartTypes = response.data.queryResult.types;
			response.data.queryResult.results.forEach((item, index) => {
				if (item.itemid.charAt(0) !== "*") {
					var totalStock = 0;
					item.otherLocations?.forEach((location) => {
						totalStock += location.stock;
					});
					item.totalStock = totalStock;
					this.hovered[index] = false;
				}
			});
			this.pageNumber = this.pageNumber+1
			this.createGroups()
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.buildFilteredParts()
    },

    async getMoreInventory(){
    	if(this.pageNumber >= this.maxPages){
			return
		}
      let search_text = this.searchReq === "" ? null : this.searchReq
    	await axios
      .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${parseInt(this.pageNumber)+1}/search_text/${encodeURIComponent(search_text)}`,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
      .then((response) => {
      	for(var item in response.data.queryResult.results){
        	this.partKitQuery.push(response.data.queryResult.results[item]);
      	}
        this.maxPages = response.data.queryResult.maxPages
        response.data.queryResult.results.forEach((item, index) => {
          if (item.itemid.charAt(0) !== "*") {
            var totalStock = 0;
            item.otherLocations?.forEach((location) => {
              totalStock += location.stock;
            });
            item.totalStock = totalStock;
            this.hovered[index] = false;
          }
        });
        this.pageNumber = this.pageNumber+1
		this.createGroups()
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.buildFilteredParts()
    },

	async searchPart(text){
		if(typeof text === 'object'){
			text = this.searchReq === "" ? null : this.searchReq
		}
		let search_text = text === "" ? null : text
		this.pageNumber = 0
    	//this.partKitQuery = []
    	await axios
      .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${parseInt(this.pageNumber)+1}/search_text/${encodeURIComponent(search_text)}`,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
      .then((response) => {
		  if(response.data.queryResult.results.length > 0){
			  this.partKitQuery = response.data.queryResult.results;
			  this.maxPages = response.data.queryResult.maxPages
		  }

        response.data.queryResult.results.forEach((item, index) => {
          if (item.itemid.charAt(0) !== "*") {
            var totalStock = 0;
            item.otherLocations?.forEach((location) => {
              totalStock += location.stock;
            });
            item.totalStock = totalStock;
            this.hovered[index] = false;
          }
        });
        this.pageNumber = this.pageNumber+1
		this.createGroups()
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.buildFilteredParts()
    },

	addMovePart(){
		if(this.newMoveItem.item?.itemid === null || this.newMoveItem.item.itemid === undefined){
			alert("Item not detected")
			return;
		}
		if(this.newMoveItem.item.itemid.includes('**')){
			alert("Non-inventory item cannot be moved")
			return;
		}
		if(this.newMoveItem.from.location === undefined){
			alert("From location not detected")
			return;
		}
		if(this.location === undefined){
			alert("To location not detected")
			return;
		}
		if(this.newBin === undefined || this.newBin === null || this.newBin === '**NEW**' || this.newBin === ''){
			alert("To Bin not detected")
			return;
		}
		if(parseInt(this.newMoveItem.qty) <=0 || parseInt(this.newMoveItem.qty) > parseInt(this.newMoveItem.from.stock)){
			alert("Qty is wrong")
			return;
		}
		for(let i in this.movementArray){
			if(this.movementArray[i].itemid === this.newMoveItem.itemid && this.movementArray[i].from === this.newMoveItem.from){
				alert("Cannot draw from the same source at this time");
				return;
				break;
			}
		}

		this.newMoveItem.to = {
			location : this.location.name,
			bin : this.newBin
		}
		this.newMoveItem.warrantyFlag = this.warrantyFlag

		this.movementArray.push(this.newMoveItem)

		this.newMoveItem = {
			item: '',
			from: '',
			to: '',
			qty: 0
		}
		this.location = ''
		this.newBin = ''
		this.binList = []
	},

    buildFilteredParts(){
    	if(this.filterPartType === null || this.filterPartType === undefined){
    		this.filteredParts = this.partKitQuery
    	}
    	else if(this.filterPartType.type === "" || this.filterPartType.type === null || this.filterPartType.type === undefined){
    		this.filteredParts = this.partKitQuery
    	}
    	else{
    		this.filteredParts = []
    		for(let item in this.partKitQuery){
    			if(this.partKitQuery[item].type === this.filterPartType.type){
    				this.filteredParts.push(this.partKitQuery[item])
    			}
    		}
    	}
    	this.$refs.inventoryTable.update()
    	this.$forceUpdate()
    },

	async addItem() {
		if (this.addId === "" || this.addId === null) {
			this.addId = Math.floor(Math.random() * 1000000000000);
		}
		if (this.addDesc === "") {
			alert("All items must have a description");
			return;
		}
		if (this.location === "" || this.location === null || this.location?.name === undefined) {
			alert("All items must have a storage location");
			return;
		}
		if (this.newBin === "" || this.newBin === null || this.newBin.includes('**')) {
			alert("All items must have a bin location");
			return;
		}
		if(this.addVendor === "" || this.addVendor === null){
			alert("Inventory need to be linked to a Vendor")
			return;
		}

		if (this.addCount === "") {
			this.addCount = 0;
		}
		if (this.addThreshold === "") {
			this.addThreshold = 0;
		}
		if (this.addCost == ""){
			this.addCost = 0
		}
		if (this.addSell == ""){
			this.addSell = null
		}
		if (this.addType === "" || this.addType === null){
			this.addType = 'Unset'
		}

		this.newBin = this.newBin.toUpperCase()

		let itemCount = [
			{
				qty: this.addCount,
				cost: this.addCost,
				costDate: new Date().toISOString().split("T")[0]
			}
		]

		if(this.addSell !== null){
			this.addSell = parseFloat(this.addSell) / 100
		}

		await axios.post(process.env.VUE_APP_API_TARGET + "/inventory/" + this.$globals.companyId,
			{
				id: this.addId,
				location: this.location.name,
				bin: this.newBin.toUpperCase(),
				desc: this.addDesc,
				count: JSON.stringify(itemCount),
				threshold: this.addThreshold,
				cost: this.addCost,
				itemMarkup: this.addSell,
				vendor: this.addVendor.company,
				image: this.addImage,
				type: this.addType,
			},
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		)
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.$globals.socket.emit("newData", "inventory", "item");
		alert("Item added!");
		this.changeState("searching");
		this.addId = "";
		this.addDesc = "";
		this.addCount = "";
		this.location = "";
		this.newBin = "";
		this.addThreshold = "";
		this.addCost = "";
		this.addSell = "";
		this.addVendor = "";
		this.addImage = "";
		this.addType = "";
	},

    async addKit() {
      if (this.kitId == "") {
        alert("Kit must have an ID");
        return;
      }
      if ((this.kitDesc == "")) {
        alert("Kit must have a description");
        return;
      }
      if (this.kitComponents.length == 0) {
        alert("Kit must have individual components");
        return;
      }
      if (this.kitType == "") {
        alert("Kit must have a type");
        return;
      }
      this.kitId = "**KIT**" + this.kitId;

	  if(this.addImage === ""){
		  this.addImage = null;
	  }

      await axios.post(
        process.env.VUE_APP_API_TARGET + "/inventory/kits/" + this.$globals.companyId,
        {
          id: this.kitId,
          desc: this.kitDesc,
          components: JSON.stringify(this.kitComponents),
          type: this.kitType,
		  image: this.addImage
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
	  this.changeState('searching')
	  this.kitId = ""
	  this.kitDesc = ""
	  this.kitComponents = []
	  this.kitType = ""
	  this.kitPart = ""
	  this.kitPartQty = 0;
	  this.addImage = "";
    },

	addNewKitPart(newItem){
		if(newItem === null || newItem === undefined){
			alert("No item selected")
			return;
		}
		if(this.kitPartQty < 1){
			this.kitPartQty = 1
		}

		if(newItem.itemid.includes('**KIT')){
			newItem.components = typeof newItem.components === 'object' ? newItem.components : JSON.parse(newItem.components)
			newItem.components.forEach((item, i) => {
				let hold = this.kitPartQty
				this.addNewKitPart(item)
				this.kitPartQty = hold
			});
			this.kitPartQty = 0
		}
		else {
			let flag = -1;
			for (let comp in this.kitComponents) {
				if (this.kitComponents[comp].itemid === newItem.itemid) {
					this.kitComponents[comp].qty = parseInt(this.kitComponents[comp].qty) + parseInt(this.kitPartQty * (newItem.qty || 1));
					flag = comp;
					break;
				}
			}
			if(flag === -1){
				let tempQty = 1
				if(newItem.qty){
					tempQty = newItem.qty * this.kitPartQty
				}
				else{
					tempQty = this.kitPartQty
				}
				this.kitComponents.push({
					qty: tempQty,
					itemid: newItem.itemid,
					itemdesc: newItem.itemdesc
				});
			}

			this.kitPart = null;
			this.kitPartQty = 0;
		}
	},

	async addLocation() {
		if (this.newLocation.name == ""){
			alert("Please included a location name")
			return;
		}
		if (this.newLocation.type == "" || this.newLocation.type == null){
			alert("Please include a location type")
			return;
		}

        await axios.post(process.env.VUE_APP_API_TARGET + "/inventory/locations/" + this.$globals.companyId,
			{
				name: this.newLocation.name,
				type: this.newLocation.type
			},
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        this.$globals.socket.emit("newData", "inventory", "location");
        await axios
          .get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
          .then((response) => {this.allLocations = response.data.queryResult})
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
        this.action = this.lastAction;
		this.newLocation= {
			name: "",
			type: ""
		};
	},

	async changeState(string) {
		if (string === "adding") {
			this.action = "adding";
			this.location = "";
		}
		else if (string === "createKit") {
			this.kitComponents = []
			this.action = "createKit";
		}
		else {
			this.pageNumber = 0
			await this.getInventory()
			this.action = "searching";
		}
	},

    createItemType(type) {
      if (type == "Part") {
        this.changeState("adding");
      }
	  else {
		this.searchReq = ""
        this.changeState("createKit");
      }
    },

	createGroups(){
		this.allParts = [];
		var partItems = [];
		var equipItems = [];
		var kitItems = [];
		var unsetItems = [];
		for(let part in this.partKitQuery){
			if(this.partKitQuery[part].type == 'Part'){
				partItems.push(this.partKitQuery[part]);
			}
			else if(this.partKitQuery[part].type == 'Equipment'){
				equipItems.push(this.partKitQuery[part]);
			}
			else if(this.partKitQuery[part].type == 'Kit'){
				kitItems.push(this.partKitQuery[part]);
			}
			else if(this.partKitQuery[part].type == 'Unset'){
				unsetItems.push(this.partKitQuery[part]);
			}
		}
		//Removed Special Order from this group
		this.allParts.push({name: 'Kit', items: kitItems});
		this.allParts.push({name: 'Part', items: partItems});
		this.allParts.push({name: 'Equipment', items: equipItems});
		this.allParts.push({name: 'Unset', items: unsetItems});
	},

	getDistinctBins(locObj){
		axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${encodeURIComponent(locObj.name)}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		).then((response)=>{
			this.newBin = ""
			this.binList = []
			this.binList = response.data.queryResult
			for(var item in this.binList){
				this.binList[item] = this.binList[item].toUpperCase()
			}
			this.binList.unshift('**NEW**')
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	handleSelection(record) {
		this.selectedItem = record;
		this.action = 'viewItemDetails'
	},

    hoverEvent(row) {
      this.hovered[row.index] = !this.hovered[row.index];
      this.$refs.inventoryTable.$forceUpdate();
    },

	moveEnmass(){
		//Safety check should never be true but will prevent if there is an issue
		for(let i in this.movementArray){
			for(let j = i; j<this.movementArray.length; j++){
				if(this.movementArray[i].item === this.movementArray[j].item && this.movementArray[i].from.location === this.movementArray[j].from.location && this.movementArray[i].from.bin === this.movementArray[j].from.bin && Number(i)!== Number(j)){
					alert(`Cannot draw from the same source at this time: ${this.movementArray[i].item.itemid} ${this.movementArray[i].from.location} - ${this.movementArray[i].from.bin}`);
					return;
					break;
				}
			}
		}

		this.movementArray.forEach((instance, e) => {
			let tempQtys = parseInt(instance.qty)
			let holder = [];

			instance.to.bin = instance.to.bin.toUpperCase()

			while(tempQtys > 0){
				//sets data.itemcount to appropate number
				let mathInfoThing = parseInt(instance.warrantyFlag ? instance.from.warranty_count[0].qty : instance.from.itemcount[0].qty) - tempQtys


				//if negitive or zero it pops off the itemcount instance to bring next forward and retries with excess qty
				if(mathInfoThing < 0){
					tempQtys = Math.abs(mathInfoThing)
					//data.itemcount[0].qty = Math.abs(data.itemcount[0].qty)
					if(instance.warrantyFlag){
						holder.push(instance.from.warranty_count.shift())
					}
					else{
						holder.push(instance.from.itemcount.shift())
					}
				}
				//else there isn't any left to subtract
				else{
					if(instance.warrantyFlag){
						holder.push({...instance.from.warranty_count[0], qty: Math.abs(tempQtys) })
					}
					else{
						holder.push({...instance.from.itemcount[0], qty: Math.abs(tempQtys) })
					}
					tempQtys = 0
				}
			}
			//Updates the DB correctly.
			if(instance.warrantyFlag){
				axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(instance.item.itemid)}/${encodeURIComponent(instance.from.location)}/${encodeURIComponent(instance.from.bin)}/${this.$globals.companyId}`,
				{
					vendor:instance.from.vendor,
					qty: parseInt(instance.qty)
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(instance.item.itemid)}/${encodeURIComponent(instance.to.location)}/${encodeURIComponent(instance.to.bin)}/${this.$globals.companyId}`,
					{
						qty: parseInt(-1 * instance.qty),
						vendor: instance.from.vendor,
						addBack: holder
					},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})


			}
			else{
				axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(instance.item.itemid)}/${encodeURIComponent(instance.from.location)}/${encodeURIComponent(instance.from.bin)}/${this.$globals.companyId}`,
				{
					vendor:instance.from.vendor,
					qty: parseInt(instance.qty)
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(instance.item.itemid)}/${encodeURIComponent(instance.to.location)}/${encodeURIComponent(instance.to.bin)}/${this.$globals.companyId}`,
					{
						qty: parseInt(-1 * instance.qty),
						vendor: instance.from.vendor,
						addBack: holder
					},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})


			}

		});

		this.movementArray = []
		this.action = 'searching'

	},

    removeKitPart(part) {
      for (let item in this.kitComponents) {
        if (this.kitComponents[item].itemid == part.itemid) {
          this.kitComponents.splice(item, 1);
          break;
        }
      }
	  this.$forceUpdate()
    },

	selectMovement(item){
		this.newMoveItem.item = item;
		if(this.newMoveItem.item === null){
			this.newMoveItem.item = ''
		}
		this.moveFromList=[]
		let headerList = []

		for(let i in item.otherLocations){
			if(!headerList.includes(item.otherLocations[i].location)){
				headerList.push(item.otherLocations[i].location)
				this.moveFromList.push({name: item.otherLocations[i].location, items: []})
			}
			item.otherLocations[i].warranty_count = typeof item.otherLocations[i].warranty_count === 'object' ? item.otherLocations[i].warranty_count : JSON.parse(item.otherLocations[i].warranty_count)
			let currentIndex = this.moveFromList.findIndex((header)=> header.name === item.otherLocations[i].location)
			this.moveFromList[currentIndex].items.push(item.otherLocations[i])

		}


		this.newMoveItem.item = item;

		//this.moveFromList = this.newMoveItem.item?.otherLocations || []
		this.newMoveItem.from =''
		this.newMoveItem.to=''
		this.$forceUpdate()

	},

    uploadPartImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.addImage = e.target.result;
      };
    },

}//END methods
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

/deep/ .twoLines {
  font-size: 0.8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

/* /deep/ div.v-popover.open{
	visibility: visible;
	z-index: 1000;
	background-color: black;
	transform: translateY(-10px)
} */

/deep/ .rowSlot:hover {
}

/deep/
  div.searching.card.card-danger
  div.main-scroll.danger
  div.item-cell-inner
  span.tag.defaultTag {
  background-color: rgba(220, 53, 69, 0.25) !important;
  border-color: #dc3545 !important;
  color: #dc3545 !important;
}

/deep/ .base-button,
.primary-btn {
  background-color: #dc3545 !important;
  color: #ffffff !important;
}

/deep/ .base-button:hover,
.primary-btn:hover {
  background-color: #fff4 !important;
  color: #dc3545 !important;
}

/deep/ .btn,
.button,
.primary-btn {
  background-color: #dc3545;
  border-color: #dc3545 !important;
}

/deep/ span.filtered,
/deep/ span.searched,
/deep/ span.numFiltered {
  color: #dc3545 !important;
}

/deep/ div.check-box.filter-list div.check-item.checked div.check-label {
  color: #dc3545 !important;
}

/deep/ div.check-box.filter-list div.check-item.checked div.check-icon svg {
  color: #dc3545 !important;
}

img.item {
  border-radius: 8px;
  height: 200px;
  width: 200px;
  margin-top: 10%;
}

.customPagination {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.counter {
  box-shadow: 1px 2px 0px 0px #899599;
  background: linear-gradient(to bottom, #ededed 5%, #bab1ba 100%);
  background-color: #ededed;
  border-radius: 17px;
  border: 1px solid #d6bcd6;
  display: inline-block;
  cursor: pointer;
  color: #3a8a9e;
  font-family: Arial;
  font-size: 10px;
  vertical-align: top;
  text-decoration: none;
  text-shadow: 0px 1px 0px #e1e2ed;
  margin-left: 2.5px;
  margin-right: 2.5px;
}
.counter:hover {
  background: linear-gradient(to bottom, #bab1ba 5%, #ededed 100%);
  background-color: #bab1ba;
}
.counter:active {
  position: relative;
  top: 1px;
}
br {
  content: "A" !important;
  display: block;
  margin-top: 0.05em;
}
br.desc {
  margin: 0;
}
form.adding {
  text-align: center;
  margin: auto;
}

form.adding button {
  margin: 15px;
  padding: 10px;
  background: #dc3545;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
}
.actbutton {
  margin: 15px;
  padding: 10px;
  background: #fff4;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
}

.new-styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 80vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  align: center;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
}

.fas:hover,
.far:hover {
  color: #dc3545;
}
div.searching {
  text-align: center;
  margin: auto;
}

/deep/ .customPagination {
  margin: auto;
  margin-bottom: 1%;
}

/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}

/deep/ .customPagination .page-item .page-link {
  color: #dc3545 !important;
}

.fillbutton {
  background-color: #dc3545 !important;
  border-color: black !important;
  color: white !important;
}

select .adding {
  background-color: #dc3545;
  text-align-last: center;
  color: white;
  padding: 12px;
  width: 40%;
  border: none;
  font-size: 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
}

.textBlack{
  color: black !important
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__option--highlight {
  background: #dc3545 !important;
}
</style>

<template>
	<div class="pb-3">
		<div v-if="action === 'load'">
			<strong>Loading...</strong>
			<b-spinner class="align-middle"></b-spinner>
		</div>
		<div v-if="action === ''">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Vehicle Information</b></h3>
					</div>
					<div class="card-tools">
						<b-button v-if="subaction == 'editing'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="updateVehicle()">Confirm</b-button>
						<b-button v-if="subaction == 'editing'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="stopEdit()">Cancel</b-button>
						<b-button v-if="subaction !== 'editing'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="edit()">Edit</b-button>
						<b-button v-if="$globals.canAccess.admin && subaction !== 'editing'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="removeVehicle(selectedVehicle)">Delete</b-button>
						<b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="$emit('return')">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<div>
						<div v-if="subaction === 'editing'" style="display: flex; justify-content: center">
							<b-row>
								<strong>Vehicle ID: </strong> <input type="text" class="form-control w-75" style="display: inline" placeholder="Vehicle ID" v-model="selectedVehicle.vehicleid" />
							</b-row>
						</div>
						<div v-else style="display: flex; justify-content: center">
							<h3>Vehicle: {{ selectedVehicle.vehicleid }}</h3>
						</div>

						<b-row style="justify-content: center; text-align: center; font-size: 24pt;"><b>Vehicle Information</b></b-row>
						<div v-if="subaction !== 'editing'">
							<b-row>
								<b-col style="justify-content: center; text-align: center; margin-left: 12.5%; margin-right: auto">
									<b-row>
										<strong>Year: &nbsp;</strong> {{ selectedVehicle.year }}
									</b-row>
									<b-row>
										<strong>Make: &nbsp;</strong> {{ selectedVehicle.make }}
									</b-row>
									<b-row>
										<strong>Model: &nbsp;</strong> {{ selectedVehicle.model }}
									</b-row>
									<b-row>
										<strong>VIN: &nbsp;</strong> {{ selectedVehicle.vin }}
									</b-row>
								</b-col>
								<b-col v-if="selectedVehicle.license !== undefined" style="justify-content: center; text-align: center;">
									<b-row>
										<strong>License Number: &nbsp;</strong> {{ selectedVehicle.license.number }}
									</b-row>
									<b-row>
										<strong>State of License: &nbsp;</strong> {{ selectedVehicle.license.state.abbreviation }}
									</b-row>
									<b-row>
										<strong>Rating: &nbsp;</strong> {{ selectedVehicle.license.rating }}
									</b-row>
									<b-row>
										<strong>Expires: &nbsp;</strong> {{ selectedVehicle.license.expires }}
									</b-row>
								</b-col>
								<b-col style="justify-content: center; text-align: center;">
									<b-row>
										<strong>Responsible User: &nbsp;</strong> {{ selectedVehicle.responsibleUser }}
									</b-row>
									<b-row>
										<strong>Location: &nbsp;</strong> {{ selectedVehicle.location }}
									</b-row>
									<b-row>
										<strong>Cost New: &nbsp;</strong> {{ selectedVehicle.costNew }}
									</b-row>
									<b-row>
										<strong>Note: &nbsp;</strong> {{selectedVehicle.notes}}
									</b-row>

								</b-col>

							</b-row>
						</div>

						<div v-if="subaction === 'editing'">
							<div class="row mt-3">
								<div class="col">
									<div class="row">
										<div class="col text-right">
											<b>Year:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Year" v-model="selectedVehicle.year" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Make:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Make" v-model="selectedVehicle.make" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Model:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Model" v-model="selectedVehicle.model" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>VIN:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="VIN" v-model="selectedVehicle.vin" />
										</div>
									</div>
								</div>
								<div class="col">
									<div class="row">
										<div class="col text-right">
											<b>License Number:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="License Number" v-model="selectedVehicle.license.number" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>State of License:</b>
										</div>
										<div class="col-7">
											<multiselect
												:options="$globals.allStates"
												placeholder="Select State"
												label="name"
												track-by="name"
												v-model="selectedVehicle.license.state"
												:clearable="true"
												:show-labels="false">
											</multiselect>
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Rating:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Rating" v-model="selectedVehicle.license.rating" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Expires:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Expires" v-model="selectedVehicle.license.expires" />
										</div>
									</div>
								</div>
								<div class="col">
									<div class="row">
										<div class="col text-right">
											<b>Responsible User:</b>
										</div>
										<div class="col-7">
											<multiselect
												:options="allUsers"
												placeholder="Select User"
												label="name"
												track-by="name"
												v-model="newUser"
												:clearable="true"
												:show-labels="false">
											</multiselect>
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Location:</b>
										</div>
										<div class="col-7">
											<multiselect
												:options="baseLocations"
												placeholder="Select Based Location"
												label="name"
												track-by="name"
												v-model="newLocation"
												:clearable="true"
												:show-labels="false">
											</multiselect>
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Cost New:</b>
										</div>
										<div class="col-7">
											<input class="form-control " type="text" placeholder="Original Cost" v-model="selectedVehicle.costNew" />
										</div>
									</div>
									<div class="row">
										<div class="col text-right">
											<b>Note:</b>
										</div>
										<div class="col-7">
											<textarea class="form-control" rows="2" placeholder="Notes" v-model="selectedVehicle.notes"></textarea>
										</div>
									</div>
								</div>
							</div>

						</div>



					</div>

				</div>
			</div>

			<div class="row">
                <div class="col">
                    <div class="card card-danger">
                        <div class="card-header">
                            <div class="card-title">
                                <h3><b>Maintenance History</b></h3>
                            </div>
                            <div class="card-tools">
                                <b-button v-if="subaction !== 'editing'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="createMaintenanceNote()">New</b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <b-table
                                ref="history"
                                :items="selectedVehicle.maintenanceHistory"
                                :fields="[
                                    { key:'action'},
                                    { key:'date'},
                                    { key: 'doc', label: 'Document'}
                                ]"
                                striped
                                :current-page="currentPage.history"
                                :per-page="5"
                                show-empty>

                                <template #top-row v-if="subaction === 'newHistory'">
                                    <td>
                                        <input type="text" class="form-control" v-model="newHistory"/>
                                    </td>
                                    <td>
                                        <input type="file" class="form-control" ref="inputFile"	name="doc" />
                                    </td>
                                    <td>
                                        <i class="fas fa-plus-circle fa-3x" @click="addHistory()"></i>
                                    </td>
                                </template>

                                <template #cell(doc)="row">
                                    <i v-if="row.item.doc" class="fas fa-download" @click="download(row.item.doc)"></i>
                                </template>
                            </b-table>

                            <b-pagination
                                v-if="selectedVehicle.maintenanceHistory.length > 0"
                                class="customPagination"
                                size="sm"
                                v-model="currentPage.history"
                                :per-page="5"
                                limit="5"
                                :total-rows="selectedVehicle.maintenanceHistory.length"
                                aria-controls="history"
                                style="margin-left: 42.5%; margin-right: 40.5%">
                            </b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col" v-if="selectedVehicle.linxup !== null && selectedVehicle.linxup !== undefined">
                    <div class="card card-danger">
                        <div class="card-header">
                            <div class="card-title">
                                <h3><b>Linxup&trade; Data</b></h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <b>Year:</b> {{selectedVehicle.linxup.year}}
                                </div>
                                <div class="col">
                                    <b>Make:</b> {{selectedVehicle.linxup.make}}
                                </div>
                                <div class="col">
                                    <b>Model:</b> {{selectedVehicle.linxup.model}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b>Virtual Odometer:</b> {{selectedVehicle.linxup.virtualOdo}}
                                </div>
                                <div class="col">
                                    <b>Fuel Level:</b> {{selectedVehicle.linxup.fuelLevel}}
                                </div>
                                <div class="col">
                                    <b>Battery:</b> {{selectedVehicle.linxup.battery}}V
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col text-center">
                                    <b>Last Updated:</b> {{ new Date(selectedVehicle.linxup.date).toLocaleString() }}
                                </div>
                            </div>
                            <hr class="w-75" />

                            <div class="row">
                                <div class="col">
                                    <b>Status:</b> {{selectedVehicle.linxup.status}}
                                </div>
                                <div class="col">
                                    <b>Speed:</b> <span :class="selectedVehicle.linxup.speeding === true ? 'text-danger font-weight-bold' : ''">{{selectedVehicle.linxup.speed}} mph</span>
                                </div>
                                <div class="col">
                                    <b>Battery:</b> {{selectedVehicle.linxup.battery}}V
                                </div>
                            </div>
                            <div ref="map" id="map" class="map" style="height: 500px"></div>
    
                        </div>
                    </div>
                </div>
			</div>


		</div>

	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import { debounce } from 'lodash';
import Multiselect from 'vue-multiselect'
import { BPagination } from "bootstrap-vue";


var _ = require('lodash');


export default{
	props: {
		targetVehicle : Object
	},

	components: {
		Multiselect,
		BPagination
	},

	data(){
		return {
			action: 'load',
			allUsers: [],
			baseLocations: [],
			copyBeforeEdit: {},
			currentPage: {
				history: 1
			},
			marker: {},
			map: {},
			newLocation: '',
			newUser: '',
			newHistory:'',
			newFile: '',
			subaction: '',
			selectedVehicle: {
				license: {
					state: {}
				}
			},
		}
	},

	created(){

	},

    async mounted() {
        console.log("prop:", this.targetVehicle)
        this.selectedVehicle = { ...this.targetVehicle }
		await this.getTargetVehicle()
		this.action = ''
		this.createMap();

	},

	methods: {

		async addHistory(){
			if(this.newHistory == '' || this.newHistory == null){
				alert("Action must be filled in");
				return;
			}
			let tempHistory = {
				action: this.newHistory,
				date: new Date().toISOString().split("T")[0],
			}
			//this.selectedVehicle.maintenanceHistory.unshift(tempHistory)

			var formData = new FormData();
			formData.append("doc", this.$refs.inputFile.files[0] || null);
			formData.append("newHistory", JSON.stringify(tempHistory))
			formData.append("oldHistory", JSON.stringify(this.selectedVehicle.maintenanceHistory))



			await axios.put(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicle/maintenance/${this.selectedVehicle.vin}/${this.$globals.companyId}`,
				formData,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				this.getTargetVehicle();
				this.subaction = ''
				this.$refs.inputFile = null
				this.newHistory = ''
			})
		},

		createMaintenanceNote(){
			this.subaction = 'newHistory'
		},

		async createMap(){

			let tempLocations = {
				name: this.selectedVehicle.vehicleid,
				location: {
					speed: this.selectedVehicle.linxup?.speed,
					heading: this.selectedVehicle.linxup?.heading,
					accuracy: 65,
					altitude: 150,
					lat: parseFloat(this.selectedVehicle.linxup?.latitude),
					lng: parseFloat(this.selectedVehicle.linxup?.longitude),
					altitudeAccuracy: 21.46
				},
				picture: ''
			}

			this.$nextTick(() =>{

				if(!isNaN(tempLocations.location.lat) && !isNaN(tempLocations.location.lng)){
					//NOTE Use a company base location as a defualt location instead of 0
					this.map = new window.google.maps.Map(this.$refs["map"],{
						center: {lat:tempLocations.location.lat || 0, lng:tempLocations.location.lng || 0},
						zoom: 17
					})
					this.marker = new window.google.maps.Marker({
						position: {lat:tempLocations.location.lat, lng:tempLocations.location.lng},
						map: this.map,
						icon: {
							url: 'https://www.freeiconspng.com/thumbs/car-icon-png/car-icon-png-25.png',
							scaledSize: new google.maps.Size(25, 25)//in pixels
						},
						title: tempLocations.name
					});
				}
				else{
					//Points at roughly USA
					this.map = new window.google.maps.Map(this.$refs["map"],{
						center: {lat:35, lng:-100},
						zoom: 5
					})
				}


			})
			//https://stackoverflow.com/questions/4416089/google-maps-api-v3-custom-cluster-icon
		},//END createMap()

		download(url){
			window.open(url, '_blank')
		},

		async edit(){
			this.copyBeforeEdit = _.cloneDeep(this.selectedVehicle)

			await axios.get(process.env.VUE_APP_API_TARGET + "/locations/" + this.$globals.companyId,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response) => {
				this.baseLocations = response.data.queryResult;
			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response) => {
				this.allUsers = response.data.queryResult;
			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			this.baseLocations.forEach((item, i) => {
				if(this.selectedVehicle.location === item.name){
					this.newLocation = item;
				}
			});

			this.allUsers.forEach((item, i) => {
				if(this.selectedVehicle.responsibleUser == item.name){
					this.newUser = item;
				}
			});



			this.subaction = 'editing'
		},

		async getTargetVehicle(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicles/${this.targetVehicle.vin}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			}).then((response)=>{
                this.selectedVehicle = response.data.queryResult[0]
                console.log("getter:", response.data.queryResult)
				if(this.selectedVehicle.license == null || this.selectedVehicle.license === undefined){
					this.selectedVehicle.license = {
						state: '',
						number: '',
						rating: '',
						expires: ''
					}
                }
				this.selectedVehicle.license = typeof this.selectedVehicle.license == 'object' ? this.selectedVehicle.license : JSON.parse(this.selectedVehicle.license)
				if(this.selectedVehicle.maintenanceHistory == null || this.selectedVehicle.maintenanceHistory == ''){
					this.selectedVehicle.maintenanceHistory = []
                }
				this.selectedVehicle.maintenanceHistory = typeof this.selectedVehicle.maintenanceHistory == 'object' ? this.selectedVehicle.maintenanceHistory : JSON.parse(this.selectedVehicle.maintenanceHistory)
			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		},

		async removeVehicle(vehicle) {
	      await axios.delete(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicle/${encodeURIComponent(vehicle.vin)}/${this.$globals.companyId}`,
	          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
	      ).then((response)=>{
			  this.$emit('return')
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	    }, //END removeVehicle()

		stopEdit(){
			this.selectedVehicle = this.copyBeforeEdit
			this.action = '';
			this.subaction = '';
		},

		updateVehicle(){
			if(this.selectedVehicle.vin == '' || this.selectedVehicle.vin == null){
				alert("Vin is required")
				return;
			}

			if(
				(this.selectedVehicle.license.state == '' || this.selectedVehicle.license.state == null) &&
				(this.selectedVehicle.license.number == '' || this.selectedVehicle.license.number == null) &&
				(this.selectedVehicle.license.rating == '' || this.selectedVehicle.license.rating == null) &&
				(this.selectedVehicle.license.expires == '' || this.selectedVehicle.license.expires == null)
			){
				this.selectedVehicle.license = null;
			}
			else{
				this.selectedVehicle.license = JSON.stringify(this.selectedVehicle.license)
			}

			axios.patch(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicle/${this.targetVehicle.vin}/${this.$globals.companyId}`,{
				vin: this.selectedVehicle.vin,
				vehicleid: this.selectedVehicle.vehicleid || null,
				location: this.newLocation?.name || null,
				responsibleUser: this.newUser?.name || null,
				make: this.selectedVehicle.make || null,
				model: this.selectedVehicle.model || null,
				year: this.selectedVehicle.year || null,
				notes: this.selectedVehicle.notes || null,
				license: this.selectedVehicle.license, //Stringify above
				cost: this.selectedVehicle.costNew || null
			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				this.selectedVehicle.license = typeof this.selectedVehicle.license === 'string' ? JSON.parse(this.selectedVehicle.license) : {}//parse back to read
				this.action = ''
				this.subaction = ''
				this.targetVehicle = this.selectedVehicle
			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		},

	}
}

</script>

<style>
.map {
	margin:1%;
	border: #dc3545;
	background:grey;
	height: 95%;
	width: 95%;
}

/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
/deep/ .customPagination .page-item .page-link {
  color: #dc4444 !important;
}

</style>

<template>
	<div class="container-fluid">
    <!--Default Show Section -->
    <div v-show="action == 'searching'">
      <div class="row mt-n4">
        <div class="col ml-4">
          <label>Customer</label>
          <div class="d-flex">
            <multiselect
              style="display: inline-block; width:95%;, height: 100px;"
              label="name"
              track-by="customerid"
              :clearable="true"
              :show-labels="false"
              :options="allCustomers"
              :optionsLimit="10000"
              v-model="searchedCustomer"
              @input="rowClicked"
              placeholder="Customer"
              @search-change="debounceCustomers"
              :loading="customerListIsLoading"
            >
            <template slot="option" slot-scope="props">
              <intersect v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
                <span>{{props.option.name}}</span>
              </intersect>
            </template>
            </multiselect>
            <i class="fas fa-plus-circle" @click="action = 'createCustomer'" style="margin-top: 1%; margin-left: 1%;"></i>
            <div v-if="canAccess.admin === true" style="margin-left: 1%; margin-top: 0.75%; margin-right: 1%;">
              <i v-if="$globals.canAccess.admin" class="fas fa-edit" @click="editCustomerInfo()" style="margin-left: 1%; display: inline;"></i>
              <i v-if="$globals.canAccess.admin" class="fas fa-trash" @click="removeCustomer(selectedCustomer)" style="margin-left: 25%; display: inline;"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" style="max-width: 99.5%">
          <div class="card card-danger mt-4 ml-4" style="height: 60vh">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Information</b></h3>
              </div>
              <div class="card-tools">
                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="newCustomerContent()">{{buttonString}}</b-button>
              </div>
            </div>
            <div class="card-body" style="overflow-y: scroll">

              <b-row>
                <b-col>
                  <b>Shipping Address:</b> <br />
                  <span style="font-size: 10pt">{{
                    selectedCustomer!==null ? selectedCustomer.shipping : ''
                  }}</span>
                </b-col>
                <b-col>
                  <b>Billing Address:</b> <br />
                  <span style="font-size: 10pt">
                    <span v-if="selectedCustomer.billing_name">{{ selectedCustomer.billing_name }}<br/></span>
                    {{ selectedCustomer.billing }}
                  </span>
                </b-col>
                <b-col>
                  <b>Phone Number:</b> <br />
                  <span style="font-size: 10pt">{{
                    selectedCustomer.phone
                  }}</span>
                </b-col>
                <b-col>
                  <b>E-Billing:</b> <br /><span style="font-size: 10pt">{{
                    selectedCustomer.electronic === 1 ? "Yes" : "No"
                  }}</span>
                </b-col>
                <b-col>
                  <b>Balance:</b> <br /><span style="font-size: 10pt; text-decoration: underline; text-decoration-style: dotted;" v-b-tooltip.hover :title="`Sales Balance: $${selectedCustomer.sales_balance}\n\rService Balance: $${selectedCustomer.service_balance}`">${{
                    (selectedCustomer.sales_balance + selectedCustomer.service_balance).toFixed(2)
                  }}</span>
                </b-col>
                <b-col>
                  <b>Tax Exempt:</b><br />
                    <span style="font-size: 10pt">
                      {{ selectedCustomer.tax_exempt }}
                    </span>
                </b-col>
              </b-row>
              <b-tabs fill lazy v-model="selectedTab">
                <b-tab title="Contacts" :title-link-class="'text-danger'">
                  <b-table
                    :items="allContacts[lastSelectedIndex]"
                    :fields="[
                      { key: 'image'},
                      { key: 'title', thStyle: 'font-size:12pt' },
                      { key: 'name', thStyle: 'font-size:12pt' },
                      { key: 'roles', thStyle: 'font-size:12pt;' },
                      { key: 'address', thStyle: 'font-size:12pt' },
                      { key: 'phone', thStyle: 'font-size:12pt; width: 15%' },
                      { key: 'email', thStyle: 'font-size:12pt' },
                      { key: 'edit', label: '' },
                    ]"
                    style="font-size: 10pt; width: 95%"
                  >
                    <template #cell(title)="row">
                        <div v-if=" subaction !== 'editContact' || editIndex !== row.item.contactid">
                            {{ row.item.title }}
                        </div>
                        <input class="form-control form-control-sm" v-if="subaction === 'editContact' && editIndex === row.item.contactid" v-model="row.item.title">
                    </template>

                    <template #cell(name)="row">
                        <div v-if=" subaction !== 'editContact' || editIndex !== row.item.contactid">
                            {{ row.item.name }}
                        </div>
                        <input class="form-control form-control-sm" v-if="subaction === 'editContact' && editIndex === row.item.contactid" v-model="row.item.name">
                    </template>

                    <template #cell(roles)="row">
                      <div
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                      >
                        <div
                          v-for="(role, index) in (typeof row.item.role === 'object' ? row.item.role : JSON.parse(row.item.role))"
                          :key="index"
                        >
                          {{ role }}
                        </div>
                      </div>
                      <multiselect
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        :options="[
                          'Administrator',
                          'Purchasing',
                          'Maintenance',
                          'Billing',
                        ]"
                        :show-labels="false"
                        :multiple="true"
                        v-model="row.item.role"
                        style="width: 150px"
                      ></multiselect>
                    </template>

                    <template #cell(image)="row">
                      <img
                        v-if="
                          (subaction !== 'editContact' ||
                            editIndex !== row.item.contactid) &&
                          row.item.image !== null
                        "
                        :src="row.item.image" 
                        class="img-circle elevation-3 note-pfp"
                      />
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                      >
                        <b-row
                          v-if="
                            subaction === 'editContact' &&
                            editIndex === row.item.contactid
                          "
                        >
                          <img
                            :src="row.item.image" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'"
                            class="img-circle elevation-3 note-pfp"
                          />
                        </b-row>
                        <b-row
                          v-if="
                            subaction === 'editContact' &&
                            editIndex === row.item.contactid
                          "
                        >
                          <input
                            style="margin-top: 3%;"
                            class="form-control-sm form-control-file"
                            type="file"
                            accept="image/*"
                            @change="updateImage($event, row.item)"
                            name="c_pic"
                            :show-labels="false"
                            id="file-input"
                          />
                        </b-row>
                      </b-col>
                    </template>

                    <template #cell(email)="row">
                      <a
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        :href="'mailto:' + row.item.email"
                        class="vehicle-link"
                        >{{ row.item.email }}</a
                      >
                      <input
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        class="form-control form-control-sm"
                        type="text"
                        style="width: 150px"
                        v-model="row.item.email"
                      />
                    </template>

                    <template #cell(phone)="row">
                      <a
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        :href="'tel:' + row.item.phone"
                        class="vehicle-link"
                        >{{ row.item.phone }}</a
                      >
                      <input
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        class="form-control form-control-sm"
                        type="text"
                        style="width: 150px"
                        v-model="row.item.phone"
                      />
                    </template>

                    <template #cell(address)="row">
                      <a
                        v-if="
                          (subaction !== 'editContact' ||
                          editIndex !== row.item.contactid) &&
                          typeof row.item.address === 'string'
                        "
                        :href="`https://www.google.com/maps/dir/?api=1&destination=${row.item.address.replace(
                          ' ',
                          '+'
                        )}`"
                        @click.prevent="
                          window.open(
                            `https://www.google.com/maps/dir/?api=1&destination=${row.item.address.replace(
                              ' ',
                              '+'
                            )}`,
                            '_blank'
                          )
                        "
                        class="vehicle-link"
                        >{{ row.item.address }}</a
                      >
                      <input
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        class="form-control form-control-sm"
                        type="text"
                        style="width: 150px"
                        v-model="row.item.address"
                      />
                      <p v-if="(typeof row.item.address !== 'string')">UNKNOWN</p>
                    </template>

                    <template #cell(edit)="row">
                      <i
                        v-if="
                          (subaction !== 'editContact' ||
                          editIndex !== row.item.contactid) && 
                          ($globals.canAccess.admin || $globals.canAccess.sales || $globals.canAccess.dispatching || $globals.canAccess.sales)
                        "
                        class="fas fa-edit"
                        size="sm"
                        @click.stop="editContact(row.item)"
                      ></i>
                      <span v-if="subaction !== 'editContact' || editIndex !== row.item.contactid"> &nbsp; </span>
                      <i
                        v-if="
                          (subaction !== 'editContact' ||
                          editIndex !== row.item.contactid) &&
                          ($globals.canAccess.admin || $globals.canAccess.sales || $globals.canAccess.dispatching || $globals.canAccess.sales)
                        "
                        class="fas fa-trash"
                        size="sm"
                        @click.stop="removeContact(row.item)"
                      ></i>
                      <i v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        " class="fas fa-check" @click.prevent="updateContact(row.item); subaction=''" style="margin-left: 1%; display: inline;"></i>
                      <i v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        " class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 15%; display: inline;"></i>
                    </template>
                  </b-table>
                </b-tab>

                <b-tab title="Firehouses" :title-link-class="'text-danger'">
                  <b-table
                    :items="allHouses"
                    :fields="[
                      {
                        key: 'name',
                        sortable: true,
                        thStyle: 'font-size:12pt',
                      },
                      { key: 'address', thStyle: 'font-size:12pt' },
                      { key: 'access', thStyle: 'font-size:12pt' },
                      { key: 'edit', label: '' },
                    ]"
                    style="font-size: 10pt"
                  >
                    <template #cell(address)="row">
                      <a
                        v-if="
                          subaction !== 'editHouse' ||
                          editIndex !== row.item.houseid
                        "
                        :href="`https://www.google.com/maps/dir/?api=1&destination=${row.item.address.replace(
                          ' ',
                          '+'
                        )}`"
                        class="vehicle-link"
                        >{{ row.item.address }}</a
                      >
                      <input
                        v-if="
                          subaction === 'editHouse' &&
                          editIndex === row.item.houseid
                        "
                        type="text"
                        v-model="row.item.address"
                      />
                    </template>

                    <template #cell(name)="row">
                      <p
                        v-if="
                          subaction !== 'editHouse' ||
                          editIndex !== row.item.houseid
                        "
                      >
                        {{ row.item.name }}
                      </p>
                      <input
                        v-if="
                          subaction === 'editHouse' &&
                          editIndex === row.item.houseid
                        "
                        type="text"
                        v-model="row.item.name"
                      />
                    </template>

                    <template #cell(access)="row">
                      <p
                        v-if="
                          subaction !== 'editHouse' ||
                          editIndex !== row.item.houseid
                        "
                      >
                        {{ row.item.access }}
                      </p>
                      <input
                        v-if="
                          subaction === 'editHouse' &&
                          editIndex === row.item.houseid
                        "
                        type="text"
                        v-model="row.item.access"
                      />
                    </template>

                    <template #cell(edit)="row">
                      <i
                        v-if="
                          (subaction !== 'editHouse' ||
                          editIndex !== row.item.houseid) &&
                          ($globals.canAccess.admin || $globals.canAccess.dispatching)
                        "
                        class="fas fa-edit"
                        size="sm"
                        @click.stop="editHouse(row.item)"
                      ></i>
                      &nbsp;
                      <i
                        v-if="
                          (subaction !== 'editHouse' ||
                          editIndex !== row.item.houseid) &&
                          $globals.canAccess.admin
                        "
                        class="fas fa-trash"
                        size="sm"
                        @click.stop="removeHouse(row.item)"
                      ></i>
                      <i v-if="
                          subaction === 'editHouse' &&
                          editIndex === row.item.houseid
                        " class="fas fa-check" @click.prevent="updateFirehouse(row.item); subaction=''" style="margin-left: 1%; display: inline;"></i>
                      <i v-if="
                          subaction === 'editHouse' &&
                          editIndex === row.item.houseid
                        " class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 25%; display: inline;"></i>
                    </template>
                  </b-table>
                </b-tab>

				<b-tab title="Apparatus" :title-link-class="'text-danger'">
				  <b-table
					:items="allVehicles[lastSelectedIndex]"
					:fields="[
					  { key: 'make', thStyle: 'font-size: 12pt' },
					  { key: 'model', thStyle: 'font-size: 12pt' },
					  { key: 'year', thStyle: 'font-size: 12pt'},
					  {
						key: 'vehicleid',
						label: 'Vehicle Id',
						sortable: true,
						thStyle: 'font-size: 12pt; width: 25%',
					  },
					  {
						key: 'vin',
						sortable: true,
						thStyle: 'font-size: 12pt',
					  },
					  { key: 'address', label: 'Location', thStyle: 'font-size: 12pt' },
					  { key: 'edit', label: '' },
					]"
					style="font-size: 10pt"
				  >
					<template #cell(vehicleid)="row">
					  <span
						class="vehicle-link"
						@click="viewVehicleHistory(row.item)"
						>{{ row.item.vehicleid }}</span
					  >
					</template>

					<template #cell(address)="row">
					  <a
						v-if="subaction !== 'editVehicle' && (typeof row.item.address === 'string')"
						:href="`https://www.google.com/maps/dir/?api=1&destination=${row.item.address.replace(
						  ' ',
						  '+'
						)}`"
						class="vehicle-link"
						>{{ row.item.address }}</a
						>
						<p v-else-if="(typeof row.item.address !== 'string')">UNKNOWN</p>
					</template>
				  </b-table>
				</b-tab>

                <b-tab title="Other Assets" :title-link-class="'text-danger'">
                  <b-table
                    :items="allAssets"
                    :fields="[
                      { key: 'asset_type', thStyle: 'font-size: 12pt' },
                      { key: 'id', thStyle: 'font-size: 12pt' },
                      { key: 'location', thStyle: 'font-size: 12pt' },
                    ]"
                    style="font-size: 10pt"
                  > <template #cell(id)="row">
                      <span
                        class="asset-link"
                        @click="viewAsset(row.item)"
                        >{{ row.item.id }}</span
                      >
                    </template>
                    <template #cell(mfg_date)="row">
						<div v-if="row.item.mfg_date !== null">
							{{row.item.mfg_date.split('T')[0]}}
						</div>
						<div v-else>
							{{''}}
						</div>
                    </template>
                  </b-table>
                </b-tab>

                <b-tab title="Contracts" :title-link-class="'text-danger'">
                  <b-tabs fill lazy>
                    <b-tab title="Active" :title-link-class="'text-danger'">
                      <b-table
                        :items="activeMaintenanceContracts"
                        :fields="[
                          { key: 'contractName', thStyle: 'font-size:12pt' },
                          { key: 'contractid', label: 'Contract Id', sortable: true, thStyle: 'font-size:12pt' },
                          { key: 'value', thStyle: 'font-size:12pt' },
                          { key: 'start', sortable: true, thStyle: 'font-size:12pt' },
                          { key: 'end', sortable: true, thStyle: 'font-size:12pt;' }
                        ]"
                        style="font-size: 10pt; width: 95%"
                        @row-clicked="getSelectedContract"
                      >
                      </b-table>
                        <template #cell(contractName)="row">
                          <span
                            >{{ row.item.contractName }}</span>
                        </template>
                        <template #cell(contractid)="row">
                          <span
                            >{{ row.item.contractid }}</span>
                        </template>
                        <template #cell(value)="row">
                          <span>{{ row.item.value }}</span>
                        </template>
                        <template #cell(start)="row">
                          <span>{{ row.item.start }}</span>
                        </template>
                        <template #cell(end)="row">
                          <span>{{ row.item.end }}</span>
                        </template>
                    </b-tab>
                    <b-tab title="Expired" :title-link-class="'text-danger'">
                      <b-table
                        :items="expiredMaintenanceContracts"
                        :fields="[
                          { key: 'contractName', thStyle: 'font-size:12pt' },
                          { key: 'contractid', label: 'Contract Id', sortable: true, thStyle: 'font-size:12pt' },
                          { key: 'value', thStyle: 'font-size:12pt' },
                          { key: 'start', sortable: true, thStyle: 'font-size:12pt' },
                          { key: 'end', sortable: true, thStyle: 'font-size:12pt;' }
                        ]"
                        style="font-size: 10pt; width: 95%"
                        @row-clicked="getSelectedContract"
                      >
                      </b-table>
                        <template #cell(contractName)="row">
                          <span
                            >{{ row.item.contractName }}</span>
                        </template>
                        <template #cell(contractid)="row">
                          <span
                            >{{ row.item.contractid }}</span>
                        </template>
                        <template #cell(value)="row">
                          <span>{{ row.item.value }}</span>
                        </template>
                        <template #cell(start)="row">
                          <span>{{ row.item.start }}</span>
                        </template>
                        <template #cell(end)="row">
                          <span>{{ row.item.end }}</span>
                        </template>
                    </b-tab>
                  </b-tabs>
                </b-tab>

                <b-tab title="Equipment" :title-link-class="'text-danger'">
                  <b-table
                    :items="allEquipment[lastSelectedIndex]"
                    :fields="[
                      { key: 'item' },
                      { key: 'description' },
                      { key: 'vendor' },
                    ]"
                  >
                    <template #cell(vendor)="row">
                      {{ row.item.vendor.company }}
                    </template>
                  </b-table>
                </b-tab>

                <b-tab title="Notes" :title-link-class="'text-danger'" >
                  <div style="overflow-y: scroll height: 350px">
                    <h5>Pinned Notes</h5>
                    <!--B-card Pinned Notes Section -->
                    <b-row
                      class="text-center"
                      style="padding: 5px"
                      v-for="note in allPinnedNotes[lastSelectedIndex]"
                      :key="note.noteid"
                    >
                      <b-col></b-col>
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        cols="10"
                        >{{ note.note }} - &nbsp;
                        <span class="vehicle-link">{{ typeof note.author === 'object' ? note.author.name : JSON.parse(note.author).name}}</span>&nbsp;
						<img class="note-pfp"
                          :src="
                            JSON.parse(note.author).pic
                              ? JSON.parse(note.author).pic
                              : ''
                          "
                      /></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        cols="9"
                      >
                        <textarea
                          style="width: 80%"
                          v-model="note.note"
                        ></textarea>
                      </b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><b-form-checkbox
                          id="pinned_edit"
                          v-model="note.pinned"
                          value="1"
                          unchecked-value="0"
                          style="margin: auto 1%"
                          >Pinned</b-form-checkbox
                        ></b-col
                      >
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        ><i
                          class="fas fa-edit"
                          size="sm"
                          @click.stop="editNote(note)"
                        ></i>
                        <i v-if="canAccess.admin === true"
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeNote(note)"
                          style="margin-left: 5%; display: inline;"
                        ></i
                      ></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><i
                          class="fas fa-check"
                          size="sm"
                          @click.stop="updateNote(note)"
                        ></i>
                        <i class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 5%; display: inline;"></i>
                      </b-col>
                    </b-row>
                    <hr>
                    <!-- Regular Notes -->
                    <h5>All Notes</h5>
                    <b-row
                      class="text-center"
                      style="padding: 5px"
                      v-for="note in allNotes[lastSelectedIndex]"
                      :key="note.noteid"
                    >
                      <b-col></b-col>
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        cols="10"
                        >{{ note.note }} - &nbsp;
                        <span class="vehicle-link">{{
                          JSON.parse(note.author).name
                            ? JSON.parse(note.author).name
                            : ""
                        }}</span
                        >&nbsp;<img
                          class="note-pfp"
                          :src="
                            JSON.parse(note.author).pic
                              ? JSON.parse(note.author).pic
                              : ''
                          "
                      /></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        cols="9"
                      >
                        <textarea
                          style="width: 80%"
                          v-model="note.note"
                        ></textarea>
                      </b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><b-form-checkbox
                          id="pinned_edit"
                          v-model="note.pinned"
                          value="1"
                          unchecked-value="0"
                          style="margin: auto 1%"
                          >Pinned</b-form-checkbox
                        ></b-col
                      >
                      <b-col
                        v-if="
                          (subaction !== 'editNote' || editIndex !== note.noteid)
                          &&
                          ($globals.canAccess.admin || $globals.canAccess.dispatching)
                        "
                        ><i
                          class="fas fa-edit"
                          size="sm"
                          @click.stop="editNote(note)"
                        ></i>
                        <i
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeNote(note)"
                          style="margin-left: 5%; display: inline;"
                        ></i
                      ></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><i
                          class="fas fa-check"
                          size="sm"
                          @click.stop="updateNote(note)"
                        ></i>
                        <i class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 5%; display: inline;"></i>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>

        <div class="row" style="width: 98%; margin-left: 0%">
          <div class="col">
            <div class="card card-danger mt-4 ml-4">
              <div class="card-header">
                <div class="card-title">
                  <h5><b>Current Leads</b></h5>
                </div>
              </div>
              <div
                class="card-body"
                style="max-height: 250px; overflow-y: scroll"
              >
                <b-table
                  sort-by="localid"
                  :sort-desc="true"
                  :items="allLeads[lastSelectedIndex]"
                  :fields="[
                    { key: 'salesman', thStyle: 'font-size:12pt' },
                    {
                      key: 'leads',
                      label: 'Description',
                      thStyle: 'width: 50%; font-size:12pt',
                    },
                    { key: 'tags', thStyle: 'font-size:12pt' },
                  ]"
                  style="font-size: 9pt"
                >
                  <template #cell(tags)="row" style="display: flex">
                    <div
                      v-for="(tag, index) in JSON.parse(row.item.tags)"
                      :key="index"
                      :style="
                        'padding:10px; border-radius:10px;' +
                        `background-color: ${tag.color};` +
                        'color: white; text-align: center; font-weight: bold;'
                      "
                    >
                      {{ tag.tag }}
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-danger mt-4 ml-4">
              <div class="card-header">
                <div class="card-title">
                  <h5><b>Recent Sales</b></h5>
                </div>
              </div>
              <div
                class="card-body"
                style="max-height: 250px; overflow-y: scroll"
              >
                <b-table
                  sort-by="starts"
                  :sort-desc="true"
                  :items="allSales[lastSelectedIndex]"
                  :fields="[
                    { key: 'salesid', label: 'ID', thStyle: 'font-size:12pt' },
                    { key: 'salesman', thStyle: 'font-size:12pt' },
                    { key: 'starts', label: 'Date', thStyle: 'font-size:12pt' },
                  ]"
                  style="font-size: 9pt"
                ></b-table>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card card-danger mt-4 ml-4">
              <div class="card-header">
                <div class="card-title">
                  <h5><b>Recent Work Orders</b></h5>
                </div>
              </div>
              <div
                class="card-body"
                style="max-height: 250px; overflow-y: scroll"
              >
                <b-table
                  :items="allTickets[lastSelectedIndex]"
                  :fields="[
                    {
                      key: 'asset',
                      label: 'Assets',
                      thStyle: 'font-size:12pt',
                    },
                    {
                      key: 'start_date',
                      sortDirection: 'desc',
                      thStyle: 'font-size:12pt',
                    },
                    {
                      key: 'end_date',
                      sortDirection: 'desc',
                      thStyle: 'font-size:12pt',
                    },
                  ]"
                  sort-by="start_date"
                  :sort-desc="true"
                  style="font-size: 9pt"
                >
                  <template #cell(start_date)="row">
                    {{ typeof row.item.start_date === 'string' ? new Date(row.item.start_date.slice(0,-2)).toLocaleString() : 'Unscheduled' }}
                  </template>

                  <template #cell(end_date)="row">
                    {{ typeof row.item.end_date === 'string' ? new Date(row.item.end_date.slice(0,-2)).toLocaleString() : 'Unscheduled' }}
                  </template>

                  <template #cell(asset)="row">
                    <div v-for="(asset, index) in (typeof row.item.asset === 'object' ? row.item.asset : JSON.parse(row.item.asset))" :key="index"
                      class="vehicle-link"
                      @click="viewTicketDetails(row.item, 'searching')"
                      >{{ asset }}</div
                    >
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END OF DASHBOARD TEMPLATE -->

    <div v-if="action === 'viewTicket'">
        <viewTicket :selectedTicket="selectedTicket" @return="action = 'searching'" />
    </div>

    <!-- New Contact -->
    <div v-if="action === 'newContact'" style="padding-bottom: 1%">
        <newContact :source="selectedCustomer" @return="action = 'searching'; rowClicked(originalCustomer)" />
    </div>

    <!-- New Firehouse -->
    <div v-if="action === 'newHouse'">
		<firehouse :customer="selectedCustomer" @cancelHouse= "action ='searching'" @accept="getHouses(selectedCustomer); action='searching'">
		</firehouse>
    </div>

    <!-- New Asset -->
    <div v-if="action === 'newAsset'" style="padding-bottom:1%">
      <newAsset :selectedCustomer="selectedCustomer" :user="user" :companyId="companyId" @return="handleNewAsset"></newAsset>
    </div>

    <!-- New Vehicle -->
    <div v-if="action === 'newVehicle'" style="padding-bottom: 1%">
        <newVehicle :selectedCustomer="selectedCustomer" @return="action = 'searching'; rowClicked(originalCustomer)" />
    </div>

    <!-- New Equipment -->
    <div v-if="action === 'newEquipment'" class="card card-danger" style="padding-bottom: 1%">
      <div class="card-header">
        <div class="card-title">
          <h3><b>New Equipment</b></h3>
        </div>
        <div class="card-tools">
          <b-button @click="updateEquipment(selectedCustomer)" style="background-color: #fff4; border-color: #dc3545;">Submit</b-button>
          <b-button @click="action = 'searching'" style="background-color: #fff4; border-color: #dc3545;">Return</b-button>
        </div>
      </div>
      <div class="card-body">
		<label for="e_type" class="w-75 text-left mt-2" style="margin-left: 13.5%">Type/Make<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
		<input id="e_type" type="text" class="form-control w-75" style="margin-left:12.5%;" v-model="newEquipment.item" placeholder="Type/Make" required />
		<label for="e_desc" class="w-75 text-left mt-2" style="margin-left: 13.5%">Info/Details<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
		<input id="e_desc" type="text" class="form-control w-75" style="margin-left:12.5%;" v-model="newEquipment.description" placeholder="Info/Details" required />
		<label for="e_vend" class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
		<multiselect id="e_vend" :options="allVendors" label="company" track-by="company" class="w-75" style="margin-left:12.5%;" v-model="newEquipment.vendor" placeholder = "Vendor" :show-labels="false" required>
			<template slot="option" slot-scope="props">
				<intersect v-if="props.option.company === allVendors[allVendors.length - 1].company" @enter="getMoreVendors">
					<span>{{props.option.company}}</span>
				</intersect>
			</template>
		</multiselect>
	  </div>
    </div>

    <!-- New Note -->
    <div v-if="action === 'newNote'" class="card card-danger" style="padding-bottom: 1%">
      <div class="card-header">
        <div class="card-title">
          <h3><b>New Note</b></h3>
        </div>
        <div class="card-tools">
          <b-button style="background-color: #fff4; border-color: #dc3545; margin-left:-5%;" @click.prevent="pushNote(selectedCustomer)">Submit</b-button>
          <b-button style="background-color: #fff4; border-color: #dc3545; margin-left:1%;" @click.prevent="action = 'searching'">Return</b-button>
        </div>
      </div>
      <div class="card-body">
        <div style="display: flex; justify-content: center">
          <textarea
            placeholder="Note on Customer*"
            v-model="newNote.note"
            style="width: 300px; margin: auto 1%"
            required
            v-b-tooltip.hover title="Required Field"
          ></textarea>
          <input type="checkbox" id="pinned" v-model="newNote.pinned" value="1" unchecked-value="0" style="margin: auto 1%"  />
          <label for="pinned" style="margin-top: 1.5%">Pinned</label>
        </div>
      </div>
    </div>

	<!-- VIEW ASSET SECTION -->
    <div style="width: 98%; margin-left: 0%; padding-bottom: 1%" v-if="action === 'viewAsset'">
        <viewAsset :selectedAsset="selectedAsset" :selectedCustomer="selectedCustomer" @return="rowClicked(originalCustomer); action = 'searching'"></viewAsset>
    </div>

    <!--Add New Customer Section -->

    <div style="margin-top: 1%; padding-bottom: 1%" v-if="action === 'createCustomer'">
      <newCustomer @return="action='searching'"></newCustomer>
    </div>

	<!-- Vehicle History -->
    <div v-if="action == 'vehicleHistory'">
        <viewVehicle :passedVehicle="selectedVehicle" :selectedCustomer="selectedCustomer" @return="action = 'searching'; rowClicked(originalCustomer)"/>
    </div>

    <!-- Edit Customer Section -->

    <div style="margin-top: 1%; padding-bottom: 1%" v-show="action == 'editInfo'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>Edit Customer</b></h3>
          </div>
          <div class="card-tools">
            <b-button style="background-color: #fff4; border-color: #dc3545;"
              @click.prevent="checkEditAddress()"
              >Submit</b-button
            >
            <b-button style="background-color: #fff4; border-color: #dc3545;"
              @click.prevent="action = 'searching'"
              >Return</b-button
            >
          </div>
        </div>
        <div class="card-body">
          <div class="row d-flex">
            <div class="col" style="margin-left: 10%">
              <label for="cust_name" class="w-75 text-left ml-n5 mt-2">Name</label>
              <input
                id="cust_name"
                type="text"
                class="form-control w-75"
                placeholder="Customer Name"
                v-model="editCustomer.name"
              />
              <label for="cust_ship" class="w-75 text-left ml-n5 mt-2">Shipping Address</label>
              <input
                id="cust_ship"
                type="text"
                class="form-control w-75"
                placeholder="Street"
                v-model="editCustomer.shipping.street1"
              />
              <input
                type="text"
                class="form-control w-75"
                placeholder="Apt / Suite"
                v-model="editCustomer.shipping.street2"
              />
              <input
                type="text"
                class="form-control w-75"
                placeholder="City"
                v-model="editCustomer.shipping.city"
              />
              <multiselect @input="updateShipState" class="w-75" :options="$globals.allStates" track-by="abbreviation" label="name" v-model="editCustomer.shipping.state" placeholder="State" :show-labels="false"/>
              <input
                type="text"
                class="form-control w-75"
                placeholder="Zip"
                v-model="editCustomer.shipping.zip"
              />
            </div>
            <div class="col" style="margin-left: 10%">
              <label for="cust_name" class="w-75 text-left ml-n5 mt-2">Billing Name</label>
                <input
                  id="cust_name"
                  type="text"
                  class="form-control w-75"
                  placeholder="Billing Name"
                  v-model="editCustomer.billing_name"
                />
              <label for="cust_bill" class="w-75 text-left ml-n5 mt-2">Billing Address</label>
              <input
                id="cust_bill"
                type="text"
                class="form-control w-75"
                placeholder="Street"
                v-model="editCustomer.billing.street1"
              />
              <input
                type="text"
                class="form-control w-75"
                placeholder="Apt / Suite"
                v-model="editCustomer.billing.street2"
              />
              <input
                type="text"
                class="form-control w-75"
                placeholder="City"
                v-model="editCustomer.billing.city"
              />
              <multiselect @input="updateBillState" class="w-75" :multiple="false" v-model="editCustomer.billing.state" placeholder="State" :options="$globals.allStates" track-by="abbreviation" label="name" :show-labels="false"/>
              <input
                type="text"
                class="form-control w-75"
                placeholder="Zip"
                v-model="editCustomer.billing.zip"
              />
            </div>
          </div>
          <label for="cust_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone Number</label>
            <input
              id="cust_phone"
              type="text"
              class="form-control"
              placeholder="Phone Number"
              v-model="editCustomer.phone"
            style="margin-left: 20%; width: 60%"
            />
          <label for="cust_tax" class="w-75 text-left mt-2" style="margin-left: 13.5%">Tax Exempt Number</label>
          <input
            id="cust_tax"
            type="text"
            class="form-control"
            placeholder="Tax Exempt Number"
            v-model="editCustomer.tax"
            style="margin-left: 20%; width: 60%"
          />
          <label for="efiling" class="w-75 text-left mt-2" style="margin-left: 13.5%">Electronic Billing</label>
          <multiselect
            id="efiling"
            :clearable="true"
            :searchable="false"
            :options="['0', '1', '2']"
            v-model="editCustomer.electronic"
            placeholder="Electronic Invoicing"
            style="margin-left: 20%; width: 60%"
            :show-labels="false"
          >
          <template slot="option" slot-scope="prop">
            {{ prop.option === "1" ? "Yes" : prop.option === "2" ? "Both" : "No" }}
          </template>
          </multiselect><br />
        </div>
      </div>
    </div>

	<div v-if="action === 'viewMaintenanceContract'">
		<viewMaintenanceContract :selectedCustomer="selectedCustomer" :contractNumber="selectedContract.contractid" :canAccess="canAccess" @reject="action='searching'"></viewMaintenanceContract>
	</div>

	<div v-if="action === 'newMaintenanceContract'">
		<newMaintenanceContract :selectedCustomer="selectedCustomer" @reject="getMaintenanceContracts(); action='searching'"></newMaintenanceContract>
	</div>

</div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import { Socket } from "socket.io-client";
import Intersect from 'vue-intersect';
import { debounce } from 'lodash';
import firehouse from './Customers/firehouse.vue'
import newAsset from './Customers/newAsset.vue'
import viewAsset from './Customers/viewAsset.vue'
import newVehicle from './Customers/newVehicle.vue'
import viewVehicle from './Customers/viewVehicle.vue'
import newContact from './Customers/newContact.vue'
import viewTicket from './Service/viewTicket.vue'
import viewMaintenanceContract from './MaintenanceContracts/viewMaintenanceContract.vue'
import newMaintenanceContract from './MaintenanceContracts/newMaintenanceContract.vue'
import newCustomer from './Customers/newCustomer.vue'


export default {
  name: "app",
  props: {
    socket: Socket,
    companyId: Number,
    user: Object,
    userRoles: Array,
    canAccess: Object,
    qbo: Boolean,
  },

  components: { Multiselect,
	  Intersect, firehouse, newAsset, viewAsset, newVehicle, viewVehicle, newContact, viewTicket, viewMaintenanceContract, newMaintenanceContract,
	  newCustomer,
   },

  data() {
    return {
		action: "searching",
      allAssets: [],
      allContacts: [],
      allCustomers: [],
      allEquipment: [],
      allHouses: [],
      allLeads: [],
      activeMaintenanceContracts: [],
      allNotes: [],
      allPinnedNotes: [],
      allSales: [],
      allTickets: [],
      allVehicles: [],
      allVendors: [],
	  expiredMaintenanceContracts: [],
      editIndex: "",
      editCustomer: {
		  name: "",
		  shipping: {
			  street1: '',
			  street2: '',
			  city: '',
			  state: '',
			  zip: ''
		  },
		  billing: {
			  street1: '',
			  street2: '',
			  city: '',
			  state: '',
			  zip: ''
		  },
		  tax_exempt: "",
		  picture: "",
		  electronic: "",
      },
      isBusy: false,
      lastSelectedIndex: 0,
      originalCustomer: {},
      newContact: {
        address: {
          street: '',
          city: '',
          state: '',
          zip: ''
        }
      },
      newCustomer: {
        name: "",
        shippingAddress: "",
        shipping: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: ''
        },
        billing: {
          street1: '',
          street2: '',
          city: '',
          state: '',
          zip: ''
        },
        tax_exempt: "",
        picture: "",
        electronic: "",
      },
      newEquipment: {
        item: "",
        description: "",
        vendor: "",
      },
      newNote: {
        note: "",
        pinned: 0
      },
      returnView: 'searching',
      selectedVehicle: {},
      selectedContract: {},
      selectedCustomer: {},
      selectedTab: null,
      selectedTicket: {},
	  searchedCustomer: {},
      subaction: "",
      totalsArray: [],
      maxPages: 0,
      pageNumber: 0,
      prevSearch: "",
      customerListIsLoading: false,
      vendorListIsLoading: false,
      pageNumberVendors: 0,
      maxPagesVendors: 0,
      prevSearchVendors: "",
    };
  },
  computed: {
    buttonString: function(){
      switch(this.selectedTab){
        case 0:
          return "New Contact"
        case 1:
          return "New Firehouse"
		case 2:
		  return "New Apparatus";
        case 3:
          return "New Asset";
        case 4:
          return "New Maintenance Contract";
        case 5:
          return "New Equipment";
        case 6:
          return "New Note";
        default:
          return;
      }
    },
  },

  created() {
    this.$globals.socket.on("newCustomerData", async function (message, data) {
        this.isBusy = true
		console.log("Customers heard newDataCreated");
        this.rowClicked(this.selectedCustomer)
        this.isBusy = false;
    });
    this.debounceCustomers = debounce(this.searchCustomers, 600);
  },

  async mounted() {
    this.isBusy = true;

	//TODO if there are not any Customers yet?
    await this.getCustomers()
    this.selectedCustomer = this.allCustomers[0];
	this.searchedCustomer = this.selectedCustomer;

    this.rowClicked(this.selectedCustomer);

    this.isBusy = false;
  },

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `customers${this.$globals.companyId}`);
        this.$globals.socket.off("newCustomerData")
    },

  methods: {

    async getCustomers(){
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allCustomers = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getMoreCustomers(){
      if(this.pageNumber >= this.maxPages){
        return
      }
      let text = this.prevSearch === "" ? text = null : text = this.prevSearch
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        for(var item in response.data.queryResult.results){
          this.allCustomers.push(response.data.queryResult.results[item])
        }
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getSelectedContract(row){
      this.selectedContract = row
      this.action = 'viewMaintenanceContract'
      this.$forceUpdate()
    },

    async searchCustomers(searchValue){
      if(searchValue === "" || searchValue === null){
        if(this.prevSearch == "" || this.prevSearch === null){
          this.getCustomers();
        }
        searchValue = null
      }
      this.customerListIsLoading = true
      this.pageNumber = 0
      this.prevSearch = searchValue
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allCustomers = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
		/*
		if(this.selectedCustomer == null){
			this.selectedCustomer = this.allCustomers[0]
		}
		*/
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.customerListIsLoading = false
    },

    async getVendors(){
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getMoreVendors(){
      if(this.pageNumber >= this.maxPages){
        return
      }
      let text = this.prevSearchVendors === "" ? text = null : text = this.prevSearchVendors
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        for(var item in response.data.queryResult.results){
          this.allVendors.push(response.data.queryResult.results[item])
        }
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async searchVendors(searchValue){
      if(searchValue === ""){
        searchValue = null
      }
      this.allVendors = []
      this.vendorListIsLoading = true
      this.pageNumberVendors = 0
      this.prevSearchVendors = searchValue
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPagesVendors = response.data.queryResult.maxPages
        this.pageNumberVendors = this.pageNumberVendors+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.vendorListIsLoading = false
    },

	checkEditAddress(){
		var shipAddressObj, billAddressObj;

		//Empty Shipping
		if(
			(this.editCustomer.shipping.street1 === null || this.editCustomer.shipping.street1 === "") &&
			(this.editCustomer.shipping.city === null || this.editCustomer.shipping.city === "") &&
			(this.editCustomer.shipping.state === null || this.editCustomer.shipping.state === "") &&
			(this.editCustomer.shipping.zip === null || this.editCustomer.shipping.zip === "")
		){
			shipAddressObj = {
				company: this.editCustomer.name,
				name: this.editCustomer.name,
			};
			this.editCustomer.shippingAddress = ""
		}

		//Not empty shipping
		else if(
			(this.editCustomer.shipping.street1 !== null && this.editCustomer.shipping.street1 !== "") &&
			(this.editCustomer.shipping.city !== null && this.editCustomer.shipping.city !== "") &&
			(this.editCustomer.shipping.state !== null && this.editCustomer.shipping.state !== "") &&
			(this.editCustomer.shipping.zip !== null && this.editCustomer.shipping.zip !== "")
		){
			if (this.editCustomer.shipping?.street2 !== undefined && this.editCustomer.shipping?.street2 !== '' && this.editCustomer.shipping?.street2 !== null ) {
				shipAddressObj = {
            		street1: this.editCustomer.shipping.street1,
					street2: this.editCustomer.shipping.street2,
					city: this.editCustomer.shipping.city,
					state: this.editCustomer.shipping.state.abbreviation,
					zip: this.editCustomer.shipping.zip,
					country: "US",
					company: this.editCustomer.name,
					name: this.editCustomer.name,
				};

				this.editCustomer.shippingAddress = this.editCustomer.shipping.street1 +", " +this.editCustomer.shipping.street2 +", " +this.editCustomer.shipping.city +", " +this.editCustomer.shipping.state.abbreviation +" " +this.editCustomer.shipping.zip
			}
			else{
				shipAddressObj = {
					street1: this.editCustomer?.shipping?.street1,
					city: this.editCustomer?.shipping?.city,
					state: this.editCustomer?.shipping?.state?.abbreviation,
					zip: this.editCustomer?.shipping?.zip,
					country: "US",
					company: this.editCustomer.name,
					name: this.editCustomer.name,
				};
				this.editCustomer.shippingAddress = this.editCustomer?.shipping?.street1 +", " +this.editCustomer?.shipping?.city +", " +this.editCustomer?.shipping?.state?.abbreviation +" " +this.editCustomer?.shipping?.zip
			}
		}
		else{
			alert("Shipping address must be a blank or complete address")
			return;
		}

		//Empty Billing
		if(
			(this.editCustomer.billing.street1 === null || this.editCustomer.billing.street1 === "") &&
			(this.editCustomer.billing.city === null || this.editCustomer.billing.city === "") &&
			(this.editCustomer.billing.state === null || this.editCustomer.billing.state === "") &&
			(this.editCustomer.billing.zip === null || this.editCustomer.billing.zip === "")
		){
			billAddressObj = {
				company: this.editCustomer.name,
				name: this.editCustomer.billing_name,
			};
			this.editCustomer.billingAddress = ""
		}

		//Not Empty Billing
		else if(
			(this.editCustomer.billing.street1 !== null && this.editCustomer.billing.street1 !== "") &&
			(this.editCustomer.billing.city !== null && this.editCustomer.billing.city !== "") &&
			(this.editCustomer.billing.state !== null && this.editCustomer.billing.state !== "") &&
			(this.editCustomer.billing.zip !== null && this.editCustomer.billing.zip !== "")
		){
			if (this.editCustomer.billing.street2 !== undefined && this.editCustomer.billing.street2 !== '' && this.editCustomer.billing.street2 !== null) {
				billAddressObj = {
					street1: this.editCustomer.billing.street1,
					street2: this.editCustomer.billing.street2,
					city: this.editCustomer.billing.city,
					state: this.editCustomer.billing.state?.abbreviation,
					zip: this.editCustomer.billing.zip,
					country: "US",
					company: this.editCustomer.name,
					name: this.editCustomer.billing_name,
				};
				this.editCustomer.billingAddress = this.editCustomer.billing.street1 + ", " + this.editCustomer.billing.street2 + ", " + this.editCustomer.billing.city + ", " + this.editCustomer.billing.state?.abbreviation + " " + this.editCustomer.billing.zip
			}
			else{
				billAddressObj = {
					street1: this.editCustomer?.billing?.street1,
					city: this.editCustomer?.billing?.city,
					state: this.editCustomer?.billing?.state?.abbreviation,
					zip: this.editCustomer?.billing?.zip,
					country: "US",
					company: this.editCustomer.name,
					name: this.editCustomer.billing_name,
				};
				this.editCustomer.billingAddress = this.editCustomer.billing.street1 + ", " + this.editCustomer.billing.city + ", " + this.editCustomer.billing.state?.abbreviation + " " + this.editCustomer.billing.zip
			}
		}
		else{
			alert("Billing address must be a blank or complete address")
			return;
		}
		this.updateCustomer(shipAddressObj, billAddressObj)
    },

    editContact(contact) {
      this.editIndex = contact.contactid;
      this.subaction = "editContact";
    },

	editCustomerInfo() {
		//TODO Make more reslilent to not crap out if the spaces are wrong from an import
		//ALSO fix import to not cause this to have issues.
      this.editCustomer = {...this.selectedCustomer};

      var secondShippingSplit = "";
      var secondBillingSplit = "";
      var billingSplit = this.selectedCustomer.billing?.split(', ')
      if(billingSplit.length === 4){
        this.editCustomer.billing = {}
        this.editCustomer.billing.street1 = billingSplit[0]
        this.editCustomer.billing.street2 = billingSplit[1]
        this.editCustomer.billing.city = billingSplit[2]
        secondBillingSplit = billingSplit[3].split(' ')
        for(let i in this.$globals.allStates){
          if(this.$globals.allStates[i].abbreviation === secondBillingSplit[0]){
            this.editCustomer.billing.state = this.$globals.allStates[i];
            break;
          }
        }
        this.editCustomer.billing.zip = secondBillingSplit[1]
      }
      else if(billingSplit.length === 3){
        this.editCustomer.billing = {}
        this.editCustomer.billing.street1 = billingSplit[0]
        this.editCustomer.billing.city = billingSplit[1]
        secondBillingSplit = billingSplit[2].split(' ')
        for(let i in this.$globals.allStates){
          if(this.$globals.allStates[i].abbreviation === secondBillingSplit[0]){
            this.editCustomer.billing.state = this.$globals.allStates[i];
            break;
          }
        }
        this.editCustomer.billing.zip = secondBillingSplit[1]
      }
	  else{
		  this.editCustomer.billing = {}
		  this.editCustomer.billing.street1 = billingSplit.toString()
		  this.editCustomer.billing.street2 = null
		  this.editCustomer.billing.city = null
		  this.editCustomer.billing.state = null
		  this.editCustomer.billing.zip = null
	  }

      var shippingSplit = this.selectedCustomer.shipping?.split(', ')
      if(shippingSplit.length === 4){
        this.editCustomer.shipping = {}
        this.editCustomer.shipping.street1 = shippingSplit[0]
        this.editCustomer.shipping.street2 = shippingSplit[1]
        this.editCustomer.shipping.city = shippingSplit[2]
        secondShippingSplit = shippingSplit[3].split(' ')
        for(let i in this.$globals.allStates){
          if(this.$globals.allStates[i].abbreviation === secondShippingSplit[0]){
            this.editCustomer.shipping.state = this.$globals.allStates[i];
            break;
          }
        }
        this.editCustomer.shipping.zip = secondShippingSplit[1]
      }
      else if(shippingSplit.length === 3){
        this.editCustomer.shipping = {}
        this.editCustomer.shipping.street1 = shippingSplit[0]
        this.editCustomer.shipping.city = shippingSplit[1]
        secondShippingSplit = shippingSplit[2].split(' ')
        for(let i in this.$globals.allStates){
          if(this.$globals.allStates[i].abbreviation === secondShippingSplit[0]){
            this.editCustomer.shipping.state = this.$globals.allStates[i];
            break;
          }
        }
        this.editCustomer.shipping.zip = secondShippingSplit[1]
      }
	  else{
		  this.editCustomer.shipping = {}
		  this.editCustomer.shipping.street1 = shippingSplit.toString()
		  this.editCustomer.shipping.street2 = null
		  this.editCustomer.shipping.city = null
		  this.editCustomer.shipping.state = null
		  this.editCustomer.shipping.zip = null
	  }

      this.action = "editInfo";
    },

    editNote(note) {
      this.editIndex = note.noteid;
      this.subaction = "editNote";
    },

    editHouse(house) {
      this.editIndex = house.houseid;
      this.subaction = "editHouse";
    },

    async getCustomerName(customerID) {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            this.$globals.companyId +
            "/" +
            customerID,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
            this.custName = response.data.queryResult[0]?.name;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //END getCustomerName()

	async getHouses(option) {
		await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/houses/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
			this.allHouses = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	async getVehicleInfo(vehicleid, customerid){
		await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${encodeURIComponent(vehicleid)}/cust/${customerid}/${this.$globals.companyId}`, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${this.$globals.user.auth0Token}`
			}
		}).then((response) => {
			this.selectedVehicle = response.data.queryResult[0]
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	async getMaintenanceContracts(){
		this.activeMaintenanceContracts = [];
		this.expiredMaintenanceContracts = [];
		await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/customer/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
		})
		.then((response) => {
	        for(let contract in response.data.queryResult){
				if(response.data.queryResult[contract].expired > 0){
					this.expiredMaintenanceContracts.push(response.data.queryResult[contract])
				}
				else{
					this.activeMaintenanceContracts.push(response.data.queryResult[contract])
				}
	        }
	        this.$forceUpdate();
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	async getCustomerAssets(){
		await axios.get(`${process.env.VUE_APP_API_TARGET}/assets/cust/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		)
		.then((response) => {
			this.allAssets = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

    async newCustomerContent(){
      switch(this.selectedTab){
        case 0:
          this.action = 'newContact'
		  this.newContact.address.state = {}

		  let hold = []
		  if(this.selectedCustomer.shipping !== null && this.selectedCustomer.shipping !==''){
			  hold = this.selectedCustomer.shipping.split(',')
		  }
		  else if(this.selectedCustomer.billing !== null && this.selectedCustomer.billing !== ''){
			  hold = this.selectedCustomer.billing.split(',')
		  }
		  else{
			  return;
		  }

		  if(hold.length == 4){
			  this.newContact.address.street = hold[0]
			  this.newContact.address.city = hold[2]
			  this.newContact.address.state.abbreviation = hold[3].split(' ')[1]
			  this.newContact.address.zip = hold[3].split(' ')[2]
		  }
		  else if(hold.length == 3){
			  this.newContact.address.street = hold[0]
			  this.newContact.address.city = hold[1]
			  this.newContact.address.state.abbreviation = hold[2].split(' ')[1]
			  this.newContact.address.zip = hold[2].split(' ')[2]
		  }
		  else{
			  return;
		  }

          return;
        case 1:
          this.action = 'newHouse'
          return;
        case 2:
          this.action = 'newVehicle'
          return;
		case 3:
		  await this.getHouses(this.selectedCustomer)
		  this.action = 'newAsset'
		  return;
        case 4:
          this.action = 'newMaintenanceContract'
          return;
        case 5:
		  await this.getVendors()
		  this.action = 'newEquipment'
          return;
        case 6:
          this.action = 'newNote'
          return;
        default:
          return;
      }
    },

	async handleNewAsset(){
		this.getCustomerAssets();
        this.action = "searching";
    },

	async pushNote(customer) {
		if(this.newNote.note === null || this.newNote.note === ''){
			alert("Fill in note")
			return;
		}
      const body = {
        note: this.newNote.note,
        pinned: this.newNote.pinned ? this.newNote.pinned : "0",
        author: {
          name: this.$auth.user.name,
          pic: this.$auth.user.picture,
        },
      };
      body.author = JSON.stringify(body.author);
      await axios.post(
        process.env.VUE_APP_API_TARGET + "/customers/" +
          customer.customerid +
          "/notes/" +
          this.$globals.companyId,
        body,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            customer.customerid +
            "/notes/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allNotes[this.lastSelectedIndex] =
            response.data.queryResult.reverse();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            customer.customerid +
            "/pinned/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allPinnedNotes[this.lastSelectedIndex] =
            response.data.queryResult.reverse();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.action = "searching";
    }, //END pushNote()

    async removeCustomer(){
      if(confirm(`Are you sure you want to delete ${this.selectedCustomer.name}?`)) {
        await axios.delete(`${process.env.VUE_APP_API_TARGET}/customers/account/${this.selectedCustomer.customerid}/${this.$globals.companyId}`, {
          headers: {
            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
          }
        }).then(async (response) => {
          if(response.data.queryResult){
            alert("Customer Removed")
            this.pageNumber = 0
            await this.getCustomers()
            this.selectedCustomer = this.allCustomers[0].customerid === this.selectedCustomer.customerid ? null : this.allCustomers[0]
            this.rowClicked(this.selectedCustomer);
          }
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      }
    },

    async removeContact(contact) {
      await axios.delete(
        process.env.VUE_APP_API_TARGET + "/customers/" +
          contact.customerid +
          "/contacts/" +
          contact.contactid +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      ).then(async (response) => {
        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" +
              contact.customerid +
              "/contacts/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allContacts[this.lastSelectedIndex] = response.data.queryResult;
            this.$forceUpdate()
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    }, //END removeContact()

    async removeHouse(house) {
      await axios.delete(
        `${process.env.VUE_APP_API_TARGET}/customers/${house.customerid}/house/${house.houseid}/${this.$globals.companyId}`,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      ).then(async (response) => {
		  await this.getHouses(house)
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async removeNote(note) {
      if (note) {
        await axios.delete(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            note.customerid +
            "/notes/" +
            note.noteid +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        ).then(async (response) => {
          await axios
            .get(
              process.env.VUE_APP_API_TARGET + "/customers/" +
                note.customerid +
                "/notes/" +
                this.$globals.companyId,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
            )
            .then((response) => {
              this.allNotes[this.lastSelectedIndex] = response.data.queryResult;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
          await axios
            .get(
              process.env.VUE_APP_API_TARGET + "/customers/" +
                note.customerid +
                "/pinned/" +
                this.$globals.companyId,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
            )
            .then((response) => {
              this.allPinnedNotes[this.lastSelectedIndex] =
                response.data.queryResult;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
          this.$forceUpdate()
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      }
    }, //END removeNote()

    async rowClicked(record) {
        this.$globals.socket.emit("leaveRoom", `customer${this.selectedCustomer.customerid}-${this.$globals.companyId}`)
      if(record === null){
        return
      }
      this.lastSelectedIndex = this.allCustomers.findIndex((element) => {
        return element.customerid === record.customerid;
      });
      this.selectedCustomer = record;
        this.originalCustomer = record;

        this.$globals.socket.emit("joinRoom", `customer${this.selectedCustomer.customerid}-${this.$globals.companyId}`)

      if (!record._showDetails) {
        this.isBusy = true;
        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" +
              record.customerid +
              "/contacts/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allContacts[this.lastSelectedIndex] =
              response.data.queryResult;
            this.allContacts[this.lastSelectedIndex].forEach((contact) => {
              contact.role =
                typeof contact.role !== "object"
                  ? JSON.parse(contact.role)
                  : contact.role;
            });
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" +
              record.customerid +
              "/vehicles/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allVehicles[this.lastSelectedIndex] =
              response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

      	this.getMaintenanceContracts();

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" +
              record.customerid +
              "/notes/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allNotes[this.lastSelectedIndex] =
              response.data.queryResult.reverse();
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" +
              record.customerid +
              "/pinned/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allPinnedNotes[this.lastSelectedIndex] =
              response.data.queryResult.reverse();
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		  await this.getHouses(record)

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/tickets/cust/" +
              encodeURIComponent(record.name) +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allTickets[this.lastSelectedIndex] = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get( //Changed call but not sure what goal was.
            process.env.VUE_APP_API_TARGET + "/sales/sales_orders/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allSales[this.lastSelectedIndex] = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/sales/custleads/" +
              record.customerid +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allLeads[this.lastSelectedIndex] = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/customers/" + record.customerid + "/equipment/" + this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allEquipment[this.lastSelectedIndex] = JSON.parse(response.data.queryResult)
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		  this.getCustomerAssets()

        this.isBusy = false;
      }
    }, //END rowClicked()

    async updateCustomer(shipAddressObj, billAddressObj){
      await axios.patch(`${process.env.VUE_APP_API_TARGET}/customers/account/${this.selectedCustomer.customerid}/${this.$globals.companyId}`, {
        name: this.editCustomer.name,
        shipping: this.editCustomer.shippingAddress,
        billing: this.editCustomer.billingAddress,
        billing_name: this.editCustomer.billing_name || this.editCustomer.name,
        tax_exempt: this.editCustomer.tax,
        electronic: this.editCustomer.electronic,
        qbid: null,
        phone: this.editCustomer.phone
      }, {
        headers: {
            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
        }
      }).then(async (response) => {
          if(!response.data.error){
          alert("Customer data changed!")
          await axios
              .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
              .then((response) => {
                this.allCustomers = response.data.queryResult.results;
                this.action="searching"
                this.selectedCustomer.shipping = this.editCustomer.shippingAddress
                this.selectedCustomer.billing = this.editCustomer.billingAddress
                this.selectedCustomer.electronic = this.editCustomer.electronic
                this.selectedCustomer.sales_balance = this.editCustomer.sales_balance
                this.selectedCustomer.tax = this.editCustomer.tax
                this.selectedCustomer.phone = this.editCustomer.phone
                this.selectedCustomer.name = this.editCustomer.name

				this.editCustomer = {
                    name: "",
                    shipping: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    billing: {
                        street1: '',
                        street2: '',
                        city: '',
                        state: '',
                        zip: ''
                    },
                    tax_exempt: "",
                    picture: "",
                    electronic: "",
                }
              })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
          }
		  this.isBusy = false;
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

		//TODO use shipAddressObj, billAddressObj to update QuickBooks file on this customer
    },

    updateBillState(args){
      this.editCustomer.billing.state.abbreviation = args.abbreviation
      this.editCustomer.billing.state.name = args.name
      this.$forceUpdate()
    },

    updateShipState(args){
      this.editCustomer.shipping.state.abbreviation = args.abbreviation
      this.editCustomer.shipping.state.name = args.name
      this.$forceUpdate()
    },

    async updateContact(contact) {
      await axios.put(
        process.env.VUE_APP_API_TARGET + "/customers/" +
          contact.customerid +
          "/contacts/" +
          contact.contactid +
          "/" +
          this.$globals.companyId,
        {
          name: contact.name,
          address: contact.address,
          phone: contact.phone,
          email: contact.email,
          title: contact.title,
          role:
            typeof contact.role === "object"
              ? JSON.stringify(contact.role)
              : contact.role,
          image: contact.image,
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            contact.customerid +
            "/contacts/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allContacts[this.lastSelectedIndex] = response.data.queryResult;
          this.allContacts[this.lastSelectedIndex].forEach((contact) => {
            contact.role =
              typeof contact.role !== "object"
                ? JSON.parse(contact.role)
                : contact.role;
          });
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.subaction = "";
    }, //END updateContact()

    async updateEquipment(customer) {
      if (
        customer.equipment_info !== null &&
        customer.equipment_info !== undefined
      ) {
        customer.equipment_info =
          (typeof customer.equipment_info === "object")
            ? customer.equipment_info
            : JSON.parse(customer.equipment_info)
        customer.equipment_info.push(this.newEquipment);
      } else {
        customer.equipment_info = [this.newEquipment];
      }
      await axios.put(
        `${process.env.VUE_APP_API_TARGET}/customers/${customer.customerid}/equipment/${this.$globals.companyId}`,
        {
          equipment_info: JSON.stringify(customer.equipment_info),
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      ).then(async (res1) => {
        await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${customer.customerid}/equipment/${this.$globals.companyId}`, {
          headers: {
            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
          }
        }). then((response) => {
          this.allEquipment[this.lastSelectedIndex] = JSON.parse(response.data.queryResult)
          this.subaction = "";
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async updateFirehouse(house) {
      await axios.patch(
        `${process.env.VUE_APP_API_TARGET}/customers/${house.customerid}/house/${this.$globals.companyId}`,
        {
          address: house.address,
          access: house.access,
          name: house.name,
          houseid: house.houseid,
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
	  await this.getHouses(house)

      this.subaction = "";
    },

    updateImage(e, contact) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        contact.image = e.target.result;
      };
    },

    async updateNote(note) {
      await axios.patch(
        process.env.VUE_APP_API_TARGET + "/customers/" +
          note.customerid +
          "/notes/" +
          note.noteid +
          "/" +
          this.$globals.companyId,
        {
          customerid: note.customerid,
          note: note.note,
          pinned: note.pinned,
          author: JSON.stringify({
            name: this.$auth.user.name,
            pic: this.$auth.user.picture,
          }),
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            note.customerid +
            "/notes/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allNotes[this.lastSelectedIndex] =
            response.data.queryResult.reverse();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/" +
            note.customerid +
            "/pinned/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allPinnedNotes[this.lastSelectedIndex] =
            response.data.queryResult.reverse();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.subaction = "";
    }, //END updateNote()

    async viewAsset(asset){
      //await this.getCustomerName(asset.customerid);
      this.action = 'viewAsset';
      this.selectedAsset = asset;
      if(typeof asset.custom_fields !== 'object'){
        asset.custom_fields = JSON.parse(asset.custom_fields);
      }
      if(typeof asset.components !== 'object'){
        asset.components = JSON.parse(asset.components);
      }
      this.isBusy = true;
      await axios.get(process.env.VUE_APP_API_TARGET + "/tickets/asset/"+asset.serial+"/"+this.$globals.companyId, {
        headers: {
          'Authorization': `Bearer ${this.$globals.user.auth0Token}`
        }
      }).then(response =>{
        this.assetHistory = response.data.queryResult;
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      this.isBusy = false;
    },//END viewAsset()

    async viewVehicleHistory(vehicle) {
      await this.getCustomerName(vehicle.customerid);
      this.selectedVehicle = vehicle;
	  this.action = "vehicleHistory";

    },

    async viewTicketDetails(ticket, returnTo) {
    	this.selectedTicket = ticket;
		this.selectedTicket.contact = typeof this.selectedTicket.contact === 'object' ? this.selectedTicket.contact : JSON.parse(this.selectedTicket.contact);
		this.selectedTicket.data = typeof this.selectedTicket.data === 'object' ? this.selectedTicket.data : JSON.parse(this.selectedTicket.data);
		this.selectedTicket.asset = typeof this.selectedTicket.asset === 'object' ? this.selectedTicket.asset : JSON.parse(this.selectedTicket.asset);

        this.returnView = returnTo

		this.getVehicleInfo(this.selectedTicket.vehicle ?? this.selectedTicket.asset[0], this.selectedTicket.contact.customerid)

		for(var step in this.selectedTicket.data){
			var subTotal = 0;
			for(var log in this.selectedTicket.data[step].time_in){
				var stepTimeIn =this.selectedTicket.data[step].time_in[log].time;
				var stepTimeOut =new Date();
				var dateHolder = stepTimeOut
				if((typeof(this.selectedTicket.data[step].time_out[log]) !== 'undefined')){
					for(var other in this.selectedTicket.data[step].time_out){
						if ((this.selectedTicket.data[step].time_out[other]?.tech === this.selectedTicket.data[step].time_in[log]?.tech) && (new Date(this.selectedTicket.data[step].time_out[other]?.time) > new Date(this.selectedTicket.data[step].time_in[log]?.time))){
							stepTimeOut = this.selectedTicket.data[step].time_out[other].time;
							break;
						}
					}
				}
				var outDate = new Date(stepTimeOut);
				var inDate = new Date(stepTimeIn);
				var outMilli = Date.parse(outDate);
				var inMilli = Date.parse(inDate);
				var tHours = (outMilli - inMilli) / 60 /60 /1000;
				if(stepTimeIn && (Math.abs(outMilli - Date.now()) > 10000)){
					subTotal += Number(Number.parseFloat(tHours).toFixed(1));
				}
			}
			this.totalsArray[step] = subTotal;
		}
        this.returnView = this.returnView === 'searching' ? this.returnView : 'vehicleHistory'
		this.action = "viewTicket";
    },
  }, //END methods
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0px;
}
.addContact input[type="text"],
.addContact input[type="tel"],
.addContact input[type="email"] {
  padding: 5px;
  font-size: 12px;
  border: 1px solid grey;
  background: #f1f1f1;
  text-align: center;
}

.asset b-col {
  margin-top: 2.5%;
  padding: 5px;
}

.asset-link {
  cursor: pointer;
  color: #dc3545;
}

.asset-link:hover {
  text-decoration: underline;
  color: #dc3545;
}

.asset-history {
  display: flex;
  justify-content: center;
  margin-left: 5%;
  margin-top: 1%;
}

.asset-history .customPagination {
  margin-left: 45%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.customPagination {
  margin-left: 38.5%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.header {
  display: flex;
  justify-content: center;
}

.note-pfp {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}

.page-item .page-link {
  color: #dc3545 !important;
}

.searchBar {
  display: flex;
  justify-content: center;
  width: 60%;
  margin-left: 20%;
}

.searchBar input[type="text"] {
  padding: 5px;
  font-size: 17px;
  border: 1px solid grey;
  background: #f1f1f1;
  text-align: center;
  width: 60%;
  margin-top: 1%;
  height: 100%;
}

.addContact input[type="text"],
.addContact input[type="tel"],
.addContact input[type="email"] {
  padding: 5px;
  font-size: 12px;
  border: 1px solid grey;
  background: #f1f1f1;
  text-align: center;
}

/* input[type="tel"],
input[type="email"],
input[type="text"] {
  padding: 5px;
  font-size: 17px;
  border: 1px solid grey;
  background: #f1f1f1;
  text-align: center;
} */

.vehicle b-col {
  margin-top: 2.5%;
  padding: 5px;
}

.vehicle-history {
  display: flex;
  justify-content: center;
  margin-left: 5%;
  margin-top: 1%;
}

.showingCard {
  background: #f3f3f3 !important;
}

.vehicle-link {
  cursor: pointer;
  color: #dc3545;
}

.vehicle-link:hover {
  text-decoration: underline;
  color: #dc3545;
}

/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}

/deep/ .customPagination .page-item .page-link {
  color: #dc3545 !important;
}

.vehicle-history .customPagination {
  margin-left: 45%;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}

.page-item .page-link {
  color: #dc3545 !important;
}

input[type="tel"],
input[type="email"],
input[type="text"] {
  padding: 5px;
  font-size: 17px;
  border: 1px solid grey;
  background: #f1f1f1;
  text-align: center;
}
</style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__tag {
  background: #dc3545;
}

.multiselect__option--highlight {
  background: #dc3545;
  outline: none;
  color: white;
}
.multiselect__option--highlight:after {
  background: #dc3545;
  outline: none;
  color: white;
}

.multiselect__tag-icon:hover {
  background: grey;
}
</style>

<template>
	<div class="container-fluid" id="app">
		<div class="card card-danger" v-show="action === 'searching'">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Tech Data Center</b></h3>
				</div>
				<div class="card-tools">
					<b-button
						type="button"
						id="addDocButton"
						@click.prevent="changeState('addingFile')"
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						>Add a File</b-button
					>
					<b-button
						type="button"
						id="addURLButton"
						@click.prevent="changeState('addingLink')"
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						>Add a Link</b-button
					>
				</div>
			</div>
			<!--Showing Section-->
			<div class="card-body">
				<div>
					<label for="searchBar"></label>
					<div id="searchBar" class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
						<input
							type="text"
							class="form-control w-100"
							placeholder="Search by Name or Description"
							v-model="search"
							@change="debounceItems"
						/>
					</div>

					<swiper ref="swiper" :options="swiperOptions">
						<swiper-slide v-for="(resource, index) in items" :key="index" class="d-flex flex-column">
								<div class="d-flex" style="margin-left: 5%">
									<intersect v-if="resource.name === items[items.length - 1].name" @enter="getMoreItems">
										<h3><b>{{resource.name}}</b></h3>
									</intersect>
									<i class="fas fa-download ml-3 mt-2" @click="download(resource.url)"></i>
								</div>
								<h5 style="margin-left: 5%">{{resource.description}}</h5>
								<iframe v-if="resource.url !== null && resource.url.includes('https://fleet4fire-official')" :src="`https://docs.google.com/gview?embedded=true&url=${resource.url}`" width="90%" height="600" style="margin-left: 5%"></iframe>
								<div v-if="resource.url.includes('https://fleet4fire-official') === false">
									<iframe :src="resource.url.replace('watch?v=','embed/')" width="90%" height="600" style="margin-left: 5%"></iframe>
								</div>
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</swiper>
				</div>
			</div>
		</div>

		<!--Adding Section-->
		<div v-show="action === 'addingFile'" class="addingFile">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Add File</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							type="submit"
							class="button"
							@click.prevent="uploadToFile()"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							>Add Item</b-button
						>
						<b-button
							type="reset"
							class="button"
							@click.prevent="changeState('searching')"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							>Return</b-button
						>
					</div>
				</div>
				<div class="card-body">
					<form
						class="addingFile"
						enctype="multipart/form-data"
						ref="submisionForm"
					>
						<div style="margin-left: 25%; margin-bottom: 1%">
							<label>Description</label>
							<input
								type="text"
								class="form-control w-75"
								v-model="items.description"
								style="margin-bottom: 10px;"
								placeholder="Add a Brief Description"
								ref="descBox"
								required
							/>
							<label>File Import<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<input
								class="form-control form-control-lg w-75"
								id="formFileLg"
								type="file"
								ref="inputFile"
								name="doc"
								required
							/>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div v-show="action === 'addingLink'" class="addingLink">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Add Link</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							type="submit"
							class="button"
							@click.prevent="uploadLink()"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							>Add Item</b-button
						>
						<b-button
							type="reset"
							class="button"
							@click.prevent="changeState('searching')"
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							>Return</b-button
						>
					</div>
				</div>
				<div class="card-body">
					<form
						class="addingLink"
						enctype="multipart/form-data"
						ref="submisionForm"
					>
						<div style="margin-left: 25%; margin-bottom: 1%">
							<label>Video Link<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<input
								type="url"
								class="form-control w-75"
								placeholder="Input video link here"
								style="width: 80%; height: 5%; margin-bottom: 10px;"
								ref="linkBox"
								required
							/>
							<label>Name</label>
                            <input
								type="text"
								class="form-control w-75"
								v-model="items.name"
								placeholder="Add a Name"
								style="width: 80%; margin-bottom: 10px;"
								ref="nameBox"
								required
							/>
							<label>Description</label>
							<input
								type="text"
								class="form-control w-75"
								v-model="items.description"
								placeholder="Add a Brief Description"
								style="width: 80%"
								ref="descBox"
								required
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import { Socket } from "socket.io-client";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import Intersect from 'vue-intersect';
import { debounce } from 'lodash';

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'

export default {
	/*eslint-disable*/
	name: "app",
	props: {
		socket: Socket,
		companyId: Number,
		user: Object
	},
	components: {
		Swiper,
		SwiperSlide,
		Intersect
	},
	directives: {
		swiper: directive
	},
	data() {
		return {
			action: "searching",
			databaseLocation: `${process.env.VUE_APP_API_TARGET}/datacenter/`,
			dataCenterTableFields: [
				{
					key: "name",
					sortable: "true",
					label: "Name",
				},
				{
					key: "description",
					sortable: "true",
					label: "Description",
				},
			],
			items: [],
			search: "",
			swiperOptions: {
				slidesPerView: 'auto',
				centeredSlides: true,
				loop: true,
				spaceBetween: 30,
				keyboard: true,
				mousewheel: true,
				// effect: 'coverflow',
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					dynamicDots: true,
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			},
			pageNumber: 0,
			maxPages: 0,
			prevSearch: "",
		}; //Keep Data vars above this bracket
	},
	created(){
		this.debounceItems = debounce(this.searchItems, 600);
	},
	async mounted() {
		this.isBusy = true;
		await this.getItems()
        this.isBusy = false;
	},
	methods: {
		changeState(string) {
			if (string === "addingFile") {
				this.action = "addingFile";
			} else if (string === "addingLink") {
				var wip = false;
				if (wip == true) {
					alert("This section is still in progress");
					return;
				}
				this.action = "addingLink";
			} else {
				this.action = "searching";
				this.items.description = "";
				this.$refs.linkBox.value = "";
				this.$refs.inputFile.value = null;
			}
		}, //END changeState()

		download(url){
			window.open(url, '_blank')
		},

		filterDocs(search){
			this.filteredDocs = this.items.filter((item) => {
                console.log(item.description?.toLowerCase().includes(search.toLowerCase()) || item.name?.toLowerCase().includes(search.toLowerCase()))
				return item.description?.toLowerCase().includes(search.toLowerCase()) || item.name?.toLowerCase().includes(search.toLowerCase())
			})
			this.$forceUpdate()
		},

		async getItems(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/datacenter/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then((response) => {
				this.items = response.data.queryResult.results;
				this.maxPages = response.data.queryResult.maxPages;
				this.filteredDocs = response.data.queryResult.results;
				this.pageNumber = this.pageNumber+1;

                this.items.forEach((item, index) => {
                    if(item.url === null){
                        this.items[index].url = ""
                    }
                })
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getMoreItems(){
			if(this.pageNumber >= this.maxPages){
				return
			}
			let text = this.prevSearch === "" ? null : this.prevSearch
			await axios.get(`${process.env.VUE_APP_API_TARGET}/datacenter/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then((response) => {
				for(var item in response.data.queryResult.results){
					this.items.push(response.data.queryResult.results[item]);
				}
				this.maxPages = response.data.queryResult.maxPages;
				this.filteredDocs = response.data.queryResult.results;
				this.pageNumber = this.pageNumber+1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async searchItems(){
	      if(this.search === "" || this.search === null){
	        if(this.prevSearch === "" || this.prevSearch === null){
	          return
	        }
	        this.search = null
	      }
	      this.customerListIsLoading = true
	      this.pageNumber = 0
	      this.prevSearch = this.search
	      await axios
	      .get(`${process.env.VUE_APP_API_TARGET}/datacenter/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${this.search}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
	      .then((response) => {
	        this.items = response.data.queryResult.results;
	        this.maxPages = response.data.queryResult.maxPages;
	        this.pageNumber = this.pageNumber+1
	      })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	    },

		async uploadLink() {
			var formData = new FormData();
			formData.append("doc", this.$refs.linkBox.value);
			formData.append("description", this.items.description);
            formData.append("name", this.items.name);
			await axios
				.post(this.databaseLocation + this.$globals.companyId, formData, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					if (response) {
						alert("The link has been uploaded");
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			await axios
				.get(this.databaseLocation + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.items = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			this.action = "searching";
			this.items.description = "";
			this.$refs.linkBox.value = "";
		}, //END uploadLink

		async uploadToFile() {
			var formData = new FormData();
			formData.append("doc", this.$refs.inputFile.files[0]);
			formData.append("description", this.items.description);
			await axios
				.post(this.databaseLocation + this.$globals.companyId, formData, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					if (response) {
						alert("The file has been uploaded");
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Op-Tech', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			await this.getItems()

			this.action = "searching";
			this.items.description = "";
			this.$refs.inputFile.value = null;
		}, //END uploadToFile()
	}, //END Methods
};
</script>

<style scoped>
/deep/ .actbutton {
	margin: 15px;
	padding: 10px;
	background: #dc3545;
	background-color: #dc3545;
	border-color: #dc3545;
	color: white;
	font-size: 17px;
	border: 1px solid grey;
}
</style>

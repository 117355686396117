<template>
	<div>
		<div v-if="action === ''">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Sell Functions</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							v-if="selectedFunction === null"
							class="mx-1"
							style="background-color: #fff4; border-color: #dc3545"
							@click="submit()"
							>Create
						</b-button>
						<b-button
							v-if="selectedFunction !== null"
							class="mx-1"
							style="background-color: #fff4; border-color: #dc3545"
							@click="update()"
							>Update
						</b-button>
						<b-button
							class="mx-1"
							style="background-color: #fff4; border-color: #dc3545"
							@click="$emit('return')"
							>Return
						</b-button>
					</div>

				</div>
				<div class="card-body">
					<div class="row">
						<div class="col">
							<div class="row">
								<div class="col-3" style="text-align: right">
									Item Cost:
								</div>
								<div class="col">
									<input class="form-control w-25" type="number" step="0.01" v-model="practiceItemCost" />
								</div>
							</div>

							<div class="row">
								<div class="col-3" style="text-align: right">
									Item List:
								</div>
								<div class="col">
									<input class="form-control w-25" type="number" step="0.01" v-model="practiceItemList" />
								</div>
							</div>

							<div class="row">
								<div class="col-3" style="text-align: right">
									Item Markup:<br>
								</div>
								<div class="col">
									<input class="form-control w-25" style="display: inline-block" type="number" step="0.01" v-model="practiceItemMarkup" /><br>
								</div>
							</div>

							<div class="row">
								<div class="col-3" style="text-align: right">
									Vendor Markup:
								</div>
								<div class="col">
									<input class="form-control w-25" style="display: inline-block" type="number" step="0.01" v-model="practiceVendorMarkup" />
								</div>
							</div>

							<div class="row">
								<div class="col-3" style="text-align: right">
									Customer Discount:
								</div>
								<div class="col">
									<input class="form-control w-25" style="display: inline-block" type="number" step="0.01" v-model="practiceCustDisc" />
								</div>
							</div>
						</div>
						<div class="col">
							Function: <b>{{buildString}}</b><br><br>

							Pratice String: {{practiceString}}<br>

							Total: <input class="form-control w-25" style="display: inline-block" readonly v-model="practiceTotal">
						</div>
						<hr>

					</div>
					<hr>
					<div class="row">
						<div class="col">
							Name:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span>
							<input type="text" style="display: inline-block" class="form-control w-50" v-model="functionName" required/>

						</div>

						<div class="col">
							Existing Functions:
							<multiselect class="w-50" style="display: inline-block" :options="functionsArray" v-model="selectedFunction" @input="edit()" track-by="name" label="name" :multiple="false" :show-labels="false">
							</multiselect>
						</div>
					</div>
					<hr>

					<div v-for="(operation,index) in buildArray" :key="index" style="display: inline-block; flex-flow: row wrap">
						<multiselect
							:clearable="true"
							v-model="buildArray[index]"
							:options="varOptions"
							:multiple="false"
							:show-labels="false"
							placeholder="Var"
							@input="rebuildBuildString()"
							>
						</multiselect>
						<input type="number" step="0.01" class="form-control" v-if="operation.includes('CONSTANT')" @input="addConst(...arguments,index)"/>
					</div>
					<!--Next line-->
					<div>
						<multiselect
							class="w-25"
							:clearable="true"
							v-model="newVar"
							:options="varOptions"
							:multiple="false"
							:show-labels="false"
							placeholder="New Var"
							@select="addLine"
							>
						</multiselect>
					</div>
				</div>
			</div>


		</div>

	</div>
</template>

<script>
/*eslint-disable*/

import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default{
	name: "app",
	props:{

	},
	components: { Multiselect },
	data(){
		return{
			action: '',
			buildString: '',
			buildArray: [],
			functionsArray: [],
			functionName: '',
			newVar: '',
			practiceItemCost: 1,
			practiceItemList: 1,
			practiceItemMarkup: 1,
			practiceVendorMarkup: 1,
			practiceCustDisc: 1,
			practiceString :'',
			selectedFunction: null,
			varOptions: [
				'ITEM_COST',
				'ITEM_LIST',
				'ITEM_MARK_UP',
				'VENDOR_MARK_UP',
				'CUST_DISCOUNT',
				'CONSTANT',
				'+',
				'-',
				'*',
				'/',
				'(',
				')'
			]

		};
	},
	computed:{
		practiceTotal(){
			this.practiceString = String(this.buildString)

			//Replace all potential var placeholders (sanatized by parseFloat)
			this.practiceString = this.practiceString.replaceAll('ITEM_LIST', `${parseFloat(this.practiceItemList)}`)
			this.practiceString = this.practiceString.replaceAll('ITEM_COST', `${parseFloat(this.practiceItemCost)}`)
			this.practiceString = this.practiceString.replaceAll('ITEM_MARK_UP', `${parseFloat(this.practiceItemMarkup)}`)
			this.practiceString = this.practiceString.replaceAll('VENDOR_MARK_UP', `${parseFloat(this.practiceVendorMarkup)}`)
			this.practiceString = this.practiceString.replaceAll('CUST_DISCOUNT', `${parseFloat(this.practiceCustDisc)}`)
			this.practiceString = this.practiceString.replaceAll('CONSTANT','')

			try{
				return eval(this.practiceString).toFixed(2)
			}
			catch(e){
				if(isNaN(e)){
					return "Not a Number"
				}
				else{
					return e
				}
			}

		},

	},
	async mounted(){
		this.getFunctionList()
	},
	methods:{
		addLine(newVar){
			if(newVar === null || newVar === ''){
				alert("Cannot add empty var")
				return;
			}
			this.buildArray.push(newVar)
			if(this.buildArray[this.buildArray.length-1] == '(' || this.buildArray[this.buildArray.length-1] == ')'){
				this.varOptions.reverse()
			}
			this.varOptions.reverse()
			this.rebuildBuildString();
			this.$nextTick(()=>{
				this.newVar = ''
				this.$forceUpdate()
			})

		},

		addConst(number,index){
			this.buildArray[index] = 'CONSTANT'+`${number.currentTarget.value}`
			this.rebuildBuildString()
		},

		edit(){
			//use selectedFunction to then backfill
			this.buildArray = []
			this.buildString =""
			this.practiceString = ""

			this.functionName = this.selectedFunction?.name ?? ''
			this.buildArray = this.selectedFunction?.equation.split(' ') ?? []
			this.rebuildBuildString()
		},

		getFunctionList(){
			axios.get(`${process.env.VUE_APP_API_TARGET}/companies/functionList/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				if(response.data.queryResult.length > 0){
					this.functionsArray = response.data.queryResult
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'buildSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		rebuildBuildString(){
			this.$nextTick(()=>{
				let execute = []

				this.buildString = ""
				for(let i in this.buildArray){
					if(this.buildArray[i] == null || this.buildArray[i].toString()=='' || this.buildArray[i].toString() === 'null'){
						execute.push(i)
						continue
					}
					this.buildString = this.buildString + " " + (this.buildArray[i]).toString()
				}
				if(execute.length > 0){
					for(let i in execute){
						this.buildArray.splice(execute[i],1)
					}
				}
				this.$forceUpdate()
			})

		},

		submit(){
			try{
				let tempExecute = eval(this.practiceString)
				if(isNaN(tempExecute)){
					alert("Function results in not a number")
					return
				}
				else if(tempExecute == Infinity){
					alert("Infinity detected")
					return
				}
			}
			catch(e){
				alert("Something is wrong with the function: ",e)
				return
			}

			if(this.functionName == '' || this.functionName == null){
				alert("Function needs a name/identifier")
				return;
			}

			for(let i in this.functionsArray) {
				if(this.functionsArray[i].name == this.functionName){
					alert("Function with matching name exists")
					return
				}
			}

			axios.post(`${process.env.VUE_APP_API_TARGET}/companies/sellFunction/${this.$globals.companyId}`,
				{
					name: this.functionName,
					equation: this.buildString
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response)=>{
				this.getFunctionList()
				this.functionName = ''
				this.buildString = ''
				this.buildArray = []
				this.practiceString = ''
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'buildSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})


		},

		update(){
			try{
				let tempExecute = eval(this.practiceString)
				if(isNaN(tempExecute)){
					alert("Function results in not a number")
					return
				}
				else if(tempExecute == Infinity){
					alert("Infinity detected")
					return
				}
			}
			catch(e){
				alert("Something is wrong with the function: ",e)
				return
			}

			if(this.functionName == '' || this.functionName == null){
				alert("Function needs a name/identifier")
				return;
			}

			axios.patch(`${process.env.VUE_APP_API_TARGET}/companies/sellFunction/${this.selectedFunction.id}/${this.$globals.companyId}`,
				{
					name: this.functionName,
					equation: this.buildString
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response)=>{
				this.getFunctionList()
				this.functionName = ''
				this.buildString = ''
				this.buildArray = []
				this.practiceString = ''
				this.selectedFunction = null
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'buildSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

	},
}
</script>

<style>

</style>

<template>
    <div>
        <div class="card card-danger" v-if="action === ''">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>New Shipment</b></h3>
                </div>
                <div class="card-tools">
                    <b-button class="m-1" style="background: #fff4 !important ; border: #dc3545;" @click="() => {
                        createShipment()
                    }">Submit</b-button>
                    <b-button style="background: #fff4 !important; border: #dc3545;" @click="() => {
                        $emit('returnFromNewShipment')
                    }">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col" style="border-right: 6px solid-black">
                        <address>
                            <label for="to_address" class="w-75 text-left mt-2" style="margin-left: 13.5%"><h3>To:</h3><span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_address" class="form-control w-75" type="text" v-model="newShipment.toAddress.street" style="margin-left: 12.5%" placeholder="Street" required/>
                            <input id="to_address" class="form-control w-75" type="text" v-model="newShipment.toAddress.street2" style="margin-left: 12.5%" placeholder="Street2"/>
                            <input id="to_address2" class="form-control w-75" type="text" v-model="newShipment.toAddress.city" style="margin-left: 12.5%" placeholder="City" requried/>
                            <multiselect class="w-75" style="margin-left:12.5%" :options="allStates" label="name" track-by="name" v-model="newShipment.toAddress.state" placeholder="State" required></multiselect>
                            <input id="to_address3" class="form-control w-75" type="text" v-model="newShipment.toAddress.zip" style="margin-left: 12.5%" placeholder="Zip Code" required/>
                            <label for="to_company" class="w-75 text-left mt-2" style="margin-left: 13.5%">Company<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_company" class="form-control w-75" type="text" v-model="newShipment.toAddress.company" style="margin-left: 12.5%" placeholder="Company name" required>
                            <label for="to_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Contact<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_name" class="form-control w-75" type="text" v-model="newShipment.toAddress.name" style="margin-left: 12.5%" placeholder="Name" required/>
                            <label for="to_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_phone" class="form-control w-75" type="text" v-model="newShipment.toAddress.phone" style="margin-left: 12.5%" placeholder="Phone" required/>
                            <label for="to_email" class="w-75 text-left mt-2" style="margin-left: 13.5%">Email<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_email" class="form-control w-75" type="text" v-model="newShipment.toAddress.email" style="margin-left: 12.5%" placeholder="Email" required/>

                            <label for="weight" class="w-75 text-left mt-2" style="margin-left:13.5%">Weight (in lbs)<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input type="number" class="form-control w-75" style="margin-left:13.5%" min="0" step="1" placeholder="weight" v-model="newShipment.weight" required/>
                        </address>
                    </div>

                    <div class="col">
                        <address>
                            <label for="from_address" class="w-75 text-left mt-2" style="margin-left: 13.5%"><h3>From:</h3><span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="from_address" class="form-control w-75" type="text" v-model="newShipment.fromAddress.street" style="margin-left: 12.5%" placeholder="Street" required/>
                            <input id="to_address" class="form-control w-75" type="text" v-model="newShipment.fromAddress.street2" style="margin-left: 12.5%" placeholder="Street2"/>
                            <input id="from_address2" class="form-control w-75" type="text" v-model="newShipment.fromAddress.city" style="margin-left: 12.5%" placeholder="City" required/>
                            <multiselect class="w-75" style="margin-left:12.5%" :options="allStates" label="name" track-by="name" v-model="newShipment.fromAddress.state" placeholder="State" required></multiselect>
                            <input id="from_address3" class="form-control w-75" type="text" v-model="newShipment.fromAddress.zip" style="margin-left: 12.5%" placeholder="Zip Code" required/>
                            <label for="to_company" class="w-75 text-left mt-2" style="margin-left: 13.5%">Company<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_company" class="form-control w-75" type="text" v-model="newShipment.fromAddress.company" style="margin-left: 12.5%" placeholder="Company name" required/>
                            <label for="to_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Contact<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="to_name" class="form-control w-75" type="text" v-model="newShipment.fromAddress.name" style="margin-left: 12.5%" placeholder="Name" required/>
                            <label for="from_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="from_phone" class="form-control w-75" type="text" v-model="newShipment.fromAddress.phone" style="margin-left: 12.5%" placeholder="Phone" required/>
                            <label for="from_email" class="w-75 text-left mt-2" style="margin-left: 13.5%">Email<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="from_email" class="form-control w-75" type="text" v-model="newShipment.fromAddress.email" style="margin-left: 12.5%" placeholder="Email" required/>

                            <label for="insurance" class="w-75 text-left mt-2" style="margin-left:13.5%">Insured Value<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input id="insurance" class="form-control w-75" type="number" style="margin-left: 12.5%" v-model="newShipment.insurance" required/>
                        </address>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-danger" v-if="action === 'viewRates'">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Available Rates</b></h3>
                </div>
                <div class="card-tools">
                    <b-button style="background: #fff4 !important; border: #dc3545;" @click="() => {
                        action = ''
                    }">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <b-table :fields="ratesFields" :items="rates" >
                    <template #cell(selected)="row">
                        <b-button v-if="isAdmin === true" style="background: #dc3545; border: #dc3545" @click="buyRate(row)">Buy Now!</b-button>
                    </template>
                </b-table>
            </div>
        </div>

    </div>

</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
    name: 'newShipment',
    props: {
        customer: Object,
        source: Object,
        items: Array,
        user: Object,
		userRoles: Array,
    },
    components: {
        Multiselect,

    },

    data() {
        return {
            action: '',
            allStates: [
                {
                    "name": "Alabama",
                    "abbreviation": "AL"
                },
                {
                    "name": "Alaska",
                    "abbreviation": "AK"
                },
                {
                    "name": "American Samoa",
                    "abbreviation": "AS"
                },
                {
                    "name": "Arizona",
                    "abbreviation": "AZ"
                },
                {
                    "name": "Arkansas",
                    "abbreviation": "AR"
                },
                {
                    "name": "California",
                    "abbreviation": "CA"
                },
                {
                    "name": "Colorado",
                    "abbreviation": "CO"
                },
                {
                    "name": "Connecticut",
                    "abbreviation": "CT"
                },
                {
                    "name": "Delaware",
                    "abbreviation": "DE"
                },
                {
                    "name": "District Of Columbia",
                    "abbreviation": "DC"
                },
                {
                    "name": "Federated States Of Micronesia",
                    "abbreviation": "FM"
                },
                {
                    "name": "Florida",
                    "abbreviation": "FL"
                },
                {
                    "name": "Georgia",
                    "abbreviation": "GA"
                },
                {
                    "name": "Guam",
                    "abbreviation": "GU"
                },
                {
                    "name": "Hawaii",
                    "abbreviation": "HI"
                },
                {
                    "name": "Idaho",
                    "abbreviation": "ID"
                },
                {
                    "name": "Illinois",
                    "abbreviation": "IL"
                },
                {
                    "name": "Indiana",
                    "abbreviation": "IN"
                },
                {
                    "name": "Iowa",
                    "abbreviation": "IA"
                },
                {
                    "name": "Kansas",
                    "abbreviation": "KS"
                },
                {
                    "name": "Kentucky",
                    "abbreviation": "KY"
                },
                {
                    "name": "Louisiana",
                    "abbreviation": "LA"
                },
                {
                    "name": "Maine",
                    "abbreviation": "ME"
                },
                {
                    "name": "Marshall Islands",
                    "abbreviation": "MH"
                },
                {
                    "name": "Maryland",
                    "abbreviation": "MD"
                },
                {
                    "name": "Massachusetts",
                    "abbreviation": "MA"
                },
                {
                    "name": "Michigan",
                    "abbreviation": "MI"
                },
                {
                    "name": "Minnesota",
                    "abbreviation": "MN"
                },
                {
                    "name": "Mississippi",
                    "abbreviation": "MS"
                },
                {
                    "name": "Missouri",
                    "abbreviation": "MO"
                },
                {
                    "name": "Montana",
                    "abbreviation": "MT"
                },
                {
                    "name": "Nebraska",
                    "abbreviation": "NE"
                },
                {
                    "name": "Nevada",
                    "abbreviation": "NV"
                },
                {
                    "name": "New Hampshire",
                    "abbreviation": "NH"
                },
                {
                    "name": "New Jersey",
                    "abbreviation": "NJ"
                },
                {
                    "name": "New Mexico",
                    "abbreviation": "NM"
                },
                {
                    "name": "New York",
                    "abbreviation": "NY"
                },
                {
                    "name": "North Carolina",
                    "abbreviation": "NC"
                },
                {
                    "name": "North Dakota",
                    "abbreviation": "ND"
                },
                {
                    "name": "Northern Mariana Islands",
                    "abbreviation": "MP"
                },
                {
                    "name": "Ohio",
                    "abbreviation": "OH"
                },
                {
                    "name": "Oklahoma",
                    "abbreviation": "OK"
                },
                {
                    "name": "Oregon",
                    "abbreviation": "OR"
                },
                {
                    "name": "Palau",
                    "abbreviation": "PW"
                },
                {
                    "name": "Pennsylvania",
                    "abbreviation": "PA"
                },
                {
                    "name": "Puerto Rico",
                    "abbreviation": "PR"
                },
                {
                    "name": "Rhode Island",
                    "abbreviation": "RI"
                },
                {
                    "name": "South Carolina",
                    "abbreviation": "SC"
                },
                {
                    "name": "South Dakota",
                    "abbreviation": "SD"
                },
                {
                    "name": "Tennessee",
                    "abbreviation": "TN"
                },
                {
                    "name": "Texas",
                    "abbreviation": "TX"
                },
                {
                    "name": "Utah",
                    "abbreviation": "UT"
                },
                {
                    "name": "Vermont",
                    "abbreviation": "VT"
                },
                {
                    "name": "Virgin Islands",
                    "abbreviation": "VI"
                },
                {
                    "name": "Virginia",
                    "abbreviation": "VA"
                },
                {
                    "name": "Washington",
                    "abbreviation": "WA"
                },
                {
                    "name": "West Virginia",
                    "abbreviation": "WV"
                },
                {
                    "name": "Wisconsin",
                    "abbreviation": "WI"
                },
                {
                    "name": "Wyoming",
                    "abbreviation": "WY"
                }
            ],
            carrier_accounts: [],
            isAdmin: false,
            newShipment: {
                toAddress: {},
                fromAddress: {},
                weight: 0,
				insurance: 0,
            },
            shipmentValue: 0,
            rates: [],
            ratesFields: [
                { key: 'carrier', sortable: true},
                { key: 'service', sortable: true},
                { key: 'est_delivery_days', sortable: true, label: 'Estimated Delivery Days'},
                { key: 'rate', filterable: true, sortable: true },
                { key: 'currency' },
                { key: 'selected', label: '' }
            ],
        }
    },

    mounted() {
        for(let index in this.$globals.user.roles){
            if(this.$globals.user.roles[index].name == "Sales Manager" || this.$globals.user.roles[index].name.includes("Admin") || this.$globals.user.roles[index].name == "Parts" || this.$globals.user.roles[index].name == "Parts Manager"){
                this.isAdmin = true;
                break;
            }
        }

        this.getCarrierAccounts();

        let formattedToAddress = this.customer.address.split(', ')
        formattedToAddress[formattedToAddress.length] = formattedToAddress[formattedToAddress.length-1]?.split(' ')[1];
        formattedToAddress[formattedToAddress.length-1] = formattedToAddress[formattedToAddress.length-1]?.split(' ')[0];

        let formattedFromAddress = this.source.address.split(', ')
        formattedFromAddress[formattedFromAddress.length] = formattedFromAddress[formattedFromAddress.length-1]?.split(' ')[1];
        formattedFromAddress[formattedFromAddress.length-1] = formattedFromAddress[formattedFromAddress.length-1]?.split(' ')[0];

        this.newShipment.toAddress = {
            street: formattedToAddress[0],
            city: formattedToAddress[1],
            state: formattedToAddress[2],
            zip: formattedToAddress[3],
            name: this.customer.name,
            phone: this.customer.phone,
            email: this.customer.email
        }

        this.newShipment.fromAddress = {
            street: formattedFromAddress[0],
            city: formattedFromAddress[1],
            state: formattedFromAddress[2],
            zip: formattedFromAddress[3],
            name: this.source.name,
            phone: this.source.phone,
        }

        this.items.forEach((item) => {
            this.shipmentValue += parseFloat(item.total)
        })
        if(this.shipmentValue >= 5000){
            this.newShipment.insurance = this.shipmentValue
        }
    },

    methods: {
        async buyRate(row) {
            if(confirm(`Are you sure you want to purchase ${row.item.carrier} ${row.item.service} for ${row.item.rate}${row.item.currency}`)){
                await axios
                    .post(process.env.VUE_APP_API_TARGET + "/shipping/shipment_buy_specific/"+this.source.companyid, {
                        rate_id: row.item.id,
                        shipment_id: row.item.shipment_id,
                    },
                    {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.$emit('addShippingToOrder', row.item.carrier, row.item.service, row.item.rate)
                        this.$emit('returnFromNewShipment')
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Shipment', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
            }
        },

        async createShipment() {
            if(this.newShipment.weight === 0){
                return alert("A shipment must have the parcel's weight defined.")
            }

            let toState = this.newShipment.toAddress.state;
            let fromState = this.newShipment.fromAddress.state;

            this.newShipment.carrier_accounts = this.carrier_accounts

            this.newShipment.toAddress.state = (typeof this.newShipment.toAddress.state === 'object' ? this.newShipment.toAddress.state.abbreviation : this.newShipment.toAddress.state)
            this.newShipment.fromAddress.state = (typeof this.newShipment.fromAddress.state === 'object' ? this.newShipment.fromAddress.state.abbreviation : this.newShipment.fromAddress.state)
            await axios.post(`${process.env.VUE_APP_API_TARGET}/shipping/shipment/${this.source.companyid}`, this.newShipment, {
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then(async (response) => {
                this.rates = response.data.shipment.rates
                this.action = 'viewRates'

                this.newShipment.toAddress.state = toState;
                this.newShipment.fromAddress.state = fromState;

				if(this.newShipment.insurance > 0){
					await axios
						.post(
						process.env.VUE_APP_API_TARGET + "/shipping/shipment/add_insurance/",
						{
							shipment_id: response.data.shipment.id,
							value: this.newShipment.insurance,
						},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Shipment', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
				}
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Shipment', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async getCarrierAccounts(){
            await axios.get(`${process.env.VUE_APP_API_TARGET}/shipping/carrier_accounts/${this.$globals.companyId}`, {
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response) => {
                this.allCarrierAccounts = response.data.queryResult
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Shipment', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },
    }
}
</script>

<style>

</style>

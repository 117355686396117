<template>
  <!-- Wrapper -->
  <div>
    <!-- Initial State -->
    <div v-if="action === ''">
      <!-- Template Viewer -->
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>Marketing</b></h3>
          </div>
          <div class="card-tools">
            <b-button
              style="
                        background-color: #fff4;
                        border-color: #dc3545;
                        margin-left: -10%;
                        margin-right: 1%;
                        margin-top: 1%;
                        "
              @click="action = 'newEmailTemplate'"
              >New Email Template</b-button
            >
            <b-button
              style="
                        background-color: #fff4;
                        border-color: #dc3545;
                        margin-right: 1%;
                        margin-top: 1%;
                        "
              @click="action = 'newTextTemplate'"
              >New Text Template</b-button
            >
          </div>
        </div>
        <div class="card-body" style="height: 100%">
          <swiper
            :options="swiperOptions"
            :auto-destroy="true"
            ref="mySwiper"
            style="width: 100%; overflow-x: hidden; height: 30vh"
          >
            <swiper-slide
              v-for="(template, index) in allTemplates"
              :key="index"
            >
              <h3 style="color: #dc3545">
                <b>{{ template.name }}</b>
              </h3>
              <h5>{{ template.type }}</h5>
              <div
                v-html="template.template"
                style="background-color: whitesmoke;"
              ></div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- EO Templates -->

      <!-- Stats Wrapper -->
      <div class="row">
        <!-- Stats Panel 1 -->
        <div class="col">
          <div class="card card-danger">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Email Activity</b></h3>
              </div>
              <div class="card-tools">
                <b-button
                  style="
                                background-color: #fff4;
                                border-color: #dc3545;
                                margin-left: -10%;
                                margin-right: 1%;
                                margin-top: 1%;
                                "
                  @click="action = 'newEmail'"
                  >New Email</b-button
                >
              </div>
            </div>
            <div class="card-body"></div>
          </div>
        </div>

        <!-- Stats Panel 2 -->
        <div class="col">
          <div class="card card-danger">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Engagement Statistics</b></h3>
              </div>
            </div>
            <div class="card-body"></div>
          </div>
        </div>

        <!-- Stats Panel 3 -->
        <div class="col">
          <div class="card card-danger">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Text Activity</b></h3>
              </div>
              <div class="card-tools">
                <b-button
                  style="
                                background-color: #fff4;
                                border-color: #dc3545;
                                margin-left: -10%;
                                margin-right: 1%;
                                margin-top: 1%;
                                "
                  @click="action = 'newText'"
                  >New Text</b-button
                >
              </div>
            </div>
            <div class="card-body"></div>
          </div>
        </div>
      </div>
      <!-- EO Stats -->
    </div>
    <!-- EO '' state -->
    <div v-if="action === 'newEmail'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>New Email</b></h3>
          </div>
          <div class="card-tools">
            <b-button
              style="
                            background-color: #fff4;
                            border-color: #dc3545;
                            margin-left: -10%;
                            margin-right: 1%;
                            margin-top: 1%;
                            "
              @click="sendEmail()"
              >Send</b-button
            >
            <b-button
              style="
                            background-color: #fff4;
                            border-color: #dc3545;
                            margin-right: 1%;
                            margin-top: 1%;
                            "
              @click="
                action = '';
                emailData = '';
                emailSubject = '';
                emailTitle = '';
              "
              >Cancel</b-button
            >
          </div>
        </div>
        <div class="card-body">
          <multiselect
            :options="allContacts"
            :multiple="true"
            group-values="set"
            group-label="group"
            :group-select="true"
            track-by="contactid"
            label="email"
            placeholder="Select Recipients"
            style="width: 95%; margin-left: 2%;"
            v-model="emailTo"
          >
            <!-- <template slot="option" slot-scope="props" style="display: flex"><img class="img-circle elevation-3" style="height: 5%; width: 5%; display: inline" :src="props.option.image">
                            <div class="option__desc"><span class="option__title">{{ `${props.option.title} ${props.option.name}` }}</span><span class="option__small">{{ props.option.email }}</span></div>
                        </template> -->
          </multiselect>
          <input
            class="form-control"
            placeholder="Title"
            style="margin-left: 2%; margin-top: 1%; width: 95%"
            v-model="emailTitle"
          />
          <input
            class="form-control"
            placeholder="Subject"
            style="margin-left: 2%; margin-top: 1%; width: 95%"
            v-model="emailSubject"
          />
          <hr />
          <quill-editor
            v-model="emailData"
            style="width: 100%; height:250px; margin-bottom: 5%"
          ></quill-editor>
          <hr />
          <div v-html="emailData"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";
import axios from "axios";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
import 'swiper/css/swiper.css'

export default {
  components: {
    quillEditor,
    Multiselect,
	Swiper,
	SwiperSlide
  },

  directives: {
	swiper: directive
  },

  props: {
    user: Object,
    companyId: Number,
  },

  computed: {
    customLabel(args) {
      console.log(args);
    },
  },

  data() {
    return {
      action: "",
      allContacts: [],
      allTemplates: [
        {
          name: "Template 1",
          type: "Service",
          template:
            "<div><h3><b>I am creating a fake template does this render? I hope so!</b></h3></div>",
        },
        {
          name: "Template 2",
          type: "Service",
          template:
            "<div><h3><b>I am creating a fake template does this render? I hope so!</b></h3></div>",
        },
      ],
      engagementStats: null,
      emailData: null,
      emailTo: [],
      emailSubject: "",
      emailTitle: "",
      subKey: null,
      swiperOptions: {
        loop: true,
        spaceBetween: 100,
      },
    };
  },

  async mounted() {
    await this.getContacts(this.$globals.companyId);
    await this.getCompanyKey(this.$globals.companyId);
    await this.getStats(this.$globals.companyId);
  },

  methods: {
    //TODO: Get access to the DB to add a column to hold the twilio subuser key
	async getCompanyKey(companyid) {
		//TODO this get route doesn't exist
      await axios
        .get(`${process.env.VUE_APP_API_TARGET}/twilio/subuser/${this.$globals.companyId}`, {
          headers: {
            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
          },
        })
        .then((response) => {
          this.subKey = response.data.queryResult[0].sgKey;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'CRM', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async getContacts(companyid) {
      await axios
        .get(`${process.env.VUE_APP_API_TARGET}/twilio/allContacts/${companyid}`, {
          headers: {
            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
          },
        })
        .then((response) => {
          this.allContacts = response.data.queryResult;
          this.allContacts.forEach((group) => {
            group.set.forEach((contact) => {
              contact.email =
                contact.email !== null || ""
                  ? contact.email
                  : `${contact.name} - No Email provided`;
            });
          });
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'CRM', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async getStats(companyid) {
      await axios
        .get(
          `${process.env.VUE_APP_API_TARGET}/twilio/stats`,
          {
            sgKey: this.subKey,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$globals.user.auth0Token}`,
            },
          }
        )
        .then((response) => {
          this.engagementStats = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'CRM', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async sendEmail() {
      await axios.post(
        `${process.env.VUE_APP_API_TARGET}/twilio/email`,
        {
          sgKey: this.subKey,
          to: this.emailTo,
          subject: this.emailSubject,
          title: this.emailTitle,
          html: this.emailData,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
          },
        }
      )
	  .catch((response)=>{
		 this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'CRM', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },
  },
};
</script>

<style></style>

<template>
	<div class="wrapper">
		<div v-show="action == ''">
			<div class="row">
				<div class="col">
					<div class="card card-danger mt-4 ml-4">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Announcements</b></h3>
							</div>
							<div class="card-tools">
								<b-button v-if="$globals.canAccess.admin" class = "fas fa-plus-circle" style="background-color: #fff4; border-color: #dc3545;" @click="action='newAnnouncment'">
								</b-button>
							</div>
						</div>
						<div class="card-body" style="overflow-y: scroll; max-height: 405px">
							<div class="card card-outline card-danger" v-for="announcement in announcements" :key="announcement.id">
								<div class="card-body">
									{{announcement.message}} <br/>
									<span style="font-size: 8pt">{{new Date(announcement.created).toLocaleDateString()}}</span>
									<span v-if="isAdmin" style="float:right" class="fas fa-trash" size='xs' @click="removeAnnouncment(announcement.id)"></span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger mt-4 ml-4" style="height: 485px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Requests</b></h3>
							</div>
							<div class="card-tools">
								<b-button style="background-color: #fff4; border-color: #dc3545;" @click="action='newPTO'">Request Time Off
								</b-button>
							</div>
						</div>
						<div class="card-body" style="overflow-y: scroll">
							<b-tabs fill lazy v-model="selectedTab">
								<b-tab title="Pending" :title-link-class="'text-danger'">
									<b-table
										striped
										sticky-header
										:fields= "[{key: 'Type'},{key: 'Data', label:'Date(s)'}]"
										:items ="pendingList"
									>
										<template #cell(Type)="row">
											<div v-if="row.item.identifier === 'PTO'">
												{{row.item.type}}
											</div>
											{{row.item.identifier}}
										</template>

										<template #cell(Data)="row">
											<div v-if="row.item.identifier === 'Announcement'">
												{{row.item.message}}
												<br>
												For: <b>{{new Date(row.item.created).toISOString().split('T')[0]}}</b>
											</div>
											<div v-else-if="row.item.identifier === 'PTO'">
												From: <b>{{row.item.requestedDates[0]}}</b>
												<br>
												To: <b>{{row.item.requestedDates[1]}}</b>
												<br>
												Created on: {{row.item.created}}
											</div>
											<div v-else-if="row.item.identifier === 'Invoice'">
												List of price changes?
											</div>
											<div v-else>
												All info: {{row.item}}
											</div>
										</template>

									</b-table>
								</b-tab>

								<b-tab title="Approved" :title-link-class="'text-danger'">
									<b-table
										striped
										sticky-header
										:fields= "[
											{key: 'Type'},
											{key: 'Data', label:'Date(s)'}
										]"
										:items ="approvedList"
									>
										<template #cell(Type)="row">
											<div v-if="row.item.identifier === 'PTO'">
												{{row.item.type}}
											</div>
											{{row.item.identifier}}
										</template>

										<template #cell(Data)="row">
											<div v-if="row.item.identifier === 'Announcement'">
												{{row.item.message}}
												<br>
												For: <b>{{new Date(row.item.created).toISOString().split('T')[0]}}</b>
											</div>
											<div v-else-if="row.item.identifier === 'PTO'">
												From: <b>{{row.item.requestedDates[0]}}</b>
												<br>
												To: <b>{{row.item.requestedDates[1]}}</b>
												<br>
												Created on: {{row.item.created}}
											</div>
											<div v-else-if="row.item.identifier === 'Invoice'">
												List of price changes?
											</div>
											<div v-else>
												All info: {{row.item}}
											</div>
										</template>

									</b-table>
								</b-tab>

								<b-tab title="Rejected" :title-link-class="'text-danger'">
									<b-table
										striped
										sticky-header
										:fields= "[
											{key: 'Type'},
											{key: 'Data', label:'Date(s)'}
										]"
										:items ="rejectedList"
									>
										<template #cell(Type)="row">
											<div v-if="row.item.identifier === 'PTO'">
												{{row.item.type}}
											</div>
											{{row.item.identifier}}
										</template>

										<template #cell(Data)="row">
											<div v-if="row.item.identifier === 'Announcement'">
												{{row.item.message}}
												<br>
												For: <b>{{new Date(row.item.created).toISOString().split('T')[0]}}</b>
											</div>
											<div v-else-if="row.item.identifier === 'PTO'">
												From: <b>{{row.item.requestedDates[0]}}</b>
												<br>
												To: <b>{{row.item.requestedDates[1]}}</b>
												<br>
												Created on: {{row.item.created}}
											</div>
											<div v-else-if="row.item.identifier === 'Invoice'">
												List of price changes?
											</div>
											<div v-else>
												All info: {{row.item}}
											</div>
										</template>

									</b-table>
								</b-tab>

							</b-tabs>

						</div>
					</div>
				</div>

				<!-- Calendar Column -->
				<div class="col">
					<div class="card card-danger mt-4 ml-4" style="height: 485px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Company Calendar</b></h3>
							</div>
						</div>
						<div class="card-body" style="overflow-y: scroll">
							<Calendar trim-weeks style="width:100%;" :rows="2" :attributes="attr">
<!--
								<template #day-popover="{ day, format, masks }">
									<div class="text-xs text-gray-300 font-semibold text-center">
										{{ format(day.date, masks.dayPopover) }}
									</div>
									<div>
										{{calendarEvents.desc}}
									</div>
								</template>
-->

<!--
								<div slot="day-popover" slot-scoope="{day, dayTitle, attributes }">
									<div class="text-xs text-gray-300 font-semibold text-center">
										{{ format(day.date, masks.dayPopover) }}
									</div>
									<ul>
										<li v-for="{key, customData} in attributes" :key="key">
											{{customData}}
										</li>
									</ul>
								</div>
-->
							</Calendar>
						</div>
					</div>
				</div>
			</div>

			<!-- To Do List -->
			<div class="row">
				<div class="col">
					<ToDoList @change="fillCalendar()"></ToDoList>
				</div>
			</div>

		</div>

		<!-- Making a new Announcement -->
		<div v-if="action == 'newAnnouncment'">
			<div class="card card-danger mt-4 ml-4">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Announcement</b></h3>
					</div>
					<div class="card-tools">
						<b-button @click="createAnouncement()" style="background-color: #fff4; border-color: #dc3545">Create</b-button>
						<b-button @click="action=''" style="background-color: #fff4; border-color: #dc3545">Return</b-button>
					</div>
				</div>
				<div class="card-body" style="text-align: center">
					<textarea name="newAnnouncmentTextArea" wrap="soft" style="width:60vw; height: 30vh" v-model="newAnnouncment" required v-b-tooltip.hover title="Required Field"></textarea>
					<br>
					<label for="timePicker" style="font-size: 8pt; text-align: left">Select date the announcement will be visible<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
					<VueCtkDateTimePicker id="timePicker" class="w-50" color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="announcementDate" required/>
				</div>
			</div>
		</div>

		<!-- PTO Request -->
		<div v-if="action === 'newPTO'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Time Off Request</b></h3>
					</div>
					<div class="card-tools">
						<b-button style="background-color: #fff4; border-color: #dc3545" @click="submitPTO()">Submit</b-button>
						<b-button style="background-color: #fff4; border-color: #dc3545" @click="action = ''">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<!-- TODO make this one range picker instead of two pickers -->
						<div class="col">
							<label for="startPTO">Start date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<VueCtkDateTimePicker id="startPTO" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="ptoStartDate" required/>
						</div>
						<div class="col">
							<label for="endPTO">End date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<VueCtkDateTimePicker id="endPTO" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="ptoEndDate" required/>
						</div>
					</div>
					<div class="row">
						<div class="col"></div>
						<div class="col">
							<multiselect style="margin-top: 5%"
								:options="[
									'Sick Leave',
									'Vacation'
								]"
								:multiple="false"
								:show-labels="false"
								v-model="ptoType"
								placeholder="Type*"
								required
								v-b-tooltip.hover title="Required Field"
								>
							</multiselect>
						</div>
						<div class="col"></div>

					</div>



				</div>
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/

	import Calendar from 'v-calendar/lib/components/calendar.umd'
	import DatePicker from 'v-calendar/lib/components/date-picker.umd'
	import axios from 'axios'
	import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
	import VSwatches from 'vue-swatches'
	import "vue-swatches/dist/vue-swatches.css"
	import Multiselect from "vue-multiselect";
	import { Socket } from "socket.io-client";
	import ToDoList from "./Dashboard/ToDo.vue"



	export default {
		name: "app",
		props: {
			socket: Socket,
			companyId: Number,
			user: Object,
			userRoles: Array,
		},
		components: {
			Calendar, DatePicker, VueCtkDateTimePicker, VSwatches, Multiselect, ToDoList
		},
		data() {
			return {
				action: '',
				announcements: [],
				announcementDate: '',
				attr: [],
				approvedList: [],
				calendarPTO: [],
				calendarEvents:[],
				isAdmin: false,
				month: String(new Date().getMonth()+1).padStart(2,'0'),
				newAnnouncment: '',
				openTasks:[],
				pendingList: [],
				ptoType: '',
				ptoStartDate: null,
				ptoEndDate: null,
				rejectedList: [],
				selectedTab: null,
				selectedTask: '',
				swatches: ["red", "orange", "yellow", "green", "teal", "blue", "indigo", "purple", "pink"],
				today: String(new Date().getDate()).padStart(2,'0'),
				year: new Date().getFullYear(),

			}
		},
		computed: {
			attributes(){
				for(let i in this.calendarEvents){
					this.calendarEvents[i] = {... this.calendarEvents[i],index: i, desc: (this.calendarEvents[i].task ?? this.calendarEvents[i].message) }
				}
				let count = 0
				let last_day = null
				let formattedEvents = this.calendarEvents.map(calendarEvents =>{
					if(new Date(calendarEvents.due ?? calendarEvents.created).getDate() === last_day){
						count += 1
					}else {
						last_day = new Date(calendarEvents.due ?? calendarEvents.created).getDate()
					}
					return {
						key: calendarEvents.index,
						dates: new Date (calendarEvents.due ?? calendarEvents.created),
						dot: count < 3 ? calendarEvents.color ?? 'red' : false,
						popover:
						{
							label: calendarEvents.task ?? calendarEvents.message,
							visibility: 'click',
						},

						customData: calendarEvents,
					}
				})

				let formattedPTO = this.calendarPTO.map((calendarPTO) => {
					if(new Date(calendarPTO.requestedDates[0][0]).getDate() === last_day){
						count += 1
					}else {
						last_day = new Date(calendarPTO.requestedDates[0][0]).getDate()
					}
					return {
						dot: count < 2 ? 'gray' : false,
						dates: {start: new Date(calendarPTO.requestedDates[0][0] +' '+ calendarPTO.requestedDates[0][1]), end: new Date(calendarPTO.requestedDates[1][0] +' '+ calendarPTO.requestedDates[1][1])},
						popover: {
							label: calendarPTO.type +' for '+ calendarPTO.user.name,
							visibility: 'click',
						}
					}
				})

				let superArray = [...formattedEvents,{
					key: 'today',
					highlight: {
						color: "red",
						fillMode: 'outline'
					},
					dates: new Date(),
				}, ...formattedPTO]

				return superArray
			}
		},

		async mounted(){
			for(let index in this.userRoles){
				if(this.userRoles[index].name == "Admin" || this.userRoles[index].name == "Global Admin"){
					this.isAdmin = true;
					break;
				}
			}

			this.getApprovals()
			this.getCalendarPTO()
			this.getListedAnnouncments()
			await this.fillCalendar()
			this.attr = this.attributes

			this.$globals.socket.on("newDashboardData", async (message, data)=>{
				this.getApprovals();
				this.getCalendarPTO();
				this.getListedAnnouncments()

				this.fillCalendar()
				this.$forceUpdate()
			})
			this.$globals.socket.emit('joinRoom', `dashboard${this.$globals.companyId}`);

		},

        destroyed() {
            this.$globals.socket.emit("leaveRoom", `dashboard${this.$globals.companyId}`);
            this.$globals.socket.off("newDataCreated")
            console.log("destroyed dashboard")
        },

		methods: {
			async changeStatus() {
			  await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do_status`,
				  {
					localid: this.selectedTask.localid,
					status: "Complete",
				  },
				  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			  this.$forceUpdate();
			  this.action = "";
			},

			async createAnouncement(){
				//Saftey
				if(this.newAnnouncment == null || this.newAnnouncment == ''){
					alert("Empty announcements message")
					return;
				}
				if(this.announcementDate == null || this.announcementDate == ''){
					alert("Unspecified date for announcement")
					return;
				}

				let created = this.announcementDate.split(' ')
				let approval = '';
				if(this.isAdmin){
					approval = 'Approved'
				}
				else{
					approval = 'Pending'
				}
				await axios
				.post(`${process.env.VUE_APP_API_TARGET}/companies/announcements/${this.$globals.companyId}`,
					{
						message:this.newAnnouncment,
						created: created[0],
						user: JSON.stringify({auth:this.$globals.user.sub, name:this.$globals.user.name}),
						approval: approval
					},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/announcements/${this.year}-${this.month}-${this.today}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response => {
						this.announcements = response.data.queryResult
						this.announcements.sort((a,b)=>{
							var c = new Date(a.created);
							var d = new Date(b.created);
							return d-c;
						 })
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				this.action =''

				this.$globals.socket.emit('newData', `adminDashboard${this.$globals.companyId}`);
				this.newAnnouncment = '';
				this.announcementDate = '';
			},

			async fillCalendar(){
				this.calendarEvents = [];
				await this.getToDoList();
				await this.getCalendarAnnouncements()
				for(let index in this.openTasks){
					this.calendarEvents.push(this.openTasks[index])
				}
				this.attr = this.attributes
				this.$forceUpdate()

			},

			async getListedAnnouncments(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/announcements/${this.year}-${this.month}-${this.today}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response => {
						this.announcements = response.data.queryResult
						this.announcements.sort((a,b)=>{
							var c = new Date(a.created);
							var d = new Date(b.created);
							return d-c;
						 })
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getCalendarAnnouncements(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/announcements/${'ALL'}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response => {
						//this.calendarEvents = response.data.queryResult
						for(let index in response.data.queryResult){
							this.calendarEvents.push(response.data.queryResult[index])
						}
						this.attr = this.attributes
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getApprovals(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/approval/${this.$globals.user.sub}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then((response)=>{
						this.approvedList = []
						this.pendingList = []
						this.rejectedList = []
						for(let index in response.data.queryResult){
							if(response.data.queryResult[index].identifier == 'PTO'){
								response.data.queryResult[index].requestedDates = typeof response.data.queryResult[index].requestedDates === 'object' ? response.data.queryResult[index].requestedDates : JSON.parse(response.data.queryResult[index].requestedDates)
							}
							if(response.data.queryResult[index].approval === 'Approved'){
								this.approvedList.push(response.data.queryResult[index])
							}
							else if(response.data.queryResult[index].approval === 'Pending'){
								this.pendingList.push(response.data.queryResult[index])
							}
							else if(response.data.queryResult[index].approval === 'Rejected'){
								this.rejectedList.push(response.data.queryResult[index])
							}
						}
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getCalendarPTO(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/pto/all/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then((response)=>{
						this.calendarPTO = response.data.queryResult
						for(let index in this.calendarPTO){
							this.calendarPTO[index].user = typeof this.calendarPTO[index].user === 'object' ? this.calendarPTO[index].user : JSON.parse(this.calendarPTO[index].user)
							this.calendarPTO[index].requestedDates = typeof this.calendarPTO[index].requestedDates === 'object' ? this.calendarPTO[index].requestedDates : JSON.parse(this.calendarPTO[index].requestedDates)
							this.calendarPTO[index].requestedDates[0] = this.calendarPTO[index].requestedDates[0].split(' ')
							this.calendarPTO[index].requestedDates[1] = this.calendarPTO[index].requestedDates[1].split(' ')

						}
						this.attr = this.attributes

					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getToDoList(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.user.sub}/${this.$globals.companyId}`, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
					.then((response) => {
						this.openTasks = response.data.queryResult;
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			removeAnnouncment(id){
				axios.delete(`${process.env.VUE_APP_API_TARGET}/companies/announcements/${id}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response =>{
						this.announcements = this.announcements.filter( (item, index, arr)=>{
							if(item.id !== id){
								return item
							}
						})
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})

			},

			async submitTask() {

				//Saftey check
				if(this.toDoDueDate == null || this.toDoDueDate == ''){
					alert("Please select a day")
					return;
				}
				if(this.toDoInput == null || this.toDoInput == ''){
					alert("What do you need to do?")
					return;
				}

				let dueDate = this.toDoDueDate.split(' ')[0];
				let today = new Date().toISOString().split("T")[0]

				await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do`,
					{
						companyid: this.$globals.companyId,
						salesman: this.$globals.user.name,
						task: this.toDoInput,
						starts: today,
						due: dueDate,
						status: "Open",
						color: this.tagColor,
				  },
				  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.companyId}/${this.$globals.user.name}`, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
					.then((response) => {
						this.openTasks = response.data.queryResult;
						this.fillCalendar();
						this.$forceUpdate();
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.action = "";

				this.toDoInput = "";
				this.toDoDueDate = null;
				this.tagColor = "";
			},

			async submitPTO(){
				//Saftey Check
				if(this.ptoStartDate == null){
					alert("Requests must have a start date")
					return;
				}
				if(this.ptoEndDate == null){
					alert("Requests must have an end date")
					return;
				}
				if(this.ptoType === '' || this.ptoType == null){
					alert("Please add a type to the PTO request")
					return;
				}
				this.$globals.socket.emit("notifyRelevantRoles", this.$globals.companyId, ['rol_c7ubznTEtdGM46JP', 'rol_InN3nAGXGq185jL5'], "PTO Request", "PTO Request from " + this.$globals.user.name);

				//Make request
				axios.post(`${process.env.VUE_APP_API_TARGET}/companies/newPTO/${this.$globals.companyId}`,{
					type: this.ptoType,
					range: JSON.stringify([this.ptoStartDate, this.ptoEndDate]),
					created: new Date().toISOString().split('T')[0],
					user: JSON.stringify({auth:this.$globals.user.sub, name:this.$globals.user.name})
				},{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then(response=>{
					this.pendingList.push({
						"identifier": 'PTO',
						 "user": {"name": this.$globals.user.name, "auth": this.$globals.user.sub},
						 "created": new Date().toISOString().split('T')[0],
						 "type": this.ptoType,
						 "approval": 'Pending',
						 "requestedDates": [this.ptoStartDate, this.ptoEndDate]
					 })
					this.ptoType = ''
					this.ptoStartDate = null
					this.ptoEndDate = null
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.action = ''
				this.$globals.socket.emit('newData', `adminDashboard${this.$globals.companyId}`);
			},

			async updateTask(){

				//Saftey Check
				if(this.toDoDueDate == null || this.toDoDueDate == ''){
					alert("Please add a due date")
					return;
				}
				if(this.toDoInput == null || this.toDoInput == ''){
					alert("Please add a task to do")
					return;
				}

				let dueDate = this.toDoDueDate.split(' ')[0];

				await axios.put(`${process.env.VUE_APP_API_TARGET}/sales/to_do/${this.selectedTask.id}/${this.$globals.companyId}`,
					{
						task: this.toDoInput,
						color: this.tagColor,
						due: dueDate,
						status: this.selectedTask.status
					},{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.companyId}/${this.$globals.user.name}`,
					{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
					.then((response) => {
						this.openTasks = response.data.queryResult;
						this.fillCalendar()
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.action=""

				this.toDoInput = ""
				this.toDoDueDate=null
				this.tagColor=""
				//this.$forceUpdate()

			},

			viewTask(record, index) {
			  this.selectedTask = record;
			  this.action = "viewTask";
			},


		}
	}
</script>

<style scoped>


</style>

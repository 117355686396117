<template>


  <div id="app">
    <!--TODO routes will be rendered here -->
    <router-view />
  </div>

</template>
<script>

import axios from 'axios'

export default {
  name: 'app',

  data () {
    return {
      props: {
        activeUser: null,
        roles: [],
      }
    }
  },//END data
  mounted(){
    this.refreshActiveUser();
  },
  methods: {

    refreshActiveUser(){
      if(this.$auth.isAuthenticated){
        this.activeUser = this.$auth.user;
        axios.get(process.env.VUE_APP_API_TARGET + "/users/" + this.activeUser.sub + "/roles/")
      } else {
        this.activeUser = null;
      }
    },//END refreshActiveUser()

    async login(){
      await this.$auth.loginWithRedirect();
      await this.refreshActiveUser();
    },//END login()

    async logout(){
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },//END logout()
  }//END methods
}
</script>

<style scoped>
body {
  margin: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

main {
  text-align: center;
  margin-top: 40px;
}

header {
  margin: 0;
  height: 56px;
  padding: 0 16px 0 24px;
  background-color: #35495E;
  color: #ffffff;
}

header span {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 1;
  letter-spacing: .02em;
  font-weight: 400;
  box-sizing: border-box;
  padding-top: 16px;
}
</style>

<template>
    <div>
		<div v-if="action === ''" class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>New Maintenance Contract</b></h3>
					<p>For Customer: {{selectedCustomer.name}}</p>
				</div>
				<div class="card-tools">
					<b-button
						class="mx-1"
						style="background-color: #fff4; border-color: #dc3545"
                        @click="submitMC()"
						>Submit
					</b-button>
					<b-button
						class="mx-1"
						style="background-color: #fff4; border-color: #dc3545"
						@click="$emit('reject')"
						>Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
                <div class="row">
					<div class="col">
						<label>Contract Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
	                    <input
                            required
	                        class="form-control"
	                        type="text"
	                        v-model="newContract.contractName"
	                        style="align-content: center; align-items: center"
	                    />
					</div>
					<div class="col">
						<label>Vehicles/Assets<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect required :clearable="true" :show-labels="false" :options="allAssetsVehicles" group-label="name" v-model="newContract.assets" :multiple="true" :group-select=false group-values="items" track-by="id" label="id" placeholder="Asset"></multiselect>
						<br>
					</div>
                </div>
                <div class="row" style="align-content: center; align-items: center">
                    <div class="col">
                        <label>Start Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                        <VueCtkDateTimePicker required v-if="$globals.displayClass === false" class="w-100 mb-3" color="#dc3545" button-color="#dc3545" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="newContract.start"/>
                        <VueCtkDateTimePicker required v-else class="w-100 mb-3" color="#dc4535" button-color="#dc4535" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="newContract.start"/>
                    </div>
                    <div class="col">
                        <label>End Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                        <VueCtkDateTimePicker required v-if="$globals.displayClass === false" class="mb-3" color="#dc3545" button-color="#dc3545" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="newContract.end"/>
                        <VueCtkDateTimePicker required v-else class="mb-3"  color="#dc4535" button-color="#dc4535" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="newContract.end"/>
                    </div>
                </div>
                <div class="row">
					<div class="col">
						<label>Price ($)<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
	                    <input
                            required
	                        class="form-control"
	                        type="number"
	                        step="0.01"
	                        min="0"
	                        v-model="newContract.value"
	                        style="align-content: center; align-items: center"
	                    />
					</div>
					<div class="col">
						<label>Occurrence<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							:show-labels="false"
							:options="['Hours', 'Miles', 'Months']"
							v-model="frequencyType"
							placeholder="Occurrence type"
                            required
						>
						</multiselect>
						<br>
						<input class="form-control w-25" v-if="frequencyType !== '' && frequencyType !== null" v-model="frequency" type="number" step="1"/>
					</div>
                </div>
				<div class="row">
					<div class="col"></div>
					<div class="col">
						<form enctype="multipart/form-data">
							<label for="formFileLg">File</label>
							<input
								class="form-control form-control-lg"
								id="formFileLg"
								type="file"
								ref="inputFile"
								name="doc"
							/>
						</form>
					</div>
				</div>

                <b-row style="justify-content: center; margin-top: 1% ">
					<h4><b>Tasks</b></h4>
				</b-row>
                <br>

                <b-row style="justify-content: center" v-for="(tasks, taskIndex) in newContract.tasks" :key="taskIndex">
                    <div class="row mb-3" v-if="editTasks[taskIndex] !== true">
                        <div class="col-6">
                            <b>{{tasks.task}}</b>
                        </div>
                        <div class="col-3">
                            <b>Amount Per Occurrence: {{tasks.amount}}</b>
                        </div>
                        <div class="col-2">
                            <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block; margin-right: 1%" @click="() => editTask(taskIndex)">Edit</b-button>
                            <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block" @click="() => deleteTask(taskIndex)">Delete</b-button>
                        </div>
                    </div>
                    <div v-else class="row mb-3">
                        <div class="col-8">
                            <label>Task<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <textarea required type="text" style="width: 100%; margin-bottom: 0.5%" v-model="tempTask.task" />
                        </div>
                        <div class="col-2">
                            <label>Amount per Occurrence<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input required class="form-control" v-model="tempTask.amount" type="number" step="1"/>
                        </div>
                        <div class="col-2">
                            <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block; margin-right: 1%" @click="updateTask(taskIndex)">Confirm</b-button>
                            <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block;" @click="() => deleteTask(taskIndex)">Delete</b-button>
                        </div>
                    </div>
                </b-row>

                <b-row style="justify-content: center">
                    <div class="col-8">
                        <label>Task<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                        <textarea required v-model="newTask.task" style="border: 1px solid black; border-radius: 10px; width: 100%"/>
                    </div>
                    <div class="col-2">
                        <label>Amount per Occurrence<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                        <input required class="form-control" v-model="newTask.amount" type="number" step="1"/>
                    </div>
                </b-row>
                <b-row style="justify-content: center">
                    <b-button style="margin-top: 1.25%" :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" @click="addTask()">Add Task</b-button>
                </b-row>
                <br>
                <hr style="width: 85%">
                <br>
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
    import axios from "axios";
	import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
	import Multiselect from "vue-multiselect";


    export default {
        components: {
            Multiselect,VueCtkDateTimePicker
        },
        name: '',
        props: {
            selectedCustomer: Object,
        },
        data() {
            return {
                action: '',
                allAssets: '',
                allAssetsVehicles: [],
                allVehicles: '',
                displayClass: '',
                editTasks: [false],
				frequency: '',
				frequencyType: '',
                index: '',
                newTask: {
                    task: '',
                    amount: 1
                },
                newContract: {
                    start: '',
                    end: '',
                    value: '',
                    tasks: [],
                    assets: []
                },
                tempTask: ''
            };
        },
        computed: {

        },

        async created() {

        },

        async mounted() {
            this.getAssetsVehicles()
        },

        methods: {

            async addTask(){
                if(this.newTask.task === "" || this.newTask.task === null || this.newTask.task === undefined){
                    alert("You must enter a task before attaching it to the work order")
                    return
                }
                this.newContract.tasks.push({...this.newTask})
                this.newTask.task = ''
                this.newTask.amount = 1
                this.$forceUpdate()
            },//END addTask

            createAssetGroups(assetsArray, vehiclesArray){
                var extItems = [];
                var scbaItems = [];
                var vehItems = [];
                for(let item in assetsArray){
                    if(assetsArray[item].asset_type == 'Extinguisher'){
                        extItems.push(assetsArray[item]);
                    }
                    else{
                        scbaItems.push(assetsArray[item]);
                    }
                }
                for(let veh in vehiclesArray){
                    vehiclesArray[veh].asset_type = "Vehicle";
                    vehiclesArray[veh].id = vehiclesArray[veh].vehicleid;
                    vehItems.push(vehiclesArray[veh]);
                }

                this.allAssetsVehicles = [];
                this.allAssetsVehicles.push({name: "Extinguisher", items: extItems});
                this.allAssetsVehicles.push({name: "SCBA", items: scbaItems});
                this.allAssetsVehicles.push({name: "Vehicle", items: vehItems});
            },

            deleteTask(taskIndex){
                this.newContract.tasks.splice(taskIndex, 1)
                this.$forceUpdate()
            },

            editTask(taskIndex){
                this.editTasks[taskIndex] = true
                this.tempTask = this.newContract.tasks[taskIndex]
                this.$forceUpdate()
            },

            async getAssetsVehicles(){
                await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+this.selectedCustomer.customerid+"/vehicles/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                    this.allVehicles = response.data.queryResult;
                    await axios.get(process.env.VUE_APP_API_TARGET + '/customers/assets/by/cust/'+this.selectedCustomer.customerid+'/'+this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                        this.allAssets = response.data.queryResult;
                        this.createAssetGroups(this.allAssets, this.allVehicles);
                    })
                    .catch((response)=>{
                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                    })
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

			async submitMC(){
				var formData = new FormData();

				formData.append("customerid", this.selectedCustomer.customerid);
				formData.append("start", this.newContract.start.split(' ')[0]);
				formData.append("end", this.newContract.end.split(' ')[0]);
				formData.append("value", this.newContract.value);
				formData.append("tasks", JSON.stringify(this.newContract.tasks));
				formData.append("frequency", this.frequency);
				formData.append("frequencyType", this.frequencyType);
				formData.append("contractName", this.newContract.contractName);
				formData.append("file", this.$refs.inputFile.files[0]);


				let linkedAssets = []
				this.newContract.assets.forEach((item, i) => {
					linkedAssets.push({
						id: item.vehicleid || item.id,
						completedTasks: []
					})
				});

				formData.append("linkedAssets", JSON.stringify(linkedAssets));

                await axios.post(`${process.env.VUE_APP_API_TARGET}/contracts/newMC/${this.$globals.companyId}`,formData,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then(response => {
                    for(var assetvehicle in this.newContract.assets){
						console.log("response loop: ", assetvehicle, this.newContract.assets[assetvehicle]);
                        if(this.newContract.assets[assetvehicle].asset_type === "Vehicle"){
                            axios.put(`${process.env.VUE_APP_API_TARGET}/contracts/vehicle/${this.newContract.assets[assetvehicle].vehicleid}/${this.$globals.companyId}`,
                            {
								customerid: this.selectedCustomer.customerid,
                                contractid: response.data.queryResult.insertId
                            },
                            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
                            )
                            .catch((response)=>{
                                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                            })
                        }
                        else{
                            axios.put(`${process.env.VUE_APP_API_TARGET}/contracts/asset/${this.newContract.assets[assetvehicle].id}/${this.$globals.companyId}`,{
								customerid: this.selectedCustomer.customerid,
                                contractid: response.data.queryResult.insertId
                            },
                            {headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}})
                            .catch((response)=>{
                                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                            })
                        }
                    }
					this.$emit('reject')
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

            async updateTask(taskIndex){
                this.newContract.tasks[taskIndex] = this.tempTask
                this.editTasks[taskIndex] = false
                this.$forceUpdate()
            },//END updateTask

        },
    };
</script>

<style>
</style>

<template>

	<div style="overflow-y: scroll;">
		<chip v-for="(salesmen,index) in allSalesmen" :key="index"
			:name="salesmen.name"
			:picture="salesmen.pic" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'"
			:rank="index+1"
			:measuredData="salesmen.total"
			></chip>
	</div>

</template>


<script>
/*eslint-disable*/
import axios from "axios";
import chip from "./salesmenChips.vue"

export default {
	name: "app",
	props: {
		companyId: Number,
		user: Object,
        dataType: String,
        period: String,
	},
	components: {
		chip
	},
	computed: {

    },
    watch: {
        period: function (newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            switch (this.dataType) {
                //NOTE: capitals matter here
                //TODO handle other dataType
                case "invoice":
                    this.associateSalesmenWithInvoices()
                    break;
                case "props":
                    this.associateSalesmenWithProposals()
                    break;
                case "sales":
                    this.associateSalesmenWithSalesOrders()
                    break;
                default:
                    console.log("Defaulted on switch, doing invoices")
                    this.associateSalesmenWithInvoices()
            }
            this.$forceUpdate()
        },

        dataType: function (newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            switch (this.dataType) {
                //NOTE: capitals matter here
                //TODO handle other dataType
                case "invoice":
                    this.associateSalesmenWithInvoices()
                    break;
                case "props":
                    this.associateSalesmenWithProposals()
                    break;
                case "sales":
                    this.associateSalesmenWithSalesOrders()
                    break;
                default:
                    console.log("Defaulted on switch, doing invoices")
                    this.associateSalesmenWithInvoices()
            }
            this.$forceUpdate()
        }
    },
	data(){
		return{
			allSalesmen:[],

		};
	},//END data
	async mounted(){
		await this.getAllSalesmen();
		switch (this.dataType) {
			//NOTE: capitals matter here
			//TODO handle other dataType
			case "invoice":
				this.associateSalesmenWithInvoices()
				break;
			default:
				console.log("Defaulted on switch, doing invoices")
				this.associateSalesmenWithInvoices()
        }

	},
	methods:{
		async getAllSalesmen(){
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/sales/map/all/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(async(response) => {
				for (var salesman in response.data.queryResult) {
					this.allSalesmen.push({...response.data.queryResult[salesman], total: 0});
					await axios.get(process.env.VUE_APP_API_TARGET + '/users/profile/byId/'+ response.data.queryResult[salesman].auth0, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then((response) =>{
						this.allSalesmen[salesman] = {...this.allSalesmen[salesman], pic: response.data.queryResult.picture}
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Leaderboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Leaderboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

        async associateSalesmenWithInvoices() {
            this.allSalesmen.forEach((salesman) => salesman.total = 0)
			await axios.get(process.env.VUE_APP_API_TARGET + '/sales/invoices/'+this.$globals.companyId+'/get/totals/'+this.period,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then((response) => {
                let resArray = response.data.queryResult
				for (let index in resArray){
					if(resArray[index].salesman !== null){
						for(let secondIndex in this.allSalesmen){
							if(resArray[index].salesman == this.allSalesmen[secondIndex].name){
								this.allSalesmen[secondIndex].total =  resArray[index].total
							}
						}
					}
				}
				this.sortByValue()

			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Leaderboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async associateSalesmenWithSalesOrders() {
            await axios.get(process.env.VUE_APP_API_TARGET + '/sales/orders/'+this.$globals.companyId+'/get/totals/'+this.period,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then((response) => {
                let resArray = response.data.queryResult
				for (let index in resArray){
					if(resArray[index].salesman !== null){
						for(let secondIndex in this.allSalesmen){
							if(resArray[index].salesman == this.allSalesmen[secondIndex].name){
								this.allSalesmen[secondIndex].total =  resArray[index].total
							}
						}
					}
				}
				this.sortByValue()

			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Leaderboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async associateSalesmenWithProposals() {
            await axios.get(process.env.VUE_APP_API_TARGET + '/sales/proposals/'+this.$globals.companyId+'/get/totals/'+this.period,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then((response) => {
                let resArray = response.data.queryResult
				for (let index in resArray){
					if(resArray[index].salesman !== null){
						for(let secondIndex in this.allSalesmen){
							if(resArray[index].salesman == this.allSalesmen[secondIndex].name){
								this.allSalesmen[secondIndex].total =  resArray[index].total
							}
						}
					}
				}
				this.sortByValue()

			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Leaderboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

		sortByValue(){
			this.allSalesmen.sort(function (a, b) {
				return b.total - a.total;
			});

		},

	}//END methods


}
</script>

<style scoped>
::webkit-scrollbar {
    display: none;
}
</style>

<template>
    <div>
        <div v-if="action=== ''" class="card card-danger">
            <div class="card-header">
                <div class="card-title">
                    <h2><b>Asset Information</b></h2>
                </div>
                <div class="card-tools">
                    <b-button
						v-if="selectedAsset.contractid !== null && selectedAsset.contractid > 0 && subaction !== 'editAsset'"
                        style="
                        	background-color: #fff4;
                        	border-color: #dc3545;
                        	color: #ffffff;"
                        @click.prevent="action = 'viewMaintenanceContract'"
                        >View Maintenance Contract
                    </b-button>

					<b-button v-if="subaction !== 'editAsset' && selectedAsset.archived == 0" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="archiveAsset(1)">Retire</b-button>
					<b-button v-if="subaction !== 'editAsset' && selectedAsset.archived > 0" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="archiveAsset(0)">Reinstate</b-button>

					<b-button v-if="subaction !== 'editAsset'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="editAsset()">Edit</b-button>
					<b-button v-if="subaction === 'editAsset'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="subaction=''">Cancel Edit</b-button>
					<b-button v-if="subaction === 'editAsset'" class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="updateAsset(editingAsset)">Confirm</b-button>

					<b-button style="background-color: #fff4; border-color: #dc3545; color: #ffffff; float: right" @click.prevent="$emit('return')">Return</b-button>
				</div>
            </div>
            <div class="card-body">
				<div>
					<div style="display: flex; justify-content: center">
						<h3>Customer: <b v-if="subaction !== 'editAsset'">{{ originalCustomer.name }}</b> </h3>
						<multiselect
							v-if="subaction === 'editAsset'"
							style="display: inline-block; width:50%;, height: 100px;"
							label="name"
							track-by="customerid"
							:clearable="true"
							:show-labels="false"
							:options="allCustomers"
							:optionsLimit="10000"
							v-model="selectedCustomer"
							@input="rowClicked"
							placeholder="Customer"
							@search-change="debounceCustomers"
							:loading="customerListIsLoading"
						>
							<template slot="option" slot-scope="props">
								<intersect v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
									<span>{{props.option.name}}</span>
								</intersect>
							</template>
						</multiselect>
					</div>

					<div v-if="subaction === 'editAsset'" style="display: flex; justify-content: center">
						<b-row>
							<h3>Asset ID:</h3>
							<input type="text" class="form-control" style="display: inline-block" placeholder="Asset ID" v-model="editingAsset.id" />
						</b-row>
					</div>
					<div v-if="subaction !== 'editAsset'" style="display: flex; justify-content: center">
						<h3>Asset ID: {{ selectedAsset.id }}</h3>
						<i v-if="$globals.canAccess.admin === true" class="fas fa-trash" size="sm" @click.stop="removeAsset(selectedAsset)"></i>
					</div>
				</div>
				<hr>
				<div>
					<b-tabs fill lazy v-model="selectedTab">
						<b-tab title="General" :title-link-class="'text-danger'">
							<div v-if="subaction !== 'editAsset'">
								<b-row>
									<b-col>
										<strong>Type: &nbsp;</strong> {{ selectedAsset.asset_type }}
									</b-col>

									<b-col>
										<strong>Serial: &nbsp;</strong> {{ selectedAsset.serial }}
									</b-col>

									<b-col>
										<strong>Location: &nbsp;</strong>
										<span v-if="selectedAsset.location !== null">{{ selectedAsset.location.name || selectedAsset.location}}</span>
										<span v-else>{{''}}</span>
									</b-col>

								</b-row>

								<b-row>
									<b-col>
										<strong>Note: &nbsp;</strong> {{selectedAsset.notes}}
									</b-col>
								</b-row>
							</div>

							<div v-else>
								<b-row>
									<b-col>
										<label for="a_serial" class="w-75 text-left mt-2" style="margin-left: 13.5%">Type<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
										<input class="form-control w-75" style="margin-left: 12.5%" type="text" v-model="editingAsset.asset_type" readonly required/>
									</b-col>

									<b-col>
										<label for="a_serial" class="w-75 text-left mt-2" style="margin-left: 13.5%">Serial Number<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
										<input id="a_serial" class="form-control w-75" style="margin-left: 12.5%;" type="text" v-model="editingAsset.serial" placeholder="Serial Number" required />
									</b-col>

									<b-col>
										<label for="a_house" class="w-75 text-left mt-2">Location</label>
										<multiselect
											id="a_house"
											:options="allHouses"
											track-by="houseid"
											label="name"
											class="w-75"
											style="margin-left: 12.5%;"
											:show-labels="false"
											v-model="editingAsset.location"
											placeholder="Location"
											>
										</multiselect>
									</b-col>
								</b-row>

								<b-row>
									<b-col>
										<strong>Note: &nbsp;</strong>
										<textarea class="form-control" rows="2" placeholder="Notes" v-model="editingAsset.notes"></textarea>
									</b-col>
								</b-row>
							</div>
						</b-tab>

						<b-tab title="Tecnhical" :title-link-class="'text-danger'">
							<div class="row" v-if="subaction !== 'editAsset'">
								<div class="col-4" v-for="(value, key) in selectedAsset.technical_fields" :key="key">
									<strong>{{ key }}: &nbsp;</strong> {{ value }} <wbr />
								</div>
							</div>

							<div v-else>
								<b-col v-for="(value, key) in editingAsset.technical_fields" :key="key" style="text-align: center; text-justify: inter-word">
									<b-row>
										<b-col>
											<strong>{{ key }}:</strong>
										</b-col>
										<b-col>
											<input class="form-control" type="text" v-model="editingAsset.technical_fields[key]" placeholder="Field Value" />
										</b-col>
										<b-col>
											<i class="fas fa-trash" style="display: inline-block" @click="removeCustomFields(editingAsset.technical_fields, key)"></i>
										</b-col>
									</b-row>
								</b-col>
								<b-row>
									<b-col style="margin-left: 12.5%">
										<input class="form-control" type="text" v-model="newField.name" placeholder="Field Name" />
									</b-col>
									<b-col style="margin: auto">
										<input class="form-control" type="text" v-model="newField.value" placeholder="Field Value" />
									</b-col>
									<b-col>
										<b-button style="background-color: #dc3545; border-color: #dc3545; margin: auto;" @click.prevent="pushField(editingAsset.technical_fields, 'technical')">Submit</b-button>
									</b-col>
								</b-row>
							</div>
						</b-tab>

						<b-tab title="Warranty" :title-link-class="'text-danger'">
							<div class="row" v-if="subaction !== 'editAsset'">
								<div class="col-4" v-for="(warranty, index) in selectedAsset.warranty_fields" :key="index" style="margin-top: 2%">
									<div class="col">
										<strong>{{ warranty.name }}: </strong> {{warranty.value}}
									</div>
									<div class="col">
										<strong>Start/In Service: </strong>&nbsp;{{ warranty.start.split(' ')[0] }}
									</div>
								</div>
								<wbr />
							</div>

							<div v-else>
								<b-row v-for="(warranty, index) in editingAsset.warranty_fields" :key="index" style="text-align: center; text-justify: inter-word">
									<b-col>
										<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="warranty.start"/>
									</b-col>
									<b-col>
										<strong>{{ warranty.name }}:</strong>
									</b-col>
									<b-col>
										<input class="form-control" type="text" v-model="warranty.value" placeholder="Field Value" />
									</b-col>
									<b-col>
										<i class="fas fa-trash" style="display: inline-block" @click="editingAsset.warranty_fields.splice(index, 1)"></i>
									</b-col>
								</b-row>

								<b-row style="text-align: center; text-justify: inter-word">
									<b-col>
										<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newWarranty.start"/>
									</b-col>
									<b-col>
										<input class="form-control" type="text" v-model="newWarranty.name" placeholder="Field Name" />
									</b-col>
									<b-col>
										<input class="form-control" type="text" v-model="newWarranty.value" placeholder="Field Value" />
									</b-col>
									<b-col>
										<b-button style="background-color: #dc3545; border-color: #dc3545;" @click.prevent="pushNewWarranty()">Submit</b-button>
									</b-col>
								</b-row>
							</div>
						</b-tab>

						<b-tab title="History" :title-link-class="'text-danger'">
							<assetHistory :selectedAsset="selectedAsset" @viewTicket="shiftViewTicket(arguments[0])"></assetHistory>
						</b-tab>
					</b-tabs>
				</div>
            </div>
        </div>

        <div v-if="action === 'viewMaintenanceContract'">
            <viewMaintenanceContract :contractNumber="selectedAsset.contractid" :selectedCustomer="selectedCustomer" @reject="action = ''"></viewMaintenanceContract>
        </div>

		<div v-if="action === 'viewTicket'">
			<viewTicket :selectedTicket="selectedTicket" @return="action=''"></viewTicket>
		</div>
    </div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import assetHistory from './assetHistory.vue';
import viewMaintenanceContract from '../MaintenanceContracts/viewMaintenanceContract.vue'
import Multiselect from "vue-multiselect";
import { debounce } from 'lodash';
import Intersect from 'vue-intersect';
import viewTicket from '../Service/viewTicket.vue';

export default {
	name: 'viewAsset',
    props:{
        selectedCustomer: Object,
        selectedAsset: Object
    },

    components: {
        VueCtkDateTimePicker,
        assetHistory,
        viewMaintenanceContract,
		Multiselect,
		Intersect,
		viewTicket,
    },

    data() {
        return {
			action: '',
			allHouses: [],
			allCustomers: [],
			customerListIsLoading: false,
			editingAsset: {},
			maxPages: 0,
			maintenanceContracts: [],
			newField: {},
			newWarranty: {
				start: '',
				name: '',
				value: ''
			},
			pageNumber: 0,
			prevSearch: '',
			originalCustomer: {},
			selectedTab: null,
            subaction: '',
        }
    },

	async mounted(){
		// console.log(this.$options.name, this.selectedAsset);
		this.selectedAsset.warranty_fields = typeof this.selectedAsset.warranty_fields == 'object' ? this.selectedAsset.warranty_fields : JSON.parse(this.selectedAsset.warranty_fields)
		this.selectedAsset.technical_fields = typeof this.selectedAsset.technical_fields == 'object' ? this.selectedAsset.technical_fields : JSON.parse(this.selectedAsset.technical_fields)

		this.originalCustomer = this.selectedCustomer
		await this.getMaintenanceContracts();
		await this.getHouses(this.originalCustomer);

		this.debounceCustomers = debounce(this.searchCustomers, 600);

	},

    methods: {
		archiveAsset(status){
			axios.patch(`${process.env.VUE_APP_API_TARGET}/assets/archive/${this.selectedAsset.serial}/${this.$globals.companyId}`,
				{status: status},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response)=>{
				this.selectedAsset.archived = status
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async editAsset(){
			if(typeof this.selectedAsset.location === 'string'){
				this.selectedAsset.location = {name: this.selectedAsset.location}
			}
            this.editingAsset = _.cloneDeep(this.selectedAsset)
			await this.getCustomers();
            this.subaction = 'editAsset';
            this.$forceUpdate();
        },

		async getHouses(option) {
			await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${option.customerid}/houses/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then(response =>{
				this.allHouses = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getCustomers(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.allCustomers = response.data.queryResult.results
				this.maxPages = response.data.queryResult.maxPages
				this.pageNumber = 1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getMoreCustomers(){
			if(this.pageNumber >= this.maxPages){
				return
			}
			let text = this.prevSearch === "" ? text = null : text = this.prevSearch
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				for(var item in response.data.queryResult.results){
					this.allCustomers.push(response.data.queryResult.results[item])
				}
				this.pageNumber = this.pageNumber+1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getMaintenanceContracts(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/customer/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
			  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response) => {
				this.maintenanceContracts = response.data.queryResult;
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
		},

		pushNewWarranty(){
			//NOTE: not a required field but if filled in needs to have correct data
			if(this.newWarranty.start == '' || this.newWarranty.start == null || this.newWarranty.start == undefined){
				alert("Warranty requires a start or an enter service date")
				return;
			}
			if(this.newWarranty.name == '' || this.newWarranty.name == null || this.newWarranty.name == undefined){
				alert("Warranty requires a field")
				return;
			}
			if(this.newWarranty.value == '' || this.newWarranty.value == null || this.newWarranty.value == undefined){
				alert("Warranty requires a value")
				return;
			}

			if(this.editingAsset.warranty_fields == null){
				this.editingAsset.warranty_fields = []
			}
			this.editingAsset.warranty_fields.push({
				name: this.newWarranty.name,
				start: this.newWarranty.start,
				value: this.newWarranty.value
			})

			this.newWarranty = {
				name: '',
				start: '',
				value: ''
			}
		},

		pushField(existingField, type) {
			if(this.newField.name == null || this.newField.name === '' || this.newField?.name === undefined){
				alert("New custom field should be named")
				return;
			}
			if(this.newField.value == null || this.newField.value === '' || this.newField?.value === undefined){
				alert("New custom field should have a value")
				return;
			}
			existingField = (existingField === null ? {} : (typeof existingField === 'object' ? existingField : JSON.parse(existingField)))

			existingField[this.newField.name] = this.newField.value;

			if(type == 'custom'){
				this.editingAsset.custom_fields = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})

			}
			else if(type == 'technical'){
				this.editingAsset.technical_fields = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})
			}
			else{
				return;
			}
			this.newField.value = '';
			this.newField.name = ''
		},

		async removeAsset(asset){
			//console.log("remove Asset: ", asset);
            if(confirm(`Are you sure you want to permanently delete ${asset.id}?`)){
                await axios.delete(`${process.env.VUE_APP_API_TARGET}/assets/${asset.id}/${this.selectedCustomer.customerid}/${this.$globals.companyId}`, {
                    headers: {
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                })
				.then((response)=>{
					alert(response.data.message)
					this.$emit('return')
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
                this.$emit('return');
            }
        },

		removeCustomFields(field, key){
			delete field[key]
			this.newField.value = '';
			this.newField.name = ''
			this.$forceUpdate();
		},

		async rowClicked(record) {
			if(record === null){
				return
			}
			this.selectedCustomer = record
			this.editingAsset.customerid = record.customerid;
			this.editingAsset.location = null;
			this.isBusy = false;
			this.getHouses(this.selectedCustomer);
			//this.getUsedUnitIDs(this.selectedCustomer);
			this.editingAsset.location = null
			this.editingAsset.houseid = null
			this.editingAsset.address = null
			this.newAddress = null
		},

		shiftViewTicket(x){
			this.action='viewTicket';
			this.selectedTicket=x
		},

		async searchCustomers(searchValue){
			if(searchValue === "" || searchValue === null){
				if(this.prevSearch === "" || this.prevSearch === null){
					return
				}
				searchValue = null
			}
			this.customerListIsLoading = true
			this.pageNumber = 0
			this.prevSearch = searchValue
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${searchValue}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.allCustomers = response.data.queryResult.results
				this.maxPages = response.data.queryResult.maxPages
				this.pageNumber = this.pageNumber+1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.customerListIsLoading = false
		},

		async updateAsset(asset){
			//Safety
			if(asset.serial == null || asset.serial == '' || asset.serial == undefined){
				alert("A serial # is required")
				return;
			}
			if(asset.asset_type == undefined || asset.asset_type == '' || asset.asset_type == null){
				alert("Type is required")
				return;
			}

			if(typeof asset.location === 'object'){
				asset.location = asset.location.name
			}

			console.log("Start update");

            //Asset warranty status can be calculated
            await axios.patch(`${process.env.VUE_APP_API_TARGET}/assets/serial/${this.selectedAsset.serial}/${this.originalCustomer.customerid}/${this.$globals.companyId}`,{
                serial: asset.serial,
                location: asset.location,
                customerid: asset.customerid,
                id: asset.id,
				oldID: this.selectedAsset.id,
                warranty_fields: JSON.stringify(asset.warranty_fields),
				technical_fields: JSON.stringify(asset.technical_fields),
				notes: asset.notes
            },
            {
                headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            })
			.then((response)=>{
				this.selectedAsset = this.editingAsset
				this.subaction = '';
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			console.log("after update");
        },
    }
}
</script>
<style>

</style>

<template>
	<div>
		<div v-if="action === ''">
			<multiselect :options="functionsArray" v-model="selectedFunction" @select="calculateSell" track-by="name" label="name" :multiple="false" :show-labels="false">
			</multiselect>

		</div>

	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default{
	name: "app",
	props:{
		item: Object,
		customer: Object,
		pastFun: Object
	},
	components: { Multiselect },
	data(){
		return{
			action: '',
			functionsArray: [
				{
					name: 'DEFAULT',
					equation: `ITEM_COST * ( 1 + ITEM_MARK_UP )`
				}
			],
			selectedFunction: '',
			selectedCust: {}

		};
	},
	async mounted(){
		await this.getFunctionList()
		if(this.customer == '' || this.customer == null){
			this.selectedCust = {}
		}
		else{
			this.selectedCust = this.customer
		}

		if((this.item.highCost === undefined || this.item.itemMarkup === undefined) && !this.item.itemid.includes('**')){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(this.item.itemid)}/total/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				this.item.highCost = this.item.highCost ?? parseFloat(response.data.queryResult[0].highCost)
				this.item.itemMarkup = this.item.itemMarkup ?? parseFloat(response.data.queryResult[0].itemMarkup ?? 0)
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'selectSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		}
		if((this.item.vendorMarkup === null || this.item.vendorMarkup === undefined) && !this.item.itemid.includes('**')){
			await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.item.vendor)}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			}).then((response)=>{
				this.item.vendorMarkup = parseFloat(response.data.queryResult[0]?.markup) || 0.1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'selectSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		}

		if(this.pastFun !== null && this.pastFun !== undefined){
			this.selectedFunction = this.pastFun
		}
		else{
			this.selectedFunction = this.functionsArray[0]
		}

		await this.calculateSell(this.selectedFunction)

	},
	methods:{
		calculateSell(fun){
			this.$nextTick(()=>{
				this.item.list = this.item?.list ?? 0
				this.item.highCost = this.item?.highCost ?? 0
				this.item.itemMarkup = this.item?.itemMarkup ?? 0
				this.item.vendorMarkup = this.item?.vendorMarkup ?? 0
                this.selectedCust.discount = this.selectedCust?.discount ?? 0

				fun.equation = fun.equation.replaceAll('ITEM_LIST', `${parseFloat(this.item.list)}`)
				fun.equation = fun.equation.replaceAll('ITEM_COST', `${parseFloat(this.item.highCost) || parseFloat(this.item.cost)}`) //high cost should be used but average cost should also just exist.
				fun.equation = fun.equation.replaceAll('ITEM_MARK_UP', `${parseFloat(this.item.itemMarkup)}`)
				fun.equation = fun.equation.replaceAll('VENDOR_MARK_UP', `${parseFloat(this.item.vendorMarkup)}`)
				fun.equation = fun.equation.replaceAll('CUST_DISCOUNT', `${parseFloat(this.selectedCust.discount)}`)
				fun.equation = fun.equation.replaceAll('CONSTANT','')

				this.$emit('evaluatedSell', {sell: eval(fun.equation).toFixed(2), fun: this.selectedFunction})
			})

		},

		async getFunctionList(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/functionList/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				if(response.data.queryResult.length > 0){
					this.functionsArray = response.data.queryResult
				}
				console.log("getFL: ", this.functionsArray);
				this.$forceUpdate()
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'selectSellFunction', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},



	},
}
</script>

<style>

</style>

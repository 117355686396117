<template>
	<div>
		<div v-if="action === ''">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Vehicle</b></h3>
					</div>
					<div class="card-tools">
						<b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="create()">Submit</b-button>
						<b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="$emit('return')">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<form>
						<div class="row d-flex">
							<div class="form-group col">
								<label for="vehicleid" class="w-75 text-left mt-2">Vehicle Id<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<input required id="vehicleid" type="text" class="form-control w-75" placeholder="Identifier for the vehicle" v-model="newVehicle.vehicleid"/>

								<label for="vin" class="w-75 text-left mt-2">VIN<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
	                            <input required id="vin" type="text" class="form-control w-75" placeholder="VIN #" v-model="newVehicle.vin" />

								<label for="location" class="w-75 text-left mt-2">Location</label>
								<multiselect
									required
									class="w-75"
									:searchable="true"
									v-model="newVehicle.location"
									:clearable="true"
									:options="baseLocations"
									label="name"
									track-by="name"
									placeholder="Based/Housed Location"
									:show-labels="false">
								</multiselect>


								<label for="user" class="w-75 text-left mt-2">Personel Responsible</label>
								<multiselect
									required
									class="w-75"
									:searchable="true"
									v-model="newVehicle.responsibleUser"
									:clearable="true"
									:options="allUsers"
									label="name"
									track-by="name"
									placeholder="User assigned Control"
									:show-labels="false">
								</multiselect>
							</div>
							<div class="form-group col">
								<label for="make" class="w-75 text-left mt-2">Make</label>
								<input id="make" type="text" class="form-control w-75" placeholder="Vehicle Make" v-model="newVehicle.make" />

								<label for="model" class="w-75 text-left mt-2">Model</label>
								<input  id="model" type="text" class="form-control w-75" placeholder="Vehicle Model" v-model="newVehicle.model" />

								<label for="year" class="w-75 text-left mt-2">Year</label>
								<input  id="year" type="text" class="form-control w-75" placeholder="Vehicle Year" v-model="newVehicle.year" />

								<label for="Note" class="w-75 text-left mt-2">Note</label>
								<textarea id="Note" class="form-control w-75" placeholder="Vehicle Note" v-model="newVehicle.note" />
							</div>
						</div>
						<div class="row d-flex">
							<div class="form-group col">
								<label for="state" class="w-75 text-left mt-2">State License</label>
								<multiselect
									required
									class="w-75"
									:searchable="true"
									v-model="newVehicle.license.state"
									:clearable="true"
									:options="$globals.allStates"
									label="name"
									track-by="name"
									placeholder="State License"
									:show-labels="false">
								</multiselect>

								<label for="plateNo" class="w-75 text-left mt-2">License Plate Number</label>
								<input  id="plateNo" type="text" class="form-control w-75" placeholder="License Plate #" v-model="newVehicle.license.number" />

								<label for="rating" class="w-75 text-left mt-2">Plate Rating</label>
								<input  id="rating" type="text" class="form-control w-75" placeholder="Plate Rating" v-model="newVehicle.license.rating" />

								<label for="expires" class="w-75 text-left mt-2">Plate Experation</label>
								<input  id="expires" type="text" class="form-control w-75" placeholder="Plate Experation" v-model="newVehicle.license.expires" />
							</div>
							<div class="form-group col">
								<label for="cost" class="w-75 text-left mt-2">Cost when New</label>
								<input id="cost" type="text" class="form-control w-75" placeholder="$ When Bought" v-model="newVehicle.cost" />
							</div>
						</div>
					</form>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import Multiselect from 'vue-multiselect'


export default{
	props: {

	},

	components: {
		Multiselect
	},

	data(){
		return {
			action: '',
			allUsers: [],
			baseLocations: [],
			newVehicle: {
				vehicleid :'',
				vin : '',
				location : '',
				responsibleUser: '',
				make: '',
				model: '',
				year : '',
				note : '',
				license: {
					state: '',
					number: '',
					rating: '',
					expires: ''
				},
				cost : ''
			},

		}
	},

	created(){

	},

	async mounted(){
		await axios.get(process.env.VUE_APP_API_TARGET + "/locations/" + this.$globals.companyId,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		).then((response) => {
			this.baseLocations = response.data.queryResult;
		}).catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		await axios.get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		).then((response) => {
			this.allUsers = response.data.queryResult;
		}).catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

	},

	methods: {
		create(){
			if(this.newVehicle.vin == '' || this.newVehicle.vin == null){
				alert("Vin is required")
				return;
			}

			if(
				(this.newVehicle.license.state == '' || this.newVehicle.license.state == null) &&
				(this.newVehicle.license.number == '' || this.newVehicle.license.number == null) &&
				(this.newVehicle.license.rating == '' || this.newVehicle.license.rating == null) &&
				(this.newVehicle.license.expires == '' || this.newVehicle.license.expires == null)
			){
				this.newVehicle.license = null;
			}
			else{
				this.newVehicle.license = JSON.stringify(this.newVehicle.license)
			}

			axios.post(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicle/${this.$globals.companyId}`,{
				vin: this.newVehicle.vin,
				vehicleid: this.newVehicle.vehicleid || null,
				location: this.newVehicle.location?.name || null,
				responsibleUser: this.newVehicle.responsibleUser?.name || null,
				make: this.newVehicle.make || null,
				model: this.newVehicle.model || null,
				year: this.newVehicle.year || null,
				note: this.newVehicle.note || null,
				license: this.newVehicle.license, //Stringify above
				cost: this.newVehicle.cost || null
			},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response)=>{
				this.$emit('return')
			}).catch((response)=>{
				this.newVehicle.license = typeof this.newVehicle.license === 'string' ? JSON.parse(this.newVehicle.license) : {}//parse back incase user tries again.
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newCompanyVehicle', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		},

	}
}

</script>

<style>

</style>

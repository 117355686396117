/*eslint-disable*/
import { DomClassList } from '@bryntum/scheduler';
import jsPDF from 'jspdf';

function printTicket(ticket, vehicleInfo, totalsArray) {
    var doc = new jsPDF();

    ticket.asset =
        typeof ticket.asset !== 'object'
            ? JSON.parse(ticket.asset)
            : ticket.asset;

    ticket.technician =
        typeof ticket.technician !== 'object'
            ? JSON.parse(ticket.technician)
            : ticket.technician;

    ticket.data =
        typeof ticket.data !== 'object' ? JSON.parse(ticket.data) : ticket.data;

    var verticalPointer = 50;
    var xOffset = doc.internal.pageSize.width / 2;

    doc.setFontSize(16);
    doc.setFont('Helvetica', 'bold');
    doc.text(
        `Work Order ${ticket.localid}`,
        xOffset,
        verticalPointer,
        'center'
    );
    doc.text(
        `Customer: ${ticket.customer}`,
        xOffset,
        (verticalPointer += 10),
        'center'
    );

    doc.setFontSize(14);
    doc.text(
        `${ticket.contact.title ?? ''} ${ticket.contact.name} - ${ticket.contact.phone ?? 'Phone Not Provided'}`,
        xOffset,
        (verticalPointer += 10),
        'center'
    );

    doc.text(`Asset(s): `, xOffset, (verticalPointer += 20), 'center');
    if (ticket.asset.length > 0) {
        ticket.asset.forEach(asset => {
            doc.text(
                `${asset} ${
                    vehicleInfo?.vehicleid === asset
                        ? '| ' + vehicleInfo.vin
                        : ''
                }`,
                xOffset,
                (verticalPointer += 10),
                'center'
            );
            doc.text(
                `${
                    vehicleInfo
                        ? ('' + (vehicleInfo.year ?? '') +
							' ' + (vehicleInfo.make ??'') +
							' ' + (vehicleInfo.model ?? '')
						) : ''
                }`,
                xOffset,
                (verticalPointer += 7),
                'center'
            );
			doc.text(
				`Note: ${vehicleInfo ? (vehicleInfo.notes) : ''}`,
				xOffset,
				(verticalPointer += 5),
				'center'
			);
        });
    }

    doc.text(`Technician(s): `, xOffset, (verticalPointer += 20), 'center');
    if (ticket.technician.length > 0) {
        ticket.technician.forEach(tech => {
            doc.text(
                `${tech.name ?? ''}`,
                xOffset,
                (verticalPointer += 10),
                'center'
            );
        });
    }

    doc.text(
        `Written by: ${ticket.author} | ${ticket.description}`,
        xOffset,
        (verticalPointer += 20),
        'center'
    );

    doc.addPage();
    verticalPointer = 10;

    if (ticket.data.length > 0) {
        ticket.data.forEach((task, index) => {
            doc.setFontSize(16);
            doc.setFont('Helvetica', 'bold');
            doc.setTextColor('#dc3545');
            doc.text(
                `${task.task} - Estimated : ${
                    task.labor
                } hrs. - Labor : ${totalsArray[index]} hrs.`,
                xOffset,
                (verticalPointer += 10),
                'center'
            );

            doc.setTextColor('black');

            doc.line(80, (verticalPointer += 5), 130, verticalPointer);

            doc.setFontSize(14);
            doc.setFont('Helvetica', 'bold');
            doc.text(
                'Work Requested: ',
                xOffset,
                (verticalPointer += 10),
                'center'
            );

            doc.setFontSize(12);
            doc.setFont('Helvetica', 'normal');
            var lineHeight =
                doc.getLineHeight(task.desc) / doc.internal.scaleFactor;
            var splitText = doc.splitTextToSize(task.desc, 200);
            var lines = splitText.length;
            var blockHeight = lines * lineHeight;

            doc.text(splitText, xOffset, (verticalPointer += 10), {
                align: 'center'
            });
            verticalPointer += blockHeight;

            doc.line(80, (verticalPointer += 5), 130, verticalPointer);

            doc.setFontSize(14);
            doc.setFont('Helvetica', 'bold');
            doc.text(
                'Internal Notes: ',
                xOffset,
                (verticalPointer += 10),
                'center'
            );

            doc.setFontSize(12);
            doc.setFont('Helvetica', 'normal');

            lineHeight =
                doc.getLineHeight(task.internalDesc) / doc.internal.scaleFactor;
            splitText = doc.splitTextToSize(task.internalDesc, 200);
            lines = splitText.length;
            blockHeight = lines * lineHeight;

            doc.text(splitText, xOffset, (verticalPointer += 10), {
                align: 'center'
            });
            verticalPointer += blockHeight;

            task.comments =
                typeof task.comments !== 'object'
                    ? JSON.parse(task.comments)
                    : task.comments;

            if (task.comments.length > 0) {
                doc.setFontSize(14);
                doc.setFont('Helvetica', 'bold');
                doc.text(
                    'Tech Comments: ',
                    xOffset,
                    (verticalPointer += 10),
                    'center'
                );
                doc.line(80, (verticalPointer += 5), 130, verticalPointer);

                doc.setFontSize(12);
                doc.setFont('Helvetica', 'normal');
                task.comments.forEach(comment => {
                    if (verticalPointer >= 230) {
                        doc.addPage();
                        verticalPointer = 20;
                    }
                    doc.setFont('Helvetica', 'bold');
                    doc.text(
                        `${comment.user} - ${comment.time}`,
                        xOffset,
                        (verticalPointer += 10),
                        'center'
                    );

                    doc.setFont('Helvetica', 'normal');
                    splitText = doc.splitTextToSize(
                        Number(
                            comment.comment.charCodeAt(
                                comment.comment.length - 1
                            )
                        ).toString(16) === 'fffc'
                            ? comment.comment.slice(0, -1)
                            : comment.comment,
                        200
                    );
                    lines = splitText.length;
                    blockHeight = lines * lineHeight;

                    doc.text(splitText, xOffset, (verticalPointer += 10), {
                        align: 'center'
                    });
                    verticalPointer += blockHeight;
                });
            }

            task.parts =
                typeof task.parts !== 'object'
                    ? JSON.parse(task.parts)
                    : task.parts;
            if (task.parts.length > 0) {
                doc.setFontSize(14);
                doc.setFont('Helvetica', 'bold');
                doc.text(
                    'Assigned Parts: ',
                    xOffset,
                    (verticalPointer += 10),
                    'center'
                );

                doc.setFontSize(12);
                doc.setFont('Helvetica', 'normal');
                task.parts.forEach(part => {
                    doc.text(
                        `${part.itemid}`,
                        xOffset,
                        (verticalPointer += 10),
                        'center'
                    );
                    doc.text(
                        `${part.itemdesc}`,
                        xOffset,
                        (verticalPointer += 7.5),
                        'center'
                    );
                    doc.text(
                        `${part.amountUsed ?? part.fulfilledQty}`,
                        xOffset,
                        (verticalPointer += 7.5),
                        'center'
                    );

					doc.line(80, (verticalPointer += 5), 130, verticalPointer);
					if (verticalPointer >= 230 && index < ticket.data.length - 1) {
		                doc.addPage();
		                verticalPointer = 10;
		            }
                });
            }

            doc.line(35, (verticalPointer += 5), 175, verticalPointer);

            if (verticalPointer >= 200 && index < ticket.data.length - 1) {
                doc.addPage();
                verticalPointer = 20;
            }
        });
    }
    //save the PDF
    doc.save(`wo-${ticket.localid}_${ticket.customer}`);
}

export default { printTicket };

<template>
    <div style="display:flex; margin-left: 2.5vw; margin-top: 2.5vh">
        <div class="card card-danger">
          <div class="card-header">
              <div class="card-title">
                <h3><b>Notifications</b></h3>
              </div>
            </div>
            <b-table ref="table" :items="allNotifications" :per-page="8" :current-page="currentPage.notis" :fields="fields" responsive="lg" class="card-body" striped style="width: 78vw">
                <template #cell(delete)="row">
                    <i class="fas fa-trash" size="sm" @click.stop="deleteNotification(row)"></i>
                </template>
            </b-table>
            <b-pagination class="customPagination" v-model="currentPage.notis" :per-page="8" :total-rows="allNotifications.length" style="margin-left: 38.5%"></b-pagination>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import { Socket } from 'socket.io-client'
import axios from 'axios'
export default {
 name: 'app',

 props: {
     socket: Socket,
     userid: String,
     companyId: Number,
     user: Object,
 },

 components: {

 },

 data () {
     return {
         allNotifications: [],
         currentPage: {},
         fields: [
            {key: 'title', sortable: true},
            {key: 'message'},
            {key: 'date', sortable: true},
            {key: 'delete', label: ''}
         ]
     }
 },//END data

  async mounted() {
    await axios
      .get(
        process.env.VUE_APP_API_TARGET + "/notifications/user/" +
          this.$globals.user.sub +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
      .then((response) => {
        this.allNotifications = response.data.queryResult.reverse();
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Notifications', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
  },

  methods: {
    async deleteNotification(row) {
      await axios.delete(
        process.env.VUE_APP_API_TARGET + "/notifications/" +
          row.item.id +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Notifications', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
	  
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/notifications/user/" +
            this.$globals.user.sub +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allNotifications = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Notifications', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },
  }, //END methods
};
</script>

<style scoped>
.customPagination {
	/* position: sticky;
	position:-webkit-sticky; */
	margin-top :1%;
	bottom:0;
}

.recurring .customPagination{
	margin-left: 45%;
	position: sticky;
	position:-webkit-sticky;
	bottom:0;
}

.parts-awaiting .customPagination, .parts-fulfilled .customPagination {
	margin-left: 30%;
	position: sticky;
	position:-webkit-sticky;
	bottom:0;
}

/deep/ .customPagination .page-item.active .page-link {
	background-color: #dc3545 !important;
	color: white !important;
	border-color: #dc3545 !important;
}

/deep/ .customPagination .page-item .page-link {
	color: #dc3545 !important;
}
</style>

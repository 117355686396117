<template>
    <div>
        <div v-if="!viewingInvoice" class="card card-danger">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Invoices</b></h3>
                </div>
            </div>
            <div class="card-body">
                <vue-virtual-table
                ref="invoiceTable"
                class="danger"
                :config="config"
                :data="allInvoices"
                :enableExport="true"
                :selectable="false"
                :height="600"
				:hoverHighlight="true"
                @click="handleSelection"
                language="en"
                style="z-index: 0">
					<template slot-scope="scope" slot="total">
						${{ parseFloat(scope.row.total).toFixed(2) !== 'NaN' ? parseFloat(scope.row.total).toFixed(2) : parseInt(0).toFixed(2) }}
					</template>

					<template slot-scope="scope" slot="order">
						{{ scope.row.order !== null ? scope.row.type === 'service' ? `WO-${scope.row.order}` : `SO-${scope.row.order}` : ''}}
					</template>

					<template slot-scope="scope" slot="customerName" style="height: 50px">
						{{ scope.row.customer.name }}
					</template>

                </vue-virtual-table>
            </div>
        </div>

		<div v-if="viewingInvoice && selectedInvoice.type === 'service'">
			<div ref="invoice" class="card card-danger">
				<div class="card-header">
				<div class="card-title">
					<h3 style="text-align: right"><b>Service Invoice</b></h3>
				</div>
				</div>
				<div class="card-body">
				<div class="row">
					<div class="col">
					<img class="w-75 h-75" :src="selectedCompany.logo" />
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- COMPANY -->
					<div class="row">
						<address>
						<p class="h4">{{ selectedCompany.name }}</p>
						<br />
						{{ selectedCompany.address }}<br />
						P: {{ selectedCompany.phone }}
						</address>
					</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- CUSTOMER -->
					<div class="row">
						<address>
						<p class="h4">{{ selectedCustomer.name }}</p>
						<br />
						{{ selectedCustomer.billing }}<br />
						P: {{ selectedCustomer.phone }}
						</address>
					</div>
					</div>
					<div
					class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
					>
					<div class="row text-align-center">
						<b-table
						:fields="[{ key: 'date' }, { key: 'invoice' }]"
						:items="[{ date: selectedInvoice.issued, invoice: selectedInvoice.id }]"
						>
						</b-table>
					</div>
					<div class="row">
						<div class="col">
						<p>
							<b>Payment Type:</b><br />
							Account/Credit
						</p>
						</div>
						<div class="col">
						<p>
							<b>Customer ID:</b><br />
							{{ selectedInvoice.customer.customerid }}
						</p>
						</div>
					</div>
					</div>
				</div>
				<div class="row border text-align-center mb-3">
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Customer WO</b><br />
						{{ selectedInvoice.order }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Unit #</b><br />
						{{ selectedInvoice.vehicle.vehicleid }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>VIN</b><br />
						{{ selectedInvoice.vehicle.vin }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Make</b><br />
						{{ selectedInvoice.vehicle.make }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Model</b><br />
						{{ selectedInvoice.vehicle.model }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Mileage</b><br />
						{{ selectedInvoice.vehicle.miles }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Hours</b><br />{{ selectedInvoice.vehicle.hours }}
					</p>
					</div>
				</div>
				<div class="row">
					<b-table
					show-empty
					:fields="invoiceTableFields"
					:items="selectedInvoice.data"
					class="table-bordered border-3"
					>
					<template #cell(tech)="row">
						<div v-for="tech in row.item.tech" :key="tech.name">
						{{ tech.name }}
						</div>
					</template>
					<template #cell(total)="row">
						<div v-if="row.item.sell">
						${{ (parseFloat(row.item.sell) * parseFloat(row.item.qty)).toFixed(2) }}
						</div>
					</template>
					<template #cell(sell)="row">
						<div v-if="row.item.sell">${{ parseFloat(row.item.sell).toFixed(2) }}</div>
					</template>
					<template #custom-foot>
						<tr>
						<th></th>
						<th></th>
						<th></th>
						<th></th>
						<th>
							<h5><b>Total: </b></h5>
						</th>
						<th>${{ selectedInvoice.total.toFixed(2) }}</th>
						</tr>
					</template>
					</b-table>
				</div>
				<div>
					<p>
					{{
						selectedCompany.invoice_footer
						? selectedCompany.invoice_footer
						: "This is a customizable footer here"
					}}
					</p>
				</div>
				</div>
			</div>
			<b-button
				v-if="!printing"
				class="button-danger mb-4"
				@click="printPDF"
				>Print</b-button
			>
			<b-button
				v-if="!printing"
				class="button-danger mb-4 float-right"
				@click="viewingInvoice = !viewingInvoice"
				>Return</b-button
			>
		</div>

		<div v-if="viewingInvoice && selectedInvoice.type === 'sales'">
			<div ref="invoice" class="card card-danger">
				<div class="card-header">
				<div class="card-title">
					<h3 style="text-align: right"><b>Sales Invoice</b></h3>
				</div>
				</div>
				<div class="card-body">
				<div class="row">
					<div class="col">
					<img class="w-75 h-75" :src="selectedCompany.logo" />
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- COMPANY -->
					<div class="row">
						<address>
						<p class="h4">{{ selectedCompany.name }}</p>
						<br />
						{{ selectedCompany.address }}<br />
						P: {{ selectedCompany.phone }}
						</address>
					</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- CUSTOMER -->
					<div class="row">
						<address>
						<p class="h4">{{ selectedCustomer.name }}</p>
						<br />
						{{ selectedCustomer.billing }}<br />
						P: {{ selectedCustomer.phone }}
						</address>
					</div>
					</div>
					<div
					class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
					>
					<div class="row text-align-center">
						<b-table
						:fields="[{ key: 'date' }, { key: 'invoice' }]"
						:items="[{ date: selectedInvoice.issued, invoice: selectedInvoice.id }]"
						>
						</b-table>
					</div>
					<div class="row">
						<div class="col">
						<p>
							<b>Payment Type:</b><br />
							Account/Credit
						</p>
						</div>
						<div class="col">
						<p>
							<b>Customer ID:</b><br />
							{{ selectedInvoice.customer.customerid }}
						</p>
						</div>
					</div>
					</div>
				</div>
				<div class="row border text-align-center mb-3">
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Order Number</b><br />
						{{ selectedInvoice.order }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Salesman</b><br />
						{{ selectedInvoice.salesman || "" }}
					</p>
					</div>
					<div class="col pt-3 border text-align-center">
					<p style="text-align: center">
						<b>Invoice Due By</b><br />
						{{ dueByDate }}
					</p>
					</div>
				</div>
				<div class="row">
					<b-table
					show-empty
					:fields="invoiceTableFieldsSO"
					:items="selectedInvoice.data"
					class="table-bordered border-3"
					>
					<template #cell(total)="row">
						<div v-if="row.item.sell">
						${{ parseFloat(row.item.total).toFixed(2) }}
						</div>
					</template>
					<template #cell(sell)="row">
						<div v-if="row.item.sell">${{ (parseFloat(row.item.sell) * ( 1 + ((parseFloat(row.item.markUp))/100))).toFixed(2) }}</div>
					</template>
					<template #custom-foot>
						<tr>
						<th></th>
						<th></th>
						<th></th>
						<th>
							<h5><b>Total: </b></h5>
						</th>
						<th>${{ selectedInvoice.total.toFixed(2) }}</th>
						</tr>
					</template>
					</b-table>
				</div>
				<div>
					<p>
					{{
						selectedCompany.invoice_footer
						? selectedCompany.invoice_footer
						: "This is a customizable footer here"
					}}
					</p>
				</div>
				</div>
			</div>
			<b-button
				v-if="!printing"
				class="button-danger mb-4"
				@click="printPDF"
				>Print</b-button
			>
			<b-button
				v-if="!printing"
				class="button-danger mb-4 float-right"
				@click="viewingInvoice = !viewingInvoice"
				>Return</b-button
			>
		</div>
    </div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import { Socket } from "socket.io-client";
import VueVirtualTable from "vue-virtual-table";
import printJS from "print-js";
import jsPDF from "jspdf";

export default {
  name: "app",
  props: {
    socket: Socket,
    companyId: Number,
    user: Object
  },
  components: { Multiselect, VueVirtualTable },
  computed: {
	  dueByDate: function() {
		  let month = (new Date(this.selectedInvoice.issued).getMonth()+2)%12
		  month = month === 0 ? month = 12 : month

		  let day = new Date(this.selectedInvoice.issued).getDate()

		  let year = (new Date(this.selectedInvoice.issued).getFullYear())
		  year = month < new Date(this.selectedInvoice.issued).getMonth()+1 ? year + 1 : year

		  return `${year}-${month}-${day}`
	  }
  },
  data() {
      return {
		  allInvoices: [],
		  config: [
			{ prop: "id", name: "Invoice ID", searchable: true },
			{ prop: "_action", name: "Customer", actionName: "customerName", searchable: true },
			{ prop: "_action", name: "Total", actionName: "total", searchable: true },
			{ prop: "_action", name: "Order Number", actionName: "order", numberFilter: true },
			{ prop: "issued", name: "Date Issued", searchable: true }
		  ],
		  invoiceTableFields: [
			{ key: "tech", label: "Technician(s)" },
			{ key: "itemid", label: "Item ID" },
			{ key: "itemdesc", label: "Description" },
			{ key: "qty", label: "Qty" },
			{ key: "sell", label: "Price Each" },
			{ key: "total", label: "Total" },
		  ],
		  invoiceTableFieldsSO: [
			{ key: "itemid", label: "Item ID" },
			{ key: "itemdesc", label: "Description" },
			{ key: "qty", label: "Qty" },
			{ key: "sell", label: "Price Each" },
			{ key: "total", label: "Total" },
		  ],
		  printing: false,
		  selectedCustomer: null,
		  selectedCompany: null,
		  selectedInvoice: null,
		  viewingInvoice: false,
      }
  },

    async mounted() {
	  await axios
		.get(`${process.env.VUE_APP_API_TARGET}/qbo/invoices/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then(response => this.allInvoices = response.data.queryResult)
		.then(() => {
			this.allInvoices.forEach(async (invoice) => {
				invoice.customer = JSON.parse(invoice.customer)
				invoice.data = JSON.parse(invoice.data)
				invoice.vehicle = JSON.parse(invoice.vehicle)
			})
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Invoicing', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	  await axios
        .get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.selectedCompany = response.data.queryResult[0];
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Invoicing', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
  },

  methods: {

	  async handleSelection(args) {
		  this.selectedInvoice = args
		  this.selectedCustomer = this.selectedInvoice.customer
          this.viewingInvoice = true
        console.log("selectedInvoice.vehicle", this.selectedInvoice.vehicle)
	  },
	//bug found: if image has a CORS error, header fails to render in save.
	  async printPDF() {
		  this.printing = true;
		  this.$nextTick(() => {
			const doc = new jsPDF("p", "mm", "letter");
			const contentHtml = this.$refs.invoice;
			doc.html(contentHtml, {
			html2canvas: {
				scale: 0.13,
			},
			callback: function (doc) {
				doc.save("test.pdf");
			},
			x: 4,
			y: 2,
			});
		});
		this.$nextTick(() => {
			this.printing = false;
		});
	  },
  }

}
</script>

<style>

</style>

<template>
	<div class="pb-3">
		<div v-if="action === ''">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Vehicles In Shop</b></h3>
					</div>
					<div class="card-tools">

					</div>
				</div>
				<div class="card-body">
					<b-table
						striped
						:current-page="currentPage.inShop"
						:items="shopTickets"
						:per-page="10"
						:busy="isBusy.inShop"
						:fields="[
							{key: 'localid', label: 'Work Order #'},
							{key: 'vehicle'},
							{key: 'technician'},
							{key: 'customer'},
							{key: 'deliveredOn', label: 'Asset Arrived'}
						]"
						@row-clicked="viewTicket">

						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>

						<template #cell(technician)="row">
							<p><span v-for="tech in (typeof row.item.technician ==='object' ?row.item.technician : JSON.parse(row.item.technician))" :key="tech.name">{{ tech.name }} </span></p>
						</template>

					</b-table>
				</div>
			</div>
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Vehicle Lookup</b></h3>
					</div>
					<div class="card-tools">

					</div>
				</div>
				<div class="card-body">
					<input type="text" class="form-control" placeholder="Search by Customer, VIN, Vehicle ID, Make, Model, or Year" @input="debounceVehicles" style="width: 95%; margin-top: 1vw"/>
					<b-table
						:items="allVehicles"
						:busy="isBusy.searchVehicles"
						:fields="[
							{key: 'vehicleid', label: 'Vehicle ID'},
							{key: 'vin'},
							{key: 'customer'},
							{key: 'make'},
							{key: 'model'},
							{key: 'year'}
						]"
						@row-clicked="viewVehicle"
						striped>
						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>
					</b-table>
				</div>
			</div>

			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Company Vehicles</b></h3>
					</div>
					<div class="card-tools">
						<b-button v-if="$globals.canAccess.admin" style="background-color: #fff4; border-color: #dc3545" @click="action='newCompanyVehicle'">New</b-button>
					</div>
				</div>
				<div class="card-body">
					<div>
						<b-table
							ref="companyVehiclesTable"
							class="new-styled-table"
							striped
							:current-page="currentPage.companyVehicles"
							:items="allCompVehicles"
							:per-page="5"
							:fields="[
								{ key: 'vin', sortable: true },
								{ key: 'vehicleid', label: 'Vehicle Identifier'},
								{ key: 'license'},
								{ key: 'location', label: 'Located at'},
								{ key: 'responsibleUser', label: 'User'}
							]"
							:busy="isBusy.companyVehicles"
							@row-clicked="viewCompVehicle"
							responsive="lg"
							table-variant="red">

							<template #cell(license)="row">
								{{row.item.license}}
							</template>

						</b-table>
						<b-pagination
							class="customPagination"
							size="sm"
							v-model="currentPage.companyVehicles"
							:per-page="5"
							limit="5"
							:total-rows="allCompVehicles.length"
							aria-controls="companyVehiclesTable"
							style="margin-left: 45.5%; margin-right: 42.5%">
						</b-pagination>
					</div>
				</div>
			</div>

		</div>

		<div v-if="action==='viewTicket'">
			<viewTicket :selectedTicket="selectedTicket" @return="action=''"></viewTicket>
		</div>

		<div v-if="action === 'viewVehicle'">
			<viewVehicle :passedVehicle="selectedVehicle" :selectedCustomer="selectedVehicle.customer" @return="action = ''"/>
		</div>

		<div v-if="action ==='newCompanyVehicle'">
			<newCompanyVehicle @return="getCompanyVehicles();action=''"></newCompanyVehicle>
		</div>

		<div v-if="action === 'viewCompVehicle'">
			<viewCompanyVehicle :targetVehicle="selectedVehicle" @return="getCompanyVehicles();action = ''"></viewCompanyVehicle>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import { BPagination } from "bootstrap-vue";

import viewTicket from './Service/viewTicket.vue'
import viewVehicle from './Customers/viewVehicle.vue'
import newCompanyVehicle from './CompanyVehicles/newCompanyVehicle.vue'
import viewCompanyVehicle from './CompanyVehicles/viewCompanyVehicle.vue'

import { debounce } from 'lodash';


export default{
	props: {

	},

	components: {
		viewTicket,
		viewVehicle,
		newCompanyVehicle,
		BPagination,
		viewCompanyVehicle
	},

	data(){
		return {
			action: '',
			allVehicles:[],
			allCompVehicles: [],
			currentPage: {
				inShop: 0,
				companyVehicles: 1
			},
			isBusy: {
				inShop: false,
				searchVehicles: false,
				companyVehicles: false
			},
			maxPages: {
				inShop: 0,
				companyVehicles: 1
			},
			shopTickets: [],
			selectedTicket: null
		}
	},

	created(){
		this.$globals.socket.emit("joinRoom", "vehicles"+this.$globals.companyId);

		this.debounceVehicles = debounce(this.searchVehicles, 600)
	},

	destroyed(){
		this.$globals.socket.emit("leaveRoom", `vehicles${this.$globals.companyId}`);
	},

	mounted(){
		this.getShopTickets()
		this.getCompanyVehicles()
	},

	methods: {

		async getShopTickets(){
			this.isBusy.inShop = true;
			await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/inShop/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response)=>{
				this.shopTickets = response.data.queryResult
			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vehicles', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.isBusy.inShop = false;

		},

		async getCustomerById(vehicle) {
			if(vehicle?.customerid !== null){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${vehicle.customerid}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.then((response) => {
					vehicle.customer = response.data.queryResult[0];
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vehicles', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

		}, //END getCustomerName()

		async getCompanyVehicles(){
			this.isBusy.companyVehicles = true;
			await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/companyVehicles/${'ALL'}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			}).then((response)=>{
				this.allCompVehicles = response.data.queryResult
				this.allCompVehicles.forEach((item, i) => {
					if(item.license == null || item.license == undefined){
						item.license = ''
					}
					else{
						item.license = typeof item.license == 'object' ? item.license.number : JSON.parse(item.license)?.number
					}
				});

			}).catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vehicles', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.isBusy.companyVehicles = false;

		},

		async searchVehicles(searchValue){
			if(searchValue.target.value === "" || searchValue.target.value === null){
				return
			}
			this.isBusy.searchVehicles = true;

			await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/search/${encodeURIComponent(searchValue.target.value)}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response)=>{
				this.allVehicles = response.data.queryResult.length > 0 ? response.data.queryResult : []
				this.allVehicles.forEach((item, i) => {
					item.customer = item.name
				});
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vehicles', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.isBusy.searchVehicles = false;

		},

		viewTicket(picked){
			this.selectedTicket = picked
			this.action = 'viewTicket'
		},

		viewCompVehicle(selectedCompVehicle){
			this.selectedVehicle = selectedCompVehicle
			this.action = 'viewCompVehicle'
		},

		async viewVehicle(picked){
			this.selectedVehicle = {...picked}
			await this.getCustomerById(this.selectedVehicle)
			this.action = "viewVehicle"
		}


	}
}

</script>

<style scoped>
.new-styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 80vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  align: center;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
}

.dark {
  background-color: #dc4535 !important;
  border-color: #dc4535 !important
}

.stand {
  background-color: #af2222 !important;
  border-color: #dc3545 !important
}

/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
/deep/ .customPagination .page-item .page-link {
  color: #dc4444 !important;
}
</style>

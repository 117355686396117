<template>
	<!-- VIEW PO -->
	<div>
		<div v-if="action === ''">
			<div id="purchaseOrder" class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Purchase Order #{{selectedPO.localid}}</b></h3>
						<p v-if="selectedPO.orderType">Linked to {{selectedPO.orderType}} #{{selectedPO.orderID}}</p>
					</div>
					<div class="card-tools" v-if="!printing">
                        <b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="sendPO()"
						>Send PO
						</b-button>

						<b-button
							v-if="canFinalize"
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="editToClosePO()"
						>Finalize PO
						</b-button>

						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="reject()"
							>Return
						</b-button>
					</div>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col">
							<img class="img-circle w-75 h-75" :src="selectedCompany.logo" />
						</div>
						<!-- Vendor Info -->
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<div class="row">
								<address>
									<p class="h4">{{ selectedPO.vendor.company }}</p>
									<br />
									{{ selectedPO.vendor.address }}<br />
									Tel: {{ selectedPO.vendor.phone }}
								</address>
							</div>
						</div>

						<!-- COMPANY -->
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<div class="row">
								<address>
									<p class="h4">{{ selectedCompany.name }}</p>
									<br />
									{{ selectedCompany.address }}<br />
									Tel: {{ selectedCompany.phone }}
								</address>
							</div>
						</div>

						<!-- CUSTOMER SHIPPING -->
						<div  v-if="selectedPO.customer" class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<div class="row">
								<address>
									<p class="h4">{{ selectedPO.customer.name }}</p>
									<br />
									{{ selectedPO.customer.shipping }}<br />
									Tel: {{ selectedPO.customer.phone }}
								</address>
							</div>
						</div>
					</div>
					<b-table :fields="poTableFields" :items="selectedPO.data" striped>

						<template #cell(qty)="row">
							{{totalReceived[row.index]}} / {{row.item.qty}}
						</template>

						<template #cell(cost)="row">
							${{ parseFloat(row.item.vendorcost !== undefined ? row.item.vendorcost : row.item.cost).toFixed(2) }}
						</template>

						<template #cell(total)="row">
							${{ parseFloat(row.item.total).toFixed(2) }}
						</template>

						<template #cell(receivedQty)="row">
							<div v-if="row.item.notReceivedFlag === false">
								<div v-if="selectedPO.orderType !== 'SO'"><b-table :items="row.item.receivedQty.filter((receipt) => !(receipt.qty === 0))" :fields="receivedTableFields"></b-table></div>
								<div v-else><strong class="text-danger"><i>Received</i></strong></div>
							</div>
							<div v-else-if="subaction === 'receive' && row.index === selectedIndex">
								<div v-if="row.item.itemid.includes('**SO**')">
									<label for="receiveQty">Received Qty</label> <br>
									<input type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-50 mb-1" v-model="receivedQty"/>
								</div>
								<div v-else>
									<label for="receiveAtLocation">Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
									<multiselect
										requried
										class="w-75 mb-2"
										id="receiveAtLocation"
										:options="allLocations"
										v-model="row.item.location"
										:clearable="false"
										:multiple="false"
										:show-labels="false"
										track-by="name"
										label="name"
										placeholder="Location"
										@input="changeLocation(row)"

									></multiselect>

									<label for="receiveQty">Received Qty & Bin<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label> <br>
									<input required type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-25 mb-1" v-model="receivedQty" style="display: inline"/>
									<input id="warrantyCheckbox" type="checkbox" v-model="warrantyFlag"/>
									<label for="warrantyCheckbox">: Warranty Part</label>
									<multiselect
										required
										class="w-50"
										:clearable="true"
										:show-labels="false"
										:options="binList"
										v-model="row.item.bin"
										:multiple="false"
										placeholder="Bin">
									</multiselect>
									<div v-if="row.item.bin.includes('**New**')">
										<input type="text" class="form-control w-50" v-model = "tempBin" placeholder="New Bin" style="display: inline"/>
										<i class="fas fa-plus-circle text-left fa-lg" @click="row.item.bin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%">
										</i>
									</div>
								</div>

								<br>
								<b-button
									:class="{dark: displayClass, stand: !displayClass}"
									@click="receivePOItem(row)"
									>Confirm
								</b-button>
							</div>
							<div v-else-if="totalReceived[row.index] > 0">
								<b-table :items="row.item.receivedQty.filter((receipt) => !(receipt.qty === 0))" :fields="receivedTableFields"></b-table>
								<b-button
									:class="{dark: displayClass, stand: !displayClass}"
									@click="receiver(row.item, row.index)"
									>Receive
								</b-button>
							</div>
							<div v-else>
								<b-button
									:class="{dark: displayClass, stand: !displayClass}"
									@click="receiver(row.item, row.index)"
									>Receive
								</b-button>
							</div>
						</template>

						<!-- <template #cell(action)="row">
							<div v-if="row.item.notReceivedFlag == undefined || row.item.notReceivedFlag == true">
								<div v-if="subaction === 'receive' && row.index === selectedIndex">
									<div v-if="row.item.itemid.includes('**SO**')">
										<label for="receiveQty">Received Qty</label> <br>
										<input type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-50 mb-1" v-model="receivedQty"/>
									</div>
									<div v-else>
										<label for="receiveAtLocation">Location</label>
										<multiselect
											class="w-75 mb-2"
											id="receiveAtLocation"
											:options="allLocations"
											v-model="row.item.location"
											:clearable="false"
											:multiple="false"
											:show-labels="false"
											track-by="name"
											label="name"
											placeholder="Location"
										></multiselect>

										<label for="receiveQty">Received Qty & Bin</label> <br>
										<input type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-25 mb-1" v-model="receivedQty" style="display: inline"/>
										<input type="text" id="receiveAtBin" placeholder="Bin" class="form-control w-50" v-model="row.item.bin" style="display: inline"/>
									</div>

									<br>
									<b-button
										style="background-color: #dc3545; border-color: #dc3545"
										@click="receivePOItem(row)"
										>Confirm
									</b-button>
								</div>

								<div v-else>
									<b-button
										style="background-color: #dc3545; border-color: #dc3545"
										@click="receiver(row.item, row.index)"
										>Receive
									</b-button>
								</div>

							</div>
							<div v-else>
								<b-table :items="row.item.receivedQty" :fields="receivedTableFields"></b-table>
							</div>
						</template> -->

					</b-table>
				</div>
			</div>
		</div>

		<!-- Edit to Close PO -->
		<div v-if="action === 'editToClosePO'">
			<closePO :user="user" :companyId="companyId" :selectedPO="selectedPO" :selectedCompany="selectedCompany" :editPOItems="editPOItems"
				@returnFromClose="action=''" @closed="() => {$emit('POclosed')}">
			</closePO>
		</div>

	</div>
</template>

<script>
	/*eslint-disable*/
	import axios from "axios";
	import closePO from "./finalizePurchaseOrder.vue"
	import Multiselect from "vue-multiselect"
    import html2canvas from "html2canvas"
	import {Socket} from "socket.io-client"


	export default{
		name: "app",
		props: {
			companyId: Number,
			user: Object,
			selectedPO: Object,
			selectedCompany: Object,
			userRoles : Array,
			socket: Socket
		},
		components: {closePO, Multiselect},

		data(){
			return {
				action: "",
				allLocations: [],
				binList: [],
				canFinalize : false,
				displayClass: '',
				editPOItems: [],
				poTableFields: [
				  { key: "itemid", label: "Item ID" },
				  { key: "itemdesc", label: "Description" },
				  { key: "qty", label: "Qty" },
				  { key: "cost", label: "Expected Cost Each" },
				  { key: "total", label: "Total" },
				  { key: "receivedQty", label: "Received" },
				  { key: "action", label: ""},
				],
                printing: false,
				receivedQty: 0,
				subaction: "",
				selectedIndex: "",
				totalReceived: [],
				tempBin: '',
				receivedTableFields: [
					{key: "qty", label: "Quantity"},
					{key: "location", label: "Location"},
					{key: "bin", label: "Bin"}
				],
				warrantyFlag: false

			}
		},

		async mounted(){
			this.calculateReceived();

			await axios.get(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.$globals.user.sub}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(response => {
				if(response.data.queryResult[0].display === "Dark Mode"){
					this.displayClass = true
				}
				else{
					this.displayClass = false
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			if(this.$globals.companyId < 0){
				alert("Invlaid Company ID")
				this.reject();
			}
			if(this.$globals.user === null){
				alert("No User Passed")
				this.reject();
			}
			if(this.selectedPO === null){
				alert("Purchase Order not selected")
				this.reject();
			}


			this.selectedPO.vendor = typeof this.selectedPO.vendor === "object" ? this.selectedPO.vendor : JSON.parse(this.selectedPO.vendor)
			this.selectedPO.data = typeof this.selectedPO.data === "object" ? this.selectedPO.data : JSON.parse(this.selectedPO.data)
			for(let index in this.userRoles){
				if(this.userRoles[index].name.includes('Admin') || this.userRoles[index].id === 'rol_N8lZN0AwFgVWjcmr'){
					this.canFinalize = true;
				}
			}
		},

		methods: {
			log(x){console.log(x)},

			calculateReceived(){
				for(let index in this.selectedPO.data){
					this.totalReceived[index] =0;
					this.selectedPO.data[index].receivedQty = this.selectedPO.data[index].receivedQty ?? []
					if(this.selectedPO.data[index].receivedQty.length < 1){
						this.selectedPO.data[index].receivedQty.push({qty:0})
					}
					for(let i in this.selectedPO.data[index].receivedQty){
						this.totalReceived[index] = this.totalReceived[index] + parseInt(this.selectedPO.data[index].receivedQty[i].qty)
					}
				}
				this.$nextTick(()=> this.$forceUpdate())
			},

			editToClosePO(){
				this.editPOItems = []
				for(let i in this.selectedPO.data){
					if(this.selectedPO.data[i].notReceivedFlag){
						alert("All Items must be received to close PO")
						this.editPOItems = []
						return;
					}
					this.editPOItems[i] = {...this.selectedPO.data[i], sell: 0, markUp: this.selectedPO.data[i].markUp ?? 0, vendor: this.selectedPO.data[i].vendor?.company ?? this.selectedPO.data[i].vendor}
				}
				this.editPOItems.push({
					itemid: 'SHIPPING',
					itemdesc: `Shipping from - ${this.selectedPO.vendor.company}`,
					markUp: 0,
					qty: 1,
					sell: 0,
					cost: 0,
					total: 0,
					otherLocations: []
				})

				this.action = "editToClosePO"
			},

			async getAllLocations(){
				//get all locations
				await axios
				  .get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				  .then((response) => {
					this.allLocations = response.data.queryResult;
				  })
				  .catch((response)=>{
					  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				  })
			},

			changeLocation(row){
			  	row.item.bin = ""
				this.getDistinctBins(row)
			},

			getDistinctBins(row){
				axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${encodeURIComponent(row.item.location.name)}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				).then((response)=>{
					row.item.bin = ""
					this.binList = []
					this.binList = response.data.queryResult
					for(var item in this.binList){
						this.binList[item] = this.binList[item].toUpperCase()
					}
					this.binList.unshift('**New**')
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			receiver(item, index){
				this.selectedIndex = index;
				this.subaction = 'receive'
				//if Special Order can't receive to a target location. So adjusts here
				if(item.itemid.includes('**SO**')){
					return;
				}
				this.getAllLocations();
				item.location = typeof item.location === "object" ? item.location : {name: item.location}
			},

			receivePOItem(row){
				//Safety Checks
				if(this.receivedQty < 1){
					alert("Cannot receive less and 1 item")
					return;
				}

				if(row.item.itemid.includes('**SO**')){
					row.item.receivedQty[0].qty = parseInt(row.item.receivedQty[0]?.qty) + parseInt(this.receivedQty)
				}
				else{
					if(row.item.location?.name == '' || row.item.location?.name === null || row.item.location?.name == undefined){
						alert("Please select a location")
						return;
					}
					if(row.item.bin == '' || row.item.bin == null){
						alert("Please fill in a bin")
						return;
					}
					row.item.bin = row.item.bin.toUpperCase()
					row.item.location = row.item.location.name;
					//Condense received loc & bin (otherwise issuse closing from back as it would just reup already adjusted items)
					let match = row.item.receivedQty.findIndex((loc) =>{loc.location === row.item.location && loc.bin === row.item.bin})
					if(match >=0){
						row.item.receivedQty[match].qty = parseInt(row.item.receivedQty[match].qty) + parseInt(this.receivedQty);
					}
					else{
						row.item.receivedQty.push({
							qty: this.receivedQty,
							location: row.item.location,
							bin: row.item.bin,
							warranty: this.warrantyFlag
						})
					}
				}

				this.totalReceived[row.index] = parseInt(this.totalReceived[row.index]) + parseInt(this.receivedQty)

				if(row.item.qty <= this.totalReceived[row.index]){
					row.item.notReceivedFlag = false
				}
				axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/purchaseOrder/received/${this.selectedPO.localid}/${this.$globals.companyId}`,
				{
					item: (row.item)
				},
				{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then(response =>{
					if(!row.item.itemid.includes("**")){
						axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(row.item.itemid)}/receive/${this.$globals.companyId}`,
						{
							item: (row.item),
							receivedQty: this.receivedQty
						}, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
						.then(response =>{
							if(this.selectedPO.orderType){
								//TODO un spegiti this. Make sure that it gets wo and so linked data correctly and will send correctly. WO have no salesmen and SO might have a different name for .salesman
								if(this.selectedPO.orderType === 'SO'){
									axios.get(`${process.env.VUE_APP_API_TARGET}/sales/sales_orders/linked/${this.selectedPO.orderID}/${this.$globals.companyId}`,
										{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }
									}).then(response => {
										if(response.data.queryResult[0].salesman !== null && response.data.queryResult[0].salesman !== undefined){
											axios.get(`${process.env.VUE_APP_API_TARGET}/users/profile/byName/${response.data.queryResult[0].salesman}/${this.$globals.companyId}`,
												{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }
											}).then(response => {
												this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, response.data.queryResult, "Purchase Order Received", "Purchase Order " + this.selectedPO.localid + " that is attached to Sales Order " + this.selectedPO.orderID + " has been received in " + row.item.location);
												// this.$globals.socket.emit("notifyRelevantRoles", this.$globals.companyId, ['rol_Uv2jW8f2ElZmR52Z', 'rol_c7ubznTEtdGM46JP'], "Purchase Order Received", "Purchase Order " + this.selectedPO.localid + " that is attached to Sales Order " + this.selectedPO.orderID + " has been received in " + row.item.location);
											})
											.catch((response)=>{
												this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
											})
										}

									})
									.catch((response)=>{
										this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
									})
								}
								else if (this.selectedPO.orderType === 'WO'){
									axios.get(`${process.env.VUE_APP_API_TARGET}/sales/work_orders/linked/${this.selectedPO.orderID}/${this.$globals.companyId}`,
										{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }
									})
									.then(response => {
										//TODO notify techs parts have arrived
										response.data.queryResult[0].technician = typeof response.data.queryResult[0].technician === 'string' ? JSON.parse(response.data.queryResult[0].technician) : response.data.queryResult[0].technician
										this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, response.data.queryResult[0].technician, "Purchase Order Received", "Purchase Order " + this.selectedPO.localid + " that is attached to Sales Order " + this.selectedPO.orderID + " has been received in " + row.item.location);

									})
								}
								else{
									console.log("WARNNING WEIRD TYPE: ", this.selectedPO.orderType);
								}
							}//END if orderType
						})
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
					}

				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.$nextTick(()=>{this.$forceUpdate()})
				this.subaction = '';

			},

			reject(){
				this.$emit('returnFromView')
			},

            async sendPO() {
                this.printing = true;
                this.$nextTick(() => {
                    let htmlContent = document.getElementById('purchaseOrder')
                    let initialState = htmlContent.style.boxShadow
                    htmlContent.style.boxShadow = 'none'
                    htmlContent.style.border = 'solid 1px black'
                    html2canvas(htmlContent, {scale: 1}).then(async (canvas) => {
                        var imgData = canvas.toDataURL("image/png");
                        await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                            pic: imgData
                        }, {
                            headers: {
                                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                            }
                        }).then((response) => {
                            axios.post(
                            `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                            {
                                sgKey: this.subKey,
                                // to: [this.selectedPO.billto.email, this.selectedSalesOrder.shipto.email],
                                to: 'jakekowa@fleet4.net',
                                subject: `${this.selectedCompany.name} - Fleet 4 Fire Purchase Order ${this.selectedPO.localid}`,
                                // title: this.emailTitle,
                                html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Purchase Order ${this.selectedPO.localid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Purchase Order ${this.selectedPO.localid}"/></body></html>`,
                                text: `Can't see our email? Click the link below to view our order!\n\n\n${response.data.queryResult}`
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                                }
                            })
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
                        })
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
                        htmlContent.style.border = 'none'
                        htmlContent.style.boxShadow = initialState
                        this.printing = false;
                    })
                })
                // this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [this.selectedSalesman], "Sent Purchase Order", "Purchase Order " + this.selectedPO.localid + "has been sent by " + this.$globals.user.name);
            },

		},
	}


</script>

<style>
.dark {
	background-color: #dc4535 !important;
	border-color: #dc4535 !important
}
.stand {
	background-color: #dc3545 !important;
	border-color: #dc3545 !important
}
</style>

<template>
    <div>
        <div v-if="action === ''">

            <div id="salesOrder" class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>{{selectedContract.contractName}}</b></h3>
                    </div>
                    <div class="card-tools" v-if="!printing">
                        <b-button v-if="canAccess.admin === true && selectedContract.expired === 0" style="background-color: #fff4; border-color: #dc3545"
                            class="mx-1" @click="forceExpiration()">Force Expiration
                        </b-button>

                        <b-button style="background-color: #fff4; border-color: #dc3545"
                            class="mx-1" @click="editContract()">Edit
                        </b-button>

                        <b-button style="background-color: #fff4; border-color: #dc3545" class="mx-1" @click="$emit('reject')">
                            Return
                        </b-button>
                    </div>
                    <div style="width: 40%">
                        <b-table
                            class="light my-1 text-center"
                            :fields="[{ key: 'contractid', label: 'Contract Id' }, { key: 'start', label: 'Start' }, { key: 'end', label: 'End' }]"
                            :items="[
                            {
                                contractid: selectedContract.contractid,
                                start: selectedContract.start,
                                end: selectedContract.end
                            }]">
                        </b-table>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                        <!-- Customer -->
                            <div class="row">
                                <address>
                                <p class="h4">{{ selectedCustomer.name }}</p>
                                <br />
                                {{ selectedCustomer.address }}<br />
                                P: {{ selectedCustomer.phone }} <br>
								<i v-if="selectedContract.file !== null" class="fas fa-download" @click="download(selectedContract.file)">Contract</i>
                                </address>
                            </div>
                        </div>
                        <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                            <!-- CUSTOMER -->
                            <div class="row">
                                <address>
                                    <p class="h4">Occurrence</p>
                                    <br />
                                    <div class="row">
                                        <div class="col">
                                            <label>Type: </label>
                                            {{ selectedContract.frequencyType }}<br />
                                            <label>Amount: </label>
                                            {{ selectedContract.frequency}}<br />
                                        </div>
                                    </div>
                                </address>
                            </div>
                        </div>
                        <div
                        class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
                        >
                            <div class="row">
                                <address>
                                    <p class="h4">Price:</p>
                                    <br />
                                    <label>$</label>
                                    {{ selectedContract.value }}<br />
                                </address>
                            </div>
                        </div>
                    </div>
                    <b-table
                        class="light my-1 text-center"
                        :fields="[{ key: 'asset_type', label: 'Asset Type' }, { key: 'id', label: 'Asset Id' }]"
                        :items="allContractAssetsVehicles">
                    </b-table>
                    <!-- <div class="row text-center border" >
                        <div class="col pt-2 border">
                        <h5><b>Asset Type</b></h5>
                        </div>
                        <div class="col pt-2 border">
                        <h5><b>Asset Id</b></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row text-center mb-3 align-items-center">
                            <div class="col border">
                            {{ asset.asset_type }}
                            </div>
                            <div class="col border">
                            {{ asset.id}}
                            </div>
                        </div>
                    </div> -->
                    <b-row style="justify-content: center; margin-top: 1% ">
                        <h4><b>Tasks</b></h4>
                    </b-row>
                    <br>
                    <b-row style="justify-content: center" v-for="(comment, commentIndex) in selectedContract.tasks" :key="commentIndex">
                        <div class="row mb-3" style="margin-left:7.5%; margin-right:7.5%">
                            <div class="col">
                                <b>{{comment.task}}</b>
							</div>
							<div class="col">
								Times per Occurrence:
								<br>
								{{comment.amount}}
                            </div>

                            <!-- <b-table :fields="qtyTableFields" :items="selectedContract.data" striped>

                                <template #cell(qty)="row">
                                    {{totalReceived[row.index]}} / {{row.item.qty}}
                                </template>

                                <template #cell(cost)="row">
                                    ${{ parseFloat(row.item.vendorcost !== undefined ? row.item.vendorcost : row.item.cost).toFixed(2) }}
                                </template>

                                <template #cell(total)="row">
                                    ${{ parseFloat(row.item.total).toFixed(2) }}
                                </template>

                                <template #cell(receivedQty)="row">
                                    <div v-if="row.item.notReceivedFlag === false">
                                        <div v-if="selectedPO.orderType !== 'SO'"><b-table :items="row.item.receivedQty.filter((receipt) => !(receipt.qty === 0))" :fields="receivedTableFields"></b-table></div>
                                        <div v-else><strong class="text-danger"><i>Received</i></strong></div>
                                    </div>
                                    <div v-else-if="subaction === 'receive' && row.index === selectedIndex">
                                        <div v-if="row.item.itemid.includes('**SO**')">
                                            <label for="receiveQty">Received Qty</label> <br>
                                            <input type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-50 mb-1" v-model="receivedQty"/>
                                        </div>
                                        <div v-else>
                                            <label for="receiveAtLocation">Location</label>
                                            <multiselect
                                                class="w-75 mb-2"
                                                id="receiveAtLocation"
                                                :options="allLocations"
                                                v-model="row.item.location"
                                                :clearable="false"
                                                :multiple="false"
                                                :show-labels="false"
                                                track-by="name"
                                                label="name"
                                                placeholder="Location"
                                                @select="getDistinctBins(row)"
                                            ></multiselect>

                                            <label for="receiveQty">Received Qty & Bin</label> <br>
                                            <input type="number" id="receiveQty" placeholder="Received Qty" min="0" class="form-control w-25 mb-1" v-model="receivedQty" style="display: inline"/>
                                            <multiselect
                                                class="form-control w-50"
                                                :clearable="true"
                                                :show-labels="false"
                                                :options="binList"
                                                v-model="row.item.bin"
                                                :multiple="false"
                                                placeholder="Bin">
                                            </multiselect>
                                            <div v-if="row.item.bin.includes('**New**')">
                                                <input type="text" class="form-control w-50" v-model = "tempBin" placeholder="New Bin" style="display: inline"/>
                                                <i class="fas fa-plus-circle text-left fa-lg" @click="row.item.bin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%">
                                                </i>
                                            </div>
                                        </div>

                                        <br>
                                        <b-button
                                            :class="{dark: displayClass, stand: !displayClass}"
                                            @click="receivePOItem(row)"
                                            >Confirm
                                        </b-button>
                                    </div>
                                    <div v-else-if="totalReceived[row.index] > 0">
                                        <b-table :items="row.item.receivedQty.filter((receipt) => !(receipt.qty === 0))" :fields="receivedTableFields"></b-table>
                                        <b-button
                                            :class="{dark: displayClass, stand: !displayClass}"
                                            @click="receiver(row.item, row.index)"
                                            >Receive
                                        </b-button>
                                    </div>
                                    <div v-else>
                                        <b-button
                                            :class="{dark: displayClass, stand: !displayClass}"
                                            @click="receiver(row.item, row.index)"
                                            >Receive
                                        </b-button>
                                    </div>
                                </template>
                            </b-table> -->
                        </div>
						<hr class="w-75" />

                    </b-row>

                </div>
            </div>
        </div>

        <div v-if="action === 'edit'">
            <!-- should look like new but fill in the data -->
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>Edit Maintenance Contract</b></h3>
                    </div>
                    <div class="card-tools" v-if="!printing">
                        <b-button style="background-color: #fff4; border-color: #dc3545"
                            class="mx-1" @click="updateContract()">Submit
                        </b-button>

                        <b-button style="background-color: #fff4; border-color: #dc3545" class="mx-1" @click="action=''">
                            Return
                        </b-button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <label>Contract Name</label>
                            <input
                                class="form-control"
                                type="text"
                                v-model="tempContract.contractName"
                                style="align-content: center; align-items: center"
                            />
                        </div>
                        <div class="col">
                            <label>Vehicles/Assets</label>
                            <multiselect :clearable="true" :show-labels="false" :options="allAssetsVehicles" group-label="name" v-model="allContractAssetsVehicles" :multiple="true" :group-select=false group-values="items" track-by="id" label="id" placeholder="Asset"></multiselect>
                            <br>
                        </div>
                    </div>
                    <div class="row" style="align-content: center; align-items: center">
                        <div class="col">
                            <label>Start Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <VueCtkDateTimePicker required v-if="$globals.displayClass === false" class="w-100 mb-3" color="#dc3545" button-color="#dc3545" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="tempContract.start"/>
                            <VueCtkDateTimePicker required v-else class="w-100 mb-3" color="#dc4535" button-color="#dc4535" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="tempContract.start"/>
                        </div>
                        <div class="col">
                            <label>End Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <VueCtkDateTimePicker required v-if="$globals.displayClass === false" class="mb-3" color="#dc3545" button-color="#dc3545" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="tempContract.end"/>
                            <VueCtkDateTimePicker required v-else class="mb-3"  color="#dc4535" button-color="#dc4535" only-date format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="tempContract.end"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>Price<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            $<input
                                class="form-control"
                                type="number"
                                step="0.01"
                                min="0"
                                v-model="tempContract.value"
                                style="align-content: center; align-items: center"
                                required
                            />
                        </div>
                        <div class="col">
                            <label>Occurrence<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <multiselect
                                :show-labels="false"
                                :options="['Hours', 'Miles', 'Months']"
                                v-model="tempContract.frequencyType"
                                placeholder="Frequency type"
                                required
                            >
                            </multiselect>
                            <br>
                            <input class="form-control w-25" v-if="tempContract.frequencyType !== '' && tempContract.frequencyType !== null" v-model="tempContract.frequency" type="number" step="1"/>
                        </div>
                    </div>
					<div class="row">
						<div class="col"></div>
						<div class="col">
							<form enctype="multipart/form-data">
								<label for="formFileLg">File</label>
								<input
									class="form-control form-control-lg"
									id="formFileLg"
									type="file"
									ref="inputFile"
									name="doc"
								/>
							</form>
						</div>
					</div>

                    <b-row style="justify-content: center; margin-top: 1% ">
                        <h4><b>Tasks</b></h4>
                    </b-row>
                    <br>

                    <b-row style="justify-content: center" v-for="(comment, commentIndex) in tempContract.tasks" :key="commentIndex">
                        <div class="row mb-3" v-if="editComments[commentIndex] !== true">
							<div class="col-8">
                                <b>{{comment.task}}</b>
							</div>
							<div class="col-2">
								Times per Occurrence:
								<br>
								{{comment.amount}}
                            </div>
                            <div class="col-2">
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block; margin-right: 1%" @click="() => editComment(commentIndex)">Edit</b-button>
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block" @click="() => deleteComment(commentIndex)">Delete</b-button>
                            </div>
                        </div>
                        <div v-else class="row mb-3">
                            <div class="col-8">
                                <textarea type="text" style="width: 100%; margin-bottom: 0.5%" v-model="tempComment.task" />
                            </div>
							<div class="col-2">
								<label>Amount per Occurrence<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
								<input required class="form-control" v-model="tempComment.amount" type="number" step="1"/>
							</div>
                            <div class="col-2">
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block; margin-right: 1%" @click="updateComment(commentIndex)">Confirm</b-button>
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="display: inline-block;" @click="() => deleteComment(commentIndex)">Delete</b-button>
                            </div>
                        </div>
                    </b-row>

                    <b-row style="justify-content: center">
                        <textarea v-model="newComment" style="border: 1px solid black; border-radius: 10px; width: 65%"/>
                    </b-row>
                    <b-row style="justify-content: center">
                        <b-button style="margin-top: 1.25%" :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" @click="addComment()">Add Task</b-button>
                    </b-row>
                    <br>
                    <hr style="width: 85%">
                    <br>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
/*eslint-disable*/
    import axios from "axios";
	import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
	import Multiselect from "vue-multiselect";

    export default {
        components: {
            Multiselect,VueCtkDateTimePicker
        },
        name: '',
        props: {
            contractNumber: Number,
            selectedCustomer: Object,
            canAccess: Object
        },
        data() {
            return {
                action: '',
                allAssetsVehicles: {},
                allContractAssetsVehicles: [],
                allAssets: {},
                allContractAssets: {},
                allContractVehicles: {},
                allVehicles: {},
                editComments: [false],
                newComment: '',
                printing: false,
				selectedContract: {},
                tempComment: '',
                tempContract: {},
				qtyTableFields: [
				  { key: "itemid", label: "Asset Type" },
				  { key: "itemdesc", label: "Asset ID" },
				  { key: "qty", label: "Qty" },
				  { key: "total", label: "Last Serviced" },
				  { key: "receivedQty", label: "Next Available Service" }
				],
            };
        },
        computed: {

        },

        async created() {

        },

        async mounted() {
			await this.getTargetContract(this.contractNumber)
            this.getAssetsVehicles()
            this.getContractAssetsVehicles()
        },

        methods: {
            async addComment(){
                if(this.newComment === "" || this.newComment === null || this.newComment === undefined){
                    alert("You must enter a comment before attaching it to the work order")
                    return
                }
                this.tempContract.tasks.push({...this.newComment})
                this.newComment = '';
                this.$forceUpdate()
            },//END addComment

            deleteComment(commentIndex){
                this.tempContract.tasks.splice(commentIndex, 1)
                this.$forceUpdate()
            },

			download(url){
				window.open(url, '_blank')
			},

            editComment(commentIndex){
                this.editComments[commentIndex] = true
                this.tempComment = this.tempContract.tasks[commentIndex]
                this.$forceUpdate()
            },

            editContract(){
                this.tempContract = {...this.selectedContract}
                this.action = 'edit';
            },

            createAssetGroups(assetsArray, vehiclesArray){
                var extItems = [];
                var scbaItems = [];
                var vehItems = [];
                for(let item in assetsArray){
                    if(assetsArray[item].asset_type == 'Extinguisher'){
                        extItems.push(assetsArray[item]);
                    }
                    else{
                        scbaItems.push(assetsArray[item]);
                    }
                }
                for(let veh in vehiclesArray){
                    vehiclesArray[veh].asset_type = "Vehicle";
                    vehiclesArray[veh].id = vehiclesArray[veh].vehicleid;
                    vehItems.push(vehiclesArray[veh]);
                }

                this.allAssetsVehicles = [];
                this.allAssetsVehicles.push({name: "Extinguisher", items: extItems});
                this.allAssetsVehicles.push({name: "SCBA", items: scbaItems});
                this.allAssetsVehicles.push({name: "Vehicle", items: vehItems});
            },

            async forceExpiration(){
                await axios.patch(`${process.env.VUE_APP_API_TARGET}/customers/maintenanceContract/update/${this.selectedContract.contractid}/${this.$globals.companyId}`,{
                    customerid: this.selectedCustomer.customerid,
                    start: this.selectedContract.start,
                    end: this.selectedContract.end,
                    value: this.selectedContract.value,
                    tasks: JSON.stringify(this.selectedContract.tasks),
					frequency: this.selectedContract.frequency,
					frequencyType: this.selectedContract.frequencyType,
                    contractName: this.selectedContract.contractName,
                    expired: 2
                },
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

            async getAssetsVehicles(){
                await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+this.selectedCustomer.customerid+"/vehicles/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                    this.allVehicles = response.data.queryResult;
                    await axios.get(process.env.VUE_APP_API_TARGET + '/customers/assets/by/cust/'+this.selectedCustomer.customerid+'/'+this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                        this.allAssets = response.data.queryResult;
                        this.createAssetGroups(this.allAssets, this.allVehicles);
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

            async getContractAssetsVehicles(){
                await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/vehicles/${this.selectedContract.contractid}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                    this.allContractVehicles = response.data.queryResult;
                    await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/assets/${this.selectedContract.contractid}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                        this.allContractAssets = response.data.queryResult;
                        this.sortAssets(this.allContractAssets, this.allContractVehicles);
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

			async getTargetContract(contractid){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/target/${contractid}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) =>{
					this.selectedContract = response.data.queryResult[0];
					this.selectedContract.tasks = JSON.parse(this.selectedContract.tasks)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

            sortAssets(assetsArray, vehiclesArray){
                this.allContractAssetsVehicles = [];
                for(let item in assetsArray){
                    this.allContractAssetsVehicles.push(assetsArray[item]);
                }
                for(let veh in vehiclesArray){
                    vehiclesArray[veh].asset_type = "Vehicle";
                    vehiclesArray[veh].id = vehiclesArray[veh].vehicleid;
                    this.allContractAssetsVehicles.push(vehiclesArray[veh]);
                }
            },

            reject(){
				this.$emit('reject')
            },

            async updateComment(commentIndex){
                this.tempContract.tasks[commentIndex] = this.tempComment
                this.editComments[commentIndex] = false
                this.$forceUpdate()
            },//END updateComment

            async updateContract(){

				var formData = new FormData();

				formData.append("customerid", this.selectedCustomer.customerid);
				formData.append("start", this.tempContract.start.split(' ')[0]);
				formData.append("end", this.tempContract.end.split(' ')[0]);
				formData.append("value", this.tempContract.value);
				formData.append("tasks", JSON.stringify(this.tempContract.tasks));
				formData.append("frequency", this.tempContract.frequency);
				formData.append("frequencyType", this.tempContract.frequencyType);
				formData.append("contractName", this.tempContract.contractName);
				if(this.$refs.inputFile.files[0] == null){
					formData.append("file", this.tempContract.file);
				}
				else{
					formData.append("file", this.$refs.inputFile.files[0]);
				}


				let linkedAssets = []
				this.allContractAssetsVehicles.forEach((item, i) => {
					linkedAssets.push({
						id: item.vehicleid || item.id,
						completedTasks: []
					})
				});

				formData.append("linkedAssets", JSON.stringify(linkedAssets));

                await axios.patch(`${process.env.VUE_APP_API_TARGET}/contracts/update/${this.selectedContract.contractid}/${this.$globals.companyId}`,
					formData,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				})
                .then(response => {
                    console.log("contract patched: ",response)
                    for(var assetvehicle in this.allContractAssetsVehicles){
                        if(this.allContractAssetsVehicles[assetvehicle].asset_type === "Vehicle"){
							axios.put(`${process.env.VUE_APP_API_TARGET}/contracts/vehicle/${this.allContractAssetsVehicles[assetvehicle].vehicleid}/${this.$globals.companyId}`,
                            {
								customerid: this.allContractAssetsVehicles[assetvehicle].customerid,
								contractid: this.selectedContract.contractid
                            },
                            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
                            )
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
                        }
                        else{
							axios.put(`${process.env.VUE_APP_API_TARGET}/contracts/asset/${this.allContractAssetsVehicles[assetvehicle].id}/${this.$globals.companyId}`,{
                                contractid: this.selectedContract.contractid,
								customerid: this.allContractAssetsVehicles[assetvehicle].customerid
                            },
                            {headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}})
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
                        }
                    }
                    this.tempContract = {}
					this.action = ''
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Maintenance Contract', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

        },
    };
</script>

<style>
</style>

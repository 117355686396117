<template>
	<div>
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>New Purchase Order</b></h3>
					<p v-if="orderID">For {{orderType}} #{{orderID}}</p>
				</div>
				<div class="card-tools">
					<b-button
						class="mx-1"
						style="background-color: #fff4; border-color: #dc3545"
						@click="submitPO"
						>Submit
					</b-button>
					<b-button
						class="mx-1"
						style="background-color: #fff4; border-color: #dc3545"
						@click="reject()"
						>Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<label for="selectedVendor">Vendor<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<multiselect
					required
					class="w-100"
					id="selectedVendor"
					:options="allVendors"
					v-model="selectedVendor"
					:clearable="true"
					track-by="company"
					label="company"
					@select="getVendorsItems"
					:show-labels="false"
					>
				</multiselect>

                <label for="selectedContact" class="mt-3">Send To<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<multiselect
					class="w-100"
					id="selectedContact"
					:options="selectedVendorContacts"
					v-model="selectedContact"
					:clearable="true"
					track-by="contactid"
					:custom-label="({name, email})=>{
						if(name && email) return `${name} - ${email}`
						else{return `${name}`}
						}"
					:show-labels="false"
					placeholder="Select Vendor Contact"
					required
					>
				</multiselect>

				<!-- <div v-if="selectedVendor.trueMinimum !== null">
					Minimum total to place an order: <b>${{selectedVendor.trueMinimum}}</b>
				</div>
				above is commented out till trueMinimum is created as a col -James -->
				<div v-if="selectedVendor.vendorDiscountMinimum !== null">
					Minimum total for discount: <b>${{selectedVendor.vendorDiscountMinimum}}</b>
					<hr>
				</div>

				<div class="row flex-nowrap" v-if="vendorLowStock.length > 0" style="display: flex; flex-direction: row; overflow-x: scroll">
					<div class="col-3" v-for="(data, index) in vendorLowStock" :key="index" style="border-right: 2px solid #d3d3d3">
						<b>{{data.itemid}}</b> at {{data.location}} - {{data.bin}}<br>
						On hand: {{data.qty}} / {{data.threshold}} 
						<span v-if="data.restockUpTo > 0"><br/>Restock up to: {{data.restockUpTo}}</span>
						<span v-if="data.onOrder > 0"><br/>Already on order: {{data.onOrder}}</span>
					</div>
				</div>

				<template v-if="newLineItem.itemdesc == 'Special Order'">
					<specialOrder :vendor="selectedVendor" @addSOPart="addSOPart" @closeSO="closeSO()"></specialOrder>
				</template>

				<div class="row flex-nowrap" v-if="taskOptions !== null && taskOptions !== undefined" style="display: flex; flex-direction: row; overflow-x: scroll">
					<div class="col-3" v-for="(data, index) in taskOptions" :key="index" style="border-right: 2px solid #d3d3d3">
						<b>{{data.task}}</b><br>
						<b>Request:</b> {{data.request}}<br>
					</div>
					<hr>
				</div>

				<b-table striped :fields="poTableFields" :items="newPOLines">
					<template #cell(qty)="row">
						<input
							class="form-control"
							type="number"
							v-model="row.item.qty"
							min="1"
						/>
						<div v-if="taskOptions !== null && taskOptions !== undefined">
							<multiselect
								v-model="row.item.tasks"
								:options = "taskOptions"
								:multiple="true"
								label= "task"
								:show-labels="false"
								placeholder = "Link to Task(s)"
								track-by="task"
							>
							</multiselect>
						</div>
					</template>
					<template #cell(cost)="row">
						<span>${{ row.item.vendorcost }}</span>
					</template>
					<template #cell(total)="row">
						<span>${{ (row.item.vendorcost * row.item.qty).toFixed(2) }}</span>
					</template>
					<template #cell(action)="row">
						<i
							class="fas fa-trash"
							@click="removeLine(row)"
						></i>
					</template>
					<template #bottom-row>
						<td>
							<label>Item<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<div class="selectors">
								<multiselect
									id="stepParts"
									:clearable="true"
									v-model="newLineItem"
									:options="vendorItems"
									:multiple="false"
									track-by="itemid"
                                    :custom-label="({itemid, itemdesc}) => {
                                        if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                    }"
									label="itemdesc"
									placeholder="Parts"
									style="width: 300px; display: inline-block"
									:show-labels="false"
									required
									>
								</multiselect>
							</div>
						</td>
						<td></td>
						<td>
							<label>Qty<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
							<input
							class="form-control w-75"
							type="number"
							v-model="newLineQty"
							required
							/>
						</td>
						<td></td>
						<td></td>
						<td>
							<i
								style="margin-top: 20px"
								class="fas fa-plus-circle fa-2x"
								@click="addPOLine()"
								>
							</i>
						</td>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import specialOrder from '../Service/SpecialOrder.vue';


export default{
	name:'newPurchaseOrder',
	props: {
		orderID: Number,
		orderType: String,
		taskOptions: Array,
		items: Array,
		vendor: String,
	},
	components: { Multiselect, specialOrder},
	data(){
		return{
			allVendors: [],
			lowStockParts: [],
			newPOLines: [],
			newLineItem : {
				itemid: "",
				itemdesc: "",
				cost: 0,
				sell: 0,
				total: 0
			},
			newLineQty : 1,
			poTableFields: [
			  { key: "itemid", label: "Item ID" },
			  { key: "itemdesc", label: "Description" },
			  { key: "qty", label: "Qty" },
			  { key: "cost", label: "Expected Cost Each" },
			  { key: "total", label: "Total" },
			  { key: "action", label: ""},
			],
            selectedContact: '',
			selectedVendor: {},
            selectedVendorContacts: [],
			vendorItems : [],
			vendorLowStock: [],


		}
	},
	async mounted(){
		if(this.$globals.companyId < 0){
			alert("Invlaid Company ID")
			this.reject();
		}
		if(this.$globals.user === null){
			alert("No User Passed")
			this.reject();
		}

		await this.getLowStock();

		await axios
			.get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}`, {
				headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
			})
			.then((response) => {
				this.allVendors = response.data.queryResult;
				this.selectedVendor = this.allVendors[0]
				this.getVendorsItems(this.allVendors[0])
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		  if(this.vendor){
			  for(let index in this.allVendors){
				  if(this.allVendors[index].company === this.vendor){
					  this.getVendorsItems(this.allVendors[index]);
					  break;
				  }
			  }
			  this.$forceUpdate()
		  }


	},
	methods:{
		addPOLine() {
			//Safety Check
			if(this.newLineQty < 1){
				alert("Qty is below 1")
				return;
			}
			if(this.newLineItem === '' || this.newLineItem === null){
				alert("Pick an Item before adding it")
				return;
			}

			let newLine = { ...this.newLineItem, qty: this.newLineQty };
			newLine.cost = parseFloat(this.newLineItem.cost);
			newLine.total = (newLine.vendorcost * newLine.qty).toFixed(2);
			this.newPOLines.push(newLine);

			this.newLineItem = '';
			this.newLineQty = 1;
		},

		addSOPart(data){
			data.id = "**SO** " + data.id;
			let specialLine = {
				itemid: data.id,
				itemdesc: data.desc,
				qty: parseInt(data.qty),
				cost: parseFloat(data.cost),
				sell: parseFloat(data.sell),
				vendorcost: parseFloat(data.cost),
				total: (data.qty * data.cost).toFixed(2),
				vendor: this.selectedVendor
			}
			this.newPOLines.push(specialLine)

			this.newLineItem = ''
			this.newLineQty = 1
		},

		closeSO(){
			this.newLineItem = ''
		},

		async getVendorsItems(option) {
			this.selectedVendor = option;
			this.newPOLines = [];
			this.vendorLowStock = [];
			if(this.vendor === option.company){
				for(let index in this.items){
					this.newPOLines[index] = {...this.items[index]}
				}
			}

			this.newLineItem = ''
			await axios.get(
				`${process.env.VUE_APP_API_TARGET}/inventory/vendor/items/${this.selectedVendor.company}/${this.$globals.companyId}`,
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
			)
			.then((response) => {
				this.vendorItems = response.data.queryResult;
				console.log("this.vendorItems: ", this.vendorItems);
				this.vendorItems.push({
					itemid: "**SO**",
					itemdesc: "Special Order",
					vendorcost: 0,
					total: 0,
					sell: 0
				})

				if(this.lowStockParts.length > 0){
					for(let i in this.lowStockParts){
						this.vendorItems.forEach((item) => {
							if(item.itemid == this.lowStockParts[i].itemid){
								this.vendorLowStock.push(this.lowStockParts[i])
							}
						});

					}
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.selectedVendor.id}/contacts/${this.$globals.companyId}`,
				{headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}}
			)
			.then((response) => {
				this.selectedVendorContacts = response.data.queryResult
			})
		},

		async getLowStock() {
			await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/lowStock/${this.$globals.companyId}`, {
				headers: {
					'Authorization': `Bearer ${this.$globals.user.auth0Token}`
				}
			}).then((response) => {
				console.log("lowStock: ", response.data.queryResult);
				this.lowStockParts = response.data.queryResult ?? []
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newPurchaseOrder', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		removeLine(row){
			this.newPOLines.splice(row.index,1)
		},

		reject(){
			this.$emit('returnFromNewPO')
		},

		async submitPO() {
			//Safety Checks
			if(this.selectedVendor == '' || this.selectedVendor === null){
				alert("Select a Vendor")
				return;
			}
			if(this.selectedContact == '' || this.selectedContact === null){
				alert("Select a vendor Contact to send to")
				return;
			}
			if(this.newPOLines.length == 0){
				alert("Add items to the PO")
				return;
			}


			for(let index in this.newPOLines){
				this.newPOLines[index].total = this.newPOLines[index].qty * this.newPOLines[index].vendorcost
				this.newPOLines[index].notReceivedFlag = true;
				this.newPOLines[index].receivedQty = [];
			}

		  await axios
			.post(
			  `${process.env.VUE_APP_API_TARGET}/sales/po/${this.$globals.companyId}`,
			  {
				data: JSON.stringify(this.newPOLines),
				vendor: JSON.stringify(this.selectedVendor),
                contact: JSON.stringify(this.selectedContact),
				orderID: this.orderID,
				orderType: this.orderType
			  },
			  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
			)
			.then(async(response) => {
			  if(this.orderID !== null){
				  if(this.orderType === 'SO'){
					  for(let index in this.items){
						  this.items[index] = {...this.items[index], 'notReceivedFlag': true}
					  }
					  await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/sales_order/lineitem/replace/${this.orderID}/${this.$globals.companyId}`, {
						  items: this.items
					  },
					  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } })
				  }
				  else if(this.orderType === 'WO'){
					  axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/lock/tasks/${this.orderID}/${this.$globals.companyId}`,{
						  data: this.newPOLines,
						  poid: response.data.queryResult+1	//NOTE this is a localid not a real POid
					  },{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } })
				  }


			  }
			  this.$emit('returnFromNewPO')
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'New Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		},
	}

}

</script>

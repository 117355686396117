<template>
	<!-- Edit to Close PO -->
	<div v-if="action === ''">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Purchase Order</b></h3>
				</div>
				<div class="card-tools">
					<b-button
						style="background-color: #fff4; border-color: #dc3545"
						class="mx-1"
						@click="closePO()"
					>Close PO
					</b-button>

					<b-button
						style="background-color: #fff4; border-color: #dc3545"
						class="mx-1"
						@click="reject()"
						>Return
					</b-button>
				</div>
			</div>

			<div class="card-body">
				<div class="row">
					<div class="col">
						<img class="img-circle w-75 h-75" :src="selectedCompany.logo" />
					</div>
					<!-- Vendor Info -->
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<div class="row">
							<address>
								<p class="h4">{{ selectedPO.vendor.company }}</p>
								<br />
								{{ selectedPO.vendor.address }}<br />
								Tel: {{ selectedPO.vendor.phone }}
							</address>
						</div>
					</div>

					<!-- COMPANY -->
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<div class="row">
							<address>
								<p class="h4">{{ selectedCompany.name }}</p>
								<br />
								{{ selectedCompany.address }}<br />
								Tel: {{ selectedCompany.phone }}
							</address>
						</div>
					</div>

					<!-- CUSTOMER SHIPPING -->
					<div  v-if="selectedPO.customer" class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<div class="row">
							<address>
								<p class="h4">{{ selectedPO.customer.name }}</p>
								<br />
								{{ selectedPO.customer.shipping }}<br />
								Tel: {{ selectedPO.customer.phone }}
							</address>
						</div>
					</div>
				</div>

				<b-table
					striped
					:fields="[
						{key: 'itemid', label: 'Item ID'},
						{key: 'itemdesc', label: 'Description'},
						{key: 'qty', label: 'Qty Received'},
						{key: 'cost', label: 'Cost Each'},
						{key: 'sell', label: 'Sell Each'},
						{key: 'total'}
					]"
					:items="editPOItems"
				>

					<template #cell(itemdesc)="row">
						<span >{{ row.item.itemdesc }}</span>
					</template>

					<template #cell(qty)="row">
						<div v-if="!row.item.itemid.includes('SHIPPING')">
							{{determineRecivedQty(row.item.receivedQty)}}
						</div>
						<div v-else>
							{{row.item.qty}}
						</div>
					</template>

					<template #cell(cost)="row">
						$<input class="form-control w-75" type="number" step="0.01" min="0" v-model="row.item.cost" @input="determineSell(row.item)" style="display: inline"/>
					</template>

					<template #cell(sell)="row">
						<div v-if="row.item.itemid === 'SHIPPING'">
							$<input class="form-control w-75" type="number" step="0.01" min="0" v-model="row.item.sell" style="display: inline">
							<div v-if="selectedPO.orderType === 'WO' && taskList.length >0">
								<multiselect class="w-75" :clearable="false" :show-labels="false" :options="taskList" v-model="row.item.task" :multiple="false" placeholder = "Link to task"></multiselect>
							</div>
						</div>
						<div v-else>
							$<input class="form-control w-75" type="number" readonly step="0.01" min="0" v-model="row.item.sell" style="display: inline">
						</div>
					</template>

					<template #cell(total)="row">
						Sell Total ${{
							((parseFloat(row.item.sell) + (parseFloat(row.item.sell) * (parseFloat(row.item.markUp) / 100))) * parseFloat(row.item.qty)).toFixed(2)
						}}
						<br>
						Cost Total ${{ (parseFloat(row.item.cost) * parseFloat(row.item.qty)).toFixed(2)}}
					</template>

				</b-table>
			</div>
		</div>
	</div>

</template>

<script>
	/*eslint-disable*/
	import axios from "axios";
	import Multiselect from "vue-multiselect";

	export default{
		name: "app",
		props: {
			companyId: Number,
			user: Object,
			selectedPO: Object,
			selectedCompany: Object,
			editPOItems: Array

		},
		components: {Multiselect},

		data(){
			return {
				action: "",
				taskList: [],

			}
		},

		async mounted(){
			if(this.$globals.companyId < 0){
				alert("Invlaid Company ID")
				this.reject();
			}
			if(this.$globals.user === null){
				alert("No User Passed")
				this.reject();
			}
			if(this.selectedPO === null){
				alert("No Purchase order passed in")
				this.reject()
			}
			if(this.editPOItems.length <1){
				alert("Passed editPOItems was less than 1 long")
				this.reject()
			}
			if(this.selectedCompany === null){
				alert("Passed selected Company was null")
				this.reject()
			}

			for(let index in this.editPOItems){
				for(let i in this.editPOItems[index].tasks){
					this.taskList.push(this.editPOItems[index].tasks[i].task)
				}
				this.determineSell(this.editPOItems[index])
			}
			//TODO reduce out duplicates in this.taskList
		},

		methods: {
			addShipping(){
				this.editPOItems.push({
					itemid: 'SHIPPING',
					itemdesc: `Shipping from - ${this.selectedPO.vendor.company}`,
					markUp: 0,
					qty: 1,
					sell: 0,
					cost: 0,
					total: 0,
					otherLocations: [],
					task: ''
				})
			},

			closePO(){
				//if has order # update that order num too
				//Update PO status, cost/sell & add Shipping cost as 'data' item
				//use editPOItems
				for(let index in this.editPOItems){
					this.editPOItems[index].total = this.editPOItems[index].qty * (this.editPOItems[index].sell)
					this.editPOItems[index].notReceivedFlag = false
				}

				axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/purchaseOrder/close/${this.selectedPO.localid}/${this.$globals.companyId}`,
					{
						data: JSON.stringify(this.editPOItems),
						orderID: this.selectedPO.orderID,
						orderType: this.selectedPO.orderType,
					},{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
				).then(response=>{
					this.$emit('closed')
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Finalize Purchase Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})


			},

			async determineSell(item){
				console.log("determine sell: ", item);
				if(item.itemid.includes("SHIPPING")){
					item.sell = parseFloat(item.cost).toFixed(2)
					return;
				}
				if(!item.itemMarkup){
					//get vendor markup if no itemMarkup
					await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.selectedPO.vendor.company)}/${this.$globals.companyId}`,
						{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
					}).then((response)=>{
						item.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
					})
				}
				console.log("determineSell maths? ", item.highCost, item.cost, item.itemMarkup, item.vendorMarkup);

				if(item.cost > item.highCost){
					item.sell = parseFloat(item.cost * (1 + (item.itemMarkup || item.vendorMarkup))).toFixed(2)
				}
				else{
					item.sell = parseFloat((item.highCost || item.cost) * (1 + (item.itemMarkup || item.vendorMarkup))).toFixed(2)
				}

			},

			determineRecivedQty(receivedArray){
				let total = 0
				for(let i in receivedArray){
					total = total + parseInt(receivedArray[i].qty)
				}
				return total;
			},

			reject(){
				this.$emit('returnFromClose')
			},
		},
	}


</script>

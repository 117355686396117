<template>
    <div>
        <div v-if="action === ''" class="card card-danger">
            <div class="card-header">
                <div class="card-title w-50">
                    <h3><b>{{ selectedTicket.customer }}</b></h3>
                    <h5>
						<span class="asset-link" @click="action='viewVehicle'">{{ selectedTicket.vehicle }}</span>
					 </h5>
                    <h6 v-if="selectedVehicle !== null && selectedVehicle !== undefined && selectedVehicle.vin !== undefined">
						{{ selectedVehicle.vin }} - <b>{{ selectedTicket.infoConfirmed == 1 ? "Confirmed" : "Unconfirmed" }}</b>
					</h6>
                    <div v-else-if="selectedAssets.length > 0">
                        <h6 v-for="asset in selectedAssets" :key="asset.id">
							<span class="asset-link" @click="selectedAsset = asset; action='viewAsset'">{{asset.id}}</span> - <b>{{ selectedTicket.infoConfirmed == 1 ? "Confirmed" : "Unconfirmed" }}</b>
						</h6>
                    </div>
                    <h6>Work Order #{{selectedTicket.localid}}</h6>
                    <h6>Contact: {{selectedTicket.contact !== null ? selectedTicket.contact.title : "None"}} {{selectedTicket.contact !== null ? selectedTicket.contact.name : "None"}}, {{selectedTicket.contact !== null ? selectedTicket.contact.phone : "None"}}, {{selectedTicket.contact !== null ? selectedTicket.contact.email : "None"}}</h6>
                    <h6>Written By: {{selectedTicket.author}}</h6>
                    <h6>Created on: {{selectedTicket.description}} </h6>
                    <h6 v-if="lastEvent !== '01/01/1970'">Last worked on: {{lastEvent}}</h6>
					<div v-if="selectedVehicle !== null" style="margin-top: 1%" >
						<span v-if="selectedVehicle.notes !== null && selectedVehicle.notes !== undefined && selectedVehicle.notes !== ''">
							<b>Note: </b>{{selectedVehicle.notes}}
						</span>
					</div>
                </div>

                <div class="card-tools justify-content-center" style="float:top" v-show="!printing">
                    <b-button class="mx-1" v-if="selectedTicket.status == 'Scheduled' || selectedTicket.status == 'Unscheduled'" style="background-color: #fff4; border-color: #dc3545" @click="completeTicket()">Complete</b-button>
                    <b-button class="mx-1" v-if="!showTaskSelect" style="background-color: #fff4; border-color: #dc3545" @click="showTaskSelect = true">Invoice</b-button>
                    <b-button class="mx-1" v-if="showTaskSelect" style="background-color: #fff4; border-color: #dc3545" @click="action = 'viewInvoice'">Create Invoice</b-button>
                    <b-button class="mx-1" v-if="selectedTicket.enroute_start || selectedTicket.enroute_end" style="background-color: #fff4; border-color: #dc3545" @click="travelReport()">Travel Report</b-button>
                    <b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="printPDF()">Print</b-button>
                    <b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="$emit('return')">Return</b-button>
                    <br>
                    <div v-if="selectedTicket.deliveredOn === null && selectedTicket.returnedOn === null">
                        <b-button class="mx-1" style = "background-color: #fff4; border-color: #dc3545; margin-top: 1%" @click="assetDelivered()">Asset Delivered</b-button>
                    </div>
                    <div v-if="selectedTicket.deliveredOn !== null && selectedTicket.returnedOn === null">
                        <b-button class="mx-1" style = "background-color: #fff4; border-color: #dc3545; margin-top: 1%" @click="assetReturned()">Asset Returned</b-button>
                        <br>
                        Delivered on: {{selectedTicket.deliveredOn}}
                    </div>
                    <div v-if="selectedTicket.deliveredOn !== null && selectedTicket.returnedOn !== null" style="margin-top: 1%, margin-right: 1%">
                        Delivered on: {{selectedTicket.deliveredOn}}
                        <br>
                        Returned on: {{selectedTicket.returnedOn}}
                    </div>
                </div>

            </div>

            <div v-if="!printing" style="justify-content: center; text-align: center;">
                <i class="fas fa-archive" size="sm" @click.stop="archiveTicket(selectedTicket)" style="float: right; margin-right: 1%; margin-top: 2%"></i>
                <i class="fas fa-edit" size="sm" @click.stop="newStep.authorization = null; editTicket(selectedTicket); action = 'editTicket'; editWorkOrder = true" style="float: right; margin-right: 1%; margin-top: 2%"></i><br>
				<div v-if="selectedVehicle!==null && selectedVehicle!==undefined">
					<div v-if="selectedVehicle.warranty_fields !== null && selectedVehicle.warranty_fields.length >0">
						<h5 style="justify-content: center; text-align: center">Warranty Information</h5>
					</div>

					<div class="row" v-if="selectedVehicle.warranty_fields !== null && selectedVehicle.warranty_fields.length >0">
						<div class="col-4" v-for="(warranty, index) in selectedVehicle.warranty_fields" :key="index" style="margin-top: 2%">
							<div class="col">
								<strong>{{ warranty.name }}: </strong> {{warranty.value}}
							</div>
							<div class="col">
								<strong>Start/In Service: </strong>&nbsp;{{ warranty.start.split(' ')[0] }}
							</div>
						</div>
						<wbr />
					</div>
				</div>

			</div>
            <div class="card-body">

                <h3 style="text-align:center"><b>Assigned Technicians</b></h3>
                <div class="row" style="text-align:center">
                    <div class="col" v-for="(tech, index) in selectedTicket.technician" :key="index">
                        <div class="info align-items-center justify-content-center">
                            <a href="#" class="d-inline">{{ tech.name }}</a> &nbsp;
                            <img v-if="!printing && tech.picture" :src="tech.picture" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'" class="img-circle elevation-3" style="width: 2.5vw;" alt="User Image">
                        </div>
                    </div>
                </div>
                <hr style="width: 85%">

                <b-row style="margin: 1%; text-align: center;">
                    <b-col><b>Task</b></b-col>
                    <b-col><b>Descriptions</b></b-col>
                    <b-col><b>Parts</b></b-col>
                    <b-col><b>Hours</b></b-col>
                    <b-col><b>Admin Data</b></b-col>
                    <b-col v-if="showTaskSelect"><b>Select For Invoice</b></b-col>
                </b-row>
                <div v-for="(step,index) in selectedTicket.data" v-bind:key="step.task">
                    <b-row :ref="'t'+index" style="margin: 1%; justify-content: center; text-align: center; align-items: center">
                        <b-col>{{ step.task }}</b-col>
                        <b-col>
                            <b>Work Request: </b><br>{{ step.desc }} <hr>
                            <b>Internal Description: </b><br> {{step.internalDesc}}
                        </b-col>
                        <b-col>
                            <div v-if="step.parts.length>0" style="overflow-y: auto; height: 225px; border: 1px solid #dc3545; border-radius: 20px;">
                                <div v-for="part in step.parts" :key="part.itemid" class="part">
                                    <p>
                                        <b>{{ part.itemid }}</b>
                                        <br>
                                        <span style="font-size:10pt">Qty: {{part.amountUsed !== undefined ? part.amountUsed : 0}} / {{part.fulfilledQty || part.qty}} </span> <i v-if="part.amountUsed !== undefined && part.amountUsed == part.fulfilledQty" class="fas fa-check" style="color: green; margin-left: 10px;"></i>
										<i class="fas fa-edit" @click="partUsedAdjustment = {...part}; adjustmentHelper = {flag: true, task: step.task}"></i>
										<br>
                                        {{ part.itemdesc }}
                                    </p>
									<!-- Modal for parts used adjustments -->
									<div class="modal-backdrop" v-show="adjustmentHelper.flag">
										<div class="modal" ref="itemModal" id="itemModal" tabindex="-1" role="dialog" :style="{'background-color' : backgroundColor}">
											<div class="modal-header">
												<h5 class="modal-title" id="itemModalLabel"><b>Adjust Qtys used of '{{partUsedAdjustment.itemdesc}}'</b></h5>
												<button type="button" class="close" @click="adjustmentHelper.flag = false">
													<span>&times;</span>
												</button>
											</div>
											<div class="modal-body">
												<b-row>
													<b-col class="d-flex justify-content-center" style="font-size: 18pt">
														<b>Filled {{partUsedAdjustment.fulfilledQty}} </b>
													</b-col>
												</b-row>
												<b-row>
													<b-col>
														Number of parts used:
													</b-col>
													<b-col>
														<input class="form-control w-50" type="number" step="1" min="0" :max="partUsedAdjustment.fulfilledQty - partUsedAdjustment.returnAmount" v-model="partUsedAdjustment.amountUsed"/>
													</b-col>
												</b-row>
												<b-row>
													<b-col>
														Number of parts to return:
													</b-col>
													<b-col>
														<input class="form-control w-50" type="number" step="1" min="0" :max="partUsedAdjustment.fulfilledQty - partUsedAdjustment.amountUsed" v-model="partUsedAdjustment.returnAmount"/>
													</b-col>
												</b-row>

											</div>
											<div class="modal-footer">
												<b-row>
													<b-col>
														<button type="button" class="btn btn-danger" :disabled="parseInt(partUsedAdjustment.fulfilledQty) < (parseInt(partUsedAdjustment.returnAmount) + parseInt(partUsedAdjustment.amountUsed)) " @click="adjustUsed()">Confirm</button>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>

                                    <hr>
                                </div>
                            </div>
                            <div v-else style="overflow-y: auto; height: 225px; border: 1px solid #dc3545; border-radius: 20px;">
                                <p>{{step.partsRequested}}</p>
                            </div>
                            <div v-if="printing">
                                <div v-for="part in step.parts" :key="part.itemid" class="part">
                                    <p><b>{{ part.itemid }}</b><br><span style="font-size:8pt">Qty: {{part.amountUsed !== undefined ? part.amountUsed : 0}} / {{part.fulfilledQty || part.qty}} </span><br>{{ part.itemdesc }}</p>
                                    <hr>
                                </div>
                            </div>
                        </b-col>
                        <b-col>
                            Estimated Labor: <br>
                            {{ step.labor }} Hours  <br> <br>
                            Hours on task: <br>

                            {{ parseFloat(totalsArray[parseInt((step.task.split(' ')[1])-1)]).toFixed(1) }}

                            <br>
                            <b-button variant="link" @click.prevent="lastSelectedIndex = index; action = 'hoursLog'; generateHours(step,index);">View Log</b-button>
                            <br>
                            <div v-if="(step.completed === 1) || (step.invoiced === true)">
                                <b class="text-danger" @click="openTask(index)">{{ step.invoiced ? "Invoiced" : "Completed"}}</b>
                            </div>
                            <div v-if="(step.completed !== 1) && (step.invoiced !== true)">
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" @click="() => {
                                completeTask(index)
                                }">Complete Task</b-button>
                            </div>
                        </b-col>
                        <b-col>
                            <div v-if="step.authorization !== undefined"> <!-- Remove this once old tickets are phased out -->
                            <b>Contact: </b><br>{{step.authorization.name}}
                            <div v-if="step.billTo.name !== selectedTicket.customer"><hr>
                                <b>Billed to: </b><br>{{step.billTo.name}}
								<div v-if="step.contractTask !== null && step.contractTask !== undefined">
									Contract Task: {{step.contractTask.task}}
								</div>
                            </div>

                            </div>
                        </b-col>

                        <b-col v-if="showTaskSelect">
                            <p v-if="step.invoiced"><strong class="text-danger"><i>Invoiced</i></strong></p>
							<p v-else-if="step.completed !== 1"><strong class="text-danger"><i>Not Complete</i></strong></p>
                            <div v-else-if="step.lock.length >0"><strong class="text-danger">Waiting on PO(s) </strong>
                                <div v-for="(limit, index) in step.lock" :key="index">
                                    #{{limit}}
                                </div>
                            </div>
                            <input v-else-if="selectedTasks.length > 0 ? selectedTasks[0].billTo === step.billTo : true" type="checkbox" class="form-control danger" @change="updateSelectedTasks(step)"/>
                        </b-col>
                    </b-row>
                    <br>
                    <b-row style="margin-left: 7.28%; justify-content: left "><h4><b>Tech Comments</b></h4></b-row>
                    <br>
                    <b-row style="margin-left: 7.5%; margin-right: 7.5%; align-content: center; align-items: center" v-for="(comment, commentIndex) in step.comments" :key="commentIndex">
                        <div class="row w-100 text-center mb-3" v-if="!editingComment(index,commentIndex)">
                            <div class="col-10">
                                <b>{{comment.user}}</b>:&nbsp;{{Number(comment.comment.charCodeAt(comment.comment.length-1)).toString(16) === 'fffc' ? comment.comment.slice(0,-1) : comment.comment}} - {{comment.time}}
                            </div>
                            <div class="col">
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="margin-right: 1%" @click="() => editComment(index,commentIndex)">Edit</b-button>
                                <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="margin-right: 1%" @click="() => deleteComment(index,commentIndex)">Delete</b-button>
                                <b-button v-if="comment.attachments" style="background: #dc3545; border: #dc3545; margin-top: 1%" @click="() => {
                                    viewAttachments = viewAttachments ? false : true;
                                    editIndex = editIndex > 0 ? -1 : commentIndex;
                                    $forceUpdate();
                                }">{{viewAttachments === true && editIndex === commentIndex ? 'Hide': 'View'}} Attachments</b-button>
                            </div>
                        </div>
                        <div v-else style="margin-bottom: 2.5%">
                            <textarea type="text" class="form-control" style="display: block; height: 100%; width: 69vw; margin-bottom: 0.5%" v-model="comment.comment" />
                            <b-button style="background: #dc3545; border: #dc3545" @click="updateComment(index, commentIndex)">Confirm</b-button>
                        </div>
                        <div class="row w-100 text-center" v-if="comment.attachments">
                            <div style="display: flex; flex-direction: row; width: 100%; overflow-x: auto; align-items: center; align-content: center; align-self: center" v-if="viewAttachments && editIndex === commentIndex">
                                <silent-box :gallery="formattedAttachments(comment)" style="margin: 1%">
                                    <template #silentbox-item="{silentboxItem}">
                                        <img :src="silentboxItem.src" style="padding: 2.5px; height: 100px; width: 100px;" />
                                    </template>
                                </silent-box>
                            </div>
                        </div>
                    </b-row>
                    <b-row style="margin-left: 7.5%; margin-right: 7.5%; justify-content: center">
                        <textarea v-model="newComment[index]" style="border: 1px solid black; border-radius: 10px; width: 100%"/>
                    </b-row>
                    <div class="row" style="margin-left: 10%" v-if="newAttachments">
                        <div style="display: flex; flex-direction: row; width: 90%; overflow: auto; align-items: center; align-content: center">
                            <div v-for="(attachment,index) in newAttachments" :key="index" style="margin: 1%;">
                                <img :src="attachment" height="75px" width="75px" />
                            </div>
                        </div>
                    </div>
                    <b-row style="margin-left: 7.5%; margin-right: 7.5%; justify-content: center">
                        <input class="form-control-file w-100" type="file" :multiple="true" @change="onFileChange" accept="image/png, image/jpeg"/>
                    </b-row>
                    <b-row style="margin-left: 7.5%; margin-right: 7.5%; justify-content: center">
                        <b-button style="width: 25%; margin-top: 1.25%" :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" @click="addComment(step, index)">Add Comment</b-button>
                    </b-row>
                    <br>
                    <hr style="width: 85%">
                    <br>
                </div>
            </div>
        </div>

        <div v-if="action === 'editTicket'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Edit Work Order #</b>{{selectedTicket.localid}}</h3>
						<h5>Creator: {{selectedTicket.author}}</h5>
						<h5>Created on: {{this.selectedTicket.description}} </h5>
					</div>
					<div class="card-tools">
                        <b-button type="submit" class="button" style="background-color: #fff4; border-color: #dc3545" @click.prevent="pushTicket()" :disabled="pendingReq">{{ pendingReq ? 'Updating...' : 'Update Ticket'}}</b-button>
						<b-button type="cancel" class="button" style="background-color: #fff4; border-color: #dc3545" @click.prevent="cancelEdit(); action = ''">Cancel</b-button>
					</div>
				</div>
				<div class="card-body">
					<form>
						<div style="margin:auto;">
							<div class = "row">
								<div class="col">
									<b>CUSTOMER:</b>
									<b-row style="margin-left: 1%; margin-top: 2%">
										<input type="text" id="customer" class="form-control" :placeholder="selectedTicket.customer" style="width: 25vw" readonly>
									</b-row>
								</div>
								<div class="col"> <label for="contactSelect">CONTACT:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
									<multiselect requried id="contactSelect" class="w-75" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allContacts" v-model="newTicket.newContact" @select="pickContact" placeholder="Contact" style="display: inline-block"></multiselect>
									<i class="fas fa-plus-circle" @click="action = 'createContact'; lastState = 'editTicket'; editWorkOrder = true" style="display: inline-block"></i>
								</div>
								<div class="col"> <label for="phoneNumber">CONTACT #</label>
									<input id="phoneNumber" class="form-control" placeholder="Phone Number" type="tel" v-model = "selectedContact.phone" readonly/>
								</div>
							</div>
							<br>
							<div class="row">

								<div class="col"> <label for="vehSelect">ASSET: <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
									<multiselect required id="assetSelect" class="w-75" :clearable="true" :show-labels="false" :options="allAssetsVehicles" group-label="name" :group-select=false group-values="items" v-model="newTicket.newasset" :multiple="multiAsset" track-by="id" label="id" placeholder="Asset" @input="pickAsset" style="display: inline-block;"></multiselect>
									<b-dropdown id="dropdown-1" no-caret text="New" variant="danger" style="margin-left: 0.5vw">
										<b-dropdown-item @click="action = 'createVehicle'; lastState='editTicket'" style="font-family: 'Segoe UI'">Vehicle</b-dropdown-item>
										<b-dropdown-item @click="action = 'createAsset'; lastState='editTicket'" style="font-family: 'Segoe UI'">Asset</b-dropdown-item>
									</b-dropdown>
								</div>

								<div class="col"> <label for="vehAddress">WORK LOCATION: <br></label>
									<input id="vehAddress" class="form-control" placeholder="Location" type="text" v-model ="locAddress">
								</div>
								<div class="col"> <label for="techSelect">TECHS:</label>
									<multiselect id="techSelect" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allTechs" v-model="newTicket.newtech" :multiple="true" :preserve-search="true" style="display: inline-block"></multiselect>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col">
									<label for="startDate">Start Date</label>
									<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm:ss a" v-model="newTicket.newstart" />
								</div>
								<div class="col"></div>
								<div class ="col">
									<label for="endDate">Projected End Date</label>
									<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm:ss a" v-model="newTicket.newend" />
								</div>
							</div>
						</div>
						<br><hr>

						<!-- Edit WO Tasks -->
						<h1 style="float: Center">Tasks</h1>
						<div v-if="contractsArray.length >0" style="text-align:center">
							<hr>

							<h2>Contract Covered Tasks</h2>
							<hr>
							<div v-for="(contract) in contractsArray" :key="contract.contractid">
								<div class="row">
									<div class="col">
										<h4><b>{{contract.contractid}} - {{contract.contractName}}</b></h4>
										<b>Assets in contract:</b>
										<div v-for="(coveredAsset,index) in contract.linkedAssets" :key="index">
											{{coveredAsset.id}}
										</div>
									</div>
								</div>
								<div class="row flex-nowrap" style="display: flex; flex-direction: row; overflow-x: scroll">
									<div class="col-3" v-for="(task, index) in contract.tasks" :key="index">
										<b>Task #{{index+1}}:</b>
										<br>
										{{task.task || task}}
									</div>
								</div>
							</div>
						</div>
						<div style="margin: auto; margin-top: 2.5%;">
							<b-table ref="newTicketSteps" striped :items="this.newTicket.newdata" :fields="[{key: 'task', label: 'Task', thStyle: { width: '5%'}},{key: 'descriptions', label: 'Descriptions', thStyle: { width: '30%'}},{key: ''},{key: 'parts', label: 'Parts', thStyle: { width: '35%'}},{key: 'labor', label: 'Labor', thStyle: { width: '10%'}},{key:'authorization', label: 'Authorization'},{key: 'actions', label: 'Actions', thStyle: { width: '5%'}} ]">

								<template #cell(parts)="row" colspan="2">
									<div>
										<textarea rows="5" class="form-control" style="border: solid 1px #ccc" placeholder="Parts Requested" v-model="row.item.partsRequested"></textarea>
									</div>
									<div v-show="$globals.canAccess.parts || $globals.canAccess.admin">
										<div v-for="(part,index) in row.item.parts" :key="part.itemid" class="part">
											<div v-if="part.itemid !== 'Special Order' && part.itemid !== 'SHIPPING'">
												<div>
													<b-row>
														<b-col cols="7">
															<b>{{part.itemdesc}}</b>
															<br>
															{{part.itemid}}
														</b-col>
														<b-col cols="3">
															<span style="font-size:10pt"> {{part.fulfilledQty}} / {{part.qty}}</span>
															<br>
															<!-- Edit fulfillment button -->
															<i class="fas fa-edit" @click="part.locationFlag = true; $forceUpdate()" style="margin-left: 1%; display: inline;"></i>
															<div v-if="row.item.parts[index].locationFlag">
																<locationSelect :displayClass="$globals.displayClass" :item="row.item.parts[index]" :companyId="$globals.companyId" :user="$globals.user" @reject="row.item.parts[index].locationFlag=false" @accept="locatePart(...arguments, index, row.item.parts)"></locationSelect>
															</div>
														</b-col>
														<b-col cols="1">
															<i class="fas fa-trash ml-2 mt-2" @click.stop="deletePart(index,row.item.parts)"></i>
														</b-col>
													</b-row>
												</div>
												<hr>
											</div>
											<div v-if="part.itemid === 'SHIPPING'">
												<b>{{part.itemdesc}}</b>
											</div>
											<!-- TODO confirm SpO works here for correct reasons -->
											<template v-if="part.itemid == 'Special Order'">
												<specialOrder @addSOPart="addEditSOPart(...arguments, row.item)" @closeSO="deletePart(index,row.item.parts)"></specialOrder>
											</template>
										</div>
										<div class="d-flex">
											<multiselect id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select="false" group-values="items" v-model="editPart" :multiple="false" track-by="itemid"  :custom-label="({itemid, itemdesc}) => {
                                                    if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                            }" label="itemdesc" placeholder="Parts" style="width: 80%; display: inline-block;" @search-change="debounceInventory">
                                                <template slot="option" slot-scope="props">
                                                    <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                        <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                    </intersect>
                                                </template>
                                            </multiselect>
											<input id="partQuantity" style="width: 12.5%" class="form-control ml-1" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="editQty">
											<i class="fas fa-plus-circle ml-2 mt-2" style="margin-top: 2%" @click="addEditedPart(row.item);"></i>
										</div>
									</div>
								</template>

								<template #cell(labor)="row" style="margin-left: 2vw">
									<input id="stepLabor" class="form-control w-50" type="number" inputmode="numeric" name="stepLabor" min="0" step="0.1" placeholder="Est. Labor" v-model="row.item.labor" /> Hours
								</template>

								<template #cell(authorization)="row">
									Authorized by:<br>
									<multiselect :clearable="false" :show-labels="false" :options="allContacts" v-model="row.item.authorization" :multiple="false" track-by="name" label="name"></multiselect>
									<hr>
									Bill to: <br>
									<multiselect :clearable="false" :show-labels="false" :options="billOptions" v-model="row.item.billTo" :multiple="false" track-by="name" label="name" group-label="name" :group-select="false" group-values="items" @search-change="debounceCustomers" @select="row.item.contractTask=null">
                                        <template slot="option" slot-scope="props">
                                            <intersect v-if="props.option.name === billOptions[1].items[billOptions[1].items.length-1].name" @enter="getMoreCustomers">
                                                <span>{{props.option.name}}</span>
                                            </intersect>
                                        </template>
                                    </multiselect>
									<div v-if="row.item.billTo.contractid > 0">
										Contract task: <br>
										<multiselect :clearable="false" :show-labels="false" :options="row.item.billTo.tasks" label="task" track-by="task" :multiple="false" v-model="row.item.contractTask"></multiselect>
									</div>
								</template>

								<template #cell(descriptions)="row">
									<textarea rows="5" class="form-control" style="width:100%; border: 1px solid #ccc" v-model="row.item.desc"></textarea> <br><hr>
									<textarea rows="5" class="form-control" style="width:100%; border: 1px solid #ccc" v-model="row.item.internalDesc"></textarea>
								</template>

								<template #cell(actions)="row">
									<i class="fas fa-trash" size="sm" @click.stop="deleteStep(row.item)"></i>
								</template>

								<template #bottom-row>
										<td></td>
										<td colspan="2">
											<textarea rows="5" class="form-control" style="width:100%; border: 1px solid #ccc" placeholder="Work Requested" v-model="newStep.newdesc"></textarea><br>
											<textarea rows="5" class="form-control" style="width:100%; border: 1px solid #ccc" placeholder="Internal Description" v-model="newStep.newInternalDesc"></textarea>
										</td>
										<td>
											<div>
												<textarea rows="5" class="form-control" style="border: solid 1px #ccc" placeholder="Parts Requested" v-model="newStep.newPartsRequested"></textarea>
											</div>
											<div v-show="$globals.canAccess.parts || $globals.canAccess.admin">
												<div v-for ="(part, index) in newStep.newparts" :key="part.itemid">
													<div v-if="part.itemid !== 'Special Order'">
														<div>
															<b-row>
																<b-col cols="7">
																	<b>{{part.itemdesc}}</b>
																	<br>
																	{{part.itemid}}
																</b-col>
																<b-col cols="3">
																	<span style="font-size:10pt"> {{part.fulfilledQty}} / {{part.qty}}</span>
																	<br>
																	<!-- Edit fulfillment button -->
																	<i class="fas fa-edit" @click="part.locationFlag = true; $forceUpdate()" style="margin-left: 1%; display: inline;"></i>
																	<div v-if="part.locationFlag">
																		<locationSelect :displayClass="$globals.displayClass" :item="part" :companyId="$globals.companyId" :user="$globals.user" @reject="part.locationFlag=false" @accept="locatePart(...arguments, index, newStep.newparts)"></locationSelect>
																	</div>
																</b-col>
																<b-col cols="1">
																	<i class="fas fa-trash ml-2 mt-2" @click.stop="deletePart(index, newStep.newparts)"></i>
																</b-col>
															</b-row>
														</div>
														<hr>
													</div>
													<!--S.O. MODAL HERE -->
													<template v-if="part.itemid == 'Special Order'">
														<specialOrder :user="user" :companyId="companyId" @addSOPart="addSOPart" @closeSO="deletePart(index,newStep.newparts)"></specialOrder>
													</template>
												</div>
												<div class="d-flex">
													<multiselect id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select="false" group-values="items" v-model="newPart" :multiple="false" track-by="itemid"  :custom-label="({itemid, itemdesc}) => {
                                                        return `${itemid} - ${itemdesc}`
                                                    }" label="itemdesc" placeholder="Parts" style="width: 80%; display: inline-block;" @search-change="debounceInventory">
                                                        <template slot="option" slot-scope="props">
                                                            <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                                <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                            </intersect>
                                                        </template>
                                                    </multiselect>
													<input id="partQuantity" style="width: 12.5%" class="form-control ml-1" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="newQuantity">
													<i class="fas fa-plus-circle ml-2 mt-2" style="margin-top: 2%" @click="addPart()"></i>
												</div>
											</div>

										</td>
										<td>
											<input id="stepLabor" class="form-control w-50" type="number" name="stepLabor" min="0" step="0.1" placeholder="Est. Labor" v-model="newStep.stepLabor" /> Hours
										</td>
										<td>
											<multiselect id="stepAuthorization" :clearable="true" :show-labels="false" :options="allContacts" v-model="newStep.authorization" :multiple="false" track-by="name" label="name" placeholder = "Contact Authorization"></multiselect>
											<multiselect id="billSelect" label="name" placeholder="Bill To" track-by="name" :clearable="true" :show-labels="false" :options="billOptions" v-model="newStep.billTo" group-label="name" :group-select="false" group-values="items" @search-change="debounceCustomers" @select="newStep.contractTask = null">
                                                <template slot="option" slot-scope="props">
                                                    <intersect v-if="props.option.name === billOptions[1].items[billOptions[1].items.length-1].name" @enter="getMoreCustomers">
                                                        <span>{{props.option.name}}</span>
                                                    </intersect>
                                                </template>
                                            </multiselect>
											<div v-if="newStep.billTo.contractid > 0">
												Contract task: <br>
												<multiselect v-model="newStep.contractTask" :show-labels="false" :options="newStep.billTo.tasks" label="task" track-by="task" :multiple="false" @select="$forceUpdate()"></multiselect>
											</div>
										</td>
										<td colspan="6"><i style="margin-top:20px;" class="fas fa-plus-circle fa-3x" @click="addStep()"></i></td>
									</template>
							</b-table>
						</div>
					</form>
				</div>
			</div>
        </div>

        <div v-if="action === 'createContact'">
            <newContact :source="selectedCustomer" @return="action = lastState; getContacts(selectedCustomer); $forceUpdate()" />
        </div>

        <div v-if="action === 'hoursLog'">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h5><b>Hours Log WO-{{ selectedTicket.localid }} Task {{ lastSelectedIndex + 1 }}</b></h5>
                    </div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; margin-top: 1%;" @click.prevent="handleReturnFromLog()">Back</b-button>
                    </div>
                </div>
            </div>
			<b-table ref="hourLogTable" :fields="[{key: 'name', sortable: true}, {key: 'in', sortable: true}, {key: 'out', sortable: true}, {key: 'total', sortable: true}, {key: 'edit', label: '', thStyle: {width: '13.5%'}}]" striped :items="this.hoursArray">
                <template #cell(in)="row">
                    <div v-if="(subaction !== 'editClock') || (editIndex !== row.index)">
                        {{row.item.in}}
                    </div>
                    <input v-if="(subaction === 'editClock') && (editIndex === row.index)" type="text" class="form-control" v-model="row.item.in" />
                </template>

                <template #cell(out)="row">
                    <div v-if="(subaction !== 'editClock') || (editIndex !== row.index)">
                        {{row.item.out}}
                    </div>
                    <input v-if="(subaction === 'editClock') && (editIndex === row.index)" type="text" class="form-control" v-model="row.item.out" />
                </template>

                <template #cell(edit)="row">
                    <div v-if="(subaction !== 'editClock') && (editIndex !== row.index)">
                        <b-button style="background: #dc3545; border: #dc3545" @click="()=> {
                            editIndex = row.index
                            subaction = 'editClock';
							ogEvent = { ...row.item }
                        }">Edit</b-button>

                        <b-button style="background: #dc3545; border: #dc3545; margin-left: 1%" @click="removeEvent(row, lastSelectedIndex)">Delete</b-button>
                    </div>

                    <div v-if="(subaction === 'editClock') && (editIndex === row.index)">
                        <b-button style="background: #dc3545; border: #dc3545" @click="handleSubmitEditClock(row, lastSelectedIndex)">Confirm</b-button>

                        <b-button style="background: #dc3545; border: #dc3545; margin-left: 2%" @click="()=> {
                            editIndex = -1
                            subaction = ''
                        }">Cancel</b-button>
                    </div>
                </template>

                <template #bottom-row>
                        <td>
                            <multiselect id="techSelect" label="name" track-by="name" :clearable="true" :show-labels="false" :options="typeof selectedTicket.technician === 'object' ? selectedTicket.technician : JSON.parse(selectedTicket.technician)" v-model="newEvent.tech" :multiple="false" :preserve-search="true" placeholder="Technician(s)"></multiselect>
                        </td>
                        <td>
                            <VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="5" format="M/D/YYYY h:mm:ss A" v-model="newEvent.in" />
                        </td>
                        <td>
                            <VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="5" format="M/D/YYYY h:mm:ss A" v-model="newEvent.out" />
                        </td>
                        <td></td>
                        <td><i class="fas fa-plus-circle fa-3x" @click="addEvent()"></i></td>
                </template>
            </b-table>
		</div>

        <div v-if="action === 'travelReport'">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>Travel Report WO-{{selectedTicket.localid}}</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; margin-top: 1%;" @click.prevent="action = ''">Back</b-button>
                    </div>
                </div>
                <div class="card-body">
                    <!-- <div v-for="time in travelTimes" :key="time">
                        {{time}}
                    </div> -->
                    <b-table :fields="[{key: 'name', sortable: true}, {key: 'in', label: 'Start'}, {key: 'out', label: 'End'}, {key: 'total', label: 'Drive Time'}, {key: 'routeDist', label: 'Route Distance'}, {key: 'routeTime', label: 'Route Time'}]" striped :items="this.travelTimes" >
                        <template #cell(total)="row">
                            {{row.item.total !== '-' ? `${row.item.total} Hours` : row.item.total}}
                        </template>

                    </b-table>
                </div>
            </div>
        </div>

        <!-- <div> -->
            <serviceInvoice v-if="action === 'viewInvoice'" :selectedTicket="selectedTicket" :selectedTasks="selectedTasks" :totalsArray="totalsArray" @return="handleReturnFromInvoice"></serviceInvoice>
        <!-- </div> -->

		<!-- New Vehicle Section -->
		<div v-if="action === 'createVehicle'" style="margin-top:1%">
			<newVehicle :selectedCustomer="selectedCustomer" @return="handleNewAsset()"></newVehicle>
		</div>

		<!-- New Asset Section -->
		<div class="createAsset" v-if="action === 'createAsset'" style="margin-top: 1%">
			<newAsset :selectedCustomer="selectedCustomer" @return="handleNewAsset"></newAsset>
		</div>

		<!-- Vehicle History -->
	    <div v-if="action == 'viewVehicle'">
	        <viewVehicle :passedVehicle="selectedVehicle" :selectedCustomer="selectedCustomer" @return="action = ''; getVehicleInfo(selectedTicket.vehicle, selectedCustomer.customerid) "/>
	    </div>

		<div v-if="action == 'viewAsset'">
			<viewAsset :selectedAsset="selectedAsset" :selectedCustomer="selectedCustomer" @return="action = ''; getAssetInfo(selectedTicket.asset, selectedCustomer.customerid)"></viewAsset>
		</div>

    </div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import printTicket from './printTicket.js'
import specialOrder from './SpecialOrder.vue'
import locationSelect from './InventoryLocationSelecter.vue'
import Paginate from 'vuejs-paginate';
import Intersect from 'vue-intersect';
import { debounce } from 'lodash';
import firehouse from '../Customers/firehouse.vue';
import newAsset from '../Customers/newAsset.vue';
import newContact from '../Customers/newContact.vue';
import serviceInvoice from './serviceInvoice.vue';
import newVehicle from '../Customers/newVehicle.vue'
import viewAsset from '../Customers/viewAsset.vue'
import viewVehicle from '../Customers/viewVehicle.vue'



    export default {
		name:'viewTicket',
        props: {
            selectedTicket: Object
        },

        components: {
            Multiselect,
            VueCtkDateTimePicker,
            specialOrder,
            locationSelect,
            Intersect,
            Paginate,
            debounce,
            serviceInvoice,
            newContact,
            firehouse,
            newAsset,
			newVehicle,
			viewAsset,
			viewVehicle,
        },

        data() {
            return {
                action: '',
                allAssetsVehicles: [],
                allContacts: [],
                allCustomers: [],
                allHouses: [],
                allInv: [],
                allParts: [],
                allTechs: [],
				adjustmentHelper: {flag: false},
				backgroundColor: 'white',
				billOptions: [],
                customerListIsLoading: false,
				contractsArray: [],
                editComments: [],
                editIndex: -1,
                editPart: {},
                editQty: 0,
                hoursArray: [],
                invListIsLoading: false,
                lastSelectedIndex: -1,
                lastEvent: '',
				lastState: '',
                locAddress: '',
                locName: '',
                maxPagesCustomers: 0,
                maxPagesInv: 0,
                multiAsset: true,
                newAttachments: [],
                newComment: [],
                newEvent: {},
                newPart: '',
                newQuantity: 0,
                newStep: {
                    newindex: 0,
                    newdesc: "",
                    newInternalDesc: "",
                    newparts: [],
                    newPartsRequested: "",
                    authorization: '',
                    billTo: '',
					contractTask: null,
                    new_in: null,
                    new_out: null,
                    newcomments: [],
                    completed:0,
                    invoiced:0,
                    stepLabor: 0
                },
                newTicket: {
                    author: this.$globals.user.name,
                    newContact: null,
                    newtech: [],
                    newvehicle: null,
                    newasset: [],
                    newcustomer: null,
                    newstart: null,
                    newend: null,
                    newstatus: null,
                    newdata:[],
                    newid: "",
                },
				ogEvent: {},
                pageNumberCustomers: 0,
                pageNumberInv: 0,
				partUsedAdjustment: {flag: false},
                pendingReq: false,
                prevSearchCustomers: "",
                prevSearchInv: "",
                printing: false,
                showTaskSelect: false,
                selectedAssets: [],	//Many assets to show on ticket
				selectedAsset: {},	//Single asset to send to view
                selectedCustomer: {},
                selectedContact: {},
                selectedTasks: [],
                selectedVehicle: null,
                spOrdParts: [],
                subaction: '',
                totalsArray: [],
                travelTimes: [],
                viewAttachments: false,
            }
        },

        async created(){
            await this.getTicket(this.selectedTicket.localid);
			if(this.selectedTicket.vehicle !== null && this.selectedTicket.vehicle !== undefined){
				await this.getVehicleInfo(this.selectedTicket.vehicle, this.selectedTicket?.contact?.customerid)
			} else {
				await this.getAssetInfo(this.selectedTicket.asset, this.selectedTicket?.contact?.customerid)
			}
            this.debounceCustomers = debounce(this.searchCustomers, 600);
            this.debounceInventory = debounce(this.searchInv, 600);

            this.$globals.socket.emit("joinRoom", `ticket${this.selectedTicket.ticketid}-${this.$globals.companyId}`)
            this.$globals.socket.on("newWorkOrderData", async () => {
                this.getTicket(this.selectedTicket.localid);
            })
            this.$globals.socket.on("connectToRoom", (msg) => console.log(msg))
        },

        async mounted() {
            this.multiAsset = false
			this.getCustomers()
			var custObj = await this.getCustomerByName(encodeURIComponent(this.selectedTicket.customer))
			this.selectedCustomer = custObj;
			this.backgroundColor = this.$globals.displayClass ? '#31373d' : 'white';
            axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/techs/all/${this.$globals.companyId}` , {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then((response) => {
                    this.allTechs = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
                    this.allTechs.unshift({ name: "Unassigned", workZone: "Unassigned" });
                })
                .catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })

            this.getInv();
            await this.totalHours();
            this.$forceUpdate();
        },

        destroyed() {
            this.$globals.socket.emit("leaveRoom", `ticket${this.selectedTicket.ticketid}-${this.$globals.companyId}`)
        },

        methods: {
            async addComment(step, index){
                if(this.newComment[index] === "" || this.newComment[index] === null || this.newComment[index] === undefined){
                    alert("You must enter a comment before attaching it to the work order")
                    return
                }
                let newComment = {
                    'user': this.$globals.user.name,
                    'time': new Date().toLocaleString('en-US'),
                    'comment': this.newComment[index]
                }
                if(this.newAttachments.length > 0){
                    newComment.attachments = this.newAttachments
                }
                var taskIndex = this.selectedTicket.data.findIndex((task) => task.task === step.task)
                this.selectedTicket.data[taskIndex].comments.push(newComment)
                await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/"+this.selectedTicket.ticketid+"/new/comment/"+this.$globals.companyId,{
                    newComment: newComment,
                    task: this.selectedTicket.data[taskIndex].task
                },
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
                this.newComment[index] = '';
                this.newAttachments = []
                this.$forceUpdate()
            },//END addComment

			addEditedPart(row){
				if(this.editPart?.itemid === undefined || this.editPart === null){
					alert("Select an item before adding")
					return;
				}
				if(this.editQty === '' || this.editQty < 1){
					this.editQty = 1;
				}

				if(this.editPart.itemid.includes("**K")){
					var tempComponents = typeof this.editPart.components === 'object' ? this.editPart.components : JSON.parse(this.editPart.components)
	                for(let index in tempComponents){
						let flag = false
						row.parts.forEach((item, i) => {
							if(tempComponents[index].itemid === item.itemid){
								item.qty = parseInt(item.qty) + parseInt(tempComponents[index].qty * this.editQty)
								item.locationFlag = true;
								flag = true
							}
						});
						if(flag === false){
							row.parts.push({
								...tempComponents[index],
								'qty': tempComponents[index].qty * this.editQty,
								'fulfillment' : tempComponents[index].fulfillment ?? [{qty:0}],
								locationFlag: true
							})
						}
	                }
				}
				else{
					let flag = false
					row.parts.forEach((item, i) => {
						if(this.editPart.itemid === item.itemid){
							item.qty = parseInt(item.qty) + parseInt(this.editQty)
							item.locationFlag = true;
							flag = true
						}
					});
					if(flag === false){
						this.editPart = {...this.editPart, 'qty': this.editQty}
						this.editPart.fulfillment = this.editPart.fulfillment ?? [{qty:0}];

						this.editPart.locationFlag = true;
						row.parts.push(this.editPart);
					}
				}
				this.editPart = '';
				this.editQty='';
			},

            async addEditSOPart(data,row){
                //only can accept data or row (weirdness with emit data)
                //accept data then in line call regular addEditedPart(row)
                //set data to this.editPart
                data.id = "**SO** " + data.id;

                let tempInfo = {
                    cost: parseFloat(data.cost),
                    itemdesc: data.desc,
                    itemid: data.id,
                    otherLocations: [],
                    qty: data.qty,
                    sell: parseFloat(data.sell),
                    type: data.type,
                    vendor: data.vendor.company,
                    notReceivedFlag: true,
                    fulfillment: data.fulfillment,
                    fulfilledQty: data.fulfilledQty
                }
                this.spOrdParts.push(tempInfo)
                row.parts.pop()
                row.parts.push(tempInfo)
            },//END addEditedSOPart

            async addEvent(){
                if(this.newEvent.tech === '' || this.newEvent.tech === undefined){
                    return alert("A clock event must have a technician assigned.")
                }
                if(this.newEvent.in === '' || this.newEvent.in === undefined){
                    return alert("A clock event must have a time in assigned.")
                }
                if(this.newEvent.out === '' || this.newEvent.out === undefined){
                    return alert("A clock event must have a time out assigned.")
                }
                if(Date.parse(this.newEvent.in) > Date.parse(this.newEvent.out)){
                    return alert("A clock event must have a time in that occured before the time out.")
                }

                this.selectedTicket.data[this.lastSelectedIndex].time_in.push({
                    'tech': this.newEvent.tech.name,
                    'time': this.newEvent.in
                })
                this.selectedTicket.data[this.lastSelectedIndex].time_out.push({
                    'tech': this.newEvent.tech.name,
                    'time': this.newEvent.out
                })

                await this.pushSparseTicket()
                this.generateHours(this.selectedTicket.data[this.lastSelectedIndex], this.lastSelectedIndex);
                this.newEvent = {
                    tech: "",
                    in: "",
                    out: ""
                }
            },//END addEvent

            addKitParts(){
                var tempKit = {...this.newPart};
                var kitQty = this.newQuantity;
                var tempComponents = JSON.parse(tempKit.components);
                for(let item in tempComponents){
                    tempComponents[item].itemid = tempComponents[item].itemid || tempComponents[item].id
                    this.newPart = tempComponents[item];
                    this.newQuantity = parseInt(tempComponents[item].qty) * parseInt(kitQty);
                    this.addPart();
                }
            },//END addKitParts

            addPart() {
                //newPart, newStep.newParts = []
                if(this.newPart === '' || this.newPart === null){
                    alert("Select a part")
                    return;
                }

                if(this.newPart.itemid.substring(0,3) == "**K"){
                    this.addKitParts();
					return;
                }
				let flag = false
				this.newStep.newparts.forEach((item, i) => {
					if(this.newPart.itemid === item.itemid){
						item.qty = parseInt(item.qty) + parseInt(this.newQuantity)
						item.locationFlag = true;
						flag = true
					}
				});
				if(flag === false){
					this.newPart = {...this.newPart, 'qty': this.newQuantity}
					this.newPart.fulfillment = this.newPart.fulfillment ?? [{qty:0}];
					this.newPart.locationFlag = true;
					this.newStep.newparts.push(this.newPart);
				}
                this.newPart = '';
                this.newQuantity = 1;
            },//END addPart

            async addStep(){
                if(this.newTicket.newcustomer === null || this.newTicket?.newcustomer === undefined){
                    if(this.selectedTicket?.customer !== null && this.selectedTicket?.customer !== undefined){
                        //Shouldn't happen barring weirdness
                        this.newTicket.newcustomer = {...this.selectedTicket.customer}
                    }
                    else{
                        alert("Please select a Customer before trying to add a step!");
                        return;
                    }
                }
                if(this.newTicket?.newContact === null || this.newTicket?.newContact === undefined){
                    alert("Please select a Primary contact for this ticket!");
                    return;
                }
                if(this.newStep.newdesc === "" || this.newStep?.newdesc === undefined || this.newStep.newdesc === null){
                    alert("A new step requires a Work Requested to be filled in!");
                    return;
                }

                if(this.newStep.stepLabor < 0){
                    Math.abs(this.newStep.stepLabor)
                }

                //Used to help with calculating the time of projected end date
                this.totalStepLabor = this.totalStepLabor + parseFloat(this.newStep.stepLabor);

                //If empty fill billTo and Authorized by with Overarching customer and contact.
                if(this.newStep.billTo == '' || this.newStep.billTo == null){
                    this.newStep.billTo = this.newTicket.newcustomer ? this.newTicket.newcustomer : await this.getCustomerByName(this.selectedTicket.customer)
                }
                if(this.newStep.authorization == '' || this.newStep.authorization == null){
                    this.newStep.authorization = this.newTicket.newContact;
                }

				if(this.newStep.contractTask === {} || this.newStep.contractTask === undefined){
					this.newStep.contractTask = null;
				}

                //Push the new step into the ticket
                this.newTicket.newdata.push({
                    "task": `Task ${this.newTicket.newdata.length + 1}`,
                    "desc": this.newStep.newdesc,
                    "internalDesc": this.newStep.newInternalDesc,
                    "parts": this.newStep.newparts,
                    "partsRequested": this.newStep.newPartsRequested,
                    "labor": this.newStep.stepLabor,
                    "authorization": this.newStep.authorization,
                    "billTo": this.newStep.billTo ,
					"contractTask": this.newStep.contractTask || null,
                    "time_in": [],
                    "time_out": [],
                    "comments": this.newStep.newcomments,
                    "completed":0,
                    "invoiced": 0,
                    "uid": parseInt(this.newTicket.newdata[this.newTicket.newdata.length-1]?.uid + 1 ?? 0),
                })

                this.$refs.newTicketSteps.refresh();
                this.newStep.newdesc ="";
                this.newStep.newcomments = [];
                this.newStep.stepLabor = 0;
                this.newStep.newparts=[];
                this.newStep.newInternalDesc="";
                this.newStep.newPartsRequested="";

                this.selectedTicket.data = this.newTicket.newdata
            },//END addStep

			adjustUsed(){
				this.partUsedAdjustment.amountUsed = this.partUsedAdjustment.amountUsed || 0
				this.partUsedAdjustment.returnAmount= this.partUsedAdjustment.returnAmount || 0

				//TODO effficnecy can be imporved
				this.partUsedAdjustment.returnAmount = this.partUsedAdjustment.returnAmount ?? 0
				this.partUsedAdjustment.amountUsed = this.partUsedAdjustment.amountUsed ?? 0
				for(let i in this.selectedTicket.data){
					if(this.adjustmentHelper.task == this.selectedTicket.data[i].task){
						for(let j in this.selectedTicket.data[i].parts){
							if(this.partUsedAdjustment.itemid == this.selectedTicket.data[i].parts[j].itemid){
								if(parseInt(parseInt(this.partUsedAdjustment.amountUsed) + parseInt(this.partUsedAdjustment.returnAmount)) >= parseInt(this.partUsedAdjustment.fulfilledQty)){
									this.partUsedAdjustment.used = 1
								}
								else{
									this.partUsedAdjustment.used = 0
								}
								this.selectedTicket.data[i].parts[j] = this.partUsedAdjustment
                                this.pushSparseTicket();
                                if (this.partUsedAdjustment.returnAmount !== 0) {
                                    axios.post(`${process.env.VUE_APP_API_TARGET}/tickets/return_parts/${this.$globals.companyId}/${this.selectedTicket.localid}`, {
                                        data: JSON.stringify(this.partUsedAdjustment),
                                        task: this.adjustmentHelper.task,
                                        user: this.$globals.user.name
                                    }, {
                                        headers: {
                                            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                                        }
                                    }).then(response => {
                                        console.log("RESPONSE: ", response)
                                    }).catch((response)=>{
                                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewTicket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                                    })
                                }

								this.adjustmentHelper.flag = false;
								break;
							}
						}
						break;
					}
				}
			},

            async archiveTicket(row){
                row = row.item === undefined ? row : row.item
                if(row){
                    await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/"+row.ticketid+"/status/"+ this.$globals.companyId, {}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
                }
                this.$emit('return')
            },//END archiveTicket()

            async assetDelivered() {
                let tempDate = new Date().toISOString().split('T')
                tempDate = tempDate[0]
                this.selectedTicket.deliveredOn = tempDate
                axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/deliveredOn/${this.selectedTicket.ticketid}/${this.$globals.companyId}`,{date: tempDate}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },//END assetDelivered

            async assetReturned() {
                let tempDate = new Date().toISOString().split('T')
                tempDate = tempDate[0]
                this.selectedTicket.returnedOn = tempDate
                axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/returnedOn/${this.selectedTicket.ticketid}/${this.$globals.companyId}`,{date: tempDate}, {headers: {'Authorization':'Bearer '+ this.$globals.user.auth0Token}})
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },//END assetReturned

            cancelEdit(){
                if(this.selectedTicket?.ticketid == undefined){
                    //Go backwards to make sure the length change doesn't matter while deleting.
                    for(let i = this.newTicket.newdata.length-1; i >=0; i--){
                        this.deleteStep(this.newTicket.newdata[i])
                    }
                }
                else {
                    axios.put(`${process.env.VUE_APP_API_TARGET}/tickets/canceledEdit/${this.selectedTicket.ticketid}/${this.$globals.companyId}`,{
                        data: JSON.stringify(this.newTicket.newdata)
                    },{headers: {'Authorization': 'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
                }

                if(this.newStep.newparts.length > 0){
                    for(let index in this.newStep.newparts){
                        this.deletePart(index,this.newStep.newparts)
                    }
                }

                this.newTicket = {
                    author: this.$globals.user.name,
                    newContact: null,
                    newtech: [],
                    newvehicle: null,
                    newasset: [],
                    newcustomer: null,
                    newstart: null,
                    newend: null,
                    newstatus: null,
                    newdata:[],
                    newid: "",
                }
                this.selectedContact = [],
                this.locAddress = ''

                this.action = '';
                removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
				this.$globals.shield = false;
            },//END cancelEdit

            async completeTask(index){
                if(this.selectedTicket.data[index].invoiced === 0 && this.selectedTicket.data[index].completed === 0){
					for(let q in this.selectedTicket.data[index].parts){
						if(this.selectedTicket.data[index].parts[q].fulfilledQty !== (parseInt(this.selectedTicket.data[index].parts[q]?.amountUsed ?? 0) + parseInt(this.selectedTicket.data[index].parts[q]?.returnAmount ?? 0))){
							alert("Adjust amount used/returned before completing a task")
							return;
						}
					}
                    if(confirm(`Are you sure you want to complete WO-${this.selectedTicket.localid} Task ${index+1}?`)){

                        this.selectedTicket.status = "Scheduled"
                        this.selectedTicket.data[index].completed = 1
                        var notAllTasks = false
                        this.selectedTicket.data.forEach((task,index) => {
                            if(task.completed === 0){
                                notAllTasks = true
                            }
                        })
                        this.selectedTicket.status = notAllTasks ? this.selectedTicket.status : "Completed"
                        await this.pushSparseTicket()
                    }
                }
            },//END completeTask

            async completeTicket(){
                if (confirm(`Are you sure you want to complete WO-${this.selectedTicket.localid}? This will remove the work order from technician view.`)) {
                    this.selectedTicket.status = "Completed"
                    await this.pushSparseTicket()
                    this.$emit('return')
                    this.$forceUpdate()
                } else {
                    return
                }
            },//END completeTicket

            async createAssetGroups(assetsArray, vehiclesArray){
				this.allAssetsVehicles = [];
				this.allAssetsVehicles.push({name: "General Service", items: [{id:"Service", asset_type: 'NonAsset'}]})

				let assetTypes = []
				await axios.get(`${process.env.VUE_APP_API_TARGET}/assets/types/${this.$globals.companyId}`,
					{headers: {'Authorization': 'Bearer ' + this.$globals.user.auth0Token }}
				)
				.then((response)=>{
					assetTypes = JSON.parse(response.data.queryResult[0].types)
				})

				for(let i in assetTypes){
					let tempTypeArray = assetsArray.filter((item) =>{
						if(item.asset_type == assetTypes[i]){
							return item
						}
					})
					this.allAssetsVehicles.push({name: assetTypes[i], items: tempTypeArray})
				}

				let vehItems = []
                for(let veh in vehiclesArray){
                    vehiclesArray[veh].asset_type = "Vehicle";
                    vehiclesArray[veh].id = vehiclesArray[veh].vehicleid;
                    vehItems.push(vehiclesArray[veh]);
                }

                this.allAssetsVehicles.push({name: "Apparatus", items: vehItems});
            },//END createAssetGroups

            createGroups(){
                this.allParts = [];
				this.allParts.push({
					name: 'Special Order',
					items: [{
						'itemid': 'Special Order',
						'itemdesc': '**Special Order**',
						'type': 'Special Order'
					}]
				})

				for(let i in this.allTypes){
					let tempTypeArray = this.allInv.filter((item) =>{
						if(item.type == this.allTypes[i].type){
							return item
						}
					})
					this.allParts.push({name: this.allTypes[i].type, items: tempTypeArray})
				}
            },

            deleteComment(taskIndex, commentIndex){
                if(confirm(`Are you sure you want to delete '${ this.selectedTicket.data[taskIndex].comments[commentIndex].comment }'?`)){
                    this.selectedTicket.data[taskIndex].comments = this.selectedTicket.data[taskIndex].comments.filter((comment, index) => index !== commentIndex)
                    this.$forceUpdate()
                    this.pushSparseTicket()
                }
            },//END deleteComment

			deletePart(partIndex,row){
				if(row[partIndex]?.itemid !== undefined && row[partIndex]?.location !== undefined && row[partIndex]?.bin !== undefined){
					row[partIndex].fulfillment.forEach((item, i) => {
						if(item.usedCount.length > 0){
							if(row[partIndex].warrantyFlag){
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(item.itemid)}/${encodeURIComponent(item.location)}/${encodeURIComponent(item.bin)}/${this.$globals.companyId}`,
								{
									qty: 0-item.qty,
									addBack: JSON.stringify(item.usedCount),
									vendor: item.vendor
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
							else{
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(item.itemid)}/${encodeURIComponent(item.location)}/${encodeURIComponent(item.bin)}/${this.$globals.companyId}`,
								{
									qty: 0-item.qty,
									addBack: JSON.stringify(item.usedCount),
									vendor: item.vendor
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
						}


					});

				}
				row.splice(partIndex,1)
			},

            deleteStep(row){

                //Removes attached parts to put back into inventory
                row.parts.forEach((item, i) => {
                    this.deletePart(i,row.parts)
                });

                //Removes task from the list
                this.newTicket.newdata = this.newTicket.newdata.filter((dataRow, index) => {
                    return dataRow.task !== row.task
                })

                //Re names all the tasks to be in order
                this.newTicket.newdata.forEach((step, i) => {
                    this.newTicket.newdata[i].task = `Task ${i+1}`
                });

            },//END deleteStep

            editComment(taskIndex,commentIndex){
                this.editComments[taskIndex] = this.editComments[taskIndex] === undefined ? [] : this.editComments[taskIndex]
                this.editComments[taskIndex][commentIndex] = true
                this.$forceUpdate()
            },//END editComment

            editingComment(taskIndex, commentIndex){
                this.editComments[taskIndex] = this.editComments[taskIndex] === undefined ? [] : this.editComments[taskIndex]
                this.editComments[taskIndex][commentIndex] = this.editComments[taskIndex][commentIndex] === undefined ? false : this.editComments[taskIndex][commentIndex]
                return this.editComments[taskIndex][commentIndex]
            },//END editingComment

			async editTicket(selectedTicket){
				this.$globals.shield = true;
                this.selectedTicket = selectedTicket;
                // //this.getContacts needs to get passed the customer obj info
                var custObj = await this.getCustomerByName(encodeURIComponent(this.selectedTicket.customer))
				this.selectedCustomer = custObj;
                this.getContacts(custObj);
                this.getAssetsVehicles(custObj);
                this.selectedTicket.asset = typeof this.selectedTicket.asset !== 'object' ? JSON.parse(this.selectedTicket.asset) : this.selectedTicket.asset;
                this.newTicket.newasset = []

				if(this.selectedVehicle !== null && this.selectedVehicle !== undefined && this.selectedVehicle !== {}){
					this.newTicket.newasset = [{...this.selectedVehicle, id: this.selectedVehicle.vehicleid}]
					this.multiAsset = false;
				}
				else if(this.selectedAssets.length >0){
					this.newTicket.newasset = [...this.selectedAssets]
					this.multiAsset = true;
				}
				else{
					for (let i in this.selectedTicket.asset) {
						this.multiAsset = true
						this.newTicket.newasset.push({
							id: this.selectedTicket.asset[i]
						})
					}
				}

                this.newTicket.newcustomer = custObj;
                this.selectedContact = {...this.selectedTicket.contact};
                this.newTicket.author = this.selectedTicket.author;
                this.newTicket.newContact = this.selectedTicket.contact;
                await axios.get(process.env.VUE_APP_API_TARGET + "/vehicles/"+encodeURIComponent(this.selectedTicket.vehicle) + "/cust/" + this.selectedTicket.contact.customerid + "/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
                    this.newTicket.newvehicle = (response.data.queryResult[0])
                })
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
                this.newTicket.newtech = (typeof this.selectedTicket.technician === 'object' ? this.selectedTicket.technician : JSON.parse(this.selectedTicket.technician))
                this.newTicket.newstart = this.selectedTicket.start_date;
                this.newTicket.newend = this.selectedTicket.end_date;

                this.newTicket.newstatus = this.selectedTicket.status;
                this.newTicket.newdata = _.cloneDeep(this.selectedTicket.data); //NOTE: does break refrence

                this.newStep.billTo = custObj;
                this.isBusy = false;
                this.locAddress = this.selectedTicket.loc_address || this.locAddress;

                addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
            },//END editTicket()

            formattedAttachments(comment){
                let tempArray = []
                comment.attachments = typeof comment.attachments === 'object' ? comment.attachments : JSON.parse(comment.attachments)

                comment.attachments.forEach((attachment) => {
                    tempArray.push({
                        src: attachment.url ?? attachment,
                        description: attachment.description ?? '',
                        thumbnailHeight: 100,
                        thumbnailWidth: 100
                    })
                })

                return tempArray
            },//END formattedAttachments

            generateHours(task, index){
                //task should be this.selectedTicket.data[x], x === lastSelectedIndex || index
                this.hoursArray=[];
                this.lastSelectedIndex = index;
                let matchedTimes = []
                let outs = [...task.time_out.sort((a,b) => Date.parse(a.time) < Date.parse(b.time) ? -1 : 1)]
                let latestClockEvent = "01/01/1970"
                for (let clockIn in task.time_in) {
					          //Conscious choice to substitute name for tech to differentiate mobile data from web data. mobile sends {"tech":"", "time":""} where web is holding name
                    this.hoursArray.push({
                        'name': task.time_in[clockIn].tech,
                        'in': task.time_in[clockIn].time,
                        'out': '-',
                        'total': '-'
                    })
                }

                for (let out in outs) {
					let unmatched = true
                    for (let clockPair in this.hoursArray) {
                        if (this.hoursArray[clockPair].out === '-' && (Date.parse(outs[out].time) >= Date.parse(this.hoursArray[clockPair].in)) && (this.hoursArray[clockPair].name === outs[out].tech) && !matchedTimes.some((element) => element.tech === outs[out].tech && element.out === outs[out].time)) {
							this.hoursArray[clockPair].out = outs[out].time;
							this.hoursArray[clockPair].total = ((Date.parse(outs[out].time) - Date.parse(this.hoursArray[clockPair].in))/1000/60/60).toFixed(1);
                            latestClockEvent = Date.parse(outs[out].time) > Date.parse(latestClockEvent) ? outs[out].time : latestClockEvent
							//chose to use out to know this is not a transmitted piece of data, nor being displayed.
							matchedTimes.push({
								"out":this.hoursArray[clockPair].out,
								"tech":this.hoursArray[clockPair].name,
							})
							unmatched = false
							break;
						}
                    }
					if (unmatched) {
						this.hoursArray.push({
							'name': outs[out].tech,
							'in': '-',
							'out': outs[out].time,
							'total': '-'
						})
                    }
                    this.lastEvent = latestClockEvent
                }
                this.$forceUpdate()
            }, //END generateHours()

			generateBillToOptions(){
				this.billOptions = []
				this.billOptions.push({name: 'Contracts', items: this.contractsArray})
                this.billOptions.push({ name: 'Customers', items: this.allCustomers })
			},

            async getCustomers(){
                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.allCustomers = response.data.queryResult.results
                        this.maxPagesCustomers = response.data.queryResult.maxPages
                        this.pageNumberCustomers = this.pageNumberCustomers+1
						this.generateBillToOptions()
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },

            async getMoreCustomers(){
                if(this.pageNumberCustomers >= this.maxPagesCustomers){
                    return
                }
                let text = this.prevSearchCustomers === "" ? text = null : text = this.prevSearchCustomers
                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        for(var item in response.data.queryResult.results){
                            this.allCustomers.push(response.data.queryResult.results[item])
                        }
                        this.maxPagesCustomers = response.data.queryResult.maxPages
                        this.pageNumberCustomers = this.pageNumberCustomers + 1
                        this.generateBillToOptions();
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },

            async getInv(){
                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.allInv = response.data.queryResult.results
                        this.maxPagesInv = response.data.queryResult.maxPages
                        this.allTypes = response.data.queryResult.types
                        this.pageNumberInv = this.pageNumberInv+1
                        this.createGroups()
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },

            async getMoreInv(){
                if(this.pageNumberInv >= this.maxPagesInv){
                    return
                }
                let text = this.prevSearchInv === "" ? text = null : text = this.prevSearchInv
                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        for(var item in response.data.queryResult.results){
                            this.allInv.push(response.data.queryResult.results[item])
                        }
                        this.maxPagesInv = response.data.queryResult.maxPages
                        this.allTypes = response.data.queryResult.types
                        this.pageNumberInv = this.pageNumberInv+1
                        this.createGroups()
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
            },

            async getAssetInfo(assets, custid){
                let result = []
				for(let x in assets){
					if(assets[x] !== 'Service'){
						await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${assets[x]}/${custid}/${this.$globals.companyId}`, {
							headers: {
								'Content-Type': 'application/json',
								'Authorization': `Bearer ${this.$globals.user.auth0Token}`
							}
						}).then(async (response) => {
							result.push(response.data.queryResult[0])
							if(response.data.queryResult[0].contractid > 0){
								await this.getContractInfo(response.data.queryResult[0].contractid)
							}
						})
						.catch((response)=>{
		                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		                })
					}

				}
                this.selectedAssets = result
            },//END getAssetInfo

            async getAssetsVehicles(option){
                await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/vehicles/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                    let allVehicles = response.data.queryResult;
                    await axios.get(process.env.VUE_APP_API_TARGET + '/customers/assets/by/cust/'+option.customerid+'/'+this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                        let allAssets = response.data.queryResult;
                        this.createAssetGroups(allAssets, allVehicles);
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
                })
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
            },//END getAssetsVehicles

            async getContacts(option){
                await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/contacts/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
                    this.allContacts = response.data.queryResult;
                })
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
            },//END getContacts

            async getCustomerByName(name){
                let response = await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${name}`, {
                    headers: {
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                })
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
                return response.data.queryResult[0]
            },//END getCustomerByName

			async getVehicleInfo(vehicleid, customerid){
				if(vehicleid == null || customerid === null || vehicleid == 'Service'){
					return;
				}
                await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${encodeURIComponent(vehicleid)}/cust/${customerid}/${this.$globals.companyId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                }).then(async (response) => {
                    this.selectedVehicle = response.data.queryResult[0]
					if(this.selectedVehicle?.contractid > 0 ){
						await this.getContractInfo(this.selectedVehicle.contractid)
					}
					this.selectedVehicle.warranty_fields = typeof this.selectedVehicle.warranty_fields === 'object' ? this.selectedVehicle.warranty_fields : JSON.parse(this.selectedVehicle.warranty_fields)
					this.$forceUpdate()
				})
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })

            },//END getVehicleInfo

			async getContractInfo(contractid){
				let flag = true;
				for(let i in this.contractsArray){
					if(this.contractsArray[i].contractid === contractid){
						//don't add.
						flag = false
						break;
					}
				}
				if(flag){
					await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/target/${contractid}/${this.$globals.companyId}`,{
						headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${this.$globals.user.auth0Token}`}
					}).then((response)=>{
						response.data.queryResult[0].tasks = (typeof response.data.queryResult[0].tasks !== 'object' && response.data.queryResult[0].task !== null) ? JSON.parse(response.data.queryResult[0].tasks) : response.data.queryResult[0].tasks
						response.data.queryResult[0].linkedAssets = (typeof response.data.queryResult[0].linkedAssets !== 'object' && response.data.queryResult[0].linkedAssets !== null) ? JSON.parse(response.data.queryResult[0].linkedAssets) : response.data.queryResult[0].linkedAssets
						response.data.queryResult[0].name = `${response.data.queryResult[0].contractid} - ${response.data.queryResult[0].contractName}`

						this.contractsArray.push(response.data.queryResult[0])
						this.$forceUpdate()
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}

			},

            async getTicket(localid) {
                await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/ticket/${localid}/${this.$globals.companyId}`, {
                    headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                })
                .then(async (response)=>{
                    this.selectedTicket = response.data.queryResult[0]
                    this.selectedTicket.contact = typeof response.data.queryResult[0].contact === 'object' ? response.data.queryResult[0].contact : JSON.parse(response.data.queryResult[0].contact)
                    this.selectedTicket.data = typeof response.data.queryResult[0].data === 'object' ? response.data.queryResult[0].data : JSON.parse(response.data.queryResult[0].data);
                    this.selectedTicket.asset = typeof response.data.queryResult[0].asset === 'object' ? response.data.queryResult[0].asset : JSON.parse(response.data.queryResult[0].asset);
                    this.selectedTicket.technician = typeof response.data.queryResult[0].technician === 'object' ? response.data.queryResult[0].technician : JSON.parse(response.data.queryResult[0].technician)
                    if(this.action === 'editTicket'){
                        if(this.selectedVehicle !== null && this.selectedVehicle !== undefined && this.selectedVehicle !== {}){
                            this.newTicket.newasset = [{...this.selectedVehicle, id: this.selectedVehicle.vehicleid}]
                            this.multiAsset = false;
                        } else if(this.selectedAssets.length > 0) {
                            this.newTicket.newasset = [...this.selectedAssets]
                            this.multiAsset = true;
                        } else {
                            for(let i in this.selectedTicket.asset){
                                this.multiAsset = true
                                this.newTicket.newasset.push({
                                    id: this.selectedTicket.asset[i]
                                })
                            }
                        }
                        var custObj = await this.getCustomerByName(encodeURIComponent(this.selectedTicket.customer))

                        this.newTicket.newcustomer = custObj;
                        this.selectedContact = {...this.selectedTicket.contact};
                        this.newTicket.author = this.selectedTicket.author;
                        this.newTicket.newContact = this.selectedTicket.contact;
                        await axios.get(process.env.VUE_APP_API_TARGET + "/vehicles/"+encodeURIComponent(this.selectedTicket.vehicle) + "/cust/" + this.selectedTicket.contact.customerid + "/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
                            this.newTicket.newvehicle = (response.data.queryResult[0])
                        })
                        .catch((response)=>{
                            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                        })
                        this.newTicket.newtech = (typeof this.selectedTicket.technician === 'object' ? this.selectedTicket.technician : JSON.parse(this.selectedTicket.technician))
                        this.newTicket.newstart = this.selectedTicket.start_date;
                        this.newTicket.newend = this.selectedTicket.end_date;

                        this.newTicket.newstatus = this.selectedTicket.status;

                        let clientData = [..._.cloneDeep(this.newTicket.newdata)]
                        let serverData = [..._.cloneDeep(this.selectedTicket.data)]

                        let firstFound = null
                        let firstIndex = -1
                        if (this.deletedTask) {
                            //Should do the same as below but with a system to gauge closeness since task numbers are not guaranteed to be consistent
                            let close_matrix = []
                            clientData.forEach((task, taskIndex) => {
                                //find all duplicate tasks, to be reconciled later
                                // total possible closeness on perfect match
                                // desc = 1, parts = 1, task = 1, billto = 1, time_in = 1, time_out = 1, comments = 1, authorization = 1, invoiced = 1, completed = 1
                                // total possible closeness = 10

                                let t = null
                                let tindex = -1
                                let close_array = []
                                for (t of serverData){
                                    tindex += 1
                                    if (true) { // && t.task === firstFound.task

                                        let closeness = 0
                                        if (t.task === task.task) {
                                            closeness += 0.75
                                        }

                                        console.log("CLOSENESS AFTER TASK @ ", taskIndex, tindex, closeness)

                                        if (t.description === task.description) {
                                            closeness += 1
                                        } else if (t.description.substring(0, Math.floor(t.description.length / 1.33333)) === task.description.substring(0, Math.floor(task.description.length / 1.33333))) {
                                            // 3/4 of the string matches
                                            closeness += 0.75
                                        } else if (t.description.substring(0, Math.floor(t.description.length / 2)) === task.description.substring(0, Math.floor(task.description.length / 2))) {
                                           // 1/2 of the string matches
                                           closeness += 0.5
                                        } else if (t.description.substring(0, Math.floor(t.description.length / 4)) === task.description.substring(0, Math.floor(task.description.length / 4))) {
                                           // 1/4 of the string matches
                                           closeness += 0.25
                                        }
                                        console.log("CLOSENESS AFTER DESC @ ", taskIndex, tindex, closeness)

                                        if (t.billTo.customerid === task.billTo.customerid) {
                                            closeness += 1
                                        }

                                        console.log("CLOSENESS AFTER BILLTO @ ", taskIndex, tindex, closeness)

                                        if(t.authorization.customerid === task.authorization.customerid){
                                            if(t.authorization.name === task.authorization.name){
                                                closeness += 1
                                            } else {
                                                closeness += 0.5
                                            }
                                        }

                                        console.log("CLOSENESS AFTER AUTH @ ", taskIndex, tindex, closeness)

                                        if(t.invoiced === task.invoiced){
                                            closeness += 1
                                        }

                                        console.log("CLOSENESS AFTER INVOICED @ ", taskIndex, tindex, closeness)

                                        if (t.completed === task.completed) {
                                            closeness += 1
                                        }

                                        console.log("CLOSENESS AFTER COMPLETED @ ", taskIndex, tindex, closeness)

                                        if ((t.time_in.length === 0) && (t.time_in.length === task.time_in.length)) {
                                            closeness += 0.25
                                        }

                                        //O(n^2)
                                        task.time_in.forEach((clockIn) => {
                                            t.time_in.forEach((newClockIn) => {
                                                if (clockIn.tech === newClockIn.tech) {
                                                    if (clockIn.time === newClockIn.time) {
                                                        closeness += parseFloat(1/t.time_in.length)
                                                    } else {
                                                        closeness += parseFloat(0.5/t.time_in.length)
                                                    }
                                                }
                                            })
                                        })

                                        console.log("CLOSENESS AFTER TIME IN @ ", taskIndex, tindex, closeness)

                                        if ((t.time_out.length === 0) && (t.time_out.length === task.time_out.length)) {
                                            closeness += 0.25
                                        }
                                        //O(n^2)
                                        task.time_out.forEach((clockIn) => {
                                            t.time_out.forEach((newClockIn) => {
                                                if (clockIn.tech === newClockIn.tech) {
                                                    if (clockIn.time === newClockIn.time) {
                                                        closeness += parseFloat(1/t.time_out.length)
                                                    } else {
                                                        closeness += parseFloat(0.5/t.time_out.length)
                                                    }
                                                }
                                            })
                                        })

                                        console.log("CLOSENESS AFTER TIME OUT @ ", taskIndex, tindex, closeness)

                                        if ((t.comments.length === 0) && (t.comments.length === task.comments.length)) {
                                            closeness += 0.25
                                        }

                                        task.comments.forEach((comment) => {
                                            t.comments.forEach((newComment) => {
                                                if (comment.user === newComment.user) {
                                                    if (comment.time === newComment.time) {
                                                        if (comment.comment === newComment.comment) {
                                                            closeness += parseFloat(1/t.comments.length)
                                                        } else {
                                                            closeness += parseFloat(0.5/t.comments.length)
                                                        }
                                                    }
                                                }
                                            })
                                        })

                                        console.log("CLOSENESS AFTER COMMENTS @ ", taskIndex, tindex, closeness)

                                        if (t.parts.length === task.parts.length) {
                                            if (t.parts.length === 0) {
                                                closeness += 0.25
                                            } else {
                                                //O(n)
                                                for (let part in t.parts) {
                                                    if (t.parts[part].itemid === task.parts[part].itemid) {
                                                        if (t.parts[part].qty !== task.parts[part].qty) {
                                                            closeness += parseFloat(0.5/t.parts.length)
                                                        } else {
                                                            closeness += parseFloat(1/t.parts.length)
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            //O(n^2)
                                            t.parts.forEach((part) => {
                                                let foundObj = task.parts.find(p => p.itemid === part.itemid)
                                                if (foundObj !== null && foundObj !== undefined) {
                                                    if (part.qty !== foundObj.qty) {
                                                        closeness += parseFloat(0.5/t.parts.length)
                                                    } else {
                                                        closeness += parseFloat(1/t.parts.length)
                                                    }
                                                }
                                            })
                                        }
                                        console.log("CLOSENESS AFTER PARTS @ ", taskIndex, tindex, closeness)

                                        close_array[tindex] = closeness
                                    }
                                }
                                close_matrix[taskIndex] = close_array
                            })
                            console.log("Closeness matrix", close_matrix)
                            for (let outer in close_matrix) {
                                let innerMax = -1
                                let maxIndex = -1
                                for (let inner in close_matrix[outer]) {
                                    if(close_matrix[outer][inner] > innerMax){
                                        innerMax = close_matrix[outer][inner]
                                        maxIndex = inner
                                    }
                                }
                                clientData[outer].comments = serverData[maxIndex].comments
                                clientData[outer].time_in = serverData[maxIndex].time_in
                                clientData[outer].time_out = serverData[maxIndex].time_out
                                clientData[outer].parts = serverData[maxIndex].parts
                            }

                        } else {
                            //Appropriately identifies duplications and reconciles. O(n) = n(2n) + (n^2 + n) = 2n^2 + n^2 + n = n^2
                            console.log("client array before", ..._.cloneDeep(clientData))

                            clientData.forEach((task, taskIndex) => {
                                console.log("Task", task)
                                firstFound = serverData.find((t) => t.task === task?.task)
                                firstIndex = serverData.findIndex((t) => t.task === task?.task)

                                console.log("CONDITION", (firstFound?.task === task?.task && firstIndex === taskIndex))
                                if (firstFound?.task === task?.task && firstIndex === taskIndex) {
                                    clientData[firstIndex].parts = firstFound?.parts
                                    clientData[firstIndex].comments = firstFound?.comments
                                    clientData[firstIndex].time_in = firstFound?.time_in
                                    clientData[firstIndex].time_out = firstFound?.time_out
                                }
                            })
                        }
                        this.newTicket.newdata = clientData

                        this.newStep.billTo = custObj;
                        this.isBusy = false;
                        this.locAddress = this.selectedTicket.loc_address || this.locAddress;
                    }
                    if (this.action !== 'hoursLog') {
                        this.totalHours()
                    }
                    this.$forceUpdate()
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

            handleReturnFromInvoice(){
                this.action = '';
                this.selectedTasks = [];
                this.showTaskSelect = false;
                this.$forceUpdate();
            },

            handleReturnFromLog() {
                this.generateHours(this.selectedTicket.data[this.lastSelectedIndex], this.lastSelectedIndex);
                this.action = '';
                this.totalHours()
                this.$nextTick(() => {
                    if(this.lastSelectedIndex !== -1){
                        let taskEl = this.$refs['t'+this.lastSelectedIndex][0]
                        taskEl.scrollIntoView({
                            behavior: 'auto',
                            block: 'center',
                            inline: 'nearest'
                        })
                    }
                    this.lastSelectedIndex = -1;
                    this.$forceUpdate()
                })
            },//END handleReturnFromLog

			async handleNewAsset(){
				await this.getAssetsVehicles(this.selectedCustomer)
				this.action = this.lastState
				this.$forceUpdate()
			},

            handleSubmitEditClock(row, index) {
                this.subaction = '';
                if (this.ogEvent.in === '-' && this.ogEvent.out !== '-' && row.item.out === this.ogEvent.out) {
					this.selectedTicket.data[this.lastSelectedIndex].time_in.push({
						"tech": row.item.name,
						"time": row.item.in
					})
				} else if (this.ogEvent.out === '-' && this.ogEvent.in !== '-' && row.item.in === this.ogEvent.in) {
					this.selectedTicket.data[this.lastSelectedIndex].time_out.push({
						"tech": row.item.name,
						"time": row.item.out
					})
				} else {
					let clockEditIn = this.selectedTicket.data[this.lastSelectedIndex].time_in.findIndex((clock) => (clock.tech == this.ogEvent.name) && (clock.time == this.ogEvent.in))
					let clockEditOut = this.selectedTicket.data[this.lastSelectedIndex].time_out.findIndex((clock) => (clock.tech == this.ogEvent.name) && (clock.time == this.ogEvent.out))

					if(clockEditIn >=0) this.selectedTicket.data[this.lastSelectedIndex].time_in[clockEditIn].time = row.item.in
					if(clockEditOut >=0) this.selectedTicket.data[this.lastSelectedIndex].time_out[clockEditOut].time = row.item.out
				}
                this.pushSparseTicket();
                this.lastSelectedIndex = index
                this.generateHours(this.selectedTicket.data[index], index);
                this.editIndex = -1;
                this.$forceUpdate()
            },

			locatePart(data, index, partArray){
				partArray[index].fulfillment = [];
				partArray[index].fulfilledQty = 0
				partArray[index].vendorMarkup = 0.1

				data.forEach((item, i) => {
					partArray[index].fulfillment.push(item)
					partArray[index].fulfilledQty = parseInt(partArray[index].fulfilledQty) + parseInt(item.qty)
					axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(item.vendor)}/${this.$globals.companyId}`,
						{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
					}).then((response)=>{
						if(response.data.queryResult[0]?.markup > partArray[index].vendorMarkup){
							partArray[index].vendorMarkup = response.data.queryResult[0].markup;
						}
					})
				});
				partArray[index].locationFlag = false
				this.$forceUpdate()
			},

            async onFileChange(e){
                var files = e.target.files || e.dataTransfer.files
                if(!files.length){
                    return
                }
                files.forEach((file) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async (e) =>{
                        await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`,{
                            pic: e.target.result
                        }, {
                            headers: {
                                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                            }
                        }).then( (response) => {
                            this.newAttachments.push(response.data.queryResult)
                            this.$forceUpdate()
                        })
						.catch((response)=>{
		                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		                })
                    };
                })
            },//END onFileChange

            async openTask(index){
                if(this.selectedTicket.data[index].invoiced === 0 && this.selectedTicket.data[index].completed === 1){
                    if(confirm(`Are you sure you want to open WO-${this.selectedTicket.localid} Task ${index+1}?`)){
                        this.selectedTicket.status = "Scheduled"
                        this.selectedTicket.data[index].completed = 0
                        await this.pushSparseTicket()
                    }
                }
            },//END openTask

			pickContact(option){
				this.selectedContact = option;
			},

			async pickAsset(option){
				if(option === null || option.length === 0){
					//the list is empty with no selections
					this.newTicket.newasset = []
					return;
				}
				if(option.length == undefined){
					option = [option]
				}
                if(option[option.length-1].asset_type !== 'NonAsset'){
                    await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/vehiclecheck/status/${encodeURIComponent(option[option.length-1].id)}/${encodeURIComponent(this.selectedTicket.customer)}/${this.$globals.companyId}`,
                        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
                    })
                    .then(response=>{
                        if(response.data.queryResult.length > 0){
                            let relatedList = []
                            response.data.queryResult.forEach((item, i) => {
                                relatedList.push(item.localid)
                            });
                            alert(`This asset is assigned to another Work Order(s): ${relatedList}`)
                        }
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                }

                if(option[option.length-1]?.asset_type == 'Vehicle' || option[option.length-1]?.asset_type === undefined){
                    this.newTicket.newasset = []
					this.newTicket.newasset.push(option[option.length-1])
                    this.multiAsset = false;
                }else if(option[0]?.asset_type == 'NonAsset'){
                    this.newTicket.newasset = this.newTicket.newasset.filter( asset => asset.asset_type === 'NonAsset');
                    this.multiAsset = false;
                }
                else {
                    this.multiAsset = true;
				}

				this.contractsArray = []
				for(let c in this.newTicket.newasset){
					if(this.newTicket.newasset[c]?.contractid > 0){
						await this.getContractInfo(this.newTicket.newasset[c].contractid)
					}
				}
				this.generateBillToOptions()

				for(let house in this.allHouses){
					if(option.location == this.allHouses[house].name){
						this.locAddress = this.allHouses[house].address;
						this.locName = this.allHouses[house].name;
						break;
					}
				}
				this.$forceUpdate()
			},

			async pickVehicle(option){
				this.getHouses(option.customer);
				var house;
				for(house in this.allHouses){
					if(option.houseid == this.allHouses[house].houseid){
						this.locAddress = this.allHouses[house].address;
						this.locName = this.allHouses[house].name;
						break;
					}
				}
			},

            printPDF() {
                printTicket.printTicket(this.selectedTicket, this.selectedVehicle, this.totalsArray);
            },

            async pushSparseTicket() {
                await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+this.selectedTicket.ticketid + "/" + this.$globals.companyId,{
                    technician: typeof this.selectedTicket.technician === 'object' ? JSON.stringify(this.selectedTicket.technician) : this.selectedTicket.technician,
                    contact: typeof this.selectedTicket.contact === 'object' ? JSON.stringify(this.selectedTicket.contact) : this.selectedTicket.contact,
                    start_date: this.selectedTicket.start_date,
                    end_date: this.selectedTicket.end_date,
                    vehicle: this.selectedTicket.vehicle ?? null,
                    asset: typeof this.selectedTicket.asset === 'object' ? JSON.stringify(this.selectedTicket.asset) : this.selectedTicket.asset,
                    status: this.selectedTicket.status,
                    data: typeof this.selectedTicket.data === 'object' ? JSON.stringify(this.selectedTicket.data) : this.selectedTicket.data,
                    loc_address: this.selectedTicket.loc_address,
                    loc_name: this.selectedTicket.loc_name
                },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
            },//END pushSparseTicket

            async pushTicket(){
                //Fields that can change:
                //Contact, Asset, Vehicle, Location, Techs, Start/End Date, Data
                let status = this.newTicket.newstatus

                //Safety Check
                if(this.newTicket.newcustomer === null) {
                    this.newTicket.newcustomer = this.selectedTicket.customer
                }
                if(this.newTicket.newContact === null){
                    alert("Tickets must have a contact");
                    return;
                }
                if(this.newTicket.newasset === null || this.newTicket.newasset.length === 0){
                    alert("Work Order must have an Asset associated with the customer");
                    return;
                }
                if(this.newTicket.newdata.length < 1){
                    alert("Work Orders must have tasks")
                    return;
                }

                if((this.newTicket.newstart == '' && this.newTicket.newend == '') ||(this.newTicket.newstart == null && this.newTicket.newend == null) || this.newTicket.newstatus == null){
                    this.newTicket.newstatus = 'Unscheduled';
                }
                if(this.newTicket.newstart == null && this.newTicket.newstatus !== "Unscheduled"){
                    alert("Tickets must have a start date or be marked 'Unscheduled'.");
                    return;
                }

                if((this.newTicket.newstart !== null && this.newTicket.newend !== null)){
                    var start = new Date(this.newTicket.newstart);
                    var end = new Date(this.newTicket.newend);
                    if(start > end){
                        alert("Start must be before End");
                        return;
                    }
                    this.newTicket.newstatus = "Scheduled";
                }
                if(this.newTicket.newend == null && this.newTicket.newstart !== null){
                    var monthMilli = 2678400000;
                    var dayMilli = 86400000;
                    var startDate = new Date(this.newTicket.newstart);
                    var startMilli = Date.parse(startDate);
                    var endMilli = startMilli + dayMilli;

                    this.newTicket.newend = new Date(endMilli).toISOString();
                }

                var assetList = []
                this.pendingReq = true

                if(this.newTicket.newasset.length > 1){
                    //is def an assets only
                    for(let i in this.newTicket.newasset){
                        await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${this.newTicket.newasset[i].id}/${this.newTicket.newcustomer.customerid}/${this.$globals.companyId}`,
                            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
                        )
                        .then(response =>{
                            this.newTicket.newasset[i] = (response.data.queryResult[0]);
                            assetList.push(this.newTicket.newasset[i].id)
                        })
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
                    }
                }
                else{
                    //check if just one asset or a vehicle
                    await this.getVehicleInfo(this.newTicket.newasset[0]?.id ?? this.newTicket.newasset[0], this.newTicket.newcustomer.customerid)
                    if((this.selectedVehicle == undefined || this.selectedVehicle === null) && this.newTicket.newasset[0] !== 'Service'){
                        await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${this.newTicket.newasset[0]}/${this.newTicket.newcustomer.customerid}/${this.$globals.companyId}`,
                            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
                        )
                        .then(response =>{
                            this.newTicket.newasset[0] = response.data.queryResult[0] ?? this.newTicket.newasset[0];
                            assetList.push(this.newTicket.newasset[0].id)
                        })
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
                        this.newTicket.newvehicle = null;
                    }
                    else if(this.newTicket.newasset[0] !== 'Service'){
                        //is a vehicle
                        this.newTicket.newvehicle = (this.newTicket.newasset.id ?? this.newTicket.newasset[0]?.id) ?? this.selectedVehicle.vehicleid
                        if (this.newTicket.newasset[0]?.id === null && this.newTicket.newasset[0]?.id === undefined) {
                            this.newTicket.newasset =  []
                            this.newTicket.newasset.push(this.selectedVehicle)
                        }
                        assetList.push(this.newTicket.newvehicle)

                    }
					else{
						this.newTicket.newvehicle = null;
					}
                }

                //Commented out house compare part as it might be getting changed and is not in use.
                /*
                for(let house in this.allHouses){
                    if(type == "Vehicle"){
                        if(this.newTicket.newvehicle?.houseid == this.allHouses[house]?.houseid){
                            if(this.allHouses[house].address != this.locAddress){
                                this.locName = "Custom Address"
                            }
                            else{
                                this.locAddress = this.allHouses[house].address;
                                this.locName = this.allHouses[house].name;
                            }
                            break;
                        }
                    }
                    else{
                        if(this.assetQuery[0]?.location == this.allHouses[house]?.name){
                            if(this.allHouses[house].address != this.locAddress){
                                this.locName = "Custom Address";
                            }
                            else{
                                this.locAddress = this.allHouses[house].address;
                                this.locName = this.allHouses[house].name;
                            }
                            break;
                        }
                    }
                }
                */
                if(status === 'Archived' ? confirm("This will remove the work order from the archive. Do you want to continue?") : true){
                    for(let largeStep in this.newTicket.newdata){

                        if(this.newTicket.newdata[largeStep].billTo === null){
                            this.newTicket.newdata[largeStep].billTo = this.newTicket.newcustomer
                        }
                        if(this.newTicket.newdata[largeStep].billTo.contractid > 0 ){
                            let flag = false;
                            for(let i in this.contractsArray){
                                if(this.contractsArray[i].contractid === this.newTicket.newdata[largeStep].billTo.contractid){
                                    flag = true
                                    break;
                                }
                            }
                            if(flag === false){
                                this.newTicket.newdata[largeStep].billTo = this.newTicket.newcustomer
                                this.newTicket.newdata[largeStep].contractTask = null
                            }
                        }

                        if(this.newTicket.newdata[largeStep].authorization === null){
                            this.newTicket.newdata[largeStep].authorization = this.newTicket.newContact
                        }

                        //IF partsRequested and diff from earlier make request and notify.
                        if(this.newTicket.newdata[largeStep].partsRequested !== "" && this.newTicket.newdata[largeStep].partsRequested !== this.selectedTicket.data[largeStep]?.partsRequested){
                            axios.patch(process.env.VUE_APP_API_TARGET + "/partsreq/update/" + this.$globals.companyId,{
                                ticketid:this.selectedTicket.localid,
                                task: this.newTicket.newdata[largeStep].task,
                                request: this.newTicket.newdata[largeStep].partsRequested,
                                status: 'Open'
                            },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
                                // MOVED TO THE SERVER
                                // this.$globals.socket.emit("notifyRelevantRoles",this.$globals.companyId, ['rol_PS9rC8bKyDbCPQFo', 'rol_2fXaufuOPHYhIRtl'], "Parts Request", `Parts requested for ticket #${this.selectedTicket.localid} on ${this.newTicket.newdata[largeStep].task}`)
                            })
                            .catch((response)=>{
                                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                            })
                        }
                    }

                    //Update Ticket
                    await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+this.selectedTicket.ticketid + "/" + this.$globals.companyId,{
                        technician: JSON.stringify(this.newTicket.newtech),
                        contact: JSON.stringify(this.newTicket.newContact),
                        start_date: this.newTicket.newstart,
                        end_date: this.newTicket.newend,
                        vehicle: this.newTicket.newvehicle,
                        asset: typeof assetList === 'object' ? JSON.stringify(assetList) : assetList,
                        status: this.newTicket.newstatus,
                        data: JSON.stringify(this.newTicket.newdata),
                        loc_address: this.locAddress,
                        loc_name: this.locName
                    },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .catch((response)=>{
                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                        this.cancelEdit(); //Attempt to stop inventory leak.
                    })
                    this.pendingReq = false
                    this.newTicket= {
                        newtech: [],
                        newvehicle: null,
                        newasset: [],
                        newContact: [],
                        newcustomer: null,
                        newstart: null,
                        newend: null,
                        newstatus: null,
                        newdata:[],
                        newid: "",
                    }

                    await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/ticket/${this.selectedTicket.localid}/${this.$globals.companyId}`, {
                        headers: {
                            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                        }
                    }).then(async (response) => {
                        this.selectedTicket = response.data.queryResult[0]
                        this.selectedTicket.contact = typeof this.selectedTicket.contact === 'object' ? this.selectedTicket.contact : JSON.parse(this.selectedTicket.contact)
                        this.selectedTicket.data = typeof this.selectedTicket.data === 'object' ? this.selectedTicket.data : JSON.parse(this.selectedTicket.data)
                        this.selectedTicket.technician = typeof this.selectedTicket.technician === 'object' ? this.selectedTicket.technician : JSON.parse(this.selectedTicket.technician)
                        if(this.selectedTicket.vehicle !== null){
                            await this.getVehicleInfo(this.selectedTicket.vehicle, this.selectedTicket.contact?.customerid)
                        } else {
                            await this.getAssetInfo(JSON.parse(this.selectedTicket.asset), this.selectedTicket?.contact?.customerid)
                        }
                        this.action = ''
                        this.totalHours()
                        this.$forceUpdate()
                        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
                        this.$globals.shield = false;

                        //Clear newTicket
                    })
                    .catch((response)=>{
                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                    })
                }
				else {
                    this.action = ''
                    this.totalHours()
                    return
                }

            },//END pushTicket()

            async removeEvent(row,index){
                if(confirm(`Are you sure you want to delete ${row.item.name}'s event from ${row.item.in} to ${row.item.out}?`)){
                    this.selectedTicket.data[this.lastSelectedIndex].time_in = this.selectedTicket.data[this.lastSelectedIndex].time_in.filter((timeEvent) => {
                        return !(timeEvent.tech === row.item.name && timeEvent.time === row.item.in)
                    })
                    this.selectedTicket.data[this.lastSelectedIndex].time_out = this.selectedTicket.data[this.lastSelectedIndex].time_out.filter((timeEvent) => {
                        return !(timeEvent.tech === row.item.name && timeEvent.time === row.item.out)
                    })
                    await this.pushSparseTicket()
                    this.lastSelectedIndex = index;
                    this.generateHours(this.selectedTicket.data[index], index);
                }
            },//END removeEvent

            async searchCustomers(searchValue){
                if(searchValue === "" || searchValue === null){
                    if(this.prevSearchCustomers === "" || this.prevSearchCustomers === null){
                        return
                    }
                    searchValue = null
                }
                this.customerListIsLoading = true
                this.pageNumberCustomers = 0
                this.prevSearchCustomers = searchValue
                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.allCustomers = response.data.queryResult.results
                        this.maxPagesCustomers = response.data.queryResult.maxPages
                        this.pageNumberCustomers = this.pageNumberCustomers+1
                        this.generateBillToOptions()
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
                this.customerListIsLoading = false
                this.selectedCustomer = this.allCustomers[0]
            },//END searchCustomers

            async searchInv(searchValue){
                if(searchValue === "" || searchValue === null){
                    if(this.prevSearchInv === "" || this.prevSearchInv === null){
                        return
                    }
                    searchValue = null
                }
                this.invListIsLoading = true
                this.pageNumberInv = 0
                this.prevSearchInv = searchValue

                await axios
                .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.allInv = response.data.queryResult.results
                        this.maxPagesInv = response.data.queryResult.maxPages
                        this.pageNumberInv = this.pageNumberInv+1
                        this.createGroups()
                    })
					.catch((response)=>{
	                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	                })
                this.invListIsLoading = false
            },//END searchInv

            async totalHours() {
                this.selectedTicket.data = typeof this.selectedTicket.data === 'object' ? this.selectedTicket.data : JSON.parse(this.selectedTicket.data)
				for(var step in this.selectedTicket.data){
					if(this.selectedTicket.data[step].lock == undefined){
						this.selectedTicket.data[step].lock = []
					}

					this.generateHours(this.selectedTicket.data[step], this.lastSelectedIndex)
					var subTotal = parseInt(0);
					for (let pair in this.hoursArray) {
						if(this.hoursArray[pair].total !== '-') subTotal += parseFloat(this.hoursArray[pair].total)
					}
                    this.totalsArray[step] = subTotal.toFixed(1);
				}
            },

            haversine_distance(coord1, coord2) {
                var R = 3958.8; // Radius of the Earth in miles
                var rlat1 = coord1.latitude * (Math.PI/180); // Convert degrees to radians
                var rlat2 = coord2.latitude * (Math.PI/180); // Convert degrees to radians
                var difflat = rlat2-rlat1; // Radian difference (latitudes)
                var difflon = (coord2.longitude-coord1.longitude) * (Math.PI/180); // Radian difference (longitudes)

                var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
                return d;
            },

            async travelReport() {
                this.selectedTicket.enroute_start = typeof this.selectedTicket.enroute_start === 'object' ? this.selectedTicket.enroute_start : JSON.parse(this.selectedTicket.enroute_start)
                this.selectedTicket.enroute_end = typeof this.selectedTicket.enroute_end === 'object' ? this.selectedTicket.enroute_end : JSON.parse(this.selectedTicket.enroute_end)

                let outs = [...this.selectedTicket.enroute_end.sort((a,b) => Date.parse(a.time) < Date.parse(b.time) ? -1 : 1)]
                let latestClockEvent = "01/01/1970"
                let matchedTimes = []
                let hoursArray = []

                for (let clockIn in this.selectedTicket.enroute_start) {
                    //Conscious choice to substitute name for tech to differentiate mobile data from web data. mobile sends {"tech":"", "time":""} where web is holding name
                    this.selectedTicket.enroute_start[clockIn].location = typeof this.selectedTicket.enroute_start[clockIn].location === 'object' ? this.selectedTicket.enroute_start[clockIn].location : JSON.parse(this.selectedTicket.enroute_start[clockIn].location)
                    hoursArray.push({
                        'name': this.selectedTicket.enroute_start[clockIn].tech,
                        'in': this.selectedTicket.enroute_start[clockIn].time,
                        'out': '-',
                        'total': '-',
                        'startPos': this.selectedTicket.enroute_start[clockIn].location.coords,
                        'endPos': '-',
                        'routeDist': '-',
                        'routeTime': '-'
                    })
                }

                for (let out in outs) {
					let unmatched = true
                    for (let clockPair in hoursArray) {
                        if (hoursArray[clockPair].out === '-' && (Date.parse(outs[out].time) >= Date.parse(hoursArray[clockPair].in)) && (hoursArray[clockPair].name === outs[out].tech) && !matchedTimes.some((element) => element.tech === outs[out].tech && element.out === outs[out].time)) {
                            await new Promise((resolve, reject) => {
                                outs[out].location = typeof outs[out].location === 'object' ? outs[out].location : JSON.parse(outs[out].location)

                                hoursArray[clockPair].out = outs[out].time;
                                hoursArray[clockPair].total = ((Date.parse(outs[out].time) - Date.parse(hoursArray[clockPair].in)) / 1000 / 60 / 60).toFixed(1);
                                latestClockEvent = Date.parse(outs[out].time) > Date.parse(latestClockEvent) ? outs[out].time : latestClockEvent
                                //chose to use out to know this is not a transmitted piece of data, nor being displayed.
                                matchedTimes.push({
                                    "out": hoursArray[clockPair].out,
                                    "tech": hoursArray[clockPair].name,
                                })
                                unmatched = false

                                hoursArray[clockPair].endPos = outs[out].location.coords

                                let directionsService = new window.google.maps.DirectionsService();
                                directionsService.route({
                                    origin: {lat: hoursArray[clockPair].startPos.latitude, lng: hoursArray[clockPair].startPos.longitude },
                                    destination: {lat: hoursArray[clockPair].endPos.latitude, lng: hoursArray[clockPair].endPos.longitude },
                                    travelMode: "DRIVING"
                                }, (res, status) => {
                                    if (status !== 'OK') {
                                        alert("ROUTE FAILED")
                                        reject()
                                    } else {
                                        let directionsData = res.routes[0].legs[0]
                                        // hoursArray.totalTravelled = haversine_distance(hoursArray[clockPair].startPos, hoursArray[clockPair].endPos)
                                        hoursArray[clockPair].routeDist = directionsData.distance.text
                                        hoursArray[clockPair].routeTime = directionsData.duration.text
                                        resolve()
                                    }
                                })
                            })
                            break;
						}
                    }
                    if (unmatched) {
                        outs[out].location = typeof outs[out].location === 'object' ? outs[out].location : JSON.parse(outs[out].location)
						hoursArray.push({
							'name': outs[out].tech,
							'in': '-',
							'out': outs[out].time,
							'total': '-',
                            'startPos': '-',
                            'endPos': outs[out].location.coords,
                            'routeDist': '-',
                            'routeTime': '-'
						})
                    }
                }
                this.travelTimes = hoursArray
                this.action = 'travelReport'
            },

            async updateComment(taskIndex, commentIndex){
                this.editComments[taskIndex][commentIndex] = false
                await this.pushSparseTicket()
                this.$forceUpdate()
            },//END updateComment

			updateSelectedTasks(task){
				if(this.selectedTasks.includes(task)){
					this.selectedTasks = this.selectedTasks.filter((taskInArray) => taskInArray !== task)
				} else {
					this.selectedTasks.push(task)
				}
				this.selectedTasks = this.selectedTasks.sort((a,b) => {
					if(a.task < b.task){
						return -1
					}
					if(a.task > b.task){
						return 1
					}
					return 0
				})
			},


        }
    }
</script>

<style scoped>

.asset-link {
  text-decoration: underline;
}

.asset-link:hover {
  text-decoration: none;
}

.modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
	opacity: 1;
	width: 100vw;
	height: 100vh;
  }

  .modal {
    display: flex;
	box-shadow: 2px 2px 20px 1px;
	background-color: white;
	opacity: 1;
	width: 45vw;
    max-height: 35vh;
	left: 33vw;
	top: 15vh;
	display: block;
	position: fixed;
    border-radius: 20px
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }
</style>

<template>
	<div>
		<div v-if="action === 'viewServiceEstimate'" ref="invoice" class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3 style="text-align: right"><b>Service Estimate</b></h3>
				</div>
				<div v-if="!printing" class="card-tools">
					<b-button v-if="selectedTicket.status <1" style="background-color: #fff4; border-color: #dc3545" @click="edit()">Edit</b-button>
					<b-button v-if="selectedTicket.status <1" style="background-color: #fff4; border-color: #dc3545" @click='printPDF'>Print</b-button>
					<b-button v-if="selectedTicket.status <1" style="background-color: #fff4; border-color: #dc3545" @click='isModalVisible=true'>Reject</b-button>

					<b-button v-if="selectedTicket.status <1" style="background-color: #fff4; border-color: #dc3545" @click="$emit('convert',selectedTicket); $globals.shield = true">Convert to Work Order</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545" class="float-right" @click="$emit('return')">Return</b-button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col">
						<img class="img w-75" :src="selectedCompany.logo" />
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- COMPANY -->
						<div class="row">
							<address>
								<p class="h4">{{ selectedCompany.name }}</p><br>
								{{ selectedCompany.address }}<br>
								P: {{ selectedCompany.phone }}
							</address>
						</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- CUSTOMER -->
						<div class="row">
							<address>
								<p class="h4">{{ selectedTicket.customer }}</p><br>
								{{ selectedCustomer.billing }}<br>
								P: {{ selectedCustomer.phone }}
							</address>
						</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
						<div class="row text-align-center">
							<b-table :fields="[{key: 'date'}, {key: 'invoice'}]" :items="[{'date': '', 'invoice': 'X'}]">
								<template #cell(date)>
									<p>{{ `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}` }}</p>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<p><b>Payment Type:</b><br/> Account/Credit </p>
							</div>
							<div class="col">
								<p><b>Customer ID:</b><br/> {{ selectedCustomer.customerid }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row border text-align-center mb-3">
					<div class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Customer WO</b><br/> {{ selectedTicket.localid }}</p>
					</div>

					<div class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Unit #</b><br/></p>
						<div v-if="assetFlag">
							<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index" >{{ asset.id }}</p>
						</div>
						<div v-else>
							<p style="text-align: center; font-size=10">{{ selectedVehicle.vehicleid }}</p>
						</div>
					</div>

					<!-- Asset headers -->
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Type</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.asset_type }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mfg</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.mfg }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Model</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.model }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Serial #</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.serial }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mfg. Date</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.mfg_date || "N/A" }}</p>
					</div>

					<!-- Vehicle Headers -->
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>VIN</b><br/> {{ selectedVehicle.vin }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Make</b><br/> {{ selectedVehicle.make }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Model</b><br/> {{ selectedVehicle.model }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mileage</b><br/> {{ selectedVehicle.mileage }} </p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Hours</b><br/>{{ selectedVehicle.hours }}</p>
					</div>
				</div>

				<div class="row">
					<b-table show-empty :fields="serviceEstimateInvoiceFields" :items = 'lineItems' class="table-bordered border-3">
						<template #cell(total)="row">
							<div v-if="row.item.sell">
								${{ (row.item.sell * row.item.qty).toFixed(2) }}
							</div>
						</template>
						<template #cell(sell)="row">
							<div v-if="row.item.sell">
								${{ parseFloat(row.item.sell).toFixed(2)}}
							</div>
						</template>
						<template #custom-foot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th><h5><b>Total: </b></h5></th>
								<th>${{ invoiceTotal() }}</th>
							</tr>
						</template>
					</b-table>
				</div>
				<div>
					<p v-html="selectedCompany.invoice_footer ? selectedCompany.invoice_footer : 'This is a placeholder footer for invoice warnings and terms'"></p>
				</div>
			</div>


		</div>

		<div class="modal-backdrop" v-show="isModalVisible">
			<div class = "modal" style="height: 30vh">
				<header class = "modal-header">
					<slot name="header">
						<h4>Rejection Details</h4>
					</slot>
					<b-button type="button" class="close" @click="isModalVisible=false">
						<span>&times;</span>
					</b-button>
				</header>
				<section class="modal-body">
					<slot name="body">
						<div class="row">
							<div class="col-4">
								<b>Reason:</b>
							</div>
							<div class="col-6">
								<textarea rows="3" class="form-control" type="text" v-model="rejectReason" placeholder="Rejection Notes"/>
							</div>
						</div>
					</slot>
				</section>
				<section class="modal-footer">
					<slot name="footer">
						<b-button @click="rejectServiceEstimate()" style="background-color: #dc3545; border-color: #dc3545">Reject</b-button>
					</slot>
				</section>
			</div>

		</div>

		<!-- Edit Service Estimate -->
		<div v-if="action === 'editServiceEstimate'" class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3 style="text-align: right"><b>Service Estimate</b></h3>
				</div>
				<div class="card-tools">
					<b-button style="background-color: #fff4; border-color: #dc3545" @click="updateServiceEstimate">Confirm</b-button>
					<b-button class="float-right" style="background-color: #fff4; border-color: #dc3545" @click="action = 'viewServiceEstimate'; lineItems = editCopy">Return</b-button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col">
						<img class="img w-75" :src="selectedCompany.logo" />
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- COMPANY -->
						<div class="row">
							<address>
								<p class="h4">{{ selectedCompany.name }}</p><br>
								{{ selectedCompany.address }}<br>
								P: {{ selectedCompany.phone }}
							</address>
						</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
					<!-- CUSTOMER -->
						<div class="row">
							<address>
								<p class="h4">{{ selectedTicket.customer }}</p><br>
								{{ selectedCustomer.billing }}<br>
								P: {{ selectedCustomer.phone }}
							</address>
						</div>
					</div>
					<div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
						<div class="row text-align-center">
							<b-table :fields="[{key: 'date'}, {key: 'invoice'}]" :items="[{'date': '', 'invoice': 'X'}]">
								<template #cell(date)>
									<p>{{ `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}` }}</p>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<p><b>Payment Type:</b><br/> Account/Credit </p>
							</div>
							<div class="col">
								<p><b>Customer ID:</b><br/> {{ selectedCustomer.customerid }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="row border text-align-center mb-3">
					<div class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Customer WO</b><br/> {{ selectedTicket.localid }}</p>
					</div>

					<div class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Unit #</b><br/></p>
						<div v-if="assetFlag">
							<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index" >{{ asset.id }}</p>
						</div>
						<div v-else>
							<p style="text-align: center; font-size=10">{{ selectedVehicle.vehicleid }}</p>
						</div>
					</div>

					<!-- Asset headers -->
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Type</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.asset_type }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mfg</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.mfg }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Model</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.model }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Serial #</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.serial }}</p>

					</div>
					<div v-if="assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mfg. Date</b><br/></p>
						<p style="text-align: center; font-size=10" v-for="(asset,index) in selectedAsset" :key="index">{{ asset.mfg_date || "N/A" }}</p>
					</div>

					<!-- Vehicle Headers -->
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>VIN</b><br/> {{ selectedVehicle.vin }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Make</b><br/> {{ selectedVehicle.make }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Model</b><br/> {{ selectedVehicle.model }}</p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Mileage</b><br/> {{ selectedVehicle.mileage }} </p>
					</div>
					<div v-if="!assetFlag" class="col pt-3 border text-align-center">
						<p style="text-align: center"><b>Hours</b><br/>{{ selectedVehicle.hours }}</p>
					</div>
				</div>

				<div class="row">
					<b-table show-empty :fields="serviceEstimateEditFields" :items = 'lineItems' class="table-bordered border-3">
						<template #cell(itemdesc)="row">
							<input v-if="(typeof row.item.itemid !== 'undefined')" type="text" class="form-control" v-model="row.item.itemdesc" />
						</template>
						<template #cell(total)="row">
							<div v-if="row.item.sell">
								${{ (row.item.sell * row.item.qty).toFixed(2) }}
							</div>
						</template>
						<template #cell(sell)="row">
							<div v-if="row.item.sell">
								<input v-if="(typeof row.item.itemid !== 'undefined')" type="float" class="form-control" v-model="row.item.sell" />
							</div>
						</template>
						<template #cell(qty)="row">
							<div v-if="row.item.qty">
								<input v-if="(typeof row.item.itemid !== 'undefined')" type="number" class="form-control" v-model="row.item.qty" />
							</div>
						</template>
						<template #cell(action)="row">
							<div v-if="row.item.itemid">
								<i class="fas fa-trash ml-2 mt-2" @click.stop="removeItem(row.item, row.index)"></i>
							</div>
						</template>
						<template #custom-foot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th><h5><b>Total: </b></h5></th>
								<th>${{ invoiceTotal() }}</th>
							</tr>
						</template>
					</b-table>
				</div>
				<div>
					<textarea v-model="selectedCompany.invoice_footer" class="w-100 m-1"></textarea>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'

var _ = require('lodash');


	export default{
		props: {
			estimate: Object
		},
		components: {

		},
		data(){
			return{
				action: 'viewServiceEstimate',
				assetFlag: false,
				editCopy: null,
				lineItems: [],
				isModalVisible: false,
				printing: false,
				rejectReason: '',
				selectedCompany: {},
				selectedCustomer: {},
				selectedVehicle: {},
				selectedAsset: [],
				selectedTicket: {},
				serviceEstimateInvoiceFields: [
					{key: 'itemid', label: 'Item ID'},
					{key: 'itemdesc', label: 'Description'},
					{key: 'qty', label: 'Qty'},
					{key: 'sell', label: 'Price Each'},
					{key: 'total', label: 'Total'}
				],
				serviceEstimateEditFields: [
					{key: 'itemid', label: 'Item ID'},
					{key: 'itemdesc', label: 'Description'},
					{key: 'qty', label: 'Qty'},
					{key: 'sell', label: 'Price Each'},
					{key: 'total', label: 'Total'},
					{key: 'action', label: ''}
				]


			}
		},
		async mounted(){
			console.log("mounted from viewServiceEstimate: ", this.estimate);
			this.getCompany();
			this.getCustomer();
			await this.viewServiceEstimate(this.estimate);
		},
		methods: {
			edit(){
				this.editCopy = _.cloneDeep(this.lineItems)
				this.action = 'editServiceEstimate'
			},

			async getCompany(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
					this.selectedCompany = response.data.queryResult[0]
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getCustomer(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.estimate.customer}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
					this.selectedCustomer = response.data.queryResult[0]
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			invoiceTotal(){
				var total = 0
				for(let item in this.lineItems){
					if(this.lineItems[item].itemid && this.lineItems[item].highCost && this.lineItems[item].qty){
						total += (parseInt(this.lineItems[item].qty) * (parseFloat(this.lineItems[item].highCost) * (1+(this.lineItems[item].itemMarkup ?? this.lineItems[item].vendorMarkup ?? 0.1))))
					}
				}

				// if(this.selectedCustomer.tax_exempt){
				if(true){
					return total.toFixed(2)
				} else {
					return (parseFloat(this.taxTotal) + total).toFixed(2)
				}

			},

			async printPDF(){
				this.$nextTick( ()=>{
					const doc = new jsPDF('p', 'mm', 'letter');
					const contentHtml = this.$refs.invoice;
					doc.html(contentHtml, {
						html2canvas: {
							scale: 0.13
						},
						callback: function(doc){
							doc.save('test.pdf')
						},
						x:4,
						y:2
					})
				})
				this.$nextTick( ()=>{
					this.printing = false
				})
			},

			async rejectServiceEstimate(){
				await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/serviceEstimate/status/"+ this.selectedTicket.estimateID + '/' + this.$globals.companyId,{
					status: 1,
					statusReason: "Rejected: "+ this.rejectReason,
				},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response=>{
					// this.getServiceEstimates("active");
					//this.changeState('');
					this.$emit("return")
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			removeItem(item, index){
				if(item.itemid.includes('Task ') && item.total === undefined){
					//removes whole task? Loop till hit the {} line?
					let executedArray = []
					for(let i = index; this.lineItems[i]?.itemid !== undefined && i < this.lineItems.length; i++){
						executedArray.push(this.lineItems[i])
					}
					this.lineItems.splice(index, executedArray.length+1)
					for(let i in this.estimate.data){
						if(item.itemid === this.estimate.data[i].task){
							this.estimate.data.splice(i,1)
							break;
						}
					}
				}
				else{
					this.lineItems.splice(index, 1)

				}
			},

			updateServiceEstimate(){
				let holder = []
				let overheadHolder = [] //parts

				let updateItem = [...this.estimate.data]

				for(let q in this.lineItems){
					if(this.lineItems[q].itemid === undefined){
						overheadHolder.push(holder)
						holder=[]
					}
					else if(this.lineItems[q]?.itemid.includes('Task ')){
						updateItem[overheadHolder.length].desc = this.lineItems[q].itemdesc ?? ''
						updateItem[overheadHolder.length].task = `Task ${overheadHolder.length +1}`
						continue
					}
					else{
						holder.push(this.lineItems[q])
					}
				}

				for(let i in overheadHolder){
					updateItem[i].parts = overheadHolder[i]
				}
				updateItem.splice(overheadHolder.length)

				axios.put(`${process.env.VUE_APP_API_TARGET}/tickets/updateServiceEstimate/${this.estimate.estimateID}/${this.$globals.companyId}`,{
					lineItems: JSON.stringify(updateItem)
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.action = "viewServiceEstimate"
			},

			async viewServiceEstimate(row){
				//mix of generatePDF() and viewTicket()
				row.contact = (typeof row.contact === 'object' ? row.contact : JSON.parse(row.contact));
				row.data = (typeof row.data === 'object' ? row.data : JSON.parse(row.data));
				var assets = typeof row.asset === 'object' ? row.asset : JSON.parse(row.asset);

				if(row.vehicle !== null){
					await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${row.vehicle}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
						this.selectedVehicle = response.data.queryResult[0]
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
				else if(assets[0] == "Service"){
					this.assetFlag = false;
				}
				else{
					if(assets !== null && assets !== undefined){
						this.assetFlag = true;
						var tempAsset = [];
						for(let asset in assets){
							await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${assets[asset]}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
								tempAsset[asset] = response.data.queryResult[0];
							})
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewServiceEstimate', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
						}
						this.selectedAsset = tempAsset;
					}
				}

				this.lineItems = []
				for(let line in row.data){
					let newLine = {
						'itemid': row.data[line].task,
						'itemdesc': row.data[line].desc,
						'qty': row.data[line].qty,
						'sell': parseFloat(row.data[line].sell),
					}
					//task line
					this.lineItems.push(newLine)
					for(let part in row.data[line].parts){
						row.data[line].parts[part].qty = row.data[line].parts[part].qty || 1
						//parts line
						this.lineItems.push(row.data[line].parts[part])
					}
					//empty line(s) for spacing
					this.lineItems.push({})
				}
				this.selectedTicket = {...row};
				this.action="viewServiceEstimate";
			},

		}
	}
</script>

<style>
.modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
	opacity: 1;
	width: 100vw;
	height: 100vh;
  }

  .modal {
    display: flex;
	box-shadow: 2px 2px 20px 1px;
	background-color: white;
	opacity: 1;
	width: 45vw;
    height: 45vh;
	left: 33vw;
	top: 15vh;
	display: block;
	position: fixed;
    border-radius: 20px
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }

</style>

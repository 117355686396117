<template>
    <div>
        <div v-if="action === 'viewPDF'" ref="invoice" id="invoice" class="card card-danger">
            <div class="card-header">
                <div class="card-title">
                    <h3 style="text-align: right"><b>Service Invoice</b></h3>
                </div>
                <div v-if="!printing" class="card-tools">
                    <b-button class="button-danger mx-1" style="background-color: #fff4; border-color: #dc3545" @click='sendPDF'>Send</b-button>
                    <b-button class="button-danger mx-1" style="background-color: #fff4; border-color: #dc3545" @click='edit()'>Edit</b-button>
                    <b-button class="button-danger mx-1" style="background-color: #fff4; border-color: #dc3545" @click='printPDF'>Print</b-button>
                    <b-button class="button-danger mx-1" style="background-color: #fff4; border-color: #dc3545" @click="$emit('return')">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col align-items-center">
                        <img class="img w-75" :src="selectedCompany.logo" />
                    </div>
                    <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                    <!-- COMPANY -->
                        <div class="row">
                            <address>
                                <p class="h4">{{ selectedCompany.name }}</p><br>
                                {{ selectedCompany.address }}<br>
                                P: {{ selectedCompany.phone }}
                            </address>
                        </div>
                    </div>
                    <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                    <!-- CUSTOMER -->
                        <div class="row">
                            <address>
                                <p class="h4">{{ selectedCustomer.name }}</p><br>
                                {{ selectedCustomer.billing }}<br>
                                P: {{ selectedCustomer.phone }}
                            </address>
                        </div>
                    </div>
                    <div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
                        <div class="row text-align-center">
                            <b-table :fields="[{key: 'date'}, {key: 'invoice'}]" :items="[{'date': '', 'invoice': this.qbid}]">
                                <template #cell(date)>
                                    <p>{{ `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}` }}</p>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p><b>Payment Type:</b><br/> Account/Credit </p>
                            </div>
                            <div class="col">
                                <p><b>Customer ID:</b><br/> {{ selectedCustomer.customerid }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border text-align-center mb-3" v-if="selectedTicket.vehicle === null && !printing">
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Customer WO</b><br/> {{ selectedTicket.localid }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Unit #</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset" >{{ asset.id }}</p>

                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Type</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.asset_type }}</p>

                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Mfg</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.mfg }}</p>

                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Model</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.model }}</p>

                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Serial #</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.serial }}</p>

                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Mfg. Date</b><br/></p>
                        <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.mfg_date.split('T')[0] }}</p>
                    </div>
                </div>
                <div class="row border text-align-center mb-3" v-if="selectedTicket.vehicle !== null && !printing">
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Customer WO</b><br/> {{ selectedTicket.localid }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Unit #</b><br/> {{ selectedVehicle.vehicleid }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>VIN</b><br/> {{ selectedVehicle.vin }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Make</b><br/> {{ selectedVehicle.make }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Model</b><br/> {{ selectedVehicle.model }}</p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Mileage</b><br/> {{ selectedVehicle.miles }} </p>
                    </div>
                    <div class="col pt-3 border text-align-center">
                        <p style="text-align: center"><b>Hours</b><br/>{{ selectedVehicle.hours }}</p>
                    </div>
                </div>
                <div class="row">
                    <b-table show-empty :fields="invoiceTableFields" :items = 'lineItems' bordered class="table-bordered border-3">
                        <template #cell(tech)="row">
                            <div v-for="tech in row.item.tech" :key="tech.name">
                                {{ tech.name }}
                            </div>
                        </template>
                        <template #cell(qty)="row">
                            <div v-if="row.item.itemid">
                                {{ row.item.amountUsed ? row.item.amountUsed : row.item.qty }}
                            </div>
                        </template>
                        <template #cell(total)="row">
                            <div v-if="row.item.sell">
                                ${{ lineTotal(row) }}
                            </div>
                        </template>
                        <template #cell(sell)="row">
                            <div v-if="row.item.sell">
                                ${{ parseFloat(row.item.sell).toFixed(2)}}
                            </div>
                        </template>
                        <template #custom-foot class="border-0">
                            <!-- TODO: Enable when QBO being used -->
                            <!-- <tr class="border-0" v-if="!selectedCustomer.tax_exempt">
                                <th colspan="4"></th>
                                <th><b>Subtotal: </b></th>
                                <th>${{(invoiceTotal() - taxTotal).toFixed(2)}}</th>
                            </tr>
                            <tr class="border-0" v-if="!selectedCustomer.tax_exempt">
                                <th colspan="4"></th>
                                <th><b>Sales Tax ({{taxRate * 100}}%):</b></th>
                                <th>${{taxTotal}}</th>
                            </tr> -->
                            <tr class="border-0">
                                <th colspan="4"></th>
                                <th><h5><b>Total: </b></h5></th>
                                <th>${{ invoiceTotal() }}</th>
                            </tr>
                        </template>
                    </b-table>
                </div>
                <div>
                    <p style="text-align: center" v-html="selectedCompany.invoice_footer ? selectedCompany.invoice_footer : 'This is a placeholder footer for invoice warnings and terms'"></p>
                </div>
            </div>
        </div>
		<!-- Edit Invoice -->
        <div v-if="action === 'editPDF'">
            <div ref="editInvoice" class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3 style="text-align: right"><b>Service Invoice</b></h3>
                    </div>
                    <div v-if="!printing" class="card-tools">
                        <b-button class="button-danger mx-1" style="background-color: #fff4; border-color: #dc3545" @click="editReturn()">Confirm</b-button>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <img class="img w-75" :src="selectedCompany.logo" />
                        </div>
                        <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                            <!-- COMPANY -->
                            <div class="row">
                                <address>
                                    <p class="h4">{{ selectedCompany.name }}</p><br>
                                    {{ selectedCompany.address }}<br>
                                    P: {{ selectedCompany.phone }}
                                </address>
                            </div>
                        </div>
                        <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
                            <!-- CUSTOMER -->
                            <div class="row">
                                <address>
                                    <p class="h4">{{ selectedTicket.customer }}</p><br>
                                    {{ selectedCustomer.billing }}<br>
                                    P: {{ selectedCustomer.phone }}
                                </address>
                            </div>
                        </div>
                        <div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
                            <div class="row text-align-center">
                                <b-table :fields="[{key: 'date'}, {key: 'invoice'}]" :items="[{'date': '', 'invoice': 1}]">
                                    <template #cell(date)>
                                        <p>{{ `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}` }}</p>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p><b>Payment Type:</b><br/> Account/Credit </p>
                                </div>
                                <div class="col">
                                    <p><b>Customer ID:</b><br/> {{ selectedCustomer.customerid }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
					<!--Nothing shows up for Vehicles??-->
                    <div class="row border text-align-center mb-3" v-if="selectedTicket.vehicle === null">
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Customer WO</b><br/> {{ selectedTicket.localid }}</p>
                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Unit #</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset" >{{ asset.id }}</p>

                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Type</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.asset_type }}</p>

                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Mfg</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.mfg }}</p>

                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Model</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.model }}</p>

                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Serial #</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.serial }}</p>

                        </div>
                        <div class="col pt-3 border text-align-center">
                            <p style="text-align: center"><b>Mfg. Date</b><br/></p>
                            <p style="text-align: center; font-size=10" v-for="asset in selectedAsset" :key="asset">{{ asset.mfg_date.split('T')[0] }}</p>

                        </div>
                    </div>
                    <div class="row">
                        <b-table show-empty :fields="invoiceTableFields" :items = 'lineItems' class="table-bordered border-3">
                            <template #cell(tech)="row">
                                <div v-for="tech in row.item.tech" :key="tech.name">
                                    {{ tech.name }}
                                </div>
                            </template>
                            <!-- <template #cell(itemdesc)="row">
                                <input v-if="(typeof row.item.itemid !== 'undefined')" type="text" class="form-control" v-model="row.item.itemdesc" />
                            </template> -->
                            <template #cell(total)="row">
                                <div v-if="row.item.itemid">
                                    ${{ lineTotal(row) }}
                                </div>
                            </template>

							<template #cell(equation)="row">
								<div v-if="row.item.itemid && !row.item.itemid.includes('Task')">
									<selectSell style="display: inline-block" :item="row.item" :customer="selectedCustomer" :pastFun="row.item.sellRate || null" @evaluatedSell="evaluatedSell(...arguments, row.item)"></selectSell>
								</div>
							</template>

                            <template #cell(sell)="row">
                                <div v-if="row.item.itemid">
                                    <input v-if="(typeof row.item.itemid !== 'undefined')" type="float" class="form-control" v-model="row.item.sell" />
                                </div>
                            </template>
                            <template #cell(qty)="row">
                                <div v-if="row.item.itemid">
                                    <input v-if="(typeof row.item.itemid !== 'undefined')" type="number" min="0" step="0.01" class="form-control" v-model="row.item.amountUsed" @change="() => row.item.qty = row.item.amountUsed"/>
                                </div>
                            </template>
                            <template #custom-foot>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th><h5><b>Total: </b></h5></th>
                                    <th>${{ invoiceTotal() }}</th>
                                </tr>
                            </template>
                        </b-table>
                    </div>
                    <div>
                        <textarea v-model="selectedCompany.invoice_footer" class="w-100 m-1"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import selectSell from '../SellFunctions/selectSellFunction.vue'

    export default {
        props: {
            selectedTasks: Array,
            selectedTicket: Object,
            totalsArray: Array,
        },

        components: {
			selectSell
        },

        data() {
            return {
                action: 'viewPDF',
                invoiceTableFields: [
                    {key: 'tech', label: 'Technician(s)'},
                    {key: 'itemid', label: 'Item ID'},
                    {key: 'itemdesc', label: 'Description'},
                    {key: 'qty', label: 'Qty'},
                    {key: 'sell', label: 'Price Each'},
                    {key: 'total', label: 'Total'}
                ],
                lineItems: [],
                printing: false,
                qbid: '',
                selectedAsset: [],
                selectedCompany: {},
                selectedCustomer: {},
                selectedVehicle: {},
            }
        },

        async mounted() {
			await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			})
			.then(response => {
				this.selectedCompany = response.data.queryResult[0]
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${encodeURIComponent(this.selectedTicket.customer)}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			})
			.then(response => {
				this.selectedCustomer = response.data.queryResult[0]
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

            await this.generatePDF(this.selectedTasks)
			console.log("mount serviceInvoice: ", this.selectedTasks, this.selectedTicket, this.totalsArray);
			await this.edit();
        },

        methods: {

			evaluatedSell(arg, item){
				console.log("evaluatedSell: ", arg, item, arg.sell);
				this.$nextTick(()=>{
					item.sell = arg.sell
					item.sellRate = arg.fun
					this.$forceUpdate()
				})
			},

			edit(){
				this.invoiceTableFields= [
					{key: 'tech', label: 'Technician(s)'},
					{key: 'itemid', label: 'Item ID'},
					{key: 'itemdesc', label: 'Description'},
					{key: 'qty', label: 'Qty'},
					{key: 'equation', label: 'Sell Rate'},
					{key: 'sell', label: 'Price Each'},
					{key: 'total', label: 'Total'}
				]

				this.lineItems.forEach((item, i) => {
					item.amountUsed = item.amountUsed ?? item.qty
				});


				this.action = 'editPDF'
				this.$forceUpdate()
			},

			editReturn(){
				this.invoiceTableFields= [
					{key: 'tech', label: 'Technician(s)'},
					{key: 'itemid', label: 'Item ID'},
					{key: 'itemdesc', label: 'Description'},
					{key: 'qty', label: 'Qty'},
					{key: 'sell', label: 'Price Each'},
					{key: 'total', label: 'Total'}
				]
				this.action = 'viewPDF'
			},

            async generatePDF(tasks) {

                this.selectedAsset = (typeof this.selectedTicket.asset === 'object'? this.selectedTicket.asset : JSON.parse(this.selectedTicket.asset));

				if(this.selectedTicket.vehicle !== null){
					await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${this.selectedTicket.vehicle}/cust/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
						{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
					}).then((response)=>{
                        this.selectedVehicle = response.data.queryResult[0]
                        console.log("SELECTED VEHICLE:", this.selectedVehicle)
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
				else{
					var tempAssetArray = [];
					for(let asset in this.selectedAsset){
						await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${encodeURIComponent(this.selectedAsset[asset])}/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
							{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
						}).then((response) => {
							tempAssetArray[asset] = response.data.queryResult[0];
						})
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
					}
					this.selectedAsset = tempAssetArray;
				}

				this.lineItems = []
                this.selectedTicket.data = (typeof this.selectedTicket.data === 'object' ? this.selectedTicket.data : JSON.parse(this.selectedTicket.data))
                this.selectedTicket.technician = (typeof this.selectedTicket.technician === 'object' ? this.selectedTicket.technician : JSON.parse(this.selectedTicket.technician))
                if(tasks.length > 0){
                    //TODO: Price hours worked
                    for(let line in tasks){
						let laborQ = 0;
						if(this.totalsArray.length !== tasks.length){
							laborQ = this.totalsArray[parseInt(tasks[line].task.split(' ')[1]) -1]
						}
						else {
							laborQ = this.totalsArray[line]
						}
                        let newLine = {
                            'tech': this.selectedTicket.technician,
                            'itemid': tasks[line]?.task,
                            'itemdesc': tasks[line].desc,
                            'qty': laborQ,
                            'sell': 123,
                        }
                        //task line
                        this.lineItems.push(newLine)
                        for(let part in tasks[line].parts){
                            tasks[line].parts[part].qty = tasks[line].parts[part].qty || 1
                            //parts line
                            this.lineItems.push(tasks[line].parts[part])
                        }
                        //empty line(s) for spacing
                        this.lineItems.push({})
                    }
                }
				else {
                    for(let line in this.selectedTicket.data){
                        let newLine = {
                            'tech': this.selectedTicket.technician,
                            'itemid': this.selectedTicket.data[line]?.task,
                            'itemdesc': this.selectedTicket.data[line].desc,
                            'qty': this.totalsArray[line],
                            'sell': 123,
                        }
                        //task line
                        if(!this.selectedTicket.data[line].invoiced && this.selectedTicket.data[line].billTo?.name === this.selectedTicket.customer && this.selectedTicket.data[line].completed){
                            this.lineItems.push(newLine)
                            for(let part in this.selectedTicket.data[line].parts){
                                this.selectedTicket.data[line].parts[part].qty = this.selectedTicket.data[line].parts[part].amountUsed || 1
                                //parts line
                                this.lineItems.push(this.selectedTicket.data[line].parts[part])
                            }
                            //empty line(s) for spacing
                            this.lineItems.push({})
                        }
                    }
                }
                // this.lineItems.push({
                // 	itemid: 'SALES TAX',
                // 	qty: 1,
                // 	itemdesc: `Rate of ${this.taxRate * 100}% applied to all goods herein.`,
                // 	sell: this.taxTotal
                // })
                this.action = 'viewPDF'
            },

            invoiceTotal(){
                var total = 0
                for (let item in this.lineItems) {
                    if(this.lineItems[item].itemid && this.lineItems[item].sell && this.lineItems[item].qty){
                        total += (parseFloat(this.lineItems[item].amountUsed ?? this.lineItems[item].qty) * parseFloat(this.lineItems[item].sell))
                    }
                }

                // if(this.selectedCustomer.tax_exempt){
                if(true){
                    return total.toFixed(2)
                } else {
                    return (parseFloat(this.taxTotal) + total).toFixed(2)
                }

            },

            lineTotal(row) {
                var total = 0
                return (row.item.sell * (row.item.amountUsed ?? row.item.qty)).toFixed(2)
            },

            async markTasksInvoiced(tasks){
                let invoicedTasks = 0;
                let allInvoiced = false;
                if (tasks.length === 0) tasks = this.selectedTicket.data.filter((task) => task.billTo.name === this.selectedTicket.customer);
				tasks.forEach((task) => {
					for(let step in this.selectedTicket.data){
                        if(step.invoiced === 1){
                            invoicedTasks += 1;
                        }
						if(task?.task === this.selectedTicket.data[step]?.task){
							this.selectedTicket.data[step].invoiced = true;
                            invoicedTasks += 1;
                            break;
						}
					}
				})
                if(invoicedTasks === this.selectedTicket.data.length) allInvoiced = true;

                let today = new Date().toISOString().replace('T', ' ');
                if(typeof this.selectedTicket.asset[0] === 'object') this.selectedTicket.asset = this.selectedTicket.asset.map((asset) => asset.id)
                //console.log("markTasksInvoiced: ",this.selectedTicket.vehicle, typeof this.selectedTicket.asset)
				await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+this.selectedTicket.ticketid+ "/" + this.$globals.companyId, {
					technician: JSON.stringify(this.selectedTicket.technician),
					vehicle: this.selectedTicket.vehicle,
					contact: JSON.stringify(this.selectedTicket.contact),
					start_date: this.selectedTicket.start_date,
					end_date: allInvoiced ? today : this.selectedTicket.end_date,
					asset: typeof this.selectedTicket.asset === 'object' ? JSON.stringify(this.selectedTicket.asset) : this.selectedTicket.asset,
					status: allInvoiced ? "Invoiced" : this.selectedTicket.status,
					data: typeof this.selectedTicket.data === 'object' ? JSON.stringify(this.selectedTicket.data) : this.selectedTicket.data,
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.$globals.socket.emit("newData", `service${this.$globals.companyId}`)

                this.$emit('return')
				return
			},

            async printPDF(){
                let js = this
                this.printing = true
                // this.$nextTick( ()=>{
                //     const doc = new jsPDF('p', 'mm', 'letter');
                //     const contentHtml = this.$refs.invoice;
                //     doc.html(contentHtml, {
                //         html2canvas: {
                //             scale: 0.13
                //         },
                //         callback: function(doc){
                //             doc.save(`Invoice-${js.qbid}-WO-${js.selectedTicket.localid}.pdf`)
                //         },
                //         x:4,
                //         y:2
                //     })
                // })
                // this.$nextTick( ()=>{
                //     this.printing = false
                // })
                let invoiceObj = document.getElementById('invoice')
                invoiceObj.style.boxShadow = 'none'
                html2canvas(invoiceObj, {scale: 1}).then(async (canvas) => {
                    var imgData = canvas.toDataURL("image/png");
                    await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                        pic: imgData
                    }, {
                        headers: {
                            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                        }
                    }).then((response) => {
                        window.open(response.data.queryResult, '_blank');
                        this.printing = false;
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'serviceInvoice', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
            },

            async sendPDF() {
                this.printing = true
                const contentHtml = this.$refs.invoice;
                //TODO: pass correct customer qbid
                if (this.$globals.qbo) {
                    let response_invoice = await axios.post(process.env.VUE_APP_API_TARGET + "/qbo/invoice", {
                        "invoice": {
                            "Line": [
                                {
                                    "DetailType": "SalesItemLineDetail",
                                    "Amount": this.invoiceTotal,
                                    "SalesItemLineDetail": {
                                        "ItemRef": {
                                            "name": "Services"
                                        }
                                    }
                                }
                            ],
                            "CustomerRef": {
                                "value": this.selectedCustomer.qbid
                            }
                        },
                        "companyid": this.$globals.companyId


                    }, { headers: { 'Authorization': 'Bearer ' + this.$globals.user.auth0Token } }).then(async (response) => {
                        this.qbid = response.data.queryResult.Id
                        await axios.post(process.env.VUE_APP_API_TARGET + "/qbo/invoiceToDb", {
                            id: response.data.queryResult.Id,
                            companyid: this.$globals.companyId,
                            customer: JSON.stringify(this.selectedCustomer),
                            invoice: JSON.stringify(this.lineItems),
                            total: this.invoiceTotal,
                            type: "service",
                            order: this.selectedTicket.localid,
                            issued: new Date().toISOString().split('T')[0],
                            vehicle: JSON.stringify(this.selectedVehicle)
                        }, { headers: { 'Authorization': 'Bearer ' + this.$globals.user.auth0Token } }).then(async (response) => {
                            let htmlContent = document.getElementById('invoice').innerHTML
                            let invoiceObj = document.getElementById('invoice')
                            invoiceObj.style.boxShadow = 'none'
                            html2canvas(invoiceObj, { scale: 1 }).then(async (canvas) => {
                                var imgData = canvas.toDataURL("image/png");
                                await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                                    pic: imgData
                                }, {
                                    headers: {
                                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                                    }
                                }).then((response) => {
                                    axios.post(
                                        `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                                        {
                                            sgKey: this.subKey,
                                            to: [this.selectedTicket.contact.email, 'jakekowa@fleet4.net'],
                                            // to: 'jakekowa@fleet4.net',
                                            subject: `${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}`,
                                            // title: this.emailTitle,
                                            html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}"/></body></html>`,
                                            text: `Can't see our email? Click the link below to view your invoice!\n\n\n${response.data.queryResult}`
                                        },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                                            }
                                        })
                                        .catch((response) => {
                                            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                                        })
                                })
                                    .catch((response) => {
                                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                                    })
                            })
                            this.markTasksInvoiced(this.selectedTasks);
                        })
                            .catch((response) => {
                                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                            })


                    })
                        .catch((response) => {
                            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                        })
                } else {
                    console.log("To DB")
                    await axios.post(process.env.VUE_APP_API_TARGET + "/qbo/invoiceToDb", {
                        id: null,
                        companyid: this.$globals.companyId,
                        customer: JSON.stringify(this.selectedCustomer),
                        invoice: JSON.stringify(this.lineItems),
                        total: this.invoiceTotal(),
                        type: "service",
                        order: this.selectedTicket.localid,
                        issued: new Date().toISOString().split('T')[0],
                        vehicle: JSON.stringify(this.selectedVehicle)
                    }, { headers: { 'Authorization': 'Bearer ' + this.$globals.user.auth0Token } }).then(async (response) => {
                        let htmlContent = document.getElementById('invoice').innerHTML
                        let invoiceObj = document.getElementById('invoice')
                        invoiceObj.style.boxShadow = 'none'
                        html2canvas(invoiceObj, { scale: 1 }).then(async (canvas) => {
                            var imgData = canvas.toDataURL("image/png");
                            await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                                pic: imgData
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                                }
                            }).then((response) => {
                                axios.post(
                                    `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                                    {
                                        sgKey: this.subKey,
                                        // to: this.selectedTicket.contact.email,
                                        to: 'jakekowa@fleet4.net',
                                        subject: `${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}`,
                                        // title: this.emailTitle,
                                        html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}"/></body></html>`,
                                        text: `Can't see our email? Click the link below to view your invoice!\n\n\n${response.data.queryResult}`
                                    },
                                    {
                                        headers: {
                                            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                                        }
                                    })
                                    .catch((response) => {
                                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                                    })
                                })
                                .catch((response) => {
                                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                                })
                            })
                            this.markTasksInvoiced(this.selectedTasks);
                        })
                    .catch((response) => {
                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error': response, 'component': 'serviceInvoice', 'user': this.$globals.user, 'time': new Date().toLocaleString() }, bubbles: true, composed: true }));
                    })
                }

				this.printing = false


				this.selectedTasks = []
			}, //END sendPDF
        }
    }
</script>

<style scoped>

</style>

<template>
	<div class="container-fluid mt-4" id="app">

		<div v-if="action === 'searching'">
			<!-- Tickets Needing Parts Section -->
			<div class="card card-danger searching">
				<div class="card-header">
					<h3 class="card-title"><b>Tickets Needing Parts</b></h3>
				</div>
				<div class="card-body">
					<form class="searchbar" id="searchFormParts">
						<!-- TODO FIX the search bars, lots has changed and they haven't-->
						<input
							type="text"
							class="form-control-inline"
							v-model="searchReq"
							placeholder="Search Requests"
							style="text-align: center"
						/>
					</form>

					<b-table
						ref="reqTable"
						class="new-styled-table"
						striped
						:current-page="currentPage.partReq"
						:items="openPartsRequests"
						:per-page="5"
						:fields="[
							{ key: 'ticketid', sortable: true, label: 'Work Order #' },
							{ key: 'data', label: 'Requests'}
						]"
						:busy="isBusy"
						@row-clicked="rowReqClicked"
						responsive="lg"
						table-variant="red"
						:filter-included-fields="['prid', 'ticketid', 'date']"
						:filter="search"
					>
						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>

						<template #cell(ticketid)="row">
							<b>WO #{{row.item.ticketid}}</b>
						</template>

						<template #cell(data)="row">
							{{row.item.data.length}}
						</template>

						<template #row-details="row">
							<b-card style="border: black 1px solid; border-radius: 10px">
								<div class="float-right">
									<i class="fas fa-window-close" size="sm" @click.prevent="row.item._showDetails = false"></i>
								</div>
								<b-row v-for="(data, index) in row.item.data" v-bind:key="index" style="text-align: center; text-justify: inter-word">
									<b-col cols="2">
										{{data.task}}
									</b-col>

									<b-col cols="6">
										<b>Request: </b>
										{{data.request}}
									</b-col>

									<b-col cols="2">
										On {{data.date}}
									</b-col>

									<b-col v-if="subaction === 'closeTasks'">
										<input type="checkbox" class="form-control danger" @change="updateSelectedLines(data)"/>
									</b-col>
								</b-row>

								<b-row v-if="subaction === ''" style="margin-top: 3%">
									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="() => {action = 'newShipment'}">Generate Label</b-button>
									</b-col>

									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="requestToPO(row.item)">Create Purchase Order</b-button>
									</b-col>

									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="action = 'viewTicket'; selectedTicket= {localid: row.item.ticketid, contact: null}">View Ticket</b-button>
									</b-col>

									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="subaction = 'closeTasks'">Close Requests</b-button>
									</b-col>
								</b-row>

								<b-row v-if="subaction === 'closeTasks'">
									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="subaction = ''">Stop</b-button>
									</b-col>

									<b-col>
										<b-button style="background: #dc3545; border: #dc3545" @click="closeRequest(row)">Close Request</b-button>
									</b-col>
								</b-row>
							</b-card>
						</template>
					</b-table>
					<b-pagination
						class="customPagination"
						size="sm"
						v-model="currentPage.partReq"
						:per-page="5"
						limit="5"
						:total-rows="openPartsRequests.length"
						aria-controls="reqTable"
						style="margin-left: 45.5%; margin-bottom: 2%"
					>
					</b-pagination>
				</div>
			</div>

			<!-- Component for part returns -->
			<div class="row" v-show="action === 'searching'" style="margin-top: 2.5vh" >
				<div class="col">
					<PartReturns ref="partReturnComponent" @rowClickedEvent="viewReturn"></PartReturns>
				</div>
			</div>

			<div class="row">
				<!-- Low Stock Card -->
				<div class="col-lg-6">
					<div class="card card-danger searching" style="margin-top: 2.5vh">
						<div class="card-header">
							<h3 class="card-title"><b>Parts With Low Stock</b></h3>
						</div>
						<div class="card-danger" style="margin-top: 1vh">
							<b-table
								ref="lowStockTable"
								class="new-styled-table"
								striped
								:current-page="currentPage.lowStock"
								:per-page="5"
								:items="lowStockParts"
								:fields="[
									{ key: 'itemid', sortable: true, label: 'Part #' },
									{ key: 'location', label: 'Site' },
									{ key: 'qty', label: 'On Hand' },
									{ key: 'onOrder', label: 'On Order'}
								]"
								style="width: 94%"
								>

								<template #cell(location)="row">
									<b>{{row.item.location}}</b>-{{row.item.bin.toUpperCase()}}
								</template>

								<template #cell(qty)="row">
									{{row.item.qty}} / {{row.item.threshold}}
								</template>

								<template #cell(onOrder)="row">
									{{row.item.onOrder || 0}}
								</template>
							</b-table>

							<b-pagination
								class="customPagination"
								size="sm"
								v-model="currentPage.lowStock"
								:per-page="5"
								limit="3"
								:total-rows="lowStockParts.length"
								aria-controls="lowStockTable"
								style="margin-left: 42.5%; margin-right: 42.5%; margin-bottom: 2%"
								>
							</b-pagination>
						</div>
					</div>
				</div>

				<!-- Parts by Location -->
				<div class="col-lg-6">
					<div class="card card-danger searching" style="margin-top: 2.5vh">
						<div class="card-header">
							<h3 class="card-title"><b>Parts By Location</b></h3>
						</div>

						<div class="card-body" id="searchBar">
							<multiselect
								class="w-75"
								label="name"
								track-by="name"
								:clearable="true"
								:show-labels="false"
								:options="allLocations"
								v-model="selectedLocation"
								placeholder="Select Location"
								style="display: inline-block"
								@select="getItemsForLocation"
								>
							</multiselect>
							<i
								v-if="locationInv.length == 0 && selectedLocation !== null && (canAccess.parts || canAccess.admin)"
								class="fas fa-trash"
								@click="deleteLocation()"
								>
							</i>
							<multiselect
								class="w-25"
								v-if="locationInv.length > 0"
								v-model="newBin"
								:options="binList"
								style="display: inline-block"
								:show-labels="false"
								:clearable="true"
								placeholder="Bin"
								@input="limitByBin"
							>
							</multiselect>


							<br />
							<b-table
								ref="truckTable"
								class="new-styled-table"
								striped
								:current-page="currentPage.truckTable"
								:per-page="5"
								:items="locationInv"
								:fields="[
									{ key: 'itemid', sortable: true, label: 'Part #' },
									{ key: 'itemdesc', sortable: true, label: 'Desc' },
									{ key: 'bin', sortable: true},
									{ key: 'stock', label: 'Qty'},
								]"
								table-variant="red"
								style="width: 94%"
								@row-clicked="sendToViewItem"
							>
								<template #cell(bin)="row">
									{{row.item.bin.toUpperCase()}}
								</template>
							</b-table>

							<b-pagination
								class="customPagination"
								size="sm"
								v-model="currentPage.truckTable"
								:per-page="5"
								:total-rows="locationInv.length"
								aria-controls="truckTable"
								style="
									margin-left: 42.5%;
									margin-right: 42.5%;
									margin-bottom: 2%;
									"
								>
							</b-pagination>
						</div>
					</div>
				</div>
			</div>

			<!-- Open PO -->
			<div class="row" style="margin-top: 2.5vh">
				<div class="col">
					<div class="openOrders card card-danger searching">
						<div class="card-header">
							<h3 class="card-title"><b>Open Purchase Orders</b></h3>
							<div class="card-tools">
								<b-button style="background: #fff4; border: #dc3545" @click="createPO()">Create Purchase Order</b-button>
							</div>
						</div>

						<div class="card-body">
							<div class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
								<input
									type="text"
									class="form-control"
									placeholder="Search by Related Order number, Purchase Order number or Vendor"
									v-model="search2"
								/>
							</div>
							<b-table
								ref="openPOTable"
								sticky-header
								:filter="search2"
								:filter-included-fields="['localid','vendor', 'orderID']"
								striped
								:busy="isBusy"
								:items="openPOs"
								:fields="[
									{ key: 'localid', label: 'Order Number', sortable: true },
									{ key: 'vendor', label: 'Vendor', sortable: true },
									{ key: 'orderType', label: 'Related Order', sortable: true},
								]"
								@row-clicked="viewPO"
								>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner class="align-middle"></b-spinner>
										<strong>Loading...</strong>
									</div>
								</template>

								<template #cell(vendor)="row">
									{{typeof row.item.vendor != "object" ? JSON.parse(row.item.vendor).company : row.item.vendor.company}}
								</template>

								<template #cell(orderType)="row">
									<div v-if="row.item.orderType !== null">
										{{row.item.orderType}} #{{row.item.orderID}}
									</div>
								</template>

							</b-table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- New Shipment Component -->
		<div v-if="action === 'newShipment'">
			<new-shipment :user="user" :items="selectedLineItems" :source="{companyid: this.$globals.companyId}" @addShippingToOrder="markLinesShipped" @returnFromNewShipment="action = 'searching'; showLineSelect = false;"></new-shipment>
		</div>

		<!-- CREATE PO -->
		<div v-if="action === 'createPO'">
			<newPO :user="user" :companyId="companyId" :vendor="selectedVendor" :orderType="poType" :items="poItems" :orderID="poTicketID" :taskOptions="poTasks"
				@returnFromNewPO="getLowStock(); getOpenPurchaseOrders(); action = 'searching'; showLineSelect = false; poTasks=null ">
			</newPO>
		</div>

		<!-- VIEW PO -->
		<div v-if="action === 'viewPO'">
			<viewPO :user="user" :companyId="companyId" :selectedPO="selectedPO" :selectedCompany="selectedCompany" :userRoles="userRoles"
				@returnFromView="action='searching'; getOpenPurchaseOrders()" @POclosed="action='searching'; getOpenPurchaseOrders()">
			</viewPO>
		</div>

		<!-- Component for viewing specific part return sheet -->
		<div v-if="action === 'ViewReturn'">
			<ViewReturn :allLocations="allLocations" :partReturn="partReturn" @backFromViewReturn="action = 'searching'"></ViewReturn>
		</div>

		<!-- View Ticket -->
		<div v-if="action === 'viewTicket'" ref="ticket" class="pb-3">
			<viewTicket :selectedTicket="selectedTicket" @return="returnFromViewTicket()"></viewTicket>
		</div>

		<!-- View Item Details Section -->
		<div v-if="action === 'viewItemDetails'">
			<viewItem :selectedItem="selectedItem" @return="action = 'searching'; selectedItem=''"></viewItem>
		</div>

	</div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import * as https from "https";
import { BPagination } from "bootstrap-vue";
import VueBarcode from "vue-barcode";
import Multiselect from "vue-multiselect";
import printJS from "print-js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Socket } from "net";
import newShipment from './Shipping/newShipment.vue';
import newPO from "./Sales/newPurchaseOrder.vue"
import viewPO from "./Sales/viewPurchaseOrder.vue"
import PartReturns from './Parts/partReturn.vue';
import ViewReturn from './Parts/viewReturn.vue';
import { debounce } from 'lodash';
import viewTicket from './Service/viewTicket.vue'
import viewItem from './Inventory/viewItem.vue'



export default {
  name: "app",
  props: {
    socket: Socket,
    user: Object,
    companyId: Number,
	userRoles: Array,
	canAccess: Object,
  },
  components: {
    barcode: VueBarcode,
    Multiselect,
    BPagination,
    newShipment,
    PartReturns,
    ViewReturn,
    newPO,
    viewPO,
	viewTicket,
	viewItem
  },
  directives: {
    print,
  },
  data() {
    return {
      action: "searching",
      allLocations: [],
	  binList: [],
      currentPage: {
        lowStock: 1,
        partReq: 1,
        parts: 1,
        truckTable: 1,
      },
	  fields: [
		  {
			key: "itemid",
			sortable: true,
			label: "Item ID",
		  },
		  {
			key: "itemdesc",
			sortable: true,
			label: "Description",
		  },
		  {
			key: "type",
			sortable: true,
		  },
		  {
			key: "threshold",
			sortable: true,
			label: "Minimum Stock",
		  },
		  {
			key: "delete",
			label: "",
		  },
	  ],
      isBusy: false,
      locationInv: [],
      lowSearch: "",
      lowStockParts: [],
	  newBin: '',
	  openPOs : [],
	  openPartsRequests: [],
	  poType :"",
	  poItems : [],
	  poTicketID : null,
	  poTasks: null,
	  partReturn: [],
	  safeLocInv: [],
      search: "",
      search2: "",
      searchReq: "",
      selectedCompany: {},
      selectedLineItems: [],
      selectedPO: {},
      selectedRequest: {},
      selectedLocation: null,
      selectedVendor: "",
	  selectedLines: [],
      showLineSelect: false,
	  selectedTicket: '',
	  selectedItem: '',
      subaction: '',
    };
  },
  created() {
    this.$globals.socket.emit("joinRoom", "parts"+this.$globals.companyId);
    this.$globals.socket.on("connectToRoom", (data) => {
      //console.log(data);
    });
    this.$globals.socket.on("newPartsData", async (message, data) => {
        console.log("trying to refresh prs")
        await this.getOpenPartsRequests()
        if (this.$refs.reqTable !== undefined) {
            this.$refs.reqTable.refresh();
        }
        await this.getAllLocations()
        this.$forceUpdate
        console.log(this.openPartsRequests[this.openPartsRequests.length -1 ].request)
    });
    //this.debounceReturns = debounce(this.searchReturns, 600);
  },
  async mounted() {
    this.isBusy = true;
	  axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`, {
		  headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		})
		.then((response) => {
		  this.selectedCompany = response.data.queryResult[0];
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})


    //get all parts requests
    this.getOpenPartsRequests()
    //get all locations
    await this.getAllLocations()

    //get all items that have low stock values
    this.getLowStock();
    //get all trucks
    // TODO: Find out what this function is
    // this.getAllTrucks();
    //get all open purchase orders
    this.getOpenPurchaseOrders();
    this.isBusy = false;
  },
  destroyed() {
        this.$globals.socket.emit("leaveRoom", `parts${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed parts")
    },

  methods: {
	createPO() {
	  this.selectedVendor = null;
	  this.poType = null;
	  this.poItems = [];
	  this.selectedLineItems = [];
	  this.poTicketID = null;
	  this.action = "createPO";
	},

	closeRequest(x){
		this.selectedLines.forEach((item, i) => {
			axios.patch(`${process.env.VUE_APP_API_TARGET}/partsreq/update/${this.$globals.companyId}`,{
				ticketid:item.ticketid,
				task: item.task,
				request: item.request,
				status: 'Closed'
			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				//TODO: notify users attached to WO Handling on Server
				//TODO clear ticket Part Request? Should be handled on backend | Potentially done 7/18/22
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		});
		this.subaction=''
		this.selectedLines = []

		this.getOpenPartsRequests()
	},

	async deleteLocation(){
		await axios.delete(`${process.env.VUE_APP_API_TARGET}/locations/${encodeURIComponent(this.selectedLocation.name)}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		await this.getAllLocations()
		this.selectedLocation = null;
		this.locationInv = []
		this.$forceUpdate()
	},

	async getAllLocations(){
		await axios
		.get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then((response) => {
			this.allLocations = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	async getItemsForLocation(location) {
		location = encodeURIComponent(location.name);
		await axios
        .get(`${process.env.VUE_APP_API_TARGET}/locations/trucks/${location}/${this.$globals.companyId}`,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
			this.locationInv = response.data.queryResult;
			this.locationInv.forEach((item, i) => {
				item.stock = 0
				item.itemcount = typeof item.itemcount === 'object' ? item.itemcount : JSON.parse(item.itemcount)
				for(let n in item.itemcount){
					item.stock = item.stock + parseInt(item.itemcount[n].qty)
				}
			});
			this.safeLocInv = [...this.locationInv]
			this.getDistinctBins(location)
        })
	    .catch((response)=>{
	      this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	    })
    },

	getDistinctBins(location){
		axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${location}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		).then((response)=>{
			this.newBin = ""
			this.binList = []
			this.binList = response.data.queryResult.sort((a, b) => {
                return a < b ? -1 : 1
            })
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	async getLowStock() {
        await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/lowStock/${this.$globals.companyId}`, {
            headers: {
                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
            }
        }).then((response) => {
			console.log("lowStock: ", response.data.queryResult);
            this.lowStockParts = response.data.queryResult ?? []
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

	async getOpenPurchaseOrders(){
		this.selectedLineItems = [];
		this.selectedVendor = null;
		this.poType = null
		this.poItems = []
		this.poTicketID = null;

		await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/po/open/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.openPOs = response.data.queryResult;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

	getOpenPartsRequests(){
		axios.get(`${process.env.VUE_APP_API_TARGET}/partsreq/open/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then((response) => {
			this.openPartsRequests = response.data.queryResult
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

	limitByBin(bin){
		if(bin === "" || bin === undefined || bin === null){
			this.locationInv = [...this.safeLocInv]
		}
		else{
			this.locationInv = this.safeLocInv.filter(obj => obj.bin === bin )
		}
	},

    async markLinesShipped(){
        this.selectedRequest.parts.forEach((part) => {
            this.selectedLineItems.forEach((line) => {
                if(part === line){
                    part.shipped = true
                }
            })
        })
    },

	rowReqClicked(record, index) {
		this.lastSelectedIndex = index;
		this.selectedRequest = record;
		this.toggleDetails(record);
	},

	requestToPO(row){
		this.poType = 'WO'
		this.poTicketID = row.ticketid
		this.poTasks = row.data
		this.action = 'createPO'
	},

	returnFromViewTicket(){
		this.action = 'searching'
		this.selectedTicket = ''
	},

	sendToViewItem(data){
		this.selectedItem = data;
		this.action = 'viewItemDetails'
	},

    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, "_showDetails", false);
      } else {
        this.$set(row, "_showDetails", true);
      }
    },

	updateSelectedLines(line){
		let flag = -1
		for(let i in this.selectedLines){
			if(this.selectedLines[i].ticketid !== line.ticketid){
				this.selectedLines = []
				break;
			}
			else if(this.selectedLines[i].prid === line.prid){
				this.selectedLines.splice(i,1)
				flag = i
				break;
			}
		}
		if(flag === -1 ){
			this.selectedLines.push(line)
		}
	},

	viewReturn(event){
	  this.partReturn = event
	  console.log("event: ",event);
	  this.action = "ViewReturn"
	},

	viewPO(record){
		this.selectedPO = record
		this.selectedPO.vendor = typeof this.selectedPO.vendor === "object" ? this.selectedPO.vendor : JSON.parse(this.selectedPO.vendor)
		this.selectedPO.data = typeof this.selectedPO.data === "object" ? this.selectedPO.data : JSON.parse(this.selectedPO.data)
		this.action = "viewPO"
	},

  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
.dark {
  background-color: #dc4535 !important;
  border-color: #dc4535 !important
}
.stand {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important
}
.actbutton {
  margin: 15px;
  padding: 10px;
  background: #dc3545;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
}
barcode {
  text-align: center;
  margin-top: 15%;
}
/deep/ .customPagination {
  margin: auto;
  margin-bottom: 1%;
}
/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
/deep/ .customPagination .page-item .page-link {
  color: #dc3545 !important;
}


/* Style the search field */
div.searching{
	text-align: center;
	margin: auto;
}
.fas:hover,
.far:hover {
	color: #dc3545;
}
.fillbutton {
	background-color: #dc3545 !important;
	border-color: black !important;
	color: white !important;
}
form.adding button {
	margin: 15px;
	padding: 10px;
	background: #dc3545;
	color: white;
	font-size: 17px;
	border: 1px solid grey;
}
/* Style the submit button */
form.searchbar button {
  width: 5%;
  padding: 10px;
  background: #dc3545;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}
form.searchbar button:hover {
  background: #0b7dda;
}

form.searchbarparts button:hover {
  background: #dc3545;
}

img.item {
  border-radius: 8px;
  height: 200px;
  width: 200px;
  margin-top: 10%;
}
.new-styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 80vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  align: center;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
}
.multiselect__option--highlight {
  background: #dc3545 !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
    <div class="userProfileView">
        <!-- View Section -->
        <div v-if="action === ''">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>User Info</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;" @click="editUser()">Edit Data</b-button>
                        <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;" @click="resetPassword()">Reset Password</b-button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col" style="margin-left:10%; margin-top: 2.5%">
                            <div class="figure">
                                <img :src="selectedUser.picture" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'" class="img-circle elevation-3 w-50" style="width: 5vw; margin-bottom: 1%; border: black solid 1px" alt="User Image" />
                                <div class="figure-caption">
                                    {{selectedUser.user_id || selectedUser.sub}}
                                </div>
                            </div>
                        </div>
                        <div class="col" style="margin-left:-15%; margin-top: 2.5%">
                            <div class="row m-4">
                                <div class="col"><b>Name: </b> {{selectedUser.name}}</div>
                            </div>
                            <div class="row m-4">
                                <div class="col"><b>Email: </b> {{selectedUser.email}}</div>
                                <div class="col"><b>Verified: </b> {{selectedUser.email_verified}}</div>
                            </div>
                            <div class="row m-4">
                                <div class="col"><b>User Roles: </b><br/>  <span v-for="(role,index) in userRoles" :key="index">{{role.name}} <br/></span></div>
                                <div class="col"><b>Base Location:</b> {{baseLocation}}</div>
                            </div>
                            <div v-if="hourlycheck === true" class="row m-4">
                                <b-button :class="{dark: theme, stand: !theme}" style="width:100%; height:50px; margin-left:2.5%" @click="viewTimeSheet()"><b>Time Sheet</b></b-button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card card-danger">
                        <div class="card-header">
                            <div class="card-title">
                                <h3><b>Notification Settings</b></h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <b-form-group>
                                <b-form-checkbox-group @change="optOut()" v-model="notificationsOn" :options="roleArray" selected switches stacked></b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card card-danger">
                        <div class="card-header">
                            <div class="card-title">
                                <h3><b>Display Settings</b></h3>
                            </div>
                        </div>
                        <div class="card-body d-flex">
                            <div class="col">
                                <b-form-group>
                                    <div class="row">
                                        <div style="margin-left: auto; margin-right: 3%; font-size: large">Standard</div>
                                        <b-form-checkbox @change="setPreferences()" size="lg" v-model="theme" switch ></b-form-checkbox>
                                        <div style="font-size: large; margin-right: auto">Dark Mode</div>
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card card-danger">
                        <div class="card-header">
                            <div class="card-title">
                                <h3><b>Language Settings</b></h3>
                            </div>
                        </div>
                        <div class="card-body scrollproperties" style="overflow-y: scroll; height: 350px">
                            <Translator style="background-color: white" :countries="languages"/>
                        </div>
                    </div>
                </div>
            </div>



        </div>

        <!-- Edit Section -->

        <div v-if="action == 'edit'">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>Edit User</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;" @click="updateUser()">Update</b-button>
                        <b-button  style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;" @click="action='';clearData()">Cancel</b-button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row m-4">
                        <div class="col"><b>Name: </b> <input type="text" class="form-control" v-model="selectedUser.name"/></div>
                        <div class="col"><b>Email: </b> <input class="form-control" type="text" v-model="selectedUser.email" /> </div>

                    </div>
                    <div class="row">
                        <div class="col">
                            <img :src="previewImage" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'" class="uploading-image" style="height: 100px; width: 100px; border-radius: 50px; border: black solid 1px" alt="Preview"/>
                        </div>
                        <div class="col">
                            <input class="form-control w-75" type="file" accept="image/*" @change="uploadImage($event)" id="file-input">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- View User TimeSheet -->
        <div v-if="action === 'viewTimeSheet'">
            <div class="card card-danger">
                <div class="card-header">
                <div class="card-title">
                    <h3><b>{{userTimeSheet.name}}</b></h3>
                </div>
                <div class="card-tools">
                    <b-button
                        class="mb-4"
                        @click="action=''"
                        style="background: #fff4; border: #dc3545"
                        >
                        Return
                </b-button>
                </div>
                </div>
                <div class="card-body">
                    <div v-for="(year, yearIndex) in timeSheet" :key="yearIndex">
                        <h2 :class="{darktext: theme, standardtext: !theme}" class="text-center" style="font-weight: bold">{{year.year}}</h2>
                        <div class="card card-outline-danger" v-for="(week,weekIndex) in year.weeks" :key="weekIndex">
                            <div class="card-header" @click="showClockSet(week,weekIndex); calculateTotalHours(week, weekIndex)">
                                <div class="card-title align-content-center align-items-center">
                                    <h4 class="text-center"><b>{{ getDateOfISOWeek(week.weekNumber-1, year.year) }}</b></h4>
                                </div>
                                <div class="card-tools">
                                    <b>{{ totalHours[weekIndex] ? totalHours[weekIndex] : 0 }}</b>
                                </div>
                            </div>
                            <div class="card-body" v-show="showClocks[weekIndex]">
                                <div v-for="(day,dayIndex) in week.days" :key="dayIndex">
                                    <h5 class="text-center"><b>{{day.day}}</b></h5>
                                    <hr class="w-75 m-auto pb-3" />
                                    <div class="row">
                                        <b class="text-center" style="margin-left: 46%">Total Hours: {{ totalDayHours[dayIndex] ? totalDayHours[dayIndex] : 0 }}</b>
                                    </div>
                                    <div class="row">
                                        <div class="col">

                                        </div>
                                        <div class="col-5">
                                            <h6 class="text-center"><b>Clock In</b></h6>
                                        </div>
                                        <div class="col-5">
                                            <h6 class="text-center"><b>Clock Out</b></h6>
                                        </div>
                                        <div class="col">

                                        </div>
                                    </div>
                                    <div class="row text-center" v-for="(clock,index) in day.clock_set" :key="index">
                                        <div class="col">

                                        </div>
                                        <div class="col-5">
                                            <!-- <div v-if="!clockEdit[index]">{{ clock.clock_in !== null && clock.clock_in !== undefined ? `${clock.clock_in.split('T')[0]} ${clock.clock_in.split('T')[1].split('.')[0]}` : "-" }}</div> -->
                                            <div v-if="!clockEdit[index]">{{ clock.clock_in !== null && clock.clock_in !== undefined ? `${clock.clock_in}` : "-" }}</div>
                                            <input v-else class="form-control text-center" v-model="clock.clock_in" placeholder="Clock In" />
                                        </div>
                                        <div class="col-5">
                                            <!-- <div v-if="!clockEdit[index]">{{ clock.clock_out !== null && clock.clock_out !== undefined ? `${clock.clock_out.split('T')[0]} ${clock.clock_out.split('T')[1].split('.')[0]}` : "-" }}</div> -->
                                            <div v-if="!clockEdit[index]">{{ clock.clock_out !== null && clock.clock_out !== undefined ? `${clock.clock_out}` : "-" }}</div>
                                            <input v-else class="form-control text-center" v-model="clock.clock_out" placeholder="Clock Out" />
                                        </div>
                                        <div class="col">
                                            <i v-if="canAccess.admin && !clockEdit[index]" class="fas fa-edit" size="sm" @click.stop="clockEdit[index] = true; $forceUpdate()"></i>
                                            <i v-if="canAccess.admin && !clockEdit[index]" class="fas fa-trash" size="sm"></i>
                                            <b-button v-else style="background: #dc3545; border-color: #dc3545" @click.stop="updateClockEvent(day.clock_set, yearIndex, weekIndex, dayIndex); clockEdit[index] = false; $forceUpdate()">Confirm</b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>



</template>

<script>
/*eslint-disable*/
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { Socket } from 'socket.io-client'
import { Translator } from 'vue-google-translate'

export default {
    name: 'app',
    props: {
        socket: Socket,
        companyId: Number,
        user: Object,
        userRoles: Array,
        canAccess: Object,
    },
    components: {
        Multiselect,
        Translator
    },
    data (){
        return {
            action: '',
            roleArray: [],
            hourlycheck: false,
            isBusy: false,
            allLocations: [],
            userBase: '',
            selectedUser: {},
            previewImage: '',
            language: '',
			languages:
	            [
	                {
	                code: 'en|af',
	                title: 'Afrikaans',
	                },
	                {
	                code: 'en|sq',
	                title: 'Albanian',
	                },
	                {
	                code: 'en|ar',
	                title: 'Arabic',
	                },
	                {
	                code: 'en|hy',
	                title: 'Armenian',
	                },
	                {
	                code: 'en|az',
	                title: 'Azerbaijani',
	                },
	                {
	                code: 'en|eu',
	                title: 'Basque',
	                },
	                {
	                code: 'en|be',
	                title: 'Belarusian',
	                },
	                {
	                code: 'en|bg',
	                title: 'Bulgarian',
	                },
	                {
	                code: 'en|ca',
	                title: 'Catalan',
	                },
	                {
	                code: 'en|zh-CN',
	                title: 'Chinese (Simplified)',
	                },
	                {
	                code: 'en|zh-TW',
	                title: 'Chinese (Traditional)',
	                },
	                {
	                code: 'en|hr',
	                title: 'Croatian',
	                },
	                {
	                code: 'en|cs',
	                title: 'Czech',
	                },

	                {
	                code: 'en|da',
	                title: 'Danish',
	                },
	                {
	                code: 'en|nl',
	                title: 'Dutch',
	                },
					{
						code: 'en|en',
						title: 'English'
					},
	                {
	                code: 'en|et',
	                title: 'Estonian',
	                },
	                {
	                code: 'en|tl',
	                title: 'Filipino',
	                },
	                {
	                code: 'en|fi',
	                title: 'Finnish',
	                },
	                {
	                code: 'en|fr',
	                title: 'French',
	                },

	                {
	                code: 'en|de',
	                title: 'German',
	                },
	                {
	                code: 'en|el',
	                title: 'Greek',
	                },
	                {
	                code: 'en|hu',
	                title: 'Hungarian',
	                },
	                {
	                code: 'en|id',
	                title: 'Indonesian',
	                },
	                {
	                code: 'en|ga',
	                title: 'Irish',
	                },
	                {
	                code: 'en|it',
	                title: 'Italian',
	                },
	                {
	                code: 'en|ja',
	                title: 'Japanese',
	                },
	                {
	                code: 'en|ko',
	                title: 'Korean',
	                },
	                {
	                code: 'en|lt',
	                title: 'Lithuanian',
	                },
	                {
	                code: 'en|ms',
	                title: 'Malay',
	                },
	                {
	                code: 'en|no',
	                title: 'Norwegian',
	                },
	                {
	                code: 'en|pl',
	                title: 'Polish',
	                },
	                {
	                code: 'en|pt',
	                title: 'Portuguese',
	                },
	                {
	                code: 'en|ro',
	                title: 'Romanian',
	                },
	                {
	                code: 'en|ru',
	                title: 'Russian',
	                },
	                {
	                code: 'en|es',
	                title: 'Spanish',
	                },
	                {
	                code: 'en|sv',
	                title: 'Swedish',
	                },
	                {
	                code: 'en|th',
	                title: 'Thai',
	                },
	                {
	                code: 'en|tr',
	                title: 'Turkish',
	                },
	                {
	                code: 'en|uk',
	                title: 'Ukrainian',
	                },
	            ],
            notifications: '',
            notificationsOn: [],
            optOutArray: [],
            tempArray: [],
            display: '',
            baseLocation:'',
            currentlanguage: {},
            displayOptions: [
                "Standard",
                "Dark Mode"
            ],
            update: {
                language: "English",
                notifications: [],
                display: "Standard",
                baseLocation: ''
            },
            selected: [],
            subaction: '',
            theme: Boolean,
            userTimeSheet: [],
            timeSheet: [],
            clockEdit: [],
            clockInHours: Number,
            clockInMinutes: Number,
            clockInMidday: "",
            clockOutHours: Number,
            clockOutMinutes: Number,
            clockOutMidday: "",
            indexToEdit: Number,
            currentItems: [],
            middayOptions: ["AM", "PM"],
            showClocks: [],
            totalHours: [],
            totalDayHours: [],
        }
    },//END data
    created(){

    },
    async mounted (){
        this.isBusy = true
        this.selectedUser = this.$globals.user;
        this.roleArray = this.userRoles.map(role => role.name)
        this.notificationsOn = this.roleArray.map((role) => role)

        for(let hour in this.roleArray){
            if(this.roleArray[hour] === "Hourly Employee"){
                this.hourlycheck = true
                break
            }
        }

        await axios.get(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.$globals.user.sub}/${this.$globals.companyId}`,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then(response => {
            this.optOutArray = typeof response.data.queryResult[0].notifications !== 'object' ? JSON.parse(response.data.queryResult[0].notifications) : response.data.queryResult[0].notifications
			if(this.optOutArray === null){
				this.optOutArray = [];
			}
            for(var off = 0; off < this.optOutArray.length; off++){
                for(var i = 0; i < this.notificationsOn.length; i++){
                    if(this.optOutArray[off] === this.notificationsOn[i]){
                        this.notificationsOn.splice(i, 1);
                        i--;
                    }
                }
            }
            for(let i in this.languages){
                if(this.languages[i].title === response.data.queryResult[0].language){
                this.currentlanguage = this.languages[i];
                break;
                }
            }
            this.display = response.data.queryResult[0].display;
            if(this.display === "Standard"){
                this.theme = false;
            }
            else{
                this.theme = true;
            }
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

        this.isBusy = false
    },

    computed: {

    },

    methods: {

        calculateTotalHours(time_sheet, weekIndex){
            let total = 0
            if(time_sheet.days === undefined)
                return

            for(var item in time_sheet.days){
                for (var i in time_sheet.days[item].clock_set) {
                    let diff = 0
                    if (time_sheet.days[item].clock_set[i].clock_out && time_sheet.days[item].clock_set[i].clock_in) {
                        diff = (new Date(time_sheet.days[item].clock_set[i].clock_out) - new Date(time_sheet.days[item].clock_set[i].clock_in))
                    }
                    total = total + diff
                }
                this.calculateDayHours(time_sheet.days[item], item)
            }
            total = (total / (1000 * 60 * 60)).toFixed(1)
            this.totalHours[weekIndex] = total
            this.$forceUpdate()
            console.log(this.totalHours)
        },

        calculateDayHours(time_sheet, dayIndex){
            let total = 0
            console.log("time sheet", time_sheet, dayIndex)
            if(time_sheet === undefined)
                return

            for (var i in time_sheet.clock_set) {
                let diff = 0
                if (time_sheet.clock_set[i].clock_out && time_sheet.clock_set[i].clock_in) {
                    diff = (new Date(time_sheet.clock_set[i].clock_out) - new Date(time_sheet.clock_set[i].clock_in))
                }
                total = total + diff
            }
            total = (total / (1000 * 60 * 60)).toFixed(1)
            this.totalDayHours[dayIndex] = total
            console.log(this.totalDayHours)
        },

        clearData(){
            this.update = {};
            this.selected = [];
        },

        editUser(){
            this.previewImage = this.selectedUser.picture;
            this.update= {
                language: this.language || "English",
                notifications: [],
                display: this.display ||"Standard",
                baseLocation: this.baseLocation
            };

            for(let section in this.canAccess){
                this.update.notifications.push({
                    text:section,
                    value:section,
                    disabled: !(this.canAccess[section])
                })
                this.selected.push(section)
            }
            if(this.notifications){
                this.selected = this.notifications
            }
            this.action = 'edit';


        },//END editUser()

        getDateOfISOWeek(w, y) {
            var simple = new Date(y, 0, 1 + (w - 1) * 7);
            var dow = simple.getDay();
            var ISOweekStart = simple;
            if (dow <= 4)
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            else
                ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            return ISOweekStart.toDateString();
        },

        optOut(){
            var contains = false

            this.roleArray.forEach((role) => {
                this.notificationsOn.forEach((on) => {
                    if(role === on){
                        contains = true
                    }
                })
                if(contains === false){
                    this.tempArray.push(role)
                }
                contains = false;
            })

            this.optOutArray = this.tempArray.map(entry => entry)

            axios.patch(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.$globals.user.sub}/${this.$globals.companyId}`, {
                display: this.display,
                notifications: JSON.stringify(this.optOutArray),
				language: this.currentlanguage.title
            },
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
            this.tempArray = []
        },

        async resetPassword(){
            await axios.post(process.env.VUE_APP_API_TARGET + "/users/"+this.selectedUser.email+"/changepassword/"+ this.$globals.companyId,{

			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(response => {
                alert("An email has been sent to: ", this.selectedUser.email)
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },//END resetPassword()

        setPreferences() {
            if(this.theme === false){
                this.display = "Standard"
                document.documentElement.className = "standard"
            }
            else{
                this.display = "Dark Mode"
                document.documentElement.className = "dark-mode";
            }
            axios.patch(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.$globals.user.sub}/${this.$globals.companyId}`, {
                display: this.display,
                notifications: JSON.stringify(this.optOutArray),
                language: this.currentlanguage.title
            },
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

            this.$forceUpdate()
            this.$emit('refreshMain')
        },

        showClockSet(week,index){
            if(this.showClocks[index]){
                this.showClocks[index] = !this.showClocks[index]
            } else {
                this.showClocks[index] = true
            }
            this.$forceUpdate()
        },

        async updateClockEvent(clock_set, yearIndex, weekIndex, dayIndex){
            this.userTimeSheet.time_sheet = typeof this.userTimeSheet.time_sheet === 'object' ? this.userTimeSheet.time_sheet : JSON.parse(this.userTimeSheet.time_sheet)
            this.userTimeSheet.time_sheet[yearIndex].weeks[weekIndex].days[dayIndex].clock_set = clock_set
            this.userTimeSheet.time_sheet = typeof this.userTimeSheet.time_sheet === 'object' ?  JSON.stringify(this.userTimeSheet.time_sheet) : this.userTimeSheet.time_sheet

            await axios.patch(`${process.env.VUE_APP_API_TARGET}/users/hourly/${this.selectedUser.user_id || this.selectedUser.sub}/${this.companyId}`,{
                time_sheet: this.userTimeSheet.time_sheet
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response)=> {
                console.log(response.data)
                this.calculateDayHours();
                //this.action = ''
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async updateUser(){
            if(this.previewImage == null || this.previewImage == ''){
                this.previewImage = this.selectedUser.picture;
            }

            await axios.patch(process.env.VUE_APP_API_TARGET + "/users/"+this.$globals.user.sub + "/profile/" + this.$globals.companyId,{
                name: this.selectedUser.name,
                email: this.selectedUser.email,
                picture: this.previewImage,
                baseLocation: this.update.baseLocation
            },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(response =>{
				//TODO maybe try an if(roles includes tech) to make sure it doesn't needless call?
                this.selectedUser.picture = response.data.queryResult.picture
                axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/techs/pictures/" + this.$globals.companyId + "/" + this.$globals.user.sub,{
                    picture: this.selectedUser.picture
                },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

            this.$forceUpdate()

            this.previewImage = null;
            this.action = '';
            this.selected=[];
            this.update = {};

        },//END updateUser()

        uploadImage(e){
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                this.previewImage = e.target.result;
            };
        },//END uploadImage()

        async viewTimeSheet(){
        await axios
            .get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/timesheet/${encodeURIComponent(this.$globals.user.sub)}/${this.$globals.companyId}`,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
            .then((response) => {
                this.userTimeSheet = response.data.queryResult[0]
                this.timeSheet = typeof this.userTimeSheet?.time_sheet === 'object' ? this.userTimeSheet?.time_sheet : JSON.parse(this.userTimeSheet?.time_sheet)
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Users', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        this.subaction = 'viewMode'
        this.action = 'viewTimeSheet'
    }, // END viewTimeSheet

    }//END methods
}

</script>

<style scoped>
.dark {
	background-color: #dc4535 !important;
	border-color: #dc4535 !important
}
.stand {
	background-color: #dc3545 !important;
	border-color: #dc3545 !important
}
.darktext {
	background-color: #343a40 !important;
	color: #dc4535 !important
}
.standardtext {
	color: #dc3545 !important;
}
.scrollproperties::-webkit-scrollbar {
    display: none;
}

.scrollproperties {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

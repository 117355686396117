<template>
  <div class="adminMain">
    <!-- List of Users -->
    <div v-if="action === ''">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>User Management</b></h3>
          </div>
          <div class="card-tools">
            <b-button
              style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;"
              @click="action = 'createUser'"
              >Create User
			</b-button>
          </div>
        </div>
        <div class="card-body">
          <div>
            <input
              class="form-control "
              type="text"
              placeholder="Search by User Name/ID"
              v-model="search"
            />
          </div>
          <b-table
            style="margin-left: 1%"
            ref="userTable"
            striped
            :busy="this.isBusy"
            :items="this.allUsers"
            :filter="search"
            :filter-included-fields="['name']"
            :fields="[
              { key: 'name', sortable: true },
              { key: 'email', sortable: true },
              { key: 'edit' },
            ]"
            @row-clicked="getUserData"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(edit)="row">
              <i
                class="fas fa-edit"
                size="sm"
                @click.stop="
                  editUser(row.item, row.index);
                  action = 'editUser';
                "
              ></i>
              <i
                class="fas fa-trash"
                size="sm"
                @click.stop="deleteUser(row)"
              ></i>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <div v-if="action === 'viewUserData'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>User Info</b></h3>
          </div>
          <div class="card-tools">
            <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;" @click="editUser(selectedUser, lastSelectedIndex)">Edit Data</b-button>
            <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;" @click="resetPassword()">Reset Password</b-button>
            <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;" @click="action = ''">Return</b-button>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col" style="margin-left:10%; margin-top: 2.5%">
                <div class="figure">
                  <img :src="selectedUser.picture" class="img-circle elevation-3 w-50" style="width: 5vw; margin-bottom: 1%; border: black solid 1px" alt="User Image" @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'" />
                  <div class="figure-caption">
                    {{selectedUser.user_id}}
                  </div>
                </div>
            </div>
            <div class="col" style="margin-left:-15%; margin-top: 2.5%">
                <div class="row m-4">
                  <div class="col"><b>Name: </b> {{selectedUser.name}}</div>
                </div>
                <div class="row m-4">
                  <div class="col"><b>Email: </b> {{selectedUser.email}}</div>
                  <div class="col"><b>Verified: </b> {{selectedUser.email_verified}}</div>
                </div>
                <div class="row m-4">
                  <div class="col"><b>User Roles: </b><br/>  <span v-for="(role, roleindex)  in selectedUser.roles" :key="roleindex">{{ role.name }} <br/></span> </div>
                  <div class="col"><b>Base Location:</b> {{selectedUser.location}}</div>
                </div>
                <div class="row m-4">
                  <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="width:40%; height:50px; margin-left:2.5%" @click="viewTimeSheet(selectedUser)"><b>Time Sheet</b></b-button>
                  <b-button :class="{dark: $globals.displayClass, stand: !$globals.displayClass}" style="width:40%; height:50px; margin-left:5%" @click="viewClockEvents(selectedUser)"><b>Weekly Clock Events</b></b-button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

	<div v-if="action === 'viewClockEvents'">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>{{selectedUser.name}}</b></h3>
				</div>
				<div class="card-tools">
					<b-button class="mb-4" @click="action='viewUserData'" style="background: #fff4; border: #dc3545">
						Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<div v-for="(ticket, ticketIndex) in weekClocks" :key="ticketIndex">
					<div class="card card-outline-danger">
						<div class="card-header">
							<div class="card-title">
								<h4 class="text-center"><b>{{ `WO-${ticket.ticket}` }}</b></h4>
							</div>
						</div>
						<div class="card-body">
							<div v-for="(task,taskIndex) in ticket.ticketClocks" :key="taskIndex">
								<h5 class="text-center"><b>{{task.task}}</b></h5>
								<hr class="w-75" />
								<div v-for="(clock, clockIndex) in task.clocks" :key="clockIndex">
									<div class="row text-center">
										<div class="col">
											<b>Type:</b> {{clock.type}}
										</div>
										<div class="col">
											<b>Time:</b> {{clock.time}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <!-- View User TimeSheet -->
    <div v-if="action === 'viewTimeSheet'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>{{userTimeSheet.name}}</b></h3>
          </div>
          <div class="card-tools">
              <b-button
                class="mb-4"
                @click="action='viewUserData'"
                style="background: #fff4; border: #dc3545"
                >
                Return
          </b-button>
          </div>
        </div>
        <div class="card-body">
              <div v-for="(year, yearIndex) in timeSheet" :key="yearIndex">
                <h2 class="text-center" style="font-weight: bold; color: #dc3545">{{year.year}}</h2>
                <div class="card card-outline-danger" v-for="(week,weekIndex) in year.weeks" :key="weekIndex">
                    <div class="card-header" @click="showClockSet(week,weekIndex); calculateTotalHours(week, weekIndex)">
                        <div class="card-title align-content-center align-items-center">
                            <h4 class="text-center"><b>{{ getDateOfISOWeek(week.weekNumber-1, year.year) }}</b></h4>
                        </div>
                        <div class="card-tools">
                            <b>{{ totalHours[weekIndex] ? totalHours[weekIndex] : 0 }}</b>
                        </div>
                    </div>
                    <div class="card-body" v-show="showClocks[weekIndex]">
                        <div v-for="(day,dayIndex) in week.days" :key="dayIndex">
                            <h5 class="text-center"><b>{{day.day}}</b></h5>
                            <hr class="w-75 m-auto pb-3" />
                            <div class="row">
                                <b class="text-center" style="margin-left: 46%">Total Hours: {{ totalDayHours[dayIndex] ? totalDayHours[dayIndex] : 0 }}</b>
                            </div>
                            <div class="row">
                                <div class="col">

                                </div>
                                <div class="col-5">
                                    <h6 class="text-center"><b>Clock In</b></h6>
                                </div>
                                <div class="col-5">
                                    <h6 class="text-center"><b>Clock Out</b></h6>
                                </div>
                                <div class="col">

                                </div>
                            </div>
                            <div class="row text-center" v-for="(clock,index) in day.clock_set" :key="index">
                                <div class="col">

                                </div>
                                <div class="col-5">
                                    <!-- <div v-if="!clockEdit[index]">{{ clock.clock_in !== null && clock.clock_in !== undefined ? `${clock.clock_in.split('T')[0]} ${clock.clock_in.split('T')[1].split('.')[0]}` : "-" }}</div> -->
                                    <div v-if="!clockEdit[dayIndex][index] && userType === 'tech'">{{ clock.clock_in !== null && clock.clock_in !== undefined ? `${clock.clock_in.split('T')[0]} ${clock.clock_in.split('T')[1].split('.')[0]}` : "-" }}</div>
                                    <div v-else-if="!clockEdit[dayIndex][index] && userType === 'hourly'">{{ clock.clock_in !== null && clock.clock_in !== undefined ? `${clock.clock_in}` : "-" }}</div>
                                    <input v-else class="form-control text-center" v-model="clock.clock_in" placeholder="Clock In" />
                                </div>
                                <div class="col-5">
                                    <div v-if="!clockEdit[dayIndex][index] && userType === 'tech'">{{ clock.clock_out !== null && clock.clock_out !== undefined ? `${clock.clock_out.split('T')[0]} ${clock.clock_out.split('T')[1].split('.')[0]}` : "-" }}</div>
                                    <div v-else-if="!clockEdit[dayIndex][index] && userType === 'hourly'">{{ clock.clock_out !== null && clock.clock_out !== undefined ? `${clock.clock_out}` : "-" }}</div>
                                    <input v-else class="form-control text-center" v-model="clock.clock_out" placeholder="Clock Out" />
                                </div>
                                <div class="col">
                                    <i v-if="canAccess.admin && !clockEdit[dayIndex][index]" class="fas fa-edit" size="sm" @click.stop="clockEdit[dayIndex][index] = true; $forceUpdate()"></i>
                                    <i v-if="canAccess.admin && !clockEdit[dayIndex][index]" class="fas fa-trash" size="sm"></i>
                                    <b-button v-if="canAccess.admin && clockEdit[dayIndex][index]" style="background: #dc3545; border-color: #dc3545" @click.stop="updateClockEvent(day.clock_set, yearIndex, weekIndex, dayIndex); clockEdit[dayIndex][index] = false; $forceUpdate()">Confirm</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
      </div>
    </div>


    <div v-if="action === 'createUser'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>New User</b></h3>
          </div>
          <div class="card-tools">
            <b-button
              style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;"
              @click="createUser()"
              >Create User</b-button
            >
            <b-button
              style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;"
              @click="
                action = '';
                showPreview = false;
              "
              >Return</b-button
            >
          </div>
        </div>
        <div class="card-body">
          <form class="adding">
            <label>Email<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input
              required
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px;"
              type="text"
              placeholder="Email"
              v-model="newUser.email"
              v-b-tooltip.hover title="Required Field"
            />
            <label>Password <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input
              requried
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px;"
              type="text"
              placeholder="Password"
              v-model="newUser.password"
              pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
              v-b-tooltip.hover title="Required Field"
            />
            <label>Name <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input
              required
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px"
              type="text"
              placeholder="Name"
              v-model="newUser.name"
              v-b-tooltip.hover title="Required Field"
            />
            <label>File</label>
            <input
              class="form-control w-75"
              style="margin-left: 12.5%"
              type="file"
              accept="image/*"
              @change="uploadImage($event)"
              id="file-input"
            />
            <div v-if="showPreview">
              <img
                :src="previewImage"
                class="uploading-image"
                style="
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  border: black solid 1px;
                "
                alt="Preview"
              />
            </div>
            <br />
          </form>
        </div>
      </div>
    </div>

    <div v-if="action === 'editUser'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>Edit User</b></h3>
          </div>
		  <div class="card-tools">
			  <b-button
				style="background-color: #fff4; border-color: #dc3545"
				@click="updateUser(selectedUser)"
				>Update User
			  </b-button>
			  <b-button
				style="background-color: #fff4; border-color: #dc3545"
				@click="
				  action = 'viewUserData';
				  showPreview = false;
				"
				>Return
			  </b-button>
		  </div>
        </div>
        <div class="card-body">
          <form class="adding">
            <label>Name <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input
              required
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px"
              type="text"
              placeholder="Name"
              v-model="selectedUser.name"
            />
            <label>Email <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px;"
              type="text"
              placeholder="Email"
              v-model="selectedUser.email"
              required
            />
            <label>Nickname</label>
            <input
              class="form-control w-75"
              style="margin-left: 12.5%; margin-bottom: 10px;"
              type="text"
              placeholder="Nickname"
              v-model="selectedUser.nickname"
            />
            <label>Roles <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <multiselect
              required
              id="editRoles"
              :clearable="true"
              label="name"
              track-by="name"
              :options="allRoles"
              v-model="newRoles"
              :multiple="true"
              placeholder="Roles"
              style="width: 75%; margin-left: 12.5%; margin-bottom: 10px;"
			  :show-labels="false"
            >
            </multiselect>
            <div v-for="(name, index) in newRoles" :key="index">
              <div v-if="newRoles[index].name.includes('Technician')">
                <label>Work Group</label>
                <multiselect
                  id="workGroupSelector"
                  :clearable="true"
                  :options="allWorkGroups"
                  v-model="selectedWorkGroup"
                  :multiple="false"
                  placeholder="Work Group"
                  :show-labels="false"
                  style="margin-bottom: 10px; width: 75%; margin-left: 12.5%"
                >
                </multiselect>
              </div>
            </div>
            <label>Base Location</label>
            <multiselect
              id="userBase"
              :clearable="true"
              label="name"
              track-by="name"
              :options="baseLocations"
              v-model="userBase"
              placeholder="Base Location"
              style="margin-bottom: 10px; width: 75%; margin-left: 12.5%"
			  :show-labels="false"
            >
            </multiselect>
            <label>File Upload</label>
            <input
              class="form-control w-75"
              type="file"
              accept="image/*"
              @change="uploadImage($event)"
              id="file-input"
              style="margin-bottom: 10px; margin-left: 12.5%"
            />
            <div v-if="showPreview">
              <img
                :src="previewImage"
                class="uploading-image"
                style="
                  height: 100px;
                  width: 100px;
                  border-radius: 50px;
                  border: black solid 1px;
                "
                alt="Preview"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import { Socket } from "socket.io-client";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: "app",
  props: {
    socket: Socket,
    companyId: Number,
    companyName: String,
    canAccess: Object,
    user: Object
  },
  components: { Multiselect, VueSlider },
  data() {
    return {
      action: "",
      allLocations: [],
      allRoles: [],
      allUsers: [],
      userTimeSheet: [],
      timeSheet: [],
      allWorkGroups: ["Mobile", "Shop"],
      baseLocations: [],
      defaultImage: "https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png",
      isBusy: false,
      lastSelectedIndex: "",
      hourlyCheck: {},
      newRoles: [],
      newUser: {
        name: "",
        password: "",
        email: "",
        picture: "",
      },
      previewImage: "",
      search: "",
      selectedUser: {},
      selectedWorkGroup: "Mobile",
      showPreview: false,
      subaction: "",
      userBase: "",
      companyInfo: null,
      clockEdit: [[],[],[],[],[],[],[]],
      clockInHours: Number,
      clockInMinutes: Number,
      clockInMidday: "",
      clockOutHours: Number,
      clockOutMinutes: Number,
      clockOutMidday: "",
      indexToEdit: Number,
      currentItems: [],
      middayOptions: ["AM", "PM"],
      showClocks: [],
      sliderBarOptions: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        direction: 'ltr',
        min: 0,
        max: 24,
        disabled: true,
      },
      sliderBarValue: [],
      globalHours: 4,
      globalMinutes: 30,
      globalMidday: "",
      totalHours: [],
		totalDayHours: [],
		userType: 'tech',
      weekClocks: [],
    };
  }, //END data
  created() {
    this.$globals.socket.emit("joinRoom", "users"+this.$globals.companyId);
    this.$globals.socket.on("connectToRoom", (data) => {
      //console.log(data);
    });
    this.$globals.socket.on("newDataCreated", async function (message, data) {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.allUsers = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/roles/" + this.$globals.companyId,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.allRoles = response.data.queryResult;
          for (role in this.allRoles) {
            if (this.allRoles[role].name == this.companyName) {
              this.allRoles[role].isDisabled = true;
            }
          }
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/locations/" + this.$globals.companyId,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.baseLocations = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    });
  },

  async mounted() {
    this.isBusy = true;
    await axios
      .get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId,
      {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allUsers = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    await axios
      .get(process.env.VUE_APP_API_TARGET + "/users/roles/" + this.$globals.companyId,
      {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allRoles = response.data.queryResult;
        for (let i = this.allRoles.length - 1; i > 0; i--) {
          if (
            this.allRoles[i].name == "Accountant" ||
            this.allRoles[i].name == "Admin" ||
            this.allRoles[i].name == "Dispatcher" ||
            this.allRoles[i].name == "Hourly Employee" ||
            this.allRoles[i].name == "Technician" ||
            this.allRoles[i].name.toLowerCase().includes("parts") ||
            this.allRoles[i].name.toLowerCase().includes("sales")
          ) {
            //console.log(this.allRoles[i], "Matched");
          } else {
            this.allRoles.splice(i, 1);
          }
        }
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

    await axios
      .get(process.env.VUE_APP_API_TARGET + "/locations/" + this.$globals.companyId,
      {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.baseLocations = response.data.queryResult;
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

	  axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`,
		  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then(response =>{
			  this.companyInfo = response.data.queryResult[0]
		  }
	  )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    this.isBusy = false;
  },

  destroyed() {
        this.$globals.socket.emit("leaveRoom", `users${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed users")
    },
  methods: {

    getDateOfISOWeek(w, y) {
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        return ISOweekStart.toDateString();
    },

	async createUser() {
		//Safety Checks
		if(this.companyInfo.cur_users >= this.companyInfo.max_users){
			alert('Cannot make more users unless max users is increased')
			return;
		}
		if(this.newUser.email === null || this.newUser.email === ''){
			alert("Email is required")
			return;
		}
		if(this.newUser.password == '' || this.newUser.password === null){
			alert("Password is required but User will likely reset it on first login.")
			return;
		}
		if(this.newUser.name === '' || this.newUser.name === null){
			alert("User must have a name")
			return;
		}

    if(!this.newUser.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/))
    {
      alert('Password must be between 6 to 20 characters and contain at least one numeric digit, one uppercase and one lowercase letter')
      return;
    }


      await axios.post(
        process.env.VUE_APP_API_TARGET + "/users/" + this.companyName,
        {
          email: this.newUser.email,
          password: this.newUser.password,
          name: this.newUser.name,
          picture: this.previewImage ? this.previewImage : this.newUser.picture,
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .then(response =>{
		  this.companyInfo.cur_users++
		  axios.patch(`${process.env.VUE_APP_API_TARGET}/companies/users/${this.$globals.companyId}`,{
			  cur_users: this.companyInfo.cur_users
		  },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	  })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.allUsers = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.previewImage = null;
      this.action = "";
      this.newUser.email = "";
      //this.newUser.username = "";
      this.newUser.password = "";
      this.newUser.name = "";
      this.newUser.picture = "";

      this.$globals.socket.emit("newData", "users", "tech");
    }, //END createUser()

	async deleteUser(row) {
		if(confirm(`Are you sure you want to delete ${row.item.name}`)){
			this.isBusy = true;
			this.selectedUser = row.item;
			this.newRoles = [];
			for (var role in this.selectedUser.roles) {
				if (this.selectedUser.roles[role].name == "Technician") {
					this.removeTech();
				}
			}

			await axios.delete(process.env.VUE_APP_API_TARGET + "/users/" + row.item.user_id + "/" + this.$globals.companyId,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

      await axios.delete(
        process.env.VUE_APP_API_TARGET + "/users/" +
          row.item.user_id +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
      .catch((response)=>{
      this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
      })

      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.allUsers = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
        })
        .catch((response)=>{
          this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })
      this.$refs.userTable.refresh();
      this.selectedUser = {};
      this.isBusy = false;
      this.$globals.socket.emit("newData", "users", "tech");
    }
  }, //END deleteUser()

    async editUser(user, index) {
      await this.getUserData(user, index);
      this.previewImage = this.selectedUser.picture;
      this.toggleDetails(user);

      this.action = 'editUser'
    }, //END editUser()

	/*
    editClockSet(record, index){
      this.toggleDetails(record)
    },
	*/

    formatRoles() {
      var roleIdArray = [];
      for (var role in this.newRoles) {
        roleIdArray.push(this.newRoles[role].id);
        if (this.newRoles[role].name == "Technician") {
          if (this.selectedWorkGroup == null) {
            this.selectedWorkGroup = "Mobile";
          }
          this.techRole();
        }

      }
      this.removeTech();
      return roleIdArray;
    },

    async getUserData(record, index) {
      this.selectedUser = record;
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/users/" + record.user_id + "/roles/",
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.lastSelectedIndex = index;
          this.selectedUser.roles = response.data.queryResult;
          this.newRoles = response.data.queryResult;
          this.allUsers[index].roles = response.data.queryResult;
          this.toggleDetails(record);
        })
        .catch((response)=>{
          this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })

      this.action = 'viewUserData'
    },

    async removeTech() {
      var missingFromNew = true;
      var existsInOld = false;
      for (var i = 0; i < this.newRoles.length; i++) {
        if (this.newRoles[i].name == "Technician") {
          missingFromNew = false;
        }
      }
      for (var j = 0; j < this.selectedUser.roles.length; j++) {
        if (this.selectedUser.roles[j].name == "Technician") {
          existsInOld = true;
        }
      }
      if (missingFromNew && existsInOld) {
        await axios.delete(
          process.env.VUE_APP_API_TARGET + "/users/technician/" +
            this.selectedUser.user_id +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      }
      return;
    },

    async resetPassword() {
      await axios.post(process.env.VUE_APP_API_TARGET + "/users/"+this.selectedUser.email+"/changepassword/"+this.$globals.companyId,null,{
          headers: {
            'Authorization':'Bearer ' + this.$globals.user.auth0Token
          }
        }
      ).then((response) => {
        this.$vToastify.success(`${response.data.message}`);
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.toggleDetails(this.selectedUser);
    }, //END resetPassword()

    async techRole() {
      //check if user already has tech role.
      for (var index = 0; index < this.selectedUser.roles.length; index++) {
        if (this.selectedUser.roles[index].name == "Technician") {
          return;
        }
      }
      //else
        await axios.post(
          process.env.VUE_APP_API_TARGET + "/users/technician/" + this.$globals.companyId,
          {
            name: this.selectedUser.name,
            picture: this.previewImage,
            id: this.selectedUser.user_id,
            work_zone: this.selectedWorkGroup,
          },
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})

      this.$globals.socket.emit("newData", "users", "tech");
    },

    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, "_showDetails", false);
      } else {
        this.currentItems.forEach(item => {
          this.$set(item, "_showDetails", false);
        })
        this.$nextTick(() =>{
          this.$set(row, '_showDetails', true)
        })
      }
      this.indexToEdit = null
    },

    async updateUser(user) {
		//Safety Checks
		if(user.name === null || user.name ===''){
			alert("Name need to exist")
			return;
		}
		if(user.email === null || user.email === ''){
			alert("Email is required")
			return;
		}
		if(this.newRoles.length <1){
			alert("User must have a role")
			return;
		}
		//TODO see if we can guarentee the company role some way. Pass in prop and check against?
      if (this.previewImage == null) {
        this.previewImage = this.selectedUser.picture;
      }
      var roles = this.formatRoles();

      await axios
        .put(
          `${process.env.VUE_APP_API_TARGET}/users/${user.user_id}/${this.$globals.companyId}`,
          {
            name: user.name,
            nickname: user.nickname,
            password: user.password,
            email: user.email,
            picture: this.previewImage ? this.previewImage : this.defaultImage,
            roles: roles,
            location: this.userBase,
          },
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.selectedUser.picture = response.data.queryResult.picture
          axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/techs/pictures/" + this.$globals.companyId + "/" + user.user_id,{
              picture: this.selectedUser.picture
          },{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
          this.$emit('refreshMain')
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})


      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/hourlyemployee/" + this.$globals.companyId + "/" +this.selectedUser.user_id,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.hourlyCheck = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      var check = false
      for(var index in roles){
        if(roles[index] == "rol_rFmH4wUB7XZDyrHD"){
          check = true
          if(this.hourlyCheck.length === 0){
            await axios
              .post(process.env.VUE_APP_API_TARGET + "/users/hourlyemployee/" + this.$globals.companyId + "/" +this.selectedUser.user_id + "/" + this.selectedUser.name,{},
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
          }
          break
        }
      }
      if(check === false && this.hourlyCheck.length > 0){
        await axios
          .delete(process.env.VUE_APP_API_TARGET + "/users/hourlyemployee/" + this.$globals.companyId + "/" +this.selectedUser.user_id,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
      }


      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/" + this.$globals.companyId,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.allUsers = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})
      this.previewImage = null;
      this.showPreview = false;
      this.action = "";
    }, //END updateUser()

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
      this.showPreview = true;
    }, //END uploadImage()

    async viewClockEvents(user) {
        this.selectedUser = user
        console.log("viewClockEvents", user)
        await axios.get(`${process.env.VUE_APP_API_TARGET}/users/technician/production/${encodeURIComponent(user.user_id)}/${this.$globals.companyId}`, {
            headers: {
                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
            }
        }).then((response) => {
			console.log("response", response.data.queryResult)

			response.data.queryResult.map((ticket) => {
				ticket.ticketClocks.forEach((task) => {
					console.log(task, typeof task)
					task.clocks.sort((clockA, clockB) => {
						return new Date(clockA.time) < new Date(clockB.time) ? -1 : 1
					})
				})
			})

			this.weekClocks = response.data.queryResult
			this.subaction = 'viewMode'
			this.action = 'viewClockEvents'
        })
    },

    async viewTimeSheet(user){
        this.selectedUser = user
        if (this.selectedUser.roles.some((role) => role.name === 'Hourly Employee')) {
            this.userType = 'hourly'
        } else {
            this.userType = 'tech'
        }
        console.log("viewTimeSheet", user)
        await axios
            .get(`${process.env.VUE_APP_API_TARGET}/tickets/clock_out/${encodeURIComponent(user.user_id)}/${this.$globals.companyId}/tech`,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
            .then(async (response) => {
                if (response.data.queryResult[0] === undefined){
                    await axios
                    .get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/timesheet/${encodeURIComponent(user.user_id)}/${this.$globals.companyId}`,
                        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.userTimeSheet = response.data.queryResult[0]
                        console.log("switching after hourly", response.data)
                        this.timeSheet = typeof this.userTimeSheet?.time_sheet === 'object' ? this.userTimeSheet?.time_sheet : JSON.parse(this.userTimeSheet?.time_sheet)
                        this.timeSheet.forEach((entry) => {
                            entry.weeks.reverse()
                        })
                        console.log(this.timeSheet)
                        this.subaction = 'viewMode'
                        this.action = 'viewTimeSheet'
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})					
                } else {
                    this.userTimeSheet = response.data.queryResult[0]
                    console.log("switching before hourly", this.userTimeSheet)
                    this.timeSheet = typeof this.userTimeSheet?.time_sheet === 'object' ? this.userTimeSheet?.time_sheet : JSON.parse(this.userTimeSheet?.time_sheet)
                    this.timeSheet.forEach((entry) => {
                        entry.weeks.reverse()
                    })
                    this.subaction = 'viewMode'
                    this.action = 'viewTimeSheet'
                }
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
    }, // END viewTimeSheet

    getMonthString(date_number){
      const month = ["January", "February", "March", "April", "May", "June", "July", "August", "Semptember", "October", "November", "December"];
      return month[date_number];
    },

    getHourString(hour){
      let returnValue = "AM";
      if(parseInt(hour) >= 12)
        returnValue = "PM"

      return returnValue;
    },

    changeToEditMode(record, index){
      this.clockInHours = parseInt(new Date(record.clock_in).getUTCHours() % 12)
      this.clockInMinutes = parseInt(new Date(record.clock_in).getMinutes())
      this.clockInMidday = this.getHourString(new Date(record.clock_in).getUTCHours())

      this.clockOutHours = parseInt(new Date(record.clock_out).getUTCHours() % 12)
      this.clockOutMinutes = parseInt(new Date(record.clock_out).getMinutes())
      this.clockOutMidday = this.getHourString(new Date(record.clock_out).getUTCHours())

      this.indexToEdit = index

      this.subaction = "editMode"
    },

    changeDateHours(hour, midday){
      if(midday === "PM"){
        return parseInt(hour) + 12
      }
      return hour
    },

    async saveClockSet(index, day){
      if(this.clockOutHours === ''|| this.clockOutMinutes === '' || this.clickInHours === '' || this.clickInMinutes === '')
        return;
      let targetObject = this.timeSheet.find(item => item.day === day)
      let targetIndex = this.timeSheet.findIndex(item => item.day === day)

      let targetClockIn = targetObject.clock_set[index].clock_in
      let targetClockOut = targetObject.clock_set[index].clock_out

      let newHour;

      newHour = this.changeDateHours(this.clockInHours, this.clockInMidday)
      targetClockIn = new Date(targetClockIn).setHours(newHour, this.clockInMinutes)
      targetClockIn = new Date(targetClockIn)
      targetClockIn = this.adjustForTimezone(targetClockIn)

      newHour = this.changeDateHours(this.clockOutHours, this.clockOutMidday)
      targetClockOut = new Date(targetClockOut).setHours(newHour, this.clockOutMinutes)
      targetClockOut = new Date(targetClockOut)
      targetClockOut = this.adjustForTimezone(targetClockOut)

      this.timeSheet[targetIndex].clock_set[index].clock_in = targetClockIn
      this.timeSheet[targetIndex].clock_set[index].clock_out = targetClockOut

      for(var i = 0; i < this.timeSheet.length; i++){
        delete this.timeSheet[i]['_showDetails']
      }

      await axios
        .patch(`${process.env.VUE_APP_API_TARGET}/tickets/update/clock_out/${this.$globals.user.sub}/${this.$globals.companyId}/tech`,
        {
            out: JSON.stringify(this.timeSheet.reverse())
        },
        {
          headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`},
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})

      await axios
        .get(`${process.env.VUE_APP_API_TARGET}/tickets/clock_out/${this.$globals.user.sub}/${this.$globals.companyId}/tech`,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.userTimeSheet = response.data.queryResult[0]
          this.timeSheet = JSON.parse(this.userTimeSheet.time_sheet).reverse()
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})

      this.indexToEdit = null
    },

    async setGlobalClockOut(day){
      if(this.globalHours === '' || this.globalMinutes === '' || this.globalMidday === '')
        return;
        let targetObject = this.timeSheet.find(item => item.day === day)
        let targetIndex = this.timeSheet.findIndex(item => item.day === day)

        let clockSet = targetObject.clock_set
        let targetClockOut
        let index;
        for(var i = 0; i < clockSet.length; i++){
          targetClockOut = clockSet[i].clock_out;
          index = i;
        }

        let newHour;
        newHour = this.changeDateHours(this.globalHours, this.globalMidday)
        targetClockOut = new Date(targetClockOut).setHours(newHour, this.globalMinutes)
        targetClockOut = new Date(targetClockOut)
        targetClockOut = this.adjustForTimezone(targetClockOut)

        this.timeSheet[targetIndex].clock_set[index].clock_out = targetClockOut

        for(var i = 0; i < this.timeSheet.length; i ++){
          delete this.timeSheet[i]['_showDetails']
        }

        await axios
        .patch(`${process.env.VUE_APP_API_TARGET}/tickets/update/clock_out/${this.$globals.user.sub}/${this.$globals.companyId}/tech`,
        {
            out: JSON.stringify(this.timeSheet.reverse())
        },
        {
          headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`},
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})

        await axios
        .get(`${process.env.VUE_APP_API_TARGET}/tickets/clock_out/${this.$globals.user.sub}/${this.$globals.companyId}/tech`,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          this.userTimeSheet = response.data.queryResult[0]
          this.timeSheet = JSON.parse(this.userTimeSheet.time_sheet).reverse()
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})

        this.indeToEdit = null
        this.globalMidday = ""
    },

    adjustForTimezone(date_value){
      var timeOffsetInMS = date_value.getTimezoneOffset() * 60000;
      date_value.setTime(date_value.getTime() - timeOffsetInMS);
      return date_value
    },

    getLastHourAndMinute(item){
      let lastClockOut;
      for(var i = item.length - 1; i > 0; i--){
        if(item[i].clock_out != null){
          lastClockOut = item[i].clock_out
          break;
        }
      }
      var returnValue = `${new Date(lastClockOut).getUTCHours() % 12}
                          :${new Date(lastClockOut).getMinutes()}
                          ${this.getHourString(new Date(lastClockOut).getUTCHours())}`
      return returnValue
    },

    showClockSet(week,index){
        if(this.showClocks[index]){
            this.showClocks[index] = !this.showClocks[index]
        } else {
            //set every entry in the array to false
            this.showClocks = this.showClocks.map(() => false)
            this.showClocks[index] = true
        }
        this.$forceUpdate()
    },

    calculateTotalHours(time_sheet, weekIndex){
        let total = 0
        if(time_sheet.days === undefined)
            return

        for(var item in time_sheet.days){
            for(var i in time_sheet.days[item].clock_set){
                let diff = 0
                if (time_sheet.days[item].clock_set[i].clock_out && time_sheet.days[item].clock_set[i].clock_in) {
                    diff = (new Date(time_sheet.days[item].clock_set[i].clock_out) - new Date(time_sheet.days[item].clock_set[i].clock_in))
                }
                total = total + diff
            }
            this.calculateDayHours(time_sheet.days[item], item)
        }
        total = (total / (1000 * 60 * 60)).toFixed(1)
        this.totalHours[weekIndex] = total
        this.$forceUpdate()
    },

    calculateDayHours(time_sheet, dayIndex){
        let total = 0
        if(time_sheet === undefined)
            return

        for(var i in time_sheet.clock_set){
            let diff = 0
            if (time_sheet.clock_set[i].clock_out && time_sheet.clock_set[i].clock_in) {
                diff = (new Date(time_sheet.clock_set[i].clock_out) - new Date(time_sheet.clock_set[i].clock_in))
            }
            total = total + diff
        }
        total = (total / (1000 * 60 * 60)).toFixed(1)
        this.totalDayHours[dayIndex] = total
    },

      async updateClockEvent(clock_set, yearIndex, weekIndex, dayIndex) {
        //Changed to one-indexing as the reverse for showing in descending order throws off zero-indexing.
        //Instead of accessing at index, accessing at array.length - index.array.length - 0 would be out of bounds, hence the one - indexing
        yearIndex += 1
        weekIndex += 1

        console.log("update:", clock_set, yearIndex, weekIndex, dayIndex)
        this.userTimeSheet.time_sheet = typeof this.userTimeSheet.time_sheet === 'object' ? this.userTimeSheet.time_sheet : JSON.parse(this.userTimeSheet.time_sheet)
        this.userTimeSheet.time_sheet[this.userTimeSheet.time_sheet.length - yearIndex].weeks[this.userTimeSheet.time_sheet[this.userTimeSheet.time_sheet.length - yearIndex].weeks.length - weekIndex].days[dayIndex].clock_set = clock_set
        console.log("before stringify:", this.userTimeSheet.time_sheet)
        this.userTimeSheet.time_sheet = typeof this.userTimeSheet.time_sheet === 'object' ?  JSON.stringify(this.userTimeSheet.time_sheet) : this.userTimeSheet.time_sheet

        console.log("posting:", this.userTimeSheet.time_sheet)

        await axios.patch(`${process.env.VUE_APP_API_TARGET}/users/${this.userType === 'hourly' ? 'hourly' : 'technician'}/${encodeURIComponent(this.selectedUser.user_id)}/${this.$globals.companyId}`,{
            time_sheet: this.userTimeSheet.time_sheet
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
            }
        }).then((response)=> {
            this.$forceUpdate()
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

		})
    }


  }, //END methods
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
}

.searchBar {
  display: flex;
  justify-content: center;
  width: 60%;
  margin-left: 20%;
  margin-bottom: 5px;
}

.selectors .multiselect__tag {
  background: #dc3545;
}

.selectors .multiselect__option--highlight {
  background: #dc3545;
  outline: none;
  color: white;
}
.selectors .multiselect__option--highlight:after {
  background: #dc3545;
  outline: none;
  color: white;
}

.selectors .multiselect__tag-icon:hover {
  background: grey;
}
</style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  background: #dc3545;
}

.multiselect__option--highlight {
  background: #dc3545;
  outline: none;
  color: white;
}
.multiselect__option--highlight:after {
  background: #dc3545;
  outline: none;
  color: white;
}

.multiselect__tag-icon:hover {
  background: grey;
}
</style>

<template>
	<div class="container-fluid">

		<div v-if="action === ''">
			<div>
				<b-button
					:class="{dark: $globals.displayClass, stand: !$globals.displayClass}"
					class="m-3"
					@click="viewLeads()"
					>Leads
				</b-button>
				<b-button
					:class="{dark: $globals.displayClass, stand: !$globals.displayClass}"
					class="m-3"
					@click="createMap()"
					>Map
				</b-button>
			</div>
			<div class="row">
				<div class="col">
					<div class="openOrders card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Proposals</b></h3>
							</div>
							<div class="card-tools">
								<b-button
									v-if="$globals.canAccess.admin || $globals.canAccess.sales"
									style="background-color: #fff4; border-color: #dc3545"
									@click="createProposal()"
									>New Proposal
								</b-button>
							</div>
						</div>

						<div class="card-body">
							<div class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
								<input
								type="text"
								class="form-control"
								placeholder="Search by Customer or Proposal Number"
								v-model="search.proposal"
								/>
							</div>
							<b-tabs fill lazy v-model="selectedTab.proposal">
								<b-tab title="Open" :title-link-class="'text-danger'">
									<b-table
										ref="openPropsTable"
										sticky-header
										:filter="this.search.proposal"
										:filter-included-fields="['customer','localid']"
										striped
										:busy="isBusy"
										:items="openProposals"
										:fields="[
											{ key: 'localid', label: 'Proposal Number', sortable: true },
											{ key: 'customer', sortable: true },
											{ key: 'expires', label: 'End Date', sortable: true },
										]"
										@row-clicked="viewProposal"
									>
										<template #cell(customer)="row">
											{{row.item.customer != null ? typeof row.item.customer != "object" ? JSON.parse(row.item.customer).name: row.item.customer.name: ""}}
										</template>
									</b-table>
								</b-tab>

								<b-tab title="Closed" :title-link-class="'text-danger'">
									<b-table
										ref="openPropsTable"
										sticky-header
										:filter="this.search.proposal"
										:filter-included-fields="['customer','localid']"
										striped
										:busy="isBusy"
										:items="closedProposals"
										:fields="[
											{ key: 'localid', label: 'Proposal Number', sortable: true },
											{ key: 'customer', sortable: true },
											{ key: 'reason', label: 'Reason'},
										]"
										@row-clicked="viewProposal"
									>
										<template #cell(customer)="row">
											{{row.item.customer != null ? typeof row.item.customer != "object" ? JSON.parse(row.item.customer).name: row.item.customer.name: ""}}
										</template>
									</b-table>
								</b-tab>
							</b-tabs>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="openOrders card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Sales Orders</b></h3>
							</div>
							<div class="card-tools">
								<b-button
									v-if="$globals.canAccess.admin || $globals.canAccess.sales"
									style="background-color: #fff4; border-color: #dc3545"
									@click="createSO()"
									>New Sales Order
								</b-button>
							</div>
						</div>

						<div class="card-body">
							<div class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
								<input
								type="text"
								class="form-control"
								placeholder="Search by Sales Order # or Customer"
								v-model="search.salesOrder"/>
							</div>
							<b-tabs fill lazy v-model="selectedTab.salesOrder">
								<b-tab title="Open" :title-link-class="'text-danger'">
									<b-table
										ref="openSalesTable"
										:filter="this.search.salesOrder"
										:filter-included-fields="['customer','localid']"
										sticky-header
										striped
										:busy="isBusy"
										:items="openSalesOrders"
										:fields="[
											{ key: 'localid', label: 'Order Number', sortable: true },
											{ key: 'customer', sortable: true },
											{ key: 'starts', label: 'Start Date', sortable: true },
										]"
										@row-clicked="viewSalesOrder"
									>
										<template #cell(customer)="row">
											{{row.item.customer != null ? typeof row.item.customer != "object" ? JSON.parse(row.item.customer).name : row.item.customer.name: ""}}
										</template>
									</b-table>
								</b-tab>
								<b-tab title="Closed" :title-link-class="'text-danger'">
									<b-table
										ref="openSalesTable"
										sticky-header
										striped
										:busy="isBusy"
										:items="closedSalesOrders"
										:fields="[
											{ key: 'localid', label: 'Order Number', sortable: true },
											{ key: 'customer', sortable: true },
											{ key: 'reason', label: 'Reason'},
										]"
										@row-clicked="viewSalesOrder"
									>
										<template #cell(customer)="row">
											{{row.item.customer != null ? typeof row.item.customer != "object" ? JSON.parse(row.item.customer).name : row.item.customer.name: ""}}
										</template>
									</b-table>
								</b-tab>
							</b-tabs>

						</div>
					</div>
				</div>

				<div class="col">
					<div class="openOrders card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Purchase Orders</b></h3>
							</div>
							<div class="card-tools">
								<b-button
									v-if="$globals.canAccess.admin || $globals.canAccess.sales"
									style="background-color: #fff4; border-color: #dc3545"
									@click="createPO()"
									>New PO
								</b-button>
							</div>
						</div>

						<div class="card-body" style="height: 442px">
							<div class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
								<input
									type="text"
									class="form-control"
									placeholder="Search by Order Number or Vendor"
									v-model="search.purchaseOrders"
								/>
							</div>
                            <b-tabs fill lazy v-model="selectedTab.purchaseOrder">
                                <b-tab title="Open" :title-link-class="'text-danger'">
                                    <b-table
                                        ref="openPOTable"
                                        sticky-header
                                        :filter="this.search.purchaseOrders"
                                        :filter-included-fields="['localid','vendor']"
                                        striped
                                        :busy="isBusy"
                                        :items="openPOs"
                                        :fields="[
                                            { key: 'localid', label: 'Order Number', sortable: true },
                                            { key: 'vendor', sortable: true },
                                        ]"
                                        @row-clicked="viewPO"
                                    >
                                        <template #cell(vendor)="row">
                                            {{typeof row.item.vendor != "object" ? JSON.parse(row.item.vendor).company : row.item.vendor.company}}
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab title="Closed" :title-link-class="canAccess['admin'] ? 'text-danger' : 'text'" :disabled="!canAccess['admin']">
                                    <b-table
                                        ref="closedPOTable"
                                        sticky-header
                                        :filter="this.search.purchaseOrders"
                                        :filter-included-fields="['localid','vendor']"
                                        striped
                                        :busy="isBusy"
                                        :items="closedPOs"
                                        :fields="[
                                            { key: 'localid', label: 'Order Number', sortable: true },
                                            { key: 'vendor', sortable: true },
                                        ]"
                                        @row-clicked="viewPO"
                                    >
                                        <template #cell(vendor)="row">
                                            {{typeof row.item.vendor != "object" ? JSON.parse(row.item.vendor).company : row.item.vendor.company}}
                                        </template>
                                    </b-table>
                                </b-tab>
                            </b-tabs>
						</div>
					</div>
				</div>
			</div>

			<!-- Next Row -->
			<div class="row">
				<div class="col">
					<div class="card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Sales Leaderboard</b></h3>
							</div>
						</div>

						<div class="card-body">
								<!-- Sales Leaderboard -->
                            <leaderboard
								:period = "'1y'"
                                :dataType="dataType">
                            </leaderboard>
						</div>
					</div>
				</div>

				<!-- TO DO List -->
				<div class="col mt-n3">
					<ToDoList></ToDoList>
				</div>
			</div>
		</div>

		<!-- CREATE PO -->
		<div v-if="action === 'createPO'">
			<newPO :vendor="selectedVendor" :orderType="poType" :items="poItems" :orderID="poTicketID" :taskOptions="null"
			@returnFromNewPO="action = '';getOpenPurchaseOrders(); showLineSelect = false; computedFields(); getSalesOrders(); ">
			</newPO>
		</div>

		<!-- VIEW PO -->
		<div v-if="action === 'viewPO'">
			<viewPO :socket="socket" :selectedPO="selectedPO" :selectedCompany="selectedCompany" :userRoles="userRoles"
				@returnFromView="action=''; getOpenPurchaseOrders()" @POclosed="action=''; getOpenPurchaseOrders(); getSalesOrders()">
			</viewPO>
		</div>

		<!-- CREATE PROPOSAL -->
		<div v-if="action === 'createProp'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Proposal</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							@click="submitProp()"
							>Submit
						</b-button>

						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							@click="action = ''"
							>Return
						</b-button>
					</div>
				</div>

				<div class="card-body">
					<div id="salesman">
						<label>Salesman<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							class="w-100 mb-3"
							id="selectedUsers"
							:options="allSalesmen"
							v-model="selectedSalesman"
							:clearable="true"
							track-by="name"
							label="name"
							:multiple="false"
							:show-labels="false"
							required
						></multiselect>
					</div>

					<label for="selectedCustomer">Customer<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
					<multiselect
						class="w-100 mb-3"
						id="selectedCustomer"
						:options="allCustomers"
						v-model="selectedCustomer"
						:clearable="true"
						track-by="name"
						label="name"
						@select="selectCustomer($event); sortLeads();"
						:show-labels="false"
					>
					</multiselect>

					<label for="billContact">Bill to Contact<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
					<multiselect
						class="w-100 mb-3"
						id="billContact"
						:options='allContacts'
						v-model="billContact"
						:clearable="true"
						track-by="name"
						:custom-label="customLabel"
						:show-labels="false"
						required
						>
					</multiselect>

					<label for="leadSelect">Lead</label>
					<multiselect
						class="w-100 mb-3"
						id="leadSelect"
						:options="sortedLeads"
						v-model="selectedLead"
						:clearable="true"
						track-by="localid"
						:custom-label="customLeadLable"
						:show-labels="false"
						@search-change="debounceSortedLeads">
						<template slot="option" slot-scope="props">
			              <intersect v-if="props.option.localid === sortedLeads[sortedLeads.length - 1].localid" @enter="getMoreSortedLeads">
			                <span>{{props.option.localid}}</span>
			              </intersect>
			            </template>
					</multiselect>

					<label>Expires</label>
					<VueCtkDateTimePicker v-if="$globals.displayClass === false" class="w-100 mb-3" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="expires"/>
					<VueCtkDateTimePicker v-else class="w-100 mb-3" style="width: 70%" color="#dc4535" button-color="#dc4535" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="expires"/>

					<b-table striped :fields="propTableFields" :items="newLines">

						<template #cell(itemdesc)="row">
							<span >{{ row.item.itemdesc }}</span>
						</template>

						<template #cell(qty)="row">
							<input
								class="form-control"
								type="number"
								v-model="row.item.qty"
								min="1"
							/>
						</template>

						<template #cell(sell)="row">
							$<input class="form-control w-75" type="number" step="0.01" min="0" v-model="row.item.sell" style="display: inline"> <br>
							<p style="color:gray">
								Minimum without approval:  ${{parseFloat(salesPriceDefault[row.index]).toFixed(2)}}
								<br/>
								List: {{parseFloat(row.item.list).toFixed(2)}}
								<br/>
								Cost per: ${{parseFloat(row.item.highCost).toFixed(2)}}
							</p>
						</template>

						<template #cell(markUp)="row">
							<!-- <input
								class="form-control w-75"
								type="number"
								step="1"
								min="0"
								v-model="row.item.itemMarkup"
								@input="determineSell(row.item)"
								style="display: inline"
							/>% -->
							<selectSell style="display: inline-block" :item="row.item" :customer="selectedCustomer" :pastFun="null" @evaluatedSell="evaluatedSell(...arguments, row.item)"></selectSell>

						</template>

						<template #cell(total)="row">
							${{
								(parseFloat(row.item.sell) * row.item.qty).toFixed(2)
							}}
						</template>

						<template #cell(action)="row">
							<i
								class="fas fa-trash"
								@click="removeLine(row.item,row.index)"
							></i>
						</template>


						<template #bottom-row>
							<template v-if="newLineItem.itemid == 'Special Order'">
								<specialOrder @addSOPart="addSOPart" @closeSO="newLineItem = {}"></specialOrder>
							</template>
							<td>
								<label>Item</label>
								<div class="selectors">
									<multiselect
										id="stepParts"
										class="w-100"
										v-model="newLineItem"
										:options="allParts"
										group-label="name"
										group-values="items"
										:multiple="false"
										track-by="itemid"
                                        :custom-label="({itemid, itemdesc}) => {
                                            if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                        }"
										label="itemdesc"
										placeholder="Parts"
										:show-labels="false"
										>
									</multiselect>
								</div>
							</td>
							<td style="width: 250px"></td>
							<td style="width: 115px">
								<label>Qty</label>
								<input
									class="form-control"
									type="number"
									v-model="newLineQty"
									min="1"
								/>
							</td>
							<td style="width: 115px"></td>
							<td></td>
							<td></td>
							<td>
								<i class="fas fa-plus-circle fa-2x" @click="addLine()" style="margin-top: 20px"></i>
							</td>
						</template>
					</b-table>
				</div>
			</div>

		</div>

    <!-- View Proposal -->
    <div v-if="action === 'viewProposal'">
      <div id="proposal" class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>Proposal</b></h3>
          </div>
        <div class="card-tools" v-if="!printing">
			<b-button class="mx-1" v-if="selectedProposal.status !== 'Closed'" style="background-color: #fff4; border-color: #dc3545" @click="editProp">Edit</b-button>
			<b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="renewProposal()">Renew</b-button>
            <b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="sendProposal()">Send</b-button>
			<b-button
				class="mx-1"
				v-if="selectedProposal.status !== 'Closed'"
				style="background-color: #fff4; border-color: #dc3545"
				@click="propToSales()"
				>Convert to Sales Order
			</b-button>

			<b-button
				v-b-modal.modalProp
				v-if="selectedProposal.status !== 'Closed'"
				class="mx-1"
				style="background-color: #fff4; border-color: #dc3545"
				>Close Proposal
			</b-button>

			<b-modal id="modalProp" title="Reason for Closing" :header-bg-variant="'danger'" @ok="handleOk">
				<form ref="propForm" @submit.stop.prevent="handleSubmit">
					<b-form-group
						label="Reason"
						label-for="input"
						invalid-feedback="Reason is required"
						:state="filledState"
					>
						<b-form-input
							id="input"
							v-model="selectedProposal.reason"
							placeholder="Briefly describe why..."
							:state="filledState"
							required
						>
						</b-form-input>
					</b-form-group>
				</form>
			</b-modal>

			<b-button
				class="mx-1"
				style="background-color: #fff4; border-color: #dc3545"
				@click="action = ''"
				>Return
			</b-button>
		</div>

		<div style="width: 300px">
			<b-table
				class="light my-1 text-center"
				:fields="[
					{ key: 'date' , label: 'Created'},
					{ key: 'localid', label: 'Proposal #' },
				]"
				:items="[{ date: '', localid: -1 }]"
			>
				<template #cell(date)>
					<p>
						{{selectedProposal.starts}}
					</p>
				</template>
				<template #cell(localid)>
					{{ selectedProposal.localid }}
				</template>
			</b-table>
		</div>

        </div>

        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <img class="img w-75 h-75" :src="selectedCompany.logo" />
              </div>
            </div>
            <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
              <!-- COMPANY -->
              <div class="row">
                <address>
                  <p class="h4">{{ selectedCompany.name }}</p>
                  <br />
                  {{ selectedCompany.address }}<br />
                  P: {{ selectedCompany.phone }}
                </address>
              </div>
            </div>
            <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
				<!-- CUSTOMER Bill To-->
				<div class="row">
					<address>
						<p class="h4">{{ selectedProposal.customer.name }}</p>
						<br />
						<div v-if="selectedProposal.billto" class="row">
							<div class="col">
								<label>Bill To:</label><br />
								{{ selectedProposal.billto.title }}
								{{ selectedProposal.billto.name }}<br />
								<hr class="w-75 my-1 ml-1" />
								{{ selectedProposal.billto.address }}<br />
								<hr class="w-75 my-1 ml-1" />
								Tel: {{ selectedProposal.billto.phone }}
							</div>
						</div>
					</address>
				</div>
			</div>
          </div>
		<br>
		<div v-if="selectedProposal.status === 'Closed'">
			<h3><b> Reason for closure: </b>{{selectedProposal.reason}}</h3>
		</div>

          <div class="row text-center border">
            <div class="col pt-2 border">
              <h5><b>Salesman</b></h5>
            </div>
            <div class="col pt-2 border">
              <h5><b>Expires</b></h5>
            </div>
          </div>
          <div class="row text-center border mb-3">
            <div class="col border">
              {{ selectedProposal.salesman }}
            </div>
            <div class="col border">
              {{ selectedProposal.expires }}
            </div>
          </div>


          <b-table
            :fields="propTableFields"
            :items="selectedProposal.lineitems"
			:tbody-tr-class="rowClass"
          >
            <template #cell(sell)="row"> ${{ parseFloat(row.item.sell).toFixed(2) }} </template>

            <template #cell(total)="row"> ${{ parseFloat(row.item.total).toFixed(2) }} </template>

            <template #custom-foot>
              <tr>
                <th v-for="column in Object.keys(propTableFields).length-3" :key="column"></th>
                <th>
                  <h5><b>Total: </b></h5>
                </th>
                <th>${{ proposalTotal }}</th>
              </tr>
            </template>
          </b-table>

          <!-- View attached Leads for Proposal -->
          <div v-if="!printing">

            <label for="attachedLeads" v-if="selectedProposalLeads.length > 0">Leads</label>
            <b-table
                v-if="selectedProposalLeads.length > 0"
                striped
                :fields="propLeadsFields"
                :items="selectedProposalLeads"
            >
                <template #cell(customer)="row">
                {{
                    row.item.localid == selectedProposal.localid
                    ? JSON.parse(row.item.customer).name
                    : ""
                }}
                </template>
                <template #cell(tags)="row">
                <template v-for="item in JSON.parse(row.item.tags)">
                    <div
                    :key="item.tag"
                    :style="{
                        'background-color': item.color,
                        'border-color': item.color,
                    }"
                    style="
                        padding: 10px;
                        border-radius: 20px;
                        color: white;
                        text-align: center;
                        font-weight: bold;
                        display: inline-block;
                        margin-right: 4px;
                    "
                    >
                    {{ item.tag }}
                    </div>
                </template>
                </template>
            </b-table>
          </div>
        </div>
      </div>

    </div>

		<!-- Create Sales Order -->
		<div v-if="action === 'createSO'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Sales Order</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							class="mx-1 my-3"
							style="background-color: #fff4; border-color: #dc3545"
							@click="submitSales()"
							>Submit
						</b-button>
						<b-button
							class="mx-1 my-3"
							style="background-color: #fff4; border-color: #dc3545"
							@click="returnItems(); action = ''; $globals.shield = false"
							>Return
						</b-button>
					</div>
				</div>
				<div class="card-body">

					<div id="salesman">
						<label>Salesman<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<multiselect
							required
							class="w-100 mb-3"
							id="selectedUsers"
							:options="allSalesmen"
							v-model="selectedSalesman"
							:clearable="true"
							track-by="name"
							label="name"
							:multiple="false"
							:show-labels="false"
						></multiselect>
					</div>

					<label for="selectedCustomer">Customer<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
					<multiselect
						required
						class="w-100 mb-3"
						id="selectedCustomer"
						:options="allCustomers"
						v-model="selectedCustomer"
						:clearable="true"
						track-by="name"
						label="name"
						@select="selectCustomer($event); sortLeads();"
						:show-labels="false"
					></multiselect>

					<label for="shipContact">Ship To Contact</label>
					<multiselect
						class="w-100 mb-3"
						id="shipContact"
						:options="allContacts"
						v-model="shipContact"
						:clearable="true"
						track-by="name"
						:custom-label="customLabel"
						:show-labels="false"
					></multiselect>

					<label for="billContact">Bill To Contact<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
					<multiselect
						requried
						class="w-100 mb-3"
						id="billContact"
						:options="allContacts"
						v-model="billContact"
						:clearable="true"
						track-by="name"
						:custom-label="customLabel"
						:show-labels="false"
					></multiselect>

					<label for="leadSelect">Lead</label>
					<multiselect
						class="w-100 mb-3"
						id="leadSelect"
						:options="sortedLeads"
						v-model="selectedLead"
						:clearable="true"
						track-by="localid"
						:custom-label="customLeadLable"
						:show-labels="false"
						@search-change="debounceSortedLeads">
						<template slot="option" slot-scope="props">
			              <intersect v-if="props.option.localid === sortedLeads[sortedLeads.length - 1].localid" @enter="searchSortedLeads">
			                <span>{{props.option.localid}}</span>
			              </intersect>
			            </template>
					</multiselect>

					<label>Expires</label>
					<VueCtkDateTimePicker v-if="$globals.displayClass === false" class="w-100 mb-3" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="expires"/>
					<VueCtkDateTimePicker v-else class="w-100 mb-3" style="width: 70%" color="#dc4535" button-color="#dc4535" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="expires"/>

					<b-table striped :fields="salesTableFields" :items="newLines">
						<template #cell(itemdesc)="row">
							<span>{{ row.item.itemdesc }}</span>
						</template>

						<template #cell(qty)="row">
							<span style="font-size:10pt"> {{row.item.fulfilledQty}} / {{row.item.qty}}</span>
							<i class="fas fa-edit" @click="row.item.locationFlag = true" style="margin-left: 1%; display: inline;"></i>
							<div v-if="row.item.locationFlag">
								<locationSelect :item="row.item" :prevAction="'viewProposal'" @reject="row.item.locationFlag=false" @returnToPrevAction="action = $event" @accept="locatePart(...arguments, row.item)"></locationSelect>
							</div>
						</template>

						<template #cell(markUp)="row">
							<!-- <input
							class="form-control w-75"
							type="number"
							step="1"
							min="0"
							@input="determineSell(row.item)"
							v-model="row.item.itemMarkup"
							style="display: inline"
							/>% -->
							<selectSell style="display: inline-block" :item="row.item" :customer="selectedCustomer" :pastFun="row.item.sellRate" @evaluatedSell="evaluatedSell(...arguments, row.item)"></selectSell>

						</template>

						<template #cell(sell)="row">
							$ <input
							class="form-control w-75"
							type="number"
							step="0.01"
							v-model="row.item.sell"
							style="display: inline"
							/>
							<p style="color:gray">Minimum without approval:  ${{parseFloat(salesPriceDefault[row.index]).toFixed(2)}}
								<br/>
								Cost per: ${{parseFloat(row.item.highCost).toFixed(2)}}
							</p>
						</template>

						<template #cell(total)="row">
							${{ (parseFloat(row.item.sell) * row.item.qty).toFixed(2) }}
						</template>

						<template #cell(action)="row">
							<i
							class="fas fa-trash"
							@click="removeLine(row.item, row.index)"
							></i>
						</template>

						<template #bottom-row>
							<template v-if="newLineItem.itemid == 'Special Order'">
								<specialOrder @addSOPart="addSOPart" @closeSO="newLineItem = {}"></specialOrder>
							</template>
							<td>
								<label>Item</label>
								<multiselect
									id="stepParts"
									:clearable="true"
									v-model="newLineItem"
									:options="allParts"
									group-label="name"
									group-values="items"
									:group-select="false"
									:multiple="false"
									track-by="itemid"
                                    :custom-label="({itemid, itemdesc}) => {
                                        if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                    }"
									label="itemdesc"
									placeholder="Parts"
									:show-labels="false"
									>
								</multiselect>
							</td>
							<td style="width: 250px"></td>
							<td style="width: 115px">
								<label>Qty</label>
								<input
									class="form-control w-75"
									type="number"
									v-model="newLineQty"
								/>
							</td>
							<td style="width: 115px"></td>
							<td></td>
							<td>
								<i
								style="margin-top: 20px"
								class="fas fa-plus-circle fa-2x"
								@click="addLine()"
								></i>
							</td>
						</template>
					</b-table>
				</div>
			</div>

		</div>

		<!-- New Shipment -->
    <div v-if="action === 'newShipment'">
        <new-shipment :customer="{...selectedSalesOrder.shipto, custName: selectedSalesOrder.customer.name}" :source="selectedCompany" :items="selectedLineItems" @addShippingToOrder="addShippingToOrder" @returnFromNewShipment="action = ''; selectedLineItems = []; showLineSelect = false"></new-shipment>
    </div>

    <!-- View Sales Order -->
	<div v-if="action === 'viewSalesOrder'">
		<div id="salesOrder" class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Sales Order</b></h3>
				</div>
				<div class="card-tools" v-if="!printing">
                    <b-button
						v-if="showAll === true"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545;"
						@click="showAll=false; sendSO()"
						>Send Order
					</b-button>
					<b-button
						v-if="!showLineSelect && showAll === true"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="showLineSelect = true; typeOfSelect = 'invoice'; computedFields();showAll=false"
						>Invoice
					</b-button>
					<b-button
						v-if="showLineSelect && typeOfSelect === 'invoice'"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="showLineSelect = false; showAll=false; sendInvoice()"
						>Send Invoice
					</b-button>
					<b-button
						v-if="showLineSelect && typeOfSelect === 'invoice'"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="showLineSelect = false; stopInvoice()"
						>Stop Invoice
					</b-button>
					<b-button
						class="mx-1 my-3"
						v-if="selectedSalesOrder.status !== 'Closed' && showAll === true"
						style="background-color: #fff4; border-color: #dc3545"
						@click="editSalesOrder();showAll=false">Edit
					</b-button>

					<b-button
						v-b-modal.modalProp
						v-if="selectedSalesOrder.status !== 'Closed' && showAll === true"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						>Archive
					</b-button>

                    <b-button
                        v-if="!showLineSelect && showAll === true"
                        class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
                        @click="showLineSelect=true; typeOfSelect = 'shipping'; computedFields();showAll=false"
                        >
                        Ship Order
                    </b-button>

					<b-button
						v-if="!showLineSelect && showAll === true"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="showLineSelect=true; typeOfSelect = 'purchaseOrder'; computedFields();showAll=false"
						>
						Make PO
					</b-button>

					<b-button v-if="showLineSelect && typeOfSelect === 'purchaseOrder'"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="salesOrderToPurchaseOrder()"
						>Create the PO
					</b-button>

                    <b-button
                        v-if="showLineSelect && typeOfSelect === 'shipping'"
                        class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
                        @click="startNewShipment()"
                        >
                        Get Label
                    </b-button>

                    <b-button
                        v-if="showLineSelect"
                        class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
                        @click="showLineSelect = false; computedFields();showAll=true"
                        >
                        Cancel
                    </b-button>

					<b-modal id="modalProp" title="Reason for Closing" :header-bg-variant="'danger'" @ok="handleOk">
						<form ref="propForm" @submit.stop.prevent="handleSubmit">
							<b-form-group
								label="Reason"
								label-for="input"
								invalid-feedback="Reason is required"
								:state="filledState"
							>
								<b-form-input
									id="input"
									v-model="selectedSalesOrder.reason"
									:state="filledState"
									required
								>
								</b-form-input>
							</b-form-group>
						</form>
					</b-modal>

					<b-button
						v-if="showAll === true"
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="action = '';stopInvoice(); showLineSelect = false; computedFields(); selectedSalesOrder={};showAll=true"
						>Return
					</b-button>
				</div>
				<div style="width: 300px">
					<b-table
						class="light my-1 text-center"
						:fields="[{ key: 'date' }, { key: 'localid', label: 'Order #' }]"
						:items="[
						{
							date: selectedSalesOrder.starts,
							localid: selectedSalesOrder.localid,
						}]">
					</b-table>
				</div>
			</div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <img class="img w-75 h-75" :src="selectedCompany.logo" />
              </div>
            </div>
            <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
              <!-- COMPANY -->
              <div class="row">
                <address>
                  <p class="h4">{{ selectedCompany.name }}</p>
                  <br />
                  {{ selectedCompany.address }}<br />
                  P: {{ selectedCompany.phone }}
                </address>
              </div>
            </div>
            <div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
              <!-- CUSTOMER -->
              <div class="row">
                <address>
                  <p class="h4">{{ selectedSalesOrder.customer.name }}</p>
                  <br />
                  <div class="row">
                    <div class="col">
                      <label>Bill To:</label><br />
                      {{ selectedSalesOrder.billto.title }}
                      {{ selectedSalesOrder.billto.name }}<br />
                      <hr class="w-75 my-1 ml-1" />
                      {{ selectedSalesOrder.billto.address }}<br />
                      <hr class="w-75 my-1 ml-1" />
                      P: {{ selectedSalesOrder.billto.phone }}
                    </div>
                  </div>
                </address>
              </div>
            </div>
            <div
              class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
            >
              <div class="row">
                <address>
                  <p class="h4">{{ selectedSalesOrder.customer.name }}</p>
                  <br />
                  <label>Ship To:</label><br />
                  {{ selectedSalesOrder.shipto.title }}
                  {{ selectedSalesOrder.shipto.name }}<br />
                  <hr class="w-75 my-1 ml-1" />
                  {{ selectedSalesOrder.shipto.address }}<br />
                  <hr class="w-75 my-1 ml-1" />
                  P: {{ selectedSalesOrder.shipto.phone }}
                </address>
              </div>
            </div>
          </div>
          <div class="row text-center border">
            <div class="col pt-2 border">
              <h5><b>Salesman</b></h5>
            </div>
            <div class="col pt-2 border">
              <h5><b>Expires</b></h5>
            </div>
            <!-- <div class="col pt-2 border">
              <h5><b>Estimated Ship Date</b></h5>
            </div> -->
            <div class="col pt-2 border">
              <h5><b>Freight</b></h5>
            </div>
          </div>
          <div class="row text-center border mb-3">
            <div class="col border">
              {{ selectedSalesOrder.salesman }}
            </div>
            <div class="col border">
              {{ selectedSalesOrder.expires}}
            </div>
            <!-- <div class="col border">
              {{ selectedSalesOrder.expires}}
            </div> -->
            <div class="col border">
              {{
                selectedSalesOrder.freight
                  ? selectedSalesOrder.freight
                  : "Not Included"
              }}
            </div>
          </div>

          <b-table
            :fields="salesTableFields"
            :items="selectedSalesOrder.lineitems"
			:tbody-tr-class="rowClass"
          >

			<template #cell(qty)="row">
                <div  v-if="row.item.itemid !== 'SHIPPING'">
                    {{row.item.fulfilledQty}} / {{row.item.qty}}
                </div>
                <div v-else>
                    {{row.item.qty}}
                </div>
			</template>

            <template #cell(sell)="row">
              ${{ parseFloat(row.item.sell).toFixed(2) }}
            </template>

            <template #cell(total)="row">
              ${{ parseFloat(row.item.total).toFixed(2) }}
            </template>

            <template #cell(select)="row">
				<div v-if="row.item.approval === 'Pending'">
					<strong class="text-danger">Pending Approval</strong>
				</div>
				<div v-else-if="typeOfSelect === 'shipping'">
					<div v-if="row.item.shipped">
						<strong class="text-danger"><i>Shipped</i></strong>
					</div>
					<div v-else-if="row.item.itemid !== 'SHIPPING'">
						<input v-if="(row.item.invoiced||0 < row.item.qty)" type="checkbox" class="form-control danger" @change="updateSelectedLines(row.item)"/>
					</div>
					<!--else show nothing as it would be itemid=SHIPPING-->
				</diV>
				<div v-else-if="typeOfSelect === 'purchaseOrder'">
					<div v-if="row.item.itemid !== 'SHIPPING'">
						<div v-if="row.item.notReceivedFlag">
							<strong v-if="row.item.type === 'Special'"><i>Awaiting Purchase Order</i></strong>
							<strong v-else class="text-danger"><i>Open Purchase Order</i></strong>
						</div>
						<input v-else-if="(row.item.invoiced||0 < row.item.qty) && (row.item.vendor === selectedVendor || selectedVendor === null)" type="checkbox" class="form-control danger" @change="updateSelectedLines(row.item)"/>
					</div>
				</div>
				<div v-else-if="typeOfSelect === 'invoice'">
					<p v-if="row.item.notReceivedFlag "><strong class="text-danger"><i>Open Purchase Order</i></strong></p>
					<div v-else-if="row.item.invoiced||0 < row.item.qty">
						<input type="checkbox" class="form-control danger" @change="updateSelectedLines(row.item)"/>
					</div>
					<div v-else-if="row.item.invoiced === row.item.qty">
						<strong class="text-danger"><i>Invoiced</i></strong>
					</div>

				</div>
            </template>

            <template #custom-foot>
              <tr>
                <th v-for="(header, index) in salesTableFields.slice(0,-3)" :key="index"></th>
                <th>
					<h5><b>Total: </b></h5>
					<p v-if="showLineSelect" style="color:gray" >Selected Total</p>
                </th>
                <th>
					${{ parseFloat(salesOrderTotal).toFixed(2) }}<br>
					<p v-if="showLineSelect" style="color:gray" >${{ approvedSalesOrderTotal }}</p>
				</th>
              </tr>
            </template>
          </b-table>

			<div v-if="relatedPO.length > 0 && !printing">
				<h4>Related Purchase Orders</h4>
				<b-table
					:fields="[{key: 'localid', label: 'PO ID'}, {key:'vendor'}, {key:'data', label: 'Items in PO'}, {key:'status'}]"
					:items="relatedPO"
				>
					<template #cell(vendor)="row">
						{{typeof row.item.vendor !== "object" ? JSON.parse(row.item.vendor).company : row.item.vendor.company}}
					</template>

					<template #cell(data)="row">
						<div
							v-for="(item, index) in (typeof row.item.data !=='object' ? JSON.parse(row.item.data) : row.item.data)"
							v-bind:key="index"
						>
							{{item.itemdesc}} | <b>Qty:</b> {{item.qty}}
							<b-table v-if="item.notReceivedFlag === false && item.receivedQty !== undefined" :items="item.receivedQty" :fields="receivedTableFields"></b-table>
							<!-- <p v-if="!item.notReceivedFlag" class="text-danger"><i>(Received)</i></p> -->
						</div>
					</template>

				</b-table>
			</div>

		</div>
      </div>

    </div>

    <!-- Create Leads Screen -->
    <div v-if="action === 'createLeads'">
      <div class="card card-danger">
        <div class="card-header">
			<div class="card-title">
				<h3><b>New Leads</b></h3>
			</div>
			<div class="card-tools">
				<b-button
					class="mx-1 my-3"
					style="background-color: #fff4; border-color: #dc3545"
					@click="submitLead()"
					>Submit
				</b-button>
				<b-button
					class="mx-1 my-3"
					style="background-color: #fff4; border-color: #dc3545"
					@click="action = 'viewLeads'"
					>Return
				</b-button>
			</div>
        </div>
        <div class="card-body">
          <div id="salesman">
            <label>Salesman<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
			<multiselect
				class="w-100 mb-3"
				id="selectedUsers"
				:options="allSalesmen"
				v-model="selectedSalesman"
				:clearable="true"
				track-by="name"
				label="name"
				:multiple="false"
				:show-labels="false"
				requried
			></multiselect>
          </div>
          <label for="selectedCustomer" style="margin-top: 10px"
            >Customer<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label
          >
          <multiselect
            class="w-100 mb-3"
            id="selectedCustomer"
            :options="allCustomers"
            v-model="selectedCustomer"
            :clearable="true"
            track-by="name"
            label="name"
            @select="selectCustomer($event)"
			:show-labels="false"
		  	requried
          ></multiselect>
          <label for="lead-input" style="margin-top: 10px">Lead<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
          <textarea
            type="text"
            style="
              width: 100%;
              height: 160px;
              border-radius: 8px;
              margin-top: 2px;
              border-color: grey;
              text-align: left;
            "
            class="lead-input"
            id="lead-input"
            v-model="leadInput"
			required
          ></textarea>
          <label for="lead-input" style="margin-top: 10px">Users</label>
          <multiselect
            class="w-100 mb-3"
            id="selectedUsers"
            :options="allUsers"
            v-model="selectedUsers"
            :clearable="true"
            track-by="name"
            label="name"
            :multiple="true"
			:show-labels="false"
          ></multiselect>
          <div>
            <label for="startDate" style="margin-top: 10px">Start Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label
            ><br />
            <input
              type="date"
              id="startDate"
              name="startDate"
              v-model="leadStartDate"
			  required
            />
          </div>
          <label for="tagInput" style="margin-top: 10px">Tags</label>
          <div style="display: flex; align-items: center">
            <input
              type="text"
              class="form-control"
              placeholder="add tag"
              v-model="tagInput"
              style="margin-right: 20px; text-align: left; width: 85%"
            />
            <v-swatches
              v-model="tagColor"
              shapes="circles"
              popover-x="left"
            ></v-swatches>
            <i
              style="margin-top: 20px; margin-left: 20px; margin-top: 10px"
              class="fas fa-plus-circle fa-2x"
              @click="addTag()"
            ></i>
          </div>
          <div style="display: flex">
            <!-- Tag Buttons -->
            <b-button
              v-for="item in tags"
              :key="item.tag"
              style="margin-top: 10px; margin-right: 10px; height: 40px"
              :style="{
                'background-color': item.color,
                'border-color': item.color,
              }"
            >
              <i class="fas fa-times"></i>
              <span style="margin-left: 2px">{{ item.tag }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Map Screen -->
    <div v-if="action === 'createMap'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>Map</b></h3>
          </div>
          <div class="card-tools" v-if="canEditMap">
            <b-button
              class="mx-1 my-3"
              style="background-color: #fff4; border-color: #dc3545"
              @click="editingMap = !editingMap"
              v-if="!editingMap"
              >Edit</b-button
            >
            <b-button
              class="mx-1 my-3"
              style="background-color: #fff4; border-color: #dc3545"
              @click="
                editingMap = !editingMap;
                submitMapChanges();
              "
              v-if="editingMap"
              >Submit</b-button
            >
			<b-button
				style="background-color: #fff4; border-color: #dc3545"
				@click="action = ''"
				>Return
			</b-button>
          </div>
        </div>
        <div class="card-body">
          <div style="display: flex; margin-top: -1%; margin-left: 1%">
            <div
              id="filterOptions"
              v-if="!editingMap"
              style="width: 50%; flex: 1"
            >
              <b-button
                class="mx-1 my-3"
                style="background-color: #dc3545; border-color: #dc3545"
                @click="getMapData('SALESMAN')"
                >Territories</b-button
              >
              <b-button
                class="mx-1 my-3"
                style="background-color: #dc3545; border-color: #dc3545"
                @click="getMapData('PROPOSALS')"
                >Proposals</b-button
              >
              <b-button
                class="mx-1 my-3"
                style="background-color: #dc3545; border-color: #dc3545"
                @click="getMapData('SALES')"
                >Sales Orders</b-button
              >
              <b-button
                class="mx-1 my-3"
                style="background-color: #dc3545; border-color: #dc3545"
                @click="getMapData('PROFIT')"
                >Profit</b-button
              >
            </div>
            <div id="selectors" style="flex: 1; width: 50%">
              <multiselect
                :options="allStates"
                :multiple="true"
                v-model="selectedStates"
                label="name"
                track-by="name"
                @input="createMap"
                style="width: 92.5%; margin-top: 3%"
				:show-labels="false"
              ></multiselect>
            </div>
          </div>
          <div
            id="legend"
            style="width: 61%; margin-left: 1%"
            v-show="mapFilter != 'SALESMAN'"
          >
            <div
              style="
                background: linear-gradient(
                  0.75turn,
                  hsl(119, 85%, 58%) 10%,
                  hsl(2, 70%, 37%)
                );
                width: 50%;
                border-radius: 10px;
                border: 1px solid black;
              "
            >
              <span
                style="
                  color: white;
                  margin-left: 2%;
                  font-weight: bold;
                  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000,
                    -1px 1px 0 #000, 1px 1px 0 #000;
                "
                >{{
                  mapFilter == "PROFIT" ? "$" + dataMin.toFixed(2) : dataMin
                }}</span
              >
              <span
                style="
                  color: white;
                  font-weight: bold;
                  margin-right: 2%;
                  float: right;
                  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000,
                    -1px 1px 0 #000, 1px 1px 0 #000;
                  z-index: 1;
                "
                >{{
                  mapFilter == "PROFIT" ? "$" + dataMax.toFixed(2) : dataMax
                }}</span
              ><span
                id="data-caret"
                style="
                  color: white;
                  z-index: 1000;
                  display: inline-block;
                  width: 10px;
                "
                >&#x25c6;</span
              >
            </div>
          </div>
          <div class="countytooltip" v-show="hoveredCounty != ''">
            {{ hoveredCounty }}
          </div>
          <div id="editOptions" v-if="editingMap">
            <!--@select="updateSalesman()"-->
            <multiselect
				:options="allSalesmen"
				label="name"
				:clearable="true"
				:searchable="true"
				track-by="name"
				v-model="selectedSalesman"
				style="width: 45%; display: inline-block"
				@select="updateSalesman()"
				:show-labels="false"
            ></multiselect>
            <input
              type="color"
              style="width: 45%"
              v-model="selectedSalesman.color"
              v-if="selectedSalesman != {}"
            />
            <!-- @input="editedSalesmen.push(this.selectedSalesman)" -->
          </div>
          <div ref="map" id="map" class="map" style="height: 500px"></div>
        </div>
      </div>
    </div>

	<!-- View all Leads -->
    <div v-if="action === 'viewLeads'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>View Leads</b></h3>
          </div>
			<div class="card-tools">
				<b-button
					style="background-color: #fff4; border-color: #dc3545"
					@click="createLeads()"
					>New Lead
				</b-button>

				<b-button
					class="mx-1 my-3"
					style="background-color: #fff4; border-color: #dc3545"
					@click="action = ''"
					>Return
				</b-button>
			</div>
        </div>
        <div class="searchBar" style="margin-bottom: 1%; margin-top: 1%">
          <input
            type="text"
            class="form-control"
            placeholder="Search by Customer, Salesman, or Tags"
            v-model="allLeadsSearchText"
          />
        </div>
        <!-- search.leads -->
        <b-table
			ref="leadsTable"
			:filter="this.search.leads"
			:filter-included-fields="['customer','salesman', 'tags']"
			striped
			sticky-header
			:busy="isBusy"
			:items="leads"
			:fields="[
				{ key: 'salesman', label: 'Salesman', sortable: true },
				{ key: 'customer', label: 'Customer', sortable: true },
				{ key: 'tags' },
			]"
			style="max-height: 700px;"
          @row-clicked="viewLead"
        >
          <template #cell(customer)="row">
            {{
              row.item.customer != null
                ? typeof row.item.customer != "object"
                  ? JSON.parse(row.item.customer).name
                  : row.item.customer.name
                : ""
            }}
          </template>
          <template #cell(tags)="row">
          	<intersect v-if="row.item.localid === leads[leads.length - 1].localid" @enter="getMoreLeads">
            <template v-for="item in JSON.parse(row.item.tags)">
              <div
                :key="item.tag"
                :style="{
                  'background-color': item.color,
                  'border-color': item.color,
                }"
                style="
                  padding: 10px;
                  border-radius: 20px;
                  color: white;
                  text-align: center;
                  font-weight: bold;
                  display: inline-block;
                  margin-right: 4px;
                "
              >
                {{ item.tag }}
              </div>
            </template>
            </intersect>
          </template>
        </b-table>
      </div>

    </div>

    <!-- View Specific Lead -->
	<div v-if="action === 'lead'">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Lead</b></h3>
				</div>
				<div class="card-tools">
					<!--
					TODO convert to edit button
					<b-button
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="attachLeadProposal()"
						>Attach
					</b-button>
					-->
					<b-button
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="action = 'viewLeads'"
						>Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<label for="Salesman" style="margin-top: 10px">Salesman</label>
				<p>{{ selectedLead.salesman }}</p>
				<label for="leadName" style="margin-top: 10px">Customer</label>
				<p>{{ JSON.parse(selectedLead.customer).name }}</p>
				<label for="lead" style="margin-top: 10px">Lead</label>
				<p>{{ selectedLead.leads }}</p>
				<label v-if="selectedLead.propid !== null" style="margin-top: 10px">Proposal ID </label> <br>
                <p><span class="order-link" @click="getProposal(selectedLead.propid)">{{selectedLead.propid}}</span></p>
				<label v-if="selectedLead.salesid !== null" style="margin-top: 10px">Sales Order ID</label> <br>
                <p class="order-link">{{selectedLead.salesid}}</p>
				<label for="leadTag" style="margin-top: 10px">Tags</label><br />
				<template v-for="item in JSON.parse(selectedLead.tags)">
					<div
						:key="item.tag"
						:style="{
							'background-color': item.color,
							'border-color': item.color,
							}"
						style="
							padding: 10px;
							border-radius: 20px;
							color: white;
							text-align: center;
							font-weight: bold;
							display: inline-block;
							margin-right: 4px;
						">
						{{ item.tag }}
					</div>
				</template>
			</div>
		</div>

    </div>

	<!-- Edit Specific Lead -->
	<div v-if="action === 'editLead'">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Lead</b></h3>
				</div>
				<div class="card-tools">
					<b-button
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="attachLeadProposal()"
						>Attach
					</b-button>
					<b-button
						class="mx-1 my-3"
						style="background-color: #fff4; border-color: #dc3545"
						@click="action = 'viewLeads'"
						>Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<label for="Salesman" style="margin-top: 10px">Salesman</label>
				<p>{{ $globals.user.name }}</p>
				<label for="leadName" style="margin-top: 10px">Customer</label>
				<p>{{ JSON.parse(selectedLead.customer).name }}</p>
				<label for="lead" style="margin-top: 10px">Lead</label>
				<p>{{ selectedLead.leads }}</p>
				<label for="leadTag" style="margin-top: 10px">Tags</label><br />
				<template v-for="item in JSON.parse(selectedLead.tags)">
					<div
						:key="item.tag"
						:style="{
							'background-color': item.color,
							'border-color': item.color,
						}"
						style="
							padding: 10px;
							border-radius: 20px;
							color: white;
							text-align: center;
							font-weight: bold;
							display: inline-block;
							margin-right: 4px;"
						>
						{{ item.tag }}
					</div>
				</template>
				<br />
				<label for="proposal" style="margin-top: 10px">Proposal</label>
				<multiselect
				class="w-100 mb-3"
				id="selectedProposal"
				:options="openProposals"
				v-model="selectedProposal"
				:clearable="true"
				track-by="name"
				label="localid"
				:show-labels="false"
				></multiselect>
			</div>
		</div>

	</div>

		<!-- Edit Proposal -->
		<div v-if="action === 'editProposal'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Proposal</b></h3>
					</div>
					<div class="card-tools">
						<b-button class="mx-1" style="background-color: #fff4; border-color: #dc3545" @click="updateProposal()">Update</b-button>
						<b-button
							class="mx-1"
							style="background-color: #fff4; border-color: #dc3545"
							@click="action = 'viewProposal'; computedPropFields(); clearData()"
							>Return
						</b-button>
					</div>

					<div style="width: 300px">
						<b-table
						class="light my-1 text-center"
						:fields="[
							{ key: 'date' , label: 'Created'},
							{ key: 'localid', label: 'Proposal #' },
						]"
						:items="[{ date: '', localid: -1 }]"
						>
							<template #cell(date)>
								<p>{{selectedProposal.starts}}</p>
							</template>
							<template #cell(localid)>
								{{ selectedProposal.localid }}
							</template>
						</b-table>
					</div>
				</div>

				<div class="card-body">
					<div class="row">
						<div class="col">
							<div class="row">
								<img class="img w-75 h-75" :src="selectedCompany.logo" />
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- COMPANY -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedCompany.name }}</p>
									<br />
									{{ selectedCompany.address }}<br />
									Tel: {{ selectedCompany.phone }}
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- CUSTOMER Bill To-->
							<div class="row">
								<address>
									<p class="h4">{{ selectedProposal.customer.name }}</p>
									<br />
									<div v-if="selectedProposal.billto" class="row">
										<div class="col">
											<label>Bill To:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br />
											<multiselect
												required
												class="w-100"
												id="billContact"
												:options='allContacts'
												v-model="billContact"
												:clearable="true"
												track-by="name"
												:custom-label="customLabel"
												:show-labels="false"
												>
											</multiselect> <br/>
											<hr class="w-75 my-1 ml-1" />
											Tel: {{ billContact.phone }}
										</div>
									</div>
								</address>
							</div>
						</div>
					</div>

					<div class="row text-center border">
						<div class="col pt-2 border">
							<h5><b>Salesman<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Expires<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></h5>
						</div>
					</div>
					<div class="row text-center border mb-3">
						<div class="col border">
							<multiselect
								required
								class="w-75"
								id="selectedUsers"
								:options="allSalesmen"
								v-model="selectedSalesman"
								:clearable="true"
								track-by="name"
								label="name"
								:multiple="false"
								:show-labels="false"
							></multiselect>
						</div>
						<div class="col border">
							<VueCtkDateTimePicker requried v-if="$globals.displayClass === false" class="w-100 mb-3" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedProposal.expires"/>
							<VueCtkDateTimePicker requried v-else class="w-100 mb-3" style="width: 70%" color="#dc4535" button-color="#dc4535" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedProposal.expires"/>
						</div>
					</div>

					<b-table striped :fields="propTableFields" :items="newLines">

						<template #cell(itemdesc)="row">
							<span >{{ row.item.itemdesc }}</span>
						</template>

						<template #cell(qty)="row">
							<input
								class="form-control"
								type="number"
								v-model="row.item.qty"
								min="1"
							/>
						</template>

						<template #cell(sell)="row">
							$<input class="form-control w-75" type="number" step="0.01" min="0" v-model="row.item.sell" style="display: inline">
							<p style="color:gray">Minimum without approval:  ${{parseFloat(salesPriceDefault[row.index]).toFixed(2)}}
								<br/>
								Cost per: ${{parseFloat(row.item.highCost).toFixed(2)}}
							</p>
						</template>

						<template #cell(markUp)="row">
							<!-- <input
								class="form-control w-75"
								type="number"
								step="1"
								min="0"
								v-model="row.item.itemMarkup"
								@input="determineSell(row.item)"
								style="display: inline"
							/>% -->
							<selectSell style="display: inline-block" :item="row.item" :customer="selectedCustomer" :pastFun="row.item.sellRate" @evaluatedSell="evaluatedSell(...arguments, row.item)"></selectSell>

						</template>

						<template #cell(total)="row">
							${{
								(parseFloat(row.item.sell) * row.item.qty).toFixed(2)
							}}
						</template>

						<template #cell(action)="row">
							<i
								class="fas fa-trash"
								@click="removeLine(row.item, row.index)"
							></i>
						</template>


						<template #bottom-row>
							<template v-if="newLineItem.itemid == 'Special Order'">
								<specialOrder @addSOPart="addSOPart" @closeSO="newLineItem = {}"></specialOrder>
							</template>
							<td>
								<label>Item</label>
								<div class="selectors">
									<multiselect
										id="stepParts"
										class="w-100"
										:clearable="true"
										v-model="newLineItem"
										:options="allParts"
										group-label="name"
										group-values="items"
										:multiple="false"
										track-by="itemid"
                                        :custom-label="({itemid, itemdesc}) => {
                                            if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                        }"
										label="itemdesc"
										placeholder="Parts"
										:show-labels="false"
										>
									</multiselect>
								</div>
							</td>
							<td style="width: 250px"></td>
							<td style="width: 115px">
								<label>Qty</label>
								<input
									class="form-control"
									type="number"
									v-model="newLineQty"
									min="1"
								/>
							</td>
							<td style="width: 115px"></td>
							<td></td>
							<td></td>
							<td>
								<i class="fas fa-plus-circle fa-2x" @click="addLine()" style="margin-top: 20px"></i>
							</td>
						</template>
					</b-table>
				</div>
			</div>

		</div>

		<!-- Edit Sales Order -->
		<div v-if="action === 'editSalesOrder'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Sales Order</b></h3>
					</div>
					<div class="card-tools">
						<b-button class="mx-1 my-3" style="background-color: #fff4; border-color: #dc3545" @click="updateSalesOrder();showAll=true">Update</b-button>
						<b-button
							class="mx-1 my-3"
							style="background-color: #fff4; border-color: #dc3545"
							@click="action = 'viewSalesOrder';returnItems();showAll=true;computedFields()"
							>Return
						</b-button>
					</div>

					<div style="width: 300px">
						<b-table
						class="light my-1 text-center"
						:fields="[
							{ key: 'date' , label: 'Created'},
							{ key: 'localid', label: 'Proposal #' },
						]"
						:items="[{ date: '', localid: -1 }]"
						>
							<template #cell(date)>
								<p>{{selectedSalesOrder.starts}}</p>
							</template>
							<template #cell(localid)>
								{{ selectedSalesOrder.localid }}
							</template>
						</b-table>
					</div>
				</div>

				<div class="card-body">
					<div class="row">
						<div class="col">
							<div class="row">
								<img class="img w-75 h-75" :src="selectedCompany.logo" />
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- COMPANY -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedCompany.name }}</p>
									<br />
									{{ selectedCompany.address }}<br />
									Tel: {{ selectedCompany.phone }}
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- CUSTOMER Bill To-->
							<div class="row">
								<address>
									<p class="h4">{{ selectedSalesOrder.customer.name }}</p>
									<br />
									<div class="row">
										<div class="col">
											<label>Bill To:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br />
											<multiselect
												required
												class="w-100"
												id="billContact"
												:options='allContacts'
												v-model="billContact"
												:clearable="true"
												track-by="name"
												:custom-label="customLabel"
												:show-labels="false"
												>
											</multiselect> <br/>
											<hr class="w-75 my-1 ml-1" />
											Tel: {{ billContact.phone }}
										</div>
									</div>
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- CUSTOMER Ship To-->
							<div class="row">
								<address>
									<p class="h4">{{ selectedSalesOrder.customer.name }}</p>
									<br />
									<div class="row">
										<div class="col">
											<label>Ship To:</label><br />
											<multiselect
												class="w-100"
												id="billContact"
												:options='allContacts'
												v-model="shipContact"
												:clearable="true"
												track-by="name"
												:custom-label="customLabel"
												:show-labels="false"
												>
											</multiselect> <br/>
											<hr class="w-75 my-1 ml-1" />
											Tel: {{ shipContact.phone }}
										</div>
									</div>
								</address>
							</div>
						</div>
					</div>

					<div class="row text-center border">
						<div class="col pt-2 border">
							<h5><b>Salesman<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Expires<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></h5>
						</div>
						<!-- <div class="col pt-2 border">
							<h5><b>Estimated Ship Date</b></h5>
						</div> -->
						<div class="col pt-2 border">
							<h5><b>Freight</b></h5>
						</div>
					</div>
					<div class="row text-center border mb-3">
						<div class="col border">
							<multiselect
								required
								class="w-75"
								id="selectedUsers"
								:options="allSalesmen"
								v-model="selectedSalesman"
								:clearable="true"
								track-by="name"
								label="name"
								:multiple="false"
								:show-labels="false"
							></multiselect>
						</div>
						<div class="col border">
							<VueCtkDateTimePicker required v-if="$globals.displayClass === false" class="w-100 mb-3" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedSalesOrder.expires"/>
							<VueCtkDateTimePicker required v-else class="w-100 mb-3" style="width: 70%" color="#dc4535" button-color="#dc4535" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedSalesOrder.expires"/>
						</div>
						<!-- <div class="col border">
							<VueCtkDateTimePicker v-if="$globals.displayClass === false" class="w-100 mb-3" style="width: 70%" color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedSalesOrder.expires"/>
							<VueCtkDateTimePicker v-else class="w-100 mb-3" style="width: 70%" color="#dc4535" button-color="#dc4535" minute-interval="15" format="YYYY-MM-DD HH:mm A" formatted="ll" v-model="selectedSalesOrder.expires"/>
						</div> -->
						<div class="col border">
							{{ selectedSalesOrder.freight ? selectedSalesOrder.freight: "Not Included" }}
						</div>
					</div>

					<b-table striped :fields="salesTableFields" :items="newLines">

						<template #cell(itemdesc)="row">
							<span >{{ row.item.itemdesc }}</span>
							<div v-if="row.item.invoiced === row.item.qty">
								<br>
								<em style="color:#dc3545">Invoiced</em>
							</div>
						</template>

						<template #cell(qty)="row">
							<div v-if="row.item.invoiced === row.item.qty || row.item.itemid === 'SHIPPING'">
								{{row.item.qty}}
							</div>
							<div v-else>
								<span> {{row.item.fulfilledQty}} / {{row.item.qty}}</span>
								<i class="fas fa-edit" @click="row.item.locationFlag = true" style="margin-left: 1%; display: inline;"></i>
								<div v-if="row.item.locationFlag">
									<locationSelect :item="row.item" @reject="row.item.locationFlag=false" @accept="locatePart(...arguments, row.item)"></locationSelect>
								</div>
							</div>
						</template>

						<template #cell(sell)="row">
							<div v-if="row.item.invoiced === row.item.qty">
								${{parseFloat(row.item.sell).toFixed(2)}}
							</div>
							<div v-else>
								$<input class="form-control w-75" type="number" step="0.01" min="0" v-model="row.item.sell" style="display: inline">
								<p style="color:gray">Minimum without approval:  ${{parseFloat(salesPriceDefault[row.index]).toFixed(2)}}
									<br/>
									Cost per: ${{parseFloat(row.item.highCost).toFixed(2)}}
								</p>
							</div>
						</template>

						<template #cell(markUp)="row">
							<div v-if="row.item.invoiced === row.item.qty">
								<!-- {{parseFloat(row.item.markUp).toFixed(2)}}% -->
								Rate used: {{row.item.sellRate.name}}
							</div>
							<div v-else>
								<!-- <input
									class="form-control w-75"
									type="number"
									step="1"
									min="0"
									v-model="row.item.itemMarkup"
									@input="determineSell(row.item)"
									style="display: inline"
								/>% -->
								<selectSell style="display: inline-block" :item="row.item" :customer="selectedCustomer" :pastFun="row.item.sellRate" @evaluatedSell="evaluatedSell(...arguments, row.item)"></selectSell>

							</div>
						</template>

						<template #cell(total)="row">
							${{
								(parseFloat(row.item.sell) * row.item.qty).toFixed(2)
							}}
						</template>

						<template #cell(action)="row">
							<i v-if="row.item.invoiced < row.item.qty"
								class="fas fa-trash"
								@click="removeLine(row.item, row.index)"
							></i>
						</template>

						<template #bottom-row>
							<template v-if="newLineItem.itemid == 'Special Order'">
								<specialOrder @addSOPart="addSOPart" @closeSO="newLineItem = {}"></specialOrder>
							</template>
							<td>
								<label>Item</label>
								<multiselect
									id="stepParts"
									:clearable="true"
									v-model="newLineItem"
									:options="allParts"
									group-label="name"
									group-values="items"
									:multiple="false"
									track-by="itemid"
                                    :custom-label="({itemid, itemdesc}) => {
                                        if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
                                    }"
									label="itemdesc"
									placeholder="Parts"
									:show-labels="false"
									>
								</multiselect>
							</td>
							<td style="width: 250px"></td>
							<td style="width: 115px">
								<label>Qty</label>
								<input
									class="form-control w-75"
									type="number"
									v-model="newLineQty"
								/>
							</td>
							<td style="width: 115px"></td>
							<td></td>
							<td></td>
							<td>
								<i
								style="margin-top: 20px"
								class="fas fa-plus-circle fa-2x"
								@click="addLine()"
								></i>
							</td>
						</template>

					</b-table>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
/*eslint-disable*/
	import axios from "axios";
	import { Socket } from "socket.io-client";
	import Multiselect from "vue-multiselect";
	import VSwatches from "vue-swatches";
	import "vue-swatches/dist/vue-swatches.css";
	import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
    import newShipment from './Shipping/newShipment.vue'
	import leaderboard from "./Leaderboard/leaderboard.vue"
	import newPO from "./Sales/newPurchaseOrder.vue"
	import viewPO from "./Sales/viewPurchaseOrder.vue"
	import specialOrder from './Service/SpecialOrder.vue'
	import locationSelect from './Service/InventoryLocationSelecter.vue'
    import html2canvas from "html2canvas"
	import ToDoList from "./Dashboard/ToDo.vue"
	import { debounce } from 'lodash';
	import Intersect from 'vue-intersect';
	import selectSell from './SellFunctions/selectSellFunction.vue'


export default {
	name: "app",
	props: {
		socket: Socket,
		canAccess: Object,
		userRoles: Array,
	},
	components: { Multiselect, VSwatches, VueCtkDateTimePicker, newShipment, leaderboard, newPO, viewPO, specialOrder, locationSelect, ToDoList, Intersect, selectSell},

	data() {
		return {
			action: "",
			allContacts: [],
			allCustomers: [],
			allInv: [],
			allParts: [
				{
					name: "Part",
					items: [],
				},
				{
					name: "Equipment",
					items: [],
				},
				{
					name: "Kit",
					items: [],
				},
				{
					name: "Unset",
					items: [],
				},
				{
					name: 'Special Order',
					items: []
				}
			],
			allTypes: [],
			allVendors: [],
			allUsers: [],
			billContact: [],
			dataType: 'invoice',
			canEditMap: false,
			countycoords: null,
			closedPOs: [],
			closedProposals: [],
			closedSalesOrders: [],
			editingMap: false,
			editedSalesmen: [],
			filledState: null,
			hoveredCounty: "",
			isBusy: false,
			leadUsers: [],
			newLines: [],
			newLineItem: "",
			newLineQty: 1,
			openProposals: [],
			openSalesOrders: [],
			openPOs: [],
			leads: [],
			unassignedLeads: [],
			tagColor: "#007bff",
			swatches: ["red", "orange", "yellow", "green", "teal", "blue", "indigo", "purple", "pink"],
			overlay: null,
			poType : "",
			poTicketID : null,
			poItems : [],
			printing: false,
			propTableFields: [
				{ key: "itemid", label: "Item ID" },
				{ key: "itemdesc", label: "Description" },
				{ key: "qty", label: "Qty" },
				{ key: "markUp", label: "Sell Rate" },
				{ key: "sell", label: "Sell" },
				{ key: "total", label: "Total" },
				{ key: "action", label: ""},
			],
			receivedTableFields: [
				{key: "qty", label: "Quantity"},
				{key: "location", label: "Location"},
				{key: "bin", label: "Bin"}
			],
			salesTableFields: [
				{ key: "itemid", label: "Item ID" },
				{ key: "itemdesc", label: "Description" },
				{ key: "qty", label: "Qty" },
				{ key: "markUp", label: "Sell Rate" },
				{ key: "sell", label: "Price Each" },
				{ key: "total", label: "Total" },
				{ key: "action", label: ""},
			],
			propLeadsFields: [
				{ key: "localid", label: "Id" },
				{ key: "leads", label: "Lead" },
				{ key: "salesman", label: "Lead Founder"},
				{ key: "tags", label: "Tags" },
			],
			relatedPO: [],
			salesPriceDefault: [],
			salesOrderTotal: 0,
			sortedLeads: [],
			selectedProposalLeads: [],
			selectedLineItems: [],
			showLineSelect: false,
			search: {
				proposal: "",
				salesOrder: "",
				purchaseOrders: "",
				leads: "",
			},
			leadInput: "",
			selectedTab: {
				proposal: null,
				salesOrder: null,
				purchaseOrder: null,
			},
			selectedCompany: {},
			selectedCustomer: {},
			selectedProposal: {},
			selectedSalesman: {},
			selectedStates: [],
			selectedVendor: null,
			shipContact: [],
			selectedPO: [],
			selectedLead: [],
			selectedUsers: [],
			selectedId: {},
			selectedSalesOrder: {},
			selectedSalesOrderLeads: [],
			showAll: true,
			tags: [],
			tagInput: "",
			expires: new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0],

			leadStartDate: null,
			leadStatus: "",
			map: null,
			mapFilter: "SALESMAN",
			geocoder: new google.maps.Geocoder(),
			allSalesmen: [],
			allStates: [
				{ name: "Alabama", code: "01" },
				{ name: "Alaska", code: "02" },
				{ name: "Arizona", code: "04" },
				{ name: "Arkansas", code: "05" },
				{ name: "California", code: "06" },
				{ name: "Colorado", code: "08" },
				{ name: "Connecticut", code: "09" },
				{ name: "Delaware", code: "10" },
				{ name: "District of Columbia", code: "11" },
				{ name: "Florida", code: "12" },
				{ name: "Georgia", code: "13" },
				{ name: "Hawaii", code: "15" },
				{ name: "Idaho", code: "16" },
				{ name: "Illinois", code: "17" },
				{ name: "Indiana", code: "18" },
				{ name: "Iowa", code: "19" },
				{ name: "Kansas", code: "20" },
				{ name: "Kentucky", code: "21" },
				{ name: "Louisiana", code: "22" },
				{ name: "Maine", code: "23" },
				{ name: "Maryland", code: "24" },
				{ name: "Massachusetts", code: "25" },
				{ name: "Michigan", code: "26" },
				{ name: "Minnesota", code: "27" },
				{ name: "Mississippi", code: "28" },
				{ name: "Missouri", code: "29" },
				{ name: "Montana", code: "30" },
				{ name: "Nebraska", code: "31" },
				{ name: "Nevada", code: "32" },
				{ name: "New Hampshire", code: "33" },
				{ name: "New Jersey", code: "34" },
				{ name: "New Mexico", code: "35" },
				{ name: "New York", code: "36" },
				{ name: "North Carolina", code: "37" },
				{ name: "North Dakota", code: "38" },
				{ name: "Ohio", code: "39" },
				{ name: "Oklahoma", code: "40" },
				{ name: "Oregon", code: "41" },
				{ name: "Pennsylvania", code: "42" },
				{ name: "Rhode Island", code: "44" },
				{ name: "South Carolina", code: "45" },
				{ name: "South Dakota", code: "46" },
				{ name: "Tennessee", code: "47" },
				{ name: "Texas", code: "48" },
				{ name: "Utah", code: "49" },
				{ name: "Vermont", code: "50" },
				{ name: "Virginia", code: "51" },
				{ name: "Washington", code: "53" },
				{ name: "West Virginia", code: "54" },
				{ name: "Wisconsin", code: "55" },
				{ name: "Wyoming", code: "56" },
			],
			mapStatistics: [],
			dataMin: 0,
			dataMax: 1,
			typeOfSelect: 'invoice',
			// Sorted Leads
			leadsPageNumber: 0,
			leadsMaxPages: 0,
			leadsSearchText: "",
			leadsPrevText: "",
			// All Leads
			allLeadsPageNumber: 0,
			allLeadsMaxPages: 0,
			allLeadsSearchText: "",
			allLeadsPrevText: ""
		};
	},
	computed: {

		proposalTotal: function() {
			var total = 0;
			for (let item in this.selectedProposal.lineitems) {
				if (this.selectedProposal.lineitems[item].itemid) {
					total +=
					parseFloat(this.selectedProposal.lineitems[item].qty) *
					(parseFloat(this.selectedProposal.lineitems[item].sell));
				}
			}
		    //console.log("proposalTotal: ",total)
			return total.toFixed(2);
		},

		approvedSalesOrderTotal: function() {
			var total = 0;
			if(this.selectedLineItems.length > 0){
				for (let item in this.selectedLineItems) {
					if (this.selectedLineItems[item].itemid) {
						total += (
							parseFloat(this.selectedLineItems[item].qty) *
							parseFloat(this.selectedLineItems[item].sell)
						)
					}
				}
			}
			else {
				for (let item in this.selectedSalesOrder.lineitems) {
					if (this.selectedSalesOrder.lineitems[item].itemid && (this.selectedSalesOrder.lineitems[item].approval !== "Pending") && (!this.selectedSalesOrder.lineitems[item].notReceivedFlag) && (this.selectedSalesOrder.lineitems[item].invoiced < this.selectedSalesOrder.lineitems[item].qty)) {
						total += (
							parseFloat(this.selectedSalesOrder.lineitems[item].qty) *
							parseFloat(this.selectedSalesOrder.lineitems[item].sell)
						)
					}
				}
			}
			return total.toFixed(2);
		},
	},

	watch: {
		allLeadsSearchText(newSearch, oldSearch){
			this.debounceLeads(newSearch)
		}
	},

	created() {
		this.debounceLeads = debounce(this.searchLeads, 600);
		this.debounceSortedLeads = debounce(this.searchSortedLeads, 600);
	},

	async mounted() {
		this.checkRoles();
		axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		})
		.then((response) => {
			this.allCustomers = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		})
		.then((response) => {
			this.selectedCompany = response.data.queryResult[0];
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		this.getProposals()
		this.getSalesOrders()
		this.getOpenPurchaseOrders()
		this.getClosedPurchaseOrders()
		this.getLeads()

		axios.get(`${process.env.VUE_APP_API_TARGET}/users/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		})
		.then((response) => {
			this.allUsers = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		this.getToDoList();

		axios.get(`${process.env.VUE_APP_API_TARGET}/sales/map/all/${this.$globals.companyId}`, {
		headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
		.then((response) => {
			this.allSalesmen = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `sales${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed sales")
    },

	methods: {
	  addSOPart(data){
		  data.id = "**SO** " + data.id

		  this.newLineItem = {
			  companyid : this.$globals.companyId,
			  cost : parseFloat(data.cost),
			  highCost: parseFloat(data.cost),
			  sell: parseFloat(data.cost),
			  itemdesc: data.desc,
			  itemid: data.id,
			  otherLocations: [],
			  type: 'Special',
			  vendor: data.vendor.company,
			  notReceivedFlag: true,
			  locationFlag: false,
		  }
		  this.newLineQty = data.qty

		  this.addLine()

	  },

	  computedFields() {
		  if(this.showLineSelect){
			if(this.typeOfSelect === 'invoice'){
				this.salesTableFields.push({ key: "select", label: "Select for Invoice"})
			}
			else if (this.typeOfSelect === 'shipping'){
				this.salesTableFields.push({ key: "select", label: "Select for Shipping"})
			}
			else if(this.typeOfSelect === 'purchaseOrder'){
				this.salesTableFields.push({ key: "select", label: "Select to create PO"})
			}
			else {
				this.salesTableFields.push({key: "select", label: `Select for ${this.typeOfSelect}`})
			}
            return this.salesTableFields
		  }
		  else {
			if(this.action === 'createSO' || this.action === 'editSalesOrder'){
				this.salesTableFields = [
					{ key: "itemid", label: "Item ID" },
					{ key: "itemdesc", label: "Description" },
					{ key: "qty", label: "Qty" },
					{ key: "markUp", label: "Sell Rate"},
					{ key: "sell", label: "Price Each" },
					{ key: "total", label: "Total" },
					{ key: "action", label: ""},
				]
			}
			else {
				this.salesTableFields = [
					{ key: "itemid", label: "Item ID" },
					{ key: "itemdesc", label: "Description" },
					{ key: "qty", label: "Qty" },
					{ key: "sell", label: "Price Each" },
					{ key: "total", label: "Total" },
					{ key: "action", label: ""},
				]
			}
		  }
		  return this.salesTableFields
	  },

	  computedPropFields() {
		  if(this.showLineSelect){
			  if(this.typeOfSelect === 'purchaseOrder'){
				  this.propTableFields.push({ key: "select", label: "Select to create PO"})
			  }
			  else {
				  this.propTableFields.push({key: "select", label: `Select for ${this.typeOfSelect}`})
			  }
            return this.propTableFields
		  } else {
              //console.log("else 1")
              if(this.action === 'createProp' || this.action === 'editProposal'){
                  this.propTableFields = [
                      { key: "itemid", label: "Item ID" },
                      { key: "itemdesc", label: "Description" },
                      { key: "qty", label: "Qty" },
                      { key: "markUp", label: "Sell Rate"},
                      { key: "sell", label: "Price Each" },
                      { key: "total", label: "Total" },
                      { key: "action", label: ""},
                  ]
              } else {
                //console.log("else 2")
                  this.propTableFields = [
                    { key: "itemid", label: "Item ID" },
                    { key: "itemdesc", label: "Description" },
                    { key: "qty", label: "Qty" },
                    { key: "sell", label: "Price Each" },
                    { key: "total", label: "Total" },
                    { key: "action", label: ""},
                ]

              }
		  }
		  return this.propTableFields
	  },

    addShippingToOrder(carrier, service, rate){
        this.selectedSalesOrder.lineitems.push({
            itemid: 'SHIPPING',
            itemdesc: `${carrier} - ${service}`,
            markUp: 0,
            qty: 1,
            sell: rate,
            total: rate,
            otherLocations: []
        })

        this.newLines = this.selectedSalesOrder.lineitems
        this.selectedLineItems.forEach((item) => {
            for(let lineitem in this.newLines){
                if((this.newLines[lineitem].itemid === item.itemid) && (this.newLines[lineitem].qty === item.qty) ){
                    this.newLines[lineitem].shipped = true
                }
            }
        })
        this.updateSalesOrder()
        this.showLineSelect = false
    },

	async addLine() {
		if(this.newLineItem === null || this.newLineItem === ''){
			alert("Cannot Add nothing")
			return;
		}

		if (this.newLineItem.components) {
			//if new item is a Kit
			var components = JSON.parse(this.newLineItem.components);
			var lineComp = {};
			for (let part in components) {
				await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(components[part].itemid)}/total/${this.$globals.companyId}`,{
					headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					lineComp = {
						...response.data.queryResult[0],
						qty: parseInt(components[part].qty) * parseInt(this.newLineQty),
						approval: "Approved",
						locationFlag: true
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(lineComp.vendor)}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				}).then((response)=>{
					lineComp.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				lineComp.itemMarkup = lineComp.itemMarkup ?? 0

				let maxPrecent = 0
				let minPrecent = 0
				if(lineComp.vendorMarkup > lineComp.itemMarkup){
					maxPrecent = lineComp.vendorMarkup
					minPrecent = lineComp.itemMarkup
				}
				else{
					maxPrecent = lineComp.itemMarkup
					minPrecent = lineComp.vendorMarkup
				}

				let minPrice = parseFloat(lineComp.highCost) * (1+minPrecent)
				let maxPrice = parseFloat(lineComp.highCost) * (1+maxPrecent)

				//lineComp.itemMarkup = maxPrecent * 100;
				lineComp.itemMarkup = maxPrecent;



				this.salesPriceDefault.push(minPrice.toFixed(2))
				lineComp.sell = (maxPrice).toFixed(2);
				lineComp.total = (lineComp.sell * lineComp.qty).toFixed(2);
				console.log("lineComp: ",lineComp);
				this.newLines.push(lineComp);
			}
		}
		else {

			await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.newLineItem.vendor)}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
			}).then((response)=>{
				this.newLineItem.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.newLineItem.itemMarkup = this.newLineItem.itemMarkup ?? null

			let maxPrecent = 0
			let minPrecent = 0
			if(this.newLineItem.vendorMarkup > this.newLineItem.itemMarkup){
				maxPrecent = this.newLineItem.vendorMarkup
				minPrecent = this.newLineItem.itemMarkup
			}
			else{
				maxPrecent = this.newLineItem.itemMarkup
				minPrecent = this.newLineItem.vendorMarkup
			}

			let minPrice = parseFloat(this.newLineItem.highCost) * (1+minPrecent)
			let maxPrice = parseFloat(this.newLineItem.highCost) * (1+maxPrecent)

			// this.newLineItem.itemMarkup = maxPrecent * 100;
			this.newLineItem.itemMarkup = maxPrecent;

			//this.determineSell(this.newLineItem)

			this.salesPriceDefault.push(minPrice.toFixed(2))
			let newLine = {
				...this.newLineItem,
				qty: this.newLineQty,
				markUp: maxPrecent * 100,
				approval: "Approved",
				locationFlag: typeof this.newLineItem.locationFlag === "Boolean" ? this.newLineItem.locationFlag : true
			};
			newLine.sell = (maxPrice).toFixed(2);
			newLine.total = (newLine.sell * newLine.qty).toFixed(2);
			this.newLines.push(newLine);
		}
		this.newLineItem = "";
		this.newLineQty = 1;
    },

    addTag() {
      this.tags.push({ tag: this.tagInput, color: this.tagColor });
      this.tagInput = "";
    },

	checkRoles() {
		let roleNames = [];
		for (let role in this.userRoles) {
			roleNames.push(this.userRoles[role].name);
		}
		for (let role in roleNames) {
			if (roleNames[role].toLowerCase().includes("admin") || roleNames[role].toLowerCase().includes("manager"))
			{
				this.canEditMap = true;
				return;
			}
		}
	},

	async changeStatus() {
		await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do_status`,
			{
				localid: this.selectedTask.localid,
				status: "complete",
			},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		this.$forceUpdate();
		this.action = "";
	},

	checkFormValidity(){
		let valid = this.$refs.propForm.checkValidity()
		this.filledState = valid
		return valid
	},

	async closeProposal(){
		//update using selectedProposal.id shift selectedProposal.status to closed and update reason
		await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/close/proposal/${this.selectedProposal.propid}/${this.$globals.companyId}`,
			{status: 'Closed', reason: this.selectedProposal.reason},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
		)
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.filledState = null
		this.getProposals()
		this.action = '';
	},

	async closeSalesOrder(){
		//update using selectedProposal.id shift selectedSalesOrder.status to closed and update reason
		await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/close/sales_order/${this.selectedSalesOrder.salesid}/${this.$globals.companyId}`,
			{status: 'Closed', reason: this.selectedSalesOrder.reason},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
		)
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.filledState = null
		this.getSalesOrders()
		this.action = '';
	},

	clearData(){
		//empty all fields related to New Proposal
		this.selectedSalesman = null;
		this.selectedCustomer = null;
		this.billto = null;
		this.selectedLead = null;
		this.expires = new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
		this.newLines = [];
		this.newLineItem = ""
		this.newLineQty = 1
		this.salesPriceDefault = []
	},

	createGroups() {
		this.allParts = [];

		this.allParts.push({
			name: 'Special Order',
			items: [{
				'itemid': 'Special Order',
				'itemdesc': '**Special Order**',
				'type': 'Special Order'
			}]
		})

		for(let i in this.allTypes){
			let tempTypeArray = this.allInv.filter((item) =>{
				if(item.type == this.allTypes[i].type){
					return item
				}
			})
			this.allParts.push({name: this.allTypes[i].type, items: tempTypeArray})
		}
    },

	async createProposal() {
	  this.selectedSalesman = this.$globals.user
      this.selectedCustomer = null;
      this.billContact = [];
      this.sortedLeads = [];
      this.selectedLead = [];
      this.selectedId = [];
      this.action = "createProp";
	  this.computedPropFields()
      this.getInventory();
    },

    async createPO() {
      this.selectedVendor = null;
	  this.poType = null;
	  this.poItems = [];
	  this.selectedLineItems = [];
	  this.poTicketID = null;
      this.action = "createPO";
      this.getInventory();

		// await axios
		//   .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}`, {
		// 	headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		//   })
		//   .then((response) => {
		// 	this.allVendors = response.data.queryResult;
		//   })
		//   .catch((response)=>{
		// 	  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		//   })

    },

	async createSO() {
		this.selectedSalesman = this.$globals.user
      this.selectedCustomer = null;
      this.billContact = [];
      this.shipContact = [];
      this.sortedLeads = [];
      this.selectedLead = [];
      this.selectedId = [];
      await axios
        .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}`, {
            headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
        })
        .then((response) => {
            this.allCustomers = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.$globals.shield = true;
      this.action = "createSO";
	  this.selectedProposal = null;
      this.getInventory();
	  this.computedFields()
    },

    async createLeads() {
    	this.selectedSalesman = this.$globals.user
      this.selectedProposal = [];
      this.selectedCustomer = null;
      this.getInventory();
      this.action = "createLeads";
    },

    async createMap() {
      this.action = "createMap";
      if (this.canEditMap) {
        await axios
          .get(
            `${process.env.VUE_APP_API_TARGET}/sales/map/all/${this.$globals.companyId}`,
            { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
          )
          .then((response) => {
            this.allSalesmen = response.data.queryResult
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
      }
	  else {
        await axios
          .get(
            `${process.env.VUE_APP_API_TARGET}/sales/map/${this.$globals.user.sub}/${this.$globals.companyId}`,
            { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
          )
          .then((response) => {
            this.allSalesmen = response.data.queryResult
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
      }
      this.$nextTick(() => {
        this.map = new window.google.maps.Map(this.$refs["map"], {
          center: { lat: 38.50372, lng: -90.29551 },
          zoom: 5,
        });
        this.overlay = new window.google.maps.OverlayView();
        this.overlay.draw = () => {};
        this.overlay.setMap(this.map);
        this.countycoords = require("../../public/countyzones.json");
        this.map.data.addGeoJson(this.countycoords, {
          idPropertyName: "GEO_ID",
        });
        this.map.data.forEach((feature) => {
          if (this.canEditMap && this.selectedStates.length > 0) {
            if (
              this.selectedStates.filter((e) => e.code === feature.i.STATE)
                .length > 0
            ) {
              this.map.data.overrideStyle(feature, {
                strokeWeight: 1,
                fillOpacity: 0.05,
              });
              for (let salesman in this.allSalesmen) {
                if (
                  this.allSalesmen[salesman].counties?.includes(
                    feature.i.GEO_ID
                  )
                ) {
                  feature.setProperty(
                    "SALESMAN",
                    this.allSalesmen[salesman].name
                  );
                  this.map.data.overrideStyle(feature, {
                    strokeWeight: 1,
                    fillOpacity: 0.3,
                    fillColor: this.allSalesmen[salesman].color,
                  });
                }
              }
            } else {
              this.map.data.overrideStyle(feature, { visible: false });
            }
          } else {
            if (this.canEditMap) {
              //no selected states
              this.map.data.overrideStyle(feature, { visible: false });
            } else {
              //not management and selectedState >= 1
              for (let salesman in this.allSalesmen) {
                if (this.allSalesmen[salesman].auth0 === this.$globals.user.sub) {
                  if (
                    this.allSalesmen[salesman].counties?.includes(
                      feature.i.GEO_ID
                    )
                  ) {
                    feature.setProperty(
                      "SALESMAN",
                      this.allSalesmen[salesman].name
                    );
                    this.map.data.overrideStyle(feature, {
                      strokeWeight: 1,
                      fillOpacity: 0.3,
                      fillColor: this.allSalesmen[salesman].color,
                    });
                  }
                }
              }
            }
          }
        });
        this.map.data.addListener("click", (event) => {
          this.selectedSalesman.counties =
            typeof this.selectedSalesman.counties != "object"
              ? JSON.parse(this.selectedSalesman.counties)
              : this.selectedSalesman.counties;
          this.selectedSalesman.customers =
            typeof this.selectedSalesman.customers != "object"
              ? JSON.parse(this.selectedSalesman.customers)
              : this.selectedSalesman.customers;
          this.selectedSalesman.todo =
            typeof this.selectedSalesman.todo != "object"
              ? JSON.parse(this.selectedSalesman.todo)
              : this.selectedSalesman.todo;
          if (this.editingMap === true) {
            if (
              this.selectedSalesman.counties?.includes(event.feature.i.GEO_ID)
            ) {
              this.selectedSalesman.counties = this.selectedSalesman.counties?.filter(
                (c) => c != event.feature.i.GEO_ID
              );
              this.map.data.overrideStyle(event.feature, {
                strokeWeight: 1,
                fillOpacity: 0.1,
              });
            } else {
              if (this.selectedSalesman.counties !== null) {
                this.selectedSalesman.counties?.push(event.feature.i.GEO_ID);
              } else {
                this.selectedSalesman.counties = [event.feature.i.GEO_ID];
              }
              this.map.data.overrideStyle(event.feature, {
                strokeWeight: 1,
                fillOpacity: 0.3,
                fillColor: this.selectedSalesman?.color,
              });
            }
            this.editedSalesmen.push(this.selectedSalesman);
          }
        });
        this.map.data.addListener("mouseover", (event) => {
          var tooltip = document.querySelectorAll(".countytooltip");
          let projection = this.overlay.getProjection();
          let mousePos = projection.fromLatLngToContainerPixel(event.latLng);
          for (var i = tooltip.length; i--; ) {
            tooltip[i].style.left = mousePos.x - 60 + "px";
            tooltip[i].style.top = mousePos.y + 120 + "px";
          }
          this.hoveredCounty =
            this.mapFilter != "PROFIT"
              ? "" +
                event.feature.i.NAME +
                " - " +
                (event.feature.getProperty(this.mapFilter) != undefined
                  ? event.feature.getProperty(this.mapFilter)
                  : "Unassigned")
              : "" +
                event.feature.i.NAME +
                " - " +
                "$" +
                (event.feature.getProperty(this.mapFilter) != undefined
                  ? event.feature.getProperty(this.mapFilter)
                  : "Unassigned");
          if (this.mapFilter !== "SALESMAN") {
            const percent =
              ((event.feature.getProperty(this.mapFilter) - this.dataMin) /
                (this.dataMax - this.dataMin)) *
              61.5;
            document.getElementById("data-caret").style.paddingLeft =
              percent + "%";
          }
        });
        this.map.data.addListener("mouseout", (event) => {
          this.hoveredCounty = "";
        });
      });
    },

	customLabel({ title, name, address }) {
		let comboString = ''
		if(title !== null && title !== 'null' && title !== '' ){
			comboString = `${title} - `
		}
		comboString = comboString + name
		if(address !== null && address !== 'null' && address !== ''){
			comboString = comboString + ` - ${address}`
		}
		return comboString;
	},

    customLeadLable({ localid, salesman, customer, leads }) {
      return `${localid} – ${JSON.parse(customer).name} – ${leads}`;
    },

	async determineSell(item){
		item.itemMarkup = item.itemMarkup /100

		if(item.cost > item.highCost){
			item.sell = parseFloat(item.cost * (1 + (item.itemMarkup ?? item.vendorMarkup))).toFixed(2)
		}
		else{
			item.sell = parseFloat((item.highCost || item.cost) * (1 + (item.itemMarkup ?? item.vendorMarkup))).toFixed(2)
		}
		item.itemMarkup = item.itemMarkup * 100

	},

	editProp() {
	  this.getInventory();
	  //this.selectedProposal.billto is a Contact Object
	  //this.selectedProposal.customer is a Customer Object (unchanged but required for billto change)
	  //this.selectedProposal.lineitems is an Array of Inventory Objects
	  //this.selectedProposal.salesman is a String
	  this.newLines = []

	  this.selectedSalesman = this.selectedProposal.salesman
	  this.selectedCustomer = this.selectedProposal.customer
	  this.getContacts();
	  this.billContact = this.selectedProposal.billto
	  this.expires = this.selectedProposal.expires
	  this.newLineItem = ""

	  for(let index in this.selectedProposal.lineitems){
		  this.newLines[index] = {...this.selectedProposal.lineitems[index]}
          //console.log("editProp: ",this.newLines[index])

		  let minPrecent = 0
		  if(this.newLines[index].vendorMarkup > this.newLines[index].itemMarkup){
			  minPrecent = this.newLines[index].itemMarkup
		  }
		  else{
			  minPrecent = this.newLines[index].vendorMarkup || 0.1
		  }

		  let minPrice = parseFloat(this.newLines[index].highCost) * (1+minPrecent)

		  this.salesPriceDefault.push(minPrice.toFixed(2))
	  }

	  this.action = 'editProposal';
      this.computedPropFields();

    },

	editSalesOrder(){
		this.getInventory();
		this.newLines = []
		this.selectedSalesman = this.selectedSalesOrder.salesman
		this.selectedCustomer = this.selectedSalesOrder.customer
		this.getContacts();
		this.billContact = this.selectedSalesOrder.billto
		this.shipContact = this.selectedSalesOrder.shipto
		this.expires = this.selectedSalesOrder.expires

		this.newLineItem = ""
		//console.log("editSO-selectedSalesOrder: ", this.selectedSalesOrder);

		for(let index in this.selectedSalesOrder.lineitems){
			this.newLines[index] = {...this.selectedSalesOrder.lineitems[index]}
			let minPrecent = 0
			if(this.newLines[index].vendorMarkup > this.newLines[index].itemMarkup){
				minPrecent = this.newLines[index].itemMarkup
			}
			else{
				minPrecent = this.newLines[index].vendorMarkup || 0.1
			}

			let minPrice = parseFloat(this.newLines[index].highCost) * (1+minPrecent)
			this.salesPriceDefault.push(minPrice.toFixed(2))
		}

		this.action = 'editSalesOrder'
		this.computedFields()
	},

	evaluatedSell(arg, item){
		console.log("evaluatedSell: ", arg, item, arg.sell);
		this.$nextTick(()=>{
			item.sell = arg.sell
			item.sellRate = arg.fun
			this.$forceUpdate()
		})
	},

    async getContacts() {
      await axios
        .get(
          `${process.env.VUE_APP_API_TARGET}/customers/${this.selectedCustomer.customerid}/contacts/${this.$globals.companyId}`,
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
        .then((response) => {
          this.allContacts = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

	async getMapData(filter) {
		//TODO get sales/mapdata doesn't exist
      this.dataMax = 0;
      this.dataMin = 0;
      if (filter === "SALESMAN") {
        this.mapFilter = "SALESMAN";
        this.map.data.forEach((feature) => {
          this.map.data.overrideStyle(feature, {
            strokeWeight: 1,
            fillOpacity: 0.05,
            fillColor: "black",
          });
          for (let salesman in this.allSalesmen) {
            if (
              this.allSalesmen[salesman].counties?.includes(feature.i.GEO_ID)
            ) {
              feature.setProperty("SALESMAN", this.allSalesmen[salesman].name);
              this.map.data.overrideStyle(feature, {
                strokeWeight: 1,
                fillOpacity: 0.3,
                fillColor: this.allSalesmen[salesman].color,
              });
            }
          }
        });
      }
	  else {
        if (this.canEditMap) {
			//TODO fix this route
          await axios
            .get(
              `${process.env.VUE_APP_API_TARGET}/sales/mapdata/${filter.toLowerCase()}/all`,
              { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
            )
            .then((response) => {
              this.mapStatistics = response.data.queryResult;
              this.map.data.forEach((feature) => {
                if (filter === "SALES" || filter === "PROPOSALS") {
                  let count = 0;
                  for (var item in this.mapStatistics) {
                    if (
                      JSON.parse(this.mapStatistics[item].customer).county ===
                        feature.i.NAME &&
                      this.allStates.find(
                        (x) =>
                          x.name ===
                          JSON.parse(this.mapStatistics[item].customer).state
                      ).code === feature.i.STATE
                    ) {
                      count += 1;
                    }
                  }
                  if (count > this.dataMax) {
                    this.dataMax = count;
                  }
                  if (count < this.dataMin) {
                    this.dataMin = count;
                  }
                  feature.setProperty(filter, count);
                } else if (filter === "PROFIT") {
                  let profit = 0;
                  for (var item in this.mapStatistics) {
                    if (
                      JSON.parse(this.mapStatistics[item].customer).county ===
                        feature.i.NAME &&
                      this.allStates.find(
                        (x) =>
                          x.name ===
                          JSON.parse(this.mapStatistics[item].customer).state
                      ).code === feature.i.STATE
                    ) {
                      var parsedLineItems = JSON.parse(
                        this.mapStatistics[item].lineitems
                      );
                      for (var line in parsedLineItems) {
                        profit +=
                          parsedLineItems[line].sell -
                          parsedLineItems[line].cost;
                      }
                    }
                  }
                  if (profit > this.dataMax) {
                    this.dataMax = profit;
                  }
                  if (profit < this.dataMin) {
                    this.dataMin = profit;
                  }
                  feature.setProperty(filter, profit.toFixed(2));
                }
              });
              this.map.data.forEach((feature) => {
                //STYLING FUNCTION GOES HERE
                const high = [119, 85, 58]; // color of largest
                const low = [2, 70, 37]; // color of smallest
                // delta represents where the value sits between the min and max
                const delta =
                  (feature.getProperty(filter) - this.dataMin) /
                  (this.dataMax - this.dataMin);
                const color = [];
                for (let i = 0; i < 3; i++) {
                  color.push((high[i] - low[i]) * delta + low[i]);
                }
                this.map.data.overrideStyle(feature, {
                  fillColor:
                    "hsl(" + color[0] + "," + color[1] + "%," + color[2] + "%)",
                  fillOpacity: 0.75,
                });
              });
              this.mapFilter = filter;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        }
		else {
          await axios
            .get(
              `${process.env.VUE_APP_API_TARGET}/sales/mapdata/${filter.toLowerCase()}/${
                this.$globals.user.name
              }`,
              { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
            )
            .then((response) => {
              this.mapStatistics = response.data.queryResult;
              this.map.data.forEach((feature) => {
                if (filter === "SALES" || filter === "PROPOSALS") {
                  let count = 0;
                  for (var item in this.mapStatistics) {
                    if (
                      JSON.parse(this.mapStatistics[item].customer).county ===
                      feature.i.NAME
                    ) {
                      count += 1;
                    }
                  }
                  feature.setProperty(filter, count);
                } else if (filter === "PROFIT") {
                  let profit = 0;
                  for (var item in this.mapStatistics) {
                    if (
                      JSON.parse(this.mapStatistics[item].customer).county ===
                      feature.i.NAME
                    ) {
                      var parsedLineItems = JSON.parse(
                        this.mapStatistics[item].lineitems
                      );
                      for (var line in parsedLineItems) {
                        profit +=
                          parsedLineItems[line].sell -
                          parsedLineItems[line].cost;
                      }
                    }
                  }
                  feature.setProperty(filter, profit.toFixed(2));
                }
              });
              this.mapFilter = filter;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        }
      }
    },

	async getToDoList(){
		await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.user.name}/${this.$globals.companyId}`, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
			.then((response) => {
				this.openTasks = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
	},

	async getProposals(){
		this.checkRoles()
		if(this.canEditMap === true){
			axios
				.get(`${process.env.VUE_APP_API_TARGET}/sales/props/open/${this.$globals.companyId}`, {
					headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
				})
				.then((response) => {
					this.openProposals = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			axios
				.get(`${process.env.VUE_APP_API_TARGET}/sales/props/closed/${this.$globals.companyId}`, {
					headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
				})
				.then((response) => {
					this.closedProposals = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}
		else{
			axios
				.get(`${process.env.VUE_APP_API_TARGET}/sales/notadminmanager/props/open/${this.$globals.user.name}/${this.$globals.companyId}`, {
					headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
				})
				.then((response) => {
					this.openProposals = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			axios
				.get(`${process.env.VUE_APP_API_TARGET}/sales/notadminmanager/props/closed/${this.$globals.user.name}/${this.$globals.companyId}`, {
					headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
				})
				.then((response) => {
					this.closedProposals = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}
	},

	async getSalesOrders(){
		this.checkRoles()
		if(this.canEditMap === true){
			axios.get(
				`${process.env.VUE_APP_API_TARGET}/sales/sales_orders/open/${this.$globals.companyId}`,
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					this.openSalesOrders = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			axios.get(
				`${process.env.VUE_APP_API_TARGET}/sales/sales_orders/closed/${this.$globals.companyId}`,
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					this.closedSalesOrders = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}
		else{
			axios.get(
				`${process.env.VUE_APP_API_TARGET}/sales/notadminmanager/sales_orders/open/${this.$globals.user.name}/${this.$globals.companyId}`,
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					this.openSalesOrders = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			axios.get(
				`${process.env.VUE_APP_API_TARGET}/sales/notadminmanager/sales_orders/closed/${this.$globals.user.name}/${this.$globals.companyId}`,
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					this.closedSalesOrders = response.data.queryResult;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}
	},

	async getInventory(){
		await axios
		  .get(
			`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}`,
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
		  )
		  .then((response) => {
			this.allInv = response.data.queryResult.items;
			this.allTypes = response.data.queryResult.types;
			this.createGroups();
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

	getAllLeads(){
		axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.leads = response.data.queryResult;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

	async getLeads(){
		this.allLeadsPageNumber = 0
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/page/${this.allLeadsPageNumber+1}/search_text/null/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.leads = response.data.queryResult.results
        console.log("LEADS LENGTH: ", this.leads.length)
        this.allLeadsMaxPages = response.data.queryResult.maxPages
        this.allLeadsPageNumber = this.allLeadsPageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getMoreLeads(){
      if(this.allLeadsPageNumber > this.allLeadsMaxPages){
        return
      }
      let text = this.allLeadsPrevText === "" ? text = null : text = this.allLeadsPrevText
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/page/${Number(this.allLeadsPageNumber+1)}/search_text/${text}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        for(var item in response.data.queryResult.results){
          this.leads.push(response.data.queryResult.results[item])
        }
        this.allLeadsMaxPages = response.data.queryResult.maxPages
        console.log("MEX PAGES: ", this.allLeadsMaxPages)
        this.allLeadsPageNumber = this.allLeadsPageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async searchLeads(searchText){
      if(searchText === "" || searchText === null){
		console.log("search is empty");
   		this.leadsPageNumber = 0
   		await this.getLeads();
        return
        searchText = null
      }
      console.log("SEARCH TEXT: ", searchText)
      this.allLeadsPrevText = searchText
      this.leads = []
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/page/${1}/search_text/${searchText}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.leads = response.data.queryResult.results
        this.allLeadsMaxPages = response.data.queryResult.maxPages
        this.allLeadsPageNumber = 1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

	getOpenPurchaseOrders(){
		this.selectedLineItems = [];
		this.selectedVendor = null;
		this.poType = null
		this.poItems = []
		this.poTicketID = null;

		axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/po/open/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.openPOs = response.data.queryResult;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

	getClosedPurchaseOrders(){

		axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/po/closed/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.closedPOs = response.data.queryResult;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

    async getProposal(id){
        await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/prop/${id}/${this.$globals.companyId}`, {
            headers: {
                'Authorization': `Bearer ${this.$globals.user.auth0Token}`
            }
        }).then((response) => {
            this.computedPropFields()
            this.viewProposal(response.data.queryResult[0])
        }).catch((error) => {
            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : error, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })
    },

	handleOk(bvModalEvt) {
		bvModalEvt.preventDefault()
		this.handleSubmit()
	},

	handleSubmit() {
		// Exit when the form isn't valid
		if (!this.checkFormValidity()) {
			return
		}
		if(this.selectedProposal){
			this.closeProposal();
		}
		else if(this.selectedSalesOrder){
			this.closeSalesOrder();
		}
		// Hide the modal manually
		this.$nextTick(() => {this.$bvModal.hide('modal-prevent-closing')})
	},

	locatePart(data, oldPart){
		oldPart.fulfillment = [];
		oldPart.fulfilledQty = 0
		oldPart.invoiced = oldPart.invoiced ?? 0
		data.forEach((item, i) => {
			oldPart.fulfillment.push(item)
			oldPart.fulfilledQty = oldPart.fulfilledQty + item.qty
		});
		oldPart.locationFlag = false
		this.$forceUpdate()
	},

	propToSales() {
	  for(let index in this.selectedProposal.lineitems){
		  this.newLines[index] = {...this.selectedProposal.lineitems[index]};
	  }
	  this.getInventory();
	  this.newLines = []

	  this.selectedSalesman = this.allSalesmen.find(item => item.name == this.selectedProposal.salesman)
	  this.selectedCustomer = this.selectedProposal.customer
	  this.getContacts();
	  this.selectedLead = this.selectedProposalLeads
	  this.billContact = this.selectedProposal.billto
	  this.expires = this.selectedProposal.expires
	  this.newLineItem = ""
	  for(let index in this.selectedProposal.lineitems){
		  this.newLines[index] = {...this.selectedProposal.lineitems[index]}

		  let minPrecent = 0
		  if(this.newLines[index].vendorMarkup > this.newLines[index].itemMarkup){
			  minPrecent = this.newLines[index].itemMarkup
		  }
		  else{
			  minPrecent = this.newLines[index].vendorMarkup || 0.1
		  }

		  let minPrice = parseFloat(this.newLines[index].highCost) * (1+minPrecent)
		  this.salesPriceDefault.push(minPrice.toFixed(2))
	  }
      if(this.selectedLead.length > 0){
          this.leadUsers = JSON.parse(this.selectedLead[0].users)
      }

      this.action = "createSO";
	  this.$globals.shield = true;

    },

	removeLine(data, index){
		if(data.fulfillment?.length >0){
			data.fulfillment.forEach((item) => {
				if(item.qty > 0){
					axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(item.itemid)}/${item.location}/${item.bin}/${this.$globals.companyId}`,
					{
						qty: 0-item.qty,
						vendor: item.vendor,
						addBack: JSON.stringify(item.usedCount)
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
			});
		}


		this.newLines.splice(index,1)
		this.salesPriceDefault.splice(index,1)

	},

	async renewProposal(){
		//take now and update expires on, change status if closed

		let status = this.selectedProposal.status
		if(status === 'Closed'){
			status = 'Open'
		}
		axios.put(`${process.env.VUE_APP_API_TARGET}/sales/renew/proposal/${this.selectedProposal.propid}/${this.$globals.companyId}`,
			{
				expires: this.expires.split(' ')[0],
				status: status
		 	},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
		).then(async response => {
			await this.getProposals();
			this.action = "";
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.expires = new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
	},

	rowClass(item, type) {
		if (!item || type !== 'row') {return}
		if (item.approval === 'Pending') {return 'table-warning'}
	},

	returnItems(){
		if(this.selectedSalesOrder?.salesid == undefined || this.selectedSalesOrder === null){
			for(let index in this.newLines){
				this.removeLine(this.newLines[index],0)
			}
			this.newLines = [];
		}
		else{
			axios.put(`${process.env.VUE_APP_API_TARGET}/sales/cancelReturn/${this.selectedSalesOrder.salesid}/${this.$globals.companyId}`,{
				data: JSON.stringify(this.newLines)
			},{headers: {'Authorization': 'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		}
		this.clearData();


	},

	salesOrderToPurchaseOrder(){
		if(this.selectedLineItems.length == 0 ){
			alert("Please Select a line item")
		}
		this.poType = "SO"
		this.poTicketID = this.selectedSalesOrder.localid
		//Loops to break ref
		for(let index in this.selectedLineItems){
			this.poItems[index] = {...this.selectedLineItems[index]}
		}
		this.action = "createPO"
	},

	async sendInvoice() {
		//TODO: Send to pre-invoice screen.
		//TODO: Use (fulfilledQty - invoiced) as the qty instead of qty and calculate total from it too.
		if(!this.$globals.qbo){
			alert("QuickBooks is not connected, please login")
			this.stopInvoice()
			this.selectedLineItems = []

			this.showLineSelect = false;
			this.showAll = true;
			//this.printing = false;

			this.computedFields()
			console.log("this.print: ", this.printing, this.showAll, this.showLineSelect );
			return;
		}
    	let response_invoice = await axios.post(
			process.env.VUE_APP_API_TARGET + "/qbo/invoice",
			{
				invoice: {
					Line: [
						{
							DetailType: "SalesItemLineDetail",
							Amount: this.approvedSalesOrderTotal,
							SalesItemLineDetail: {
								ItemRef: {
									name: "Services",
									value: "1",
								},
							},
						},
					],
					CustomerRef: {
						value: this.selectedSalesOrder.customer.qbid ?? "26",
					},
				},
				companyid: this.$globals.companyId,
			},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
		)
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

	  var lineitems = []
	  if(this.selectedLineItems.length > 0){
		  lineitems = this.selectedLineItems
	  }
	  else {
		  for(let i in this.selectedSalesOrder.lineitems){
			  if(this.selectedSalesOrder.lineitems[i].approval !== "Pending"){
				  lineitems.push(this.selectedSalesOrder.lineitems[i])
			  }
		  }
	  }
      await axios
        .post(
          process.env.VUE_APP_API_TARGET + "/qbo/invoiceToDb",
          {
            id: response_invoice.data.queryResult.Id,
            companyid: this.$globals.companyId,
            customer: JSON.stringify(this.selectedSalesOrder.customer),
            invoice: JSON.stringify(lineitems),
            total: this.approvedSalesOrderTotal,
            type: "sales",
            order: this.selectedSalesOrder.localid,
            issued: new Date().toISOString().split('T')[0],
            salesman: this.selectedSalesOrder.salesman,
          },
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
        .then(async (response) => {
		  if(this.selectedLineItems.length > 0){
			  this.selectedLineItems.forEach((item) => {
				  for(let lineitem in lineitems){
					  if((lineitems[lineitem].itemid === item.itemid) && (lineitems[lineitem].qty === item.qty) ){
						  item.invoiced = parseInt(item.fulfilledQty)
					  }
				  }
			  })
		  }
		  else{
			  for(let lineitem in this.selectedSalesOrder.lineitems){
				  if(this.selectedSalesOrder.lineitems[lineitem].approval !== 'Pending'){
					  this.selectedSalesOrder.lineitems[lineitem].invoiced = parseInt(this.selectedSalesOrder.lineitems[lineitem].fulfilledQty);
				  }
			  }
		  }

		  let allInvoiced = true;
		  for(let index in this.selectedSalesOrder.lineitems){
			  if(this.selectedSalesOrder.lineitems[index].invoiced < this.selectedSalesOrder.lineitems[index].qty){
				  allInvoiced = false
				  break;
			  }
		  }
		  if(allInvoiced){
			  this.selectedSalesOrder.status = "Closed"
			  this.selectedSalesOrder.reason = "All items Invoiced"
		  }
		  axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/update/sales_order/${this.selectedSalesOrder.salesid}/${this.$globals.companyId}`, {
			  lineitems: JSON.stringify(this.selectedSalesOrder.lineitems),
			  status: this.selectedSalesOrder.status,
			  billto: JSON.stringify(this.selectedSalesOrder.billto),
			  shipto: JSON.stringify(this.selectedSalesOrder.shipto),
			  salesman: this.selectedSalesOrder.salesman,
			  expires: this.selectedSalesOrder.expires,
			  reason: this.selectedSalesOrder.reason,
			  },
			  {headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}}
		  ).then((response)=>{
			  this.getSalesOrders()
			  this.action = "";
			  this.$vToastify.success("Invoice sent to Customer.");
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.showLineSelect = false;
		this.selectedLineItems = []
		this.expires = new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
		this.computedFields()
    },

    async sendProposal(){
        this.printing = true;
        this.$nextTick(() => {
            let htmlContent = document.getElementById('proposal')
            let initialState = htmlContent.style.boxShadow
            htmlContent.style.boxShadow = 'none'
            htmlContent.style.border = 'solid 1px black'
            html2canvas(htmlContent, {scale: 1}).then(async (canvas) => {
                var imgData = canvas.toDataURL("image/png");
                await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                    pic: imgData
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                }).then((response) => {
                    axios.post(
                    `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                    {
                        sgKey: this.subKey,
                        // to: this.selectedProposal.billto.email,
                        to: ['jakekowa@fleet4.net'],
                        subject: `${this.selectedCompany.name} - Fleet 4 Fire Proposal ${this.selectedProposal.localid}`,
                        // title: this.emailTitle,
                        html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Proposal ${this.selectedProposal.localid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Proposal ${this.selectedProposal.localid}"/></body></html>`,
                        text: `Can't see our email? Click the link below to view your estimate!\n\n\n${response.data.queryResult}`
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                        }
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
                htmlContent.style.boxShadow = initialState
                htmlContent.style.border = 'none'
                this.printing = false;
            })
        })
    },

    async sendSO() {
        this.printing = true;
        this.$nextTick(() => {
            let htmlContent = document.getElementById('salesOrder')
            let initialState = htmlContent.style.boxShadow
            htmlContent.style.boxShadow = 'none'
            htmlContent.style.border = 'solid 1px black'
            html2canvas(htmlContent, {scale: 1}).then(async (canvas) => {
                var imgData = canvas.toDataURL("image/png");
                await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                    pic: imgData
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                }).then((response) => {
                    axios.post(
                    `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                    {
                        sgKey: this.subKey,
                        // to: [this.selectedSalesOrder.billto.email, this.selectedSalesOrder.shipto.email],
                        to: 'jakekowa@fleet4.net',
                        subject: `${this.selectedCompany.name} - Fleet 4 Fire Sales Order ${this.selectedSalesOrder.localid}`,
                        // title: this.emailTitle,
                        html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Sales Order ${this.selectedSalesOrder.localid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Sales Order ${this.selectedSalesOrder.localid}"/></body></html>`,
                        text: `Can't see our email? Click the link below to view your order!\n\n\n${response.data.queryResult}`
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                        }
                    })
					.catch((response)=>{
						this.printing = false;
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
				.catch((response)=>{
					this.printing = false;
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
                htmlContent.style.boxShadow = initialState
                htmlContent.style.border = 'none'
                this.printing = false;
				this.showAll=true;
            })
        })
    },

	selectCustomer(option, id){
		this.billContact = null
		this.shipContact = null
		this.selectedLead = null
		this.selectedCustomer = option;
		this.getContacts();
	},

    startNewShipment(){
        this.action = 'newShipment'
    },

	stopInvoice(){
		this.printing = false;
		this.selectedLineItems = []
        this.showAll = true;
		this.showLineSelect = false;

		this.computedFields()
	},

    async submitMapChanges() {
      this.allSalesmen.forEach((salesman) => {
        salesman.counties =
          typeof salesman.counties == "object"
            ? JSON.stringify(salesman.counties)
            : salesman.counties;
        salesman.customers =
          typeof salesman.counties == "object"
            ? JSON.stringify(salesman.customers)
            : salesman.customers;
        salesman.todo =
          typeof salesman.counties == "object"
            ? JSON.stringify(salesman.todo)
            : salesman.todo;
      });
	  //TODO redo map things
      await axios.put(
        process.env.VUE_APP_API_TARGET + "/sales/map",
        {
          salesmen: this.allSalesmen,
        },
        { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.action = "";
    },

	async submitProp() {
	  let status = 'Open';

	  if(this.selectedSalesman === null){
		  this.selectedSalesman = this.$globals.user
	  }
	  if(this.selectedCustomer === null || this.selectedCustomer.length === 0){
		  alert("Proposal requires a customer")
		  return;
	  }
	  if(this.billContact === null || this.billContact.length === 0){
		  //if empty then grab first contact
		  this.billContact = this.allContacts[0]
	  }
	  if(this.newLines.length === 0){
		  alert("Please add items to the proposal")
		  return;
	  }

	  for(let index in this.newLines){
		  this.newLines[index].total = this.newLines[index].qty * (this.newLines[index].sell)
		  if(this.salesPriceDefault[index] > this.newLines[index].sell){
			  this.newLines[index].approval = 'Pending'
			  status = 'Pending'
			  //TODO emit to adminDashboard for approvals
		  }
	  }


      await axios
        .post(
          `${process.env.VUE_APP_API_TARGET}/sales/prop/${this.$globals.companyId}`,
          {
            salesman: this.selectedSalesman.name,
            customer: JSON.stringify(this.selectedCustomer),
            billto: JSON.stringify(this.billContact),
            lineitems: JSON.stringify(this.newLines),
            starts: new Date().toISOString().split('T')[0],
            expires: this.expires.split(' ')[0],
            status: status,
            lead: JSON.stringify(this.selectedLead),
          },
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		//this.$globals.socket.emit("newData", `sales${this.$globals.companyId}`);
		this.getProposals();

		this.action = "";
    	this.$globals.socket.emit("notifyReleventUsers", this.$globals.companyId, [this.selectedSalesman], "New Proposal", "New Proposal for " + this.selectedCustomer.name + " by " + this.$globals.user.name);
		this.newLines = [];
		this.selectedSalesman = {};
		this.selectedCustomer = {};
		this.expires = new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
		this.$forceUpdate();
    },

	async submitSales() {
		let status = 'Open';

		if(this.selectedSalesman === null){
			this.selectedSalesman = this.$globals.user
		}
		if(this.selectedCustomer === null || this.selectedCustomer.length === 0){
			alert("Sales Order requires a customer")
			return;
		}
		if(this.billContact === null || this.billContact.length === 0){
			//if empty then grab first contact
			this.billContact = this.shipContact ?? this.allContacts[0]
		}
		if(this.shipContact === null || this.shipContact.length === 0){
			this.shipContact = this.billContact ?? this.allContacts[0]
		}
		if(this.newLines.length === 0){
			alert("Please add items to the Sales Order")
			return;
		}

		for(let index in this.newLines){
			this.newLines[index].total = this.newLines[index].qty * (this.newLines[index].sell)
			if(this.salesPriceDefault[index] > this.newLines[index].sell){
				this.newLines[index].approval = 'Pending'
				status = 'Pending'
				//TODO emit to adminDashboard for approvals
			}
		}

		await axios
        .post(
          `${process.env.VUE_APP_API_TARGET}/sales/sales_orders/${this.$globals.companyId}`,
          {
            salesman: this.selectedSalesman.name,
            customer: JSON.stringify(this.selectedCustomer),
            billto: JSON.stringify(this.billContact),
            shipto: JSON.stringify(this.shipContact),
            lineitems: JSON.stringify(this.newLines),
            lead: JSON.stringify(this.selectedLead),
            status: status,
            starts: new Date().toISOString().split('T')[0],
			expires: this.expires.split(' ')[0],
          },
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
        this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [this.selectedSalesman], "New Sales Order", "New Sales Order for " + this.selectedCustomer.name + " by " + this.$globals.user.name);
		this.newLines = [];
		this.selectedSalesman = null;
		this.selectedCustomer = null;
		this.billContact = null;
		this.shipContact = null;
		this.selectedLead = null;
		this.salesPriceDefault=[];
		this.expires = new Date((Date.parse(new Date)) + (parseInt(30) * 24 * 60 * 60 * 1000)).toISOString().split('T')[0];
		this.getSalesOrders();

		if(this.selectedProposal){
			await axios
			.patch(`${process.env.VUE_APP_API_TARGET}/sales/leads/proptosales/${this.selectedProposal.propid}/${this.$globals.companyId}`, {
				status: "Converted"
			},
			{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [this.leadUser[0]], "Proposal converted to Sales Order", "Proposal " + this.selectedProposal.localid + " has been converted to new Sales Order");
			this.selectedProposal.reason = "Converted to Sales Order"
			await this.closeProposal()
		}

		this.$globals.shield = false;
		this.action = "";
		this.$forceUpdate();
    },

    async submitLead() {
      //post request for leads table
      const currentDate = new Date();
      const submittedDate = new Date(this.leadStartDate);
      if (currentDate.getTime() >= submittedDate.getTime()) {
        this.leadStatus = "Open";
      } else {
        this.leadStatus = "Closed";
      }

	  //Saftey?

    	await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/leads`,
			{
				companyid: this.$globals.companyId,
				salesman: this.$globals.user.name,
            	customer: this.selectedCustomer,
            	leads: this.leadInput,
            	tags: this.tags,
            	starts: this.leadStartDate,
            	status: this.leadStatus,
            	taggedUsers: this.selectedUsers,
			},{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
			.then(response =>{
				this.getLeads();
				this.action = "viewLeads";
				this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, this.selectedUsers, "New Lead", "New Lead for " + this.selectedCustomer.name + " by " + this.$globals.user.name);
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

      this.leadInput = "";
      this.tags = [];
      this.selectedUsers = [];
	  this.leadStartDate = null;
      this.$forceUpdate();
    },

	async submitTask() {
		let dueDate = this.toDoDueDate.split(' ')[0];
		let today = new Date().toISOString().split("T")[0]
		await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do`,
			{
				companyid: this.$globals.companyId,
				salesman: this.$globals.user.name,
				task: this.toDoInput,
				starts: today,
				due: dueDate,
				status: "Open",
				color: this.tagColor,
				auth: this.$globals.user.auth0
		  },
		  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
		)
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.user.name}/${this.$globals.companyId}`, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
			.then((response) => {
				this.openTasks = response.data.queryResult;
				this.$forceUpdate();
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		this.action = "";
		this.toDoInput = "";
		this.toDoDueDate = null;
		this.tagColor = "";
	},

    async attachLeadProposal() {
      await axios
        .post(
          `${process.env.VUE_APP_API_TARGET}/sales/attach_lead/${this.$globals.companyId}`,
          {
            lead: this.selectedLead.localid,
            proposal: this.selectedProposal.propid,
          },
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.action = "";
      this.$forceUpdate();
    },

    async attachLeadSales() {
      await axios
        .post(
          `${process.env.VUE_APP_API_TARGET}/sales/attach_lead/${this.$globals.companyId}`,
          {
            lead: this.selectedLead.localid,
            salesOrder: this.selectedSalesOrder,
          },
          { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.action = "";
      this.$forceUpdate();
    },

    async updateSalesman() {
      this.$nextTick(() => {
        this.allSalesmen[this.selectedSalesman.id] = this.selectedSalesman;
      });
    },

	async updateProposal(){
		//Takes edited data and updates the record
		//Updates this.selectedProposal too!

		let status = 'Open';

		if(this.selectedSalesman === null){
  			this.selectedSalesman = this.$globals.user
		}

  	  if(this.billContact === null || this.billContact.length === 0){
  		  //if empty then grab old contact
  		  this.billContact = this.selectedProposal.billto
  	  }
  	  if(this.newLines.length === 0){
  		  alert("Please add items to the proposal")
  		  return;
  	  }

  	  for(let index in this.newLines){
  		  this.newLines[index].total = this.newLines[index].qty * parseFloat(this.newLines[index].sell)
  		  if(this.salesPriceDefault[index] > this.newLines[index].sell){
			  console.log("pending: ", this.salesPriceDefault[index], this.newLines[index].sell);
  			  this.newLines[index].approval = 'Pending'
  			  status = 'Pending'
  			  //TODO emit to adminDashboard for approvals
  		  }
		  else{
			  this.newLines[index].approval = 'Approved'
			  if(status !== 'Pending'){
				  status = 'Open'
			  }
		  }
  	  }

        await axios
          .patch(
            `${process.env.VUE_APP_API_TARGET}/sales/update/proposal/${this.selectedProposal.propid}/${this.$globals.companyId}`,
            {
              salesman: this.selectedSalesman.name,
              billto: JSON.stringify(this.billContact),
              lineitems: JSON.stringify(this.newLines),
              expires: this.selectedProposal.expires,
              status: status,
            },
            { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		  this.selectedProposal.lineitems = this.newLines
		  this.selectedProposal.salesman = this.selectedSalesman.name
		  this.selectedProposal.billto = this.billContact

        //this.$globals.socket.emit("newData", `sales${this.$globals.companyId}`);
        this.getProposals();

  		this.action = "";

		this.clearData();
  		this.$forceUpdate();


		this.action = 'viewProposal';
        this.computedPropFields();
	},

	async updateSalesOrder(){
		//Takes edited data and updates the record

		let status = 'Open';

		if(this.selectedSalesman === null){
			this.selectedSalesman = this.$globals.user
		}

		if(this.billContact === null || this.billContact.length === 0){
			//if empty then grab old contact
			this.billContact = this.selectedSalesOrder.billto
		}

		if(this.shipContact === null || this.shipContact.length === 0){
			this.shipContact = this.selectedSalesOrder.shipto
		}

		if(this.newLines.length === 0){
			alert("Please add items to the Sales Order")
			return;
		}

		for(let index in this.newLines){
			this.newLines[index].total = this.newLines[index].qty * (this.newLines[index].sell)
			if(this.salesPriceDefault[index] > this.newLines[index].sell){
				this.newLines[index].approval = 'Pending'
				status = 'Pending'
				//TODO emit to adminDashboard for approvals
			}
			else{
				this.newLines[index].approval = 'Approved'
				if(status !== 'Pending'){
					status = 'Open'
				}
			}
		}

		//Saftey Return
		//return;

		await axios
			.patch(`${process.env.VUE_APP_API_TARGET}/sales/update/sales_order/${this.selectedSalesOrder.salesid}/${this.$globals.companyId}`,
				{
					salesman: this.selectedSalesman.name,
					billto: JSON.stringify(this.billContact),
					shipto: JSON.stringify(this.shipContact),
					lineitems: JSON.stringify(this.newLines),
					expires: this.selectedSalesOrder.expires,
					status: status
				},
				{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			this.selectedSalesOrder.lineitems = this.newLines
			this.selectedSalesOrder.salesman = this.selectedSalesman.name
			this.selectedSalesOrder.billto = this.billContact
			this.selectedSalesOrder.shipto = this.shipContact

			//this.$globals.socket.emit("newData", `sales${this.$globals.companyId}`);
			this.getSalesOrders();
			this.salesOrderTotal = 0;
			for (let item in this.selectedSalesOrder.lineitems) {
				if (this.selectedSalesOrder.lineitems[item].itemid) {
					this.salesOrderTotal += (this.selectedSalesOrder.lineitems[item].sell * this.selectedSalesOrder.lineitems[item].qty)
				}
			}

			this.action = "";

			this.clearData();
			this.$forceUpdate();

			this.action = 'viewSalesOrder'
		},

	async updateTask(){
		let dueDate = this.toDoDueDate.split(' ')[0];

			await axios.put(`${process.env.VUE_APP_API_TARGET}/sales/to_do/${this.selectedTask.id}/${this.$globals.companyId}`,
				{
					task: this.toDoInput,
					color: this.tagColor,
					due: dueDate,
					status: this.selectedTask.status
				},{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.user.name}/${this.$globals.companyId}`,
				{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
				.then((response) => {
					this.openTasks = response.data.queryResult;
					this.fillCalendar()
				}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			this.action=""
			this.toDoInput = ""
			this.toDoDueDate=null
			this.tagColor=""
			//this.$forceUpdate()

	},

	updateSelectedLines(line){
      if(this.selectedLineItems.includes(line)){
        this.selectedLineItems = this.selectedLineItems.filter((lineInArray) => lineInArray !== line)
      } else {
        this.selectedLineItems.push(line)
      }
	  if(this.selectedLineItems.length > 0 ){
		  this.selectedVendor = line.vendor
	  }
	  else{
		  this.selectedVendor = null;
	  }
    },

	viewProposal(record) {
        //console.log("viewProposal passed: ",record)
		record.customer = typeof record.customer == "object" ? record.customer : JSON.parse(record.customer);
		record.billto = typeof record.billto == "object" ? record.billto : JSON.parse(record.billto);

		record.lineitems = typeof record.lineitems == "object" ? record.lineitems : JSON.parse(record.lineitems);
		this.selectedProposal = record;
		this.selectedProposalLeads = [];
		for (let item in this.leads) {
			if (this.leads[item].propid === this.selectedProposal.propid){
				this.selectedProposalLeads.push(this.leads[item]);
			}
		}
		this.selectedSalesOrder = null;
		this.action = "viewProposal";
        this.computedPropFields();
	},

	viewPO(record){
		this.selectedPO = record
		this.selectedPO.vendor = typeof this.selectedPO.vendor === "object" ? this.selectedPO.vendor : JSON.parse(this.selectedPO.vendor)
		this.selectedPO.data = typeof this.selectedPO.data === "object" ? this.selectedPO.data : JSON.parse(this.selectedPO.data)
		this.action = "viewPO"
	},

    viewSalesOrder(record, index) {
      record.customer =
        typeof record.customer == "object"
          ? record.customer
          : JSON.parse(record.customer);
      record.billto =
        typeof record.billto == "object"
          ? record.billto
          : JSON.parse(record.billto);
      record.shipto =
        typeof record.shipto == "object"
          ? record.shipto
          : JSON.parse(record.shipto);
      record.lineitems =
        typeof record.lineitems == "object"
          ? record.lineitems
          : JSON.parse(record.lineitems);
      this.selectedSalesOrder = record;
      this.selectedSalesOrderLeads = [];
	  this.salesOrderTotal = 0;

	  for (let item in this.selectedSalesOrder.lineitems) {
		  if(this.selectedSalesOrder.lineitems[item].markUp === ""){
			  this.selectedSalesOrder.lineitems[item].markUp = 0;
		  }
		  if (this.selectedSalesOrder.lineitems[item].itemid) {
			  this.salesOrderTotal += (this.selectedSalesOrder.lineitems[item].sell * this.selectedSalesOrder.lineitems[item].qty)
		  }
	  }

      for (let item in this.leads) {
        if (this.leads[item].salesid == this.selectedSalesOrder.salesid)
          this.selectedSalesOrderLeads.push(this.leads[item]);
      }

	  this.selectedProposal = null;

	  axios.get(`${process.env.VUE_APP_API_TARGET}/sales/salesOrder/relatedPO/${this.selectedSalesOrder.localid}/${this.$globals.companyId}`,
		  {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
		  .then((response)=>{
			  this.relatedPO = response.data.queryResult;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        this.computedFields();
      this.action = "viewSalesOrder";
    },

    viewLeads() {
      var item;
      this.unassignedLeads = [];
      for (item in this.leads){
		  if (this.leads[item].propid == null){
			this.unassignedLeads.push(this.leads[item]);
		}
	  }

      this.action = "viewLeads";
    },

    viewLead(record, index) {
      this.selectedLead = record;
      //this.selectedProposal = [];
      this.action = "lead";
    },

    async sortLeads() {
    	this.leadsPageNumber = 0;
      if (this.action == "createProp") {
        await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/sales/page/${this.leadsPageNumber+1}/search_text/${null}/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.sortedLeads = response.data.queryResult.results;
			this.leadsMaxPages = response.data.queryResult.maxPages;
			this.leadsPageNumber = this.leadsPageNumber + 1;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		console.log("SALES: ", this.sortedLeads)
      } else if (this.action == "createSO") {
        await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/props/page/${this.leadsPageNumber}/search_text/${null}/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			this.sortedLeads = response.data.queryResult.results;
			this.leadsMaxPages = response.data.queryResult.maxPages;
			this.leadsPageNumber = this.leadsPageNumber + 1;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  console.log("PROPS: ", this.sortedLeads)
      }
    },

    async searchSortedLeads(searchText){
    	if(this.action == "createProp"){
    		if(searchText === "" || searchText === null){
			console.log("search is empty");
	   		this.leadsPageNumber = 0
	   		await this.sortLeads();
	        return
	        searchText = null
	      }
	      console.log("SEARCH TEXT: ", searchText)
	      this.leadsPrevText = searchText
	      this.sortedLeads = []
	      await axios
	      .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/props/page/${1}/search_text/${searchText}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
	      .then((response) => {
	        this.sortedLeads = response.data.queryResult.results
	        this.leadsMaxPages = response.data.queryResult.maxPages
	        this.leadsPageNumber = 1
	      })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

    	} else if(this.action == "createSO"){
    		if(searchText === "" || searchText === null){
				console.log("search is empty");
		   		this.leadsPageNumber = 0
		   		await this.sortLeads();
		        return
		        searchText = null
		      }
		      console.log("SEARCH TEXT: ", searchText)
		      this.leadsPrevText = searchText
		      this.sortedLeads = []
		      await axios
		      .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/sales/page/${1}/search_text/${searchText}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		      .then((response) => {
		        this.sortedLeads = response.data.queryResult.results
		        this.leadsMaxPages = response.data.queryResult.maxPages
		        this.leadsPageNumber = 1
		      })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })

    	}
    },

    async getMoreSortedLeads(){
      let text = this.leadsPrevText === "" ? null : this.leadsPrevText
      if (this.action == "createProp") {
        await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/sales/page/${this.leadsPageNumber+1}/search_text/${text}/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
			for(var item in response.data.queryResult.results){
	          this.sortedLeads.push(response.data.queryResult.results[item])
	        }
			this.leadsMaxPages = response.data.queryResult.maxPages;
			this.leadsPageNumber = this.leadsPageNumber + 1;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		console.log("SALES: ", this.sortedLeads)
      } else if (this.action == "createSO") {
        await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/sales/leads/props/page/${this.leadsPageNumber+1}/search_text/${text}/${this.$globals.companyId}`, {
			headers: { Authorization: "Bearer " + this.$globals.user.auth0Token },
		  })
		  .then((response) => {
		  	for(var item in response.data.queryResult.results){
	          this.sortedLeads.push(response.data.queryResult.results[item])
	        }
			this.leadsMaxPages = response.data.queryResult.maxPages;
			this.leadsPageNumber = this.leadsPageNumber + 1;
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Sales', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  console.log("PROPS: ", this.sortedLeads)
      }
    }

  }, //END methods
};
</script>
<style>
.dark {
	background-color: #dc4535 !important;
	border-color: #dc4535 !important
}
.stand {
	background-color: #dc3545 !important;
	border-color: #dc3545 !important
}

.map {
  margin: 1%;
  border: #dc3545;
  background: grey;
  height: 95%;
  width: 95%;
}
.countytooltip {
  display: block;
  background: #ececec;
  margin-left: 28px;
  padding: 10px;
  position: absolute;
  z-index: 10000;
  border-radius: 10px;
  border: 1px black solid;
}

.order-link {
  cursor: pointer;
  color: #dc3545;
}

.order-link:hover {
  text-decoration: underline;
  color: #dc3545;
}


/deep/ .vt-success {
  border-color: #dc3515 !important;
}
</style>

<template>
	<div>
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>New Customer</b></h3>
				</div>
				<div class="card-tools">
					<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="checkNewAddress()">Submit</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="$emit('return')">Return</b-button>
				</div>
			</div>
			<div class="card-body">
				<div class="row d-flex">
					<div class="form-group col" style="margin-left: 10%">
						<label for="cust_name" class="w-75 text-left ml-n5 mt-2">Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							requried
							id="cust_name"
							type="text"
							class="form-control w-75"
							placeholder="Customer Name"
							v-model="newCustomer.name"
						/>
						<label for="cust_ship" class="w-75 text-left ml-n5 mt-2">Shipping Address</label>
						<input
							id="cust_ship"
							type="text"
							class="form-control w-75"
							placeholder="Street"
							v-model="newCustomer.shipping.street1"
						/>
						<input
							type="text"
							class="form-control w-75"
							placeholder="Apt / Suite"
							v-model="newCustomer.shipping.street2"
						/>
						<input
							type="text"
							class="form-control w-75"
							placeholder="City"
							v-model="newCustomer.shipping.city"
						/>
						<multiselect class="w-75" :options="allStates" track-by="name" label="name" v-model="newCustomer.shipping.state" placeholder="State" :show-labels="false"/>
						<input
							type="text"
							class="form-control w-75"
							placeholder="Zip"
							v-model="newCustomer.shipping.zip"
						/>
					</div>
					<div class="form-group col" style="margin-left: 10%">
						<label for="cust_name" class="w-75 text-left ml-n5 mt-2">Billing Name</label>
						<input
							id="cust_name"
							type="text"
							class="form-control w-75"
							placeholder="Billing Name"
							v-model="newCustomer.billing_name"
						/>
						<label for="cust_bill" class="w-75 text-left ml-n5 mt-2">Billing Address<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
						<input
							required
							id="cust_bill"
							type="text"
							class="form-control w-75"
							placeholder="Street"
							v-model="newCustomer.billing.street1"
						/>
						<input
							required
							type="text"
							class="form-control w-75"
							placeholder="Apt / Suite"
							v-model="newCustomer.billing.street2"
						/>
						<input
							required
							type="text"
							class="form-control w-75"
							placeholder="City"
							v-model="newCustomer.billing.city"
						/>
						<multiselect class="w-75" :options="allStates" track-by="name" label="name" v-model="newCustomer.billing.state" placeholder="State" :show-labels="false" required/>
						<input
							required
							type="text"
							class="form-control w-75"
							placeholder="Zip"
							v-model="newCustomer.billing.zip"
						/>
					</div>
				</div>
				<label for="cust_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone Number</label>
				<input
					id="cust_phone"
					type="text"
					class="form-control"
					placeholder="Phone Number"
					v-model="newCustomer.phone"
					style="margin-left: 20%; width: 60%"
				/>
				<label for="cust_tax" class="w-75 text-left mt-2" style="margin-left: 13.5%">Tax Exempt Number</label>
				<input
					id="cust_tax"
					type="text"
					class="form-control"
					placeholder="Tax Exempt Number"
					v-model="newCustomer.tax"
					style="margin-left: 20%; width: 60%"
				/>
				<label for="efiling" class="w-75 text-left mt-2" style="margin-left: 13.5%">Electronic Billing</label>
				<multiselect
					id="efiling"
					:clearable="true"
					:searchable="false"
					:options="['0', '1', '2']"
					v-model="newCustomer.electronic"
					placeholder="Electronic Invoicing"
					:show-labels="false"
					style="margin-left: 20%; width: 60%"
					>
					<template slot="option" slot-scope="prop">
						{{ prop.option === "1" ? "Yes" : prop.option === "2" ? "Both" : "No" }}
					</template>
				</multiselect>
				<br />
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";

export default{
	components:{Multiselect},
	data(){
		return{
			allStates: [
				{
					"name": "Alabama",
					"abbreviation": "AL"
				},
				{
					"name": "Alaska",
					"abbreviation": "AK"
				},
				{
					"name": "American Samoa",
					"abbreviation": "AS"
				},
				{
					"name": "Arizona",
					"abbreviation": "AZ"
				},
				{
					"name": "Arkansas",
					"abbreviation": "AR"
				},
				{
					"name": "California",
					"abbreviation": "CA"
				},
				{
					"name": "Colorado",
					"abbreviation": "CO"
				},
				{
					"name": "Connecticut",
					"abbreviation": "CT"
				},
				{
					"name": "Delaware",
					"abbreviation": "DE"
				},
				{
					"name": "District Of Columbia",
					"abbreviation": "DC"
				},
				{
					"name": "Federated States Of Micronesia",
					"abbreviation": "FM"
				},
				{
					"name": "Florida",
					"abbreviation": "FL"
				},
				{
					"name": "Georgia",
					"abbreviation": "GA"
				},
				{
					"name": "Guam",
					"abbreviation": "GU"
				},
				{
					"name": "Hawaii",
					"abbreviation": "HI"
				},
				{
					"name": "Idaho",
					"abbreviation": "ID"
				},
				{
					"name": "Illinois",
					"abbreviation": "IL"
				},
				{
					"name": "Indiana",
					"abbreviation": "IN"
				},
				{
					"name": "Iowa",
					"abbreviation": "IA"
				},
				{
					"name": "Kansas",
					"abbreviation": "KS"
				},
				{
					"name": "Kentucky",
					"abbreviation": "KY"
				},
				{
					"name": "Louisiana",
					"abbreviation": "LA"
				},
				{
					"name": "Maine",
					"abbreviation": "ME"
				},
				{
					"name": "Marshall Islands",
					"abbreviation": "MH"
				},
				{
					"name": "Maryland",
					"abbreviation": "MD"
				},
				{
					"name": "Massachusetts",
					"abbreviation": "MA"
				},
				{
					"name": "Michigan",
					"abbreviation": "MI"
				},
				{
					"name": "Minnesota",
					"abbreviation": "MN"
				},
				{
					"name": "Mississippi",
					"abbreviation": "MS"
				},
				{
					"name": "Missouri",
					"abbreviation": "MO"
				},
				{
					"name": "Montana",
					"abbreviation": "MT"
				},
				{
					"name": "Nebraska",
					"abbreviation": "NE"
				},
				{
					"name": "Nevada",
					"abbreviation": "NV"
				},
				{
					"name": "New Hampshire",
					"abbreviation": "NH"
				},
				{
					"name": "New Jersey",
					"abbreviation": "NJ"
				},
				{
					"name": "New Mexico",
					"abbreviation": "NM"
				},
				{
					"name": "New York",
					"abbreviation": "NY"
				},
				{
					"name": "North Carolina",
					"abbreviation": "NC"
				},
				{
					"name": "North Dakota",
					"abbreviation": "ND"
				},
				{
					"name": "Northern Mariana Islands",
					"abbreviation": "MP"
				},
				{
					"name": "Ohio",
					"abbreviation": "OH"
				},
				{
					"name": "Oklahoma",
					"abbreviation": "OK"
				},
				{
					"name": "Oregon",
					"abbreviation": "OR"
				},
				{
					"name": "Palau",
					"abbreviation": "PW"
				},
				{
					"name": "Pennsylvania",
					"abbreviation": "PA"
				},
				{
					"name": "Puerto Rico",
					"abbreviation": "PR"
				},
				{
					"name": "Rhode Island",
					"abbreviation": "RI"
				},
				{
					"name": "South Carolina",
					"abbreviation": "SC"
				},
				{
					"name": "South Dakota",
					"abbreviation": "SD"
				},
				{
					"name": "Tennessee",
					"abbreviation": "TN"
				},
				{
					"name": "Texas",
					"abbreviation": "TX"
				},
				{
					"name": "Utah",
					"abbreviation": "UT"
				},
				{
					"name": "Vermont",
					"abbreviation": "VT"
				},
				{
					"name": "Virgin Islands",
					"abbreviation": "VI"
				},
				{
					"name": "Virginia",
					"abbreviation": "VA"
				},
				{
					"name": "Washington",
					"abbreviation": "WA"
				},
				{
					"name": "West Virginia",
					"abbreviation": "WV"
				},
				{
					"name": "Wisconsin",
					"abbreviation": "WI"
				},
				{
					"name": "Wyoming",
					"abbreviation": "WY"
				}
			],
			newCustomer: {
				name: "",
				shippingAddress: "",
				shipping: {
					street1: '',
					street2: '',
					city: '',
					state: '',
					zip: ''
				},
				billing: {
					street1: '',
					street2: '',
					city: '',
					state: '',
					zip: ''
				},
				tax_exempt: "",
				picture: "",
				electronic: "",
			},
		}
	},
	mounted(){

	},
	methods:{
		checkNewAddress(){
		  let checkshipping = false;
		  let checkbilling = false;
		  var shipAddressObj, billAddressObj;
		  if(
			(this.newCustomer.shipping.street1 === undefined || this.newCustomer.shipping.street1 === "") &&
			(this.newCustomer.shipping.city === undefined || this.newCustomer.shipping.city === "") &&
			(this.newCustomer.shipping.state === undefined || this.newCustomer.shipping.state === "") &&
			(this.newCustomer.shipping.zip === undefined || this.newCustomer.shipping.zip === "")
		  ){
			shipAddressObj = {
			  company: this.newCustomer.name,
			  name: this.newCustomer.name,
			};
			this.newCustomer.shippingAddress = ""
			checkshipping = true;
		  }
		  if(
			(this.newCustomer.billing.street1 === undefined || this.newCustomer.billing.street1 === "") &&
			(this.newCustomer.billing.city === undefined || this.newCustomer.billing.city === "") &&
			(this.newCustomer.billing.state === undefined || this.newCustomer.billing.state === "") &&
			(this.newCustomer.billing.zip === undefined || this.newCustomer.billing.zip === "")
		  ){
			billAddressObj = {
			  company: this.newCustomer.name,
			  name: this.newCustomer.billing_name,
			};
			this.newCustomer.billingAddress = ""
			checkbilling = true;
		  }
		  if(
			(this.newCustomer.shipping.street1 !== undefined && this.newCustomer.shipping.street1 !== "") &&
			(this.newCustomer.shipping.city !== undefined && this.newCustomer.shipping.city !== "") &&
			(this.newCustomer.shipping.state !== undefined && this.newCustomer.shipping.state !== "") &&
			(this.newCustomer.shipping.zip !== undefined && this.newCustomer.shipping.zip !== "")
		  ){
			if (this.newCustomer?.shipping?.street2 !== undefined && this.newCustomer?.shipping?.street2 !== '' ) {
			  shipAddressObj = {
				street1: this.newCustomer?.shipping?.street1,
				street2: this.newCustomer?.shipping?.street2,
				city: this.newCustomer?.shipping?.city,
				state: this.newCustomer?.shipping?.state?.abbreviation,
				zip: this.newCustomer?.shipping?.zip,
				country: "US",
				company: this.newCustomer.name,
				name: this.newCustomer.name,
			  };
			  this.newCustomer.shippingAddress =
				this.newCustomer?.shipping?.street1 +
				", " +
				this.newCustomer?.shipping?.street2 +
				", " +
				this.newCustomer?.shipping?.city +
				", " +
				this.newCustomer?.shipping?.state?.abbreviation +
				" " +
				this.newCustomer?.shipping?.zip
			}
			else{
			  shipAddressObj = {
				street1: this.newCustomer?.shipping?.street1,
				city: this.newCustomer?.shipping?.city,
				state: this.newCustomer?.shipping?.state?.abbreviation,
				zip: this.newCustomer?.shipping?.zip,
				country: "US",
				company: this.newCustomer.name,
				name: this.newCustomer.name,
			  };
			  this.newCustomer.shippingAddress =
				this.newCustomer?.shipping?.street1 +
				", " +
				this.newCustomer?.shipping?.city +
				", " +
				this.newCustomer?.shipping?.state?.abbreviation +
				" " +
				this.newCustomer?.shipping?.zip
			}
			checkshipping = true;
		  }
		  if(
			(this.newCustomer.billing.street1 !== undefined && this.newCustomer.billing.street1 !== "") &&
			(this.newCustomer.billing.city !== undefined && this.newCustomer.billing.city !== "") &&
			(this.newCustomer.billing.state !== undefined && this.newCustomer.billing.state !== "") &&
			(this.newCustomer.billing.zip !== undefined && this.newCustomer.billing.zip !== "")
		  ){
			if (this.newCustomer?.billing?.street2 !== undefined && this.newCustomer?.billing?.street2 !== '' ) {
			  billAddressObj = {
				street1: this.newCustomer?.billing?.street1,
				street2: this.newCustomer?.billing?.street2,
				city: this.newCustomer?.billing?.city,
				state: this.newCustomer?.billing?.state?.abbreviation,
				zip: this.newCustomer?.billing?.zip,
				country: "US",
				company: this.newCustomer.name,
				name: this.newCustomer.name,
			  };
			  this.newCustomer.billingAddress =
				this.newCustomer?.billing?.street1 +
				", " +
				this.newCustomer?.billing?.street2 +
				", " +
				this.newCustomer?.billing?.city +
				", " +
				this.newCustomer?.billing?.state?.abbreviation +
				" " +
				this.newCustomer?.billing?.zip
			}
			else{
			  billAddressObj = {
				street1: this.newCustomer?.billing?.street1,
				city: this.newCustomer?.billing?.city,
				state: this.newCustomer?.billing?.state?.abbreviation,
				zip: this.newCustomer?.billing?.zip,
				country: "US",
				company: this.newCustomer.name,
				name: this.newCustomer.name,
			  };
			  this.newCustomer.billingAddress =
				this.newCustomer?.billing?.street1 +
				", " +
				this.newCustomer?.billing?.city +
				", " +
				this.newCustomer?.billing?.state?.abbreviation +
				" " +
				this.newCustomer?.billing?.zip
			}
			checkbilling = true;
		  }

		  if(checkshipping === true && checkbilling === true){
			this.pushCustomer(shipAddressObj, billAddressObj)
		  }
		  else{
			if(checkshipping === false){
			  alert("Shipping address must be a blank or complete address")
			}
			else if(checkbilling === false){
			  alert("Billing address must be a blank or complete address")
			}
		  }
		},

		async pushCustomer(shipAddressObj, billAddressObj) {
			//Safety Check
			if(this.newCustomer.name === '' || this.newCustomer.name === null || this.newCustomer.name === undefined){
				alert("New customer requires a name")
				return;
			}

		  await axios
			.post(process.env.VUE_APP_API_TARGET + "/customers/" + this.$globals.companyId, {
			  name: this.newCustomer.name,
			  shipping: this.newCustomer.shippingAddress,
			  billing: this.newCustomer.billingAddress,
			  tax_exempt: this.newCustomer.tax,
			  electronic: this.newCustomer.electronic,
			  qbid: null,
			  phone: this.newCustomer.phone,
			  billing_name: this.newCustomer.billing_name || this.newCustomer.name
			},
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then(async (response) => {
			  alert("Customer Created Successfully");
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newCustomer', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		  //TODO: MAKE THE CUSTOMER ACCOUNT IN QUICKBOOKS
		  if(this.$globals.qbo){
			axios.post(`${process.env.VUE_APP_API_TARGET}/qbo/customer/${this.$globals.companyId}`, {
			  name: this.newCustomer.name,
			  taxable: this.newCustomer.tax ? false : true,
			  shipaddr: shipAddressObj,
			  billaddr: billAddressObj,
			}, {
			  headers:{
				'Authorization': `Bearer ${this.$globals.user.auth0Token}`
			  }
			}).then(async (response) => {})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newCustomer', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		  }

		  this.$emit("return")
		},
	}

}




</script>

<template>
	<div>
		<div v-if="action === 'viewReturns'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h2><b>Return Parts</b></h2>
						<h3 v-if="subaction === 'returnToStorage'"> to Storage</h3>
						<h3 v-if="subaction === 'returnToVendor'"> to Vendor</h3>
					</div>
					<div class="card-tools">
						<b-button
							class="mx-1 my-3"
							v-if="subaction === 'returnToStorage'"
							style="background-color: #fff4; border-color: #dc3545"
							@click="returnPartsToStorage(partReturn.return_parts)"
							>Submit
						</b-button>

						<b-button
							class="mx-1 my-3"
							v-if="subaction === 'returnToVendor'"
							style="background-color: #fff4; border-color: #dc3545"
							@click="viewReturnSheet(returnToVendorQty)"
							>View Documents
						</b-button>

						<b-button
							class="mx-1 my-3"
							style="background: #fff4; border: #dc3545"
							v-if="subaction === '' || subaction === 'returnToStorage'"
							@click="onReturnToVendorButton(partReturn.return_parts.vendor, partReturn.return_parts.itemid)"
							>Return to Vendor
						</b-button>

						<b-button
							class="mx-1 my-3"
							style="background: #fff4; border: #dc3545"
							v-if="subaction === '' || subaction === 'returnToVendor'"
							@click="onReturnToStorageButton(partReturn.return_parts.itemid)"
							>Return to Storage
						</b-button>

						<b-button
							class="mx-1 my-3"
							style="background-color: #fff4; border-color: #dc3545"
							@click.prevent="$emit('backFromViewReturn')"
							>Return
						</b-button>
					</div>
				</div>

				<div class="card-body">
					<div style="display: flex;">
						<img
							class="item"
							v-if="partReturn.return_parts.image"
							v-bind:src="partReturn.return_parts.image"
							style="max-height: 250px; margin-left: 2.5%; margin-right: 5%; margin-top: 0%"
						/>

						<div style="padding-left: 20px; width: 70%; max-height: 90vh; min-height: 30vh;">
							<div v-if="partReturn.completed > 0" style="display: flex;">
								<p><b>All Parts Returned</b></p><i class="fas fa-check" style="color: green; margin-left: 10px; margin-top: 5px;"></i>
							</div>

							<div>
								<p><b>Item ID: </b>{{partReturn.return_parts.itemid}}</p>
								<p><b>Description: </b>{{partReturn.return_parts.itemdesc}}</p>
								<p><b>Qty to Return: </b>{{partReturn.return_parts.returnAmount}}</p>
							</div>

							<div v-if="partReturn.completed < 1">

								<div v-if="subaction === 'returnToStorage'" style="width: 100%;">
									<div style="width: 100%; display: inline; vertical-align: center;">

										<div style="width: 25%;">
											<label for="returnLocationStorage">Return Qty<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
											<input required id="returnQtyStorage" style="width: 40%" class="form-control ml-1" type="number" min="0" placeholder="Qty" v-model="returnQty">
										</div>

										<label for="returnLocationStorage" style="margin-top: 10px;" >Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
										<div style="width: 100%; display: flex;">
											<div style="width: 45%">
												<div style="width: 100%;">
													<multiselect
														required
														v-model="selectedLocation"
														:options="allLocations"
														style="display: inline-block"
														track-by = "name"
														label = "name"
														:show-labels="false"
														:clearable="true"
														placeholder="Location"
														@input="changeLocation(selectedLocation)"
													>
													</multiselect>
												</div>
												<div v-if="selectedLocation !== null && selectedLocation.name !== undefined && selectedLocation.name.includes('**New**')" style="margin-top: 10px; display: flex; width: 400px;">
													<input type="text" class="form-control w-50" v-model = "tempLocation" placeholder="New Location" style="display: inline; margin-right: 5px; height: 40px;"/>
													<multiselect
														class="w-50"
														v-model="selectedLocationType"
														:options="locationTypes"
														style="display: inline-block"
														:show-labels="false"
														:clearable="true"
														placeholder="Type"
													>
													</multiselect>
													<i class="fas fa-plus-circle text-left fa-lg" @click="handleNewLocation" style="display: inline; margin-top: 2.5%; margin-left: 10px;"></i>
												</div>
											</div>
											<div style="display: flex; align-items: center; justify-content center; margin-left: 15px; height: 40px; width: 40%;">
												<div><b>Fulfilled From</b>
													<br>
													<div class="row" v-for="(data, index) in partReturn.return_parts.fulfillment" v-bind:key="index">
														<div class="col" v-if="data.qty > 0">
															{{data.location}} : {{data.bin}}
															<input type="radio" name="originalLocation" v-model="originalLocation" :value="data.location" @click="handleOriginalLocation(data.location, data.bin)" style="margin-left: 15px;" />
														</div>
													</div>
												</div>
											</div>
										</div>

										<label for="returnLocationStorageBin" style="margin-top: 10px;" >Bin Location<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
										<div style="width: 100%; display: flex;">
											<div style="width: 45%">
												<div style="width: 100%;">
													<multiselect
														required
														class="w-50"
														v-model="selectedBin"
														:options="binList"
														style="display: inline-block"
														:show-labels="false"
														:clearable="true"
														placeholder="Bin"
													>
													</multiselect>
												</div>
												<div v-if="selectedBin !== null && selectedBin.includes('**New**')" style="margin-top: 10px;">
													<input type="text" class="form-control w-50" v-model = "tempBin" placeholder="New Bin" style="display: inline"/>
													<i class="fas fa-plus-circle text-left fa-lg" @click="selectedBin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%"></i>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>

                          <div v-if="partReturn.return_parts.all_returned !== true" style="width: 100%;">


                            <div v-if="subaction === 'returnToVendor'" style="width: 100%;">

                              <div style="width: 100%; display: inline; vertical-align: center;" v-show="returnToVendorBool">
                                <div style="display: flex; width: 100%; justify-content: center; margin-bottom: 20px;">
                                  <div style="width: 33%;">
                                    <label for="productID">Product:</label>
                                    <p>{{partReturn.return_parts.itemid}}</p>
                                  </div>

                                  <div style="width: 33%;">
                                    <label for="returnToValue">To:</label>
                                    <p>{{returnToVendor.address}}</p>
                                  </div>

                                  <div style="width: 33%;" v-if="customer !== null">
                                    <label for="customerValue">Customer:</label>
                                    <p>{{customer.customer}}</p>
                                  </div>
                                </div>

                                <div style="display: flex; width: 100%; justify-content: center; margin-bottom: 20px;">
                                  <div style="width: 33%">
                                    <label for="RGAinputValue">RGA Number:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                                    <input required id="RGAinputValue" style="width: 60%;" class="form-control ml-1" type="text" name="RGAinputValue" min="0" placeholder="RGA" v-model="RGAinput" onkeyup="this.value = this.value.toUpperCase();" maxlength="40">
                                  </div>

                                  <div style="width: 33%">
                                    <label for="returnToVendorQty">Qty:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                                    <input required id="returnToVendorQty" style="width: 25%;" class="form-control ml-1" type="text" name="returnToVendorQty" min="0" placeholder="Qty" v-model="returnToVendorQty">
                                  </div>

                                  <div style="width: 33%">
                                    <label for="serialNumberInput">Serial Number:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                                    <input required id="serialNumberInput" style="width: 60%;" class="form-control ml-1" type="text" name="serialNumberInput" min="0" placeholder="Serial Number" v-model="serialNumber" onkeyup="this.value = this.value.toUpperCase();" maxlength="40">
                                  </div>
                                </div>

                                <div style="width: 100%; margin-bottom: 20px;">
                                  <label for="reasonForReturnvalue">Reason:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                                  <textarea
									required
                                    type="text"
                                    style="
                                      width: 85%;
                                      height: 140px;
                                      border-radius: 8px;
                                      margin-top: 2px;
                                      border-color: grey;
                                      text-align: left;
                                    "
                                    class="form-control ml-1"
                                    id="returnReason"
                                    v-model="returnReason"
                                  ></textarea>
                                </div>

                                <div style="width: 100%;">
                                  <label for="returnNotes">Notes:</label>
                                  <textarea
                                    type="text"
                                    style="
                                      width: 85%;
                                      height: 140px;
                                      border-radius: 8px;
                                      margin-top: 2px;
                                      border-color: grey;
                                      text-align: left;
                                    "
                                    class="form-control ml-1"
                                    id="returnNotes"
                                    v-model="returnNotes"
                                  ></textarea>
                                </div>


                              </div>

                            </div>
                          </div>
                      </div>

                  </div>

            </div>
      </div>
    </div>

    <div v-if="action === 'viewReturnSheet'">
      <view-document :returnToVendorQty="parseInt(returnToVendorQty)" :serialNumber="serialNumber" :customer="customer" :partReturn="partReturn" :RGA="RGAinput" :product="partID" :returnReason="returnReason" :returnNotes="returnNotes" :vendor="returnToVendor" @backFromViewDocument="backFromViewDocument"></view-document>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Multiselect from 'vue-multiselect'
import ViewDocument from "./viewDocument.vue"


export default{
	name: "ViewReturn",
	components: {
	    Multiselect,
	    ViewDocument,
	},
	props: {
		partReturn: Object,
	},

  data() {
    return {
		allLocations: [],
		action: "viewReturns",
		binList: [],
		customer: null,
		locationTypes:[
			"Truck",
			"Warehouse"
		],
		originalLocation: false,
		partID: null,
		returnToVendorBool: false,
		returnQty: 0,
		RGAinput: "",
		returnToVendor: [],
		returnNotes: "",
		returnReason: "",
		returnToVendorQty: null,
		selectedLocation: null,
		subaction: "",
		selectedLocationType: null,
		serialNumber: "",
		selectedBin: null,
		tempBin: "",
		tempLocation: "",
    }
  },

  mounted() {
	  this.getAllLocations()
  },

  methods: {
	async backFromViewDocument(){
		this.action = 'viewReturns'
		this.returnToVendorQty = null
		this.serialNumber = ""
		this.returnNotes = ""
		this.returnReason = ""
		this.RGAinput = ""
		this.$forceUpdate()
	},

	changeLocation(value){
	  this.selectedLocation = value;
	  this.selectedBin = null
	  this.originalLocation = false
	  if(this.selectedLocation !== null && this.selectedLocation.name !== undefined)
		this.getDistinctBins(value)
	},

	async getDistinctBins(locObj){
        axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${encodeURIComponent(locObj.name)}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        ).then((response)=>{
          this.binList = []
				  this.binList = response.data.queryResult
				  for(var item in this.binList){
				  	this.binList[item] = this.binList[item].toUpperCase()
				  }
          this.binList.unshift('**New**')
        })
        .catch((response)=>{
          this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })
    },

	async getAllLocations(){
		await axios
		.get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then((response) => {
			this.allLocations = response.data.queryResult;
			this.allLocations.unshift({"name": '**New**'})
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
	},

    async handleNewLocation() {
      if (this.tempLocation === null || this.tempLocation === undefined || this.tempLocation === ""){
        alert("Please included a location name")
        return;
      }
      if (this.selectedLocationType === null || this.selectedLocationType === undefined){
        alert("Please include a location type")
        return;
      }

      await axios.post(process.env.VUE_APP_API_TARGET + "/inventory/locations/" + this.$globals.companyId,
        {
          name: this.tempLocation,
          type: this.selectedLocationType
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .catch((response)=>{
          this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })
      this.selectedLocation = {"name": this.tempLocation}
      this.tempLocation = ""
      this.selectedLocationType = null
    },

	handleOriginalLocation(location, bin){
		if(this.selectedLocation?.name == location && this.selectedBin == bin){
			this.originalLocation = false;
		}
		else{
			this.originalLocation = true
		}
		this.originalLocation === true ? this.selectedLocation = {"name": location}: this.selectedLocation = null
		this.originalLocation === true ? this.selectedBin = bin : this.selectedBin = null
    },

    async onReturnToStorageButton(itemid){
      this.subaction = "returnToStorage"
      this.returnToVendorBool = false
    },

    async onReturnToVendorButton(vendor, partid){
      this.subaction = "returnToVendor"
      this.returnToVendorBool = !this.returnToVendorBool
      if(this.returnToVendorBool === true){
        await axios.get(process.env.VUE_APP_API_TARGET + '/vendors/company/'+encodeURIComponent(vendor)+"/"+this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
          .then(response => {
            this.returnToVendor = response.data.queryResult[0]
          })
          .catch(error => {
            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
          })

        await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/ticketid/${this.partReturn.ticketid}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
          .then(response => {
            this.customer = response.data.queryResult[0]
            this.partID = partid
          })
          .catch(error => {
            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
          })
      }
    },

    async returnPartsToStorage(parts){
      // Checks to makesure a location and qty have been set
	  this.returnQty = parseInt(this.returnQty)
      if(this.returnQty > parts.returnAmount || this.returnQty === undefined || this.returnQty === null || this.returnQty === 0 || this.returnQty === ''){
        alert("Invalid Return Quantity")
        return;
      }
      if(this.selectedLocation === null || this.selectedLocation === "" || this.selectedLocation.name === undefined || this.selectedLocation.name.includes('**')){
        alert("No Return Location Selected")
        return;
      }
      if(this.selectedBin === null || this.selectedBin === "" || this.selectedBin.includes('**')){
        alert("No Return Bin Selected")
        return;
      }

      let holder = []

	  for(let i in parts.fulfillment){
		  if(parts.fulfillment[i].qty > 0){
			  for(let j in parts.fulfillment[i].usedCount){
				  holder.push(parts.fulfillment[i].usedCount[j])
			  }
		  }
	  }

	  //sorted low to high since backend pulls from the back of the list.
	  holder.sort((a,b) =>(a.costDate < b.costDate) ? -1: 1)

      // update inventory after part returned
	  if(parts.warrantyFlag){
		  await axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(parts.itemid)}/${encodeURIComponent(this.selectedLocation.name)}/${encodeURIComponent(this.selectedBin)}/${this.$globals.companyId}`, {
			  qty: 0 - parseInt(this.returnQty),
			  vendor: parts.vendor,
			  addBack: holder,
		  }, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	  }
	  else{
		  await axios.patch(process.env.VUE_APP_API_TARGET + "/inventory/update/target/"+encodeURIComponent(parts.itemid)+"/"+encodeURIComponent(this.selectedLocation.name)+"/"+encodeURIComponent(this.selectedBin)+"/"+this.$globals.companyId, {
			qty: 0 - parseInt(this.returnQty),
			vendor: parts.vendor,
			addBack: holder,
		  }, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	  }


      let part_returns = typeof this.partReturn.return_parts !== 'object' ? JSON.parse(this.partReturn.return_parts) : this.partReturn.return_parts

      part_returns.returnAmount = part_returns.returnAmount - parseInt(this.returnQty)
	  part_returns.fulfilledQty = part_returns.fulfilledQty - parseInt(this.returnQty)


      if(part_returns.returnAmount === 0){
        this.partReturn.completed = 1
      }



	  //fix is an array of used items that are going to be put back. They need removed from the ticket so that only part of the qty is returned it doesn't double dip on items
	  let fix = []
	  while(this.returnQty > 0){
		  let tempDiff = holder[holder.length-1].qty - parseInt(this.returnQty)
		  if(tempDiff < 0){
			  this.returnQty = Math.abs(tempDiff)
			  fix.push(holder.pop())
		  }
		  else if (tempDiff > 0){
			  fix.push({
				  qty: parseInt(this.returnQty),
				  cost: holder[holder.length-1].cost,
				  costDate: holder[holder.length-1].costDate
			  })
			  break;
		  }
		  else{
			  fix.push(holder.pop())
			  break;
		  }
	  }

	  //Since we lose where the usedCount data comes from we have to step through every option and adjust
	  for(let f in fix){
		  for(let i in parts.fulfillment){
			  if(parts.fulfillment[i].qty > 0){
				  parts.fulfillment[i].usedCount = parts.fulfillment[i].usedCount.map((pointer) =>{
					  if(pointer.cost === fix[f].cost && pointer.costDate === fix[f].costDate){
						  pointer.qty = pointer.qty - fix[f].qty
					  }
					  return pointer
				  })
			  }
		  }
	  }

      // update part return
      await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/return_parts/"+this.$globals.companyId, {
        data: JSON.stringify(part_returns),
        returnid: this.partReturn.returnid,
        status: this.partReturn.completed,
		task: this.partReturn.task,
		ticketid: this.partReturn.ticketid
      }, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .catch((response)=>{
        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
      })

	  if(this.partReturn.completed < 1){
		  // Re-get part return
		  await axios.get(process.env.VUE_APP_API_TARGET + "/tickets/get_return/"+this.partReturn.returnid+"/"+this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then(response => {
			this.partReturn = response.data.queryResult[0]
			this.partReturn.return_parts = JSON.parse(this.partReturn.return_parts)
		  })
		  .catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	  }
	  else{
		  //is complete
		  this.$emit('backFromViewReturn')
	  }

  	},

	viewReturnSheet(returnAmount){
	  if(this.RGAinput === ""){
		alert("RGA field is blank")
		return;
	  }
	  if(this.returnToVendorQty === null || !parseInt(this.returnToVendorQty)){
		alert("Invalid return quantity")
		return;
	  }
	  if(this.returnToVendorQty > parseInt(returnAmount)){
		alert("Invalid return quantity")
		return;
	  }
	  this.action = 'viewReturnSheet'
	},

  }
}

</script>

<template>
    <div>
        <div v-if="action ===''" class="card card-danger" style="padding-bottom: 1%">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>New Vehicle</b></h3>
                </div>
                <div class="card-tools">
                    <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -5%" @click.prevent="pushVehicle()">Submit</b-button>
                    <b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%" @click.prevent="clearAsset(); $emit('return')">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <form>
                    <div class="row d-flex">
                        <div class="form-group col" style="margin-left: 10%">
							<label class="w-75 text-left ml-n5 mt-2">Customer<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <multiselect required class="w-75" id="custSelect" label="name" track-by="name" :clearable="true" :options="allCustomers" :show-labels="false" v-model="newVehicle.customer" placeholder="Customer" @select="handleSelect(...arguments)" @search-change="debounceCustomers">
                                <template slot="option" slot-scope="props">
                                    <intersect v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
                                        <span>{{props.option.name}}</span>
                                    </intersect>
                                </template>
                            </multiselect>
                            <label for="unit" class="w-75 text-left ml-n5 mt-2">Unit #<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input required id="unit" type="text" class="form-control w-75" placeholder="Unit #" v-model="newVehicle.unit" />
                            <label for="vin" class="w-75 text-left ml-n5 mt-2">VIN<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                            <input required id="vin" type="text" class="form-control w-75" placeholder="VIN #" v-model="newVehicle.vin" />
                            <label for="miles" class="w-75 text-left ml-n5 mt-2">Mileage</label>
                            <input id="miles" type="text" class="form-control w-75" placeholder="Mileage" v-model="newVehicle.miles" />
                            <label for="hours" class="w-75 text-left ml-n5 mt-2">Hours</label>
                            <input id="hours" type="text" class="form-control w-75" placeholder="Hours" v-model="newVehicle.hours" />
							<!-- TODO: Add functionality to this -->
							<label for="pic" class="w-75 text-left ml-n5 mt-2">Image</label>
							<input id="pic" type="file" class="form-control-file" />
                        </div>
                        <div class="form-group col" style="margin-left: 10%">
							<label class="w-75 text-left ml-n5 mt-2">Location</label>
							<div class="w-75 d-flex">
                                <multiselect :searchable="true" v-model="newVehicle.house" :clearable="true" :options="allHouses" label="name" track-by="name" placeholder="Location" :show-labels="false"></multiselect>
								<i class="fas fa-plus-circle" @click="action = 'addHouse'" style="margin-top:3.5%; margin-left: 1%"></i>
                            </div>
                            <label for="make" class="w-75 text-left ml-n5 mt-2">Make</label>
                            <input id="make" type="text" class="form-control w-75" placeholder="Vehicle Make" v-model="newVehicle.make" />
                            <label for="model" class="w-75 text-left ml-n5 mt-2">Model</label>
                            <input id="model" type="text" class="form-control w-75" placeholder="Vehicle Model" v-model="newVehicle.model" />
                            <label for="year" class="w-75 text-left ml-n5 mt-2">Year</label>
                            <input id="year" type="text" class="form-control w-75" placeholder="Vehicle Year" v-model="newVehicle.year" />
							<label for="Note" class="w-75 text-left ml-n5 mt-2">Note</label>
							<textarea id="Note" class="form-control w-75" placeholder="Vehicle Note" v-model="newVehicle.note" />
                        </div>
                    </div>
					<div class="row d-flex">
						<div class="form-group col" style="margin-left: 10%; text-align: center; text-justify: inter-word">
							<strong>Warranty Information: </strong><br>
							<div class="row" v-for="(warranty, index) in newVehicle.warranty_fields" :key="index">
								<div class="col">
									<label>Start/In Service</label>
									<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="warranty.start"/>
								</div>

								<div class="col">
									<label>Field</label><br>
									{{warranty.name}}
								</div>

								<div class="col">
									<label>Value</label>
									<input type="text" class="form-control w-75" placeholder="Warranty Value" v-model="warranty.value"/>
									<i class="fas fa-trash" style="display: inline-block; margin-top: -15%; float: right" @click="newVehicle.warranty_fields.splice(index, 1)"></i>

								</div>

							</div>

							<div class="row">
								<div class="col">
									<label>Start/In Service</label>
									<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newWarranty.start"/>
								</div>

								<div class="col">
									<label>Field</label>
									<input class="form-control" type="text" v-model="newWarranty.name" placeholder="Field Name" />
								</div>

								<div class="col">
									<label>Value</label>
									<input class="form-control w-75" type="text" v-model="newWarranty.value" placeholder="Field Value" />
									<i class="fas fa-plus-circle" style="display: inline-block; float: right; margin-top: -15%" @click.prevent="pushNewWarranty()"></i>

								</div>

							</div>
						</div>

						<div class="form-group col" style="margin-left: 10%; text-align: center; text-justify: inter-word">
							<b>Technical Information:</b> <br>
							<div class="row" v-for="(value, key) in newVehicle.technical_fields" :key="key">
								<div class="col">
									<strong>{{ key }}:</strong>
								</div>
								<div class="col">
									<input class="form-control" type="text" v-model="newVehicle.technical_fields[key]" placeholder="Field Value" />
								</div>
								<div class="col">
									<i class="fas fa-trash" style="display: inline-block" @click="removeCustomFields(newVehicle.technical_fields, key)"></i>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<input class="form-control" type="text" v-model="newField.name" placeholder="Field Name" />
								</div>
								<div class="col" style="margin: auto">
									<input class="form-control" type="text" v-model="newField.value" placeholder="Field Value" />
								</div>
								<div class="col">
									<!-- <b-button style="background-color: #dc3545; border-color: #dc3545; margin: auto;" @click.prevent="pushField(newVehicle.technical_fields, 'technical')">Submit</b-button> -->
									<i class="fas fa-plus-circle" @click.prevent="pushField(newVehicle.technical_fields, 'technical')"></i>
								</div>
							</div>
						</div>
					</div>
                </form>
            </div>
        </div>

		<div v-if="action === 'addHouse'">
			<firehouse :customer="selectedCustomer" @cancelHouse= "action =''" @accept="getHouses(selectedCustomer); action=''" ></firehouse>
		</div>
    </div>
</template>

<script>
/*eslint-disable*/
import { debounce } from 'lodash';
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import Intersect from 'vue-intersect';
import firehouse from '../Customers/firehouse.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'


export default {
	name: 'newVehicle',
    props: {
        selectedCustomer: Object,
    },

    components: {
        Multiselect,
        Intersect,
		firehouse,
		VueCtkDateTimePicker,
    },

    data() {
        return {
            allCustomers: [],
            allHouses: [],
			action: '',
            maxPages: 0,
			newField: {
				name: '',
				field: ''
			},
            newVehicle: {
                customer: '',
                house: '',
				warranty_fields: [],
				technical_fields: [],
            },
			newWarranty: {
				start: '',
				name: '',
				value: ''
			},
            pageNumber: 0,
            prevSearch: '',
			usedCustomerUnitIds: [],
        }
    },

    created() {
        this.debounceCustomers = debounce(this.searchCustomers, 600);
    },

    mounted() {
        this.getCustomers()
        this.getHouses(this.selectedCustomer)
        this.newVehicle.customer = this.selectedCustomer
    },

    methods: {
        clearAsset() {
            this.newVehicle = {
                vehicleid: null,
                customerid: null,
                make: '',
                model: '',
                year: '',
                vin: '',
                miles: '',
                hours: '',
                pump_make: '',
                pump_model: '',
                pump_serial: '',
                engine_serial: '',
                trans_serial: '',
                manf_build: '',
                unit: '',
                house: {}
            }
        },//END clearAsset

        async getCustomers(){
            await axios
            .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
            .then((response) => {
                this.allCustomers = response.data.queryResult.results
                this.maxPages = response.data.queryResult.maxPages
                this.pageNumber = this.pageNumber+1
            })
            .catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
        },

        async getMoreCustomers(){
            if(this.pageNumber >= this.maxPages){
                return
            }
            let text = this.prevSearch === "" ? text = null : text = this.prevSearch
            await axios
            .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
            .then((response) => {
                for(var item in response.data.queryResult.results){
                this.allCustomers.push(response.data.queryResult.results[item])
                }
                this.maxPages = response.data.queryResult.maxPages
                this.pageNumber = this.pageNumber+1
            })
            .catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
        },

        async getHouses(option) {
            await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/houses/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
                this.allHouses = response.data.queryResult;
            })
            .catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
			this.getUsedUnitIDs(option)
        },

		async getUsedUnitIDs(option){
			this.usedCustomerUnitIds = []
			await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${option.customerid}/vehicles/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response) => {
				response.data.queryResult.forEach((item, i) => {
					this.usedCustomerUnitIds.push(item.vehicleid)
				});
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

        async handleSelect(args) {
            this.selectedCustomer = args
            await this.getHouses(args)
        },

		pushField(existingField, type) {
			if(this.newField.name == null || this.newField.name === '' || this.newField?.name === undefined){
				alert("New custom field should be named")
				return;
			}
			if(this.newField.value == null || this.newField.value === '' || this.newField?.value === undefined){
				alert("New custom field should have a value")
				return;
			}
			existingField = (existingField === null ? {} : (typeof existingField === 'object' ? existingField : JSON.parse(existingField)))

			existingField[this.newField.name] = this.newField.value;
			if(type == 'technical'){
				this.newVehicle.technical_fields = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})
			}
			else{
				//console.log("Type not provided??");
				return;
			}
			this.newField.value = '';
			this.newField.name = ''
		},

        async pushVehicle() {
            if((this.newVehicle.vin === '' || this.newVehicle.vin === undefined) || (this.newVehicle.unit === '' || this.newVehicle.unit === undefined)){
                alert("A vehicle must have an ID and a VIN")
				return;
            }
			if(this.usedCustomerUnitIds.includes(this.newVehicle.unit)){
				alert("This customer already has this unit id in use")
				return;
			}
			else {
				if(this.newVehicle.technical_fields !== null){
					this.newVehicle.technical_fields = typeof this.newVehicle.technical_fields == 'object' ? JSON.stringify(this.newVehicle.technical_fields) : this.newVehicle.technical_fields
				}
				if(this.newVehicle.warranty_fields !== null || this.newVehicle.warranty_fields.length > 0){
					this.newVehicle.warranty_fields = typeof this.newVehicle.warranty_fields == 'object' ? JSON.stringify(this.newVehicle.warranty_fields) : this.newVehicle.warranty_fields
				}

                await axios.post(
                process.env.VUE_APP_API_TARGET + "/vehicles/" + this.$globals.companyId,
                {
                    vehicleid: this.newVehicle.unit,
                    customerid: this.newVehicle.customer.customerid,
                    make: this.newVehicle.make,
                    model: this.newVehicle.model,
                    year: this.newVehicle.year,
                    vin: this.newVehicle.vin,
                    miles: this.newVehicle.miles,
                    hours: this.newVehicle.hours,
					houseid: this.newVehicle.house?.houseid,
					note: this.newVehicle.note,
					warranty_fields: this.newVehicle.warranty_fields,
					technical_fields: this.newVehicle.technical_fields
                },
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
                ).then(() => {
                    this.$emit('return')
                })
                .catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
            }
        }, //END pushVehicle()

		pushNewWarranty(){
			//NOTE: not a required field but if filled in needs to have correct data
			if(this.newWarranty.start == '' || this.newWarranty.start == null || this.newWarranty.start == undefined){
				alert("Warranty requires a start or an enter service date")
				return;
			}
			if(this.newWarranty.name == '' || this.newWarranty.name == null || this.newWarranty.name == undefined){
				alert("Warranty requires a field")
				return;
			}
			if(this.newWarranty.value == '' || this.newWarranty.value == null || this.newWarranty.value == undefined){
				alert("Warranty requires a value")
				return;
			}

			if(this.newVehicle.warranty_fields == null){
				this.newVehicle.warranty_fields = []
			}
			this.newVehicle.warranty_fields.push({
				name: this.newWarranty.name,
				start: this.newWarranty.start,
				value: this.newWarranty.value
			})

			this.newWarranty = {
				name: '',
				start: '',
				value: ''
			}
		},

		removeCustomFields(vehicleFields, key){
			delete vehicleFields[key]
			this.newField.value = '';
			this.newField.name = ''
			this.$forceUpdate();
		},

        async searchCustomers(searchValue){
            if(searchValue === "" || searchValue === null){
                if(this.prevSearch === "" || this.prevSearch === null){
                return
                }
                searchValue = null
            }
            this.pageNumber = 0
            this.prevSearch = searchValue
            await axios
            .get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${searchValue}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
            .then((response) => {
                this.allCustomers = response.data.queryResult.results
                this.maxPages = response.data.queryResult.maxPages
                this.pageNumber = this.pageNumber+1
            })
            .catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
        },
    }
}
</script>

<style scoped>

</style>

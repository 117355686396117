<template>
  <div>
    <div class="shipping card card-danger" v-if="action === 'home'">
      <div class="card-header">
        <div class="card-title">
          <h3><b>Data Management</b></h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <b-button
              type="button"
              class="actbutton"
              id="importDecsion"
              @click.prevent="action = 'import'"
              style="background-color: #dc3545; border-color: #dc3545"
              >Import</b-button
            >
          </div>
          <div class="col">
            <b-button
              type="button"
              class="actbutton"
              id="exportDecsion"
              @click.prevent="action = 'export'"
              style="background-color: #dc3545; border-color: #dc3545"
              >Export</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Import Section -->
    <div class="shipping card card-danger" v-if="action == 'import'">
      <div class="card-header">
        <div class="card-title">
          <h3><b>Import</b></h3>
        </div>
		<div class="card-tools">
			<b-button
			  type="cancel"
			  class="button"
			  style="background-color: #fff4; border-color: #dc3545"
			  @click.prevent="action = 'home'"
			  >Return
		  </b-button>
		</div>
      </div>
      <div class="card-body" style="text-align:center">
		  <p>Welcome to the import tool, if you have any issues or questions please contact support at support@fleet4.net or call 314-937-5253 for assistance.</p>
		  <p>Please use the examples as a guide as to how to set up your import CSV</p>
		  <b-tabs :title-link-class="'text-danger'" content-class="mt-3" fill lazy v-model="selectedTab">
			  <b-tab title="Inventory" active :title-link-class="'text-danger'">
				  <img
					src="../assets/inventoryGuide.jpg"
					alt="Inventory Guide"
				  />
				  <p>
					  Headers:
					  item, description, cost, sale,
					  location, vendor, quantity
				  </p>
				  <p>
					  The minimum required for an inventory item is just the Item data.
				  </p>
			  </b-tab>
			  <b-tab title="Vendor" :title-link-class="'text-danger'">
				  <img
					src="../assets/vendorGuide.jpg"
					alt="Vendor Guide"
				  />
				  <p>
					  Headers:
					  vendor,
					  phone, address, contact
				  </p>
				  <p>
					  Minimum Required is just the Vendor as seen as the second item. Things unprovided will be blank or Unassigned. No contacts would be created.
				  </p>
				  Address format is expected as "Street1, [Street2,] City, State(Abbr) Zip" without commas the whole address will need edited but will save in Street1
			  </b-tab>
			  <b-tab title="Customer" :title-link-class="'text-danger'">
				  <img
					src="../assets/customerGuide.jpg"
					alt="Customer Guide"
				  />
				  <p>
					  Headers:
					  customer, phone, email,
					  Bill Address, Ship Address, Contact, Balance
				  </p>
				  <p>
					  The minimum required is just a Customer as shown in the 2nd row. Things partialy provided will be resolved as blanks or Unassigned. No Contacts would be created.
				  </p>
				  Address format is expected as "Street1, [Street2,] City, State(Abbr) Zip" without commas the whole address will need edited but will save in Street1
			  </b-tab>
		  </b-tabs>

        <label style="align-self: left;">File Upload<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
        <input
          required
          style="margin-bottom: 10px;"
          class="form-control form-control-lg"
          id="formFileLg"
          type="file"
          ref="inputFile"
          name="doc"
          accept=".csv"
        />
		<b-button
		  type="submit"
		  class="button"
		  style="background-color: #dc3545; border-color: #dc3545"
		  @click.prevent="intake()"
		  >Submit
	  </b-button>
        <br />


      </div>
    </div>

    <!-- Loading Area -->
    <div class="shipping card card-danger" v-if="action == 'loading'">
      <div class="card-header">
        <div class="card-title">
          <h3><b>Import</b></h3>
        </div>
      </div>
      <div class="card-body">
        <strong>Loading...</strong>
        <br />
        <div>
          <b-progress
            :value="progress"
            :max="progressMax"
            variant="danger"
            class="mb-3"
          ></b-progress>
        </div>
        <div>
          <strong
            >Sucessful Objects Imported: {{ this.createdObjects }}
          </strong>
        </div>
        ERRORS: {{ errorArray.length }}<br />
        <div>
          <b-table
            id="errorTable"
            ref="errorTable"
            class="new-styled-table"
            :items="errorArray"
            striped
            table-variant="red"
            :current-page="currentPage"
            style="width: 90%; overflow-x: scroll"
            :per-page="10"
          ></b-table>
          <b-pagination
            class="customPagination"
            size="sm"
            v-model="currentPage"
            :per-page="10"
            limit="3"
            :total-rows="errorArray.length"
            aria-controls="errorTable"
            style="margin-left: 42.5%; margin-right: 42.5%; margin-bottom: 2%"
          ></b-pagination>
        </div>
      </div>
      <b-button
        type="return"
        class="button"
        style="background-color: #dc3545; border-color: #dc3545"
        @click.prevent="action = 'home'"
        >Return</b-button
      >
    </div>

    <!-- Export Section -->
    <div class="shipping card card-danger" v-if="action == 'export'">
      <div class="card-header">
        <div class="card-title">
          <h3><b>Export</b></h3>
          WARNING: Exporting will remove the data from the program and give you
          the only copy!
        </div>
      </div>
      <div class="card-body">
        <div class="row">
                    <div class = "col">
                        <b-button class="button" style="background-color: #dc3545; border-color: #dc3545" @click.prevent="exportTest()">Test</b-button>
                    </div>
        	<div class="col">
	            <b-button
	            	class="button"
					style="background-color: #dc3545; border-color: #dc3545"
					@click.prevent="exportAll()"
					>ALL DATA
				</b-button>
			</div>
			<div class="col">
				<b-button
					style="background-color: #dc3545; border-color: #dc3545"
					@click.prevent="zipInventory()"
					>Inventory
				</b-button>
			</div>
        </div>

        <br />
        <b-button
          type="cancel"
          class="button"
          style="background-color: #dc3545; border-color: #dc3545"
          @click.prevent="action = 'home'"
          >Cancel</b-button
        >
      </div>
    </div>

    <!-- Loading Export -->
    <div class="shipping card card-danger" v-if="action == 'exportLoad'">
      <div class="card-header">
        <div class="card-title">
          <h3><b>Export</b></h3>
        </div>
      </div>
      <div class="card-body">
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import { Socket } from "socket.io-client";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Papa from "papaparse";
import { BPagination } from "bootstrap-vue";
import JSZip from "jszip";
import FileSaver from "file-saver";

export default {
  name: "app",
  props: {
    socket: Socket,
    userid: String,
    companyId: Number,
    user: Object
  },

  components: {
    Multiselect,
    Papa,
    BPagination,
  },

  data() {
    return {
      action: "home",
      createdObjects: 0,
      csvArray: [],
      currentPage: 1,
      errorArray: [],
      progress: 0,
      progressMax: 0,
      selected: "",
	  selectedTab: null,
      type: "",
    };
  }, //END data

  async mounted() {

  },

  destroyed() {
    this.$globals.socket.emit("leaveRoom", `import${this.$globals.companyId}`);
    this.$globals.socket.off("newDataCreated")
    console.log("destroyed import")
},

  methods: {
    async exportAll() {
      let zip = new JSZip();
      this.action = "exportLoad";

      await this.exportInventory();
      await this.exportCustomers();
      await this.exportContacts();
      await this.exportHouses();
      await this.exportInvoices();
      await this.exportNotes();
      await this.exportProposals();
      await this.exportPurchaseOrders();
      await this.exportSalesOrders();
      await this.exportShippingReports();
      await this.exportTickets();
      await this.exportVehicles();
      await this.exportVendors();
      await this.exportVendorContacts();
      await this.exportVendorNotes();
      for (let index in this.csvArray) {
        zip.file(this.csvArray[index].name, this.csvArray[index].data);
      }

      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver.saveAs(content, "Fleet4ExportData.zip");
      });
      this.action = "home";
    }, //END exportAll

    async exportContacts() {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/companies/contacts/" + this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Contacts.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/companies/export/contacts/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportCustomers() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/customers/" + this.$globals.companyId,
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Customer.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/customers/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //exportCustomers

    async exportHouses() {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vehicles/export/firehouses/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Houses.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/vehicles/export/houses/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportInventory() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/inventory/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Inventory.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/inventory/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //END exportInventory

    async exportInvoices() {
      //UNTESTED as there is currenlty no data to test on.
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/sales/invoices/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Invoices.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/sales/export/invoices/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportNotes() {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/customers/notes/export/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "AllCustomerNotes.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/customers/notes/export/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportProposals() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/sales/props/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Proposals.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/sales/export/props/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportPurchaseOrders() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/sales/po/ALL/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "PurchaseOrders.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/sales/export/po/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportSalesOrders() {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/sales/sales_orders/" + this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "SalesOrders.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/sales/export/salesOrders" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportShippingReports() {
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/shipping/ShippingReports/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "ShippingReports.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/shipping/export/ShippingReports/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportTest() {
      //TODO: remove for deployment
      let zip = new JSZip();

      await axios
        .get(process.env.VUE_APP_API_TARGET + "/inventory/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Test.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/inventory/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */

          for (let index in this.csvArray) {
            zip.file(this.csvArray[index].name, this.csvArray[index].data);
          }

          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, "ExportTest.zip");
          });
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportTickets() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/tickets/export/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "AllTickets.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/tickets/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportVehicles() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/vehicles/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Vehicles.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/vehicles/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportVendors() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/vendors/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "Vendors.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/vendors/export/" + this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportVendorContacts() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/vendors/export/contacts/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "AllVendorContacts.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/vendors/export/vendorContact/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async exportVendorNotes() {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/vendors/export/notes/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
        .then((response) => {
          var tempCSV = Papa.unparse(response.data.queryResult);
          var csvData = new Blob([tempCSV], {
            type: "text/csv;charset=utf-8;",
          });
          this.csvArray.push({
            data: csvData,
            name: "AllVendorNotes.csv",
          });
		  /*
          axios.delete(
            process.env.VUE_APP_API_TARGET + "/vendors/export/notes/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  */
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async intake() {
      this.errorArray = [];
      var fileSelector = document.getElementById("formFileLg");
      var dataArray = [];
      if (fileSelector.files[0] == null) {
        alert("Please input a file to upload");
        return;
      }
      var doc = this;
      doc.action = "loading";

      Papa.parse(fileSelector.files[0], {
        header: true,
        complete: async function (results) {
          dataArray = results.data;
          var headers = results.meta.fields;
		  //reads the headers and turns them into trusted stings of lower case.

          var lowerHeaders = headers.map((headers) => headers.toLowerCase().replace(' ',''));
          var savedHeaders = [];

		  //Sets progress bar maximum
          doc.progressMax = dataArray.length;

          //File type picker

		  //Is Inventory if file has item, description, cost, sale price
		  if (lowerHeaders.includes("item") && lowerHeaders.includes("description") && lowerHeaders.includes("cost") && lowerHeaders.includes("sale")) {
			  //Saves relevent headers and index for them.
			  var itemIdIndex,
              descIndex,
              costIndex,
              salesIndex,
              locationIndex,
              vendorIndex,
              countIndex = -1;

            for (var index in lowerHeaders) {
            	if (lowerHeaders[index] == "item") {
					itemIdIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("description")) {
                	descIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("cost")) {
                	costIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("sale")) {
                	salesIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("location")) {
                	locationIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("vendor")) {
                	vendorIndex = savedHeaders.push(headers[index]) - 1;
				}
				else if (lowerHeaders[index].includes("quantity")) {
                	countIndex = savedHeaders.push(headers[index]) - 1;
            	}
            }
            let existingVendors = [];

            await axios
              .get(process.env.VUE_APP_API_TARGET + "/vendors/" + doc.companyId, {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }})
              .then((response) => {
                for (let i in response.data.queryResult) {
                  existingVendors.push(response.data.queryResult[i].company);
                }
              })
			  .catch((response)=>{
				  doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })


            //upload to DB
            for (var index in dataArray) {
				//submisson data
				var submitItem = "Unassigned"
                var submitDesc = "Unassigned"
                var submitCost = 0
                var submitSales = 0
                var submitLocation = "Unassigned"
                var submitVendor = "Unassigned";

              if (dataArray[index][savedHeaders[itemIdIndex]] !== null) {
                submitItem = dataArray[index][savedHeaders[itemIdIndex]];
              }
              if (dataArray[index][savedHeaders[descIndex]] !== null) {
                submitDesc = dataArray[index][savedHeaders[descIndex]];
              }
              if (dataArray[index][savedHeaders[costIndex]] !== null) {
                submitCost = dataArray[index][savedHeaders[costIndex]];
              }
              if (dataArray[index][savedHeaders[salesIndex]] !== null) {
                submitSales = dataArray[index][savedHeaders[salesIndex]];
              }
              if (dataArray[index][savedHeaders[locationIndex]] !== null && dataArray[index][savedHeaders[locationIndex]] !== "" && dataArray[index][savedHeaders[locationIndex]] !== undefined ) {
                submitLocation = dataArray[index][savedHeaders[locationIndex]];
              }
              if (dataArray[index][savedHeaders[vendorIndex]] !== undefined && dataArray[index][savedHeaders[vendorIndex]] !== '') {
                submitVendor = dataArray[index][savedHeaders[vendorIndex]];
              }

			if (dataArray[index] !== undefined) {

				let locArray = []
				if(submitLocation.includes(',')){
					locArray = submitLocation.split(',')
				}
				else{
					locArray.push(submitLocation)
				}

                //post
                //Vendor post
                if (!existingVendors.includes(submitVendor)) {
                  await axios
                    .post(
                      process.env.VUE_APP_API_TARGET + "/vendors/" + doc.companyId,
                      {
                        company: submitVendor,
                        phone: null,
                        address: "Imported via Inventory import",
                      },
                      {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
                    )
                    .then((response) => {
                      existingVendors.push(submitVendor);
                      doc.createdObjects++;
                    })
					.catch((response)=>{
						doc.errorArray.push({
						  error: response.data.message,
						});
					})
                }

				for(let i in locArray){
					let tempLoc = locArray[i]
					let tempBin = 'Imported'
					if(locArray[i].includes(':')){
						tempLoc = locArray[i].split(':')[0]
						tempBin = locArray[i].split(':')[1]
					}

					//Location post
					await axios.post(`${process.env.VUE_APP_API_TARGET}/inventory/locations/${doc.companyId}`,{
						name: tempLoc
					},{headers: {'Authorization':'Bearer ' + doc.user.auth0Token }})
					.then((response)=>{
						if(response.request.status === 201){
							doc.createdObjects++;
						}
					})
					.catch((response)=>{
						doc.errorArray.push({
						  error: response.data.message,
						});
					})

					//inventory post
					var countString = "";
					var temp = dataArray[index][savedHeaders[countIndex]].split(",")
					for (let item in temp) {
					  countString = countString + temp[item];
					}
					if(i >= 1){
						countString = 0
					}

					let qtyObject = [{
						cost: parseFloat(submitCost.replace('$','')),
						costDate: new Date().toISOString().split("T")[0],
						qty: parseInt(countString)
					}]

					axios.post(
						process.env.VUE_APP_API_TARGET + "/inventory/" + doc.companyId,
						{
						  id: submitItem,
						  desc: submitDesc,
						  count: JSON.stringify(qtyObject),
						  location: tempLoc,
						  bin: tempBin,
						  threshold: 0,
						  cost: submitCost.replace('$',''),
						  sell: submitSales.replace('$',''),
						  vendor: submitVendor,
						  image: null,
						  type: "Unset",
						},
						{headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
					  )
					  .then((response) => {
						if (response.data.error) {
						  doc.errorArray.push({
							error: response.data.message,
						  });
						} else {
						  doc.createdObjects++;
						}
					  })
					  .catch((response)=>{
						  doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					  })
				}
			}
			doc.progress = doc.progress + 1;
            }
          } //end inventory

		  //Is a Vendor file but not a Inventory File
          else if (lowerHeaders.includes("vendor")) {
            var vendorIndex,
              phoneIndex,
              addressIndex,
              contactIndex = -1;

            for (var index in lowerHeaders) {
              if (lowerHeaders[index].includes("vendor")) {
                	vendorIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index].includes("phone")) {
                	phoneIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index].includes("address")) {
                	addressIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index].includes("contact")) {
                	contactIndex = savedHeaders.push(headers[index]) - 1;
              	}
            }

            for (var index in dataArray) {
              var vendorId = "";
              if (
                dataArray[index][savedHeaders[vendorIndex]] !== undefined &&
                dataArray[index] !== undefined
              ) {
                await axios
                  .post(
                    process.env.VUE_APP_API_TARGET + "/vendors/" + doc.companyId,
                    {
                      company: dataArray[index][savedHeaders[vendorIndex]],
                      phone: dataArray[index][savedHeaders[phoneIndex]],
                      address: dataArray[index][savedHeaders[addressIndex]],
                    },
                    {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
                  )
                  .then((response) => {
                    if (response.data.error) {
                      doc.errorArray.push({
                        error: response.data.message,
                      });
                    } else {
                      doc.createdObjects++;
                      if (
                        dataArray[index][savedHeaders[contactIndex]] !== "" &&
                        dataArray[index][savedHeaders[contactIndex]] !==
                          undefined &&
                        dataArray[index][savedHeaders[contactIndex]] !== null
                      ) {
                        vendorId = response.data.createdVendor.insertId;
                      }
                    }
                    doc.progress = doc.progress + 1;
                  })
				  .catch((response)=>{
					  doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				  })

                if (vendorId !== "") {
                  await axios
                    .post(
                      process.env.VUE_APP_API_TARGET + "/vendors/vendorContact/" +
                        doc.companyId,
                      {
                        vendorId: vendorId,
                        name: dataArray[index][savedHeaders[contactIndex]],
                        title: null,
                        phone: dataArray[index][savedHeaders[phoneIndex]],
                        email: null,
						picture: null,
                      },
                      {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
                    )
                    .then((nextResponse) => {
                      if (nextResponse.data.error) {
                        doc.errorArray.push({
                          error: nextResponse.data.sqlMessage,
                        });
                      } else {
                        doc.createdObjects++;
                      }
                    })
					.catch((response)=>{
						doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                }
              }
            }
          } //end vendor

		  //Is a Customer File
          else if (
            lowerHeaders.includes("customer") &&
            lowerHeaders.includes("email") &&
            lowerHeaders.includes("balance")
          ) {
            //Saves relevent headers and index for them.
            var customerIndex= -1;
            var phoneIndex= -1;
            var emailIndex= -1;
            var billIndex= -1;
            var shipIndex= -1;
            var contactIndex= -1;
            var balanceIndex = -1;

            for (var index in lowerHeaders) {
            	if (lowerHeaders[index] == "customer") {
					customerIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "phone") {
					phoneIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "email") {
					emailIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "billaddress") {
					billIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "shipaddress") {
					shipIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "contact") {
					contactIndex = savedHeaders.push(headers[index]) - 1;
				} else if (lowerHeaders[index] == "balance") {
					balanceIndex = savedHeaders.push(headers[index]) - 1;
				}
            }
            //upload to DB
            for (var index in dataArray) {
              if (
                dataArray[index] !== undefined &&
                dataArray[index][savedHeaders[customerIndex]] !== undefined
              ) {
                //data check
                //0 false, 1 true
                var electronic = 0;
                var billAddress ="";
                var shippingAddress = "";

                //electronic payment
                if (
                  dataArray[index][savedHeaders[emailIndex]] !== "" &&
                  dataArray[index][savedHeaders[emailIndex]] !== undefined
                ) {
                  electronic = 1;
                }

                //Billing set to shipping if empty
                if (
                  dataArray[index][savedHeaders[billIndex]] !== "" &&
                  dataArray[index][savedHeaders[billIndex]] !== undefined
                ) {
                  billAddress = dataArray[index][savedHeaders[billIndex]];
                } else if (
                  dataArray[index][savedHeaders[shipIndex]] !== "" &&
                  dataArray[index][savedHeaders[shipIndex]] !== undefined
                ) {
                  billAddress = dataArray[index][savedHeaders[shipIndex]];
                }

                //Shipping set to billing if empty
                if (
                  dataArray[index][savedHeaders[shipIndex]] !== "" &&
                  dataArray[index][savedHeaders[shipIndex]] !== undefined
                ) {
                  shippingAddress = dataArray[index][savedHeaders[shipIndex]];
                } else if (
                  dataArray[index][savedHeaders[billIndex]] !== "" &&
                  dataArray[index][savedHeaders[billIndex]] !== undefined
                ) {
                  shippingAddress = dataArray[index][savedHeaders[billIndex]];
                }

                //Post
                var custId = -1;
                await axios
                  .post(
                    process.env.VUE_APP_API_TARGET + "/customers/" + doc.companyId,
                    {
                      name: dataArray[index][savedHeaders[customerIndex]],
                      shipping: shippingAddress,
                      billing: billAddress,
                      tax_exempt: null,
                      electronic: electronic,
                      phone: dataArray[index][savedHeaders[phoneIndex]],
                      sales_balance: dataArray[index][savedHeaders[balanceIndex]],
                    }, {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
                  )
                  .then((response) => {
                    if (response.data.error) {
                      doc.errorArray.push({
                        error: response.data.message,
                      });
                    } else {
                      doc.createdObjects++;
					  custId = response.data.queryResult.insertId;
                    }
                    doc.progress = doc.progress + 1;
                  })
				  .catch((response)=>{
					  doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				  })

                if (custId !== -1) {
                  //posts requiring custID.
                  //CONTACT
                  if (
                    dataArray[index][savedHeaders[contactIndex]] !== "" &&
                    dataArray[index][savedHeaders[contactIndex]] !== undefined
                  ) {
                    axios
                      .post(
                        process.env.VUE_APP_API_TARGET + "/customers/" +
                          custId +
                          "/contacts/" +
                          doc.companyId,
                        {
                          name: dataArray[index][savedHeaders[contactIndex]],
                          address: shippingAddress,
                          phone: dataArray[index][savedHeaders[phoneIndex]],
                          email: dataArray[index][savedHeaders[emailIndex]],
                          title: null,
                        }, {headers: {'Authorization':'Bearer ' + doc.user.auth0Token }}
                      )
                      .then((response) => {
                        doc.createdObjects++;
                      })
					  .catch((response)=>{
						  doc.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Data Management', 'user' : doc.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					  })
                  }
                }
              }
		  	}//END for loop to upload to DB
          } //end customer


          else {
            alert(
              "The file is not an expected form. Contact Support for assistance."
            );
          }
        },
      });
    }, //END intake

	async zipInventory(){
		let zip = new JSZip();
		this.action = "exportLoad";

		await this.exportInventory();

		for (let index in this.csvArray) {
		  zip.file(this.csvArray[index].name, this.csvArray[index].data);
		}

		zip.generateAsync({ type: "blob" }).then(function (content) {
		  FileSaver.saveAs(content, "Fleet4ExportInventory.zip");
		});
		this.action = "home";
	},

  }, //END methods
};
</script>

<style scoped>
.new-styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  max-width: 80vw;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  align: center;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
}

/deep/ .customPagination {
  margin: auto;
  margin-bottom: 1%;
}
/deep/ .customPagination .page-item.active .page-link {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
/deep/ .customPagination .page-item .page-link {
  color: #dc3545 !important;
}
</style>

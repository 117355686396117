<template>
	<div class="container-xxl">
		<div>
			<div class="actionHolder" v-if="action === '' && !isBusy">
				<b-button v-if="$globals.canAccess.admin || this.$globals.canAccess.dispatching" :class="{dark: displayClass, stand: !displayClass}" @click="changeState('createTicket'); lastState = 'createTicket'; pendingReq = false; newStep.authorization = null;">Create Work Order</b-button>
				<b-button v-if="$globals.canAccess.admin || this.$globals.canAccess.dispatching" :class="{dark: displayClass, stand: !displayClass}" style="margin-left: 1vw" @click="changeState('serviceEstimate')">Create Service Estimate</b-button>
				<b-button v-if="$globals.canAccess.admin || this.$globals.canAccess.dispatching" :class="{dark: displayClass, stand: !displayClass}" style="margin-left: 1vw" @click="action = 'partsPending'">Parts Pending</b-button>
				<b-button v-if="$globals.canAccess.admin || this.$globals.canAccess.dispatching" :class="{dark: displayClass, stand: !displayClass}" style="margin-left: 1vw" @click="mapTechs = allTechs; action = 'viewMap'">Tech Locator</b-button>
			</div>

				<!-- Map -->
			<div v-if="action === 'viewMap'" class="card card-danger mt-4">
				<techLocator :allTechs="mapTechs" @closeMap="() =>  returnFromViewTicket()"></techLocator>
			</div>

			<!--New Work Order -->
			<div v-if="action === 'createTicket'" style="margin-top:1%">
				<div class="card card-danger">
					<div class="card-header">
						<div class="card-title">
							<h3><b>New Work Order</b></h3>
						</div>
						<div class="card-tools">
							<b-button type="submit" class="button" style="background-color: #fff4; border-color: #dc3545" @click.prevent="createTicket()" :disabled="pendingReq">{{ pendingReq ? 'Creating...' : 'Create Ticket'}}</b-button>
							<b-button type="cancel" class="button" style="background-color: #fff4; border-color: #dc3545;" @click.prevent="cancelEdit(); returnFromViewTicket()">Cancel</b-button>
                        </div>
					</div>
					<div class="card-body">
						<form>
							<div style="margin:auto;">
								<div class = "row">

									<div class="col"> <label for="custSelect">CUSTOMER:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
										<multiselect id="custSelect" class="w-75" style="display: inline-block" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allCustomers" v-model="newTicket.newcustomer" @select="pickCustomer" placeholder="Customer" @search-change="debounceCustomers">
											<template slot="option" slot-scope="props">
												<intersect v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
													<span>{{props.option.name}}</span>
												</intersect>
											</template>
										</multiselect>
										<i class="fas fa-plus-circle" @click="action = 'createCustomer'; lastState='createTicket'" style="display: inline-block"></i>
									</div>
									<div class="col"> <label for="contactSelect">CONTACT:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
										<multiselect id="contactSelect" class="w-75" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allContacts" v-model="newTicket.newContact" @select="pickContact" placeholder="Contact" style="display: inline-block"></multiselect>
										<i class="fas fa-plus-circle" @click="action = 'createContact'; newContact.customer = newTicket.newcustomer; lastState = 'createTicket'" style="display: inline-block"></i>
									</div>
									<div class="col"> <label for="phoneNumber">CONTACT #:</label><br>
										<input id="phoneNumber" class="form-control" placeholder="Phone Number" type="tel" v-model = "selectedContact.phone" readonly/>
									</div>
								</div>
								<br>
								<div class="row">

									<div class="col"> <label for="vehSelect">ASSET:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
										<multiselect id="assetSelect" class="w-75" :clearable="true" :show-labels="false" :options="allAssetsVehicles" group-label="name" :group-select=false group-values="items" v-model="newTicket.newasset" :multiple="multiAsset" track-by="id" label="id" placeholder="Asset" @input="pickAsset" style="display: inline-block;"></multiselect>
										<b-dropdown id="dropdown-1" border no-caret text="New" variant="danger" style="margin-left: 0.5vw">
											<b-dropdown-item @click="action = 'createVehicle'; newVehicle.customer = newTicket.newcustomer; lastState='createTicket'" style="font-family: 'Segoe UI'">Vehicle</b-dropdown-item>
											<b-dropdown-item @click="action = 'createAsset'; lastState='createTicket'" style="font-family: 'Segoe UI'">Asset</b-dropdown-item>
										</b-dropdown>
									</div>

									<div class="col"> <label for="vehAddress">WORK LOCATION:</label><br>
										<input id="vehAddress" class="form-control w-75" placeholder="Location" type="text" v-model ="locAddress">
									</div>
									<div class="col"> <label for="techSelect">TECHS:</label><br>
										<multiselect id="techSelect" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allTechs" v-model="newTicket.newtech" :multiple="true" :preserve-search="true" placeholder="Technician(s)" style="display: inline-block; float: right"></multiselect>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col">

										<label for="startDate">START DATE:</label><br>
										<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm:ss A" v-model="newTicket.newstart" />
									</div>
									<div class="col">
									</div>
									<div class ="col">

										<label for="endDate">PROJECTED END DATE:</label><br>
										<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" minute-interval="15" format="YYYY-MM-DD HH:mm:ss A" v-model="newTicket.newend" />
									</div>
								</div>
							</div>
							<br><hr>

							<!-- New Work Order Tasks Section -->
								<h1 style="float: Center">Tasks</h1>
								<div v-if="contractsArray.length >0" style="text-align:center">
									<hr>

									<label>Contract Covered Tasks</label>
									<div class="row flex-nowrap" style="display: flex; flex-direction: row; overflow-x: scroll" v-for="(contract) in contractsArray" :key="contract.contractid">
										<div class="col-2">
											<h4><b>{{contract.contractid}} - {{contract.contractName}}</b></h4>
											<b>Assets in contract:</b>
											<div v-for="(coveredAsset,index) in contract.linkedAssets" :key="index">
												{{coveredAsset.id}}
												<hr>
											</div>
										</div>
										<div class="col-3" v-for="(task, index) in contract.tasks" :key="index">
											<b>Task #{{index+1}}:</b>
											<br>
											{{task.task || task}}
										</div>
									</div>
								</div>
								<div style="margin: auto; margin-top: 2.5%;">
								<b-table ref="newTicketSteps" striped :items="this.newTicket.newdata" :fields="[{key: 'task', label: 'Task', thStyle: { width: '5%'}},{key: 'descriptions', label: 'Descriptions', thStyle: { width: '30%'}},{key: ''},{key: 'parts', label: 'Parts', thStyle: { width: '35%'}},{key: 'labor', label: 'Labor', thStyle: { width: '10%'}},{key:'authorization', label: 'Authorization'},{key: 'actions', label: 'Actions', thStyle: { width: '5%'}} ]">

									<template #cell(parts)="row" colspan="2">
										<div>
											<textarea v-b-tooltip.hover title="Required Field" rows="5" class="form-control" style="border: solid 1px #ccc" placeholder="Parts Requested" v-model="row.item.partsRequested"></textarea>
										</div>

											<br>
											<div v-for="(part,index) in row.item.parts" :key="part.itemid" class="part">
												<div>
													<b-row>
														<b-col cols="7">
															<b>{{part.itemdesc}}</b>
															<br>
															{{part.itemid}}
														</b-col>
														<b-col cols="3">
															<span style="font-size:10pt"> {{part.fulfilledQty}} / {{part.qty}}</span>
															<br>
															<!-- Edit fulfillment button -->
															<i v-show="canAccess.parts || canAccess.admin" class="fas fa-edit" @click="part.locationFlag = true" style="margin-left: 1%; display: inline;"></i>
															<!--S.O. MODAL HERE -->
															<template v-if="part.itemid == '**Special Order**'">
																<specialOrder :user="user" :companyId="companyId" @addSOPart="addEditSOPart(...arguments, row.item)" @closeSO="newStep.newparts.splice(index,1)"></specialOrder>
															</template>
															<div v-if="part.locationFlag">
																<locationSelect :displayClass="displayClass" :item="row.item.parts[index]" :companyId="companyId" :user="user" @reject="part.locationFlag=false" @accept="locatePart(...arguments, index, row.item.parts)"></locationSelect>
															</div>
														</b-col>
														<b-col v-show="canAccess.parts || canAccess.admin" cols="1">
															<i class="fas fa-trash ml-2 mt-2" @click.stop="deletePart(index,row.item.parts)"></i>
														</b-col>
													</b-row>
												</div>
												<hr>
											</div>
											<div>
												<div v-show="canAccess.parts || canAccess.admin" class="d-flex">
													<multiselect v-b-tooltip.hover title="Required Field" id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select="false" group-values="items" v-model="editPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width: 80%; display: inline-block;" @search-change="debounceInventory">
                                                        <template slot="option" slot-scope="props">
                                                            <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                                <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                            </intersect>
                                                        </template>
                                                    </multiselect>
													<input id="partQuantity" style="width: 12.5%" class="form-control ml-1" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="editQty">
													<i class="fas fa-plus-circle ml-2 mt-2" size="sm" @click.stop="addEditedPart(row.item);"></i>
												</div>
											</div>

									</template>

									<template #cell(authorization)="row">
										Authorized by:<br>
										<multiselect :clearable="true" :show-labels="false" :options="allContacts" v-model="row.item.authorization" :multiple="false" track-by="name" label="name"></multiselect>
										<hr>
										Bill to: <br>
										<multiselect :clearable="true" :show-labels="false" :options="billOptions" v-model="row.item.billTo" group-label="name" :group-select="false" group-values="items" :multiple="false" track-by="name" label="name" @search-change="debounceCustomers" @select="row.item.billTo.selectedTask=null"></multiselect>
										<div v-if="row.item.billTo.contractid > 0">
											Contract task: <br>
											<multiselect :clearable="false" :show-labels="false" :options="row.item.billTo.tasks" label="task" track-by="task" :multiple="false" v-model="row.item.contractTask"></multiselect>
										</div>
									</template>
									<template #cell(descriptions)="row">
										<textarea class="form-control" rows="5" style="width: 100%; border: solid 1px #ccc" v-model="row.item.desc"></textarea> <br><hr>
										<textarea class="form-control" rows="5" style="width: 100%; border: solid 1px #ccc" v-model="row.item.internalDesc"></textarea>
									</template>

									<template #cell(labor)="row">
										<input id="stepLabor" class="form-control w-50" type="number" name="stepLabor" min="0" step="0.1" placeholder="Est. Labor" v-model="row.item.labor" /> Hours
									</template>

									<template #cell(actions)="row">
										<i class="fas fa-trash" size="sm" @click.stop="deleteStep(row.item)"></i>
									</template>

									<template #bottom-row>
										<td></td>
										<td colspan="2">
											<textarea v-b-tooltip.hover title="Required Field" rows="5" class="form-control" style="width:100%; border: solid 1px #ccc" placeholder="Work Requested" v-model="newStep.newdesc"></textarea><br>
											<textarea v-b-tooltip.hover title="Required Field" rows="5" class="form-control" style="width:100%; border: solid 1px #ccc" placeholder="Internal Description" v-model="newStep.newInternalDesc"></textarea>
										</td>
										<td>
											<div>
												<textarea v-b-tooltip.hover title="Required Field" rows="5" class="form-control" style="border: solid 1px #ccc" placeholder="Parts Requested" v-model="newStep.newPartsRequested"></textarea>
											</div>
												<br>
											<div v-show="canAccess.parts || canAccess.admin">
												<div v-for ="(part, index) in newStep.newparts" :key="part.itemid">
													<div v-if="part.itemid !== '**Special Order**'">
														<div>
															<b-row>
																<b-col cols="7">
																	<b>{{part.itemdesc}}</b>
																	<br>
																	{{part.itemid}}
																</b-col>
																<b-col cols="3">
																	<span style="font-size:10pt"> {{part.fulfilledQty}} / {{part.qty}}</span>
																	<br>
																	<!-- Edit fulfillment button -->
																	<i v-show="canAccess.parts || canAccess.admin" class="fas fa-edit" @click="part.locationFlag = true" style="margin-left: 1%; display: inline;"></i>
																	<div v-if="part.locationFlag">
																		<locationSelect :displayClass="displayClass" :item="part" :companyId="companyId" :user="user" @reject="part.locationFlag=false" @accept="locatePart(...arguments, index, newStep.newparts)"></locationSelect>
																	</div>
																</b-col>
																<b-col v-show="canAccess.parts || canAccess.admin" cols="1">
																	<i class="fas fa-trash ml-2 mt-2" @click.stop="deletePart(index,newStep.newparts)"></i>
																</b-col>
															</b-row>
														</div>
														<hr>
													</div>
													<!--S.O. MODAL HERE -->
													<template v-if="part.itemid == '**Special Order**'">
														<specialOrder :user="user" :companyId="companyId" @addSOPart="addSOPart" @closeSO="newStep.newparts.splice(index,1)"></specialOrder>
													</template>
												</div>
												<div v-show="canAccess.parts || canAccess.admin" class="d-flex">
													<multiselect id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select="false" group-values="items" v-model="newPart" :multiple="false" track-by="itemid" :custom-label="({itemid, itemdesc}) => {
                                                        return `${itemid} - ${itemdesc}`
                                                    }" label='itemdesc' placeholder="Parts" style="width: 80%; display: inline-block;" @search-change="debounceInventory">
                                                        <template slot="option" slot-scope="props">
                                                            <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                                <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                            </intersect>
                                                        </template>
                                                    </multiselect>
													<input id="partQuantity" style="width: 12.5%" class="form-control ml-1" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="newQuantity">
													<i class="fas fa-plus-circle ml-2 mt-2" style="margin-top: 2%" @click="addPart(newPart, newQuantity)"></i>
												</div>
											</div>

										</td>
										<td>
											<input id="stepLabor" class="form-control w-50" type="number" name="stepLabor" min="0" step="0.1" placeholder="Est. Labor" v-model="newStep.stepLabor" /> Hours
										</td>
										<td>
											<multiselect id="stepAuthorization" :clearable="true" :show-labels="false" :options="allContacts" v-model="newStep.authorization" :multiple="false" track-by="name" label="name" placeholder = "Contact Authorization"></multiselect>
											<multiselect id="billSelect" label="name" placeholder="Bill To" track-by="name" :clearable="true" :show-labels="false" :options="billOptions" v-model="newStep.billTo" group-label="name" :group-select="false" group-values="items" @search-change="debounceCustomers" @select="newStep.contractTask =null">
                                                <template slot="option" slot-scope="props">
                                                    <intersect v-if="props.option.name === billOptions[1].items[billOptions[1].items.length-1].name" @enter="getMoreCustomers">
                                                        <span>{{props.option.name}}</span>
                                                    </intersect>
                                                </template>
                                            </multiselect>

											<div v-if="newStep.billTo.contractid > 0">
												Contract task: <br>
												<multiselect v-model="newStep.contractTask" :show-labels="false" :options="newStep.billTo.tasks" label="task" track-by="task" :multiple="false" @select="$forceUpdate()"></multiselect>
											</div>
										</td>
										<td colspan="6"><i style="margin-top:20px;" class="fas fa-plus-circle fa-3x" @click="addStep()"></i></td>
									</template>
								</b-table>
							</div>
						</form>
					</div>
				</div>
			</div>

            <div class="text-center align-center" v-if="isBusy">
                <b-spinner variant="danger"></b-spinner>
            </div>

			<div class="createAsset" v-if="action === 'createAsset'" style="margin-top: 1%">
                <newAsset :selectedCustomer="selectedCustomer" @return="handleNewAsset"></newAsset>
			</div>

			<div style="margin-top: 1%" v-show="action === 'createCustomer'">
                <newCustomer @return="action=lastState"></newCustomer>
			</div>

			<!-- New Vehicle Section -->
			<div v-if="action === 'createVehicle'" style="margin-top:1%">
                <newVehicle :selectedCustomer="selectedCustomer" @return="handleNewAsset()"></newVehicle>
			</div>

		<!-- New Contact Section -->
			<div class="createContact" v-if="action === 'createContact'" style="margin-top:1%">
                <newContact :source="selectedCustomer" @return="action = lastState; getContacts(selectedCustomer); $forceUpdate()"/>
			</div>

		<!-- Add New House/Location -->
			<div v-if="action === 'addHouse'" style="margin-top: 1%">
				<firehouse :user="user" :companyId="companyId" :customer="newVehicle.customer" @cancelHouse= "action ='createVehicle'" @accept="getHouses(newVehicle.customer); action='createVehicle'">
				</firehouse>
			</div>

   <!-- View Ticket -->
		<div v-if="action === 'viewTicket'" ref="ticket" class="pb-3">
            <viewTicket :selectedTicket="selectedTicket" @return="returnFromViewTicket()"></viewTicket>
		</div>

		<div v-if="action === 'changeLocation'">
			<b-card style="margin-left: 5vw; margin-top: 5vh; border: black solid 1px; border-radius: 20px">
				<b-row>Current Location: {{ selectedTicket.loc_name }}, {{ selectedTicket.loc_address }}</b-row>
				<b-row>
					<multiselect id="locSelect" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allCustHouses" v-model="locName" :preserve-search="true" placeholder="Locations" style="width:60%; display: inline-block;"></multiselect>
				</b-row>
				<b-row>
					<input class="form-control" type="text" v-model="locAddress" placeholder="If location is not listed, enter a new location address."  />
				</b-row>
				<div style="float:right">
					<b-button style="background-color: #ce2029; border-color: #ce2029; margin-left: 1%; margin-top: 1%;" @click.prevent="updateLocation(locName, locAddress)">Update Location</b-button>
					<b-button style="background-color: #ce2029; border-color: #ce2029; margin-left: 1%; margin-top: 1%;" @click.prevent="changeState('viewTicket')">Cancel</b-button>
				</div>
			</b-card>
		</div>

		<div v-if="action === ''" class="container2">
			<div class="scheduler" style="border: solid #d6d6d6 1px; height: 55vh; width: 100%; margin-top: 1%; padding-bottom: 1.5%">
				<b-button style="background-color: transparent; color: #dc3545; border: none; font-size: 15px;" @click="setToDayView()">Day</b-button>
				<b-button style="background-color: transparent; color: #dc3545; margin-left: 1vw; border: none; font-size: 15px;" @click="setToWeekView()">Week</b-button>
				<b-button style="background-color: transparent; color: #dc3545; margin-left: 1vw; border: none; font-size: 15px;" @click="setToMonthView()">Month</b-button>
				<bryntum-scheduler ref="scheduler" id="scheduler" :tooltip="schedulerConfig.tooltip" v-bind="schedulerConfig" :key='schedulerKey'></bryntum-scheduler>
			</div>
			<!-- tickets -->

			<div class="card card-danger mt-4" style="width: 100%;">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Work Orders</b></h3>
					</div>
				</div>
				<div class="card-body overflow-auto">
					<div class="filter-buttons form-group ml-4">
						<div class="row" style="width: 100%">
							<div class="col">
								<b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="mainTableView='Work Orders'">All</b-button>
							</div>
							<div class="col ml-n3">
                                <b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="ticketFilterSwitch('unscheduled')">Unscheduled</b-button>
							</div>
							<div class="col ml-n3">
                                <b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw"  @click.prevent="ticketFilterSwitch('scheduled')">Scheduled</b-button>
							</div>
							<div class="col ml-n3">
                                <b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="ticketFilterSwitch('completed')">Completed</b-button>
							</div>
                            <div class="col ml-n3">
                                <b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="ticketFilterSwitch('invoiced')">Invoiced</b-button>
                            </div>
							<div class="col ml-n3">
								<b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="ticketFilterSwitch('archived')">Archived</b-button>
							</div>
							<div class="col ml-n3">
								<b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="ticketFilterSwitch('backorder')">Back Order</b-button>
							</div>
						</div>
						<br>
						<div class="row" style="width: 100%">
							<div class="col">
								<b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="mainTableView='Service Estimates Active'">Service Estimates</b-button>
							</div>
							<div class="col ml-n3">
								<b-button :class="{dark: displayClass, stand: !displayClass}" style="width: 8vw" @click.prevent="mainTableView='Service Estimates Inactive'">Old Estimates</b-button>
							</div>
							<div class="col ml-n3"></div>
							<div class="col ml-n3"></div>
							<div class="col ml-n3"></div>
							<div class="col ml-n3"></div>
							<div class="col ml-n3"></div>

						</div>
						<input v-if="mainTableView=='Work Orders'" type="text" class="form-control" placeholder="Search by Customer, Ticket ID, Technician, or Asset" @input="debounceTickets" style="width: 95%; margin-top: 1vw" v-model="searchTickets">

						<input v-if="mainTableView=='Work Orders Filtered'" type="text" class="form-control" placeholder="Search by Customer, Ticket ID, Technician, or Asset" @input="debounceFilteredTickets" style="width: 95%; margin-top: 1vw" v-model="ticketFilterSearch">

						<input v-if="mainTableView=='Service Estimates Active'" type="text" class="form-control" placeholder="Search by Customer, Ticket ID, Technician, or Asset" style="width: 95%; margin-top: 1vw" v-model="activeServiceEstimatesSearch" @input="debounceSEActive">

						<input v-if="mainTableView=='Service Estimates Inactive'" type="text" class="form-control" placeholder="Search by Customer, Ticket ID, Technician, or Asset" style="width: 95%; margin-top: 1vw" v-model="inactiveServiceEstimatesSearch" @input="debounceSEInactive">
					</div>

					<div v-show="mainTableView=='Work Orders'" class="ticketTable">
						<b-table ref="WOTable" id="ticketTable" :per-page="10" :current-page="currentPageTickets" :items="ticketProvider" :fields="ticketTableFields" :busy="isBusy" striped :filter-included-fields="['localid','technician','customer','asset']" @filtered="onFiltered" @row-clicked="viewTicket">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
							<template #cell(asset)="row">
								<div>
									<div class="row align-top" v-for="(assets, index) in (typeof row.item.asset !== 'object' ? JSON.parse(row.item.asset) : row.item.asset)" :key="index">
										{{ assets }}
									</div>
								</div>
							</template>
							<template #cell(technician)="row">
								<p><span v-for="tech in (typeof row.item.technician ==='object' ?row.item.technician : JSON.parse(row.item.technician))" :key="tech.name">{{ tech.name }} </span></p>
							</template>
							<template #cell(start_date)="row">
								{{row.item.start_date ? new Date(row.item.start_date.slice(0,-2)).toLocaleString() : ''}}
							</template>
                            <template #cell(status)="row">
                                {{row.item.status}}
                            </template>
						</b-table>
					</div>

					<div v-show="mainTableView=='Work Orders Filtered'" class="ticketTable">
						<b-table ref="WOTableFiltered" id="ticketTable" :per-page="10" :current-page="currentPageFiltered" :items="filteredTicketsProvider" :fields="ticketTableFields" :busy="isBusy" striped :filter-included-fields="['localid','technician','customer','asset']" @filtered="onFiltered" @row-clicked="viewTicket">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
							<template #cell(asset)="row">
								<div>
									<div class="row align-top" v-for="(assets, index) in (typeof row.item.asset !== 'object' ? JSON.parse(row.item.asset) : row.item.asset)" :key="index">
										{{ assets }}
									</div>
								</div>
							</template>
							<template #cell(technician)="row">
								<p><span v-for="tech in (typeof row.item.technician ==='object' ?row.item.technician : JSON.parse(row.item.technician))" :key="tech.name">{{ tech.name }} </span></p>
							</template>
							<template #cell(start_date)="row">
								{{row.item.start_date ? new Date(row.item.start_date.slice(0,-2)).toLocaleString() : ''}}
							</template>
                            <template #cell(status)="row">
                                {{row.item.status}}
                            </template>
						</b-table>
					</div>

					<div v-show="mainTableView=='Service Estimates Active'">
						<b-table id="serviceEstimateTable" :per-page="10" :current-page="activeServiceEstimatesCurrentPage" :items="activeEstimateProvider" :fields="serviceEstimateFields" :busy="this.isBusy" striped :filter-included-fields="['localid','customer','asset']" :filter="search.main" @row-clicked="viewServiceEstimate" ref="ASETable">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
							<template #cell(asset)="row">
								<div v-if="row.item.vehicle">{{ row.item.vehicle.id }}</div>
								<div><div class="row align-top" v-for="(assets, index) in (typeof row.item.asset !== 'object' ? JSON.parse(row.item.asset) : row.item.asset)" :key="index">{{ assets }}</div></div>
							</template>
						</b-table>
					</div>

					<div v-show="mainTableView=='Service Estimates Inactive'">
						<b-table id="serviceEstimateTable" :per-page="10" :current-page="inactiveServiceEstimatesCurrentPage" :items="inactiveEstimateProvider" :fields="serviceEstimateFields" :busy="this.isBusy" striped :filter-included-fields="['localid','customer','asset']" :filter="search.main" @row-clicked="viewServiceEstimate" ref="ISETable">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
							<template #cell(asset)="row">
								<p v-if="row.item.vehicle">{{ row.item.vehicle.id }}</p>
								<p><b-row v-for="(assets, index) in (typeof row.item.asset !== 'object' ? JSON.parse(row.item.asset) : row.item.asset)" :key="index">{{ assets }}</b-row></p>
							</template>
						</b-table>
					</div>

					<b-pagination v-if="mainTableView == 'Work Orders'" class="customPagination" v-model="currentPageTickets" :per-page="10" :total-rows="maxRowsTickets" aria-controls="ticketTable" style="margin-left: 38.5%" ref="WOPage"></b-pagination>

					<b-pagination v-if="mainTableView == 'Work Orders Filtered'" class="customPagination" v-model="currentPageFiltered" :per-page="10" :total-rows="parseInt(maxPagesFiltered) * 10" aria-controls="ticketTable" style="margin-left: 38.5%"  ref="WOPageFiltered"></b-pagination>

					<b-pagination v-if="mainTableView == 'Service Estimates Active'" class="customPagination" v-model="activeServiceEstimatesCurrentPage" :per-page="10" :total-rows="maxRowsActiveEstimates" aria-controls="serviceEstimateTable" style="margin-left: 38.5%" ref="ASEPage"></b-pagination>

					<b-pagination v-if="mainTableView == 'Service Estimates Inactive'" class="customPagination" v-model="inactiveServiceEstimatesCurrentPage" :per-page="10" :total-rows="maxRowsInactiveEstimates" aria-controls="serviceEstimateTable" style="margin-left: 38.5%" ref="ISEPage"></b-pagination>
				</div>
			</div>
		</div>


			<div v-if="action === 'partsPending'">
				<div class="card card-danger">
					<div class="card-header">
						<div class="card-title">
							<h3><b>Parts Arrived</b></h3>
						</div>
						<div class="card-tools">
							<b-button style="background-color: #fff4; border-color: #dc3545;" @click="action = ''; populateScheduler()">Return</b-button>
						</div>
					</div>
					<div class="card-body">
						<b-table striped ref="parts-fulfilled" id="partsTable1" :busy="this.isBusy" :per-page="5" :items="allFulfilled" :fields="[{key: 'customer', sortable: true},{key: 'ticketid', label: 'Work Order', sortable: true},{key: 'task', label: 'Tasks'}]">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
						</b-table>
						<b-pagination class="customPagination" v-model="currentPage.parts_fulfilled" :total-rows="allFulfilled.length" :per-page="5" aria-controls="ticketTable" style="margin-left: 40vw"></b-pagination>
					</div>
				</div>

				<div class="card card-danger">
					<div class="card-header">
						<div class="card-title">
							<h3><b>Parts Pending</b></h3>
						</div>
					</div>
					<div class="card-body">
						<b-table striped ref="parts-awaiting" id="partsTable2" :busy="this.isBusy" :per-page="5" :items="allAwaiting" :fields="[{key: 'customer', sortable: true},{key: 'ticketid', sortable: true, label: 'Work Order'},{key: 'task', label: 'Tasks'}]">
							<template #table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>
						</b-table>
					</div>
				</div>
			</div>
		</div>

		<!-- Invoice -->
		<div v-if="action === 'viewPDF'">
            <serviceInvoice :selectedTicket="selectedTicket" :selectedTasks="selectedTasks" :totalsArray="totalsArray"></serviceInvoice>
		</div>

		<!-- New Service Estimates -->
		<div v-if="action =='serviceEstimate'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Service Estimate</b></h3>
						<br>
						<h5>Created on: {{this.creationDate}} </h5>
					</div>
					<div class="card-tools">
						<b-button type="submit" class="button" style="background-color: #fff4; border-color: #dc3545; margin-left: 0.25vw" @click.prevent="createServiceEstimate()">Create Service Estimate</b-button>
						<b-button type="cancel" class="button" style="background-color: #fff4; border-color: #dc3545; margin-left: 0.25vw; margin-right: 0.25vm" @click.prevent="changeState(''); populateScheduler()">Cancel</b-button>
					</div>
				</div>
				<div class="card-body">
					<form>
						<div style="margin:auto;">
							<div class = "row">
								<div class="col"> <label for="custSelect">CUSTOMER:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
									<multiselect required id="custSelect" class="w-75" style="display: inline-block" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allCustomers" v-model="newTicket.newcustomer" @select="pickCustomer" placeholder="Customer" @search-change="debounceCustomers">
										<template slot="option" slot-scope="props">
											<intersect v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
												<span>{{props.option.name}}</span>
											</intersect>
										</template>
									</multiselect>
									<i class="fas fa-plus-circle" @click="action = 'createCustomer'; lastState='serviceEstimate'" style="display: inline-block"></i>
								</div>
								<div class="col"> <label for="contactSelect">CONTACT:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
									<multiselect required id="contactSelect" class="w-75" label="name" track-by="name" :clearable="true" :show-labels="false" :options="allContacts" v-model="newTicket.newContact" @select="pickContact" placeholder="Contact" style="display: inline-block"></multiselect>
									<i class="fas fa-plus-circle" @click="action = 'createContact'; lastState = 'serviceEstimate'" style="display: inline-block; margin-left: 0.25vw"></i>
								</div>
								<div class="col"> <label for="phoneNumber">CONTACT #:</label><br>
									<input id="phoneNumber" class="form-control" placeholder="Phone Number" type="tel" v-model = "selectedContact.phone" readonly/>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col"> <label for="vehSelect">ASSET:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </label><br>
									<multiselect required id="assetSelect" class="w-75" :clearable="true" :show-labels="false" :options="allAssetsVehicles" group-label="name" :group-select=false group-values="items" v-model="newTicket.newasset" :multiple="multiAsset" track-by="id" label="id" placeholder="Asset" @select="pickAsset" style="display: inline-block;"></multiselect>
									<b-dropdown id="dropdown-1" border: none no-caret text="New" variant="danger" style="margin-left: 0.5vw">
										<b-dropdown-item @click="action = 'createVehicle'; lastState='serviceEstimate'" style="font-family: 'Segoe UI'">Vehicle</b-dropdown-item>
										<b-dropdown-item @click="action = 'createAsset'; lastState='serviceEstimate'" style="font-family: 'Segoe UI'">Asset</b-dropdown-item>
									</b-dropdown>
								</div>
								<div class="col">
								</div>
								<div class="col">
								</div>
							</div>
						</div>
						<br><hr>

						<!-- Service Estimate Tasks Section -->
							<h1 style="float: Center">Tasks</h1>
							<div style="margin: auto; margin-top: 2.5%;">
							<b-table ref="newTicketSteps" striped :items="this.newTicket.newdata" :fields="[{key: 'task', label: 'Task'},{key: 'descriptions', label: 'Descriptions'},{key: 'parts'},{key:'authorization', label: 'Authorization'},{key: 'actions', label: 'Actions'} ]">

								<template #cell(descriptions)="data">
									<textarea class="form-control" style="width:300px" v-model="data.item.desc"></textarea> <br><hr>
								</template>

								<template #cell(parts)="row">
									<div v-for="(part,index) in row.item.parts" :key="part.itemid" class="part">
										<b style="width:200px; display: inline-block;">{{row.item.parts[index].itemdesc}}</b>
										<input id="partQuantity" class="w-25 form-control" type="number" name="partQuantity" min="0" v-model="part.qty"><br>
										Total cost: ${{ (part.sell * part.qty).toFixed(2) }}
										<!--S.O. MODAL HERE -->
										<template v-if="part.itemid == '**Special Order**'">
											<specialOrder :user="user" :companyId="companyId" @addSOPart="addEditSOPart(...arguments, row.item)" @closeSO="newStep.newparts.splice(index,1)"></specialOrder>
										</template>
										<hr>
									</div>

									<div v-if="editPart.itemid !== '**Estimated Labor**'" class="d-flex">
										<multiselect id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select="false" group-values="items" v-model="editPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width: 75%; display: inline-block;" @search-change="debounceInventory">
											<template slot="option" slot-scope="props">
												<intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
													<span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
												</intersect>
											</template>
										</multiselect>
										<input id="partQuantity" style="width: 12.5%" class="form-control ml-1" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="editQty">
										<i class="fas fa-plus-circle ml-2 mt-2" size="sm" @click.stop="addEditedPart(row.item);"></i>
									</div>

									<div v-if="editPart.itemid == '**Estimated Labor**'">
										<multiselect v-b-tooltip.hover title="Required Field" id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select=false group-values="items" v-model="editPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width:10vw" @search-change="debounceInventory">
											<template slot="option" slot-scope="props">
												<intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
													<span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
												</intersect>
											</template>
										</multiselect><br>
										<input class="form-control w-25" type="number" name="estimatedHours" min="0" placeholder="Hours" v-model="estimatedHours" style="display: inline-block">
										<input class="form-control w-25" type="number" name="rate" min="0" placeholder="Rate" v-model="estimatedRate" style="display: inline-block">
										<i class="fas fa-plus-circle" @click="addLabor(row.item)" style="display: inline-block"></i>

									</div>

								</template>

								<template #cell(authorization)="row">
									Authorized by:<br>
									<multiselect :clearable="true" :show-labels="false" :options="allContacts" v-model="row.item.authorization" :multiple="false" track-by="name" label="name"></multiselect>
									<hr>
									Bill to: <br>
									<multiselect :clearable="true" :show-labels="false" :options="billOptions" v-model="row.item.billTo" :multiple="false" track-by="name" label="name" group-label="name" :group-select="false" group-values="items" @search-change="debounceCustomers" @select="row.item.billTo.selectedTask = null"></multiselect>
									<div v-if="row.item.billTo.contractid > 0">
										Contract task: <br>
										<multiselect :clearable="false" :show-labels="false" :options="row.item.billTo.tasks" label="task" track-by="task" :multiple="false" v-model="row.item.contractTask"></multiselect>
									</div>
								</template>

								<template #cell(actions)="row">
									<i class="fas fa-trash" size="sm" @click.stop="deleteStep(row.item)"></i>
								</template>


								<template #bottom-row>
									<td></td>
									<td>
										<textarea v-b-tooltip.hover title="Required Field" class="form-control" style="width:300px" placeholder="Work Requested" v-model="newStep.newdesc"></textarea>
									</td>
									<td>
										<div v-for ="part in newStep.newparts" :key = "part.itemid">
											<p><b>{{ part.itemdesc }}</b><br>
												<span style="font-size:8pt">Qty: {{part.qty}} </span>
												<br>Total cost:{{ (part.sell * part.qty).toFixed(2) }}
											</p>
											<!--S.O. MODAL HERE -->
											<template v-if="part.itemid == '**Special Order**'">
												<specialOrder :user="user" :companyId="companyId" @addSOPart="addSOPart" @closeSO="newStep.newparts.splice(index,1)"></specialOrder>
											</template>
											<hr>
										</div>
										<div v-if="newPart.itemid != '**Estimated Labor**'">
											<multiselect v-b-tooltip.hover title="Required Field" id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select=false group-values="items" v-model="newPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width:10vw; display: inline-block;" @search-change="debounceInventory">
                                                <template slot="option" slot-scope="props">
                                                    <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                        <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                    </intersect>
                                                </template>
                                            </multiselect>
											<!--<multiselect id="stepParts" :clearable="true" :show-labels="false" :options="allParts" v-model="newPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width:200px; display: inline-block;"></multiselect>-->
											<input id="partQuantity" class="form-control w-25" type="number" name="partQuantity" min="0" placeholder="Qty" v-model="newQuantity" style="display: inline-block">
											<i class="fas fa-plus-circle" @click="addPart(newPart, newQuantity)" style="display: inline-block"></i>
										</div>
										<div v-if="newPart.itemid == '**Estimated Labor**'">
											<multiselect v-b-tooltip.hover title="Required Field" id="stepParts" :preserveSearch="true" :clearable="true" :show-labels="false" :options="allParts" group-label="name" :group-select=false group-values="items" v-model="newPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width:10vw" @search-change="debounceInventory">
                                                <template slot="option" slot-scope="props">
                                                    <intersect v-if="props.option.itemid === allInv[allInv.length - 1].itemid" @enter="getMoreInv">
                                                        <span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
                                                    </intersect>
                                                </template>
                                            </multiselect><br>
											<!--<multiselect id="stepParts" :clearable="true" :show-labels="false" :options="allParts" v-model="newPart" :multiple="false" track-by="itemid" label="itemdesc" placeholder="Parts" style="width:200px; display: inline-block;"></multiselect><br>-->
											<input class="form-control w-25" type="number" name="estimatedHours" min="0" placeholder="Hours" v-model="estimatedHours" style="display: inline-block">
											<input class="form-control w-25" type="number" name="rate" min="0" placeholder="Rate" v-model="estimatedRate" style="display: inline-block">
											<i class="fas fa-plus-circle" @click="addLabor()" style="display: inline-block"></i>

										</div>

									</td>
									<td>
										<multiselect v-b-tooltip.hover title="Required Field" id="stepAuthorization" :clearable="true" :show-labels="false" :options="allContacts" v-model="newStep.authorization" :multiple="false" track-by="name" label="name" placeholder = "Contact Authorization"></multiselect>
										<multiselect v-b-tooltip.hover title="Required Field" id="billSelect" label="name" placeholder="Bill To" track-by="name" :clearable="true" :show-labels="false" :options="billOptions" v-model="newStep.billTo" group-label="name" :group-select="false" group-values="items" @search-change="debounceCustomers" @select="newStep.billTo.selectedTask = {}"></multiselect>
										<div v-if="newStep.billTo.contractid > 0">
											Contract task: <br>
											<multiselect :clearable="false" :show-labels="false" :options="newStep.billTo.tasks" label="index" track-by="index" :multiple="false" v-model="newStep.contractTask"></multiselect>
										</div>
									</td>
									<td colspan="6"><i style="margin-top:20px;" class="fas fa-plus-circle fa-3x" @click="addStep()"></i></td>
								</template>
							</b-table>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Show Service Estimate -->
		<div v-if="action === 'viewServiceEstimate'">
			<viewEstimate :estimate="selectedTicket" @return="returnFromViewTicket()" @convert="convertToWO"></viewEstimate>
		</div>

	</div>
</template>

<script src="https://code.jquery.com/jquery-1.12.3.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script>

<script>
/*eslint-disable*/
import '../styles/ticketing.css'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import { BryntumScheduler } from '@bryntum/scheduler-vue'
import { schedulerConfig } from './AppConfig'
import "@bryntum/scheduler/scheduler.stockholm.css";
import printJS from 'print-js'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import html2canvas from 'html2canvas'
import { Socket } from 'socket.io-client'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { BDropdown } from 'bootstrap-vue'
import specialOrder from './Service/SpecialOrder.vue'
import locationSelect from './Service/InventoryLocationSelecter.vue'
import { printTicket } from './Service/printTicket.js'
import viewTicket from './Service/viewTicket.vue'
import Paginate from 'vuejs-paginate'
import Intersect from 'vue-intersect'
import { debounce } from 'lodash'
import firehouse from './Customers/firehouse.vue'
import newAsset from './Customers/newAsset.vue'
import newContact from './Customers/newContact.vue'
import serviceInvoice from './Service/serviceInvoice.vue'
import newVehicle from './Customers/newVehicle.vue'
import newCustomer from './Customers/newCustomer.vue'
import viewEstimate from './Service/viewServiceEstimate.vue'
import techLocator from './Service/techLocator.vue'

var _ = require('lodash');

export default {
	name: "app",
	props: {
		socket: Socket,
		companyId: Number,
		user: Object,
		qbo: Boolean,
		taxRate: Number,
		userRoles: Array,
        canAccess: Object,
		displayClass: Boolean,
	},
	components: {
		Multiselect,
        BryntumScheduler,
        VueCtkDateTimePicker,
        specialOrder,
        locationSelect,
        Paginate,
        Intersect,
        debounce,
        firehouse,
        newAsset,
        newContact,
        viewTicket,
        serviceInvoice,
        newVehicle,
		newCustomer,
		viewEstimate,
        techLocator
	},

    data() {
        return{
			action: '',
			allAssets: [],
			allAssetsVehicles: [],
			allAwaiting: [
				{'customer': 'Chicago Fire', 'ticketid': 32, 'task': 'Step 1'},
				{'customer': 'Chicago Fire', 'ticketid': 36, 'task': 'Step 2'}
			],
			allContacts: [],
			allCustHouses: [],
			allCustomers: [],
			allFulfilled: [
				{'customer': 'Customer Two', 'ticketid': 12, 'task': 'Step 1-3'},
				{'customer': 'Customer Two', 'ticketid': 29, 'task': 'Step 1-2'}
			],
			allHouses: [],
			allInv: [],
			allParts: [
				{
					name: 'Part',
					items: []
				},
				{
					name: 'Equipment',
					items: []
				},
				{
					name: 'Kit',
					items: []
				},
				{
					name: 'Unset',
					items: []
				},
				{
					name: 'Special Order',
					items: []
				}
			],
			allTechs:[],
			allTickets: [],
			allVehicles: [],
			allServiceEstimatesActive:[],
			allServiceEstimatesInactive: [],
			billOptions: [],
			contractsArray: [],
			currentPage: {
				tickets: 1,
				recurring: 1,
				parts_fulfilled: 1,
				parts_awaiting: 1,
				today: 1,
			},
			custObj: {},
			creationDate: new Date().toISOString().split('T')[0],
			editPart:{},
			editQty:'',
			editWorkOrder: false,
			event: {},
			eventColor: '',
			eventid: '',
			estimatedRate:'',
			estimatedHours:'',
			geocoder: new google.maps.Geocoder(),
			isBusy: false,
			lineItems: [],
			lastSelectedIndex: -1,
            lastState: "",
			locAddress: '',
			locName: '',
			map: null,
            mapTechs: [],
			markers: [],
			mainTableView: 'Work Orders',
			maxPagesFiltered: null,
			multiAsset: true,
			newAddress:{
				street:'',
				city:'',
				zip:'',
				state:''
			},
			newAsset: {
				asset_type: '',
				model: '',
				mfg: '',
				serial: '',
				mfg_date: '',
				location: ''
			},
			newContact: {
				name: '',
				title: '',
				number: '',
				email: '',
				address: '',
				customer: ''
			},
			newPart:'',
			newStep:{
				newindex: 0,
				newdesc: "",
				newInternalDesc: "",
				newparts: [],
				newPartsRequested: "",
				authorization: '',
				billTo: '',
				new_in: null,
				new_out: null,
				newcomments: [],
				completed:0,
				invoiced:0,
				stepLabor: 0
			},
			newTicket: {
				author: this.$globals.user.name,
				newContact: null,
				newtech: [],
				newvehicle: null,
				newasset: [],
				newcustomer: null,
				newstart: null,
				newend: null,
				newstatus: null,
				newdata:[],
				newid: "",
			},
			newVehicle: {
				customer: '',
				make: '',
				model: '',
				vin: '',
				unit: '',
				pump_model: '',
				pump_serial: '',
				engine_serial: '',
				trans_serial: '',
				manf_build: '',
			},
			newQuantity: 1,
			schedulerConfig,
            rows: 1,
			search:{
				main: '',
				fulfilled: '',
				awaiting: '',
				recurring: '',
				today: '',
			},
			selectedContact:[],
			selectedCustomer: {},
			selectedTicket: {},
			selectedVehicle: null,
			spOrdParts: [],
			stepLabor: 0,
			selectedTasks: [],
			subaction:'view',
			ticketTableFields: [
				{
					key: 'localid',
					sortable: true,
					label: "Work Order"
				},
				{
					key: 'technician',
					sortable: true
				},
				{
					key: 'customer',
					sortable: true,
				},
				{
					key: 'asset',
					sortable: true,
				},
				{
					key: 'start_date',
					sortable:true,
				},
				{
					key: 'status',
					sortable:true,
				},

			],
			serviceEstimateFields:[
				{
					key: 'localid',
					label: "Number"
				},
				{
					key: 'customer',
					sortable: true,
				},
				{
					key: 'asset',
					sortable: true,
				},
				{
					key: 'statusReason',
					label: "Status"
				},
			],
			totalsArray : [],
			totalStepLabor: 0,
			schedulerKey: "week",
			maxPagesTickets: 0,
            mapInterval: null,
			searchTickets: "",
			currentPageTickets: 1,
			// Customer Things
			maxPagesCustomers: 0,
			pageNumberCustomers: 0,
			prevSearchCustomers: "",
            maxPagesInv: 0,
            pageNumberInv: 0,
            prevSearchInv: 0,
			// Active Service Estimaate Things
			activeServiceEstimatesCurrentPage: 1,
			activeServiceEstimatesMaxPages: 0,
			activeServiceEstimatesSearch: "",
			// Inactive Service Estimates Things
			inactiveServiceEstimatesCurrentPage: 1,
			inactiveServiceEstimatesMaxPages: 0,
			inactiveServiceEstimatesSearch: "",
			allTicketsFiltered: [],
			currentPageFiltered: 1,
			ticketFilterType: "",
			ticketFilterSearch: "",
            pendingReq: false,

		}
    },//END data

	computed: {
		maxRowsTickets(){
			return this.maxPagesTickets * 10;
		},

		maxRowsActiveEstimates(){
			return this.activeServiceEstimatesMaxPages * 10;
		},

		maxRowsInactiveEstimates(){
			return this.inactiveServiceEstimatesMaxPages * 10;
		}
	},

	beforeCreate(){
		this.source = {
			dataType: 'json',
			dataFields: [
				{ name: 'id', type: 'string' },
				{ name: 'description', type: 'string' },
				{ name: 'location', type: 'string' },
				{ name: 'subject', type: 'string' },
				{ name: 'technician', type: 'string' },
				{ name: 'start', type: 'date' },
				{ name: 'end', type: 'date' }
			],
			id: 'id',
			url: '',
		};
	},//END beforeCreate()

	async created() {
		const js = this
		this.$globals.socket.emit("joinRoom", `service${this.$globals.companyId}`);

		this.$globals.socket.on("newDataCreated", async function(room){
            if(js.action === ''){
                await axios
                    .get(
                    process.env.VUE_APP_API_TARGET + "/tickets/techs/all/" + js.$globals.companyId,{headers: {'Authorization':'Bearer ' + js.user.auth0Token }})
                    .then((response) => {
                        js.allTechs = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
                        js.allTechs.unshift({
                            name: "Unassigned",
                            workZone: "Unassigned",
                        });
                        js.populateScheduler();
                        js.$forceUpdate();
                    })
                    .catch((response)=>{
                        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : js.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                    })
            }
		//get all customers
            axios
                .get(process.env.VUE_APP_API_TARGET + "/customers/" + js.$globals.companyId, {headers: {'Authorization':'Bearer ' + js.user.auth0Token }})
                .then((response) => {
                    js.allCustomers = response.data.queryResult;
                    js.allCustomers.sort((customerA, customerB) => {
                        return customerA.name < customerB.name ? -1 : 1
                    })
                })
                .catch((response)=>{
                    this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : js.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                })
		});

        this.$globals.socket.on("newTechData", async function(args){
            if(js.action === ''){
            await axios
				.get(
				process.env.VUE_APP_API_TARGET + "/tickets/techs/all/" + js.$globals.companyId,{headers: {'Authorization':'Bearer ' + js.$globals.user.auth0Token }})
				.then((response) => {
                    js.allTechs = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
                    js.allTechs.unshift({
                        name: "Unassigned",
                        workZone: "Unassigned",
                    });
                    js.populateScheduler();
                    js.$forceUpdate();
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : js.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            }
        })

		document.addEventListener("colorChanged", (record) => {
            this.eventColor = JSON.parse(
                JSON.stringify(record.detail.data.eventColor)
            );
            this.eventid = JSON.parse(JSON.stringify(record.detail.data.id));
            this.changeColors(this.eventColor, this.eventid);
		});

		this.debounceTickets = debounce(this.queryTickets, 600);
		this.debounceCustomers = debounce(this.searchCustomers, 600);
        this.debounceInventory = debounce(this.searchInv, 600);
		this.debounceSEActive = debounce(this.querySEActive, 600);
		this.debounceSEInactive = debounce(this.querySEInactive, 600);
		this.debounceFilteredTickets = debounce(this.queryFilteredTickets, 600);
		// this.debounceVendors = debounce(this.searchVendors, 600);
	}, //END created()

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `service${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        this.$globals.socket.off("newTechData")
    },

	async mounted() {
		this.isBusy = true;
		const beforeUnloadListener = (event) =>{
			this.cancelEdit()
		}

		new Promise(async (resolve, reject) => {

			await axios
				.get(`${process.env.VUE_APP_API_TARGET}/tickets/techs/all/${this.$globals.companyId}` , {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allTechs = response.data.queryResult.sort((a,b) => a.name < b.name ? -1 : 1);
					this.allTechs.unshift({ name: "Unassigned", workZone: "Unassigned" });
				})
				.catch((response)=>{
					reject()
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			//get all parts
			this.getInv()

			//get all customers
			this.getCustomers()


			//populate Scheduler with ticket and technician data
			this.populateScheduler().then(() => {
                this.$nextTick(() => {
                    this.mainTableView= this.mainTableView ?? 'Work Orders'
                })
                if(this.mapInterval !== null) clearInterval(this.mapInterval)
                this.mapInterval = setInterval(this.shiftUnit, 30 * 1000);
                resolve()
            })
		}).then(() => this.isBusy = false)
	}, //END mounted()

    methods: {
    	returnFromViewTicket(){
            this.action = '';
    		this.selectedTicket = null;
            this.mapTechs = []
			this.queryTickets()
			this.queryFilteredTickets();
			this.querySEActive()
			this.querySEInactive()
            this.isBusy = true
			this.populateScheduler().then(() => {
                this.isBusy = false;
            })
    	},

		async queryTickets(){
            this.isBusy=true;
			let text = this.searchTickets === "" ? null : this.searchTickets
			this.currentPageTickets = 1
			this.allTickets = []
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/${this.$globals.companyId}/${this.currentPageTickets}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})

			.then((response) => {
				this.allTickets[this.currentPageTickets] = response.data.queryResult.results;
				this.maxPagesTickets = response.data.queryResult.maxPages;
				this.isBusy = false;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.$refs.WOTable.refresh()
            this.$nextTick(() => {
                this.$forceUpdate()
            })
		},

		async querySEActive(){
			this.isBusy=true;
			let text = this.activeServiceEstimatesSearch === "" ? null : this.activeServiceEstimatesSearch
			this.activeServiceEstimatesCurrentPage = 1
			this.allServiceEstimatesActive = []
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/serviceEstimates/status/`+0+`/${this.$globals.companyId}/${this.activeServiceEstimatesCurrentPage}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})

				.then((response) => {
					this.allServiceEstimatesActive[this.activeServiceEstimatesCurrentPage] = response.data.queryResult.results
					this.activeServiceEstimatesMaxPages = response.data.queryResult.maxPages
					this.isBusy =false;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.$refs.ASETable.refresh()
			this.$nextTick(() => {
				this.$forceUpdate()
			})
		},

		async querySEInactive(){
			let text = this.inactiveServiceEstimatesSearch === "" ? null : this.inactiveServiceEstimatesSearch
			this.inactiveServiceEstimatesCurrentPage = 1
			this.allServiceEstimatesInactive = []
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/serviceEstimates/status/`+1+`/${this.$globals.companyId}/${this.inactiveServiceEstimatesCurrentPage}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})

				.then((response) => {
					this.allServiceEstimatesInactive[this.inactiveServiceEstimatesCurrentPage] = response.data.queryResult.results
					this.inactiveServiceEstimatesMaxPages = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.$refs.ISETable.refresh()
			this.$nextTick(() => {
				this.$forceUpdate()
			})
		},

		async queryFilteredTickets(){
			let search_text = this.ticketFilterSearch === "" ? null : this.ticketFilterSearch
			let filter_text = this.ticketFilterType === "" ? 'today' : this.ticketFilterType

			this.currentPageFiltered = 1
			this.allTicketsFiltered = []

			if(this.ticketFilterType !== 'today'){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/filtered/${filter_text}/${this.$globals.companyId}/${this.currentPageFiltered}/search_text/${encodeURIComponent(search_text)}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{

					this.allTicketsFiltered[this.currentPageFiltered] = response.data.queryResult.results;
					this.maxPagesFiltered = response.data.queryResult.maxPages;
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			else{
				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/date/${new Date().toISOString().split("T")[0]}/${this.$globals.companyId}/${this.currentPageFiltered}/search_text/${encodeURIComponent(search_text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{

					this.allTicketsFiltered[this.currentPageFiltered] = response.data.queryResult.results;
					this.maxPagesFiltered = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

			this.$refs.WOTableFiltered.refresh()
			this.$nextTick(() => {
				this.$forceUpdate()
			})
		},

		//Remove?
		changePageActive(event){
			this.activeServiceEstimatesCurrentPage = parseInt(event.target.ariaPosInSet)
		},

		//Remove?
		changePageInactive(event){
			this.inactiveServiceEstimatesCurrentPage = parseInt(event.target.ariaPosInSet)
		},

		async ticketProvider(){
			let text = this.searchTickets === "" ? null : this.searchTickets
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/${this.$globals.companyId}/${this.currentPageTickets}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})

			.then((response) => {
				this.allTickets[this.currentPageTickets] = response.data.queryResult.results;
				this.maxPagesTickets = response.data.queryResult.maxPages;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			return this.allTickets[this.currentPageTickets]
		},

		async getCustomers(){
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allCustomers = response.data.queryResult.results
					this.maxPagesCustomers = response.data.queryResult.maxPages
					this.pageNumberCustomers = this.pageNumberCustomers+1
					this.generateBillToOptions();
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		},

		async getMoreCustomers(){
			if(this.pageNumberCustomers >= this.maxPagesCustomers){
				return
			}
			let text = this.prevSearchCustomers === "" ? text = null : text = this.prevSearchCustomers
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					for(var item in response.data.queryResult.results){
						this.allCustomers.push(response.data.queryResult.results[item])
					}
					this.maxPagesCustomers = response.data.queryResult.maxPages
					this.pageNumberCustomers = this.pageNumberCustomers+1
                    this.generateBillToOptions();
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		},

		async searchCustomers(searchValue){
			if(searchValue === "" || searchValue === null){
				if(this.prevSearchCustomers === "" || this.prevSearchCustomers === null){
					return
				}
				searchValue = null
			}
			this.customerListIsLoading = true
			this.pageNumberCustomers = 0
			this.prevSearchCustomers = searchValue
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumberCustomers+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allCustomers = response.data.queryResult.results
					this.maxPagesCustomers = response.data.queryResult.maxPages
					this.pageNumberCustomers = this.pageNumberCustomers+1
                    this.generateBillToOptions();
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.customerListIsLoading = false
			// this.selectedCustomer = this.allCustomers[0]
		},

        async getInv(){
            await axios
			.get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allInv = response.data.queryResult.results
					this.maxPagesInv = response.data.queryResult.maxPages
					this.pageNumberInv = this.pageNumberInv+1
                    this.createGroups()
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
        },

        async getMoreInv(){
            if(this.pageNumberInv >= this.maxPagesInv){
				return
			}
			let text = this.prevSearchInv === "" ? text = null : text = this.prevSearchInv
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					for(var item in response.data.queryResult.results){
						this.allInv.push(response.data.queryResult.results[item])
					}
					this.maxPagesInv = response.data.queryResult.maxPages
					this.pageNumberInv = this.pageNumberInv+1
                    this.createGroups()
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
        },

        async searchInv(searchValue){
            if(searchValue === "" || searchValue === null){
				if(this.prevSearchInv === "" || this.prevSearchInv === null){
					return
				}
				searchValue = null
			}
            this.invListIsLoading = true
			this.pageNumberInv = 0
			this.prevSearchInv = searchValue

            await axios
			.get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumberInv+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allInv = response.data.queryResult.results
					this.maxPagesInv = response.data.queryResult.maxPages
					this.pageNumberInv = this.pageNumberInv+1
                    this.createGroups()
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.invListIsLoading = false
        },

		async activeEstimateProvider(){
			let text = this.activeServiceEstimatesSearch === "" ? null : this.activeServiceEstimatesSearch
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/serviceEstimates/status/`+0+`/${this.$globals.companyId}/${this.activeServiceEstimatesCurrentPage}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allServiceEstimatesActive[this.activeServiceEstimatesCurrentPage] = response.data.queryResult.results
					this.activeServiceEstimatesMaxPages = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				return this.allServiceEstimatesActive[this.activeServiceEstimatesCurrentPage]
		},

		async inactiveEstimateProvider(){
			let text = this.inactiveServiceEstimatesSearch === "" ? null : this.inactiveServiceEstimatesSearch
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/serviceEstimates/status/`+1+`/${this.$globals.companyId}/${this.inactiveServiceEstimatesCurrentPage}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
					this.allServiceEstimatesInactive[this.inactiveServiceEstimatesCurrentPage] = response.data.queryResult.results
					this.inactiveServiceEstimatesMaxPages = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				return this.allServiceEstimatesInactive[this.inactiveServiceEstimatesCurrentPage]
		},

        onFiltered(args) {
            this.rows = args.length;
            this.isBusy = false;
        },

		addKitParts(){
			var tempKit = {...this.newPart};
			var tempComponents = JSON.parse(tempKit.components);
			let tempQty = parseInt(this.newQuantity)

			tempComponents.forEach((item, i) => {
				var kitQty = 1;
				item.itemid = item.itemid || item.id
				axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${item.itemid}/total/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				}).then((response)=>{
					//item = response.data.queryResult[0]
					kitQty = parseInt(item.qty) * tempQty
					this.addPart(response.data.queryResult[0], kitQty)
				})
			});
		},

		addSOPart(data){
			data.id = "**SO** " + data.id;
			this.spOrdParts.push(data)
			for(let part in this.newStep.newparts){
				if(this.newStep.newparts[part].itemid == "**Special Order**"){
					this.newStep.newparts[part].itemid = data.id;
					this.newStep.newparts[part].itemdesc = data.desc;
					this.newStep.newparts[part].qty = parseInt(data.qty);
					this.newStep.newparts[part].sell = parseFloat(data.sell);
					this.newStep.newparts[part].cost = parseFloat(data.cost);
					this.newStep.newparts[part].otherLocations = [];
					this.newStep.newparts[part].type = data.type;
					this.newStep.newparts[part].vendor = data.vendor.company;
					this.newStep.newparts[part].notReceivedFlag = true;
				}
			}
		},

		async addEditSOPart(data,row){
			//only can accept data or row (weirdness with emit data)
			//accept data then in line call regular addEditedPart(row)
			//set data to this.editPart
			data.id = "**SO** " + data.id;

			let tempInfo = {
				cost: parseFloat(data.cost),
				itemdesc: data.desc,
				itemid: data.id,
				otherLocations: [],
				qty: data.qty,
				sell: data.sell,
				type: data.type,
				vendor: data.vendor.company,
				notReceivedFlag: true,
				fulfillment: data.fulfillment,
				fulfilledQty: data.fulfilledQty,
				locationFlag: true
			}
			this.spOrdParts.push(tempInfo)
			row.parts.pop()
			row.parts.push(tempInfo)

		},

		async addStep(){
			if(this.newTicket.newcustomer === null || this.newTicket?.newcustomer === undefined){
				if(this.selectedTicket?.customer !== null && this.selectedTicket?.customer !== undefined){
					//Shouldn't happen barring weirdness
					this.newTicket.newcustomer = {...this.selectedTicket.customer}
				}
				else{
					alert("Please select a Customer before trying to add a step!");
					return;
				}
			}
			if(this.newTicket?.newContact === null || this.newTicket?.newContact === undefined){
				alert("Please select a Primary contact for this ticket!");
				return;
			}
			if(this.newStep.newdesc === "" || this.newStep?.newdesc === undefined || this.newStep.newdesc === null){
				alert("A new step requires a Work Requested to be filled in!");
				return;
			}
			if(this.newStep.billTo?.contractid > 0 && this.newStep.contractTask === null){
				alert("Select contract task")
				return
			}

			if(this.newStep.stepLabor < 0){
				Math.abs(this.newStep.stepLabor)
			}

			//Used to help with calculating the time of projected end date
			this.totalStepLabor = this.totalStepLabor + parseFloat(this.newStep.stepLabor);

			//If empty fill billTo and Authorized by with Overarching customer and contact.
			if(this.newStep.billTo == '' || this.newStep.billTo == null){
				this.newStep.billTo = this.newTicket.newcustomer ? this.newTicket.newcustomer : await this.getCustomerByName(this.selectedTicket.customer)
			}
			if(this.newStep.authorization == '' || this.newStep.authorization == null){
				this.newStep.authorization = this.newTicket.newContact;
			}

			if(this.newStep.contractTask === {} || this.newStep.contractTask === undefined){
				this.newStep.contractTask = null;
			}

			//Push the new step into the ticket
			this.newTicket.newdata.push({
				"task": `Task ${this.newTicket.newdata.length + 1}`,
				"desc": this.newStep.newdesc,
				"internalDesc": this.newStep.newInternalDesc,
				"parts": this.newStep.newparts,
				"partsRequested": this.newStep.newPartsRequested,
				"labor": this.newStep.stepLabor,
				"authorization": this.newStep.authorization,
				"billTo": this.newStep.billTo ,
				"contractTask": this.newStep.contractTask || null,
				"time_in": [],
				"time_out": [],
				"comments": this.newStep.newcomments,
				"completed":0,
				"invoiced":0
			})

			this.$refs.newTicketSteps.refresh();
			this.newStep.newdesc ="";
			this.newStep.newcomments = [];
			this.newStep.stepLabor = 0;
			this.newStep.newparts=[];
			this.newStep.newInternalDesc="";
			this.newStep.newPartsRequested="";
		},//END addStep

		async addPart(newPart, qty) {
			if(newPart === '' || newPart === null){
				alert("Select a part")
				return;
			}

			if(newPart.itemid.substring(0,3) == "**K"){
				this.addKitParts();
				return;
			}

			let flag = false
			this.newStep.newparts.forEach((item, i) => {
				if(newPart.itemid === item.itemid){
					item.qty = parseInt(item.qty) + parseInt(qty)
					item.locationFlag = true;
					flag = true
				}
			});
			if(flag === false){
				if(!newPart.itemid.includes('**')){
					await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(newPart.vendor)}/${this.$globals.companyId}`,
						{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
					}).then((response)=>{
						newPart.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
					})

					newPart.itemMarkup = newPart.itemMarkup ?? null
					newPart.sell = newPart.highCost * (1+(newPart.itemMarkup ?? newPart.vendorMarkup))
				}

				newPart = {...newPart, 'qty': qty}
				newPart.fulfillment = newPart.fulfillment ?? [{qty:0}];

				newPart.locationFlag = true;
				this.newStep.newparts.push(newPart);
			}

			this.newPart = '';
			this.newQuantity = 1;
		},

        async getCustomerByName(name){
            let response = await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${name}`, {
                headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

            return response.data.queryResult[0]
        },

		async addEditedPart(row){
			if(this.editPart?.itemid === undefined || this.editPart === null){
				alert("Select an item before adding")
				return;
			}
			if(this.editQty === '' || this.editQty < 1){
				this.editQty = 1;
			}

			if(this.editPart.itemid.includes("**K")){
				var tempComponents = typeof this.editPart.components === 'object' ? this.editPart.components : JSON.parse(this.editPart.components)
				for(let index in tempComponents){
					let flag = false
					row.parts.forEach((item, i) => {
						if(tempComponents[index].itemid === item.itemid){
							item.qty = parseInt(item.qty) + parseInt(tempComponents[index].qty * this.editQty)
							item.locationFlag = true;
							flag = true
						}
					});
					if(flag === false){
						let kitQty = tempComponents[index].qty
						await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${tempComponents[index].itemid}/total/${this.$globals.companyId}`,
							{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
						}).then((response)=>{
							tempComponents[index] = response.data.queryResult[0]
						})
						await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(tempComponents[index].vendor)}/${this.$globals.companyId}`,
							{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
						}).then((response)=>{
							tempComponents[index].vendorMarkup = response.data.queryResult[0]?.markup || 0.1
						})

						tempComponents[index].itemMarkup = tempComponents[index].itemMarkup ?? null
						tempComponents[index].sell = tempComponents[index].highCost * (1+(tempComponents[index].itemMarkup ?? tempComponents[index].vendorMarkup))

						row.parts.push({
							...tempComponents[index],
							'qty': kitQty * this.editQty,
							'fulfillment' : tempComponents[index].fulfillment ?? [{qty:0}],
							locationFlag: true
						})
					}
                }
			}
			else{
				let flag = false
				row.parts.forEach((item, i) => {
					if(this.editPart.itemid === item.itemid){
						item.qty = parseInt(item.qty) + parseInt(this.editQty)
						item.locationFlag = true;
						flag = true
					}
				});
				if(flag === false){
					if(!this.editPart.itemid.includes("**")){
						await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.editPart.vendor)}/${this.$globals.companyId}`,
							{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
						}).then((response)=>{
							this.editPart.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
						})

						this.editPart.itemMarkup = this.editPart.itemMarkup ?? null
						this.editPart.sell = this.editPart.highCost * (1+(this.editPart.itemMarkup ?? this.editPart.vendorMarkup))
						this.editPart.locationFlag = true;

					}
					else{
						this.editPart.locationFlag = false;

					}

					this.editPart = {...this.editPart, 'qty': this.editQty}
					this.editPart.fulfillment = this.editPart.fulfillment ?? [{qty:0}];

					row.parts.push(this.editPart);
				}
			}
			this.editPart = '';
			this.editQty='';
		},

		addLabor(location){
			if(typeof location == 'object'){
				this.editPart = {...this.editPart, 'qty':this.estimatedHours, 'sell': this.estimatedRate, 'highCost': this.estimatedRate, 'cost': this.estimatedRate}
				this.editQty = parseFloat(this.estimatedHours)
				this.addEditedPart(location)
			}
			else {
				this.newPart = {...this.newPart, 'qty': this.estimatedHours, 'sell': this.estimatedRate, 'highCost': this.estimatedRate, 'cost': this.estimatedRate}
				this.newStep.newparts.push(this.newPart)
			}

			this.estimatedHours ='';
			this.estimatedRate='';
			this.newPart ='';
		},

		cancelEdit(){
			if(this.selectedTicket?.ticketid == undefined){
				//Go backwards to make sure the length change doesn't matter while deleting.
				for(let i = this.newTicket.newdata.length-1; i >=0; i--){
					this.deleteStep(this.newTicket.newdata[i])
				}
			}
			else{
				axios.put(`${process.env.VUE_APP_API_TARGET}/tickets/canceledEdit/${this.selectedTicket.ticketid}/${this.$globals.companyId}`,{
					data: JSON.stringify(this.newTicket.newdata)
				},{headers: {'Authorization': 'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

			if(this.newStep.newparts.length > 0){
				for(let index in this.newStep.newparts){
					this.deletePart(index,this.newStep.newparts)
				}
			}

			this.newTicket = {}
			this.selectedContact = [];
			this.contractsArray = [];
			this.locAddress = ''
			this.clearTicket()

			this.action = '';
            this.pendingReq = false;
			removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
			this.$globals.shield = false;
		},

		async changeColors(eventColor, eventid){
			var color = eventColor;
			var id = eventid;
			var userid;

			//find the tech in all techs and pass relevent data to patch
			for(let tech in this.allTechs){
				if(this.allTechs[tech].id == id){
					userid = this.allTechs[tech].auth0;
					break;
				}
			}
			await axios.patch(
				process.env.VUE_APP_API_TARGET + "/tickets/tech/" +
				userid +
				"/color/" +
				this.$globals.companyId,
				{
				color: color,
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.populateScheduler();
			this.action = '';
		},

		async changeLocation(){ //This function may be outdated
			//get custid using custname
			var cust;
			var custid;
			for(cust in this.allCustomers){
				if(this.selectedTicket.customer == this.allCustomers[cust].name){
					custid = this.allCustomers[cust].customerid;
				}//END if
			}//END for

			//get all of cust's houses
			await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+custid+"/houses/"+ this.$globals.companyId,
			{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(response => {
				this.allCustHouses = response.data.queryResult
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		},//END changeLocation()

		changeState(string) {
			if(string === "createTicket"){
				addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
				this.$globals.shield = true;
                this.newTicket = {
                    author: this.$globals.user.name,
                    newContact: null,
                    newtech: [],
                    newvehicle: null,
                    newasset: [],
                    newcustomer: null,
                    newstart: null,
                    newend: null,
                    newstatus: null,
                    newdata:[],
                    newid: "",
                }
				this.action = "createTicket"
			}
			else if(string === "editTicket") {
				this.clearTicket();
				this.action = "editTicket"
			}
			else if(string === "deleteTicket"){
				this.action = "deleteTicket"
			}
			else if(string === "searchCust"){
				this.action = "searchCust"
			}
			else if(string === "searchTickets"){
				this.action = "searchTickets"
			}
			else if(string === "changeLocation"){
				this.action = "changeLocation"
			}
			else if(string == "serviceEstimate"){
				this.action ="serviceEstimate";
				let labor ={
					itemid : "**Estimated Labor**",
					itemdesc : "**Estimated Labor**",
					sell : 0,// gets set manually
					cost : 0
				}
				this.allParts.unshift({'name': 'Labor', 'items': [{...labor}]});
			}
			else if(string ==="viewMap"){
				this.action = "viewMap"
			}
			else if(string === "viewTicket"){
				this.action= "viewTicket"
				this.clearTicket();
				this.subaction = 'view';
			}
			else {
				if(this.allParts[0].name == "Labor"){
					this.allParts.splice(0, 1);
				}
				this.action = '';
				this.clearTicket();
				this.populateScheduler()
			}
		},//END changeState()

		clearTicket(){
			//this.deleteStep(this.newStep)
			this.newTicket= {
				newtech: [],
				newvehicle: null,
				newContact: [],
				newcustomer: null,
				newstart: null,
				newend: null,
				newstatus: null,
				newdata:[],
				newid: "",
			}
			this.newPart = ''
			this.locAddress ='';
			this.subaction = 'view';
			this.selectedContact=[];
			this.allContacts = [];
			this.contractsArray = []
			this.allAssetsVehicles=[];
			this.newStep.authorization=null;
			this.newStep.billTo='';
			this.newStep.contractTask = null;
			this.newStep.newdesc ="";
			this.newStep.newcomments = [];
			this.newStep.newparts=[];
			this.newStep.stepLabor = 0;
			this.newStep.newInternalDesc="";
			this.generateBillToOptions()

		},//END clearTicket()

		async convertToWO(serviceEstimate){
			//Labor Removal Loops
			for(let index in serviceEstimate.data){
				for(let i in serviceEstimate.data[index].parts){
					if(serviceEstimate.data[index].parts[i].itemid.includes('Estimated Labor')){
						this.selectedTicket.data[index].labor = serviceEstimate.data[index].parts[i].qty
						serviceEstimate.data[index].parts.splice(i,1)
					}
				}
				serviceEstimate.data[index].partsRequested = serviceEstimate.data[index].parts.map((part)=>{
					return `${part.itemid} - ${part.itemdesc} - ${part.qty}`
				}).join(', ')
				serviceEstimate.data[index].parts = []
			}

			this.selectedTicket.data = serviceEstimate.data;

			this.selectedTicket.asset = typeof serviceEstimate.asset !== 'object' ? JSON.parse(serviceEstimate.asset) : serviceEstimate.asset;
			this.selectedTicket.vehicle = serviceEstimate.vehicle

			if(this.selectedTicket.vehicle !== null && this.selectedTicket.vehicle !== undefined){
				await this.getVehicleInfo(this.selectedTicket.vehicle, this.selectedTicket?.contact?.customerid)
			} else {
				await this.getAssetInfo(this.selectedTicket.asset)
			}

			this.selectedTicket.technician = [];
			this.selectedTicket.start_date = null;
			this.selectedTicket.end_date = null;


			this.editTicket(this.selectedTicket)
			this.action = 'createTicket';
		},

		async createAssetGroups(assetsArray, vehiclesArray){
			this.allAssetsVehicles = [];
			this.allAssetsVehicles.push({name: "General Service", items: [{id:"Service", asset_type: 'NonAsset'}]})

			let assetTypes = []
			await axios.get(`${process.env.VUE_APP_API_TARGET}/assets/types/${this.$globals.companyId}`,
				{headers: {'Authorization': 'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response)=>{
				assetTypes = JSON.parse(response.data.queryResult[0].types)
			})

			for(let i in assetTypes){
				let tempTypeArray = assetsArray.filter((item) =>{
					if(item.asset_type == assetTypes[i]){
						return item
					}
				})
				this.allAssetsVehicles.push({name: assetTypes[i], items: tempTypeArray})
			}

			let vehItems = []
			for(let veh in vehiclesArray){
				vehiclesArray[veh].asset_type = "Vehicle";
				vehiclesArray[veh].id = vehiclesArray[veh].vehicleid;
				vehItems.push(vehiclesArray[veh]);
			}

			this.allAssetsVehicles.push({name: "Apparatus", items: vehItems});
		},//END createAssetGroups

		async createContact(customer){
			if(this.newContact.name === ''){
				return alert("Contact must have a name.");
			}
			if(this.newContact.customer === ''){
				return alert("Contact must have a company.");
			}
			if(this.newContact.title === ''){
				return alert("Contact must have a title.");
			}
			let address = this.newAddress.street + ", " + this.newAddress.city + ", " + this.newAddress.state + ", " + this.newAddress.zip;

			if(this.newAddress.street == '' || this.newAddress.city == '' || this.newAddress.state == '' || this.newAddress.zip == ''){
				address = null;
			}
			await axios.post(process.env.VUE_APP_API_TARGET + "/customers/"+customer.customerid+"/contacts/" + this.$globals.companyId,{
				name: this.newContact.name,
				address: address,
				phone: this.newContact.phone,
				email: this.newContact.email,
				title: this.newContact.title
			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			//This is used just to create the address and get its id from easypost
			if(address !== null){
				var address1 = {
					street1: this.newAddress.street,
					city: this.newAddress.city,
					state: this.newAddress.state,
					zip: this.newAddress.zip,
					country: "US",
					name: this.newContact.name,
					company: this.newContact.customer,
					phone: this.newContact.phone
				};
				address1 = JSON.stringify(address1);
			}

            this.$globals.socket.emit("newData", "service", "customer")
            this.$globals.socket.emit("newData", "customers", "customer")

			if(this.editWorkOrder === true){
				this.changeState('editTicket');
				this.getContacts(this.selectedTicket.contact);
				this.editWorkOrder = false
				this.$forceUpdate()
			}
			else{
				this.action = 'createTicket'
				this.getContacts(this.newTicket.newcustomer);
			}
		},//END createContact()

		createGroups(){
			let laborCamp = null;
			if(this.allParts[0].name == 'Labor'){
				laborCamp = this.allParts[0].items;
			}
			this.allParts = [];
			var partItems = [];
			var equipItems = [];
			var kitItems = [];
			var unsetItems = [];
			var soItems = [];
			for(let part in this.allInv){
				if(this.allInv[part].type == 'Part'){
					partItems.push(this.allInv[part]);
				}
				else if(this.allInv[part].type == 'Equipment'){
					equipItems.push(this.allInv[part]);
				}
				else if(this.allInv[part].type == 'Kit'){
					kitItems.push(this.allInv[part]);
				}
				else{
					unsetItems.push(this.allInv[part]);
				}
			}
			let item = {
				'itemid': '**Special Order**',
				'itemdesc': '**Special Order**',
				'type': 'Special Order',
				fulfillment: [{qty:0}],
			}
			soItems.push(item);
			if(laborCamp !== null){
				this.allParts.push({name: 'Labor', items: laborCamp})
			}
			this.allParts.push({name: 'Special Order', items: soItems});
			this.allParts.push({name: 'Kit', items: kitItems});
			this.allParts.push({name: 'Part', items: partItems});
			this.allParts.push({name: 'Equipment', items: equipItems});
			this.allParts.push({name: 'Unset', items: unsetItems});
		},



		techMap(event){
			//TODO Alot is commented out and James doesn't know what is dead or asleep here..
			// this.markers = [];

			// if(event.record.originalData.latestClockEvent != null){
			// 	//only need to get tech's location if tech is idle or on job site - must add to map after its creation
			// 	//if(event.record.originalData.jobStatus == "idle" || event.record.originalData.jobStatus == "working"){
			// 		let tech = this.getTechByResourceId(event.record.originalData.id);

			// 		var icon = {
			// 			url: this.allTechs[tech].picture,
			// 			scaledSize: new google.maps.Size(50, 50)
			// 		};

			// 		let techLat = parseFloat(JSON.parse(this.allTechs[tech].location).coords.latitude);
			// 		let techLng = parseFloat(JSON.parse(this.allTechs[tech].location).coords.longitude);
			// 		this.markers[0] = new google.maps.Marker({
			// 			position: new google.maps.LatLng(techLat, techLng),
			// 			map: this.map,
			// 			icon: icon
			// 		});
			// 	//}
			// 	//tech is enroute to site - need tech location and site location on map
			// 	if(event.record.originalData.jobStatus == "enroute"){
			// 		for(let ticket in this.allTickets){
			// 			if(this.allTickets[ticket].ticketid == event.record.originalData.latestEvent.wo){
			// 				this.selectedTicket = this.allTickets[ticket];
			// 			}
			// 			this.codeAddress(this.selectedTicket.loc_address);
			// 		}
			// 	}

			// 	this.action = 'viewMap';
			// 	this.$nextTick(() =>{
			// 		this.map = new window.google.maps.Map(this.$refs["map"],{
			// 			center: {lat:techLat,lng:techLng},
			// 			zoom: 12
			// 		})
			// 		for(let mark in this.markers){
			// 			this.markers[mark].setMap(this.map);
			// 		}
			// 	})
			// }
            this.mapTechs = [this.allTechs[this.getTechByResourceId(event.record.originalData.id)]];
            this.action = 'viewMap'
		},

		//This section will require the use of a Google API Key!!!
		codeAddress(address){
			this.geocoder.geocode({'address': address}), function (results, status) {
				var latLng = {lat: results[0].geometry.location.lat (), lng: results[0].geometry.location.lng ()};
				if(status == 'OK'){
					var mark = new google.maps.Marker({
						position: latLng,
						map: this.map
					})
				}
				else {
					alert('Geocode was not successful for the following reason: '+status);
				}
			}
		},

		async createTicket() {
			this.creationDate = new Date().toISOString().split('T')[0];
			var dayMilli = 86400000;
			// var startDate = this.newTicket.newstart?.slice(0, -3);
			var startMilli = Date.parse(this.newTicket.newstart);
			var laborMilli = 3600000 * this.totalStepLabor;  //= sum of estimated labor
			var endMilli;

			//Safety check
			if(this.newTicket.newcustomer === null) {
				alert("Tickets must have a customer.");
				return;
			}
			//newTicket.newasset is expected to be an array of objects
			if(this.newTicket.newasset.length === 0) {
				alert("Tickets must have at least one Asset.");
				return;
			}
			if(this.newTicket.newContact?.name == undefined || this.newTicket.newContact == null){
				alert("Please Select a Contact")
				return;
			}
			if(this.newTicket.newdata.length === 0 ){
				alert("Work Orders must have tasks")
				return;
			}

			if((this.newTicket.newstart == '' && this.newTicket.newend == '') ||(this.newTicket.newstart == null && this.newTicket.newend == null) || this.newTicket.newstatus == null){
				this.newTicket.newstatus = 'Unscheduled';
			}
			if(this.newTicket.newstart == null && this.newTicket.newstatus !== "Unscheduled"){
				alert("Tickets must have a start date or be marked 'Unscheduled'.");
				return;
			}

			if((this.newTicket.newend == null || this.newTicket.newend == '') && this.newTicket.newstart !== null){			//THIS WORKS BUT LABOR IS REQUIRED
				endMilli = startMilli + laborMilli;
				var endDate = new Date(endMilli).toLocaleString();
				var endD = endDate.split(',')[0];
				var endT = endDate.split(' ')[1];
				var endAP = endDate.split(' ')[2];
				if(endAP == "PM"){
					var endH = parseInt(endT.split(':')[0]) + 12;
					endT = endH + ':'+endT.split(':')[1]+':'+endT.split(':')[2];
				}
				endDate = endD.split('/')[2]+'-'+endD.split('/')[0]+'-'+endD.split('/')[1]+' '+endT;
				this.newTicket.newend = endDate;
				this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [this.newTicket.newtech], "Work Order End Date Changed", "Work Order End Date changed to " + endDate);
			}

			//  End date that is entered that occurs before est labor is done - THIS WORKS!
			if(this.newTicket.newend !== null && this.newTicket.newstart !== null && this.newTicket.newend !== '' && this.newTicket.newstart !== ''){
				endMilli = startMilli + laborMilli;
				var endMil = new Date(this.newTicket.newend);
				endMil = Date.parse(endMil);
				if(endMil < endMilli){
					if(confirm("End date occurs before estimated\nlabor hours can be completed.\nClick OK to generate end date with estimated labor.")){
						var newEnd = new Date(startMilli + laborMilli).toLocaleString();
						var endD = newEnd.split(',')[0];
						var endT = newEnd.split(' ')[1];
						var endAP = newEnd.split(' ')[2];
						if(endAP == "PM"){
							var endH = parseInt(endT.split(':')[0]) + 12;
							endT = endH + ':'+endT.split(':')[1]+':'+endT.split(':')[2];
						}
						newEnd = endD.split('/')[2]+'-'+endD.split('/')[0]+'-'+endD.split('/')[1]+' '+endT;
						this.newTicket.newend = newEnd;
					}
				}
				this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [this.newTicket.newtech], "Work Order Start Date Changed", "Work Order Start Date changed to " + newEnd);
			}

			if((this.newTicket.newstart !== null && this.newTicket.newend !== null)){
				if(startMilli > endMilli){
					alert("Start must be before End");
					return;
				}
				this.newTicket.newstatus = "Scheduled";
			}
			//Get location name and address of asset to be worked on
			//Get all houses in preparation for searching
            this.pendingReq = true
			this.getHouses(this.newTicket.newcustomer)

			//Check for vehicle and empty out
			var tempAssets = []; //Array of IDs
			this.newTicket.newvehicle = null;
			for(let ast in this.newTicket.newasset){
				tempAssets.push(this.newTicket.newasset[ast].id)
				if(this.newTicket.newasset[ast]?.asset_type == undefined || this.newTicket.newasset[ast]?.asset_type == 'Vehicle'){
					this.newTicket.newvehicle = tempAssets[ast]
					let fullVeh = {...this.newTicket.newasset[ast]}
					this.newTicket.newasset = []
					this.newTicket.newasset[0] = fullVeh
					break;
				}
			}

			for(let house in this.allHouses){
				if(this.newTicket.newasset[0].houseid == this.allHouses[house].houseid || this.newTicket.newasset[0].location == this.allHouses[house].name){
					if(this.allHouses[house].address !== this.locAddress){
						this.locName = "Custom Address"
					}
					else{
						this.locAddress = this.allHouses[house].address;
						this.locName = this.allHouses[house].name;
					}
					break;
				}
			}

			if(this.newTicket.newtech[0] == undefined){
				this.newTicket.newtech = []
			}

            for(let tech in this.newTicket.newtech){
                //Trim unnecessary tech data to prevent future contamination of search
                delete this.newTicket.newtech[tech].lastEvent;
                delete this.newTicket.newtech[tech].time_sheet;
            }

			//Ensure no ticket is created with a part that is a kit - qty should be inside the part!!!
			// & ensure no ticket has blank part line
			//TODO Revisit this once Kits work. This should never happen but should be handled in other places to prevent this double for
			for(let tickStep in this.newTicket.newdata){
				for(let partStep in this.newTicket.newdata[tickStep].parts){
					if(this.newTicket.newdata[tickStep].parts[partStep].components){
						this.deletePart(partStep,this.newTicket.newdata[tickStep].newparts);
					}
					if(!this.newTicket.newdata[tickStep].parts[partStep].itemid){
						this.deletePart(partStep, this.newTicket.newdata[tickStep].newparts);
					}
				}
				if(this.newTicket.newdata[tickStep].billTo.contractid > 0 ){
					let flag = false;
					for(let i in this.contractsArray){
						if(this.contractsArray[i].contractid === this.newTicket.newdata[tickStep].billTo.contractid){
							flag = true
							break;
						}
					}
					if(flag === false){
						this.newTicket.newdata[tickStep].billTo = this.newTicket.newcustomer
						this.newTicket.newdata[tickStep].contractTask = null
					}
				}
			}

			//Set to deal with undefined issues:
			this.newTicket.description = null;
			//Post ticket to DB
			await axios.post(process.env.VUE_APP_API_TARGET + "/tickets/"+ this.$globals.companyId,{
				//Description is creation date, needs fixed to accurately represent.
				description: this.creationDate,
				contact: JSON.stringify(this.newTicket.newContact),
				technician: JSON.stringify(this.newTicket.newtech),
				customer: this.newTicket.newcustomer,
				vehicle: this.newTicket.newvehicle?? null,
				asset: JSON.stringify(tempAssets),
				start_date: this.newTicket.newstart,
				end_date: this.newTicket.newend,
				status: this.newTicket.newstatus,
				data: JSON.stringify(this.newTicket.newdata),
				author: this.$globals.user.name,
				loc_address: this.locAddress,
				loc_name: this.locName},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
                    this.pendingReq = false
                    this.newTicket.newid = response.data.queryResult.insertId;
                    this.newTicket.localid= response.data.localid;
                    this.$globals.socket.emit("newData", `service${this.$globals.companyId}`)
                    this.$globals.socket.emit("notifyReleventUsers", this.$globals.companyId, this.newTicket.newtech, "Work Order Created", "Work Order Created for " + this.newTicket.newcustomer + " by " + this.$globals.user.name);
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				this.pendingReq = false;
			})
            this.pendingReq = false

			//Handles converting from Service Estimate to Work Order.
			if(this.selectedTicket?.estimateID !== undefined){
				await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/serviceEstimate/status/"+ this.selectedTicket?.estimateID + '/' + this.$globals.companyId,{
					status: 1,
					statusReason: "Converted into Work Order #"+this.newTicket.localid,
				},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

			//Data process loops
			for(let largeStep in this.newTicket.newdata){
				//IF partsRequested make request and notify.
				if(this.newTicket.newdata[largeStep].partsRequested !== ""){
					await axios.post(process.env.VUE_APP_API_TARGET + "/partsreq/" + this.$globals.companyId,{
						ticketid:this.newTicket.localid,
						task: this.newTicket.newdata[largeStep].task,
						request: this.newTicket.newdata[largeStep].partsRequested,
						status: 'Open',
						date: new Date().toISOString().split("T")[0]
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
						this.$globals.socket.emit("notifyRelevantRoles",this.$globals.companyId, ['rol_PS9rC8bKyDbCPQFo', 'rol_2fXaufuOPHYhIRtl'], "Parts Request", `Parts requested for ticket #${this.newTicket.localid} on ${this.newTicket.newdata[largeStep].task}`)
						//rol_2fXaufuOPHYhIRtl = Parts Manager, rol_PS9rC8bKyDbCPQFo = Parts
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
			}

            this.$globals.shield = false;
			this.action = '';

			this.populateScheduler();

			//Clear newTicket
			this.newTicket= {
				newtech: [],
				newvehicle: null,
				newasset: [],
				newContact: [],
				newcustomer: null,
				newstart: null,
				newend: null,
				newstatus: null,
				newdata:[],
				newid: "",
			};
			this.locAddress ='';
			this.selectedContact=[];
			this.newStep.authorization=null;
			this.newStep.billTo='';
			removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
		},//END createTicket()

		async createServiceEstimate(){
			this.creationDate = new Date().toISOString().split('T')[0];
			if(this.newTicket.newcustomer == null) {
				alert("Tickets must have a customer.");
				return;
			}
			if(this.newTicket.newContact == null || this.newTicket.newContact == ''){
				alert("Tickets require a contact")
				return;
			}
			if(this.newTicket.newasset == null || this.newTicket.newasset.length === 0 || this.newTicket.newasset.length === undefined) {
				alert("Tickets must have an asset associated with the customer.");
				return;
			}
			if(this.newTicket.newdata == null || this.newTicket.newdata == ''){
				alert("Tickets must have tasks");
				return;
			}
			var tempAssetArray = [];
			let passVehicle = null;
			for(let asset in this.newTicket.newasset){
				tempAssetArray.push(this.newTicket.newasset[asset].id);
				if(this.newTicket.newasset[asset].asset_type == 'Vehicle'){
					passVehicle = this.newTicket.newasset[asset].id
				}
			}
			this.newTicket.newasset = [];
			for(let item in tempAssetArray){
				this.newTicket.newasset.push(tempAssetArray[item]);
			}

			//post
			await axios.post(process.env.VUE_APP_API_TARGET + "/tickets/service/estimate/"+ this.$globals.companyId,{
				creationDate: this.creationDate,
				contact: JSON.stringify(this.newTicket.newContact),
				customer: this.newTicket.newcustomer.name,
				asset: JSON.stringify(this.newTicket.newasset),
				vehicle: passVehicle,
				data: JSON.stringify(this.newTicket.newdata),
				author: this.$globals.user.name,
				status:0,
				statusReason: "Open",
			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			//get active service Estimates
			this.changeState('')
		},

		deletePart(partIndex,row){
			if(row[partIndex]?.itemid !== undefined && row[partIndex]?.location !== undefined && row[partIndex]?.bin !== undefined){
				row[partIndex].fulfillment.forEach((item, i) => {
					if(item.usedCount.length > 0){
						if(row[partIndex].warrantyFlag){
							axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(item.itemid)}/${encodeURIComponent(item.location)}/${encodeURIComponent(item.bin)}/${this.$globals.companyId}`,
							{
								qty: 0-item.qty,
								addBack: JSON.stringify(item.usedCount),
								vendor: item.vendor
							},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
						}
						else{
							axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(item.itemid)}/${encodeURIComponent(item.location)}/${encodeURIComponent(item.bin)}/${this.$globals.companyId}`,
							{
								qty: 0-item.qty,
								addBack: JSON.stringify(item.usedCount),
								vendor: item.vendor
							},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
							.catch((response)=>{
								this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
							})
						}
					}


				});

			}
			row.splice(partIndex,1)
		},

		deleteStep(row){
			//TODO will require admin privileges?

			//Removes attached parts to put back into inventory
			if(!this.action.includes('serviceEstimate')){
				row.parts.forEach((item, i) => {
					this.deletePart(i,row.parts)
				});
			}

			//Removes task from the list
            this.newTicket.newdata = this.newTicket.newdata.filter((dataRow, index) => {
                return dataRow.task !== row.task
            })

			//Re names all the tasks to be in order
			this.newTicket.newdata.forEach((step, i) => {
				this.newTicket.newdata[i].task = `Task ${i+1}`
			});

		},

		async editTicket(selectedTicket){
			this.selectedTicket = selectedTicket;
			// //this.getContacts needs to get passed the customer obj info
			var cust;
			var custObj = await this.getCustomerByName(encodeURIComponent(this.selectedTicket.customer))
			this.getContacts(custObj);
			this.getAssetsVehicles(custObj);
			this.selectedTicket.asset = typeof this.selectedTicket.asset !== 'object' ? JSON.parse(this.selectedTicket.asset) : this.selectedTicket.asset;
			this.newTicket.newasset = []

			if(this.selectedVehicle !== null && this.selectedVehicle !== undefined){
				this.newTicket.newasset = [{...this.selectedVehicle, id: this.selectedVehicle.vehicleid}]
				this.multiAsset = false;
			}
			else if(this.selectedAssets.length >0){
				this.newTicket.newasset = [...this.selectedAssets]
				this.multiAsset = true;
			}
			else{
				for (let i in this.selectedTicket.asset) {
					this.multiAsset = true
					this.newTicket.newasset.push({
						id: this.selectedTicket.asset[i]
					})
				}
			}

			this.newTicket.newcustomer = custObj;
			this.selectedContact = {...this.selectedTicket.contact};
			this.newTicket.author = this.selectedTicket.author;
			this.newTicket.newContact = this.selectedTicket.contact;
			await axios.get(process.env.VUE_APP_API_TARGET + "/vehicles/"+encodeURIComponent(this.selectedTicket.vehicle) + "/cust/" + this.selectedTicket.contact.customerid + "/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response => {
				this.newTicket.newvehicle = (response.data.queryResult[0])
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.newTicket.newtech = (typeof this.selectedTicket.technician === 'object' ? this.selectedTicket.technician : JSON.parse(this.selectedTicket.technician))
			this.newTicket.newstart = this.selectedTicket.start_date;
			this.newTicket.newend = this.selectedTicket.end_date;

			this.newTicket.newstatus = this.selectedTicket.status;
			this.newTicket.newdata = _.cloneDeep(this.selectedTicket.data); //NOTE: does break refrence

			this.newStep.billTo = custObj;
			this.isBusy = false;
			this.locAddress = this.selectedTicket.loc_address || this.locAddress;

            this.pendingReq = false
			addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
		},//END editTicket()

		async getContractInfo(contractid){
			let flag = true;
			for(let i in this.contractsArray){
				if(this.contractsArray[i].contractid === contractid){
					//don't add.
					flag = false
					break;
				}
			}
			if(flag){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/target/${contractid}/${this.$globals.companyId}`,{
					headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${this.$globals.user.auth0Token}`}
				}).then((response)=>{
					response.data.queryResult[0].tasks = (typeof response.data.queryResult[0].tasks !== 'object' && response.data.queryResult[0].task !== null) ? JSON.parse(response.data.queryResult[0].tasks) : response.data.queryResult[0].tasks
					response.data.queryResult[0].linkedAssets = (typeof response.data.queryResult[0].linkedAssets !== 'object' && response.data.queryResult[0].linkedAssets !== null) ? JSON.parse(response.data.queryResult[0].linkedAssets) : response.data.queryResult[0].linkedAssets
					response.data.queryResult[0].name = `${response.data.queryResult[0].contractid} - ${response.data.queryResult[0].contractName}`
					this.contractsArray.push(response.data.queryResult[0])
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

		},

		generateBillToOptions(){
			this.billOptions = []
			this.billOptions.push({name: 'Contracts', items: this.contractsArray})
			this.billOptions.push({name: 'Customers', items: this.allCustomers})
		},

		async getContacts(option){
			await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/contacts/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
				this.allContacts = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getHouses(option) {
			await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/houses/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
				this.allHouses = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getVehicleInfo(vehicleid, customerid){
			if(vehicleid == null || customerid === null){
				return;
			}
			await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${encodeURIComponent(vehicleid)}/cust/${customerid}/${this.$globals.companyId}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${this.$globals.user.auth0Token}`
				}
			}).then(async (response) => {
				this.selectedVehicle = response.data.queryResult[0] || null
				if(this.selectedVehicle?.contractid > 0 ){
					await this.getContractInfo(this.selectedVehicle.contractid)
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},//END getVehicleInfo

		async getAssetInfo(assets){
			let result = []
			for(let x in assets){
				if(assets[x] !== 'Service'){
					await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/assets/byid/${assets[x]}/${this.$globals.companyId}`, {
						headers: {
							'Content-Type': 'application/json',
							'Authorization': `Bearer ${this.$globals.user.auth0Token}`
						}
					}).then(async (response) => {
						result.push(response.data.queryResult[0])
						if(response.data.queryResult[0].contractid > 0){
							await this.getContractInfo(response.data.queryResult[0].contractid)
						}
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'View Ticket', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}

			}
			this.selectedAssets = result
		},//END getAssetInfo

		async filteredTicketsProvider(){
			let data = []
			let search_text = this.ticketFilterSearch === "" ? null : this.ticketFilterSearch

			let filter_text = this.ticketFilterType === "" ? 'today' : this.ticketFilterType

			if(this.ticketFilterType !== 'today'){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/filtered/${filter_text}/${this.$globals.companyId}/${this.currentPageFiltered}/search_text/${encodeURIComponent(search_text)}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
					this.allTicketsFiltered[this.currentPageFiltered] = response.data.queryResult.results;
					data = response.data.queryResult.results;
					this.maxPagesFiltered = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			else{
				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/date/${new Date().toISOString().split("T")[0]}/${this.$globals.companyId}/${this.currentPageFiltered}/search_text/${encodeURIComponent(search_text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
					this.allTicketsFiltered[this.currentPageFiltered] = response.data.queryResult.results;
					data = response.data.queryResult.results;
					this.maxPagesFiltered = response.data.queryResult.maxPages
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			return data
		},

        async handleNewAsset(){
            await this.getAssetsVehicles(this.selectedCustomer)
            this.action = this.lastState
			this.$forceUpdate()
        },

		ticketFilterSwitch(string){
            this.currentPageFiltered = 1;
            this.ticketFilterType = string
			this.mainTableView = 'Work Orders Filtered'
            this.queryFilteredTickets()
		},

		getTechByResourceId(id){
			for(let tech in this.allTechs){
				if(this.allTechs[tech].id == id){
					return(tech);
				}
			}
		},

		async getAssetsVehicles(option){
			await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/vehicles/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
				this.allVehicles = response.data.queryResult;
                await axios.get(process.env.VUE_APP_API_TARGET + '/customers/assets/by/cust/'+option.customerid+'/'+this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(async (response) =>{
                    this.allAssets = response.data.queryResult;
                    await this.createAssetGroups(this.allAssets, this.allVehicles);
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getVehicles(option){
			await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/vehicles/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
				this.allVehicles = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},//END getVehicles()

		locatePart(data, index, partArray){
			partArray[index].fulfillment = [];
			partArray[index].fulfilledQty = 0
			partArray[index].vendorMarkup = 0.1

			data.forEach((item, i) => {
				partArray[index].fulfillment.push(item)
				partArray[index].fulfilledQty = parseInt(partArray[index].fulfilledQty) + parseInt(item.qty)
				axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(item.vendor)}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				}).then((response)=>{
					if(response.data.queryResult[0]?.markup > partArray[index].vendorMarkup){
						partArray[index].vendorMarkup = response.data.queryResult[0].markup;
					}
				})
			});
			partArray[index].locationFlag = false
			this.$forceUpdate()
		},

		onEventDragStart: function(s, records) {
				this.eventData = this.$refs.scheduler.schedulerInstance.project.eventStore._idMap;
				bryntum.query('scheduler').eventStore.on('change', (event)=>{this.dragChange = (event)})    //this is the listener
		},

		onEventDrop: function() {
			//window.document.ondrop = this.onEventDrop;
			this.updateTicketDatabase();
		},    //https://www.bryntum.se/forum/viewtopic.php?f=16&t=2733

		onEventResizeStart: function(s, records) {
			this.eventData = this.$refs.scheduler.schedulerInstance.project.eventStore._idMap;
			bryntum.query('scheduler').eventStore.on('change', (event)=>{this.sizeChange = (event)});
		},

		onEventResizeEnd: function() {
			this.updateTicketTimes();
		},

		async populateScheduler() {
			this.show = true;
            // this.isBusy = true;
            return new Promise(async (resolve,reject) => {
                await axios.get(process.env.VUE_APP_API_TARGET + "/tickets/techs/all/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                .then(async (response) => {
                    let tempTechs = response.data.queryResult;
                    let tempResources = [];
                    var latestEvent, latestClockEvent;
                    for(var tech in tempTechs) {
                        if(tempTechs[tech].lastEvent != null){
                            latestEvent = JSON.parse(tempTechs[tech].lastEvent);
                            latestClockEvent = new Date(latestEvent.time);
                        }
                        else{
                            latestEvent = null;
                            latestClockEvent = null;
                        }
                            let newResource = {
                                id: tempTechs[tech].id,
                                name: tempTechs[tech].name,
                                imageUrl: tempTechs[tech].picture,
                                latestEvent: latestEvent,
                                latestClockEvent: latestClockEvent,
                                eventColor: tempTechs[tech].color,
                                jobStatus : tempTechs[tech].jobStatus,
                                workZone: tempTechs[tech].work_zone ?? 'Unassigned'
                            }
                            tempResources.push(newResource);
                        }
                        tempResources.push({
                            id: tempTechs[tempTechs.length-1].id+1,
                            name: 'Unassigned',
                            workZone: 'Unassigned'
                        })

                        tempResources = tempResources.sort((a,b) => {
                            if(a.name < b.name){
                                return -1
                            } else {
                                return 0
                            }
                            return 0
                        })
                        this.$refs.scheduler.schedulerInstance.resourceStore.add( tempResources );
                        await axios.get(process.env.VUE_APP_API_TARGET + "/tickets/filtered/Scheduled/"+ this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                        .then((response) => {

                            let tickets = response.data.queryResult;
                            var index=0;
                            for(var ticket in tickets){
                                tickets[ticket].technician = JSON.parse(tickets[ticket].technician);
                                if(tickets[ticket].technician.length > 0 && tickets[ticket].technician[0] !== []){
                                    for(var tech in tickets[ticket].technician){
                                        this.$refs.scheduler.schedulerInstance.eventStore.add({
                                            id: index++,
                                            name: `${tickets[ticket].customer} | ${tickets[ticket].vehicle || JSON.parse(tickets[ticket].asset).map(asset => asset).join(", ")} | WO-${tickets[ticket].localid != null ? tickets[ticket].localid : tickets[ticket].ticketid}`,
                                            startDate: new Date(tickets[ticket].start_date?.slice(0,-2)),
                                            endDate: new Date(tickets[ticket].end_date?.slice(0,-2)),
                                            resourceId: tickets[ticket].technician[tech].id
                                        })
                                    }
                                }
                                else {
                                    this.$refs.scheduler.schedulerInstance.eventStore.add({
                                            id: index++,
                                            name: `${tickets[ticket].customer} | ${tickets[ticket].vehicle || tickets[ticket].asset} | WO-${tickets[ticket].localid != null ? tickets[ticket].localid : tickets[ticket].ticketid}`,
                                            startDate: new Date(tickets[ticket].start_date?.slice(0,-2)),
                                            endDate: new Date(tickets[ticket].end_date?.slice(0,-2)),
                                            resourceId: tempResources[tempResources.length-1].id
                                    })
                                }
                            }
                        })
                        .catch((response)=>{
                            reject()
                            console.log("REJECTER 1")
                            // this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                        })
                        // TODO: REVISIT THIS. CALCULATIONS ARE OFF AND DUPLICATE LOGIC
                        this.$refs.scheduler.schedulerInstance.resourceStore.forEach((resource) => {
                            if(resource.events.length < 3){
                                resource.rowHeight = 100
                            }
                            if(resource.events.length < 7 && resource.events.length > 3) {
                                resource.rowHeight = 25 + 10 * resource.events.length
                            }
                            if(resource.events.length > 8){
                                resource.rowHeight = 15 + 5 * resource.events.length
                            }
                        })
                    })
                    .catch((response)=>{
                        reject()
                        console.log("REJECTER 2")
                        // this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                    })
                this.listen();
                // this.isBusy = false;
                resolve()
            })
		}, //end populateScheduler

		listen(){
			this.$refs.scheduler.schedulerInstance.addListener('cellClick', (event) => {
				if(event.column.data.field === "latestClockEvent"){
					this.techMap(event);
				}
			});
			this.$refs.scheduler.schedulerInstance.addListener('scheduleDblClick', (event) => {
				for(let tech in this.allTechs){
					if(this.allTechs[tech].id == event.resourceRecord.data.id){
						this.newTicket.newtech = this.allTechs[tech];
						break;
					}
				}
				var startDate = event.date.toLocaleString();
				var startD = startDate.split(',')[0];
				var startT = startDate.split(' ')[1];
				var ampm = startDate.split(' ')[2];
				if(ampm == 'PM' && parseInt(startT.split(':')[0]) < 12){
					var hour = parseInt(startT.split(':')[0]) + 12;
					startT = hour + ':' + startT.split(':')[1] + ':' + startT.split(':')[2];
				}
				var sDate = startD.split('/')[2] + '-' + startD.split('/')[0] + '-' + startD.split('/')[1]
				this.newTicket.newstart = sDate + ' ' + startT;
				this.changeState("createTicket");
			});

			this.$refs.scheduler.schedulerInstance.addListener('oninput', function(e){
				console.log("we made it");
			});

			this.$refs.scheduler.schedulerInstance.addListener('dragcreateend', (event) => {
				for(let tech in this.allTechs){
					if(this.allTechs[tech].id == event.resourceRecord.data.id){
						this.newTicket.newtech = this.allTechs[tech];
						break;
					}
				}
				this.newTicket.newstart = new Date(event.eventRecord.startDate)
				this.newTicket.newend = new Date(event.eventRecord.endDate)
				this.changeState("createTicket")
			})

			this.$refs.scheduler.schedulerInstance.addListener('eventDblClick',async (event) => {
				var eventTicket = event.eventRecord.originalData.name.split(' | ')[2];
				eventTicket = /*303*/eventTicket.split('-')[1];
                await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/ticket/${eventTicket}/${this.$globals.companyId}`, {
                    headers: {
                        'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                    }
                }).then((response) => {
                    this.selectedTicket = response.data.queryResult[0]
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.viewTicket(this.selectedTicket);
			})
		},

		async pickCustomer(option){
            this.newTicket.newContact =null;
			this.newTicket.newvehicle=null;
			this.newTicket.newasset=[];
			this.selectedContact=[];
			this.newStep.authorization=null;
			this.newStep.billTo='';
			this.locAddress ='';
			this.getVehicles(option);
			this.getAssetsVehicles(option);
			this.getContacts(option);
			this.getHouses(option)
            this.selectedCustomer = option
			this.contractsArray = []
		},

		pickContact(option){
			this.selectedContact = option;
		},

		async pickAsset(option){
			if(option === null || option.length === 0){
				//the list is empty with no selections
				this.newTicket.newasset = []
				return;
			}
			if(option.length == undefined){
				option = [option]
			}
            if(option[option.length-1].asset_type !== 'NonAsset'){
                await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/vehiclecheck/status/${encodeURIComponent(option[option.length-1].id)}/${encodeURIComponent(this.newTicket.newcustomer.name)}/${this.$globals.companyId}`,
                    {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
                })
                .then(response=>{
                    if(response.data.queryResult.length > 0){
                        let relatedList = []
                        response.data.queryResult.forEach((item, i) => {
                            relatedList.push(item.localid)
                        });
                        alert(`This asset is assigned to another Work Order(s): ${relatedList}`)
                    }
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            }

            if(option[option.length-1]?.asset_type == 'Vehicle' || option[option.length-1]?.asset_type === undefined){
                this.newTicket.newasset = []
				this.newTicket.newasset.push(option[option.length-1])
                this.multiAsset = false;
            }
			else if(option[0]?.asset_type == 'NonAsset'){
				//Comented out due to problem with creating a SE, ".filter is not a funciton".
                //this.newTicket.newasset = this.newTicket.newasset.filter( asset => asset.asset_type === 'NonAsset');
                this.multiAsset = false;
            }
            else {
                this.multiAsset = true;
			}

			this.contractsArray = []
			for(let c in this.newTicket.newasset){
				if(this.newTicket.newasset[c]?.contractid > 0){
					await this.getContractInfo(this.newTicket.newasset[c].contractid)
				}
			}
			this.generateBillToOptions()

			this.$forceUpdate()

			//There appears to be no reason to get houses. Houses are related to Customer not Vehicle
			//this.getHouses(option)
			for(let house in this.allHouses){
				if(option.location == this.allHouses[house].name){
					this.locAddress = this.allHouses[house].address;
					this.locName = this.allHouses[house].name;
					break;
				}
			}
		},

		async pickVehicle(option){
			this.getHouses(option.customer);
			var house;
			for(house in this.allHouses){
				if(option.houseid == this.allHouses[house].houseid){
					this.locAddress = this.allHouses[house].address;
					this.locName = this.allHouses[house].name;
					break;
				}
			}
		},

		refreshMap(){
			var d = new Date;
			if(d.getMinutes() % 5 == 0){
				this.createMap();
			}
		},

		setToDayView(){
			schedulerConfig.viewPreset.headers[0].unit = 'day'
			schedulerConfig.viewPreset.headers[0].dateFormat = 'ddd MM/DD'
			schedulerConfig.viewPreset.headers[1].unit = 'hour'
			schedulerConfig.viewPreset.headers[1].dateFormat = 'h A'
			this.schedulerKey = 'day'
			this.populateScheduler()
		},

		setToWeekView(){
			schedulerConfig.viewPreset.headers[0].unit = 'week'
			schedulerConfig.viewPreset.headers[0].dateFormat = 'DD MMM YYYY'
			schedulerConfig.viewPreset.headers[1].unit = 'day'
			schedulerConfig.viewPreset.headers[1].dateFormat = 'd1'
			this.schedulerKey = 'week'
			this.populateScheduler()
		},

		setToMonthView(){
			schedulerConfig.viewPreset.headers[0].unit = 'month'
			schedulerConfig.viewPreset.headers[0].dateFormat = 'YYYY MMMM DD'
			schedulerConfig.viewPreset.headers[1].unit = 'day'
			schedulerConfig.viewPreset.headers[1].dateFormat = 'DD MMM'
			this.schedulerKey = 'month'
			this.populateScheduler()
		},

		async updateLocation(){
			if(this.locName == ''){
				if(this.locAddress == ''){
					alert("You can't update the location without entering a new location.");
				}//END inner if
				else{     //An address was typed in
					this.locName = "New Location";
				}
			}//END outer if

			//if a name was selected, get its address
			else if(this.locName != ''){
				this.locName = this.locName.name;
				var house;
				for(house in this.allCustHouses){
					if(this.locName == this.allCustHouses[house].name){
						this.locAddress = this.allCustHouses[house].address;
					}//END if
				}//END for
			}//END else
			await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+this.selectedTicket.ticketid+ "/" + this.$globals.companyId, {
				technician: typeof this.selectedTicket.technician === 'object' ? JSON.stringify(this.selectedTicket.technician) : this.selectedTicket.technician,
                contact: typeof this.selectedTicket.contact === 'object' ? JSON.stringify(this.selectedTicket.contact) : this.selectedTicket.contact,
                start_date: this.selectedTicket.start_date,
                end_date: this.selectedTicket.end_date,
                vehicle: this.selectedTicket.vehicle ?? null,
                asset: typeof this.selectedTicket.asset === 'object' ? JSON.stringify(this.selectedTicket.asset) : this.selectedTicket.asset,
                status: this.selectedTicket.status,
                data: typeof this.selectedTicket.data === 'object' ? JSON.stringify(this.selectedTicket.data) : this.selectedTicket.data,
				loc_name: this.locName,
				loc_address: this.locAddress
			},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.$globals.socket.emit("newData", "service", "work order")
			for(let tech in this.selectedTicket.technician){
				this.$globals.socket.emit("notifyTechnicianLocationChanged", this.selectedTicket.technician[tech].auth0, this.selectedTicket.ticketid)
			}
		},//END updateLocation()

		async updateTicketDatabase(){
			//TODO needs a Revisit
			var updatedTicket;
			var oldTech;
			var newTech;
			var oldStart;
			var newStart;
			var oldEnd;
			var newEnd;
			var workOrdNum;
			var eventTech;

			//tech changed
			if(typeof this.dragChange?.changes.resourceId !== 'undefined'){
				oldTech = this.dragChange.changes.resourceId.oldValue;
				newTech = this.dragChange.changes.resourceId.value;
			}

			//startDate changed
			if(typeof this.dragChange?.changes.startDate !== 'undefined'){
				oldStart = this.dragChange.changes.startDate.oldValue;
				newStart = this.dragChange.changes.startDate.value;
			}

			//endDate changed
			if(typeof this.dragChange?.changes.endDate !== 'undefined'){
				oldEnd = this.dragChange.changes.endDate.oldValue;
				newEnd = this.dragChange.changes.endDate.value;
			}

			//get event tech (new)
			for(let tech in this.allTechs){
				if(this.allTechs[tech].id == newTech){
					eventTech = {
						auth0: this.allTechs[tech].auth0,
						color: this.allTechs[tech].color,
						companyid: this.allTechs[tech].companyid,
						id: this.allTechs[tech].id,
						jobStatus: this.allTechs[tech].jobStatus,
						last_in: this.allTechs[tech].last_in,
						last_out: this.allTechs[tech].last_out,
						location: this.allTechs[tech].location,
						name: this.allTechs[tech].name,
						picture: this.allTechs[tech].picture,
						weekly: this.allTechs[tech].weekly
					}
					break;
				}
			}
			//Get W/O # from data
			workOrdNum = this.dragChange?.record.data.name.split('|')[2];
			workOrdNum = workOrdNum.split('-')[1];

			//get original ticket
			for(let step in this.allTickets){
				if(parseInt(this.allTickets[step].localid) == parseInt(workOrdNum)){
					updatedTicket = this.allTickets[step];
				}
			}
			//set correct tech in W/O
			var tempTechs = JSON.parse(updatedTicket.technician)
			for(let tech in tempTechs){
				if(tempTechs[tech].id == oldTech){
					tempTechs[tech] = eventTech;
					updatedTicket.technician = JSON.stringify(tempTechs);
				}
			}
			//set correct start_date
			updatedTicket.start_date = (newStart).toISOString();
			//set correct end_date
			updatedTicket.end_date = (newEnd).toISOString();

			await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+updatedTicket.ticketid + "/" + this.$globals.companyId,{
				technician: updatedTicket.technician,
				contact: updatedTicket.contact,
				customer: updatedTicket.customer,
				vehicle: updatedTicket.vehicle.vehicleid,
				asset: updatedTicket.asset,
				start_date: updatedTicket.start_date,
				end_date: updatedTicket.end_date,
				status: updatedTicket.status,
				data: updatedTicket.data
			},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.$globals.socket.emit("newData", "service", "work order")
			this.populateScheduler();
		},

		async updateTicketTimes(){
			for(let index in this.eventData){
				if(this.eventData[index].record.data.name == event.originalTarget.textContent){
					var resourceId = this.eventData[index].record.data.resourceId;
					//Get W/O number
					let workOrdNum = this.eventData[index].record.data.name.split('|')[2];
					workOrdNum = workOrdNum.split('-')[1];

					//get original ticket
					for(let step in this.allTickets){
						if(parseInt(this.allTickets[step].localid) == parseInt(workOrdNum)){
							updatedTicket = this.allTickets[step];
						}
					}

					//get event tech
					for(let tech in this.allTechs){
						if(this.allTechs[tech].id == resourceId){
							let eventTech = {
								auth0: this.allTechs[tech].auth0,
								color: this.allTechs[tech].color,
								companyid: this.allTechs[tech].companyid,
								id: this.allTechs[tech].id,
								jobStatus: this.allTechs[tech].jobStatus,
								last_in: this.allTechs[tech].last_in,
								last_out: this.allTechs[tech].last_out,
								location: this.allTechs[tech].location,
								name: this.allTechs[tech].name,
								picture: this.allTechs[tech].picture,
								weekly: this.allTechs[tech].weekly
							}
							break;
						}
					}

					//Test to see if start date stayed same
					if(this.eventData[index].record.data.startDate == this.eventData[index].record.originalData.startDate){

						//Test to see if end date changed
						if(this.eventData[index].record.data.endDate != this.eventData[index].record.originalData.endDate){

							//set end date to new value and save in db
							var endDate = new Date(this.eventData[index].record.data.endDate).toISOString();
							var startDate = new Date(this.eventData[index].record.data.startDate).toISOString();
						}
					}
					//This is for the start date changing
					else{
						//set start date to new value and save in db
						var startDate = new Date(this.eventData[index].record.data.startDate).toISOString();
						var endDate = new Date(this.eventData[index].record.data.endDate).toISOString();
					} //END else

					await axios.put(process.env.VUE_APP_API_TARGET + "/tickets/"+updatedTicket.ticketid + "/" + this.$globals.companyId,{
						technician: eventTech,
						contact: updatedTicket.contact,
						customer: updatedTicket.customer,
						vehicle: updatedTicket.vehicle.vehicleid,
						asset: updatedTicket.asset,
						start_date: startDate,
						end_date: endDate,
						status: updatedTicket.status,
						data: updatedTicket.data
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Dispatching', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
					this.$globals.socket.emit("newData", "service", "work order")
					break;
				} //END outermost if
			} //END for

			this.eventData = [];
			this.populateScheduler();
		},

		viewTicket(row){
            this.show = false;
			this.selectedTicket = row;
			this.selectedTicket.contact = typeof this.selectedTicket.contact === 'object' ? this.selectedTicket.contact : JSON.parse(this.selectedTicket.contact)
			this.selectedTicket.data = typeof this.selectedTicket.data === 'object' ? this.selectedTicket.data : JSON.parse(this.selectedTicket.data);
            this.selectedTicket.asset = typeof this.selectedTicket.asset === 'object' ? this.selectedTicket.asset : JSON.parse(this.selectedTicket.asset);

			this.action = "viewTicket";
			for(var step in this.selectedTicket.data){
				if(this.selectedTicket.data[step].lock == undefined){
					this.selectedTicket.data[step].lock = []
				}

				var subTotal =parseInt(0);
                for(var log in this.selectedTicket.data[step].time_in){
					var stepTimeIn =this.selectedTicket.data[step].time_in[log].time;
					var stepTimeOut =new Date();
                    var dateHolder = stepTimeOut
					if((typeof(this.selectedTicket.data[step].time_out[log]) !== 'undefined')){
						for(var other in this.selectedTicket.data[step].time_out){
							if ((this.selectedTicket.data[step].time_out[other]?.tech === this.selectedTicket.data[step].time_in[log]?.tech) && (new Date(this.selectedTicket.data[step].time_out[other]?.time) > new Date(this.selectedTicket.data[step].time_in[log]?.time))){
								stepTimeOut = this.selectedTicket.data[step].time_out[other].time;
								break;
							}
						}
					}
                    var outDate = new Date(stepTimeOut);
                    var inDate = new Date(stepTimeIn);
                    var outMilli = Date.parse(outDate);
                    var inMilli = Date.parse(inDate);
                    var tHours = (outMilli - inMilli) / 60 /60 /1000;
                    if(stepTimeIn && (Math.abs(outMilli - Date.now()) > 10000)){
                        subTotal += Number(Number.parseFloat(tHours).toFixed(1));
                    }
				}

				this.totalsArray[step] = subTotal;
			}
            this.$forceUpdate();


            this.$nextTick(() => {
                if(this.lastSelectedIndex !== -1){
                    let taskEl = this.$refs['t'+this.lastSelectedIndex][0]
                    taskEl.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'nearest'
                    })
                }
                this.lastSelectedIndex = -1;
            })
		},//END viewTicket()

		async viewServiceEstimate(row){
			this.selectedTicket = {...row};
			this.action="viewServiceEstimate";
		},


	},//END methods section

}

</script>

<style scoped>

/deep/ th>:nth-child(even){
	background-color: grey
}

.container2{
	margin-left: 1%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
}

.actionHolder {
	margin: 2px;
	margin-left: 1%;
}

.tickets {
	margin-top:1%;
	margin-left: -1.5vw;
	width: 90vw;
	grid-area: tickets;
}

.recurring {
	grid-area: recurring;
	margin-left: 2%;
	margin-top: 1%;
	max-height: 30vh;
	overflow-y: auto;
	min-width: 390px;
}

.parts-fulfilled {
	grid-area: parts-fulfilled;
	margin-top: 1%;
	margin-left: 1%;
	max-height: 30vh;
	overflow-y: auto;
	min-width: 390px;
}

.parts-awaiting {
	grid-area: parts-awaiting;
	margin-left: 2%;
	margin-top: 1%;
	max-height: 30vh;
	overflow-y: auto;
	min-width: 390px;
}

/* .searchBar input[type="text"] {
	padding: 5px;
	font-size: 17px;
	border: 1px solid grey;
	background: #f1f1f1;
	text-align: center;
	width: 80%;
	margin-top: 1%;
} */

.col select {
	height: 5vh;
	line-height: 3vh;
	padding: 1vh;
	font-size: 17px;
	border: 1px solid grey;
	background: #f1f1f1;
	text-align: center;
}
/*
td input[type="text"], input[type="float"], input[type="number"] {
	padding: 5px;
	font-size: 17px;
	border: 1px solid grey;
	background: #f1f1f1;
	text-align: center;
} */

.ticketTable {
	margin-top:1%;
	overflow-x: hidden;
    overflow-y: auto;
    min-height: 250px;
    max-height: 800px;
}

.customPagination {
	/* position: sticky;
	position:-webkit-sticky; */
	margin-top :1%;
	bottom:0;
}

.recurring .customPagination{
	margin-left: 45%;
	position: sticky;
	position:-webkit-sticky;
	bottom:0;
}

.parts-awaiting .customPagination, .parts-fulfilled .customPagination {
	margin-left: 30%;
	position: sticky;
	position:-webkit-sticky;
	bottom:0;
}

/deep/ .customPagination .page-item.active .page-link {
	background-color: #dc3545 !important;
	color: white !important;
	border-color: #dc3545 !important;
}

/deep/ .customPagination .page-item .page-link {
	color: #dc3545 !important;
}

/deep/ .dropdown-item:hover{
	background-color: #dc3545;
	color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.map {
	margin:1%;
	border: #dc3545;
	background:grey;
	height: 95%;
	width: 95%;
}

.styled-table {
	border-collapse: collapse;
	font-size: 0.9em;
	font-family: sans-serif;
	font-size:10pt;
	min-width: 375px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	text-align: left;
	width:100%;
}

.styled-table thead {
	background-color: #dc3545;
	color: #ffffff;
	text-align: center;
}

.styled-table th,
	.styled-table td {
		padding: 12px 15px;
}

.styled-table tbody tr {
	border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
	border-bottom: 2px solid #dc3545;
}
.modal-backdrop {
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    display: block;
    justify-content: center;
	opacity: 1;
	width: 100;
	height: 100;
  }

  .modal {
	background: #FFFFFF;
	overflow-y: auto;
	box-shadow: 2px 2px 20px 1px;
	opacity: 1;
	background-color: white;
	width: 40vw;
	height: 30vw;
	left: 30vw;
	display: block;
	position: fixed;
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }

  .modal-body {
	position: relative;
	padding: 20px 10px;
  }

table.parts {
	font-size: 8pt;
	max-width: 50px;
}

</style>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css">
.dark {
	background-color: #dc4535 !important;
	border-color: #dc4535 !important
}
.stand {
	background-color: #dc3545 !important;
	border-color: #dc3545 !important
}
</style>

<template>
    <div class="card card-danger">
        <div class="card-header">
            <div class="card-title">
                <h3><b>New Contact for {{source.name || source.company}}</b></h3>
            </div>
            <div class="card-tools">
                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="pushContact()">Submit</b-button>
                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="$emit('return')">Return</b-button>
            </div>
        </div>
        <div class="card-body">
            <label for="c_title" class="w-75 text-left mt-2" style="margin-left: 13.5%">Title</label>
            <input id="c_title" class="form-control w-75" type="text" v-model="newContact.title" style="margin-left: 12.5%" placeholder="Title" />

            <label for="c_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
            <input id="c_name" class="form-control w-75" type="text" v-model="newContact.name" style="margin-left: 12.5%" placeholder="Name" required/>

            <label for="c_roles" class="w-75 text-left mt-2" style="margin-left: 13.5%">Roles</label>
            <multiselect
                id="c_roles"
                class="w-75"
                :options="[
	                'Administrator',
	                'Purchasing',
	                'Maintenance',
	                'Billing',
                ]"
                :show-labels="false"
                :multiple="true"
                v-model="newContact.role"
                style="margin-left: 12.5%"
                placeholder="Roles"
            ></multiselect>

            <label for="c_address" class="w-75 text-left mt-2" style="margin-left: 13.5%">Address</label>
            <input id="c_address" class="form-control w-75" type="text" v-model="newContact.address.street" style="margin-left: 12.5%" placeholder="Street" />

            <input id="c_address2" class="form-control w-75" type="text" v-model="newContact.address.city" style="margin-left: 12.5%" placeholder="City" />
            <multiselect class="w-75" style="margin-left:12.5%" :options="allStates" label="abbreviation" track-by="name" v-model="newContact.address.state" placeholder="State" :show-labels="false" ></multiselect>
            <input id="c_address3" class="form-control w-75" type="text" v-model="newContact.address.zip" style="margin-left: 12.5%" placeholder="Zip Code" />
            <label for="c_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone</label>
            <input id="c_phone" class="form-control w-75" type="text" v-model="newContact.phone" style="margin-left: 12.5%" placeholder="Phone" />
            <label for="c_email" class="w-75 text-left mt-2" style="margin-left: 13.5%">Email</label>
            <input id="c_email" class="form-control w-75" type="text" v-model="newContact.email" style="margin-left: 12.5%" placeholder="Email" />
            <label for="c_pic" class="w-75 text-left mt-2" style="margin-left: 13.5%">Image</label>
            <input
                class="form-control-file w-75"
                id="c_pic"
                type="file"
                accept="image/*"
                @change="uploadImage($event)"
                name="c_pic"
                style="margin-left: 12.5%"
            />
            <div v-if="showPreview">
            <img
                :src="newContact.image"
                class="img-circle elevation-3"
                style="
                height: 100px;
                width: 100px;
                border-radius: 50px;
                border: black solid 1px;
                "
                alt="Preview"
            />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
    props: {
        source: Object
    },
    components: {
        Multiselect
    },
    data() {
        return {
            allStates: [
                {
                    "name": "Alabama",
                    "abbreviation": "AL"
                },
                {
                    "name": "Alaska",
                    "abbreviation": "AK"
                },
                {
                    "name": "American Samoa",
                    "abbreviation": "AS"
                },
                {
                    "name": "Arizona",
                    "abbreviation": "AZ"
                },
                {
                    "name": "Arkansas",
                    "abbreviation": "AR"
                },
                {
                    "name": "California",
                    "abbreviation": "CA"
                },
                {
                    "name": "Colorado",
                    "abbreviation": "CO"
                },
                {
                    "name": "Connecticut",
                    "abbreviation": "CT"
                },
                {
                    "name": "Delaware",
                    "abbreviation": "DE"
                },
                {
                    "name": "District Of Columbia",
                    "abbreviation": "DC"
                },
                {
                    "name": "Federated States Of Micronesia",
                    "abbreviation": "FM"
                },
                {
                    "name": "Florida",
                    "abbreviation": "FL"
                },
                {
                    "name": "Georgia",
                    "abbreviation": "GA"
                },
                {
                    "name": "Guam",
                    "abbreviation": "GU"
                },
                {
                    "name": "Hawaii",
                    "abbreviation": "HI"
                },
                {
                    "name": "Idaho",
                    "abbreviation": "ID"
                },
                {
                    "name": "Illinois",
                    "abbreviation": "IL"
                },
                {
                    "name": "Indiana",
                    "abbreviation": "IN"
                },
                {
                    "name": "Iowa",
                    "abbreviation": "IA"
                },
                {
                    "name": "Kansas",
                    "abbreviation": "KS"
                },
                {
                    "name": "Kentucky",
                    "abbreviation": "KY"
                },
                {
                    "name": "Louisiana",
                    "abbreviation": "LA"
                },
                {
                    "name": "Maine",
                    "abbreviation": "ME"
                },
                {
                    "name": "Marshall Islands",
                    "abbreviation": "MH"
                },
                {
                    "name": "Maryland",
                    "abbreviation": "MD"
                },
                {
                    "name": "Massachusetts",
                    "abbreviation": "MA"
                },
                {
                    "name": "Michigan",
                    "abbreviation": "MI"
                },
                {
                    "name": "Minnesota",
                    "abbreviation": "MN"
                },
                {
                    "name": "Mississippi",
                    "abbreviation": "MS"
                },
                {
                    "name": "Missouri",
                    "abbreviation": "MO"
                },
                {
                    "name": "Montana",
                    "abbreviation": "MT"
                },
                {
                    "name": "Nebraska",
                    "abbreviation": "NE"
                },
                {
                    "name": "Nevada",
                    "abbreviation": "NV"
                },
                {
                    "name": "New Hampshire",
                    "abbreviation": "NH"
                },
                {
                    "name": "New Jersey",
                    "abbreviation": "NJ"
                },
                {
                    "name": "New Mexico",
                    "abbreviation": "NM"
                },
                {
                    "name": "New York",
                    "abbreviation": "NY"
                },
                {
                    "name": "North Carolina",
                    "abbreviation": "NC"
                },
                {
                    "name": "North Dakota",
                    "abbreviation": "ND"
                },
                {
                    "name": "Northern Mariana Islands",
                    "abbreviation": "MP"
                },
                {
                    "name": "Ohio",
                    "abbreviation": "OH"
                },
                {
                    "name": "Oklahoma",
                    "abbreviation": "OK"
                },
                {
                    "name": "Oregon",
                    "abbreviation": "OR"
                },
                {
                    "name": "Palau",
                    "abbreviation": "PW"
                },
                {
                    "name": "Pennsylvania",
                    "abbreviation": "PA"
                },
                {
                    "name": "Puerto Rico",
                    "abbreviation": "PR"
                },
                {
                    "name": "Rhode Island",
                    "abbreviation": "RI"
                },
                {
                    "name": "South Carolina",
                    "abbreviation": "SC"
                },
                {
                    "name": "South Dakota",
                    "abbreviation": "SD"
                },
                {
                    "name": "Tennessee",
                    "abbreviation": "TN"
                },
                {
                    "name": "Texas",
                    "abbreviation": "TX"
                },
                {
                    "name": "Utah",
                    "abbreviation": "UT"
                },
                {
                    "name": "Vermont",
                    "abbreviation": "VT"
                },
                {
                    "name": "Virgin Islands",
                    "abbreviation": "VI"
                },
                {
                    "name": "Virginia",
                    "abbreviation": "VA"
                },
                {
                    "name": "Washington",
                    "abbreviation": "WA"
                },
                {
                    "name": "West Virginia",
                    "abbreviation": "WV"
                },
                {
                    "name": "Wisconsin",
                    "abbreviation": "WI"
                },
                {
                    "name": "Wyoming",
                    "abbreviation": "WY"
                }
            ],
			defaultImage:
			  "https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png",
            newContact: {
                address: {}
            },
            showPreview: false,
        }
    },
    mounted() {
		if(this.source?.customerid !== undefined){
			//is a customer
		}
		else if(this.source?.id !== undefined){
			//is a vendor
		}
		else{
			alert("Something wrong with source sent")
			this.$emit("return")
		}

    },

    methods: {
        async pushContact() {
            //Safety Measures
            if(this.newContact.name == null || this.newContact.name == '' || this.newContact.name == undefined){
                alert("Name is required")
                return;
            }

            let formattedAddress = null
            if(
                this.newContact.address.street == '' || this.newContact.address.street == null || this.newContact.address.street==undefined &&
                this.newContact.address.city == '' || this.newContact.address.city == null || this.newContact.address.city == undefined &&
                this.newContact.address.state?.abbreviation == undefined &&
                this.newContact.address.zip == '' || this.newContact.address.zip == null ||this.newContact.address.zip==undefined
            ){
                formattedAddress = null
            }
            else if (
                this.newContact.address.street !== '' && this.newContact.address.street !== null &&
                this.newContact.address.city !== '' && this.newContact.address.city !== null &&
                this.newContact.address.state?.abbreviation !== undefined &&
                this.newContact.address.zip !== '' && this.newContact.address.zip !== null
            ){
                formattedAddress = `${this.newContact.address.street}, ${this.newContact.address.city}, ${this.newContact.address.state.abbreviation} ${this.newContact.address.zip}`
            }
            else{
                alert("Address needs to be complete or have nothing")
                return;
            }

			if(this.source.customerid !== undefined){
				//Post
				await axios.post(
				process.env.VUE_APP_API_TARGET + "/customers/" +
					this.source.customerid +
					"/contacts/" +
					this.$globals.companyId,
				{
					name: this.newContact.name,
					address: formattedAddress,
					phone: this.newContact.phone,
					email: this.newContact.email,
					title: this.newContact.title,
					role: JSON.stringify(this.newContact.role),
					image: this.newContact.image ? this.newContact.image : this.defaultImage
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newContact', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			else if(this.source.id !== undefined){
				await axios.post(
				  process.env.VUE_APP_API_TARGET + "/vendors/vendorContact/" + this.$globals.companyId,
				  {
					name: this.newContact.name,
					address: formattedAddress,
					phone: this.newContact.phone,
					email: this.newContact.email,
					title: this.newContact.title,
					role: JSON.stringify(this.newContact.role),//NEW
					picture: this.newContact.image
					  ? this.newContact.image
					  : this.defaultImage,
					vendorId: this.source.id,
				  },
				  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'newContact', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			}

			this.newContact.name = '';
			this.newContact.address = {};
			this.newContact.phone = null;
			this.newContact.email = null;
			this.newContact.title = null;
			this.newContact.image = null;
			this.newContact.role= null;
			this.showPreview = false;
			this.$emit('return')

        }, //END pushContact()

        uploadImage(e) {
            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = (e) => {
                this.newContact.image = e.target.result;
                this.showPreview = true;
                this.$nextTick(()=>{
                    this.$forceUpdate()
                })
            };

        },
    }
}
</script>

<style scoped>

</style>

<template>
    <div class="card card-danger" style="padding-bottom: 1%">
      <div class="card-header">
        <div class="card-title">
          <h3><b>New Asset for {{ selectedCustomer.name }}</b></h3>
        </div>
        <div class="card-tools">
          <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="pushAsset()">Submit</b-button>
          <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="$emit('return')">Return</b-button>
        </div>
      </div>
      <div class="card-body">
		  <div class="row">
			  <div class="col">
				  <label for="a_type" class="w-75 text-left mt-2" style="margin-left: 13.5%">Asset Type<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
		          <multiselect
		              id="a_type"
		              :options="assetOptions"
		              :show-labels="false"
		              class="w-75"
		              style="margin-left: 12.5%"
		              aria-placeholder="Type"
		              v-model="newAsset.asset_type"
		              placeholder="Asset Class"
		              required>
					  <!-- <template slot="option" slot-scope="props">
						  <div class="option__desc" style="background-color: #dc4535; color: green"><span class="option__title">{{props.option}}</span></div>
					  </template> -->
		          </multiselect>
				  <input v-if="newAsset.asset_type == '**NEW**'" type="string" class="form-control w-75 text-left mt-2" style="margin-left: 12.5%" v-model="newAssetType" />
			  </div>
		  </div>

		  <div class="row">
			  <div class="col">
				  <label for="a_serial" class="w-75 text-left mt-2" style="margin-left: 13.5%">Serial Number<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				  <input id="a_serial" class="form-control w-75" style="margin-left: 12.5%;" type="text" v-model="newAsset.serial" placeholder="Serial Number" required />
			  </div>
		  </div>


  		  <div class="row">
  			  <div class="col">
  				  <label for="a_serial" class="w-75 text-left mt-2" style="margin-left: 13.5%">ID</label>
  				  <input id="a_serial" class="form-control w-75" style="margin-left: 12.5%;" type="text" v-model="newAsset.id" placeholder="ID defaults to Type + Serial"/>
  			  </div>
  		  </div>

		  <div class="row">
			  <div class="col">
				  <label for="a_house" class="w-75 text-left mt-2" style="margin-left: 13.5%">Location</label>
				  <multiselect
					  id="a_house"
					  :options="allHouses"
					  track-by="houseid"
					  label="name"
					  class="w-75"
					  style="margin-left: 12.5%;"
					  :show-labels="false"
					  v-model="newAsset.location"
					  placeholder="Location"
					  >
				  </multiselect>
			  </div>
		  </div>

		  <div style="text-align: center; text-justify: inter-word">
			  <h4 style="margin-top: 2%"><strong>Warranty Information: </strong></h4><br>
			  <div class="row mt-2" v-for="(warranty, index) in warrantyArray" :key="index">
			  	<div class="col">
			  		<label>Start/In Service</label>
			  		<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="warranty.start"/>
			  	</div>

			  	<div class="col">
			  		<label>Field</label><br>
			  		{{warranty.name}}
			  	</div>

			  	<div class="col">
			  		<label>Value</label>
					<div class="row align-center">
						<input type="text" class="form-control" style="width: 85%" placeholder="Warranty Value" v-model="warranty.value"/>
						<i class="fas fa-trash" style="display: inline-block; margin-left: 1vw" @click="warrantyArray.splice(index, 1)"></i>
					</div>


			  	</div>

			  </div>

			  <div class="row mt-5">
			  	<div class="col">
			  		<label>Start/In Service</label>
			  		<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newWarranty.start"/>
			  	</div>

			  	<div class="col">
			  		<label>Field</label>
			  		<input class="form-control" type="text" v-model="newWarranty.name" placeholder="Field Name" />
			  	</div>

			  	<div class="col">
			  		<label>Value</label>
					<div class="row align-center">
						<input class="form-control" style="width: 85%" type="text" v-model="newWarranty.value" placeholder="Field Value" />
				  		<i class="fas fa-plus-circle" style="display: inline-block; margin-left: 1vw" @click.prevent="pushNewWarranty()"></i>
					</div>
			  	</div>
			  </div>
		  </div>

		  <div style="text-align: center; text-justify: inter-word">
			  <h4 style="margin-top: 2%"><b>Technical Information:</b></h4> <br>
			  <div class="row mt-2" v-for="(value, key) in technicalObjects" :key="key">
				  <div class="col">
					  <strong>{{ key }}:</strong>
				  </div>
				  <div class="col">
					  <input class="form-control" type="text" v-model="technicalObjects[key]" placeholder="Field Value" />
				  </div>
				  <div class="col">
					  <i class="fas fa-trash" style="display: inline-block" @click="removeCustomFields(technicalObjects, key)"></i>
				  </div>
			  </div>
			  <div class="row mt-4">
				  <div class="col">
					  <input class="form-control" type="text" v-model="newField.name" placeholder="Field Name" />
				  </div>
				  <div class="col" style="margin: auto">
					  <input class="form-control" type="text" v-model="newField.value" placeholder="Field Value" />
				  </div>
				  <div class="col">
					  <i class="fas fa-plus-circle" @click.prevent="pushField(technicalObjects, 'technical')"></i>
				  </div>
			  </div>
		  </div>
      </div>
    </div>
</template>

<script>
/*eslint-disable*/
import Multiselect from "vue-multiselect";
import axios from "axios";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'

export default {
	name: 'newAsset',
    props: {
        selectedCustomer: Object,
    },

    components: {
        Multiselect, VueCtkDateTimePicker,
    },

    data() {
        return {
            allHouses: [],
            assetOptions: [],
			maintenanceContracts: [],
			newAsset: {},
			newAssetType: '',
			newField: {
				name: '',
				field: ''
			},
			newWarranty: {
				start: '',
				name: '',
				value: ''
			},
			technicalObjects: {},
			warrantyArray: [],
        }
    },

    async mounted() {
		if(this.selectedCustomer.name == undefined || this.selectedCustomer.name == null){
			alert("No Customer selected")
			this.$emit('return')
		}
		else{
			await this.getHouses(this.selectedCustomer);
			//await this.getMaintenanceContracts();
			await this.getAssetTypes()
		}
    },

    methods: {

		async getAssetTypes(){
			axios.get(`${process.env.VUE_APP_API_TARGET}/assets/types/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer '+ this.$globals.user.auth0Token}})
			.then((response)=>{
				this.assetOptions = typeof response.data.queryResult[0].types == 'object' ? response.data.queryResult[0].types : JSON.parse(response.data.queryResult[0].types)
				this.assetOptions.push("**NEW**")
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

        async getHouses(option) {
            await axios.get(process.env.VUE_APP_API_TARGET + "/customers/"+option.customerid+"/houses/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}).then(response =>{
                this.allHouses = response.data.queryResult;
            })
            .catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
        },

		async getMaintenanceContracts(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/contracts/customer/${this.selectedCustomer.customerid}/${this.$globals.companyId}`,
			  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response) => {
				console.log("maintenanceContract response: ",response.data.queryResult)
				this.maintenanceContracts = response.data.queryResult;
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
		},

        async pushAsset() {
            var custid = this.selectedCustomer.customerid;
            //Saftey Check
            if(this.newAsset?.asset_type == undefined || this.newAsset?.asset_type == null || this.newAsset?.asset_type == 'Select Type' || this.newAsset?.asset_type == ''){
                alert("Please select an asset type")
                return;
            }

            if(this.newAsset?.serial == undefined || this.newAsset?.serial == null || this.newAsset?.serial == ''){
                alert("Please enter the serial number")
                return;
            }

			if(this.newAsset.asset_type == '**NEW**'){
				if(this.newAssetType == '' || this.newAssetType == null){
					alert("Please enter a type for the asset")
					return;
				}
				this.newAsset.asset_type = this.newAssetType
			}

			if(this.newAsset.id == '' || this.newAsset.id == null || this.newAsset.id == undefined){
				this.newAsset.id = `${this.newAsset.asset_type}${this.newAsset.serial}`
			}

            await axios.post(
                `${process.env.VUE_APP_API_TARGET}/assets/${custid}/${this.$globals.companyId}`,
                {
                    type: this.newAsset.asset_type,
                    serial: this.newAsset.serial,
					id: this.newAsset.id,
                    location: this.newAsset.location?.name,
					warranty_fields: JSON.stringify(this.warrantyArray),
					technical_fields: JSON.stringify(this.technicalObjects)
                },
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
            )
			.then((response)=>{
				axios.patch(`${process.env.VUE_APP_API_TARGET}/assets/types/${this.$globals.companyId}`,{},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			})
			.catch((response)=>{
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
            this.$emit('return')
        }, //END pushAsset()

		pushField(existingField, type) {
			if(this.newField.name == null || this.newField.name === '' || this.newField?.name === undefined){
				alert("New custom field should be named")
				return;
			}
			if(this.newField.value == null || this.newField.value === '' || this.newField?.value === undefined){
				alert("New custom field should have a value")
				return;
			}
			existingField = (existingField === null ? {} : (typeof existingField === 'object' ? existingField : JSON.parse(existingField)))

			existingField[this.newField.name] = this.newField.value;
			if(type == 'technical'){
				this.technicalObjects = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})
			}
			else{
				//console.log("Type not provided??");
				return;
			}
			this.newField.value = '';
			this.newField.name = ''
		},

		pushNewWarranty(){
			//NOTE: not a required field but if filled in needs to have correct data
			if(this.newWarranty.start == '' || this.newWarranty.start == null || this.newWarranty.start == undefined){
				alert("Warranty requires a start or an enter service date")
				return;
			}
			if(this.newWarranty.name == '' || this.newWarranty.name == null || this.newWarranty.name == undefined){
				alert("Warranty requires a field")
				return;
			}
			if(this.newWarranty.value == '' || this.newWarranty.value == null || this.newWarranty.value == undefined){
				alert("Warranty requires a value")
				return;
			}


			this.warrantyArray.push({
				name: this.newWarranty.name,
				start: this.newWarranty.start,
				value: this.newWarranty.value
			})

			this.newWarranty = {
				name: '',
				start: '',
				value: ''
			}
		},

		removeCustomFields(vehicleFields, key){
			delete vehicleFields[key]
			this.newField.value = '';
			this.newField.name = ''
			this.$forceUpdate();
		},
    }
}
</script>

<style>

</style>

<template>
	<div>
		<div v-if="action==='list'">
			<div class="card card-danger mt-4 ml-4">
				<div class="card-header">
					<div class="card-title">
						<h3><b>To Do</b></h3>
					</div>
					<div class="card-tools">
						<b-button style="background-color: #fff4; border-color: #dc3545" @click="action = 'new'">New Task</b-button>
					</div>
				</div>
				<div class="card-body" style="height: 400px; overflow: scroll">
					<b-table
						striped
						:items="openTasks"
						:fields="[
							{ key: 'status', label: 'Active', sortable: true },
							{ key: 'task', sortable: true },
							{ key: 'due', sortable: true },
						]"
						@row-clicked="viewTask"
					>
						<template #cell(task)="row">
							<span style="text-overflow: ellipsis; word-wrap: normal">{{row.item.task}}</span>
						</template>
						<template #cell(due)="row">
							{{(row.item.due).split('T')[0]}}
						</template>
					</b-table>
				</div>
			</div>
		</div>

		<div v-if="action==='new'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>New Task</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							@click="submitTask()"
						>Submit
						</b-button>
						<b-button style="background-color: #fff4; border-color: #dc3545;" @click="action = 'list'">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<div class="row" >
						<div class="col" style="text-align: center">
							<label style="text-align: left; display: inline">To Do<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
							<textarea
								type="text"
								style="
									width: 78%;
									height: 30vh;
									border-radius: 8px;
									border-color: grey;
									text-align: left;
								"
								id="to-do-input"
								v-model="newToDo.task"
								required
							>
							</textarea>
						</div>
						<hr>
					</div>
					<div class="row">
						<div class="col" style="text-align: center">
							<label style="margin-top: 1%; display: inline" for="circleColors">Color for Calendar: </label><br>
							<v-swatches id="circleColors" :swatches="swatches" v-model="newToDo.tag" shapes="circles" popover-x="right" style="display: inline"></v-swatches>
						</div>
						<div class="col" style="text-align: left">
							<label style="display: inline">Due Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
							<VueCtkDateTimePicker id="dueDate" style="width: 70%" color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newToDo.due" requried/>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div v-if="action==='edit'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Edit Task</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="updateTask()"
						>Update
						</b-button>
						<b-button style="background-color: #fff4; border-color: #dc3545" class="mx-1" @click="action = 'viewSelected'; newToDo.due=null; newToDo.task=null; newToDo.tag=null">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<div class="row" >
						<div class="col" style="text-align: center">
							<label style="text-align: left; display: inline">To Do<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
							<textarea
								type="text"
								required
								style="
									width: 78%;
									height: 30vh;
									border-radius: 8px;
									border-color: grey;
									text-align: left;
								"
								id="to-do-input"
								v-model="newToDo.task"
							>
							</textarea>
						</div>
						<hr>
					</div>
					<div class="row">
						<div class="col" style="text-align: center">
							<label style="margin-top: 1%; display: inline" for="circleColors">Color for Calendar: </label><br>
							<v-swatches id="circleColors" :swatches="swatches" v-model="newToDo.tag" shapes="circles" popover-x="right" style="display: inline"></v-swatches>
						</div>
						<div class="col" style="text-align: left">
							<label style="display: inline">Due Date<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label><br>
							<VueCtkDateTimePicker required id="dueDate" style="width: 70%" color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newToDo.due"/>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div v-if="action==='viewSelected'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Task</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="changeStatus()"
						>Complete Task
						</b-button>
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click ="editTask()">
							Edit
						</b-button>
						<b-button
							style="background-color: #fff4; border-color: #dc3545"
							class="mx-1"
							@click="action = 'list'"
						>Return
						</b-button>
					</div>
				</div>
				<div class="card-body">

					<div class="row">
						<div class="col" style="text-align: right">
							<strong>Started on : </strong>{{selectedTask.starts.split('T')[0]}}
						</div>
						<div class="col" style="text-align: left">
							<strong>Due on: </strong>{{selectedTask.due.split('T')[0]}}
						</div>

					</div>
					<div class="row" style=" margin-top:3%; font-size:16pt">
						<div class="col" style="text-align: center">
							<p>{{selectedTask.task}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
	//Used in Dashboard, Sales
	import axios from 'axios'
	import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
	import VSwatches from 'vue-swatches'
	import "vue-swatches/dist/vue-swatches.css"

	export default {
		props: {
		},
		components: {
			VueCtkDateTimePicker, VSwatches
		},
		data(){
			return{
				action: 'list',
				selectedTask: null,
				openTasks: [],
				newToDo: {
					due: null,
					task: null,
					tag: null
				},
				swatches: ["red", "orange", "yellow", "green", "teal", "blue", "indigo", "purple", "pink"],


			}
		},
		async mounted(){
			await this.getToDoList()
		},
		methods:{
			async changeStatus() {
				this.selectedTask.status = 'Complete'
				await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do_status/${this.$globals.user.sub}/${this.$globals.companyId}`,
					{
						localid: this.selectedTask.id,
						status: "Complete",
					},{ headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'To Do', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.$forceUpdate();
				this.action = "list";
				this.selectedTask = null;
				this.getToDoList();
				this.$emit('change')

			},

			editTask(){
				this.newToDo.tag = this.selectedTask.color
				this.newToDo.task = this.selectedTask.task
				this.newToDo.due = this.selectedTask.due
				this.action = 'edit'
			},

			async getToDoList(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/to_do/open/${this.$globals.user.sub}/${this.$globals.companyId}`, {headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }})
					.then((response) => {
						this.openTasks = response.data.queryResult;
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'To Do', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async submitTask() {
				//Saftey check
				if(this.newToDo.due == null || this.newToDo.due == ''){
					alert("Please select a day")
					return;
				}
				if(this.newToDo.task == null || this.newToDo.task == ''){
					alert("What do you need to do?")
					return;
				}
				if(this.newToDo.tag === null || this.newToDo.tag === ''){
					this.newToDo.tag = 'red'
				}

				let dueDate = this.newToDo.due.split(' ')[0];
				let today = new Date().toISOString().split("T")[0]

				await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/to_do`,
					{
						companyid: this.$globals.companyId,
						salesman: this.$globals.user.name,
						task: this.newToDo.task,
						starts: today,
						due: dueDate,
						auth: this.$globals.user.sub,
						status: "Open",
						color: this.newToDo.tag,
						auth: this.$globals.user.sub
				  },
				  { headers: { Authorization: "Bearer " + this.$globals.user.auth0Token } }
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'To Do', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await this.getToDoList()
				this.$emit('change')
				this.action = "list";

				this.newToDo = {
					due: null,
					tag: null,
					task: null,
				}
			},

			async updateTask(){
				//Saftey Check
				if(this.newToDo.due == null || this.newToDo.due == ''){
					alert("Please add a due date")
					return;
				}
				if(this.newToDo.task == null || this.newToDo.task == ''){
					alert("Please add a task to do")
					return;
				}

				let dueDate = this.newToDo.due.split(' ')[0];

				await axios.put(`${process.env.VUE_APP_API_TARGET}/sales/to_do/${this.selectedTask.id}/${this.$globals.companyId}`,
					{
						task: this.newToDo.task,
						color: this.newToDo.tag,
						due: dueDate,
						status: this.selectedTask.status
					},{headers: { Authorization: "Bearer " + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'To Do', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await this.getToDoList();
				this.$emit('change')
				this.action="list"
				this.newToDo.task = ""
				this.newToDo.due=null
				this.newToDo.tag=""
				//this.$forceUpdate()

			},

			viewTask(record) {
			  this.selectedTask = record;
			  this.action = "viewSelected";
		  },

		}
	}

</script>

<style>

</style>

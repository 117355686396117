<template>
    <div>
        <div v-if="action === ''">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>Recent Shipments</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button style="background: #fff4; border: #dc3545; margin-right: 1%; margin-left: -10%" @click="() => {
                            action = 'newShipment'
                        }">Create New Shipment</b-button>

                        <b-button v-if="$globals.canAccess.admin" style="background: #fff4; border: #dc3545; margin-right: 1%" @click="() => {
                            action = 'newCarrierAccount'
                        }">Attach Carrier Account</b-button>

                         <b-button v-if="$globals.canAccess.admin" style="background: #fff4; border: #dc3545;" @click="() => {
                            action = 'viewCarrierAccounts'
                        }">View Carrier Accounts</b-button>
                    </div>
                </div>
                <div class="card-body">
                    <b-table :fields="openShipmentFields" :items="openShipments" striped @row-clicked="showTrackingInfo">
                        <template #cell(customer)="row">
                            {{row.item.to_address.company ? row.item.to_address.company : 'INDIVIDUAL'}}
                        </template>

                        <template #cell(to_address)="row">
                            {{row.item.to_address.name}} - {{`${row.item.to_address.street1}, ${row.item.to_address.city}, ${row.item.to_address.state} ${row.item.to_address.zip}`}}
                        </template>

                        <template #cell(tracking_code)="row">
                            <span class="vehicle-link" @click.prevent="showTrackingInfo(row)">{{ row.item.tracking_code }}</span>
                        </template>

                        <template #cell(updated_at)="row">
                            {{ new Date(row.item.updated_at).toLocaleString() }}
                        </template>

                        <template #cell(postage_label)="row">
                            <i v-if="row.item.postage_label" class="fas fa-print" @click.stop="downloadLabel(row)"></i>
                        </template>

                    </b-table>
                </div>
            </div>
        </div>

        <div v-if="action === 'newShipment'">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>New Shipment</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button class="m-1" style="background: #fff4; border: #dc3545;" @click="() => {
                            createShipment()
                        }">Submit</b-button>
                        <b-button style="background: #fff4; border: #dc3545;" @click="() => {
                            action = ''
                        }">Return</b-button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col" style="border-right: 6px solid-black">
                            <address>
                                <label for="to_address" class="w-75 text-left mt-2" style="margin-left: 13.5%"><h3>To:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></h3></label>
                                <input required id="to_address" class="form-control w-75" type="text" v-model="newShipment.toAddress.street" style="margin-left: 12.5%" placeholder="Street"/>
                                <input id="to_address" class="form-control w-75" type="text" v-model="newShipment.toAddress.street2" style="margin-left: 12.5%" placeholder="Street2"/>
                                <input requried id="to_address2" class="form-control w-75" type="text" v-model="newShipment.toAddress.city" style="margin-left: 12.5%" placeholder="City"/>
                                <multiselect required class="w-75" style="margin-left:12.5%" :options="allStates" label="name" track-by="name" v-model="newShipment.toAddress.state" placeholder="State"></multiselect>
                                <input required id="to_address3" class="form-control w-75" type="text" v-model="newShipment.toAddress.zip" style="margin-left: 12.5%" placeholder="Zip Code"/>
                                <label for="to_company" class="w-75 text-left mt-2" style="margin-left: 13.5%">Company</label>
                                <input id="to_company" class="form-control w-75" type="text" v-model="newShipment.toAddress.company" style="margin-left: 12.5%" placeholder="Company name"/>
                                <label for="to_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Contact</label>
                                <input id="to_name" class="form-control w-75" type="text" v-model="newShipment.toAddress.name" style="margin-left: 12.5%" placeholder="Name"/>
                                <label for="to_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone</label>
                                <input id="to_phone" class="form-control w-75" type="text" v-model="newShipment.toAddress.phone" style="margin-left: 12.5%" placeholder="Phone"/>
                                <label for="to_email" class="w-75 text-left mt-2" style="margin-left: 13.5%">Email</label>
                                <input id="to_email" class="form-control w-75" type="text" v-model="newShipment.toAddress.email" style="margin-left: 12.5%" placeholder="Email"/>

                                <label for="weight" class="w-75 text-left mt-2" style="margin-left:13.5%">Weight (in lbs)<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                                <input required type="number" class="form-control w-75" style="margin-left:13.5%" min="0" step="1" placeholder="weight" v-model="newShipment.weight"/>
                            </address>
                        </div>

                        <div class="col">
                            <address>
                                <label for="from_address" class="w-75 text-left mt-2" style="margin-left: 13.5%"><h3>From:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></h3></label>
                                <input required id="from_address" class="form-control w-75" type="text" v-model="newShipment.fromAddress.street" style="margin-left: 12.5%" placeholder="Street"/>
                                <input id="to_address" class="form-control w-75" type="text" v-model="newShipment.fromAddress.street2" style="margin-left: 12.5%" placeholder="Street2"/>
                                <input required id="from_address2" class="form-control w-75" type="text" v-model="newShipment.fromAddress.city" style="margin-left: 12.5%" placeholder="City"/>
                                <multiselect required class="w-75" style="margin-left:12.5%" :options="allStates" label="name" track-by="name" v-model="newShipment.fromAddress.state" placeholder="State"></multiselect>
                                <input required id="from_address3" class="form-control w-75" type="text" v-model="newShipment.fromAddress.zip" style="margin-left: 12.5%" placeholder="Zip Code"/>
                                <label for="to_company" class="w-75 text-left mt-2" style="margin-left: 13.5%">Company</label>
                                <input id="to_company" class="form-control w-75" type="text" v-model="newShipment.fromAddress.company" style="margin-left: 12.5%" placeholder="Company name"/>
                                <label for="to_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Contact</label>
                                <input id="to_name" class="form-control w-75" type="text" v-model="newShipment.fromAddress.name" style="margin-left: 12.5%" placeholder="Name"/>
                                <label for="from_phone" class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone</label>
                                <input id="from_phone" class="form-control w-75" type="text" v-model="newShipment.fromAddress.phone" style="margin-left: 12.5%" placeholder="Phone"/>
                                <label for="from_email" class="w-75 text-left mt-2" style="margin-left: 13.5%">Email</label>
                                <input id="from_email" class="form-control w-75" type="text" v-model="newShipment.fromAddress.email" style="margin-left: 12.5%" placeholder="Email"/>

                                <label for="insurance" class="w-75 text-left mt-2" style="margin-left:13.5%">Insured Value</label>
								<input id="insurance" class="form-control w-75" type="number" style="margin-left: 12.5%" v-model="newShipment.insurance"/>
                            </address>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card card-danger" v-if="action === 'viewRates'">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Available Rates</b></h3>
                </div>
                <div class="card-tools">
                    <b-button style="background: #fff4; border: #dc3545;" @click="() => {
                        action = ''
                    }">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <b-table :fields="ratesFields" :items="rates" >
                    <template #cell(selected)="row">
                        <b-button style="background: #dc3545; border: #dc3545" @click="buyRate(row)">Buy Now!</b-button>
                    </template>
                </b-table>
            </div>
        </div>

        <div class="card card-danger" v-if="action === 'viewTrackingInfo'">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Tracking Log</b></h3>
                </div>
                <div class="card-tools">
                    <b-button style="background: #fff4; border: #dc3545" @click="action = ''">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <b-table :fields="trackingFields" :items="selectedShipmentTracker.tracking_details">
                    <template #cell(datetime)="row">
                        {{ new Date(row.item.datetime).toLocaleString() }}
                    </template>

                    <template #cell(tracking_location)="row">
                        {{ row.item.tracking_location.city ? `${row.item.tracking_location.city}, ${row.item.tracking_location.state} ${row.item.tracking_location.zip ? row.item.tracking_location.zip : '' }` : '' }}
                    </template>

                </b-table>
            </div>
        </div>

        <div class="card card-danger" v-if="action === 'newCarrierAccount'">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>New Carrier Account</b></h3>
                </div>

                <div class="card-tools">
                    <b-button style="background: #fff4; border: #dc3545; margin-left: -10%" @click="() => {
                        createCarrierAccount()
                    }">Submit</b-button>
                    <b-button style="background: #fff4; border: #dc3545; margin-left: 1.5%" @click="() => {
                        action = '';
                    }">Cancel</b-button>
                </div>
            </div>

            <div class="card-body">
                <label for="carrierName" style="margin-left: 25%">Carrier<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                <select
                    name="carrierName"
                    id="carrierName"
                    v-model="selectedCarrier"
                    class="form-control w-50 m-auto"
                    @change="getCarrierFields(selectedCarrier)"
                    required
                >
                    <option v-for="(readable, index) in allCarrierTypes" v-bind:key="index">
                        {{ allCarrierTypes[index].readable }}
                    </option>
                </select>

                <label for="description" style="margin-left: 25%; margin-top: 1.5%;">Description<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                <input type="text" placeholder="Description" v-model="newCarrier.description" class="form-control w-50 m-auto" required/>

                <label for="reference" style="margin-left: 25%; margin-top: 1.5%;">Reference<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
                <input type="text" placeholder="Reference" v-model="newCarrier.reference" class="form-control w-50 m-auto" required/>
                <hr />

                <div v-if="showCarrierFields" class="m-auto w-50">
                    <div v-for="(field,index) in credFields" :key="index" class="my-3">
                        <label :for="index">{{field.label}}</label><br/>
                        <input :id="index" type="text" :placeholder="field.label" v-model="newCarrier.creds[index]" class="form-control"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="action === 'viewCarrierAccounts'" class="card card-danger">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Carrier Accounts</b></h3>
                </div>
                <div class="card-tools">
                    <b-button style="background: #fff4; border: #dc3545; margin-left: 1.5%" @click="() => {
                        action = '';
                    }">Return</b-button>
                </div>
            </div>
            <div class="card-body">
                <b-table :items="allCarrierAccounts" :fields="[{key: 'carrier'}, {key: 'acct_type', label: 'Account Type'}, {key: 'description'}, {key: 'reference'}, {key: 'credentials'}]">
                    <template #cell(credentials)="row">
                        <div v-for="(credential, index) in JSON.parse(row.item.credentials)" :key="credential">
                            {{ index.replaceAll('_', ' ').toUpperCase() }}: {{ credential }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

    </div>

</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import VueBarcode from "vue-barcode";
import "print-js";

export default {
    name: 'shipping',
    components: {
        Multiselect,
        VueBarcode
    },
    props: {
        user: Object,
        companyId: Number
    },
    computed: {

    },
    data(){
        return {
            action: '',
            allStates: [
                {
                    "name": "Alabama",
                    "abbreviation": "AL"
                },
                {
                    "name": "Alaska",
                    "abbreviation": "AK"
                },
                {
                    "name": "American Samoa",
                    "abbreviation": "AS"
                },
                {
                    "name": "Arizona",
                    "abbreviation": "AZ"
                },
                {
                    "name": "Arkansas",
                    "abbreviation": "AR"
                },
                {
                    "name": "California",
                    "abbreviation": "CA"
                },
                {
                    "name": "Colorado",
                    "abbreviation": "CO"
                },
                {
                    "name": "Connecticut",
                    "abbreviation": "CT"
                },
                {
                    "name": "Delaware",
                    "abbreviation": "DE"
                },
                {
                    "name": "District Of Columbia",
                    "abbreviation": "DC"
                },
                {
                    "name": "Federated States Of Micronesia",
                    "abbreviation": "FM"
                },
                {
                    "name": "Florida",
                    "abbreviation": "FL"
                },
                {
                    "name": "Georgia",
                    "abbreviation": "GA"
                },
                {
                    "name": "Guam",
                    "abbreviation": "GU"
                },
                {
                    "name": "Hawaii",
                    "abbreviation": "HI"
                },
                {
                    "name": "Idaho",
                    "abbreviation": "ID"
                },
                {
                    "name": "Illinois",
                    "abbreviation": "IL"
                },
                {
                    "name": "Indiana",
                    "abbreviation": "IN"
                },
                {
                    "name": "Iowa",
                    "abbreviation": "IA"
                },
                {
                    "name": "Kansas",
                    "abbreviation": "KS"
                },
                {
                    "name": "Kentucky",
                    "abbreviation": "KY"
                },
                {
                    "name": "Louisiana",
                    "abbreviation": "LA"
                },
                {
                    "name": "Maine",
                    "abbreviation": "ME"
                },
                {
                    "name": "Marshall Islands",
                    "abbreviation": "MH"
                },
                {
                    "name": "Maryland",
                    "abbreviation": "MD"
                },
                {
                    "name": "Massachusetts",
                    "abbreviation": "MA"
                },
                {
                    "name": "Michigan",
                    "abbreviation": "MI"
                },
                {
                    "name": "Minnesota",
                    "abbreviation": "MN"
                },
                {
                    "name": "Mississippi",
                    "abbreviation": "MS"
                },
                {
                    "name": "Missouri",
                    "abbreviation": "MO"
                },
                {
                    "name": "Montana",
                    "abbreviation": "MT"
                },
                {
                    "name": "Nebraska",
                    "abbreviation": "NE"
                },
                {
                    "name": "Nevada",
                    "abbreviation": "NV"
                },
                {
                    "name": "New Hampshire",
                    "abbreviation": "NH"
                },
                {
                    "name": "New Jersey",
                    "abbreviation": "NJ"
                },
                {
                    "name": "New Mexico",
                    "abbreviation": "NM"
                },
                {
                    "name": "New York",
                    "abbreviation": "NY"
                },
                {
                    "name": "North Carolina",
                    "abbreviation": "NC"
                },
                {
                    "name": "North Dakota",
                    "abbreviation": "ND"
                },
                {
                    "name": "Northern Mariana Islands",
                    "abbreviation": "MP"
                },
                {
                    "name": "Ohio",
                    "abbreviation": "OH"
                },
                {
                    "name": "Oklahoma",
                    "abbreviation": "OK"
                },
                {
                    "name": "Oregon",
                    "abbreviation": "OR"
                },
                {
                    "name": "Palau",
                    "abbreviation": "PW"
                },
                {
                    "name": "Pennsylvania",
                    "abbreviation": "PA"
                },
                {
                    "name": "Puerto Rico",
                    "abbreviation": "PR"
                },
                {
                    "name": "Rhode Island",
                    "abbreviation": "RI"
                },
                {
                    "name": "South Carolina",
                    "abbreviation": "SC"
                },
                {
                    "name": "South Dakota",
                    "abbreviation": "SD"
                },
                {
                    "name": "Tennessee",
                    "abbreviation": "TN"
                },
                {
                    "name": "Texas",
                    "abbreviation": "TX"
                },
                {
                    "name": "Utah",
                    "abbreviation": "UT"
                },
                {
                    "name": "Vermont",
                    "abbreviation": "VT"
                },
                {
                    "name": "Virgin Islands",
                    "abbreviation": "VI"
                },
                {
                    "name": "Virginia",
                    "abbreviation": "VA"
                },
                {
                    "name": "Washington",
                    "abbreviation": "WA"
                },
                {
                    "name": "West Virginia",
                    "abbreviation": "WV"
                },
                {
                    "name": "Wisconsin",
                    "abbreviation": "WI"
                },
                {
                    "name": "Wyoming",
                    "abbreviation": "WY"
                }
            ],
            allCarrierAccounts: [],
            allCarrierTypes: [],
            carrierFields:[],
            credFields:{},
            closedShipments:[],
            newCarrier: {
                description: '',
                reference: '',
                creds: {},
            },
            newShipment: {
                toAddress: {},
                fromAddress: {},
                weight: 0,
				insurance: 0,
            },
            openShipments: [],
            openShipmentFields: [
                { key: "customer", label: "Customer", filterable: true},
                { key: "tracking_code", label: "Tracking ID", filterable: true },
                { key: "to_address", label: "Shipped To:", thStyle: "width: 300px"},
                { key: "updated_at", label: "Date", sortable: true, tdClass: "small"},
                { key: "order", label: "Attached Order" },
                { key: "status", sortable: true},
                { key: "postage_label", label: "Label"}
            ],
            rates: [],
            ratesFields: [
                { key: 'carrier', sortable: true},
                { key: 'service', sortable: true},
                { key: 'est_delivery_days', sortable: true, label: 'Estimated Delivery Days'},
                { key: 'rate', filterable: true, sortable: true },
                { key: 'currency' },
                { key: 'selected', label: '' }
            ],
            selectedCarrier: null,
            selectedCarrierIndex: -1,
            selectedShipmentTracker: null,
            selectedShipmentLabel: null,
            showCarrierFields: false,
            trackingFields: [
                { key: 'datetime', label: 'Date', sortable: true },
                { key: 'message', label: 'Event' },
                { key: 'status', sortable: true },
                { key: 'tracking_location', label: 'Location' }
            ],
        }
    },

    mounted() {
        this.getOpenShipments()
        this.getAllCarriers()
        this.getCarrierAccounts()
    },

    methods: {
        async buyRate(row) {
            if(confirm(`Are you sure you want to purchase ${row.item.carrier} ${row.item.service} for ${row.item.rate}${row.item.currency}`)){
                await axios
                    .post(process.env.VUE_APP_API_TARGET + "/shipping/shipment_buy_specific/"+this.$globals.companyId, {
                        rate_id: row.item.id,
                        shipment_id: row.item.shipment_id,
                    },
                    {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
                    .then((response) => {
                        this.$emit('addShippingToOrder', row.item.carrier, row.item.service, row.item.rate)
                        this.$emit('returnFromNewShipment')
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
            }
        },

        async createShipment() {
            if(this.newShipment.weight === 0){
                return alert("A shipment must have the parcel's weight defined.")
            }

            let toState = this.newShipment.toAddress.state;
            let fromState = this.newShipment.fromAddress.state;

            this.newShipment.carrier_accounts = this.allCarrierAccounts

            this.newShipment.toAddress.state = (typeof this.newShipment.toAddress.state === 'object' ? this.newShipment.toAddress.state.abbreviation : this.newShipment.toAddress.state)
            this.newShipment.fromAddress.state = (typeof this.newShipment.fromAddress.state === 'object' ? this.newShipment.fromAddress.state.abbreviation : this.newShipment.fromAddress.state)
            await axios.post(`${process.env.VUE_APP_API_TARGET}/shipping/shipment/${this.$globals.companyId}`, this.newShipment, {
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then(async (response) => {
                this.rates = response.data.shipment.rates
                this.action = 'viewRates'

                this.newShipment.toAddress.state = toState;
                this.newShipment.fromAddress.state = fromState;

				if(this.newShipment.insurance > 0){
					await axios
						.post(
						process.env.VUE_APP_API_TARGET + "/shipping/shipment/add_insurance/",
						{
							shipment_id: response.data.shipment.id,
							value: this.newShipment.insurance,
						},
						{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
						)
						.catch((response)=>{
							this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
						})
				}
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async createCarrierAccount() {
            await axios.post(`${process.env.VUE_APP_API_TARGET}/shipping/carrier_accounts/${this.$globals.companyId}`, {
                type: this.allCarrierTypes[this.selectedCarrierIndex].type,
                description: this.newCarrier.description,
                reference: this.newCarrier.reference,
                credentials: this.newCarrier.creds,
                carrier: this.selectedCarrier
            }, {
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then(response => {
                if(response.data.error){
                    this.$vToastify.error(`${response.data.message}\n`, 'Something went wrong...');
                } else {
                    this.$vToastify.success(`${response.data.message}\n`, 'Carrier Account Created')
                }
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        downloadLabel(row){
            window.open(row.item.postage_label.label_url, '_blank')
        },

        async getAllCarriers(){
            await axios.get(process.env.VUE_APP_API_TARGET + "/shipping/carrier_types/", {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then(response =>{
                this.allCarrierTypes = response.data.queryResult;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async getCarrierAccounts(){
            await axios.get(`${process.env.VUE_APP_API_TARGET}/shipping/carrier_accounts/${this.$globals.companyId}`, {
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response) => {
                this.allCarrierAccounts = response.data.queryResult
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        getCarrierFields(carrierName) {
            var index = 0;
            for (index in this.allCarrierTypes) {
                if (this.allCarrierTypes[index].readable === carrierName) {
                    this.carrierFields = this.allCarrierTypes[index].fields;
                    this.selectedCarrierIndex = index;
                    break;
                }
            }
            this.credFields = {};
            for (let credField in this.carrierFields.credentials) {
                this.credFields[credField] = this.carrierFields.credentials[credField];
                this.newCarrier.creds[credField] = '';
            }
            this.showCarrierFields = true
            this.$forceUpdate()
        },

        async getOpenShipments() {
            await axios.get(`${process.env.VUE_APP_API_TARGET}/shipping/shipments/${this.$globals.companyId}/${Date.now()- 60 * 24 * 60 * 60 * 1000}`,{
                headers: {
                    Authorization: `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response) => {
                this.openShipments = response.data.queryResult
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Shipping', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        showTrackingInfo(row){
            this.action = 'viewTrackingInfo'
            this.selectedShipmentTracker = row.tracker
        }

    }

}
</script>

<style scoped>
.vehicle-link {
  cursor: pointer;
  color: #dc3545;
}

.vehicle-link:hover {
  text-decoration: underline;
  color: #dc3545;
}
</style>

<template>
    <div>
        <div class="card card-danger" v-if="action === ''">
            <div class="card-header">
                <div class="card-title">
                    <h3><b>Asset History</b></h3>
                </div>
                <div class="card-tools">

                </div>
            </div>

            <div class="card-body">
                <div class="searchBar">
                    <input
                        type="text"
                        class="form-control w-100"
                        placeholder="Search by Work Order, Asset, or Technician"
                        v-model="search"
						@input="debounceSearch"
                    />
                </div>
                <b-table
                    ref="vehicleHistory"
                    id="vehicleHistory"
                    striped
                    @row-clicked="viewTicketDetails"
                    :busy="isBusy"
                    :sort-by="'start_date'"
                    :sort-desc="true"
                    :per-page="10"
                    :current-page="currentPage"
                    :filter-included-fields="[
                        'localid',
                        'vehicle',
                        'technician',
                    ]"
                    :filter="search"
                    :fields="[
                        { key: 'localid', label: 'Work Order #', sortable: true },
                        { key: 'technician' },
                        { key: 'start_date' },
                        { key: 'end_date' },
                        { key: 'status' },
                    ]"
                    :items="historyProvider">
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>

                    <template #cell(technician)="row">
                        <p v-for="tech in JSON.parse(row.item.technician)" :key="tech.name">
                            {{ tech.name }}
                        </p>
                    </template>

                </b-table>
				<b-pagination class="customPagination" v-model="currentPage" :per-page="10" :total-rows="parseInt(maxPages)*10" aria-controls="vehicleHistory" style="margin-left: 38.5%"></b-pagination>

            </div>
        </div>

        <div v-if="action === 'viewTicket'">
            <viewTicket :selectedTicket="selectedTicket" @return="handleTicketReturn" ></viewTicket>
        </div>

    </div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios'
import viewTicket from '../Service/viewTicket.vue'
import { debounce } from 'lodash'


export default {

    props:{
        selectedAsset: Object,
    },

    components: {
        viewTicket,
    },

    data() {
        return {
            action: "",
            currentPage: 1,
            isBusy: false,
            maxPages: 0,
            search: "",
            selectedTicket: {},
            vehicleHistory: []
        }
    },

	created(){
		this.debounceSearch = debounce(this.historyProvider, 600)
	},

    async mounted() {
		console.log("Asset History");
		if(this.selectedAsset.id == undefined){
			this.selectedAsset.id = this.selectedAsset.vehicleid
		}
    },

    methods: {
        handleTicketReturn(args){
            this.action = ''
            this.selectedTicket = null
            this.$forceUpdate()
        },

        async historyProvider(){
			let text = this.search === "" ? null : this.search
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/history/${encodeURIComponent(this.selectedAsset.id)}/${this.$globals.companyId}/${this.currentPage}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.vehicleHistory = response.data.queryResult.results;
				this.maxPages = response.data.queryResult.maxPages;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'assetHistory', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			return this.vehicleHistory
		},

        viewTicketDetails(row,index){
            this.selectedTicket = row;
            //this.action = 'viewTicket'
			this.$emit('viewTicket', this.selectedTicket)
        }
    }
}

</script>

<style>

</style>

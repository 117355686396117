<template>
	<div>
		<div class="modal-backdrop" v-show="isModalVisible">
			<div class = "modal">
				<div class = "modal-header">
					<h4>Special Order Details</h4>
					<b-button class="btn-danger" @click="closeModal()">X</b-button>
				</div>
				<div class="modal-body">
					<b-row>
						<b-col>
							<b>Part #</b>
						</b-col>
						<b-col>
							<input class="form-control" type="text" v-model="item.id" placeholder="Part #"/>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b>Description</b>
						</b-col>

						<b-col>
							<input class="form-control" type="text" v-model="item.desc" placeholder="Description"/>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b>Qty</b>
						</b-col>

						<b-col>
							<input class="form-control" type="number" v-model="item.qty" placeholder="Qty"/>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b>Vendor</b>
						</b-col>

						<b-col>
							<Multiselect :multiple="false" :show-labels="false" :options="allVendors" size=3 track-by="company" label="company" v-model="item.vendor" placeholder="Vendor"></Multiselect>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b>Cost</b>
						</b-col>

						<b-col>
							<input class="form-control" type="number" v-model="item.cost" placeholder="Cost"/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<b>Notes</b>
						</b-col>

						<b-col>
							<textarea id="notes" name="notes" style="width:100%; height:100%" v-model="item.notes" placeholder="Notes"></textarea>
						</b-col>
					</b-row>

				</div>
				<div class="modal-footer">
					<b-button type="button" class="btn-danger" @click="addSOPart()">Save</b-button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
/*eslint-disable*/

import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default{
	name: "app",
	props:{
		vendor: Object,
	},
	components: {
		Multiselect
	},

	data(){
		return{
			action: '',
			allVendors : [],
			item: {
				id: "",
				desc: "",
				qty: 0,
				cost: 0,
				sell: 0,
				notes: "",
				type: "Special",
				vendor: null,
				fulfillment: [{qty:0}],
				fulfilledQty: 0,
			},
			isModalVisible: true,
		};
	},
	async mounted(){
		if(this.$globals.companyId < 0){
			alert("Invlaid Company ID")
			this.closeModal();
		}
		if(this.$globals.user === null){
			alert("No User Passed")
			this.closeModal();
		}
		await axios
			.get(process.env.VUE_APP_API_TARGET + "/vendors/" + this.$globals.companyId,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.allVendors = response.data.queryResult;
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Special Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		if(this.vendor !== null){
			this.item.vendor = this.vendor
		}
	},
	methods:{
		async addSOPart(){
			//will NOT make a PO here (might be deleted or ticket not created)
			if(this.item.id === ''){
				alert("Enter a part number!");
				return;
			}
			else if(this.item.qty === 0 || this.item.qty === null){
				alert("Quantity required");
				return;
			}
			else if(this.item.desc === ''){
				alert("Description Required");
				return;
			}
			else if(this.item.vendor === null) {
				alert("Vendor must be selected here");
				return;
			}
			this.item.qty = parseInt(this.item.qty)
			this.item.cost = parseFloat(this.item.cost)
			if(this.item.sell === 0){
				await this.determineSell()
			}
			let item = this.item;
			this.$emit('addSOPart', item)
		},

		closeModal(){
			//emmits back with no data. Will remove item on return
			this.$emit('closeSO')
		},

		async determineSell(){
			if(!this.item.itemMarkup){
				//get vendor markup if no itemMarkup
				await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.item.vendor.company)}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				}).then((response)=>{
					this.item.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Special Order', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

			console.log("determineSell maths SpO ", this.item.cost, this.item.itemMarkup, this.item.vendorMarkup);
			this.item.sell = this.item.cost * (1 + (this.item.itemMarkup || this.item.vendorMarkup))
		},

	},
}


</script>
<style scoped>
.modal-backdrop {
    position: fixed;
    background-color: rgba(0,0,0,0.4);
    display: block;
    justify-content: center;
	opacity: 1;
	width: 100;
	height: 100;
  }

  .modal {
	background: #FFFFFF;
	overflow-y: auto;
	box-shadow: 2px 2px 20px 1px;
	opacity: 1;
	background-color: white;
	width: 40vw;
	height: 30vw;
	left: 30vw;
	display: block;
	position: fixed;
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }

  .modal-body {
	position: relative;
	padding: 20px 10px;
  }

</style>

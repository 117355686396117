<template>
	<div>
		<!-- Modal for single Item -->
		<div class="modal-backdrop" v-show="action === 'showItem'">
            <!-- :style="height=$refs.modalContent.style.height" -->
			<div :style="{'background-color' : backgroundColor}" class="modal" ref="itemModal" id="itemModal" tabindex="-1" role="dialog" >
					<!-- <div ref="modalContent" class="modal-content"> -->
						<div class="modal-header">
							<h5 class="modal-title" id="itemModalLabel"><b>Select Where To Pull '{{item.itemdesc}}'</b></h5>
							<button type="button" class="close" @click="reject()">
								<span>&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<b-row>
								<b-col class="d-flex justify-content-center" style="font-size: 18pt">
									<div v-show="subaction === ''">
										<b>Filled {{Math.abs(item.qty - numberPool)}} of {{item.qty}} </b>
										<i class="fas fa-edit" @click="subaction='editMax'"></i>
										<br>
										<label style="font-size: 14pt" for="warrantyCheckbox">Warranty Part:</label>
										<input id="warrantyCheckbox" type="checkbox" v-model="warrantyFlag" @change="changeWarranty()"/>

										<br>
									</div>
									<div v-show="subaction === 'editMax'">
										<input type="number" v-model="item.qty"/>
										<i class="fas fa-check" @click="subaction=''; calculateFulfill()"></i>
									</div>

								</b-col>
							</b-row>
							<b-row>
								<b-col><b>Location</b></b-col>
								<b-col style="text-align:center"><b>Bin</b></b-col>
								<b-col style="text-align:center"><b>Vendor</b></b-col>
								<b-col style="text-align:center"><b>Stock</b></b-col>
								<b-col><b>Filled Qty</b></b-col>
							</b-row>
							<hr>
							<div v-for="(part, index) in liveItem.otherLocations" :key="index" style="margin: 1%">
								<b-row v-if="!warrantyFlag">
									<b-col>{{part.location}}</b-col>
									<b-col style="text-align:center">{{part.bin.toUpperCase()}}</b-col>
									<b-col style="text-align:center">{{part.vendor}}</b-col>
									<b-col style="text-align:center">
										{{part.stock}}
										<!-- <span v-if="part.warrantyStock > 0">(+{{part.warrantyStock}})</span> -->
									</b-col>
									<b-col><input type="number" pattern="[0-9]{1,5}" class="form-control" min="0" :max="numberPool < part.stock ? (numberPool+part.qty) : part.stock" @input="calculateFulfill()" v-model="part.qty"></b-col>
								</b-row>
								<b-row v-else>
									<b-col>{{part.location}}</b-col>
									<b-col style="text-align:center">{{part.bin.toUpperCase()}}</b-col>
									<b-col style="text-align:center">{{part.vendor}}</b-col>
									<b-col style="text-align:center">
										{{part.warrantyStock}}
									</b-col>
									<b-col><input type="number" pattern="[0-9]{1,5}" class="form-control" min="0" :max="numberPool < part.warrantyStock ? (numberPool+part.qty) : part.warrantyStock" @input="calculateFulfill()" v-model="part.qty"></b-col>

								</b-row>
							</div>

						</div>
						<div class="modal-footer">
							<b-row>
								<!--TODO Purchase Order Button here abouts?-->
								<b-col>
									<button type="button" class="btn btn-danger" :disabled="0 > numberPool || numberPool > item.qty" @click="accept()">Confirm</button>
								</b-col>
							</b-row>
						</div>
					<!-- </div> -->
			</div>
		</div>

		<div class="modal-backdrop" v-show="action === 'showSpO'">
			<!-- :style="height=$refs.modalContent.style.height" -->
			<div class="modal" ref="itemModal" id="itemModal" tabindex="-1" role="dialog" :style="{'background-color' : backgroundColor}">
					<!-- <div ref="modalContent" class="modal-content"> -->
						<div class="modal-header">
							<h5 class="modal-title" id="itemModalLabel"><b>Fill Special Order '{{item.itemdesc}}'</b></h5>
							<button type="button" class="close" @click="reject()">
								<span>&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<b-row>
								<b-col class="d-flex justify-content-center" style="font-size: 18pt">
									<b>Filled {{Math.abs(item.qty - numberPool)}} of {{item.qty}}</b>
									<br>
								</b-col>
							</b-row>
							<b-row>
								<b-col><b>Filled Qty</b></b-col>
							</b-row>
							<hr>
							<b-row>
								<b-col><input type="number" pattern="[0-9]{1,5}" class="form-control" min="0" :max="item.qty" @change="calculateFulfill()" @input="calculateFulfill()" v-model="item.fulfillment[0].qty"></b-col>
							</b-row>

						</div>
						<div class="modal-footer">
							<b-row>
								<!--TODO Purchase Order Button here abouts?-->
								<b-col>
									<button type="button" class="btn btn-danger" :disabled="item.qty < numberPool < 0" @click="accept()">Confirm</button>
								</b-col>
							</b-row>
						</div>
					<!-- </div> -->
			</div>
		</div>


	</div>
</template>

<script>
/*eslint-disable*/

import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default{
	name: "app",
	props:{
		user: Object,
		companyId: Number,
		item: Object,
		displayClass: Boolean,
		prevAction: String

	},
	components: { Multiselect },
	data(){
		return{
			action: '',
			backgroundColor: 'white',
			locationOptions: [],
			liveItem: {},
			numberPool: 0,
			subaction: '',
			warrantyFlag: false,

		};
	},
	async mounted(){
		if(this.$globals.companyId < 0){
			alert("Invlaid Company ID")
			this.reject();
		}
		if(this.$globals.user === null){
			alert("No User Passed")
			this.reject();
		}
		if(this.item === null){
			alert("No Item Passed")
			this.reject()
		}

		this.backgroundColor = this.$globals.displayClass ? '#31373d' : 'white';
		this.item.qty = parseInt(this.item.qty)
		if(this.item.fulfillment == undefined){
			this.item.fulfillment = [{qty:0}];
		}

		if(this.item.warrantyFlag){
			this.warrantyFlag = this.item.warrantyFlag
		}
		else{
			//Set to false on else to account for undefined, this is semi-redundent
			this.item.warrantyFlag = false
			this.warrantyFlag =  false
		}

		console.log("item passed into locSelect: ",this.item);
		if(this.item.warranty_count == undefined){
			this.item.warranty_count = [{
				qty: 0,
				cost: 0,
				costDate: new Date().toISOString().split("T")[0]
			}]
		}
		this.item.warranty_count = typeof this.item.warranty_count == 'object' ? this.item.warranty_count : JSON.parse(this.item.warranty_count)
		if(this.item.itemid.includes('**')){
			if(this.item.itemid.includes('**KIT')){
				this.action = "showKit"
			}
			else if(this.item.itemid.includes('**SO**') || this.item.itemid.includes("**Special")){
				this.action = "showSpO"
                this.liveItem = this.item
                console.log("SPECIAL ORDER:", this.item)
                this.calculateFulfill()
				//this.item.fulfillment = [{qty : 0}]
			}
			else if(this.item.itemid.includes("Labor")){
				console.log("labor got through");
				this.reject()
			}
			else{
				console.log("Yikes we shouldn't allow this kind of name.");
				this.action = "showItem"

				await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(this.item.itemid)}/total/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then((response) =>{
						this.liveItem = response.data.queryResult[0];
						if(this.item.fulfillment.length >0){
							for(let index in this.item.fulfillment) {
								for(let i in this.liveItem.otherLocations){
									if(this.item.fulfillment[index].location === this.liveItem.otherLocations[i].location && this.item.fulfillment[index].bin === this.liveItem.otherLocations[i].bin){
										this.liveItem.otherLocations[i].qty = this.item.fulfillment[index].qty
									}
								}
							};

						}
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
			}

		}
		else{
			this.action = "showItem"

			await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(this.item.itemid)}/total/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) =>{
					this.liveItem = response.data.queryResult[0];
					if(this.item.fulfillment.length >0){
						for(let i in this.liveItem.otherLocations) {
							this.liveItem.otherLocations[i].qty = 0
							for(let index in this.item.fulfillment){
								if(this.item.fulfillment[index].location === this.liveItem.otherLocations[i].location && this.item.fulfillment[index].bin === this.liveItem.otherLocations[i].bin && this.item.fulfillment[index].vendor === this.liveItem.otherLocations[i].vendor){
									this.liveItem.otherLocations[i].qty = this.item.fulfillment[index].qty
									if(this.item.fulfillment[index].vendor == undefined){
										this.item.fulfillment[index].vendor = this.item.vendor
									}
								}
							}
						};
						this.$forceUpdate()
					}
					this.liveItem.otherLocations = this.liveItem.otherLocations.reverse()
					this.calculateFulfill()

				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}


        //calculated styling for modal
		let modal = document.getElementById('itemModal');
		let modalBody = document.getElementsByClassName('modal-content')[0]
        let dynamicHeight = this.liveItem.otherLocations.length > 3 ? this.liveItem.otherLocations.length < 8 ? 8 * (this.liveItem.otherLocations.length + 1) : 7 * (this.liveItem.otherLocations.length + 1) : this.liveItem.otherLocations.length === 3 ? 11 * (this.liveItem.otherLocations.length + 1) : 12.5 * (this.liveItem.otherLocations.length + 1)
        modal.style.maxHeight = `${dynamicHeight > 65 ? 65 : dynamicHeight}vh`
        this.$forceUpdate()
	},
	methods:{
		accept(){
			//redo logic so it always does new - fulfilledQty old and send the same data back
			this.locationOptions = []
			//Assures item.fulfillment data exists to check against
			if(this.item.fulfillment.length <= 0 && !this.item.itemid.includes('**')){
				this.item.fulfillment.push({
					bin: this.item.bin,
					itemid: this.item.itemid,
					location: this.item.location,
					vendor: this.item.vendor,
					qty: 0,
					usedCount: [],
				})
			}

			if(this.item.itemid.includes('**SO') || this.item.itemid.includes("**Special")){
				this.locationOptions.push({
					itemid: this.item.itemid,
					qty: parseInt(this.item.fulfillment[0].qty),
					cost: this.item.cost,
					fulfillment: this.item.fulfillment,
					itemdesc: this.item.itemdesc,
					locationFlag: this.item.locationFlag,
					notReceivedFlag: this.item.notReceivedFlag,
					sell: this.item.sell,
					type: this.item.type,
					vendor: this.item.vendor
				})
			}
			else{
				this.liveItem.otherLocations.forEach((data) => {
					if(data.qty === "" || data.qty == undefined){
						data.qty = 0
					}
					else{
						data.qty = parseInt(data.qty);
					}

					if(data.qty > 0 || this.item.fulfillment.length > 0){
						let usedCount = []
						let tempQtys = data.qty

						if(data.itemcount[0].qty === undefined || data.itemcount === null || data.itemcount === undefined || data.itemcount === [] || data.itemcount == '[]'){
							data.itemcount = [{
								qty: 0,
								cost: this.item.cost,
								costDate: new Date().toISOString().split("T")[0]
							}]
						}

						//generate usedCount
						while(tempQtys > 0){
							//sets data.itemcount to appropate number
							let mathInfoThing = parseInt(data.itemcount[0]?.qty) - tempQtys

							//if negitive or zero it pops off the itemcount instance to bring next forward and retries with excess qty
							if(mathInfoThing < 0){
								tempQtys = Math.abs(mathInfoThing)
								//data.itemcount[0].qty = Math.abs(data.itemcount[0].qty)
								if(this.warrantyFlag){
									usedCount.push(data.warranty_count.shift())
								}
								else{
									usedCount.push(data.itemcount.shift())
								}
							}
							//else there isn't any left to subtract
							else{
								if(this.warrantyFlag){
									usedCount.push({...data.warranty_count[0], qty: Math.abs(tempQtys) })
								}
								else{
									usedCount.push({...data.itemcount[0], qty: Math.abs(tempQtys) })
								}
								tempQtys = 0
							}
						}
						this.locationOptions.push({
							itemid: this.item.itemid,
							location: data.location,
							bin: data.bin,
							vendor: data.vendor,
							qty: parseInt(data.qty),
							usedCount: usedCount,
						})

						let changedQty, n = Number
						for(n in this.item.fulfillment){
							//is match with existing fulfillment
							if(this.item.fulfillment[n].location === data.location && this.item.fulfillment[n].bin === data.bin && this.item.fulfillment[n].vendor === data.vendor){
								//delta of new - old
								changedQty = parseInt(data.qty) -parseInt(this.item.fulfillment[n].qty)
								break;
							}
							else{
								changedQty = data.qty - 0
							}
						}

						//NOTE: there will be complications with adding back if it is a warranty item. This section is soon to be depercated by pick tickets and therefore pointless to adjust for warranty_count
						if(!this.warrantyFlag){
							if(changedQty < 0){
								//add back
								//need itemcount of the past (use fulfillment[x].usedCount) //usedCount is an array of former itemCount data
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(data.location)}/${encodeURIComponent(data.bin)}/${this.$globals.companyId}`,
								{
									qty: changedQty,
									vendor: data.vendor,
									addBack: JSON.stringify(this.item.fulfillment[n].usedCount)
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
							else if (changedQty > 0){
								//More parts added, subtract from Inventory
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(data.location)}/${encodeURIComponent(data.bin)}/${this.$globals.companyId}`,
								{
									vendor: data.vendor,
									qty: Math.abs(changedQty)
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
							else {
								console.log("No change ");
							}
						}
						else{
							if(changedQty < 0){
								//add back
								//need itemcount of the past (use fulfillment[x].usedCount) //usedCount is an array of former itemCount data
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(data.location)}/${encodeURIComponent(data.bin)}/${this.$globals.companyId}`,
								{
									qty: changedQty,
									vendor: data.vendor,
									addBack: JSON.stringify(this.item.fulfillment[n].usedCount)
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
							else if (changedQty > 0){
								//More parts added, subtract from Inventory
								axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(data.location)}/${encodeURIComponent(data.bin)}/${this.$globals.companyId}`,
								{
									vendor: data.vendor,
									qty: Math.abs(changedQty)
								},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
								.catch((response)=>{
									this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Location Selector', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
								})
							}
							else {
								console.log("No change ");
							}
						}

					}
				});

			}

			this.item.warrantyFlag = this.warrantyFlag;
			//Safety fill to stop empty
			if(this.locationOptions.length <=0 && !this.item.itemid.includes('**')){
				this.locationOptions.push({
					itemid: this.item.itemid,
					location: this.item.location,
					bin: this.item.bin,
					vendor: this.item.vendor,
					qty: 0
				})
			}
			//this.locationOptions is for fulfillment.
			this.$emit('accept', this.locationOptions);
		},

		calculateFulfill(){
			let totalFull = 0
			this.numberPool = this.item.qty

			if(this.liveItem.itemid.includes('**SO**')){
                totalFull = this.item.fulfilledQty
                if(this.item.fulfillment[0].qty > this.item.qty) this.item.fulfillment[0].qty = this.item.qty
			}
			else{
				for(let i in this.liveItem.otherLocations){
					if(this.liveItem.otherLocations[i].qty >0){
						if(parseInt(this.liveItem.otherLocations[i].qty) > parseInt(this.item.qty)){
							this.liveItem.otherLocations[i].qty = parseInt(this.item.qty)
						}
						if(this.warrantyFlag){
							if(parseInt(this.liveItem.otherLocations[i].qty) > parseInt(this.liveItem.otherLocations[i].warrantyStock)){
								//NOTE if this is a re-edit it causes problems use fulfillment to bypass this.
								if(this.item.fulfilledQty > 0){
									for(let w in this.item.fulfillment){
										if(this.item.fulfillment[w].location === this.liveItem.otherLocations[i].location && this.item.fulfillment[w].bin === this.liveItem.otherLocations[i].bin && parseInt(this.item.fulfillment[w].qty) > 0){
											this.liveItem.otherLocations[i].qty = parseInt(this.item.fulfillment[w].qty)
										}
									}
								}
								else{
									this.liveItem.otherLocations[i].qty = parseInt(this.liveItem.otherLocations[i].warrantyStock)
								}
							}
						}
						else{
							if(parseInt(this.liveItem.otherLocations[i].qty) > parseInt(this.liveItem.otherLocations[i].stock)){
								//NOTE if this is a re-edit it causes problems use fulfillment to bypass this.
								if(this.item.fulfilledQty > 0){
									for(let w in this.item.fulfillment){
										if(this.item.fulfillment[w].location === this.liveItem.otherLocations[i].location && this.item.fulfillment[w].bin === this.liveItem.otherLocations[i].bin && parseInt(this.item.fulfillment[w].qty) > 0){
											this.liveItem.otherLocations[i].qty = parseInt(this.item.fulfillment[w].qty)
										}
									}
								}
								else{
									this.liveItem.otherLocations[i].qty = parseInt(this.liveItem.otherLocations[i].stock)
								}
							}
						}


						totalFull = parseInt(totalFull) + parseInt(this.liveItem.otherLocations[i].qty)
					}
				}
			}
            this.numberPool = this.numberPool - totalFull
            this.$forceUpdate()
			//return totalFull;
		},

		changeWarranty(){
			this.liveItem.otherLocations.forEach((item, i) => {
				//if fulfilled do a return accordingly?
				if(item.qty > 0){
					item.qty = 0
				}
			});


			this.calculateFulfill()
		},

        reject(){
            this.action = ''
            this.$emit('reject',null)
            if(this.prevAction){
            	this.$emit("returnToPrevAction", this.prevAction)
            }
        }

	},
}


</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
	opacity: 1;
	width: 100vw;
	height: 100vh;
  }

  .modal {
	overflow: scroll;
    display: flex;
	box-shadow: 2px 2px 20px 1px;
	opacity: 1;
	width: 45vw;
    max-height: 65vh;
	left: 33vw;
	top: 15vh;
	display: block;
	position: fixed;
    border-radius: 20px
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }

  ::-webkit-scrollbar{
	  width: 0px
  }
</style>

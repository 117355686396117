<template>
	<div>
		<div v-if="action === 'viewItemDetails'" style="padding-bottom: 10px">
			<div class="card card-danger" style="width: 100%">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Item Details</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw;"
							v-if="!selectedItem.itemid.includes('**')"
							@click.prevent="action = 'moveInv'; getItemInfo(selectedItem)">
							Move
						</b-button>
						<b-button
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							@click.prevent="editPart(selectedItem)">
							Edit
						</b-button>
						<b-button
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							@click.prevent="$emit('return')">
							Return
						</b-button>
					</div>
				</div>
				<div class="card-body">
					<!-- Image -->
					<div style="display: flex">
						<div class="image-holder">
							<div
								class="image"
								style="display: flex"
								@click="showBar = !showBar"
							>
								<i class="fas fa-rotate-left"></i>
								<img
									class="item mr-3"
									v-if="selectedItem.image"
									v-show="!showBar"
									v-bind:src="selectedItem.image"
								/>

								<barcode
									id="printme"
									format="code128"
									:value="selectedItem.itemid"
									v-show="showBar"
									width="1"
									height="50"
									style="image-resolution: 300dpi; margin: auto">
								</barcode>
								<i
									class="fas fa-print"
									@click.stop="printBarcode()"
									v-show="showBar">
								</i>
							</div>
							<br>
							<div v-if="selectedItem.note !== null && selectedItem.note !== ''">
								<b>NOTE:</b> {{selectedItem.note}}
							</div>
						</div>
						<br />
						<div class="ml-5" style="border-left: 1px solid #d3d3d3; height: 300px"></div>
						<!-- Inventory View -->
						<div v-if="!selectedItem.itemid.includes('**')">
							<div class="ml-4" style="text-align: center; font-size: 10pt; width: 58vw">
								<div class="row" style="font-size: 18px">
									<div class="col">
										<div class="row">
											<div class="col-2" style="text-align: right">
												<strong >Part #: </strong>
											</div>
											<div class="col-8" style="text-align: left">
												{{selectedItem.itemid}}
											</div>
										</div>

										<div class="row">
											<div class="col-2" style="text-align: right">
												<strong >Description: </strong>
											</div>
											<div class="col-8" style="text-align: left">
												{{selectedItem.itemdesc}}
											</div>
										</div>

										<div class="row">
											<div class="col-2" style="text-align: right">
												<strong >Total Stock: </strong>
											</div>
											<div class="col-8" style="text-align: left">
												{{selectedItem.totalStock}}
												<span v-if="selectedItem.warrantyTotalStock >0"> &nbsp;(+{{selectedItem.warrantyTotalStock}})</span>
											</div>
										</div>

										<div class="row">
											<div class="col-2" style="text-align: right">
												<strong >Average Cost: </strong>
											</div>
											<div class="col-8" style="text-align: left">
												{{ formatNumberAsCurrency(selectedItem.cost) }}
											</div>
										</div>

										<div class="row">
											<div class="col-2" style="text-align: right">
												<strong >Sell: </strong>
											</div>
											<div class="col-7" style="text-align: left">
												{{ formatNumberAsCurrency(selectedItem.sell) }}
											</div>
											<div class="col-3">
												Sell Equation:
												<selectSell style="display: inline-block" :item="selectedItem" :customer="{}" :pastFun="null" @evaluatedSell="evaluatedSell"></selectSell>
											</div>
										</div>
									</div>

								</div>
								<hr/>
								<div class="row" style="display: flex; flex-direction: row">
									<div class="col-4 mt-5" v-for="(location, index) in locationGroup" :key="index">
										<b-row>
											<strong>Location: </strong>
											<br />
											<span class="ml-2">{{ location.location }}</span>
										</b-row>
										<b-row>
											<strong>Stock: </strong>
											<br />
											<span class="ml-2">{{ location.stock }}</span>
											<span v-if="location.warrantyStock > 0">&nbsp; (+{{location.warrantyStock}})</span>
										</b-row>
										<b-row v-if="location.threshold >0">
											<strong>Restock Point: </strong>
											<span class="ml-2">{{location.threshold}}</span>
										</b-row>
										<b-row>
											<strong>Bin: </strong>
											<br />
											<span class="ml-2">{{ location.bin.toUpperCase() }}</span>
										</b-row>
										<b-row>
											<strong>Vendor: </strong>
											<br />
											<span class="ml-2">{{ location.vendor }}</span>
										</b-row>
										<b-row v-show="parseInt(location.threshold) >= parseInt(location.stock)">
											<!-- TODO add button to make a Purchase Order at this location. -->
											<b-button style="background: #dc3545; border: #dc3545" @click="action='createPO'">Purchase Order</b-button>
										</b-row>
									</div>
									<wbr>
								</div>
							</div>

						</div>
						<!-- Kit Data -->
						<div v-else>
							<div class="ml-4" style="text-align: center; font-size: 10pt; width: 58vw">
								<div class="row">
									<div class="col">
										<div class="row">
											<div class="col-3 text-right">
												<strong>Kit #:</strong>
											</div>
											<div class="col-8 text-left">
												{{selectedItem.itemid}}
											</div>
										</div>
										<div class="row">
											<div class="col-3 text-right">
												<strong>Description:</strong>
											</div>
											<div class="col-8 text-left">
												{{selectedItem.itemdesc}}
											</div>
										</div>

										<div class="row">
											<div class="col-3 text-right">
												<strong>Estimated Sell:</strong>
											</div>
											<div class="col-8 text-left">
												${{kitTotal.toFixed(2)}}
											</div>
										</div>
									</div>
								</div>
								<hr>
								<b-row>
									<b-col><strong>Qty</strong></b-col>
									<b-col><strong>Part #</strong></b-col>
									<b-col><strong>Description</strong></b-col>
									<b-col><strong>Avg Cost Each</strong></b-col>
									<b-col><strong>Sell Each</strong></b-col>
								</b-row>

								<b-row v-for="(item, index) in selectedItem.components" :key="index">
									<b-col>{{ item.qty }}</b-col>
									<b-col v-if="item.part !== undefined">{{ item.part.itemid}}</b-col>
									<b-col v-if="item.part !== undefined">{{ item.part.itemdesc}}</b-col>
									<b-col v-if="item.part !== undefined">${{ parseFloat(item.part.cost).toFixed(2)}}</b-col>
									<b-col v-if="item.part !== undefined">${{ parseFloat(item.part.sell).toFixed(2)}}</b-col>
								</b-row>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		<!-- Edit Inventory Section -->
		<div v-if="action === 'editPart'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Edit Item Details</b></h3>
					</div>
					<div class="card-tools">
						<div style="float: right; margin-top: -2.5%">
							<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw;" @click.prevent="updatePart(selectedItem);">
								Submit
							</b-button>
							<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw;" @click.prevent="action = 'viewItemDetails'">
								Return
							</b-button>
						</div>
					</div>
				</div>
				<div class="card-body">
					<div class="part-data">
						<div style="display: flex">
							<br />
							<div class="image-holder col-3">
									<div class="image" style="display: flex">
										<div v-if="selectedItem.image != ''" style="margin-left: 10%; margin-right: 10%">
											<img class="item" v-bind:src="addImage" style="height: 200px; width: 200px;"/>
											<br>
											<input
												class="form-control form-control-file w-75"
												id="formFileLg"
												type="file"
												accept="image/*"
												@change="uploadPartImage($event)"
												name="picture"
											/>
										</div>
									</div>
									<br>
									<label>Note </label>
									<textarea class="form-control" style="height:10vh" v-model="addNote"></textarea>
								</div>

				<!--Edit Kit -->
				  <div
					v-if="selectedItem.itemid.charAt(0) == '*'"
					style="justify-content: center;"
					class="col-9">
					  <b-col class="flex-col" style="border-left: 1px solid #d3d3d3; font-size: 16px">
						<b-row>
							<b-col style="text-align: left">
								<strong>Kit Type:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong>
							</b-col>
							<b-col>
								<select
									required
									ref="typeSelect"
									name="newType"
									v-model="newKitType"
									style="height: 50px; width: 400px"
								>
								<option value="Part">Part</option>
								<option value="Equipment">Equipment</option>
								</select>
							</b-col>
						</b-row>
						<b-row>
						  <b-col style="text-align: left"
							><strong>Kit ID: <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong></b-col
						  >
						  <b-col>
							<input
								type="text"
								class="form-control"
								v-model="newKitId"
								:placeholder="selectedItem.itemid"
								style="height: 50px; width: 400px"
								required
							/>
						</b-col>
						</b-row>
						<b-row>
						  <b-col style="text-align: left"
							><strong>Description: <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong></b-col
						  >
						  <b-col>
							<input
								required
								type="text"
								class="form-control"
								v-model="newKitDesc"
								:placeholder="selectedItem.itemdesc"
								style="height: 50px; width: 400px"
							/>
						</b-col>
						</b-row>
						<hr>
						<b-row>
							<b-col style="width: 30vw">
								<h5><strong>Components:</strong></h5>
							</b-col>
						</b-row>

						  <!-- COMPONENT LIST -->
						<div>
							<!--Headers row-->
							<div class="row">
								<div class="col" style="text-align: left">
									<strong>Qty</strong>
								</div>
								<div class="col" style="text-align: left">
									<strong>Part # </strong>
								</div>
								<div class="col" style="text-align: left">
									<strong>Description</strong>
								</div>
								<div class="col"> </div>
							</div>
							<!-- Existing Components -->
							<div class="row" v-for="(component, index) in kitComponents" :key="index">
								<div class="col-2" style="text-align: left">
									<input class="form-control w-75" type="number" min="0" v-model="component.qty">
								</div>
								<div class="col-3" style="text-align: center">
									{{component.itemid}}
								</div>
								<div class="col-5" style="text-align: left">
									{{component.itemdesc}}
								</div>
								<div class="col-1" style="text-align: right">
									<i class="fas fa-minus-circle" style="margin-top: 2.5%" @click="removeKitPart(component)"></i>
								</div>
							</div>

							<!--New Component Row -->
							<div class="row">
								<div class="col-2" style="text-align: left">
									<input class="form-control w-75" type="number" name="partQuantity" min="0" v-model="kitPartQty"/>
								</div>
								<div class="col-8">
									<multiselect
										:clearable="true"
										:show-labels="false"
										:preserveSearch="true"
										:options="allParts"
										:group-select="false"
										:custom-label="({itemid, itemdesc}) => {
											if(itemid && itemdesc) return `${itemid} - ${itemdesc}`
										}"
										group-label="name"
										group-values="items"
										v-model="kitPart"
										:multiple="false"
										track-by="itemid"
										label="itemdesc"
										placeholder="Item"
										class="w-75"
										@search-change="debounceParts"
										>
										<template slot="option" slot-scope="props">
											<intersect v-if="props.option.itemid === partKitQuery[partKitQuery.length - 1].itemid" @enter="getMoreInventory">
												<span>{{props.option.itemid}} - {{props.option.itemdesc}}</span>
											</intersect>
										</template>
									</multiselect>

								</div>
								<div class="col-1" style="text-align: right">
									<i class="fas fa-plus-circle" style="margin-top: 2.5%"  @click="addNewKitPart(kitPart)"></i>
								</div>

							</div>

						</div>

					  </b-col>

				  </div>

				<!--Edit Item-->
				<div v-if="selectedItem.itemid.charAt(0) !== '*'"
					style="
						text-align: center;
						justify-content: center;
						margin-left: 5%;
						font-size: 9pt;"
					class="col-9">
					<b-row>
						<b-col style="border-left: 1px solid #d3d3d3; font-size: 16px">
							<b-row>
								<b-col>
									<div>
										<b-row>
											<label for="location" style="margin-left: 1vw">Location:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
										</b-row>
										<b-row id="location">
											<multiselect
												required
												:clearable="true"
												:show-labels="false"
												:options="namedPartLocations"
												v-model="editLocation"
												:multiple="false"
												placeholder="Location"
												style="height: 50px; width: 300px; margin-left: 1.25vw; display: inline-block"
												@select="getBins">
											</multiselect> &nbsp;
											<i class="fas fa-edit"
												size="sm"
												@click.stop="fixLocation()"
												style="margin-left: 1vw; margin-top: 0.5vw">
											</i>
										</b-row>
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left; margin-top:2%">
									<strong>Bin:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </strong>
								</b-col>
								<b-col>
									<multiselect
										required
										:clearable="true"
										:show-labels="false"
										:options="binList"
										v-model="newItemBin"
										:custom-label="({bin,vendor})=>{return `${bin} from ${vendor}`}"
										:multiple="false"
										placeholder="Bin"
										style="height: 50px; width: 400px; margin-left: 2%; margin-top: 0.2%"
										@select="getLocalData">
									</multiselect>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Type: <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong>
								<br>
									<multiselect
										required
										ref="typeSelect"
										v-model="newItemType"
										:options = "['Part', 'Equipment']"
										:show-labels="false"
										:clearable = "false"
										:multiple="false"
										placeholder = "Select Type"
										class="w-100"
										>
									</multiselect>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Part #:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </strong>
								</b-col>
								<b-col>
									<input
										required
										type="text"
										class="form-control"
										v-model="newItemId"
										:placeholder="selectedItem.itemid"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Description: <span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong>
								</b-col>
								<b-col>
									<input
										required
										type="text"
										class="form-control"
										v-model="newItemDesc"
										:placeholder="selectedItem.itemdesc"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Local Stock:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span> </strong>
								</b-col>
								<b-col>
									<input
										required
										type="number"
										class="form-control"
										v-model="newLocalCount"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Local Warranty Count: </strong>
								</b-col>
								<b-col>
									<input
										required
										type="number"
										class="form-control"
										v-model="newWarrantyCount"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Purchase Threshold: </strong>
								</b-col>
								<b-col>
									<input
										class="form-control"
										type="text"
										v-model="newThreshold"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Re-Stock Up To</strong>
								</b-col>
								<b-col>
									<input
										class="form-control"
										type="text"
										v-model="newRestockUpTo"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Vendor:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong>
								</b-col>
								<b-col style="text-align: right">
									<multiselect
										ref="vendorSelector"
										v-model="currentVendor"
										:options="allVendors"
										label="company"
										track-by="company"
										:show-labels="false"
										placeholder="Select Vendor"
										:clearable="false"
										style="height: 50px; width: 400px"
										:searchable = "true"
										@search-change="searchVendors"
										:loading="vendorListIsLoading"
										>
										<template slot="option" slot-scope="props">
											<intersect v-if="props.option.company === allVendors[allVendors.length - 1].company" @enter="getMoreVendors">
												<span>{{ props.option.company }}</span>
											</intersect>
										</template>
									</multiselect>

								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Vendor Part #:</strong>
								</b-col>
								<b-col>
									<input
										type="text"
										class="form-control"
										v-model="newVendorPartNumber"
										:placeholder="selectedItem.vendorPartNumber ? selectedItem.vendorPartNumber : '' "
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Cost:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></strong>
								</b-col>
								<b-col>
									<input
										required
										type="number"
										class="form-control"
										v-model="newItemCost"
										step="0.01"
										min="0"
										:placeholder="'0'"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Sell Markup:</strong>
								</b-col>
								<b-col>
									<input
										type="number"
										step="1"
										class="form-control"
										v-model="newItemSell"
										:placeholder="'% of cost'"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>List:</strong>
								</b-col>
								<b-col>
									<input
										type="number"
										step="0.01"
										class="form-control"
										v-model="newItemList"
										:placeholder="'$'"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
							<b-row>
								<b-col style="text-align: left">
									<strong>Discount %:</strong>
								</b-col>
								<b-col>
									<input
										type="text"
										class="form-control"
										v-model="newVendorDiscount"
										style="height: 50px; width: 400px"
									/>
								</b-col>
							</b-row>
						</b-col>
						<b-col style="border-left: 1px solid #d3d3d3; font-size: 16px">
							<b-row v-for="(location, index) in itemGroup" :key="index">
								<b-col style="margin-left: 1vw">
									<b-row>
										<strong>Location: </strong>
										{{ location.location }}
									</b-row>
									<b-row>
										<strong>Stock: </strong>
										{{ location.stock }}
										<span v-if="location.warrantyStock > 0">(+{{location.warrantyStock}})</span>
									</b-row>
									<b-row>
										<strong>Bin: </strong>
										{{ location.bin.toUpperCase() }}
									</b-row>
									<b-row>
										<strong>Vendor: </strong>
										{{ location.vendor }}
									</b-row>
									<b-row><hr /></b-row>
								</b-col>
								<b-col v-if = "location.stock === 0 && location.warrantyStock === 0">
									<b-row></b-row>
									<b-row v-if="location.stock == 0" style="margin-left: 5%; margin-top: 2vw; width 1vw">
										<i class="fas fa-trash"
											@click="removeLocation(location.location,location.bin, location.stock, location.warrantyStock, location.vendor)">
										</i>
									</b-row>
									<b-row v-else></b-row>
									<b-row></b-row>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-button type="button" @click.prevent="newInventoryLocation(editLocation)">Add New Location</b-button>
								</b-col>
							</b-row>
					  </b-col>
					</b-row>
					<hr style="width: 45vw" />
				</div>

				</div>
			  </div>
			</div>
		  </div>
		</div>

		<!-- Move inventory component -->
		<div v-if="action === 'moveInv'">
			<movePanel
				:item="selectedItem"
				@reject="action = 'viewItemDetails'"
				@accept="handleSelection(selectedItem)" >
			</movePanel>
		</div>

		<!-- Edit a Location -->
		<div v-if="action === 'editLocation'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Edit Location</b></h3>
					</div>
					<div class="card-tools">
						<b-button
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							@click.prevent="updateLocation()"
							>Update Location
						</b-button>
						<b-button
							style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
							@click.prevent="action = 'editPart'"
							>Return
						</b-button>
					</div>
				</div>
				<div class="card-body d-flex">
					<form class="m-auto">
						<input
						type="text"
						class="form-control w-100"
						v-model="newLocation.name"
						placeholder="Location Name"
						/>
						<multiselect
						ref="locTypeSelector"
						class ="w-100"
						:max-height="200"
						:clearable="true"
						:show-labels="false"
						:searchable="false"
						:options="locationTypeList"
						v-model="newLocation.type"
						placeholder="Type of Location"
						></multiselect>

					</form>
				</div>
			</div>
		</div>

		<!-- Adds new Bin @ Location of all Locations -->
		<div v-if="action === 'addBinLocation'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Add New Bin Location</b></h3>
					</div>
					<div class="card-tools">
						<div style="float: right; margin-top: -2.5%">
							<b-button
								type="submit"
								id="addBinLocationSubmitButton"
								@click.prevent="addBinLocation();"
								style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw;"
								>Submit
							</b-button>
							<b-button
								type="cancel"
								id="addBinLocationCancelButton"
								@click.prevent="editPart(selectedItem)"
								style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw;">
								Return
							</b-button>
						</div>
					</div>
				</div>
				<div class="card-body">
					<b-row style="margin-left: 15vw; width: 45vw">
						<b-col style="width: 10vw"><b>New Location:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></b-col>
						<b-col style="width: 10vw">
							<multiselect
								required
								ref="locationSelector"
								label="name"
								track-by="name"
								:max-height="200"
								:clearable="true"
								:searchable="true"
								:options="allLocations"
								v-model="location"
								placeholder="Location"
								class="w-75"
								display: inline
								:show-labels="false"
								@select="getDistinctBins"
								style="height: 50px; width: 300px; display: inline-block">
							</multiselect>
							<i
								class="fas fa-plus-circle text-left fa-lg"
								@click="action = 'createLocation'; lastAction = 'addBinLocation'"
								style="display: inline; margin-top: 2.5%">
							</i>
						</b-col>
					</b-row>
					<b-row style="width: 45vw; margin-left: 15vw; margin-top: 1vh">
						<b-col style="width: 10vw"><b>New Bin:<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></b></b-col>
						<b-col style="width: 10vw">
							<multiselect
								required
								v-model="newBin"
								:options = "binList"
								:show-labels="false"
								placeholder="Bin">
							</multiselect>
							<div v-if="newBin.includes('**NEW**')">
								<input type="text" class="form-control w-75" v-model = "tempBin" placeholder="New Bin" style="display: inline"/>
								<i class="fas fa-plus-circle text-left fa-lg" @click="newBin = tempBin; tempBin = ''" style="display: inline; margin-top: 2.5%">
								</i>
							</div>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>

		<!-- Make a New Location -->
		<div v-if="action === 'createLocation'">
		  <div class="card card-danger">
			<div class="card-header">
			  <div class="card-title">
				<h3><b>New Location</b></h3>
			  </div>
				<div class="card-tools">
					<b-button
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						@click.prevent="addLocation()"
					>Add Location
					</b-button>
					<b-button
						style="background-color: #fff4; border-color: #dc3545; margin-left: 1vw"
						@click.prevent="action = lastAction"
					>Return
					</b-button>
				</div>
			</div>
			<div class="card-body d-flex">
			  <form class="m-auto">
				<input
				  type="text"
				  class="form-control w-100"
				  v-model="newLocation.name"
				  placeholder="Location Name"
				/>
				<label>Type of Location</label>
				<multiselect
					ref="locTypeSelector"
					class ="w-100"
					:max-height="200"
					:clearable="true"
					:show-labels="false"
					:searchable="false"
					track-by="key"
					:options="locationTypeList"
					v-model="newLocation.type"
					placeholder="Type of Location"
				></multiselect>
				<br />
			  </form>
			</div>
		  </div>
		</div>

		<!-- CREATE PO -->
		<div v-if="action === 'createPO'">
			<newPO :vendor="selectedItem.vendor" :orderType="null" :items="[selectedItem]" :orderID="null" :taskOptions="null"
				@returnFromNewPO="getItemInfo(selectedItem); action = 'viewItemDetails'">
			</newPO>
		</div>





	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import VueBarcode from "vue-barcode";
import movePanel from './move.vue'
import "print-js";
import { debounce } from 'lodash';
import Intersect from 'vue-intersect'
import selectSell from '../SellFunctions/selectSellFunction.vue'
import newPO from "../Sales/newPurchaseOrder.vue"


var _ = require('lodash');

export default{
	name: 'viewItem',
	props:{
		selectedItem: Object
	},

	components: { Multiselect, movePanel, barcode: VueBarcode, Intersect, selectSell, newPO},

	data(){
		return{
			action: 'viewItemDetails',
			addImage: '',
			addNote: '',
			allParts: [
				{
					name: 'Part',
					items: []
				},
				{
					name: 'Equipment',
					items: []
				},
				{
					name: 'Kit',
					items: []
				},
				{
					name: 'Unset',
					items: []
				}
			],
			allVendors: [],
			allLocations: [],
			binList: [],
			currentVendor: '',
			editLocation: '',
			itemGroup: [],
			kitTotal: 0,
			kitComponents: [],
			kitPart: '',
			kitPartQty: 0,
			locationTypeList: [
				'Warehouse',
				'Truck'
			],
			lastAction: '',
			locationGroup: [],
			maxPagesVendors: 0,
			maxPages: 0,
			newBin: '',
			newKitType: '',
			newKitId: '',
			newKitDesc: '',
			newItemType: '',
			newItemId: '',
			newItemBin: '',
			newItemCost: 0,
			newItemDesc: '',
			newItemList: 0,
			newItemSell: 0,
			newLocalCount: 0,
			newThreshold: 0,
			newRestockUpTo: 0,
			newLocation: {
				name: "",
				type: ""
			},
			newVendorDiscount: '',
			newWarrantyCount: '',
			namedPartLocations: [],
			newVendorPartNumber: '',
			oldLocalStock: '',
			oldWarrantyCount: '',
			partKitQuery: [],
			pageNumber: 0,
			pageNumberVendors: 0,
			prevSearchVendor: '',
			showBar: false,
			tempBin: '',
			vendorListIsLoading: false
		}
	},

	created(){
        this.debounceParts = debounce(this.searchPart, 600);
		this.getItemInfo(this.selectedItem)
        this.$globals.socket.emit("joinRoom", `item${this.selectedItem.itemid}-${this.$globals.companyId}`);
        this.$globals.socket.on("newDataCreated", (args) => {
            this.getItemInfo(this.selectedItem)
            this.getInventory()
            this.$forceUpdate()
        })
	},

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `item${this.selectedItem.itemid}-${this.$globals.companyId}`)
		console.log("destroyed");
    },

	async mounted(){
		this.getVendors()
		this.getInventory()
		//await this.getItemInfo(this.selectedItem)


		//get all locations
		axios
		.get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then((response) => {
			this.allLocations = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
		this.action = 'viewItemDetails'
	},

	methods:{
		addNewKitPart(newItem){
			if(newItem === null || newItem === undefined){
				alert("No item selected")
				return;
			}
			if(this.kitPartQty < 1){
				this.kitPartQty = 1
			}

			if(newItem.itemid.includes('**KIT')){
				newItem.components = typeof newItem.components === 'object' ? newItem.components : JSON.parse(newItem.components)
				newItem.components.forEach((item, i) => {
					let hold = this.kitPartQty
					this.addNewKitPart(item)
					this.kitPartQty = hold
				});
				this.kitPartQty = 0
			}
			else {
				let flag = -1;
				for (let comp in this.kitComponents) {
					if (this.kitComponents[comp].itemid === newItem.itemid) {
						this.kitComponents[comp].qty = parseInt(this.kitComponents[comp].qty) + parseInt(this.kitPartQty * (newItem.qty || 1));
						flag = comp;
						break;
					}
				}
				if(flag === -1){
					let tempQty = 1
					if(newItem.qty){
						tempQty = newItem.qty * this.kitPartQty
					}
					else{
						tempQty = this.kitPartQty
					}
					this.kitComponents.push({
						qty: tempQty,
						itemid: newItem.itemid,
						itemdesc: newItem.itemdesc
					});
				}

				this.kitPart = null;
				this.kitPartQty = 0;
			}
		},

		async addBinLocation(){
			//create new inv item with new location and bin
			//Safety Checks
			if(this.location == '' || this.location?.name === undefined || this.location.name == ""){
				alert("Pick a location")
				return;
			}
			if(this.newBin == null || this.newBin === "" || this.newBin.includes('**')){
				if(this.tempBin !== ""){
					this.newBin = this.tempBin
					this.tempBin = ""
				}
				else{
					alert("Bin was not filled in, set to Unassigned")
					this.newBin = 'UNASSIGNED'
					return;
				}
			}

			let itemcount = [{
				cost: parseFloat(this.selectedItem.cost),
				costDate: new Date().toISOString().split("T")[0],
				qty: 0
			}]
			await axios.post(
				process.env.VUE_APP_API_TARGET + "/inventory/" + this.$globals.companyId,
				{
					id: this.selectedItem.itemid,
					desc: this.selectedItem.itemdesc,
					count: JSON.stringify(itemcount),
					location: this.location.name,
					bin: this.newBin.toUpperCase(),
					threshold: 0,
					cost: this.selectedItem.cost,
					itemMarkup: this.selectedItem.itemMarkup,
					vendor: this.selectedItem.vendor,
					image: this.selectedItem.image,
					type: this.selectedItem.type,
					list: this.selectedItem.list,
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.selectedItem.otherLocations.push({
					bin: this.newBin.toUpperCase(),
					location: this.location.name,
					stock: 0
				})

				await this.getItemInfo(this.selectedItem)
				this.editPart(this.selectedItem)
				if(this.editLocation == this.location.name){
					this.getBins(this.location.name)
				}
				//this.action = 'editPart';
				this.location = ""
				this.newBin = ""
				this.tempBin = ""

			},

		async addLocation() {
			if (this.newLocation.name == ""){
				alert("Please included a location name")
				return;
			}
			if (this.newLocation.type == "" || this.newLocation.type == null){
				alert("Please include a location type")
				return;
			}

			await axios.post(process.env.VUE_APP_API_TARGET + "/inventory/locations/" + this.$globals.companyId,
				{
					name: this.newLocation.name,
					type: this.newLocation.type
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			await axios
			  .get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response) => {this.allLocations = response.data.queryResult})
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
			this.action = this.lastAction;
			this.newLocation= {
				name: "",
				type: ""
			};
		},

		createGroups(){
			this.allParts = [];
			var partItems = [];
			var equipItems = [];
			var kitItems = [];
			var unsetItems = [];
			for(let part in this.partKitQuery){
				if(this.partKitQuery[part].type == 'Part'){
					partItems.push(this.partKitQuery[part]);
				}
				else if(this.partKitQuery[part].type == 'Equipment'){
					equipItems.push(this.partKitQuery[part]);
				}
				else if(this.partKitQuery[part].type == 'Kit'){
					kitItems.push(this.partKitQuery[part]);
				}
				else if(this.partKitQuery[part].type == 'Unset'){
					unsetItems.push(this.partKitQuery[part]);
				}
			}
			//Removed Special Order from this group
			this.allParts.push({name: 'Kit', items: kitItems});
			this.allParts.push({name: 'Part', items: partItems});
			this.allParts.push({name: 'Equipment', items: equipItems});
			this.allParts.push({name: 'Unset', items: unsetItems});
		},

		combineByLocation(){
			this.locationGroup = _.cloneDeep(this.itemGroup)
			for(let i in this.locationGroup){
				for(let j = Number(i); j<this.locationGroup.length; j++){
					if(this.locationGroup[i].location.trim() == this.locationGroup[j].location.trim() && this.locationGroup[i].bin.trim() == this.locationGroup[j].bin.trim() && Number(i) !== Number(j)){
						this.locationGroup[i].stock = this.locationGroup[i].stock + this.locationGroup[j].stock;
						this.locationGroup[i].vendor = this.locationGroup[i].vendor + `, ${this.locationGroup[j].vendor}`;
						this.locationGroup[i].warrantyStock = this.locationGroup[i].warrantyStock + this.locationGroup[j].warrantyStock
						this.locationGroup.splice(j,1)
					}
				}
			}
		},

		evaluatedSell(arg){
			this.$nextTick(()=>{
				this.selectedItem.sell = arg.sell
				this.$forceUpdate()
			})
		},

		async determineSell(){
			if(!this.selectedItem.itemMarkup){
				//get vendor markup if no itemMarkup
				await axios.get(process.env.VUE_APP_API_TARGET + `/vendors/company/${encodeURIComponent(this.selectedItem.vendor)}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				}).then((response)=>{
					this.selectedItem.vendorMarkup = response.data.queryResult[0]?.markup || 0.1
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			this.selectedItem.sell = (this.selectedItem.highCost || this.selectedItem.cost) * (1 + (this.selectedItem.itemMarkup || this.selectedItem.vendorMarkup))
		},

		editPart(row) {
			this.addImage = row.image;
			this.addNote = row.note;
			if(row.itemid.charAt(0) !== "*"){
				this.editLocation = row.location;
				this.newItemBin = row.bin;
				this.newItemType = row.type;
				this.newItemId = row.itemid;
				this.newItemDesc = row.itemdesc;
				this.newThreshold = row.threshold;
				this.newRestockUpTo = row.restockUpTo;
				this.currentVendor = {company:row.vendor};
				this.newVendorPartNumber = row.vendorpartnumber || '';
				this.newItemCost = parseFloat(row.itemcount[row.itemcount.length-1].cost);
				this.newItemSell = (row.itemMarkup * 100) || null ;
				this.newItemList = row.list;
				this.newVendorDiscount = row.vendordiscount;

				this.getPartLocations(row.itemid);
				this.getBins(row.location)
			}
			else{
				this.newKitType = row.kit_type;
				this.newKitId = row.itemid;
				this.newKitDesc = row.itemdesc;
				this.kitComponents = _.cloneDeep(row.components)
			}
			this.action = 'editPart'
		},

		async fixLocation(){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/locations/location/${this.editLocation}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					this.newLocation.name = response.data.queryResult[0].name
					this.newLocation.type = response.data.queryResult[0].loctype
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			this.action= 'editLocation'
		},

		formatNumberAsCurrency(number) {
		  var formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",

			// These options are needed to round to whole numbers if that's what you want.
			minimumFractionDigits: 2,
			//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
		  });
		  return formatter.format(number);
		},

		async getInventory(){
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${null}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response) => {
				this.partKitQuery = response.data.queryResult.results;
				this.maxPages = response.data.queryResult.maxPages;
				this.allPartTypes = response.data.queryResult.types;
				response.data.queryResult.results.forEach((item, index) => {
					if (item.itemid.charAt(0) !== "*") {
						var totalStock = 0;
						let warrantyTotalStock = 0;
						item.otherLocations?.forEach((location) => {
							totalStock += location.stock;
							warrantyTotalStock += location.warrantyStock
						});
						item.totalStock = totalStock;
						item.warrantyTotalStock = warrantyTotalStock
					}
				});
				this.pageNumber = this.pageNumber+1
				this.createGroups()
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		getBins(locName){
			//Executes when edit location is changed. Grabs bins under locName
			this.editLocation = locName;
			this.binList = [];
			for(let loc in this.selectedItem.otherLocations){
				if(this.selectedItem.otherLocations[loc].location == locName){
					this.binList.push({bin: this.selectedItem.otherLocations[loc].bin.toUpperCase(), vendor: this.selectedItem.otherLocations[loc].vendor});
				}
			}
			this.newItemBin = this.binList[0]
			this.getLocalData(this.newItemBin)
		},

		async getPartLocations(itemnum) {
			this.namedPartLocations = []
			//namedPartLocations is used to group otherLocations of selectedItem by location
			//selectedItem.otherLocations has every Location+Bin pair as seprate entires

			var itemid = encodeURIComponent(itemnum);
			var tempLocations = [];
			await axios
			.get(process.env.VUE_APP_API_TARGET + "/inventory/item/" +itemid +"/" +this.$globals.companyId,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				tempLocations = response.data.queryResult[0].otherLocations
				this.selectedItem.otherLocations = tempLocations
				for(let loc in tempLocations){
					if(!this.namedPartLocations.includes(tempLocations[loc].location)){
						this.namedPartLocations.push(tempLocations[loc].location)
					}
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getItemInfo(record) {
			//console.log("getItemInfo: ", record, !record.itemid.includes('**'));
			//this.selectedItem = record
		  if (!record.itemid.includes("**")) {
			await axios
			  .get(
				`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(record.itemid)}/total/${this.$globals.companyId}`,
				  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			  )
			  .then((response) => {
				var count = 0;
				var warrantyTotalStock = 0;

				for(let i in response.data.queryResult[0].otherLocations){
					count = count + parseInt(response.data.queryResult[0].otherLocations[i].stock)
					warrantyTotalStock += parseInt(response.data.queryResult[0].otherLocations[i].warrantyStock)
				}

				this.itemGroup = response.data.queryResult[0].otherLocations;
				this.selectedItem = response.data.queryResult[0];
				//this.determineSell();
				this.combineByLocation()
				this.selectedItem.totalStock = count ?? 0;
				this.selectedItem.warrantyTotalStock = warrantyTotalStock ?? 0;
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
		  }
		  else {
			await axios
				.get(
					`${process.env.VUE_APP_API_TARGET}/inventory/kit/${record.itemid}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.then(async (response) => {
					this.kitTotal = 0;
					this.selectedItem = response.data.queryResult[0]
					this.selectedItem.components = JSON.parse(response.data.queryResult[0].components);
					for(let item in this.selectedItem.components){
						//this.selectedItem.components[item].part = null
						this.selectedItem.components[item].part = (await this.getTargetItem(this.selectedItem.components[item].itemid))
						//console.log("IT EXISTS: ", this.selectedItem.components[item].part);
						this.kitTotal = this.kitTotal + (parseInt(this.selectedItem.components[item].qty) * parseFloat(this.selectedItem.components[item].part?.sell))
					}
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
		  }
		  if (!this.selectedItem.image) {
			this.selectedItem.image =
			  "https://fleet4fire-official.s3.us-east-2.amazonaws.com/1636573467842";
		  }
		  this.$forceUpdate();
		},

		async getLocalData(bin){
			for(let index in this.itemGroup){
				if(this.itemGroup[index].bin.toUpperCase() === bin.bin.toUpperCase() && this.itemGroup[index].location === this.editLocation && this.itemGroup[index].vendor === bin.vendor){
					this.newLocalCount = parseInt(this.itemGroup[index].stock ?? 0),
					this.oldLocalStock = parseInt(this.itemGroup[index].stock ?? 0),
					this.newWarrantyCount = parseInt(this.itemGroup[index].warrantyStock ?? 0),
					this.oldWarrantyCount = parseInt(this.itemGroup[index].warrantyStock ?? 0),
					this.newThreshold = this.itemGroup[index].threshold,
					this.newRestockUpTo = this.itemGroup[index].restockUpTo,
					this.currentVendor = {company:this.itemGroup[index].vendor},
					this.newVendorPartNumber = this.itemGroup[index].vendorpartnumber || null,
					this.newItemCost = parseFloat(this.itemGroup[index].itemcount[this.itemGroup[index].itemcount.length-1].cost),
					//this.newItemSell = this.selectedItem.sell,
					this.newItemList = this.selectedItem.list,
					this.newVendorDiscount = this.selectedItem.vendordiscount
					break;
				}
			}


		},

		async getMoreInventory(){
			if(this.pageNumber >= this.maxPages){
			return
		  }
		  //let search_text = this.searchReq === "" ? null : this.searchReq
		  let search_text = this.kitPart === '' ? null : this.kitPart
			await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${parseInt(this.pageNumber)+1}/search_text/${encodeURIComponent(search_text)}`,
			  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		  )
		  .then((response) => {
			for(var item in response.data.queryResult.results){
				this.partKitQuery.push(response.data.queryResult.results[item]);
			}
			this.maxPages = response.data.queryResult.maxPages
			response.data.queryResult.results.forEach((item, index) => {
			  if (item.itemid.charAt(0) !== "*") {
				var totalStock = 0;
				item.otherLocations?.forEach((location) => {
				  totalStock += location.stock;
				});
				item.totalStock = totalStock;
			  }
			});
			this.pageNumber = this.pageNumber+1
			this.createGroups()
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		},

		async getVendors(){
		  await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then((response) => {
			this.allVendors = response.data.queryResult.results
			this.maxPagesVendors = response.data.queryResult.maxPages
			this.pageNumberVendors = this.pageNumberVendors+1
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		},

		async getMoreVendors(){
		  if(this.pageNumberVendors >= this.maxPagesVendors){
			return
		  }
		  let text = this.prevSearchVendor === "" ? null : this.prevSearchVendor
		  await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${encodeURIComponent(text)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then((response) => {
			for(var item in response.data.queryResult.results){
			  this.allVendors.push(response.data.queryResult.results[item])
			}
			this.maxPagesVendors = response.data.queryResult.maxPages
			this.pageNumberVendors = this.pageNumberVendors+1
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		},

		getDistinctBins(locObj){
			axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${encodeURIComponent(locObj.name)}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response)=>{
				this.newBin = ""
				this.binList = []
				this.binList = response.data.queryResult
				for(var item in this.binList){
					this.binList[item] = this.binList[item].toUpperCase()
				}
				this.binList.unshift('**NEW**')
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getTargetItem(id){
			let returnToTarget = null;
			await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(id)}/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					returnToTarget =  response.data.queryResult[0]
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

			return returnToTarget;
		},

		async handleSelection(record) {
			this.selectedItem = record;
			await this.getItemInfo(this.selectedItem);
			this.action = "viewItemDetails"
		},

		newInventoryLocation(loc){
			this.location = {name:loc}
			this.getDistinctBins(this.location)
			this.action = 'addBinLocation'
		},

		printBarcode() {
		  printJS({ printable: "printme", type: "html", targetStyles: ["*"] });
		},

		removeKitPart(part) {
		  for (let item in this.kitComponents) {
			if (this.kitComponents[item].itemid == part.itemid) {
			  this.kitComponents.splice(item, 1);
			  break;
			}
		  }
		  this.$forceUpdate()
		},

		removeLocation(location, bin, stock, warrantyStock, vendor){
			//TODO: Admin check
			if(stock > 0){
				alert("Location with stock cannot be removed");
				return;
			}
			if(warrantyStock > 0){
				alert("Warranty items exist at this location and cannot be removed.")
				return;
			}
			else{
				var item = {
					'itemid': this.selectedItem.itemid,
					'location': location,
					'bin': bin,
					'vendor' : vendor
				}
				this.removeThisItem(item);

				if(this.itemGroup.length <= 1){
					this.changeState("searching")
				}
				else{
					this.getItemInfo(this.selectedItem)
					this.editPart(this.selectedItem)
				}
				//this.changeState("searching");
			}
		},

		async removeThisItem(item) {
			this.isBusy = true;
			if (item) {
				await axios
				.delete(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(item.itemid)}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token },
					data: {
						location: item.location,
						bin: item.bin.toUpperCase(),
						vendor: item.vendor
					},
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				alert("Item removed");
			}
			else {
				alert("A valid item must be selected for deletion");
			}
			this.isBusy = false;
		},

		async searchVendors(searchValue){
		  if(searchValue === ""){
			searchValue = null
		  }
		  this.allVendors = []
		  this.vendorListIsLoading = true
		  this.pageNumberVendors = 0
		  this.prevSearchVendor = searchValue
		  await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumberVendors+1}/search_text/${encodeURIComponent(searchValue)}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then((response) => {
			this.allVendors = response.data.queryResult.results
			this.maxPagesVendors = response.data.queryResult.maxPages
			this.pageNumberVendors = this.pageNumberVendors+1
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		  this.vendorListIsLoading = false
		},

		async searchPart(text){
			// if(typeof text === 'object'){
			// 	text = this.searchReq === "" ? null : this.searchReq
			// }
			let search_text = text === "" ? null : text
			this.pageNumber = 0
			this.partKitQuery = []
			await axios
		  .get(`${process.env.VUE_APP_API_TARGET}/inventory/parts/plus/kits/${this.$globals.companyId}/${parseInt(this.pageNumber)+1}/search_text/${encodeURIComponent(search_text)}`,
			  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		  )
		  .then((response) => {
			this.partKitQuery = response.data.queryResult.results;
			this.maxPages = response.data.queryResult.maxPages
			response.data.queryResult.results.forEach((item, index) => {
			  if (item.itemid.charAt(0) !== "*") {
				var totalStock = 0;
				item.otherLocations?.forEach((location) => {
				  totalStock += location.stock;
				});
				item.totalStock = totalStock;
			  }
			});
			this.pageNumber = this.pageNumber+1
			this.createGroups()
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
		},

		async updateKit(item) {
			//Auto asign
			if (!this.newKitId) {
				this.newKitId = this.selectedItem.itemid;
			}
			if (!this.newKitType) {
				this.newKitType = this.selectedItem.kit_type;
			}
			if (!this.newKitDesc) {
				this.newKitDesc = this.selectedItem.itemdesc;
			}
			if (this.addImage === "" || this.addImage === null){
				this.addImage = this.selectedItem.image
			}
			if (this.addNote === ''){
				this.addNote = null
			}

			let tempString = this.newKitId.substring(0,7)
			if(tempString !=="**KIT**"){
				this.newKitId = "**KIT**" + this.newKitId
			}

			let componentList = []
			this.kitComponents.forEach((item, i) => {
				componentList.push({
					qty: item.qty,
					itemid : item.itemid,
					location: item.location,
					bin: item.bin,
					itemdesc: item.itemdesc
				})
			});

			await axios.put(`${process.env.VUE_APP_API_TARGET}/inventory/kit/part/${this.selectedItem.itemid}/${this.$globals.companyId}`,
				{
					itemid: this.newKitId,
					itemdesc: this.newKitDesc,
					kit_type: this.newKitType,
					components: JSON.stringify(componentList),
					image: this.addImage,
					note: this.addNote,
				},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then(response =>{
				this.selectedItem.note = this.addNote
				this.selectedItem.image = this.addImage
				//this.selectedItem.components = componentList
				this.kitComponents = []
				this.handleSelection(this.selectedItem)
				this.$forceUpdate()
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			this.newKitId =""
			this.newKitDesc = ""
			this.newKitType = ""
			this.addImage =""
		},

		async updatePart(item) {
			if (item.itemid.charAt(0) == "*") {
				this.updateKit(item);
			}
			else {
				//Saftey check
				if (this.newItemDesc === "") {
					alert("All items must have a description");
					return;
				}
				if (this.editLocation == "" || this.editLocation == null) {
					alert("All items must have a storage location");
					return;
				}
				if (this.newItemBin == "" || this.newItemBin == null) {
					alert("All items must have a bin location");
					return;
				}
				if (this.newItemType == "Unset" || this.newItemType == null){
					alert("This Item needs a type")
					return;
				}
				if (this.currentVendor == null || this.currentVendor.company == '' || this.currentVendor.company == undefined){
					alert("All Items must have Vendors")
					return;
				}
				//Auto Asign if empty
				if (this.newLocalCount === "") {
					this.newLocalCount = 0;
				}
				if (this.newWarrantyCount == ""){
					this.newWarrantyCount = 0
				}
				if (this.newThreshold === "") {
					this.newThreshold = 0;
				}
				if (this.newRestockUpTo === "" || this.newRestockUpTo == null){
					this.newRestockUpTo = parseInt(this.newThreshold ?? 0)
				}
				if (this.newItemCost == ""){
					this.newItemCost = 0
				}
				if (this.newItemSell == ""){
					this.newItemSell = null
				}
				if (this.addType == ""){
					this.addType = 'Unset'
				}
				if (this.newItemId == ""){
					this.newItemId = item.itemid
				}
				if (this.addImage == null || this.addImage == ""){
					this.addImage = item.image
				}
				if (this.addNote === ''){
					this.addNote = null
				}


                let qtyObject = [];
				let warrantyQtyArray = [];
                let poppedObjArray = []
				let avgCost = 0;
				let activeDate = new Date().toISOString().split("T")[0]

				//diff should determine if the local was increased or decreased
				let diff = parseInt(this.oldLocalStock) - parseInt(this.newLocalCount)
				let warrantyDiff = parseInt(this.oldWarrantyCount) - parseInt(this.newWarrantyCount)

				this.itemGroup.forEach((data, i) => {
					if(data.location === this.editLocation && data.bin.toUpperCase() === this.newItemBin.bin.toUpperCase() && data.vendor == this.newItemBin.vendor ){
						qtyObject = data.itemcount !== null && data.itemcount !== undefined ? [...data.itemcount] : []
						warrantyQtyArray = data.warranty_count !== null && data.warranty_count !== undefined ? [...data.warranty_count] : []
					}
					data.itemcount.forEach((info, j) => {
						avgCost = avgCost + (parseInt(info.qty) * parseFloat(info.cost) / item.totalStock)
					});
				});

				axios.put(`${process.env.VUE_APP_API_TARGET}/inventory/vendorcost/${encodeURIComponent(this.newItemId)}/${this.$globals.companyId}`,{
					vendorcost: this.newItemCost,
					vendor: this.currentVendor.company ?? item.vendor
				},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				if(diff !== 0){
					if(diff > 0){
						//Removes from front
                        while (diff > 0) {
                            let ogQTY = parseInt(qtyObject[0].qty)
							qtyObject[0].qty = parseInt(qtyObject[0].qty) - diff
							if(qtyObject[0].qty <= 0){
                                diff = Math.abs(qtyObject[0].qty)
                                poppedObjArray.push({
                                    cost: qtyObject[0].cost,
                                    costDate: qtyObject[0].costDate,
                                    qty: ogQTY,
                                    ident: "<= 0"
                                })
                                qtyObject.shift()
                            }
							else{
                                poppedObjArray.push({
                                    cost: qtyObject[0].cost,
                                    costDate: qtyObject[0].costDate,
                                    qty: diff,
                                    ident: "not <= 0"
                                })
								diff = 0
							}
						}
					}

					if(diff < 0){
						//adds diffrence at whatever the cost is to back
						qtyObject.push({
							cost: this.newItemCost,
							costDate: activeDate,
							qty: Math.abs(diff)
                        })
                        poppedObjArray.push({
							cost: this.newItemCost,
							costDate: activeDate,
                            qty: Math.abs(diff),
                            ident: "< 0"
						})
                    }
				}
				else if(this.newItemCost !== this.selectedItem.itemcount[this.selectedItem.itemcount.length-1].cost){
					//if diffrence is 0 and cost is different than the last cost. Only cost changed
					qtyObject[qtyObject.length-1].cost = parseFloat(this.newItemCost)
					avgCost = 0
					this.itemGroup.forEach((data, i) => {
						if(data.location === this.editLocation && data.bin === this.newItemBin.bin){
							data.itemcount = qtyObject
						}
						data.itemcount.forEach((info, j) => {
							avgCost = avgCost + (parseInt(info.qty) * parseFloat(info.cost) / item.totalStock)
						});
					});

				}

				if(warrantyDiff !== 0){
					if(warrantyDiff > 0){
						//Removes from front
						while (warrantyDiff > 0) {
							let ogQTY = parseInt(warrantyQtyArray[0].qty)
							warrantyQtyArray[0].qty = parseInt(warrantyQtyArray[0].qty) - warrantyDiff
							if(warrantyQtyArray[0].qty <= 0){
								warrantyDiff = Math.abs(warrantyQtyArray[0].qty)
								poppedObjArray.push({
									cost: 0,
									costDate: warrantyQtyArray[0].costDate,
									qty: ogQTY,
									ident: "<= 0"
								})
								warrantyQtyArray.shift()
							}
							else{
								poppedObjArray.push({
									cost: 0,
									costDate: warrantyQtyArray[0].costDate,
									qty: warrantyDiff,
									ident: "not <= 0"
								})
								warrantyDiff = 0
							}
						}
					}
					if(warrantyDiff < 0){
						//adds diffrence at whatever the cost is to back
						warrantyQtyArray.push({
							cost: 0,
							costDate: activeDate,
							qty: Math.abs(warrantyDiff)
						})
						poppedObjArray.push({
							cost: 0,
							costDate: activeDate,
							qty: Math.abs(warrantyDiff),
							ident: "< 0"
						})
					}
				}


				if(this.newItemCost === this.selectedItem.itemcount[this.selectedItem.itemcount.length-1].cost){
					activeDate = this.selectedItem.itemcount[this.selectedItem.itemcount.length-1].costDate;
				}

				//If all qty removed it backfills with something in the correct format and last known cost.
				if(qtyObject.length < 1){
					qtyObject.push({
						cost: this.newItemCost,
						costDate: activeDate,
						qty: 0
					})
				}

				if(warrantyQtyArray.length < 1){
					warrantyQtyArray.push({
						cost: 0,
						costDate: activeDate,
						qty: 0
					})
				}

				if(this.newItemSell !== null){
					this.newItemSell = this.newItemSell / 100
				}

				//Cross Item update [Static fields]
				await axios.put(`${process.env.VUE_APP_API_TARGET}/inventory/update/id/${encodeURIComponent(item.itemid)}/${this.$globals.companyId}`,
					{
						id: this.newItemId ?? item.itemid,
						desc: this.newItemDesc ?? item.itemdesc,
						type: this.newItemType ?? item.type,
						image: this.addImage,
						cost: parseFloat(avgCost.toFixed(2)),
						itemMarkup: this.newItemSell,
						list: this.newItemList ?? 0,
						note: this.addNote,
					},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.selectedItem.itemid = this.newItemId

				//Specific location + bin update
				await axios.put(`${process.env.VUE_APP_API_TARGET}/inventory/item/${encodeURIComponent(this.newItemId)}/${this.$globals.companyId}`,{
					itemcount: JSON.stringify(qtyObject),
					warrantyCount: JSON.stringify(warrantyQtyArray),
					location: this.editLocation,
					bin: this.newItemBin.bin.toUpperCase(),
					threshold: this.newThreshold ?? 0,
					restockUpTo: this.newRestockUpTo ?? this.newThreshold ?? 0,
					vendor: this.currentVendor.company ?? item.vendor,
					oldVendor: this.newItemBin.vendor,
					vendorpartnumber: this.newVendorPartNumber,
					vendordiscount: this.newVendorDiscount,
					costdate: activeDate,
				},
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }
                }).then((response) => {
					//TODO: account for warranty adjusted?
                    //TODO: inventory adjustment call here
                    //diff: parseInt(this.oldLocalStock) - parseInt(this.newLocalCount)
                    //location: this.editLocation
                    //bin: this.newItemBin.toUpperCase()
                    //vendor: this.currentVendor.company ?? item.vendor
					let tempBin = String(this.newItemBin.bin ?? item.bin)
                    axios.post(`${process.env.VUE_APP_API_TARGET}/inventory/adjustment/${this.$globals.companyId}`, {
                        adjustedCount: JSON.stringify(poppedObjArray),
                        bin: tempBin.toUpperCase(),
                        itemid: this.selectedItem.itemid,
                        location: this.editLocation ?? item.location,
                        qtyAdjusted: parseInt(this.newLocalCount) - parseInt(this.oldLocalStock),
                        vendor: this.currentVendor.company ?? item.vendor
                    },
                    {headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}})
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.handleSelection(this.selectedItem)


				//Clear variables
				this.editLocation = "";
				this.addImage = "";
				this.newItemBin = "";
				this.newItemCost = "";
				this.newItemDesc = "";
				this.newItemId = "";
				this.newItemList = "";
				this.newItemSell = "";
				this.newItemType = "";
				this.addNote = "";

		  }
		},

		async updateLocation(){
			//Saftey Check
			if(this.newLocation.name == ""){
				alert("Location name cannot be empty")
				return
			}
			if(this.newLocation.type == "" || this.newLocation.type == null){
				alert("Location must have a type")
				return
			}

			await axios.put(`${process.env.VUE_APP_API_TARGET}/locations/update/location/inventory/${this.$globals.companyId}`,
				{
					newLocation: this.newLocation.name ?? this.editLocation,
					oldLocation: this.editLocation,
					type:this.newLocation.type
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewItem', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			//now get those location after the edit..
			this.getPartLocations(this.selectedItem.itemid)
			await this.getItemInfo(this.selectedItem)

			this.editLocation = this.newLocation.name

			this.action = 'editPart'
			this.newLocation= {
				name: "",
				type: ""
			}
		},

		uploadPartImage(e) {
		  const image = e.target.files[0];
		  const reader = new FileReader();
		  reader.readAsDataURL(image);
		  reader.onload = (e) => {
			this.addImage = e.target.result;
		  };
		},



	}
}

</script>

<style>
img.item {
  border-radius: 8px;
  /* height: 200px; */
  max-width: 200px;
  margin-top: 10%;
}

barcode {
  text-align: center;
  margin-top: 15%;
  margin: auto
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.action === '')?_c('div',{staticClass:"card card-danger"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"searchBar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control w-100",attrs:{"type":"text","placeholder":"Search by Work Order, Asset, or Technician"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.debounceSearch]}})]),_c('b-table',{ref:"vehicleHistory",attrs:{"id":"vehicleHistory","striped":"","busy":_vm.isBusy,"sort-by":'start_date',"sort-desc":true,"per-page":10,"current-page":_vm.currentPage,"filter-included-fields":[
                        'localid',
                        'vehicle',
                        'technician' ],"filter":_vm.search,"fields":[
                        { key: 'localid', label: 'Work Order #', sortable: true },
                        { key: 'technician' },
                        { key: 'start_date' },
                        { key: 'end_date' },
                        { key: 'status' } ],"items":_vm.historyProvider},on:{"row-clicked":_vm.viewTicketDetails},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(technician)",fn:function(row){return _vm._l((JSON.parse(row.item.technician)),function(tech){return _c('p',{key:tech.name},[_vm._v(" "+_vm._s(tech.name)+" ")])})}}],null,false,3127929682)}),_c('b-pagination',{staticClass:"customPagination",staticStyle:{"margin-left":"38.5%"},attrs:{"per-page":10,"total-rows":parseInt(_vm.maxPages)*10,"aria-controls":"vehicleHistory"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]):_vm._e(),(_vm.action === 'viewTicket')?_c('div',[_c('viewTicket',{attrs:{"selectedTicket":_vm.selectedTicket},on:{"return":_vm.handleTicketReturn}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',[_c('b',[_vm._v("Asset History")])])]),_c('div',{staticClass:"card-tools"})])}]

export { render, staticRenderFns }
/* eslint-disable */

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import Router from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Main from '@/components/Main';
import { domain, clientId, audience } from '../auth_config.json';
import { Auth0Plugin } from './auth';
import { authGuard } from './auth/authGuard';
import VueToastify from 'vue-toastify'; //https://www.npmjs.com/package/vue-toastify?activeTab=readme
import Chartkick from 'vue-chartkick';
import chart from 'chart.js';
import VCalendar from 'v-calendar';
import VueSilentbox from 'vue-silentbox';

import { store } from '../store.js';

if (process.env.NODE_ENV === 'development') {
    console.log("I am a development app.")
    require('dotenv').config({ path: '.env' })
    console.log(process.env.VUE_APP_API_TARGET)
} else {
    require('dotenv').config({path: '.env.production'})
}

console.log(Vue, window.Vue);

Vue.prototype.$globals = store;

Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.use(Router);
Vue.use(VueToastify, {
    position: 'bottom-right',
    warningInfoDuration: 4000,
    canTimeout: false,
    canPause: false,
    orderLatest: false,
    iconEnabled: true,
    maxToasts: 3
});
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});

Vue.use(Chartkick.use(chart));

Vue.use(VCalendar);
Vue.use(VueSilentbox);

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Main',
            component: Main,
            beforeEnter: authGuard
        }
    ]
});

export default router;

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router: router,
    render: (h) => h(App)
});

<template>
  <div class="app">
    <!--Defualt Show Section -->
    <div class="main" v-show="action == 'searching'">
      <div class="d-flex">
        <multiselect
          style="display: inline-block; width:95%"
          label="company"
          track-by="id"
          :clearable="true"
          :show-labels="false"
          :options="allVendors"
          v-model="searchedVendor"
          @input="rowClicked"
          placeholder="Vendor"
          @search-change="debounceVendors"
          :loading="vendorListIsLoading"
        >
          <template slot="option" slot-scope="props">
              <intersect v-if="props.option.company === allVendors[allVendors.length - 1].company" @enter="getMoreVendors">
                <span>{{props.option.company}}</span>
              </intersect>
          </template>
        </multiselect>

        <i class="fas fa-plus-circle" @click="action = 'createVendor'" style="margin-top: 1%; margin-left: 1%;"></i>
        <div v-if="canAccess.admin === true" style="margin-left: 1%; margin-top: 0.75%; margin-right: 1%;">
          <i class="fas fa-edit" @click="editVendorInfo()" style="margin-left: 1%; display: inline;"></i>
          <i class="fas fa-trash" @click="removeVendor(selectedVendor)" style="margin-left: 25%; display: inline;"></i>
        </div>
      </div>

      <div class="row">
        <div class="col" style="max-width: 99.5%">
          <div class="card card-danger mt-4" style="height: 485px">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Info</b></h3>
              </div>
              <div class="card-tools">
                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="newCustomerContent()">{{buttonArray[this.selectedTab]}}</b-button>
              </div>
            </div>
            <div class="card-body scrollproperties" style="overflow-y: scroll">
              <b-row>
                <b-col>
                  <b>Address:</b> <br />
                  <span style="font-size: 10pt">
                    {{ selectedVendor!==undefined ? selectedVendor.address : null }}
                  </span>
                </b-col>
                <b-col>
                  <b>Phone:</b> <br />
                  <span style="font-size: 10pt">
                    {{ selectedVendor!==undefined ? selectedVendor.phone : null }}
                  </span>
                </b-col>
                <b-col>
                  <b>Vendor Discount Minimum:</b> <br />
                  <span v-if="selectedVendor.vendorDiscountMinimum" style="font-size: 10pt">
                    ${{ selectedVendor.vendorDiscountMinimum }}
                  </span>
                  <span v-else style="font-size: 10pt">
                    Not Set
                  </span>
                </b-col>
				<b-col v-if="selectedVendor.markup !== null">
					<b>Vendor Markup:</b><br />
					<span style="font-size: 10pt">
						{{selectedVendor.markup * 100}}%
					</span>
				</b-col>
              </b-row>
              <b-tabs :title-link-class="'text-danger'" content-class="mt-3" fill lazy v-model="selectedTab">
                <b-tab title="Contacts" active :title-link-class="'text-danger'">
                  <!--Vendor Contact Info Section -->
                  <b-table
                    ref="vendorContactTable"
                    style="font-size: 11pt; width: 95%;"
                    :busy="isBusy"
                    :title-link-class="'text-danger'"
                    :fields="[
                      { key: 'picture'},
                      { key: 'title'},
                      { key: 'name'},
					  { key: 'roles'},
                      { key: 'phone'},
                      { key: 'email'},
                      { key: 'actions', label: '' },
                    ]"
                    :items="allContacts[this.lastSelectedIndex]"
                    striped
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                    <template #cell(picture)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                      >
                        <img
                          :src="row.item.picture"
                          class="img-circle elevation-3 note-pfp"
                          alt="Contact Image"
                          @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'"
                        />
                      </b-col>
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                      >
                        <b-row
                          v-if="
                            subaction === 'editContact' &&
                            editIndex === row.item.contactid
                          "
                        >
                          <img
                            :src="row.item.picture"
                            class="img-circle elevation-3 note-pfp"
                            alt="Contact Image"
                          />
                        </b-row>
                        <b-row
                          v-if="
                            subaction === 'editContact' &&
                            editIndex === row.item.contactid
                          "
                        >
                          <input
                            style="margin-top: 3%"
                            id="contactPic"
                            type="file"
                            accept="image/*"
                            @change="updateVendorContactImage($event, row.item)"
                            name="contactPic"
                            :show-labels="false"
                          />
                        </b-row>
                      </b-col>
                    </template>

                    <template #cell(title)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        >{{ row.item.title }}</b-col
                      >
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        ><input
                          class="form-control form-control-sm"
                          type="text"
                          required
                          v-model="row.item.title"
                          placeholder="Title"
                          style="width: 150px"
                      /></b-col>
                    </template>

					<template #cell(roles)="row">
						<div v-if="subaction !== 'editContact' || editIndex !== row.item.contactid">
							<div v-for="(role, index) in (typeof row.item.roles === 'object' ? row.item.roles : JSON.parse(row.item.roles))" :key="index">
								{{ role }}
							</div>
						</div>
						<multiselect
							v-if="subaction === 'editContact' && editIndex === row.item.contactid"
							:options="[
								'Administrator',
								'Purchasing',
								'Maintenance',
								'Billing',
							]"
							:show-labels="false"
							:multiple="true"
							v-model="row.item.role"
							style="width: 150px"
							>
						</multiselect>
                    </template>

                    <template #cell(name)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        class="vehicle-link"
                        >{{ row.item.name }}</b-col
                      >
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        ><input
                          class="form-control form-control-sm"
                          type="text"
                          style="width: 150px"
                          required
                          v-model="row.item.name"
                          placeholder="Name"
                      /></b-col>
                    </template>

                    <template #cell(phone)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        ><a
                          :href="'tel:' + row.item.phone"
                          class="vehicle-link"
                          style="cursor: pointer; color: #dc3545;"
                          >{{ row.item.phone }}</a
                        ></b-col
                      >
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex == row.item.contactid
                        "
                        ><input
                          class="form-control form-control-sm"
                          type="tel"
                          style="width: 110px"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          required
                          v-model="row.item.phone"
                          placeholder="Phone Number"
                      /></b-col>
                    </template>

                    <template #cell(email)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                        ><a :href="'mailto:' + row.item.email"
                          style="cursor: pointer; color: #dc3545; width: 80%">{{
                          row.item.email
                        }}</a></b-col
                      >
                      <b-col
                        v-if="
                          subaction === 'editContact' &&
                          editIndex === row.item.contactid
                        "
                        cols="3"
                        ><input
                          class="form-control form-control-sm"
                          type="email"
                          style="width: 200px"
                          required
                          v-model="row.item.email"
                          placeholder="Email"
                      /></b-col>
                    </template>

                    <template #cell(actions)="row">
                      <b-col
                        v-if="
                          subaction !== 'editContact' ||
                          editIndex !== row.item.contactid
                        "
                      >
                        <i
                          class="fas fa-edit"
                          size="sm"
                          @click.stop="editContact(row.item)"
                        ></i>
                        &nbsp;<i v-if="canAccess.admin === true"
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeContact(row.item)"
                        ></i>
                      </b-col>
                      <b-col
                        v-if="
                          subaction == 'editContact' &&
                          editIndex == row.item.contactid
                        "
                      >

                        <i class="fas fa-check" @click.prevent="updateContact(row.item); subaction=''" style="margin-left: 1%; display: inline;"></i>
                        <i class="fas fa-times" style:="margin-left: 5%" @click.prevent="subaction = ''" style="margin-left: 25%; display: inline;"></i>
                      </b-col>
                    </template>
                  </b-table>
                </b-tab>

                <b-tab title="Price History" :title-link-class="'text-danger'">
                  <b-table
                    striped
                    ref="priceHistory"
                    :per-page="5"
                    :items="priceSchedules[lastSelectedIndex]"
                    :fields="[
                      { key: 'uploadDate', sortable: true },
                      { key: 'effectiveDate', sortable: true },
                      { key: 'download' },
                    ]"
                  >
                    <template #cell(download)="row">
                      <a :href="row.item.file" style="cursor: pointer; color: #dc3545;">View</a>
                    </template>
                  </b-table>
                </b-tab>

                <b-tab title="Locations" :title-link-class="'text-danger'">
                  <div v-if="action !== 'addLocation'">
                    <b-table
                      striped
                      ref="storeLocations"
                      :per-page="5"
                      :items="allLocations[lastSelectedIndex]"
                      :fields="[
                        { key: 'street' },
                        { key: 'city', sortable: true },
                        { key: 'state', sortable: true },
                        { key: 'actions', label: '' },
                      ]"
                    >
                      <template #cell(actions)="row">
                        <i v-if="canAccess.admin === true"
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeLocation(row.item)"
                        ></i>
                      </template>
                    </b-table>
                  </div>
                </b-tab>

                <b-tab title="Notes" :title-link-class="'text-danger'" >
                  <div style="overflow-y: scroll height: 350px">
                    <h5>Pinned Notes</h5>
                    <!--B-card Pinned Notes Section -->
                    <b-row
                      class="text-center"
                      style="padding: 5px"
                      v-for="note in allPinnedNotes[lastSelectedIndex]"
                      :key="note.noteid"
                    >
                      <b-col></b-col>
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        cols="10"
                        >{{ note.note }} - &nbsp;
                        <span class="vehicle-link">{{
                          JSON.parse(note.author).name
                            ? JSON.parse(note.author).name
                            : ""
                        }}</span
                        >&nbsp;<img
                          class="note-pfp"
                          :src="
                            JSON.parse(note.author).pic
                              ? JSON.parse(note.author).pic
                              : ''
                          "
                      /></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        cols="9"
                      >
                        <textarea
                          style="width: 80%"
                          v-model="note.note"
                        ></textarea>
                      </b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><b-form-checkbox
                          id="pinned_edit"
                          v-model="note.pinned"
                          value="1"
                          unchecked-value="0"
                          style="margin: auto 1%"
                          >Pinned</b-form-checkbox
                        ></b-col
                      >
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        ><i
                          class="fas fa-edit"
                          size="sm"
                          @click.stop="editNote(note)"
                        ></i>
                        <i v-if="canAccess.admin === true"
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeNote(note)"
                          style="margin-left: 5%; display: inline;"
                        ></i
                      ></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><i
                          class="fas fa-check"
                          size="sm"
                          @click.stop="updateNote(note)"
                        ></i>
                        <i class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 5%; display: inline;"></i>
                      </b-col>
                    </b-row>
                    <hr>
                    <!-- Regular Notes -->
                    <h5>All Notes</h5>
                    <b-row
                      class="text-center"
                      style="padding: 5px"
                      v-for="note in allNotes[lastSelectedIndex]"
                      :key="note.noteid"
                    >
                      <b-col></b-col>
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        cols="10"
                        >{{ note.note }} - &nbsp;
                        <span class="vehicle-link">{{
                          JSON.parse(note.author).name
                            ? JSON.parse(note.author).name
                            : ""
                        }}</span
                        >&nbsp;<img
                          class="note-pfp"
                          :src="
                            JSON.parse(note.author).pic
                              ? JSON.parse(note.author).pic
                              : ''
                          "
                      /></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        cols="9"
                      >
                        <textarea
                          style="width: 80%"
                          v-model="note.note"
                        ></textarea>
                      </b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><b-form-checkbox
                          id="pinned_edit"
                          v-model="note.pinned"
                          value="1"
                          unchecked-value="0"
                          style="margin: auto 1%"
                          >Pinned</b-form-checkbox
                        ></b-col
                      >
                      <b-col
                        v-if="
                          subaction !== 'editNote' || editIndex !== note.noteid
                        "
                        ><i
                          class="fas fa-edit"
                          size="sm"
                          @click.stop="editNote(note)"
                        ></i>
                        <i v-if="canAccess.admin === true"
                          class="fas fa-trash"
                          size="sm"
                          @click.stop="removeNote(note)"
                          style="margin-left: 5%; display: inline;"
                        ></i
                      ></b-col>
                      <b-col
                        v-if="
                          subaction === 'editNote' && editIndex === note.noteid
                        "
                        ><i
                          class="fas fa-check"
                          size="sm"
                          @click.stop="updateNote(note)"
                        ></i>
                        <i class="fas fa-times" @click.prevent="subaction = ''" style="margin-left: 5%; display: inline;"></i>
                      </b-col>
                    </b-row>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>

        <div class="row" style="width: 98%; margin-left: 0%">
          <div class="col">
            <div class="card card-danger mt-4" style="width: 100%">
              <div class="card-header">
                <div class="card-title">
                  <h3><b>Inventory</b></h3>
                </div>
              </div>
              <div class="card-body scrollproperties" style="height: 315px; max-height: 315px; overflow-y: scroll">
                <div class="searchBar">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search by item"
                    v-model="search"
                  />
                  &nbsp;
                </div>
                <!-- Hey this table needs pagination? -->
                <b-table
                  ref="mainVendorTable"
                  :busy="isBusy"
                  :filter="search"
                  :fields="[
                    { key: 'itemid', sortable: true },
                    { key: 'itemdesc' },
                    { key: 'cost'},
                  ]"
                  :items="allParts"
                  striped
                >
                  <template #cell(cost)='row'>
                    <div>
                        ${{ row.item.vendorcost.toFixed(2) }}
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>


          <div class="col">
            <div class="card card-danger mt-4 ml-4" style="width: 100%">
              <div class="card-header">
                  <div class="card-title">
                    <h3><b>Recent Activity</b></h3>
                  </div>
              </div>
              <div class="card-body scrollproperties" style="height: 315px; max-height: 315px; overflow-y: scroll">
                <b-table
                  striped
                  ref="activity2"
                  :per-page="10"
                  :items="allActivity"
                  :fields="[{ key: 'date' }, { key: 'action' }]"
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

	<!--Add New Vendor Section -->
	<div style="margin-top: 1%" v-show="action === 'createVendor'">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>New Vendor</b></h3>
				</div>
				<div class="card-tools">
					<b-button style="background-color: #fff4; border-color: #dc3545;"
						@click.prevent="checkNewAddress()"
						>Submit
					</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545;"
						@click.prevent="newVendor = {}; action = 'searching'"
						>Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<input required class="form-control w-75" type="text" v-model="newVendor.company" style="margin-left: 12.5%" placeholder="Vendor Name"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Discount Minimum</label>
				<input class="form-control w-75" type="text" v-model="newVendor.vendorDiscountMinimum" style="margin-left: 12.5%" placeholder="$ for minimum discount"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Markup %</label>
				<input class="form-control w-75" type="number" v-model="newVendor.markup" step="1" style="margin-left: 12.5%" placeholder="% markup for selling any item from this Vendor"/>


				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Address<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<input required class="form-control w-75" type="text" v-model="newVendor.street1" style="margin-left: 12.5%" placeholder="Street"/>
				<input required class="form-control w-75" type="text" v-model="newVendor.street2" style="margin-left: 12.5%" placeholder="Apt / Suite"/>
				<input required class="form-control w-75" type="text" v-model="newVendor.city" style="margin-left: 12.5%" placeholder="City"/>
				<multiselect required class="w-75" :multiple="false" v-model="newVendor.state" style="margin-left: 12.5%" placeholder="State" :options="allStates" track-by="abbreviation" label="name" :show-labels="false"/>
				<input required class="form-control w-75" type="number" v-model="newVendor.zip" style="margin-left: 12.5%" placeholder="Zip Code"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone</label>
				<input required class="form-control w-75" type="tel" v-model="newVendor.phone" style="margin-left: 12.5%" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
			</div>
		</div>
	</div>

	<!-- Edit Vendor Section -->
	<div style="margin-top: 1%" v-show="action == 'editInfo'">
		<div class="card card-danger">
			<div class="card-header">
				<div class="card-title">
					<h3><b>Edit Vendor</b></h3>
				</div>
				<div class="card-tools">
					<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="checkEditAddress()">
						Submit
					</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'searching'">
						Return
					</b-button>
				</div>
			</div>
			<div class="card-body">
				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Name</label>
				<input required class="form-control w-75" type="text" v-model="editVendor.company" style="margin-left: 12.5%" placeholder="Vendor Name"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Discount Minimum $</label>
				<input class="form-control w-75" type="number" v-model="editVendor.vendorDiscountMinimum" style="margin-left: 12.5%" placeholder="$ for minimum discount"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Vendor Markup %</label>
				<input class="form-control w-75" type="number" v-model="editVendor.markup" step="1" style="margin-left: 12.5%" placeholder="% markup for selling any item from this Vendor"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Address</label>
				<input required class="form-control w-75" type="text" v-model="editVendor.street1" style="margin-left: 12.5%" placeholder="Street"/>
				<input required class="form-control w-75" type="text" v-model="editVendor.street2" style="margin-left: 12.5%" placeholder="Apt / Suite"/>
				<input required class="form-control w-75" type="text" v-model="editVendor.city" style="margin-left: 12.5%" placeholder="City"/>
				<multiselect required class="w-75" :multiple="false" v-model="editVendor.state" style="margin-left: 12.5%" placeholder="State" :options="allStates" track-by="abbreviation" label="name" :show-labels="false"/>
				<input required class="form-control w-75" type="number" v-model="editVendor.zip" style="margin-left: 12.5%" placeholder="Zip Code"/>

				<label class="w-75 text-left mt-2" style="margin-left: 13.5%">Phone</label>
				<input required class="form-control w-75" type="tel" v-model="editVendor.phone" style="margin-left: 12.5%" placeholder="Phone Number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"/>
			</div>
		</div>
	</div>

    <!--Upload Price Schedule Section -->
    <div style="margin-top: 1%" v-if="action == 'uploadPriceSchedule'">
      <div class="card card-danger">
        <div class="card-header">
          <div class="card-title">
            <h3><b>New Price Schedule</b></h3>
          </div>
          <div class="card-tools">
            <b-button style="background-color: #fff4; border-color: #dc3545;"
              @click.prevent="uploadPriceSchedule()"
              >Upload</b-button
            >
            <b-button style="background-color: #fff4; border-color: #dc3545;"
              @click.prevent="action = 'searching'"
              >Return</b-button
            >
          </div>
        </div>
        <div class="card-body">
          <form
            class="addingFile"
            enctype="multipart/form-data"
            ref="submisionForm"
          >
            <div style="margin-left: 15%; margin-bottom: 1%; margin-right: 15%">

				<img
				  src="../assets/priceScheduleGuide.jpg"
				  alt="Vendor Price Schedule Guide"
				/>
				<p>
					<b>Headers:</b>
					Part Number, List Price, Discount, Net Price, Description
				</p>
				<p>
					The Part Number can be either the Item Id of your inventory or Vendor Part Number.
					Item matches if Description, Vendor Part Number, or Item ID matches.
					It is acceptable to not have a Discount % or List Price.
				</p>
				<p>
					File is expected to be of type .CSV
				</p>
              <label>Date and Time<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
              <VueCtkDateTimePicker
				        only-date
                color="#dc3545"
                button-color="#dc3545"
                format="DD-MM-YYYY"
                v-model="newPriceSchedule.effectiveDate"
				        formatted= "YYYY-MM-DD"
                required
                style="margin-bottom: 10px;"
              />
              <label>File Upload<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
              <input
                class="form-control form-control-lg"
                id="formFileLg"
                type="file"
                ref="inputFile"
                name="doc"
				        accept=".csv"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- New Contact Section-->
    <div v-if="action === 'addContact'" class="addContact card card-danger">
		<newContact :source="selectedVendor" @return="action = 'searching'; getVendorContacts()"></newContact>
    </div>

    <!-- New Location Section-->
    <div v-if="action == 'addLocation'" class="addLocation card card-danger">
      <div class="card-header">
        <div class="card-title">
          <h3><b>New Location</b></h3>
        </div>
        <div class="card-tools">
          <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="addLocation()">Submit</b-button>
          <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'searching'">Return</b-button>
        </div>
      </div>
      <div class="card-body">
        <label class="w-75 text-left mt-2" style="margin-left: 13.5%">Street<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
        <input class="form-control w-75" type="text" v-model="newLocation.street" style="margin-left: 12.5%" placeholder="Street" required/>
        <label class="w-75 text-left mt-2" style="margin-left: 13.5%">City<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
        <input class="form-control w-75" type="text" v-model="newLocation.city" style="margin-left: 12.5%" placeholder="City" required/>
        <label class="w-75 text-left mt-2" style="margin-left: 13.5%">State<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
          <select
            class="form-control w-75"
            name="state"
            id="state"
            required
            v-model="newLocation.state"
            style="margin-left: 12.5%"
          >
            <option value="" selected="selected">
              Select a State
            </option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
      </div>
    </div>

    <!-- New Note Section -->
    <div v-if="action === 'addNote'" class="card card-danger">
      <div class="card-header">
        <div class="card-title">
          <h3><b>New Note</b></h3>
        </div>
        <div class="card-tools">
          <b-button style="background-color: #fff4; border-color: #dc3545; margin-left:-5%;" @click.prevent="pushNote()">Submit</b-button>
          <b-button style="background-color: #fff4; border-color: #dc3545; margin-left:1%;" @click.prevent="action = 'searching'">Return</b-button>
        </div>
      </div>
      <div class="card-body">
        <div style="display: flex; justify-content: center">
          <textarea
            placeholder="Note on Customer"
            v-model="newNote.note"
            style="width: 300px; margin: auto 1%"
            required
            v-b-tooltip.hover title="Required Field"
          ></textarea>
          <input type="checkbox" id="pinned" v-model="newNote.pinned" value="1" unchecked-value="0" style="margin: auto 1%"  />
          <label for="pinned" style="margin-top: 1.5%">Pinned</label>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from "vue-multiselect";
import { Socket } from "socket.io-client";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import Intersect from 'vue-intersect'
import { debounce } from 'lodash';
import newContact from './Customers/newContact.vue'


var _ = require('lodash');


export default {
  name: "app",
  props: {
    socket: Socket,
    companyId: Number,
    user: Object,
    canAccess: Object,
    qbo: Boolean
  },
  components: { Multiselect, VueCtkDateTimePicker, Intersect, newContact },
  data() {
    return {
      action: "searching",
      allActivity: [],
      allContacts: [],
      allLocations: [],
      allNotes: [],
      allParts: [],
      allPinnedNotes: [],
	  allStates: [
			{
				"name": "Alabama",
				"abbreviation": "AL"
			},
			{
				"name": "Alaska",
				"abbreviation": "AK"
			},
			{
				"name": "American Samoa",
				"abbreviation": "AS"
			},
			{
				"name": "Arizona",
				"abbreviation": "AZ"
			},
			{
				"name": "Arkansas",
				"abbreviation": "AR"
			},
			{
				"name": "California",
				"abbreviation": "CA"
			},
			{
				"name": "Colorado",
				"abbreviation": "CO"
			},
			{
				"name": "Connecticut",
				"abbreviation": "CT"
			},
			{
				"name": "Delaware",
				"abbreviation": "DE"
			},
			{
				"name": "District Of Columbia",
				"abbreviation": "DC"
			},
			{
				"name": "Federated States Of Micronesia",
				"abbreviation": "FM"
			},
			{
				"name": "Florida",
				"abbreviation": "FL"
			},
			{
				"name": "Georgia",
				"abbreviation": "GA"
			},
			{
				"name": "Guam",
				"abbreviation": "GU"
			},
			{
				"name": "Hawaii",
				"abbreviation": "HI"
			},
			{
				"name": "Idaho",
				"abbreviation": "ID"
			},
			{
				"name": "Illinois",
				"abbreviation": "IL"
			},
			{
				"name": "Indiana",
				"abbreviation": "IN"
			},
			{
				"name": "Iowa",
				"abbreviation": "IA"
			},
			{
				"name": "Kansas",
				"abbreviation": "KS"
			},
			{
				"name": "Kentucky",
				"abbreviation": "KY"
			},
			{
				"name": "Louisiana",
				"abbreviation": "LA"
			},
			{
				"name": "Maine",
				"abbreviation": "ME"
			},
			{
				"name": "Marshall Islands",
				"abbreviation": "MH"
			},
			{
				"name": "Maryland",
				"abbreviation": "MD"
			},
			{
				"name": "Massachusetts",
				"abbreviation": "MA"
			},
			{
				"name": "Michigan",
				"abbreviation": "MI"
			},
			{
				"name": "Minnesota",
				"abbreviation": "MN"
			},
			{
				"name": "Mississippi",
				"abbreviation": "MS"
			},
			{
				"name": "Missouri",
				"abbreviation": "MO"
			},
			{
				"name": "Montana",
				"abbreviation": "MT"
			},
			{
				"name": "Nebraska",
				"abbreviation": "NE"
			},
			{
				"name": "Nevada",
				"abbreviation": "NV"
			},
			{
				"name": "New Hampshire",
				"abbreviation": "NH"
			},
			{
				"name": "New Jersey",
				"abbreviation": "NJ"
			},
			{
				"name": "New Mexico",
				"abbreviation": "NM"
			},
			{
				"name": "New York",
				"abbreviation": "NY"
			},
			{
				"name": "North Carolina",
				"abbreviation": "NC"
			},
			{
				"name": "North Dakota",
				"abbreviation": "ND"
			},
			{
				"name": "Northern Mariana Islands",
				"abbreviation": "MP"
			},
			{
				"name": "Ohio",
				"abbreviation": "OH"
			},
			{
				"name": "Oklahoma",
				"abbreviation": "OK"
			},
			{
				"name": "Oregon",
				"abbreviation": "OR"
			},
			{
				"name": "Palau",
				"abbreviation": "PW"
			},
			{
				"name": "Pennsylvania",
				"abbreviation": "PA"
			},
			{
				"name": "Puerto Rico",
				"abbreviation": "PR"
			},
			{
				"name": "Rhode Island",
				"abbreviation": "RI"
			},
			{
				"name": "South Carolina",
				"abbreviation": "SC"
			},
			{
				"name": "South Dakota",
				"abbreviation": "SD"
			},
			{
				"name": "Tennessee",
				"abbreviation": "TN"
			},
			{
				"name": "Texas",
				"abbreviation": "TX"
			},
			{
				"name": "Utah",
				"abbreviation": "UT"
			},
			{
				"name": "Vermont",
				"abbreviation": "VT"
			},
			{
				"name": "Virgin Islands",
				"abbreviation": "VI"
			},
			{
				"name": "Virginia",
				"abbreviation": "VA"
			},
			{
				"name": "Washington",
				"abbreviation": "WA"
			},
			{
				"name": "West Virginia",
				"abbreviation": "WV"
			},
			{
				"name": "Wisconsin",
				"abbreviation": "WI"
			},
			{
				"name": "Wyoming",
				"abbreviation": "WY"
			}
	  ],
      allVendors: [],
      buttonArray: ["New Contact", "Upload New Price Schedule", "New Location", "New Note"],
      defaultImage:
        "https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png",
      editIndex: "",
      editVendor: {
        company: "",
        address: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        phone: "",
        vendorDiscountMinimum: ""
      },
      isBusy: false,
      lastSelectedIndex: "",
      newContact: {},
      newNote: {},
      newLocation: {
        street: "",
        city: "",
        state: "",
      },
      newPriceSchedule: {
        uploadDate: "",
        effectiveDate: "",
        file: "",
        flag: "", //0-executed, 1-awaiting effectiveDate
      },
      newVendor: {
        name: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
        picture: "",
        phone: "",
		vendorDiscountMinimum: null
      },
      priceSchedules: [],
      purchaseOrders: [],
      search: "",
      selectedTab: 0,
      selectedVendor: {
        company: "",
        address: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        phone: "",
        vendorDiscountMinimum: "",
      },
	  searchedVendor: '',
      showPreview: false,
      subaction: "",
      maxPages: 0,
      pageNumber: 0,
      prevSearch: "",
      vendorListIsLoading: false
    };
  },

  computed: {
    buttonString: function(){
      switch(this.selectedTab){
        case 0:
          this.selectedTab = 0;
          this.$forceUpdate();
          return;
        case 1:
          this.selectedTab = 1;
          this.$forceUpdate();
          return;
        case 2:
          this.selectedTab = 2;
          this.$forceUpdate();
          return;
        default:
          return;
      }
    },
  },

  created() {
    this.$globals.socket.emit("joinRoom", "vendors"+this.$globals.companyId);
    this.$globals.socket.on("newDataCreated", async function (message, data) {
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    });

    this.debounceVendors = debounce(this.searchVendors, 600);
  },

  async mounted() {
    this.isBusy = true;
    await this.getVendors()
    this.selectedVendor = this.allVendors[0];
	this.searchedVendor = this.selectedVendor
    this.rowClicked(this.selectedVendor);
  },

  destroyed() {
        this.$globals.socket.emit("leaveRoom", `vendors${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed vendors")
    },

  methods: {
    async getVendors(){
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = 1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async getMoreVendors(){
      if(this.pageNumber >= this.maxPages || this.vendorListIsLoading){
        return
      }
      this.vendorListIsLoading = true
      let text = this.prevSearch === "" ? text = null : text = this.prevSearch
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        // for(var item in response.data.queryResult.results){
        //   this.allVendors.push(response.data.queryResult.results[item])
        // }

        this.allVendors = this.allVendors.concat(response.data.queryResult.results)
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
        this.vendorListIsLoading = false
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async searchVendors(searchValue) {
      if(searchValue === ""){
        searchValue = null
      }
      this.allVendors = []
      this.vendorListIsLoading = true
      this.pageNumber = 0
      this.prevSearch = searchValue
      await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${searchValue}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = this.pageNumber+1
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
      this.vendorListIsLoading = false
    },

    async addLocation() {
      await axios
        .post(
          process.env.VUE_APP_API_TARGET + "/vendors/locations/" +
            this.selectedVendor.id +
            "/" +
            this.$globals.companyId,
          {
            street: this.newLocation.street,
            city: this.newLocation.city,
            state: this.newLocation.state,
          },
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then(async (response) => {
          this.newLocation.street = "";
          this.newLocation.city = "";
          this.newLocation.state = "";
          await axios
            .get(
              process.env.VUE_APP_API_TARGET + "/vendors/" +
                this.selectedVendor.id +
                "/locations/" +
                this.$globals.companyId,
                {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
            )
            .then((response) => {
              this.allLocations[this.lastSelectedIndex] = response.data.queryResult;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.action = "searching";
    },

    calculateActivity() {
		//TODO Fix purchaseOrders as recent activity
      for (var index in this.purchaseOrders) {
        if (this.purchaseOrders[index].vendor.id == this.selectedVendor.id) {
          this.allActivity.push({
            date: this.purchaseOrders[index].data.orderDate,
            action: "Purchase Order Placed",
          });
        }
      }

      for (var index in this.priceSchedules[this.lastSelectedIndex]) {
        this.allActivity.push({
          date: this.priceSchedules[this.lastSelectedIndex][index].uploadDate,
          action: "Price Schedule Uploaded",
        });
        if (this.priceSchedules[this.lastSelectedIndex][index].flag == 0) {
          this.allActivity.push({
            date: this.priceSchedules[this.lastSelectedIndex][index]
              .effectiveDate,
            action: "Price Schedule Implemented",
          });
        }
      }
      this.allActivity = this.allActivity.reverse();
    },

    checkEditAddress(){
      var check = false
      if(
        (this.editVendor.street1 === undefined || this.editVendor.street1 === "") &&
        (this.editVendor.city === undefined || this.editVendor.city === "") &&
        (this.editVendor.state === undefined || this.editVendor.state === "") &&
        (this.editVendor.zip === undefined || this.editVendor.zip === "")
      ){
        this.editVendor.address = ""
        check = true;
      }
      if(
        (this.editVendor.street1 !== undefined && this.editVendor.street1 !== "") &&
        (this.editVendor.city !== undefined && this.editVendor.city !== "") &&
        (this.editVendor.state !== undefined && this.editVendor.state !== "") &&
        (this.editVendor.zip !== undefined && this.editVendor.zip !== "")
      ){
        if (this.editVendor?.street2 !== undefined && this.editVendor?.street2 !== '' ) {
          this.editVendor.address =
            this.editVendor?.street1 +
            ", " +
            this.editVendor?.street2 +
            ", " +
            this.editVendor?.city +
            ", " +
            this.editVendor?.state?.abbreviation +
            " " +
            this.editVendor?.zip
        }
        else{
          this.editVendor.address =
            this.editVendor?.street1 +
            ", " +
            this.editVendor?.city +
            ", " +
            this.editVendor?.state?.abbreviation +
            " " +
            this.editVendor?.zip
        }
        check = true;
      }
      if(check === true){
        this.updateVendor()
      }
      else{
        alert("Address must be a blank or complete address")
      }
    },

    checkNewAddress(){
      var addressObj;
      var check = false
      if(
        (this.newVendor.street1 === undefined || this.newVendor.street1 === "") &&
        (this.newVendor.city === undefined || this.newVendor.city === "") &&
        (this.newVendor.state === undefined || this.newVendor.state === "") &&
        (this.newVendor.zip === undefined || this.newVendor.zip === "")
      ){
        addressObj = {
          company: this.newVendor.company
        };
        this.newVendor.address = ""
        check = true;
      }
      if(
        (this.newVendor.street1 !== undefined && this.newVendor.street1 !== "") &&
        (this.newVendor.city !== undefined && this.newVendor.city !== "") &&
        (this.newVendor.state !== undefined && this.newVendor.state !== "") &&
        (this.newVendor.zip !== undefined && this.newVendor.zip !== "")
      ){
        if (this.newVendor?.street2 !== undefined && this.newVendor?.street2 !== '' ) {
          addressObj = {
            street1: this.newVendor?.street1,
            street2: this.newVendor?.street2,
            city: this.newVendor?.city,
            state: this.newVendor?.state?.abbreviation,
            zip: this.newVendor?.zip,
            country: "US",
            company: this.newVendor.company,
            name: this.newVendor.company,
          };
          this.newVendor.address =
            this.newVendor?.street1 +
            ", " +
            this.newVendor?.street2 +
            ", " +
            this.newVendor?.city +
            ", " +
            this.newVendor?.state?.abbreviation +
            " " +
            this.newVendor?.zip
        }
        else{
          addressObj = {
            street1: this.newVendor?.street1,
            city: this.newVendor?.city,
            state: this.newVendor?.state?.abbreviation,
            zip: this.newVendor?.zip,
            country: "US",
            company: this.newVendor.company,
          };
          this.newVendor.address =
            this.newVendor?.street1 +
            ", " +
            this.newVendor?.city +
            ", " +
            this.newVendor?.state?.abbreviation +
            " " +
            this.newVendor?.zip
        }
        check = true;
      }
      if(check === true){
        this.pushVendor(addressObj)
      }
      else{
        alert("Address must be a blank or complete address")
      }
    },

    editContact(contact) {
      this.editIndex = contact.contactid;
      this.subaction = "editContact";
    }, //END editContact()

    editNote(note) {
      this.editIndex = note.noteid;
      this.subaction = "editNote";
    }, //END editNote()

    editVendorInfo() {
      this.action = "editInfo";
      this.editVendor = {...this.selectedVendor};
	  this.editVendor.markup = this.editVendor.markup ?? 0.10
	  this.editVendor.markup = this.editVendor.markup * 100
      var secondAddressSplit = "";
      var addressSplit = this.selectedVendor?.address.split(', ')
      if(addressSplit.length === 4){
        this.editVendor.street1 = addressSplit[0]
        this.editVendor.street2 = addressSplit[1]
        this.editVendor.city = addressSplit[2]
        secondAddressSplit = addressSplit[3].split(' ')
        for(let i in this.allStates){
          if(this.allStates[i].abbreviation === secondAddressSplit[0]){
            this.editVendor.state = this.allStates[i];
            break;
          }
        }
        this.editVendor.zip = secondAddressSplit[1]
      }
      else{
        this.editVendor.street1 = addressSplit[0]
        this.editVendor.city = addressSplit[1]
        secondAddressSplit = addressSplit[2].split(' ')
        for(let i in this.allStates){
          if(this.allStates[i].abbreviation === secondAddressSplit[0]){
            this.editVendor.state = this.allStates[i];
            break;
          }
        }
        this.editVendor.zip = secondAddressSplit[1]
      }
    },

	async getVendorContacts(){
		await axios
		  .get(
			process.env.VUE_APP_API_TARGET + "/vendors/" +
			  this.selectedVendor.id +
			  "/contacts/" +
			  this.$globals.companyId,
			  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
		  )
		  .then((response) => {
			this.allContacts[this.lastSelectedIndex] =
			  response.data.queryResult;
			  this.$forceUpdate()
		  })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })
	},

    newCustomerContent(){
      switch(this.selectedTab){
        case 0:
          this.action = 'addContact'
          return;
        case 1:
          this.action = 'uploadPriceSchedule'
          return;
        case 2:
          this.action = 'addLocation'
          return;
        case 3:
          this.action = 'addNote'
          return;
        default:
          return;
      }
    },

    async pushContact() {
      await axios.post(
        process.env.VUE_APP_API_TARGET + "/vendors/vendorContact/" + this.$globals.companyId,
        {
          name: this.newContact.name,
          address: this.newContact.address,
          phone: this.newContact.phone,
          email: this.newContact.email,
          title: this.newContact.title,
          picture: this.newContact.picture
            ? this.newContact.picture
            : this.defaultImage,
          vendorId: this.selectedVendor.id,
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/" +
            this.selectedVendor.id +
            "/contacts/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allContacts[this.lastSelectedIndex] = response.data.queryResult;
          this.newContact.name = "";
          this.newContact.address = {};
          this.newContact.email = "";
          this.newContact.title = "";
          this.newContact.phone = "";
          this.action = "searching";
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //END pushContact()

    async pushVendor(addressObj) {
		this.newVendor.markup = this.newVendor.markup ?? 10
		this.newVendor.markup = this.newVendor.markup / 100
		await axios.post(process.env.VUE_APP_API_TARGET + "/vendors/" + this.$globals.companyId, {
			company: this.newVendor.company,
			address: this.newVendor.address,
			phone: this.newVendor.phone,
			vendorDiscountMinimum: this.newVendor.vendorDiscountMinimum,
			markup: this.newVendor.markup
		},
		{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.then(async (response) => {
			if(this.qbo === true){
				axios.post(`${process.env.VUE_APP_API_TARGET}/qbo/vendor/${this.$globals.companyId}`, {
					company: this.newVendor.company,
					billaddr: addressObj,
					shipaddr: addressObj
				}, {headers: {'Authorization': `Bearer ${this.$globals.user.auth0Token}`}})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

    await axios
      .get(`${process.env.VUE_APP_API_TARGET}/vendors/${this.$globals.companyId}/${1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then((response) => {
        this.allVendors = response.data.queryResult.results
        this.maxPages = response.data.queryResult.maxPages
        this.pageNumber = 1
      })
      .catch((response)=>{
        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
      })

		this.newVendor = {};
		this.isBusy = false;
		this.action = "searching";
		this.$forceUpdate();
    }, //END pushVendor()

    async pushNote() {
		var tempAuthor = {
			name: this.$auth.user.name,
        	pic: this.$auth.user.picture,
		};
		tempAuthor = JSON.stringify(tempAuthor);
		await axios.post(process.env.VUE_APP_API_TARGET + "/vendors/notes/" + this.$globals.companyId,
		{
			vendorId: this.selectedVendor.id,
			note: this.newNote.note,
			pinned: this.newNote.pinned ? this.newNote.pinned : "0",
			author: tempAuthor,
		},
		{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      await axios.get(process.env.VUE_APP_API_TARGET + "/vendors/notes/" +this.selectedVendor.id +"/" +this.$globals.companyId,
	  {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
	  .then((response) => {
		  this.allNotes[this.lastSelectedIndex] = response.data.queryResult;
	  })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/pinned/" +
            this.selectedVendor.id +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allPinnedNotes[this.lastSelectedIndex] =
            response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.action="searching"
    }, //END pushNote()

    refreshCard() {
      this.subaction = "";
      this.editIndex = "";
      this.newNote = {};
      this.newContact = {};
      this.showPreview = false;
      this.$refs.mainVendorTable.refresh();
      this.$forceUpdate();
    }, //END refreshCard()

    async removeContact(contact) {
      await axios.delete(
        process.env.VUE_APP_API_TARGET + "/vendors/" +
          contact.vendorid +
          "/contacts/" +
          contact.contactid +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/" +
            contact.vendorid +
            "/contacts/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allContacts[this.lastSelectedIndex] = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.refreshCard();
    }, //END removeContact()

    async removeLocation(location) {
      await axios.delete(
        process.env.VUE_APP_API_TARGET + "/vendors/location/" +
          location.locationid +
          "/" +
          this.$globals.companyId,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/" +
            this.selectedVendor.id +
            "/locations/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allLocations[this.lastSelectedIndex] = response.data.queryResult;
          this.$refs.storeLocations.refresh();
          this.$forceUpdate();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async removeNote(note) {
      if (note) {
        await axios.delete(
          process.env.VUE_APP_API_TARGET + "/vendors/" +
            note.vendorid +
            "/notes/" +
            note.noteid +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/notes/" +
              note.vendorid +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allNotes[this.lastSelectedIndex] = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/pinned/" +
              note.vendorid +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allPinnedNotes[this.lastSelectedIndex] =
              response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        this.refreshCard();
      }
    }, //END removeNote()

	async removeVendor(selectedVendor){
		if(confirm(`Are you sure you want to delete ${selectedVendor.company}?`)) {
			await axios.delete(`${process.env.VUE_APP_API_TARGET}/vendors/${selectedVendor.companyid}/${encodeURIComponent(selectedVendor.company)}`, {
				headers: {
					'Authorization': `Bearer ${this.$globals.user.auth0Token}`
				}})
				.then(async (response) => {
					alert("Vendor Removed")
					await axios
					.get(process.env.VUE_APP_API_TARGET + "/vendors/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.user.auth0Token }})
					.then((response) => {
						this.allVendors = response.data.queryResult.sort((vendorA, vendorB) => {
							return vendorA.company < vendorB.company ? -1 : 1
						})
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})

					selectedVendor = this.allVendors[0]
					this.rowClicked(selectedVendor);
					this.searchedVendor = selectedVendor
					this.$forceUpdate()
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
		}
	},

    async rowClicked(record) {
      this.subaction = "";
	  console.log("rowClicked record: ",record, this.selectedVendor);
      this.selectedVendor = this.selectedVendor === undefined ? {} : this.selectedVendor
	  this.selectedVendor = record === null ? this.selectedVendor : _.cloneDeep(record)
	  this.lastSelectedIndex = this.allVendors.findIndex((element) => {
		return element.id === this.selectedVendor.id;
	  });
      if (!this.selectedVendor._showDetails) {
        this.isBusy = true;
        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/priceSchedules/" +
              this.selectedVendor.id +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.priceSchedules[this.lastSelectedIndex] =
              response.data.queryResult;
            this.allActivity = [];
            this.calculateActivity();
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/inventory/vendor/items/" +
              encodeURIComponent(this.selectedVendor.company) +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allParts = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/" +
              this.selectedVendor.id +
              "/contacts/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allContacts[this.lastSelectedIndex] =
              response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/notes/" +
              this.selectedVendor.id +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allNotes[this.lastSelectedIndex] = response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/pinned/" +
              this.selectedVendor.id +
              "/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allPinnedNotes[this.lastSelectedIndex] =
              response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        await axios
          .get(
            process.env.VUE_APP_API_TARGET + "/vendors/" +
              this.selectedVendor.id +
              "/locations/" +
              this.$globals.companyId,
              {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
          )
          .then((response) => {
            this.allLocations[this.lastSelectedIndex] =
              response.data.queryResult;
          })
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

        this.isBusy = false;
      }
    }, //END rowClicked()

    async updateContact(contact) {
		console.log("passed: ", contact);
      await axios.put(
        process.env.VUE_APP_API_TARGET + "/vendors/" +
          contact.vendorid +
          "/contacts/" +
          contact.contactid +
          "/" +
          this.$globals.companyId,
        {
          name: contact.name,
          phone: contact.phone,
          email: contact.email,
          title: contact.title,
          picture: contact.picture,
		  roles: JSON.stringify(contact.role)
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/" +
            contact.vendorid +
            "/contacts/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allContacts[this.lastSelectedIndex] = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.refreshCard();
    }, //END updateContact()

    async updateNote(note) {
      await axios.patch(
        process.env.VUE_APP_API_TARGET + "/vendors/" +
          note.vendorid +
          "/notes/" +
          note.noteid +
          "/" +
          this.$globals.companyId,
        {
          note: note.note,
          pinned: note.pinned,
          author: JSON.stringify({
            name: this.$auth.user.name,
            pic: this.$auth.user.picture,
          }),
        },
        {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
      )
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/notes/" +
            note.vendorid +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allNotes[this.lastSelectedIndex] = response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/pinned/" +
            note.vendorid +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.allPinnedNotes[this.lastSelectedIndex] =
            response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      this.refreshCard();
    }, //END updateNote()

    async updateVendor() {
		this.editVendor.markup = this.editVendor.markup || 10
		this.editVendor.markup = this.editVendor.markup/100
		await axios.put(process.env.VUE_APP_API_TARGET + "/vendors/" +this.editVendor.id +"/" +this.$globals.companyId,
		{
			name: this.editVendor.company,
            phone: this.editVendor.phone,
            address: this.editVendor.address,
            vendorDiscountMinimum: this.editVendor.vendorDiscountMinimum,
			markup: this.editVendor.markup
          },
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then(async (response) => {
			await this.getVendors()
			this.selectedVendor = {...this.editVendor}
			this.action = 'searching'
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    updateVendorContactImage(e, contact) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        contact.picture = e.target.result;
      };
    },

    async uploadPriceSchedule() {
      var formData = new FormData();
      this.newPriceSchedule.uploadDate = new Date(Date.now()).toISOString().split("T")[0];

      //Used to process as relevant to date. Now assumes scheudle needs executed.
      this.newPriceSchedule.flag = 1;

      formData.append("doc", this.$refs.inputFile.files[0]);
      formData.append("effectiveDate", this.newPriceSchedule.effectiveDate);
      formData.append("uploadDate", this.newPriceSchedule.uploadDate);
      formData.append("flag", this.newPriceSchedule.flag);

      await axios
        .post(
          process.env.VUE_APP_API_TARGET + "/vendors/priceSchedule/" +
            this.selectedVendor.id +
            "/" +
            this.$globals.companyId,
          formData,
          {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          alert("The file has been uploaded");
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/vendors/priceSchedules/" +
            this.selectedVendor.id +
            "/" +
            this.$globals.companyId,
            {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
        )
        .then((response) => {
          this.priceSchedules[this.lastSelectedIndex] =
            response.data.queryResult;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Vendor', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
      this.action = "searching";
      this.newPriceSchedule = {};
      this.$refs.inputFile.value = null;
      this.$forceUpdate();
    },

    uploadVendorContactImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.newContact.picture = e.target.result;
      };
      this.showPreview = true;
    }, //END uploadVendorContactImage()
  }, //END methods
};
</script>
[]

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__tag {
  background: #dc3545;
}

.multiselect__option--highlight {
  background: #dc3545;
  outline: none;
  color: white;
}
.multiselect__option--highlight:after {
  background: #dc3545;
  outline: none;
  color: white;
}

.multiselect__tag-icon:hover {
  background: grey;
}

.note-pfp {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.scrollproperties::-webkit-scrollbar {
    display: none;
}

.scrollproperties {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>

<template>
  <div class="wrapper">
    <!-- Navbar -->
    <nav :class="{topbar: displayClass, 'navbar-white': !displayClass}" class="main-header navbar navbar-expand">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" role="button">
            <i :class="{whiteborder: displayClass, blackborder: !displayClass}" style="color: white" class="fas fa-bars"></i>
          </a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
          <a class="nav-link" :class="{contactus: displayClass, stand: !displayClass}" href="mailto:support@fleet4.net">Contact Us</a>
        </li>
      </ul>

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">

        <!-- Messages Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i :class="{whiteborder: displayClass, blackborder: !displayClass}" class="far fa-comments"></i>
            <!-- <span v-if="numberOfNotifications > 0" :class="{blackborder: displayClass}" class="badge badge-danger navbar-badge">{{numberOfMessages}}</span> -->
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right mr-3">
            <a href="#" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img
                  src="dist/img/user1-128x128.jpg"
                  alt="User Avatar"
                  class="img-size-50 mr-3 img-circle"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Brad Diesel
                    <span class="float-right text-sm text-danger"
                      ><i class="fas fa-star"></i
                    ></span>
                  </h3>
                  <p class="text-sm">Call me whenever you can...</p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
              <!-- Message End -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img
                  src="dist/img/user8-128x128.jpg"
                  alt="User Avatar"
                  class="img-size-50 img-circle mr-3"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    John Pierce
                    <span class="float-right text-sm text-muted"
                      ><i class="fas fa-star"></i
                    ></span>
                  </h3>
                  <p class="text-sm">I got your message bro</p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
              <!-- Message End -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <!-- Message Start -->
              <div class="media">
                <img
                  src="dist/img/user3-128x128.jpg"
                  alt="User Avatar"
                  class="img-size-50 img-circle mr-3"
                />
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Nora Silvester
                    <span class="float-right text-sm text-warning"
                      ><i class="fas fa-star"></i
                    ></span>
                  </h3>
                  <p class="text-sm">The subject goes here</p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
              <!-- Message End -->
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"
              >See All Messages</a
            >
          </div>
        </li>
        <!-- Notifications Dropdown Menu -->
        <li class="nav-item dropdown ml-2">
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="#"
            @click="
              notificationsOpen =
                numberOfNotifications > 0 ? !notificationsOpen : false
            "
          >
            <span v-if="numberOfNotifications > 0" :class="{blackborder: displayClass}" class="badge badge-danger navbar-badge">{{numberOfNotifications}}</span>
            <i :class="{whiteborder: displayClass, blackborder: !displayClass}" class="far fa-bell"></i>
          </a>
        </li>
        <li class="nav-item" @click="requestFullscreen">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i :class="{whiteborder: displayClass, blackborder: !displayClass}" class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.navbar -->


    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="" class="brand-link d-flex">
        <img
          :src="(fullCompany.logo)"
          alt="F4 Customer Logo"
          class="brand-image-xl m-auto"
        />
        <br />
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <div v-if="hourlyEmployee === false" class="user-panel pt-2 pb-2 d-flex" id="logout">
          <b-button class="m-auto" style="background-color: #fff4; border-color: #fff4; color: white" @click="logout">Logout</b-button>
        </div>
        <div v-else class="user-panel pt-2 pb-2 d-flex">
          <div class="col" style="margin-left: 5%">
            <b-button class="m-auto" style="background-color: #fff4; border-color: #fff4; color: white" @click="logout">Logout</b-button>
          </div>
          <div class="col" style="margin-right: 5%">
            <b-button v-if="clockEvent === 'in' || clockEvent === 'Day In'" class="m-auto" style="background-color: #fff4; border-color: #fff4; color: white" @click="handleClockOut()">Clock Out</b-button>
            <b-button v-else class="m-auto" style="background-color: #fff4; border-color: #fff4; color: white" @click="handleClockIn()">Clock In</b-button>
          </div>
        </div>
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image ml-4">
            <img
              :src="activeUser.picture"
              @error="$event.target.src='https://secure.gravatar.com/avatar/15626c5e0c749cb912f9d1ad48dba440?s=480&r=pg&d=https%3A%2F%2Fssl.gstatic.com%2Fs2%2Fprofiles%2Fimages%2Fsilhouette80.png'"
              class="img-circle elevation-3"
              alt="User Image"
            />
          </div>
          <div class="info">
            <a href="#" class="d-block" @click="activeModule = 'UserProfile'">{{
              activeUser.name
            }}</a>
          </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2" style="max-height: 80vh; overflow-y: overlay; scrollbar-color: #dc3545">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <!-- Add whiteborder to the links using the .nav-icon class
                     with font-awesome or any other icon font library -->
            <li class="nav-item menu-open danger">
              <a
                v-if="display==='Dark Mode'"
                class="nav-link active"
                style="background: #dc4535"
                @click="showCore = !showCore"
              >
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Core
                  <i v-if="showCore" class="right fas fa-angle-left"></i>
                  <i v-if="!showCore" class="right fas fa-angle-down"></i>
                </p>
              </a>
              <a
                v-else
                class="nav-link active"
                style="background: #dc3545"
                @click="showCore = !showCore"
              >
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>
                  Core
                  <i v-if="showCore" class="right fas fa-angle-left"></i>
                  <i v-if="!showCore" class="right fas fa-angle-down"></i>
                </p>
              </a>
              <ul class="nav nav-treeview" v-if="showCore">
                <li class="nav-item" 
                  @click="activeModule = 'Dashboard'">
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Dashboard' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>Dashboard</p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  v-if="canAccess.admin || canAccess.parts"
                  @click="
                    activeModule = 'InvManager';
                    newContent['InvManager'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'InvManager' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Parts
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['InvManager'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  v-if="canAccess.sales || canAccess.admin || canAccess.parts"
                  class="nav-item"
                  @click="
                    activeModule = 'Inventory';
                    newContent['Inventory'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Inventory' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Inventory
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Inventory'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  v-if="canAccess.sales || canAccess.admin || canAccess.parts"
                  class="nav-item"
                  @click="
                    activeModule = 'Sales';
                    newContent['Sales'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Sales' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Sales
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Sales'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  v-if="canAccess.dispatching || canAccess.admin || canAccess.parts || canAccess.technicians"
                  class="nav-item"
                  @click="
                    activeModule = 'DispatchCenter';
                    newContent['DispatchCenter'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'DispatchCenter' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Service
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['DispatchCenter'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
				<!-- activeModule = 'Shipping' -->
                <li
                  v-if="canAccess.sales || canAccess.admin || canAccess.parts"
                  class="nav-item"
                  @click="
                    newContent['Shipping'] === false;
                    activeModule = 'Shipping';
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Shipping' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Shipping
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Shipping'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  v-if="canAccess.sales || canAccess.admin || canAccess.parts || canAccess.dispatching"
                  class="nav-item"
                  @click="
                    activeModule = 'TechDataCenter';
                    newContent['TechDataCenter'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'TechDataCenter' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Op-Tech
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['TechDataCenter'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Customers';
                    newContent['Customers'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Customers' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Customers
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Customers'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    newContent['Vehicle'] = false;
                    activeModule = 'Vehicle';
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Vehicle' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Vehicles
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Vehicle'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Vendor';
                    newContent['Vendor'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Vendor' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Vendors
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Vendor'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Notifications';
                    newContent['Notifications'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Notifications' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Notifications
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Notifications'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item menu-open danger" v-if="canAccess.admin">
              <a
                v-if="display==='Dark Mode'"
                class="nav-link active"
                style="background: #dc4535"
                @click="showManagement = !showManagement"
              >
                <i class="nav-icon fas fa-tools"></i>
                <p>
                  Management
                  <i v-if="showManagement" class="right fas fa-angle-left"></i>
                  <i v-if="!showManagement" class="right fas fa-angle-down"></i>
                </p>
              </a>
              <a
                v-else
                class="nav-link active"
                style="background: #dc3545"
                @click="showManagement = !showManagement"
              >
                <i class="nav-icon fas fa-tools"></i>
                <p>
                  Management
                  <i v-if="showManagement" class="right fas fa-angle-left"></i>
                  <i v-if="!showManagement" class="right fas fa-angle-down"></i>
                </p>
              </a>
				<ul class="nav nav-treeview" v-if="showManagement">
					<li
						class="nav-item"
						@click="
						activeModule = 'AdminDash';
						newContent['AdminDash'] = false;
						"
					>
						<a class="nav-link" :class="{ active: activeModule === 'AdminDash' }">
							<i class="far fa-circle nav-icon"></i>
							<p>
								Admin Dashboard
								<span
									class="right badge badge-danger"
									v-if="newContent['AdminDash'] === true">New
								</span>
							</p>
						</a>
					</li>

                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Stats';
                    newContent['Stats'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Stats' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Business Reporting
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Stats'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Import';
                    newContent['Import'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Import' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Data Management
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Import'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Invoicing';
                    newContent['Invoicing'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Invoicing' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Invoicing<span
                        class="right badge badge-danger"
                        v-if="newContent['Invoicing'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    activeModule = 'Admin';
                    newContent['Admin'] = false;
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'Admin' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      User Management
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['Admin'] === true"
                        >New</span
                      >
                    </p>
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item menu-open danger">
              <a
                v-if="display==='Dark Mode'"
                class="nav-link active"
                style="background: #dc4535"
                @click="showAddons = !showAddons"
              >
                <i class="nav-icon fas fa-puzzle-piece"></i>
                <p>
                  Add-ons
                  <i v-if="showAddons" class="right fas fa-angle-left"></i>
                  <i v-if="!showAddons" class="right fas fa-angle-down"></i>
                </p>
              </a>
              <a
                v-else
                class="nav-link active"
                style="background: #dc3545"
                @click="showAddons = !showAddons"
              >
                <i class="nav-icon fas fa-puzzle-piece"></i>
                <p>
                  Add-ons
                  <i v-if="showAddons" class="right fas fa-angle-left"></i>
                  <i v-if="!showAddons" class="right fas fa-angle-down"></i>
                </p>
              </a>
              <ul class="nav nav-treeview" v-if="showAddons">
                <li
                  class="nav-item"
                  @click="
                    () => {
                      if (canAccess.crm) {
                        activeModule = 'CRM';
                        newContent['CRM'] = false;
                      }
                    }
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'CRM' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Marketing
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['CRM'] === true"
                        >New</span
                      >
                      <span
                        class="right badge badge-light"
                        v-if="!canAccess.crm"
                        >Buy Now!</span
                      >
                    </p>
                  </a>
                </li>
                <li
                  class="nav-item"
                  @click="
                    () => {
                      if (canAccess.placeholder) {
                        activeModule = 'PLACEHOLDER';
                        newContent['PLACEHOLDER'] = false;
                      }
                    }
                  "
                >
                  <a
                    class="nav-link"
                    :class="{ active: activeModule === 'PLACEHOLDER' }"
                  >
                    <i class="far fa-circle nav-icon"></i>
                    <p>
                      Other
                      <span
                        class="right badge badge-danger"
                        v-if="newContent['PLACEHOLDER'] === true"
                        >New</span
                      >
                      <span
                        class="right badge badge-light"
                        v-if="!canAccess.placeholder"
                        >Buy Now!</span
                      >
                    </p>
                  </a>
                </li>
              </ul>
            </li>
            <!-- <li class="nav-item">
                  <a href="#" class="nav-link">
                    <i class="nav-icon fas fa-th"></i>
                    <p> Simple Link <span class="right badge badge-danger">New</span> </p>
                  </a>
                </li> -->
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid"></div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <div class="content">
        <div ref="comp" class="container-fluid">
          <div v-if="activeModule === 'Dashboard'"><dashboard :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></dashboard></div>
          <div v-if="activeModule === 'UserProfile'"><user-profile :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></user-profile></div>
          <div v-if="activeModule === 'InvManager'"><inv-manager :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></inv-manager></div>
          <div v-if="activeModule === 'Inventory'"><inventory :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></inventory></div>
          <div v-if="activeModule === 'Sales'"><sales :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></sales></div>
          <div v-if="activeModule === 'DispatchCenter'"><dispatch-center :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></dispatch-center></div>
		      <div v-if="activeModule === 'Vehicle'"><vehicle :socket="socket" @refreshMain="handleRefreshMain" class="dynamicComponent"></vehicle></div>
		      <div v-if="activeModule === 'Shipping'"><shipping :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></shipping></div>
          <div v-if="activeModule === 'TechDataCenter'"><tech-data-center :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></tech-data-center></div>
          <div v-if="activeModule === 'Customers'"><customers :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></customers></div>
          <div v-if="activeModule === 'Vendor'"><vendor :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></vendor></div>
          <div v-if="activeModule === 'Notifications'"><notifications :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></notifications></div>
          <div v-if="activeModule === 'AdminDash'"><admin-dash :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></admin-dash></div>
          <div v-if="activeModule === 'Stats'"><stats :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></stats></div>
          <div v-if="activeModule === 'Import'"><import :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></import></div>
          <div v-if="activeModule === 'Invoicing'"><invoicing :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></invoicing></div>
          <div v-if="activeModule === 'Admin'"><admin :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></admin></div>
          <div v-if="activeModule === 'CRM'"><CRM :user="activeUser" :socket="socket" :companyId="companyId" :companyName="companyName" :userRoles="userRoles" :canAccess="canAccess" :qbo="qboConnected" :taxRate="localTaxRate" :displayClass="displayClass" @refreshMain="handleRefreshMain" class="dynamicComponent"></CRM></div>
          <div
            v-if="notificationsOpen"
            class="card"
            style="
              z-index: 5;
              max-height: 25%;
              max-width: 15%;
              overflow-y: scroll;
              position: fixed;
              top: 55px;
              right: 75px;
            "
          >
            <div
              class="fas"
              style="text-align: center; margin-top: 10px"
              @click="removeAllNotifications(activeUser.sub)"
            >
              <span
                style="
                  font-family: Arial, sans-serif;
                  font-weight: initial;
                  font-size: 10pt;
                "
                >Clear All</span
              >
              &nbsp; <i class="fas fa-times"></i>
              <hr />
            </div>

            <div
              v-for="notification in allNotifications"
              :key="notification.id"
              style="margin-left: 10px; margin-right: 10px"
            >
              <div style="margin-top: 5px">
                <span>{{ notification.message }}</span> <br />
                <span style="font-size: 8pt; color: #7d7d7d">{{
                  notification.date
                }}</span>
              </div>
              <i
                class="fas fa-times"
                style="position: absolute; right: 10px"
                @click="changeNotificationStatus(notification, activeUser.sub)"
              ></i>
              <hr />
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

	<div v-if="$globals.shield" class="shield"> </div>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
      <div class="p-3">
        <h5>Title</h5>
        <p>Sidebar content</p>
      </div>
    </aside>
    <!-- /.control-sidebar -->

    <!-- Main Footer -->
    <footer class="main-footer">
      <!-- To the right -->
      <div class="float-right d-none d-sm-inline">
        <button
          v-if="!qboConnected"
          style="background: none; border: none"
          @click="connectToQuickBooks()"
        >
          <img
            style="margin-top: -2%"
            src="../assets/Connect_to_QuickBooks_SVG/C2QB_transparent_btn_med_hover.svg"
          />
        </button>
        <b-button
          v-if="qboConnected"
          style="background: #dc3545; border-color: #dc3545; margin-top: -3.5%"
          @click="disconnectQuickBooks()"
          >Disconnect QuickBooks™</b-button
        >
      </div>

      <!-- Default to the left -->
      <strong
        >Copyright &copy; 2021
        <a :class="{dark: displayClass, stand: !displayClass}" href="https://fleet4.com">Fleet 4, LLC</a
        >.</strong
      >
      All rights reserved.
    </footer>
  </div>
  <!-- ./wrapper -->
</template>

<script>
import axios from "axios";
import InvManager from "./InvManager.vue";
import DispatchCenter from "./Dispatching.vue";
import TechDataCenter from "./TechDataCenter.vue";
import Customers from "./Customers.vue";
import Sales from "./Sales.vue";
import Inventory from "./Inventory.vue";
import Invoicing from "./Invoicing.vue";
import Vehicle from "./Vehicles.vue";
import Shipping from "./Shipping.vue";
import Admin from "./Admin.vue";
import Dashboard from "./Dashboard.vue";
import Notifications from "./Notifications.vue";
import Import from "./DataManagement.vue";
import { io } from "socket.io-client";
import Vendor from "./Vendor.vue";
import CRM from "./CRM.vue";
import UserProfile from "./Users.vue";
import Stats from "./BusinessIntelligence.vue";
import AdminDash from "./AdminDashboard.vue";
import { Translator } from 'vue-google-translate';
import html2canvas from 'html2canvas'


import '../../node_modules/admin-lte/dist/js/adminlte.min.js'

import '../../node_modules/admin-lte/dist/js/adminlte.min.js'


//var socket;
export default {
  /*eslint-disable*/
  components: {
    InvManager,
    DispatchCenter,
    TechDataCenter,
    Customers,
    CRM,
    Sales,
	Vehicle,
    Shipping,
    Admin,
    Inventory,
    Invoicing,
    Dashboard,
    Notifications,
    Import,
    Vendor,
    UserProfile,
    Stats,
    AdminDash,
    Translator
  },
  name: "app",

  data() {
    return {
      activeModule: "",
      activeUser: null,
      allNotifications: {},
      canAccess: {
        dashboard: true,
        inventory: false,
        parts: false,
        dispatching: false,
        sales: false,
        stdc: false,
        customers: false,
        crm: false,
        reminders: false,
        shipping: false,
		vehicle: false,
        admin: false, //should be false
        import: false,
        vendor: false,
        stats: false,
        adminDash: false,
        userProfile: true,
      },
      clockEvent: "",
      companyId: -1,
      companyName: "",
      connectedRooms: [],
      hourlyEmployee: false,
	    displayClass: false,
	    display: null,
      localTaxRate: -1,
      fullCompany: {},
      profilePic: null,
      modules: [
        "InvManager",
        "DispatchCenter",
        "TechDataCenter",
        "Customers",
        "CRM",
		"Vehicle",
        "Shipping",
        "Import",
        "Inventory",
        "Invoicing",
        "Sales",
        "Admin",
        "Dashboard",
        "Notifications",
        "Stats",
        "Vendor",
        "UserProfile",
		"AdminDash",
      ],
      newContent: {
        InvManager: false,
        Inventory: false,
        DispatchCenter: false,
        Shipping: false,
		Vehicle: true,
        TechDataCenter: false,
        Customers: false,
        CRM: false,
        Sales: false,
        Admin: false,
        Dashboard: false,
        Import: false,
        Vendor: false,
        Stats: false,
		AdminDash: false,
        UserProfile: false,
      },
      notificationsOpen: false,
      qboConnected: false,
      showing: "dashboard",
      showCore: true,
      showManagement: false,
      showAddons: false,
      socket: null,
      userRoles: [],
    };
  },

  computed: {
    numberOfMessages(){
      return 3;
    },

    numberOfNotifications() {
      let number = 0;
      for(let check in this.allNotifications){
        if(this.allNotifications[check].status === 0){
          number++;
        }
      }
      return number;
    },
  },

  async created() {
    await this.refreshActiveUser();
  },

  async mounted() {
    await this.refreshActiveUser();
    if(this.companyId < 0){
      //exists to handle race condition of passing -1 to frontend
      await this.getCompanyId(this.companyName);
    }
      this.socket = io(process.env.VUE_APP_API_TARGET + "/", {
        reconnection: true
    });

    this.socket.on("newNotification", async (title, message, date) => {
      this.$vToastify.success(`${message}\n \n${date}`, title);
      await axios
        .get(
          process.env.VUE_APP_API_TARGET + "/notifications/user/" +
            this.activeUser.sub +
            "/" +
            this.companyId +
            "/" +
            0,
          { headers: { Authorization: "Bearer " + this.activeUser.auth0Token } }
        )
        .then((response) => {
          this.allNotifications = response.data.queryResult.reverse();
          this.$forceUpdate();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    });

    this.socket.on("newQBOData", async () => {
        await axios
        .get(`${process.env.VUE_APP_API_TARGET}/qbo/checkTokens/${this.companyId}`, {
            headers: { Authorization: "Bearer " + this.activeUser.auth0Token },
        })
        .then((response) => {
            this.qboConnected = response.data.connected;
        })
        .catch((response)=>{
            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
        })
    })

    this.$el.addEventListener('returnDocument', e => this.handleReturnDocument(e.detail));

	this.$el.addEventListener('errorOccured', e => this.handleErrorResponse(e.detail));

    this.socket.emit("joinRoom", `main${this.companyId}`)

    this.socket.emit(
        "storeClientDetails",
        this.activeUser.sub,
        this.userRoles,
        this.companyId
    );
    await axios.get(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.activeUser.sub}/${this.companyId}`,
        {headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
    .then(response => {
        this.display = response.data.queryResult[0].display;
        if(this.display === "Standard"){
          document.documentElement.className = "standard"
        }
        else{
          document.documentElement.className = "dark-mode";
        }
        this.displayMode()
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

	  //WARNING This should only execute if user has the hourlyEmployee role. Appears to be causing issues if user doesn't
	  //NOTE: James put in a fix to make sure that length is positive before it tries to read into anything.
    await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clockevent/${this.activeUser.name}/${this.companyId}`,
      {headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
      .then(response => {
		  if(response.data.queryResult.length > 0){
			  let event = JSON.parse(response.data.queryResult[0].lastEvent);
              this.clockEvent = event.type
            console.log("Result event:", event)
          } else {
            console.log("Missed the condition:", response.data)
          }
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    this.activeModule = "Dashboard"

    await axios
      .get(
        process.env.VUE_APP_API_TARGET + "/notifications/user/" +
          this.activeUser.sub +
          "/" +
          this.companyId +
          "/" +
          0,
        { headers: { Authorization: "Bearer " + this.activeUser.auth0Token } }
      )
      .then((response) => {
        this.allNotifications = response.data.queryResult.reverse();
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

    await axios
      .get(`${process.env.VUE_APP_API_TARGET}/qbo/checkTokens/${this.companyId}`, {
        headers: { Authorization: "Bearer " + this.activeUser.auth0Token },
      })
      .then((response) => {
        this.qboConnected = response.data.connected;
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

    this.profilePic = this.activeUser.picture;
    this.$forceUpdate()
  },

  methods: {
    async checkRoleAccess() {
      let roleNames = [];
      for (let role in this.userRoles) {
        roleNames.push(this.userRoles[role].name);
      }
      for (let role in roleNames) {
        if (roleNames[role].includes("Admin") || roleNames[role].includes("Accountant")) {
          for (let mod in this.canAccess) {
            if (mod !== "crm") {
              this.canAccess[mod] = true;
            }
            this.canAccess.admin = true;
          }
        }

        if (roleNames[role].includes("Dispatcher")) {
          this.canAccess.dispatching = true;
          this.canAccess.customers = true;
          this.canAccess.stdc = true;
          this.canAccess.vendor = true;
		  this.canAccess.vehicle = true;
        }

        if (roleNames[role].includes("Parts")) {
          this.canAccess.inventory = true;
          this.canAccess.parts = true;
          this.canAccess.customers = true;
          this.canAccess.reminders = true;
          this.canAccess.shipping = true;
		  this.canAccess.vehicle = true;
          this.canAccess.vendor = true;
        }

        if (roleNames[role].includes("Sales")) {
          this.canAccess.sales = true;
          this.canAccess.inventory = true;
          this.canAccess.customers = true;
          this.canAccess.reminders = true;
          this.canAccess.shipping = true;
          this.canAccess.vendor = true;
        }

        if (roleNames[role].includes("Technician")) {
          this.canAccess.dispatching = true;
          this.canAccess.parts = true;
          this.canAccess.stdc = true;
          this.canAccess.customers = true;
          this.canAccess.vendor = true;
		  this.canAccess.vehicle = true;
        }
        if (
          !(
            roleNames[role].includes("Admin") ||
            roleNames[role].includes("Dispatcher") ||
            roleNames[role].includes("Parts") ||
            roleNames[role].includes("Sales") ||
            roleNames[role].includes("Technician") ||
            roleNames[role].includes("Accountant") ||
            roleNames[role].includes("Hourly Employee")
          )
        ) {
          this.companyName = roleNames[role];
          await this.getCompanyId(this.companyName);
          await axios
            .get(`${process.env.VUE_APP_API_TARGET}/companies/${this.companyId}`, {
              headers: {
                Authorization: `Bearer ${this.activeUser.auth0Token}`,
              },
            })
            .then(async (response) => {
              if (response.data.queryResult) {
                let addons = JSON.parse(response.data.queryResult[0].addons);
                for (let index in addons) {
                  this.canAccess[addons[index]] = true;
                }

                this.$globals.canAccess = this.canAccess;
                this.$globals.companyId = this.companyId;
                this.$globals.companyName = this.companyName
                this.$globals.qbo = this.qboConnected;
                this.$globals.socket = this.socket;
                this.$globals.user = this.activeUser;
                this.$globals.user.roles = this.userRoles;
                this.$globals.displayClass = this.displayClass;

                let zip = response.data.queryResult[0].address.slice(-5)
                // await this.getLocalTaxRate(zip)
              }
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        }
      } //END for[role]
      this.$forceUpdate()
    }, //END checkRoleAccess()

    displayMode() {
      if(this.display==="Dark Mode"){
        this.displayClass = true
      }
      else{
        this.displayClass = false
      }
    },

    async getLocalTaxRate(zipcode){
      let src = this


      await axios.get('https://sandbox-rest.avatax.com/api/v2/taxrates/bypostalcode?country=US&postalCode='+zipcode, {
        headers: {
          'Authorization': `Basic ${btoa('2001694309' + ':' + '5952D5EFBB39EF41')}`
        }
      })
        .then(function (response) {
          src.localTaxRate = response.data.totalRate;
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async getCompanyId(companyName) {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/companies/", {
          headers: { Authorization: "Bearer " + this.activeUser.auth0Token },
        })
        .then((response) => {
          let ary = response.data.queryResult;
          for (let i = 0; i < ary.length; i++) {
            if (ary[i].name === companyName) {
              this.fullCompany = response.data.queryResult[i]
              this.fullCompany.logo = this.fullCompany.logo === null ? 'https://i.imgur.com/EmTCcIB.png' : this.fullCompany.logo
              this.companyId = ary[i].companyid;
              break;
            } else {
              //This shouldn't happen else the company doens't exist.
            }
          } //end for(i)
        })
          .catch((response) => {
            console.log("error on companyid", response)
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //END getCompanyId()

    async connectToQuickBooks() {
        if(this.canAccess.admin){
            await axios
              .get(`${process.env.VUE_APP_API_TARGET}/qbo/requestToken/${this.companyId}`, {
                headers: { Authorization: "Bearer " + this.activeUser.auth0Token },
              })
              .then((response) => {
                // console.log(response.data)
                window.open(response.data.authUri, "_self");
              })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
        }
		else {
            alert("Contact your administrator to connect to QuickBooks!")
        }
    },

    async disconnectQuickBooks() {
        if(this.canAccess.admin){
            await axios
                .post(
                    `${process.env.VUE_APP_API_TARGET}/qbo/disconnect/${this.companyId}`,
                    { headers: { Authorization: "Bearer " + this.activeUser.auth0Token }
                })
                .then((response) => {
                    this.qboConnected = response.data.connected;
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
        } else {
            alert("Contact your administrator to disconnect your QuickBooks account!")
        }
    },

    async getUserRoles(userId) {
      await axios
        .get(process.env.VUE_APP_API_TARGET + "/users/" + encodeURIComponent(userId) + "/roles/", {
          headers: { Authorization: "Bearer " + this.activeUser.auth0Token },
        })
        .then((response) => {
          this.userRoles = response.data.queryResult;
          for(var i = 0; i < this.userRoles.length; i++){
            if(this.userRoles[i].name === "Hourly Employee"){
              this.hourlyEmployee = true
              break
            }
          }
          this.checkRoleAccess();
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    }, //END getUserRoles()

    async handleClockIn(){
      // setStatus('idle')
      // setClockedIn(true)
      let time_sheets;

      await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clock_in/${this.activeUser.sub}/${this.companyId}`,
      { headers: { Authorization: "Bearer " + this.activeUser.auth0Token }, })
      .then((response) => {
        time_sheets = JSON.parse(response.data.queryResult[0].time_sheet)
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      let last_in = {
        year: null,
        weeks: [
          {
            weekNumber: null,
            days: [
              {
                day: null,
                clock_set: []
              }
            ]
          }
        ]
      }

      let currentDay = new Date().toLocaleDateString()
      if(time_sheets === null){
        time_sheets = []
        let yearSet = new Date().getFullYear()

        let now = new Date()
        let oneJan = new Date(now.getFullYear(), 0, 1)
        let weekSet = Math.ceil((((now.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7)

        last_in.year = yearSet
        last_in.weeks[0].weekNumber = weekSet
        last_in.weeks[0].days[0].day = currentDay
        last_in.weeks[0].days[0].clock_set.push({clock_in: new Date().toLocaleString(), clock_out: null})
        time_sheets.push(last_in)
      }
      else{
        let weeks = time_sheets[time_sheets.length - 1].weeks
        let days = time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days

        let yearSet = new Date().getFullYear()

        let now = new Date()
        let oneJan = new Date(now.getFullYear(), 0, 1)
        let weekSet = Math.ceil((((now.getTime() - oneJan.getTime()) / 86400000) + oneJan.getDay() + 1) / 7)

        let findYear = time_sheets.find(object => object.year === yearSet)

        if(findYear === undefined){
          last_in.year = yearSet
          last_in.weeks[0].weekNumber = weekSet
          last_in.weeks[0].days[0].day = currentDay
          last_in.weeks[0].days[0].clock_set.push({clock_in: new Date().toLocaleString(), clock_out: null})
          time_sheets.push(last_in)
        }
        else{
          let findWeek = findYear.weeks.find(object => object.weekNumber === weekSet)

          if(findWeek === undefined){
            let week_insert = {
              weekNumber: weekSet,
              days: [
                {
                  day: currentDay,
                  clock_set: [{clock_in: new Date().toLocaleString(), clock_out: null}],
                }
              ]
            }
            time_sheets[time_sheets.length - 1].weeks.push(week_insert)
          }
          else{
            let findDay = findWeek.days.find(object => object.day === currentDay)

            if(findDay === undefined){
              let day_insert = {
                day: currentDay,
                clock_set: [{clock_in: new Date().toLocaleString(), clock_out: null}],
              }
              time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days.push(day_insert)
            }
            else{
              time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days[days.length - 1].clock_set.push({clock_in: (new Date().toLocaleString()), clock_out: null})
            }
          }
        }
      }

      await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/update/clock_in/${this.activeUser.sub}/${this.companyId}`, {
        in: JSON.stringify(time_sheets),
      },
      { headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + this.activeUser.auth0Token }, })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      let lastClock = {
        'tech': this.activeUser.name,
        'time': new Date().toLocaleString(),
        'type': 'Day In'
      }

      await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/${this.activeUser.sub}/lastEvent/${this.companyId}`, {
        lastEvent: JSON.stringify(lastClock)
      },
      { headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + this.activeUser.auth0Token }, })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clockevent/${this.activeUser.name}/${this.companyId}`,
        {headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
        .then(response => {
          if(response.data.queryResult.length > 0){
			  let event = JSON.parse(response.data.queryResult[0].lastEvent);
              this.clockEvent = event.type
            console.log("Result event:", event)
          } else {
            console.log("Missed the condition:", response.data)
          }
          this.$forceUpdate()
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    async handleClockOut(){
      let time_sheets

      await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clock_out/${this.activeUser.sub}/${this.companyId}`,
      { headers: { Authorization: "Bearer " + this.activeUser.auth0Token }, })
      .then((response) => {
        time_sheets = JSON.parse(response.data.queryResult[0].time_sheet)
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      let weeks = time_sheets[time_sheets.length - 1].weeks
      let days = time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days
      let clockSet = time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days[days.length - 1].clock_set

      time_sheets[time_sheets.length - 1].weeks[weeks.length - 1].days[days.length - 1].clock_set[clockSet.length - 1].clock_out = new Date().toLocaleString()



      await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/update/clock_out/${this.activeUser.sub}/${this.companyId}`, {
        out: JSON.stringify(time_sheets),
      },
      { headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + this.activeUser.auth0Token }, })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      let lastClock = {
        'tech': this.activeUser.name,
        'time': new Date().toLocaleString(),
        'type': 'Day Out'
      }

      await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/${this.activeUser.sub}/lastEvent/${this.companyId}`, {
        lastEvent: JSON.stringify(lastClock)
      },
      { headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + this.activeUser.auth0Token }, })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })

      await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clockevent/${this.activeUser.name}/${this.companyId}`,
        {headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
        .then(response => {
          let event = JSON.parse(response.data.queryResult[0].lastEvent);
          this.clockEvent = event.type
          this.$forceUpdate()
      })
	  .catch((response)=>{
		  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
	  })
    },

    handleRefreshMain(){
    	axios.get(`${process.env.VUE_APP_API_TARGET}/users/user_preferences/${this.activeUser.sub}/${this.companyId}`,
			{headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
			.then(response => {
				this.display = response.data.queryResult[0].display;
				if(this.display === "Standard"){
					document.documentElement.className = "standard"
				}
				else{
					document.documentElement.className = "dark-mode";
				}
				axios.get(process.env.VUE_APP_API_TARGET + "/users/"+this.activeUser.sub + "/profile/" + this.companyId,
				{headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
				.then(response =>{
					this.activeUser.picture = response.data.queryResult.picture
					this.profilePic = this.activeUser.picture;
					this.displayMode()
                    this.$forceUpdate()
                    axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/hourlyemployee/clockevent/${this.activeUser.name}/${this.companyId}`,
                        {headers: {'Authorization':'Bearer ' + this.activeUser.auth0Token }})
                        .then(response => {
                            if(response.data.queryResult.length > 0){
                                let event = JSON.parse(response.data.queryResult[0].lastEvent);
                                this.clockEvent = event.type
                                console.log("Result event:", event)
                            } else {
                                console.log("Missed the condition:", response.data)
                            }
                            this.$forceUpdate()
                        })
                        .catch((response)=>{
                            this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
                        })
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

    },

  async handleReturnDocument(arg){
    let htmlContent = arg.content
    await html2canvas(htmlContent, {scale: 1}).then(async (canvas) => {
      canvas.toBlob(function(blob) {
        window.saveAs(blob, `${arg.company.company}_${arg.time}`)
      })

    })
  },

	async handleErrorResponse(arg){
		console.log("Encountered an API error, Arg: ",arg);
        console.log()
        console.log(process.env.VUE_APP_API_TARGET)
		let htmlContent = document.documentElement
		let pic = null
		if(arg.error.response !== undefined){
            if (arg.error.response.status === 401 || arg.error.response.data.error.status === 401) {
                // FIXME: NOT GRABING INITIAL REFRESH TOKEN && ROUTE CRASHES ON API
                // axios.post(`${process.env.VUE_APP_API_TARGET}/users/refresh_token`, {
                //     refresh_token: this.$globals.user.refresh_token
                // }, {
                //     headers: {
                //         'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                //     }
                // }).then(response => {
                //     this.$globals.user.refresh_token = response.data.queryResult.refresh_token
                //     this.$globals.user.auth0Token = response.data.queryResult.access_token
                // })
            } else {
                await html2canvas(htmlContent, {scale: 1}).then(async (canvas)=>{
                    var imgData = canvas.toDataURL("image/png");
                    await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`,{
                        pic: imgData
                    }, {headers: {'Authorization': `Bearer ${arg.user.auth0Token}`}})
                    .then((response)=>{
                        pic = response.data.queryResult
                    })
                })
                axios.post(`${process.env.VUE_APP_API_TARGET}/twilio/support/errorlog`, {
                    from: arg.user.email,
                    subject: `Error Report by ${arg.user.name} from ${this.companyName} at ${arg.time}`,
                    html: `<!DOCTYPE html>
                        <head>
                            <title>Error Report from ${arg.user.name} at ${this.companyName}</title>
                        </head>
                        <body>
                            <p><b>Component</b>: ${arg.component}</p>
                            <p><b>Time</b>: ${arg.time}</p>
                            <p><b>User Email</b>: ${arg.user.email}</p>
                            <p><b>User auth0</b>: ${arg.user.sub}</p>
                            <p><b>Route</b>: ${arg.error.config.method} ${arg.error.config.url}</p>
                            <p><b>Error</b>: ${arg.error.response.data.message || arg.error} - ${JSON.stringify(arg.error.response.data.error)} - Error No: ${arg.error.response.status || arg.error.response.data.error.status}</p>
                            <img src='${pic}' alt="Pic of error" style='max-width:8.35in'/>
                        </body>
                        </html>`,
                }, { headers: { 'Authorization': 'Bearer ' + arg.user.auth0Token } })
                    .then(response => {
                        this.$vToastify.error(`Support has been notified ${arg.error.response.data.message || arg.error}`)
                    })
                    .catch(response => {
                        this.$vToastify.error(`Please contact support about error: ${arg.error.response.data.message || arg.error}`)
                    })
            }
		}
		else{
			this.$vToastify.error(`Unexpected Error. Please call (314)937-5253 about ${arg.error}`)
		}


	},

    joinRoom(event) {
      this.connectedRooms.push(event);
    }, //END joinRoom()

    async login() {
      await this.$auth.loginWithRedirect();
      await this.refreshActiveUser();
    }, //END login()

    async logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    }, //END logout()

    async refreshActiveUser() {
      if (this.$auth.isAuthenticated && !this.$auth.isLoading) {
        this.activeUser = this.$auth.user;
        this.activeUser.auth0Token = await this.$auth.getTokenSilently();
        console.log("got auth token")
        await this.getUserRoles(this.$auth.user.sub);
      } else {
        this.activeUser = null;
      }
    }, //END refreshActiveUser()

    requestFullscreen() {
      // this.$refs.comp.requestFullscreen()
      if (document.fullscreenElement == null) {
        document.documentElement.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },

    async changeNotificationStatus(notifObj, userid) {
      await axios
        .patch(
          `${process.env.VUE_APP_API_TARGET}/notifications/user/${userid}/${notifObj.id}/${this.companyId}/${1}`,
          null,
          { headers: { Authorization: "Bearer " + this.activeUser.auth0Token } }
        )
        .then(async (response) => {
          await axios
            .get(
              process.env.VUE_APP_API_TARGET + "/notifications/user/" +
                this.activeUser.sub +
                "/" +
                this.companyId +
                "/" +
                0,
              {
                headers: {
                  Authorization: "Bearer " + this.activeUser.auth0Token,
                },
              }
            )
            .then((response) => {
              this.allNotifications = response.data.queryResult.reverse();
              if (this.allNotifications.length === 0) {
                this.notificationsOpen = false;
              }
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },

    async removeAllNotifications(userid) {
      await axios
        .patch(
          `${process.env.VUE_APP_API_TARGET}/notifications/user/${userid}/${this.companyId}/${1}`,
          null,
          { headers: { Authorization: "Bearer " + this.activeUser.auth0Token } }
        )
        .then(async (response) => {
          await axios
            .get(
              process.env.VUE_APP_API_TARGET + "/notifications/user/" +
                this.activeUser.sub +
                "/" +
                this.companyId +
                "/" +
                0,
              {
                headers: {
                  Authorization: "Bearer " + this.activeUser.auth0Token,
                },
              }
            )
            .then((response) => {
              this.allNotifications = response.data.queryResult;
              this.notificationsOpen = false;
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        })
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Main', 'user' : this.activeUser, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})
    },
  }, //END methods
};
</script>

<style>
body {
  top: 0 !important;
}
/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}
</style>

<style scoped>

.contactus{
   color: white !important;
   background-color: #fff4 !important
}
.whiteborder{
  color: white !important
}
.blackborder{
  background-color: white !important;
  color: black !important
}
.topbar {
	background-color: #dc4535 !important;
	border-color: #dc4535 !important
}
.stand{
  color: #dc3545 !important;
  background-color: white !important
}
.dark{
  color: #dc4535 !important;
  background-color: #343a40 !important
}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-thumb {
  color: #dc3545;
}

.container-fluid:fullscreen {
  background-color: rgba(255, 255, 255, 0.85);
}

.container-fluid:fullscreen >>> .dynamicComponent {
  margin-top: 1%;
}

.shield {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
	display: block;
	width: 250px;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1040;
}

.fas:hover,
.far:hover {
  color: #dc3545;
}
</style>

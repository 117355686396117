<template>
	<div>
		<div v-if="action === ''">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Apparatus Information</b></h3>
					</div>
					<div class="card-tools">
						<b-button v-if="subaction !== 'editVehicle'" style="background-color: #fff4; border-color: #dc3545; color: #ffffff;" @click.prevent="editVehicle()">Edit</b-button>
						<b-button v-if="subaction == 'editVehicle'" style="background-color: #fff4; border-color: #dc3545; color: #ffffff;" @click.prevent="selectedVehicle = tempVehicle; selectedCustomer = originalCustomer; newAddress = {} ; subaction='' ">Cancel</b-button>
						<b-button v-if="subaction == 'editVehicle'" style="background-color: #fff4; border-color: #dc3545; color: #ffffff;" @click.prevent="updateVehicle(selectedVehicle)">Update</b-button>
						<b-button style="background-color: #fff4; border-color: #dc3545; color: #ffffff;" @click.prevent="$emit('return')">Return</b-button>
					</div>
				</div>
				<div class="card-body">
					<div>
						<div style="display: flex; justify-content: center">
							<h3>Customer: </h3>
							<h3><b v-if="subaction !== 'editVehicle'">{{ originalCustomer.name }}</b></h3>
							<multiselect
								v-if="subaction === 'editVehicle'"
								style="display: inline-block; width:50%;, height: 100px;"
								label="name"
								track-by="customerid"
								:clearable="true"
								:show-labels="false"
								:options="allCustomers"
								:optionsLimit="10000"
								v-model="selectedCustomer"
								@input="rowClicked"
								placeholder="Customer"
								@search-change="debounceCustomers"
								:loading="customerListIsLoading"
							>
								<template slot="option" slot-scope="props">
									<intersect required v-if="props.option.name === allCustomers[allCustomers.length - 1].name" @enter="getMoreCustomers">
										<span>{{props.option.name}}</span>
									</intersect>
								</template>
							</multiselect>
						</div>
						<div v-if="subaction === 'editVehicle'" style="display: flex; justify-content: center">
							<b-row>
								<h3>Apparatus ID:</h3>
								<input required type="text" class="form-control" style="display: inline-block" placeholder="Vehicle ID" v-model="selectedVehicle.vehicleid" />
							</b-row>
						</div>
						<div v-else style="display: flex; justify-content: center">
							<h3>Apparatus ID: {{ selectedVehicle.vehicleid }}</h3>
							<i v-if="$globals.canAccess.admin === true" class="fas fa-trash mt-2" @click.stop="removeVehicle(selectedVehicle)"></i>
						</div>

						<hr>
						<b-tabs fill lazy v-model="selectedTab">
							<b-tab title="General" :title-link-class="'text-danger'">
								<div v-if="subaction !== 'editVehicle'" style="margin-top:2%">
									<b-row>
										<b-col>
											<strong>Year: &nbsp;</strong> {{ selectedVehicle.year }}
										</b-col>

										<b-col>
											<strong>Make: &nbsp;</strong> {{ selectedVehicle.make }}
										</b-col>

										<b-col>
											<strong>Model: &nbsp;</strong> {{ selectedVehicle.model }}
										</b-col>
										<b-col>
											<strong>VIN: &nbsp;</strong> {{ selectedVehicle.vin }}
										</b-col>
									</b-row>

									<b-row>
										<b-col cols="3">
											<strong>Hours: &nbsp;</strong> {{ selectedVehicle.hours }}
										</b-col>

										<b-col cols="3">
											<strong>Miles: &nbsp;</strong> {{ selectedVehicle.miles }}
										</b-col>

										<b-col cols="3">
											<strong>Location: &nbsp;</strong> {{ selectedVehicle.address }}
										</b-col>

									</b-row>

									<b-row>
										<b-col>
											<strong>Note: &nbsp;</strong> {{selectedVehicle.notes}}
										</b-col>
									</b-row>
								</div>

								<div v-else>
									<b-row>
										<b-col>
											<strong style="display: inline-block">Year: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="Year" v-model="selectedVehicle.year" />

										</b-col>

										<b-col>
											<strong style="display: inline-block">Make: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="Make" v-model="selectedVehicle.make" />
										</b-col>

										<b-col>
											<strong>Model: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="Model" v-model="selectedVehicle.model" />

										</b-col>
										<b-col>
											<strong>VIN: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="VIN" v-model="selectedVehicle.vin" />

										</b-col>
									</b-row>

									<b-row>
										<b-col cols="3">
											<strong>Hours: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="Hours" v-model="selectedVehicle.hours" />

										</b-col>

										<b-col cols="3">
											<strong>Miles: </strong>
											<input class="form-control w-50" style="display: inline-block" type="text" placeholder="Miles" v-model="selectedVehicle.miles" />

										</b-col>

										<b-col cols="4">
											<strong>Location: </strong>
											<multiselect
												class="w-75"
												style="display: inline-block"
												:options="customerHouses"
												:custom-label="({address, name}) => { return name !== undefined && name !== null && address !== undefined && address !== null ? `${name} - ${address}` : ''}"
												track-by="houseid"
												v-model="newAddress"
												:clearable="true"
												:show-labels="false">
											</multiselect>

											<i class="fas fa-plus-circle" @click="action = 'newFirehouse';" style="display: inline-block"></i>

										</b-col>

									</b-row>

									<b-row>
										<b-col>
											<strong>Note: &nbsp;</strong>
											<textarea class="form-control" rows="2" placeholder="Notes" v-model="selectedVehicle.notes"></textarea>

										</b-col>
									</b-row>
								</div>
							</b-tab>

							<b-tab title="Technical" :title-link-class="'text-danger'">
								<div class="row" v-if="subaction !== 'editVehicle'" style="margin-top: 2%">
									<div class="col-4" v-for="(value, key) in selectedVehicle.technical_fields" :key="key">
										<strong>{{ key }}: &nbsp;</strong> {{ value }} <wbr />
									</div>
								</div>

								<div v-else>
									<b-col v-for="(value, key) in selectedVehicle.technical_fields" :key="key" style="text-align: center; text-justify: inter-word">
										<b-row>
											<b-col>
												<strong>{{ key }}:</strong>
											</b-col>
											<b-col>
												<input class="form-control" type="text" v-model="selectedVehicle.technical_fields[key]" placeholder="Field Value" />
											</b-col>
											<b-col>
												<i class="fas fa-trash" style="display: inline-block" @click="removeCustomFields(selectedVehicle.technical_fields, key)"></i>
											</b-col>
										</b-row>
									</b-col>
									<b-row>
										<b-col style="margin-left: 12.5%">
											<input class="form-control" type="text" v-model="newField.name" placeholder="Field Name" />
										</b-col>
										<b-col style="margin: auto">
											<input class="form-control" type="text" v-model="newField.value" placeholder="Field Value" />
										</b-col>
										<b-col>
											<b-button style="background-color: #dc3545; border-color: #dc3545; margin: auto;" @click.prevent="pushField(selectedVehicle.technical_fields, 'technical')">Submit</b-button>
										</b-col>
									</b-row>
								</div>
							</b-tab>

							<b-tab title="Warranty" :title-link-class="'text-danger'">
								<div class="row" v-if="subaction !== 'editVehicle'">
									<div class="col-4" v-for="(warranty, index) in selectedVehicle.warranty_fields" :key="index" style="margin-top: 2%">
										<div class="col">
											<strong>{{ warranty.name }}: </strong> {{warranty.value}}
										</div>
										<div class="col">
											<strong>Start/In Service: </strong>&nbsp;{{ warranty.start.split(' ')[0] }}
										</div>
									</div>
									<wbr />
								</div>

								<div v-else>
									<b-row v-for="(warranty, index) in selectedVehicle.warranty_fields" :key="index" style="text-align: center; text-justify: inter-word">
										<b-col>
											<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="warranty.start"/>
										</b-col>
										<b-col>
											<strong>{{ warranty.name }}:</strong>
										</b-col>
										<b-col>
											<input class="form-control" type="text" v-model="warranty.value" placeholder="Field Value" />
										</b-col>
										<b-col>
											<i class="fas fa-trash" style="display: inline-block" @click="selectedVehicle.warranty_fields.splice(index, 1)"></i>
										</b-col>
									</b-row>

									<b-row style="text-align: center; text-justify: inter-word">
										<b-col>
											<VueCtkDateTimePicker color="#dc3545" button-color="#dc3545" format="YYYY-MM-DD HH:mm" formatted="ll" only-date v-model="newWarranty.start"/>
										</b-col>
										<b-col>
											<input class="form-control" type="text" v-model="newWarranty.name" placeholder="Field Name" />
										</b-col>
										<b-col>
											<input class="form-control" type="text" v-model="newWarranty.value" placeholder="Field Value" />
										</b-col>
										<b-col>
											<b-button style="background-color: #dc3545; border-color: #dc3545;" @click.prevent="pushNewWarranty()">Submit</b-button>
										</b-col>
									</b-row>
								</div>
							</b-tab>

							<b-tab title="Custom" :title-link-class="'text-danger'">
								<div class="row" v-if="subaction !== 'editVehicle'">
									<div class="col-4" v-for="(value, key) in selectedVehicle.custom_fields" :key="key" style="margin-top: 2%">
										<strong>{{ key }}: &nbsp;</strong> {{ value }} <wbr />
									</div>
								</div>
								<div v-else>
									<b-col v-for="(value, key) in selectedVehicle.custom_fields" :key="key" style="text-align: center; text-justify: inter-word">
										<b-row>
											<b-col>
												<strong>{{ key }}:</strong>
											</b-col>
											<b-col>
												<input class="form-control" type="text" v-model="selectedVehicle.custom_fields[key]" placeholder="Field Value" />
											</b-col>
											<b-col>
												<i class="fas fa-trash" style="display: inline-block" @click="removeCustomFields(selectedVehicle.custom_fields, key)"></i>
											</b-col>
										</b-row>
									</b-col>
									<b-row>
										<b-col style="margin-left: 12.5%">
											<input class="form-control" type="text" v-model="newField.name" placeholder="Field Name" />
										</b-col>
										<b-col style="margin: auto">
											<input class="form-control" type="text" v-model="newField.value" placeholder="Field Value" />
										</b-col>
										<b-col>
											<b-button style="background-color: #dc3545; border-color: #dc3545; margin: auto;" @click.prevent="pushField(selectedVehicle.custom_fields, 'custom')">Submit</b-button>
										</b-col>
									</b-row>
								</div>
							</b-tab>

							<b-tab title="History" :title-link-class="'text-danger'">
                                <!-- @viewTicket="action='viewTicket'; selectedTicket=arguments[0]" -->
								<assetHistory :selectedAsset="selectedVehicle" @viewTicket="shiftViewTicket(arguments[0])" />
							</b-tab>

						</b-tabs>
					</div>
				</div>
			</div>



			<!-- TODO Recuring annual jobs not implemented yet -->
			<!-- <div class="card card-danger w-100">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Annual Jobs</b></h3>
					</div>
				</div>
				<div class="card-body">
					<b-table striped ref="recurring" id="recurring" :busy="this.isBusy" :per-page="5" :items="allRecurring" :fields="[{ key: 'customer', sortable: true },{ key: 'date', sortable: true },{ key: 'task', label: 'Tasks' }]">
						<template #table-busy>
							<div class="text-center text-danger my-2">
								<b-spinner class="align-middle"></b-spinner>
								<strong>Loading...</strong>
							</div>
						</template>
					</b-table>
				</div>
			</div> -->

		</div>
        <div v-if="action === 'newFirehouse'">
            <firehouse :customer="selectedCustomer" @accept="getHouses(selectedCustomer); action = ''" @cancelHouse="action = ''"></firehouse>
        </div>

		<div v-if="action === 'viewTicket'">
			<viewTicket :selectedTicket="selectedTicket" @return="action=''"></viewTicket>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import assetHistory from './assetHistory.vue';
import { debounce } from 'lodash';
import Intersect from 'vue-intersect';
import Multiselect from "vue-multiselect";
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import firehouse from './firehouse.vue'
import viewTicket from '../Service/viewTicket.vue';


export default {
	name: 'viewVehicle',
	props: {
		selectedCustomer: Object,
		passedVehicle: Object,
	},

	components: {
		assetHistory,
        firehouse,
		Intersect,
        Multiselect,
		VueCtkDateTimePicker,
		viewTicket,
	},

	data() {
		return {
			action: '',
			allCustomers: [],
			allRecurring: [],
            customerHouses: [],
			customerListIsLoading: false,
			isBusy: false,
			maxPages: 0,
            newAddress: {},
			newField: {
				name: '',
				field: ''
			},
			newWarranty: {
				start: '',
				name: '',
				value: ''
			},
			originalCustomer: {},
			pageNumber: 0,
            prevSearch: '',
			selectedVehicle: {},
			selectedTicket: {},
			selectedTab: null,
			subaction: '',
			tempVehicle: {}, //safe version to return to
			usedCustomerUnitIds: [],
		}
	},

	async mounted() {
        this.originalCustomer = this.selectedCustomer
        await this.getVehicleInfo(this.passedVehicle.vehicleid, this.originalCustomer.customerid)
        this.getHouses(this.selectedCustomer);
		await this.getUsedUnitIDs(this.selectedCustomer)
		this.usedCustomerUnitIds = this.usedCustomerUnitIds.filter(id => id !== this.passedVehicle.vehicleid)
        this.debounceCustomers = debounce(this.searchCustomers, 600);
        this.getCustomers();
	},

	methods: {
		async editVehicle(){
			this.selectedVehicle.custom_fields = typeof this.selectedVehicle.custom_fields === 'object' ? this.selectedVehicle.custom_fields : JSON.parse(this.selectedVehicle.custom_fields)
			this.selectedVehicle.technical_fields = typeof this.selectedVehicle.technical_fields === 'object' ? this.selectedVehicle.technical_fields : JSON.parse(this.selectedVehicle.technical_fields)

			this.getHouses(this.selectedCustomer);
			await this.getUsedUnitIDs(this.selectedCustomer)
			this.usedCustomerUnitIds = this.usedCustomerUnitIds.filter(id => id !== this.selectedVehicle.vehicleid)

			this.tempVehicle = _.cloneDeep(this.selectedVehicle) //Makes a copy to cancel edit to and update vehicle from
			this.newAddress.name = this.selectedVehicle.name
			this.newAddress.address = this.selectedVehicle.address
			this.subaction = 'editVehicle'

		},

		async getCustomers(){
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${1}/search_text/${null}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.allCustomers = response.data.queryResult.results
				this.maxPages = response.data.queryResult.maxPages
				this.pageNumber = 1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async getHouses(customer) {
            await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${customer.customerid}/houses/${this.$globals.companyId}`, {
                headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            })
            .then((response) => {
                this.customerHouses = response.data.queryResult
            })
            .catch((response) => {
                this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
            })
        },

		async getUsedUnitIDs(option){
			this.usedCustomerUnitIds = []
			await axios.get(`${process.env.VUE_APP_API_TARGET}/customers/${option.customerid}/vehicles/${this.$globals.companyId}`,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then((response) => {
				response.data.queryResult.forEach((item, i) => {
					this.usedCustomerUnitIds.push(item.vehicleid)
				});
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getMoreCustomers(){
			if(this.pageNumber >= this.maxPages){
				return
			}
			let text = this.prevSearch === "" ? text = null : text = this.prevSearch
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${text}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				for(var item in response.data.queryResult.results){
					this.allCustomers.push(response.data.queryResult.results[item])
				}
				this.maxPages = response.data.queryResult.maxPages
				this.pageNumber = this.pageNumber+1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		async getVehicleInfo(vehicleid, customerid){
			await axios.get(`${process.env.VUE_APP_API_TARGET}/vehicles/${encodeURIComponent(vehicleid)}/cust/${customerid}/${this.$globals.companyId}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${this.$globals.user.auth0Token}`
				}
			}).then((response) => {
                this.selectedVehicle = response.data.queryResult[0]
				this.selectedVehicle.custom_fields = typeof this.selectedVehicle.custom_fields === 'object' ? this.selectedVehicle.custom_fields : JSON.parse(this.selectedVehicle.custom_fields)
				this.selectedVehicle.technical_fields = typeof this.selectedVehicle.technical_fields === 'object' ? this.selectedVehicle.technical_fields : JSON.parse(this.selectedVehicle.technical_fields)
				this.selectedVehicle.warranty_fields = typeof this.selectedVehicle.warranty_fields === 'object' ? this.selectedVehicle.warranty_fields : JSON.parse(this.selectedVehicle.warranty_fields)

				if(this.selectedVehicle.technical_fields){
					this.selectedVehicle.technical_fields = Object.keys(this.selectedVehicle.technical_fields).sort().reduce((acc,key)=>{
						acc[key] = this.selectedVehicle.technical_fields[key]
						return acc
					},{})
				}

				if(this.selectedVehicle.custom_fields){
					this.selectedVehicle.custom_fields = Object.keys(this.selectedVehicle.custom_fields).sort().reduce((acc,key)=>{
						acc[key] = this.selectedVehicle.custom_fields[key]
						return acc
					},{})
				}

                this.$forceUpdate()
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

		pushField(existingField, type) {
			if(this.newField.name == null || this.newField.name === '' || this.newField?.name === undefined){
				alert("New custom field should be named")
				return;
			}
			if(this.newField.value == null || this.newField.value === '' || this.newField?.value === undefined){
				alert("New custom field should have a value")
				return;
			}
			existingField = (existingField === null ? {} : (typeof existingField === 'object' ? existingField : JSON.parse(existingField)))

			existingField[this.newField.name] = this.newField.value;

			if(type == 'custom'){
				this.selectedVehicle.custom_fields = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})

			}
			else if(type == 'technical'){
				this.selectedVehicle.technical_fields = Object.keys(existingField).sort().reduce((acc,key)=>{
					acc[key] = existingField[key]
					return acc
				},{})
			}
			else{
				return;
			}
			this.newField.value = '';
			this.newField.name = ''
		},

		pushNewWarranty(){
			//NOTE: not a required field but if filled in needs to have correct data
			if(this.newWarranty.start == '' || this.newWarranty.start == null || this.newWarranty.start == undefined){
				alert("Warranty requires a start or an enter service date")
				return;
			}
			if(this.newWarranty.name == '' || this.newWarranty.name == null || this.newWarranty.name == undefined){
				alert("Warranty requires a field")
				return;
			}
			if(this.newWarranty.value == '' || this.newWarranty.value == null || this.newWarranty.value == undefined){
				alert("Warranty requires a value")
				return;
			}

			if(this.selectedVehicle.warranty_fields == null){
				this.selectedVehicle.warranty_fields = []
			}
			this.selectedVehicle.warranty_fields.push({
				name: this.newWarranty.name,
				start: this.newWarranty.start,
				value: this.newWarranty.value
			})

			this.newWarranty = {
				name: '',
				start: '',
				value: ''
			}
		},

		async rowClicked(record) {
			if(record === null){
				return
			}
            this.selectedCustomer = record
			this.selectedVehicle.customerid = record.customerid;
			this.selectedVehicle.location = null;
			this.isBusy = false;
			this.getHouses(this.selectedCustomer);
			this.getUsedUnitIDs(this.selectedCustomer);
			this.selectedVehicle.location = null
			this.selectedVehicle.houseid = null
			this.selectedVehicle.address = null
			this.newAddress = null
		},

		removeCustomFields(vehicleFields, key){
			delete vehicleFields[key]
			this.newField.value = '';
			this.newField.name = ''
			this.$forceUpdate();
		},

		async removeVehicle(vehicle) {
			if(confirm("Warning: Deleting this vehicle will delete all tickets related to it!\nAre you sure you wish to proceed?")){
				await axios.delete(`${process.env.VUE_APP_API_TARGET}/vehicles/target/${encodeURIComponent(vehicle.vehicleid)}/${vehicle.customerid}/${vehicle.vin}/${this.$globals.companyId}`,
					{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
				).then((response)=>{
					alert(response.data.message)
					this.$emit('return')
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

		}, //END removeVehicle()

		shiftViewTicket(x){
			this.action='viewTicket';
			this.selectedTicket=x
		},

		async searchCustomers(searchValue){
			if(searchValue === "" || searchValue === null){
				if(this.prevSearch === "" || this.prevSearch === null){
					return
				}
				searchValue = null
			}
			this.customerListIsLoading = true
			this.pageNumber = 0
			this.prevSearch = searchValue
			await axios
			.get(`${process.env.VUE_APP_API_TARGET}/customers/${this.$globals.companyId}/${this.pageNumber+1}/search_text/${searchValue}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.allCustomers = response.data.queryResult.results
				this.maxPages = response.data.queryResult.maxPages
				this.pageNumber = this.pageNumber+1
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.customerListIsLoading = false
		},

		async updateVehicle(vehicle) {
			let customerid = this.originalCustomer.customerid !== null && this.originalCustomer.customerid !== undefined ? this.originalCustomer.customerid : this.selectedCustomer.customerid
			let custID = vehicle.customerid !== null && vehicle.customerid !== undefined ? vehicle.customerid : this.selectedCustomer.customerid

			//Safety
			if(vehicle.vehicleid == '' || vehicle.vehicleid == null){
				alert("Unit ID is unset")
				return;
			}

			if(this.usedCustomerUnitIds.includes(vehicle.vehicleid)){
				alert("This customer already has this unit id in use")
				return;
			}

			if(vehicle.vin == '' || vehicle.vin == null){
				alert("Vin is unset")
				return
			}

			//processing

			if(vehicle.custom_fields !== null){
				vehicle.custom_fields = typeof vehicle.custom_fields == 'object' ? JSON.stringify(vehicle.custom_fields) : vehicle.custom_fields
			}
			if(vehicle.technical_fields !== null){
				vehicle.technical_fields = typeof vehicle.technical_fields == 'object' ? JSON.stringify(vehicle.technical_fields) : vehicle.technical_fields
			}
			if(vehicle.warranty_fields !== null && vehicle.warranty_fields.length > 0){
				vehicle.warranty_fields = typeof vehicle.warranty_fields == 'object' ? JSON.stringify(vehicle.warranty_fields) : vehicle.warranty_fields
			}

			await axios.put(`${process.env.VUE_APP_API_TARGET}/vehicles/update/${customerid}/${encodeURIComponent(this.tempVehicle.vehicleid)}/${this.$globals.companyId}`,
				{
					customerid: custID,
					vehicleid: vehicle.vehicleid ?? this.tempVehicle.vehicleid,
					make: vehicle.make,
					model: vehicle.model,
					year: vehicle.year,
					vin: vehicle.vin,
					houseid: this.newAddress.houseid ?? vehicle.houseid,
					custom_fields: vehicle.custom_fields,
					warranty: vehicle.warranty,
					chassis: vehicle.chassis,
					body: vehicle.body,
					aerial: vehicle.aerial,
					hours: vehicle.hours,
                    miles: vehicle.miles,
					note: vehicle.notes,
					technical_fields: vehicle.technical_fields,
					warranty_fields: vehicle.warranty_fields
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.then(async () => {
                await this.getVehicleInfo(this.selectedVehicle.vehicleid, custID)
				this.originalCustomer = this.selectedCustomer
				this.newAddress = {}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : this.$options.name, 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.subaction = "";
			this.$forceUpdate()
		},

	}
}
</script>

<style scoped>

</style>

<template>
	<div>
		<div class="row d-flex align-items-center text-center">
			<div class="col">
				<div class="image p-2 d-inline">
					<img :src="picture" class="img-circle" style="width:100%; max-width:100px;"/>
				</div>
			</div>
            <div class="col-6">
                <div class="p-2 ">
					<h5><b>{{name}}</b></h5>
					${{measuredData.toFixed(2)}}
				</div>
            </div>
            <div class="col">
                <div class = "ml-auto p-2">
					<h4><b>#{{rank}}</b></h4>
				</div>
            </div>
		</div>
		<hr>

	</div>

</template>

<script>
/*eslint-disable*/

export default {
	name: "app",
	props: {
		name: String,
		picture: String,
		rank: Number,
		measuredData: Number
	},
}

</script>

<template>
	<div class="wrapper">
		<!--Main View-->
		<div v-show="action == ''">
			<div class="row">
				<div class="col">
					<div class="card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Revenue vs Cost</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'revenueVSCostDetails'; buildRevenueVSCost('revenueAndCostDetails')">
                                    <b>Details</b>
                                </b-button>
                            </div>
						</div>
						<div class="card-body">
							<canvas id="revenueAndCost" width="80" height="80"></canvas>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Gross Sales</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'grossSalesDetails'; buildGrossSalesChart('grossSalesDetails')">
                                    <b>Details</b>
                                </b-button>
                            </div>
						</div>
						<div class="card-body">
							<canvas id="grossSalesChart" width="80" height="80"></canvas>
							<h6>Total number of invoices: {{allInvoiceData.length}}</h6>

						</div>
					</div>
				</div>

				<div class="col">
					<div class="row card card-danger" style="max-width: 400px;">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Cost of Goods Sold</b></h3>
							</div>
                            <div class="card-tools mb-n4">
                                <b-button style="background-color: #fff4; border-color: #dc3545; float: right; margin-top: -35%" @click.prevent="action = 'cogsDetails';">
                                    <b>Details</b>
                                </b-button>
                                <br/>
                                <b-button-group style="margin-top: -35%; margin-bottom: 0">
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { cogsPeriod = '24h'; getCOGS() }"><b>Day</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { cogsPeriod = '30d'; getCOGS() }"><b>Month</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { cogsPeriod = '1y'; getCOGS() }"><b>Year</b></b-button>
                                </b-button-group>
                            </div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-8">
									<b>Merchandise Purchased:</b>
								</div>
								<div class="col">
									$ {{ cogs.merch.total }}
								</div>
							</div>
                            <br/>
							<div class="row">
								<div class="col-8">
									<b>Shipping Charges:</b>
								</div>
								<div class="col">
									$ {{ cogs.shipping.total }}
								</div>
							</div>
                            <br/>
							<div class="row">
								<div class="col-8">
									<b>Inventory Adjustments:</b>
								</div>
								<div class="col">
									$ {{ cogs.adjustments.total }}
								</div>
							</div>
                            <br/>
							<div class="row">
								<div class="col-8">
									<b>Parts and Accessories:</b>
								</div>
								<div class="col">
									$ {{ cogs.parts.total }}
								</div>
							</div>
						</div>
					</div>

                    <div class="row card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Inventory Evaluation</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'invEvalDetails'">
                                    <b>Details</b>
                                </b-button>
                            </div>
						</div>
						<div class="card-body">
							<div class="row mt-n2 text-center">
                                <div class="col">
                                    <b>Total Cost:</b>
                                </div>
                                <div class="col">
                                    ${{parseFloat(invTotal.cost).toLocaleString()}}
                                </div>
                            </div>
                            <div class="row mb-n3 text-center">
                                <div class="col">
                                    <b>Total Value:</b>
                                </div>
                                <div class="col">
                                    ${{parseFloat(invTotal.sell).toLocaleString()}}
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Leaderboard</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545; float: right" @click.prevent="generateLeaderboardDetails()">
                                    <b>Details</b>
                                </b-button><br/>
                                <b-button-group style="margin-top: 2.5%">
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { leaderPeriod='30d'; $forceUpdate() }"><b>Month</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { leaderPeriod='1y'; $forceUpdate() }"><b>Year</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { leaderPeriod='all-time'; $forceUpdate() }"><b>All-Time</b></b-button>
                                </b-button-group><br/>
                                <b-button-group style="margin-top: 2.5%">
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { dataType = 'props' }"><b>Props</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { dataType = 'sales' }"><b>SOs</b></b-button>
                                    <b-button style="background-color: #fff4; border-color: #dc3545; font-size: 8pt;" @click.prevent="() => { dataType = 'invoices' }"><b>Invoices</b></b-button>
                                </b-button-group>
                            </div>
						</div>
						<div class="card-body">
							<leaderboard
								:user="$globals.user"
								:companyId="$globals.companyId"
								:dataType="dataType"
                                :period="leaderPeriod">
							</leaderboard>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Service</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'serviceDetails', buildServiceBar('serviceDetails')">
                                    <b>Details</b>
                                </b-button>
                            </div>
						</div>
                        <div class="card-body">
                            <canvas id="serviceBarGraph" width="80" height="80"></canvas>
                        </div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger" style="max-width: 400px">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Outside Sales</b></h3>
							</div>
                            <div class="card-tools">
                                <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = 'outsideSalesDetails'; buildOutsideSales('outsideSalesDetails')">
                                    <b>Details</b>
                                </b-button>
                            </div>
						</div>
						<div class="card-body" style="max-width: 405px">
							<canvas id="outsideSalesMixedGraph" width="80" height="80"></canvas>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!--Rev v Cost details-->
		<div v-show="action == 'revenueVSCostDetails'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Revenue Vs Cost</b></h3>
					</div>
                    <div class="card-tools">
						<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''; buildRevenueVSCost('revenueAndCost')">
							<b>Return</b>
						</b-button>
                    </div>
				</div>
				<div class="card-body">
					<canvas id="revenueAndCostDetails" width="80" height="80"></canvas>
				</div>
			</div>
		</div>

		<!--Gross Sales details-->
		<div v-show="action == 'grossSalesDetails'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Gross Sales</b></h3>
					</div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''">
                            <b>Return</b>
                        </b-button>
                    </div>
				</div>
				<div class="card-body">
					<canvas id="grossSalesDetails" width="80" height="80"></canvas>
				</div>
			</div>
		</div>

		<!--Cost of Goods Sold details-->
		<div v-if="action == 'cogsDetails'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Cost of Goods Sold</b></h3>
					</div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''">
                            <b>Return</b>
                        </b-button>
                    </div>
				</div>
				<div class="card-body text-center">
                    <div v-if="cogs.merch.items.length > 0">
                        <h3><b>Merchandise</b> <br/><b>${{cogs.merch.total}}</b></h3>
                        <b-row class="text-center " style="font-size: 15pt" v-for="(item, index) in cogs.merch.items" :key="index">
                            <b-col style="margin-left: 15%" cols="5">{{item.itemid}}</b-col>
                            <b-col v-for="(fill,index) in item.fulfillment" :key="`f${index}`">
                                <b-row v-for="(use, index) in fill.usedCount" :key="`u${index}`">
                                    {{use.qty}} @ {{use.cost}} = ${{(parseInt(use.qty) * parseFloat(use.cost)).toFixed(2)}}
                                </b-row>
                            </b-col>
                            <hr class="w-75 text-left" style="border: 1px solid #d3d3d3"/>
                        </b-row>
                    </div>
                    <!-- {{cogs.shipping.items}}
                    {{cogs.adjustments.items}} -->
                    <div v-if="cogs.shipping.items.length > 0">
                        <h3><b>Shipping</b> <br/><b>${{cogs.shipping.total}}</b></h3>
                        <b-row class="text-center " style="font-size: 15pt" v-for="(item, index) in cogs.shipping.items" :key="index">
                            {{item}}
                            <hr class="w-75 text-left" style="border: 1px solid #d3d3d3"/>
                        </b-row>
                    </div>
                    <div v-if="cogs.adjustments.items.length > 0">
                        <h3><b>Adjustments</b> <br/><b>${{cogs.adjustments.total}}</b></h3>
                        <b-row class="text-center " style="font-size: 15pt" v-for="(item, index) in cogs.adjustments.items" :key="index">
                            <b-col style="margin-left: 15%" cols="5">{{item.itemid}}</b-col>
                            <b-col>
                                <b-row v-for="(count, index) in item.adjustedCount" :key="`a${index}`">
                                    {{item.qtyAdjusted > 0 ? count.qty : -count.qty}} @ ${{count.cost}} = ${{(parseInt(item.qtyAdjusted > 0 ? count.qty : -count.qty) * parseFloat(count.cost)).toFixed(2)}}
                                </b-row>
                            </b-col>
                            <hr class="w-75 text-left" style="border: 1px solid #d3d3d3"/>
                        </b-row>
                    </div>
				</div>
			</div>
		</div>

		<!--Inventory Evaluation details-->
        <div v-if="action === 'invEvalDetails'">
            <div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Inventory Evaluation</b></h3>
					</div>
                    <div class="card-tools">
						<b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="printInvEval(); action = ''">
							<b>Print</b>
						</b-button>
                        <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''">
                            <b>Return</b>
                        </b-button>
                    </div>
				</div>
				<div id="invEval" class="card-body">
					<div class="row text-center">
                        <div class="col">
                            <b>Total Cost:</b> ${{parseFloat(invTotal.cost).toLocaleString()}}
                        </div>
                        <div class="col">
                            <b>Total Retail Value:</b> ${{parseFloat(invTotal.sell).toLocaleString()}}
                        </div>
                    </div>
                    <hr size="10">
                    <div class="row text-center" v-for="(item,index) in allInventory" :key="`i${index}`">
                        <!-- TODO: match to invoice template -->
                        <div class="col-12">
                            <div class="row">
                                <div class="col"><h5><b class="text-center">{{item.itemid}}</b></h5></div>
                            </div>
                            <div class="row ml-3">
                                <div class="col">
                                    <div class="row"><b>Description: &nbsp;</b> {{item.itemdesc}}</div>
                                    <div class="row"><b>Preferred Vendor: &nbsp;</b>{{item.vendor}}</div>
                                    <div class="row"><b>Total On Hand: &nbsp;</b>{{getItemTotal(item)[0]}}</div>
                                    <div class="row"><b>Total Cost: &nbsp;</b>${{getItemTotal(item)[1].toFixed(2)}}</div>
                                    <div class="row"><b>Total Value: &nbsp;</b>${{getItemTotal(item)[2].toFixed(2)}}</div>
                                </div>
                                <div class="col">
                                    <div class="row text-center"><b>Locations</b></div>
                                    <div class="row" v-for="(location,lIndex) in item.otherLocations" :key="`l${lIndex}`">
                                        <div class="col">Location: {{location.location}} &nbsp; Bin: {{location.bin}}</div>
                                        <div class="col">Qty: {{ combineCostObjs(location.stock) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr style="width: 75%">
                    </div>
				</div>
			</div>
        </div>

		<!--Leaderboard details-->
        <div v-if="action == 'leaderboardDetails'">
            <div class="card card-danger">
                <div class="card-header">
                    <div class="card-title">
                        <h3><b>Leaderboard</b></h3>
                    </div>
                    <div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''">
                            <b>Return</b>
                        </b-button>
                    </div>
                 </div>
				<div class="card-body">
					<div>
						<leaderboard
							refs = "leaderboardDetails"
							:user="user"
							:companyId="companyId"
							:dataType="dataType">
						</leaderboard>
						<!-- TODO pass in diffrent dataType and get back rank based on that -->
					</div>
					<br>
					<div>
						<multiselect
							id="salesmenSelect"
							class="w-25"
							style="display: inline-block"
							label="name"
							track-by="name"
							:clearable="true"
							:show-labels="false"
							:options="allSalesmen"
							v-model="selectedSalesman"
							placeholder="List of Salesmen"
						></multiselect>

						<multiselect
							id="leaderboardMonths"
							class="w-25"
							style="display: inline-block"
							:options="months"
							v-model="selectedMonth"
							placeholder="Month"
						></multiselect>

						<multiselect
							id="leaderboardYear"
							class="w-25"
							style ="display: inline-block"
							:options="operationYears"
							v-model="selectedYear"
							placeholder="of Year"
						></multiselect>

						<b-button style="background-color: #dc3545; border-color: #dc3545; margin-left: 1%" @click="salesmanDetails()">
							Search
						</b-button>
						<b-button style="background-color: #dc3545; border-color: #dc3545; margin-left: 1%" @click="subaction='', selectedYear='', selectedMonth='', selectedSalesman=''">
							Clear
						</b-button>

						<br><hr>

						<b-table v-show="subaction == 'salesmanDetails'"
							striped
							:items="selectedDetails"
							:fields="[
								{key: 'Type'},
								{key: 'Salesman'},
								{key: 'Customer'},
								{key: 'Date'},
								{key: 'Totals'}
							]"
							@row-clicked = "instanceDetails"
							>
							<template #cell(Type)="row">
								{{row.item.identifier}}

							</template>

							<template #cell(Salesman)="row">
								{{row.item.salesman}}
							</template>

							<template #cell(Customer)="row">
								{{typeof row.item.customer === "object" ? row.item.customer.name: JSON.parse(row.item.customer).name}}
							</template>

							<template #cell(Date)="row">
								<div v-if="row.item.identifier == 'Invoice'">
									Issued: {{row.item.issued}}
								</div>
								<div v-if="row.item.identifier == 'Proposal'">
									Starts: {{row.item.starts}}
									<br>
									Expires: {{row.item.expires}}
								</div>
								<div v-if="row.item.identifier == 'Sales Order'">
									Starts: {{row.item.starts}}
								</div>
							</template>

							<template #cell(Totals)="row">
								Sales: ${{calculateSales(row.item)}}<br/>
								Costs: ${{calculateCosts(row.item)}}

							</template>

						</b-table>


						<b-table v-show="subaction == ''"
							striped
							:items="this.allSalesmen"
							:fields="[
								{ key: 'saleman', label: 'Salesmen'},
								{ key: 'invoices', label: 'Invoices' },
								{ key: 'salesOrders', label: 'Sales Orders' },
								{ key: 'proposals', label: 'Proposals' },
							]"
							@row-clicked="clickedSalesman"
						>
							<template #cell(saleman)="row">
								{{row.item.name}}

							</template>

							<template #cell(invoices)="row">
								Invoices done: {{row.item.invoice.count}}
								<br>
								Sales: ${{row.item.invoice.sale}}
								<br>
								Cost: ${{row.item.invoice.cost}}

							</template>

							<template #cell(salesOrders)="row">
								Sales Orders : {{row.item.salesOrder.count}}
								<br>
								Estimated: ${{row.item.salesOrder.sale}}
								<br>
								Cost: ${{row.item.salesOrder.cost}}

							</template>

							<template #cell(proposals)="row">
								Proposals done: {{row.item.proposal.count}}
								<br>
								Estimated: ${{row.item.proposal.sale}}
								<br>
								Cost: ${{row.item.proposal.cost}}

							</template>


						</b-table>
					</div>
					<br>


				</div>
			</div>
        </div>

		<!--Service Details -->
		<div v-show="action == 'serviceDetails'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Service</b></h3>
					</div>
					<div class="card-tools">
						<b-button style="background-color: #fff4; border-color: #dc3545; color: #ffffff; float: right" @click.prevent="action = ''; buildServiceBar('serviceBarGraph')">
							<b>Return</b>
						</b-button>
					</div>
				</div>
				<div class="card-body">
					<b-row>
						<b-col cols="10">
							<canvas id="serviceDetails" style="width:150%"></canvas>
						</b-col>
						<b-col>
							<br>
							<b-row>
								<multiselect
									id="leaderboardYear"
									style ="display: inline-block"
									:options="operationYears"
									v-model="selectedYear"
									placeholder="of Year"
									:show-labels="false"
									@input="buildServiceBar('serviceDetails')"
									>
								</multiselect>

							</b-row>

						</b-col>
					</b-row>
					<b-row>

						<b-table
							striped
							:items="selectedDetails"
							:fields="[
								{key: 'IDs'},
								{key: 'Salesman'},
								{key: 'Customer'},
								{key: 'Date'},
								{key: 'Totals'},
								{key: 'Throughput'}
							]"
							@row-clicked = "instanceDetails"
							>
							<template #cell(Type)="row">
								Service Invoice
							</template>

							<template #cell(IDs) = "row">
								Work Order #{{row.item.order}}
								<br/>
								Invoice #{{row.item.qbID}}
							</template>

							<template #cell(Salesman)="row">
								{{row.item.salesman}}
							</template>

							<template #cell(Customer)="row">
								{{typeof row.item.customer === "object" ? row.item.customer.name: JSON.parse(row.item.customer).name}}
								<br/>
							</template>

							<template #cell(Date)="row">
								Issued: {{row.item.issued}}
							</template>

							<template #cell(Totals)="row">
								Sales: ${{calculateSales(row.item)}}<br/>
								Costs: ${{calculateCosts(row.item)}}
							</template>

							<template #cell(Throughput)="row">
								<div v-if="row.item.awayFromCust >= 72">
									Time away from Customer: {{ (row.item.awayFromCust /60).toFixed(0) }} Days
								</div>
								<div v-if="row.item.awayFromCust < 72">
									Time away from Customer: {{row.item.awayFromCust}} Hours
								</div>
								<div v-if="row.item.ticketTime >= 72">
									Ticket time: {{(row.item.ticketTime /60).toFixed(0)}} Days
								</div>
								<div v-if="row.item.ticketTime < 72">
									Ticket time: {{row.item.ticketTime}} Hours
								</div>
								Total Tech time: {{row.item.techTime}} Hours
							</template>

						</b-table>
					</b-row>

				</div>
			</div>
		</div>

		<!--Outside Sales details-->
		<div v-show="action == 'outsideSalesDetails'">
			<div class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Outside Sales</b></h3>
					</div>
					<div class="card-tools">
                        <b-button style="background-color: #fff4; border-color: #dc3545;" @click.prevent="action = ''">
                            <b>Return</b>
                        </b-button>
                    </div>
				</div>
				<div class="card-body">
					<canvas id="outsideSalesDetails" width="80" height="80"></canvas>
				</div>
			</div>
		</div>



		<!-- Proposal Display -->
		<div v-if="action === 'viewProposal'">
			<div ref="document" class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Proposal</b></h3>
					</div>
					<div class="card-tools">
						<b-table
						class="light my-1 text-center"
						:fields="[
							{ key: 'date' },
							{ key: 'propid', label: 'Proposal #' },
						]"
						:items="[{ date: '', propid: -1 }]"
						>
							<template #cell(date)>
								<p>{{selectedProposal.starts}}
								</p>
							</template>
							<template #cell(propid)>
								{{ selectedProposal.localid }}
							</template>
						</b-table>
					</div>
				</div>
				<div class="card-body">
					<div class="row text-align-center">
						<div class="col"></div>
						<div class="col"></div>
						<div class="col"></div>
						<div class="col"></div>
					</div>
					<div class="row">
						<div class="col">
							<div class="row">
								<img class="img w-75 h-75" :src="selectedCompany.logo" />
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- COMPANY -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedCompany.name }}</p>
									<br />
									{{ selectedCompany.address }}<br />
									Tel: {{ selectedCompany.phone }}
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- CUSTOMER -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedProposal.customer.name }}</p>
									<br />
									<div class="row">
										<div class="col">
											<label>Bill To:</label><br />
											{{ selectedProposal.billto.title }}
											{{ selectedProposal.billto.name }}<br />
											<hr class="w-75 my-1 ml-1" />
											{{ selectedProposal.billto.address }}<br />
											<hr class="w-75 my-1 ml-1" />
											Tel: {{ selectedProposal.billto.phone }}
										</div>
									</div>
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
							<div class="row">
								<address>
									<p class="h4">{{ selectedProposal.customer.name }}</p>
									<br />
									<label>Ship To:</label><br />
									{{ selectedProposal.shipto.title }}
									{{ selectedProposal.shipto.name }}<br />
									<hr class="w-75 my-1 ml-1" />
									{{ selectedProposal.shipto.address }}<br />
									<hr class="w-75 my-1 ml-1" />
									Tel: {{ selectedProposal.shipto.phone }}
								</address>
							</div>
						</div>
					</div>
					<div class="row text-center border">
						<div class="col pt-2 border">
							<h5><b>Salesman</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Expires</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Freight</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Submitted By</b></h5>
						</div>
					</div>
					<div class="row text-center border mb-3">
						<div class="col border">
							{{ selectedProposal.salesman }}
						</div>
						<div class="col border">
							{{ selectedProposal.expires }}
						</div>
						<div class="col border">
							{{selectedProposal.freight ? selectedProposal.freight : "Not Included"}}
						</div>
						<div class="col border">
							{{ selectedProposal.salesman }}
						</div>
					</div>

					<b-table
						:items="selectedProposal.lineitems"
						:fields="[
							{ key: 'itemid', label: 'Item ID' },
							{ key: 'itemdesc', label: 'Description' },
							{ key: 'vendor', label: 'Vendor' },
							{ key: 'qty', label: 'Qty' },
							{ key: 'sell', label: 'Price Each' },
							{ key: 'total', label: 'Total' },
						]"
					>
						<template #cell(sell)="row"> ${{ row.item.sell }} </template>

						<template #cell(total)="row"> ${{ (row.item.sell * row.item.qty).toFixed(2) }} </template>

						<template #custom-foot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>
									<h5><b>Total: </b></h5>
								</th>
								<th>${{ proposalTotal }}</th>
							</tr>
						</template>
					</b-table>
					<!-- View attached Leads -->
					<label for="attachedLeads" v-if="selectedProposalLeads.length>0">Leads</label>
					<b-table
						v-if="selectedProposalLeads.length>0"
						striped
						:fields="[
							{ key: 'propid', label: 'Prop Id' },
							{ key: 'customer', label: 'Customer' },
							{ key: 'tags', label: 'Tags' },
						]"
						:items="selectedProposalLeads"
					>
						<template #cell(customer)="row">
							{{row.item.propid == selectedProposal.propid ? JSON.parse(row.item.customer).name : ""}}
						</template>
						<template #cell(tags)="row">
							<template v-for="item in JSON.parse(row.item.tags)">
								<div
									:key="item.tag"
									:style="{'background-color': item.color,'border-color': item.color,}"
									style="padding: 10px;
										border-radius: 20px;
										color: white;
										text-align: center;
										font-weight: bold;
										display: inline-block;
										margin-right: 4px;
									"
								>
									{{ item.tag }}
								</div>
							</template>
						</template>
					</b-table>
				</div>
			</div>

			<b-button
				v-if="!printing"
				class="button-danger mb-4"
				@click="printPDF(selectedProposal)"
				>Print</b-button
			>
			<b-button
				v-if="!printing"
				class="button-danger mb-4 float-right"
				@click="action = lastVisted"
				>Return</b-button
			>
		</div>

		<!-- Invoice Display -->
		<div v-if="action == 'viewInvoice'">
			<!-- Service Invoice -->
			<div v-if="selectedInvoice.type === 'service'">
				<div ref="document" class="card card-danger">
					<div class="card-header">
					<div class="card-title">
						<h3 style="text-align: right"><b>Service Invoice</b></h3>
					</div>
					</div>
					<div class="card-body">
					<div class="row">
						<div class="col">
						<img class="w-75 h-75" :src="selectedCompany.logo" />
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<!-- COMPANY -->
						<div class="row">
							<address>
							<p class="h4">{{ selectedCompany.name }}</p>
							<br />
							{{ selectedCompany.address }}<br />
							P: {{ selectedCompany.phone }}
							</address>
						</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<!-- CUSTOMER -->
						<div class="row">
							<address>
							<p class="h4">{{ selectedCustomer.name }}</p>
							<br />
							{{ selectedCustomer.billing }}<br />
							P: {{ selectedCustomer.phone }}
							</address>
						</div>
						</div>
						<div
						class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
						>
						<div class="row text-align-center">
							<b-table
							:fields="[{ key: 'date' }, { key: 'invoice' }]"
							:items="[{ date: selectedInvoice.issued, invoice: selectedInvoice.id }]"
							>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
							<p>
								<b>Payment Type:</b><br />
								Account/Credit
							</p>
							</div>
							<div class="col">
							<p>
								<b>Customer ID:</b><br />
								{{ selectedCustomer.customerid }}
							</p>
							</div>
						</div>
						</div>
					</div>
					<div class="row border text-align-center mb-3">
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Customer WO</b><br />
							{{ selectedInvoice.order }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Unit #</b><br />
							{{ selectedInvoice.vehicle.vehicleid }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>VIN</b><br />
							{{ selectedInvoice.vehicle.vin }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Make</b><br />
							{{ selectedInvoice.vehicle.make }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Model</b><br />
							{{ selectedInvoice.vehicle.model }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Mileage</b><br />
							{{ selectedInvoice.vehicle.mileage }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Hours</b><br />{{ selectedInvoice.vehicle.hours }}
						</p>
						</div>
					</div>
					<div class="row">
						<b-table
						show-empty
						:fields=" [
							{ key: 'tech', label: 'Technician(s)' },
							{ key: 'itemid', label: 'Item ID' },
							{ key: 'itemdesc', label: 'Description' },
							{ key: 'qty', label: 'Qty' },
							{ key: 'sell', label: 'Price Each' },
							{ key: 'total', label: 'Total' },
						]"
						:items="selectedInvoice.data"
						class="table-bordered border-3"
						>
						<template #cell(tech)="row">
							<div v-for="tech in row.item.tech" :key="tech.name">
							{{ tech.name }}
							</div>
						</template>
						<template #cell(total)="row">
							<div v-if="row.item.sell">
							${{ (row.item.sell * row.item.qty).toFixed(2) }}
							</div>
						</template>
						<template #cell(sell)="row">
							<div v-if="row.item.sell">${{ row.item.sell }}</div>
						</template>
						<template #custom-foot>
							<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>
								<h5><b>Total: </b></h5>
							</th>
							<th>${{ selectedInvoice.total.toFixed(2) }}</th>
							</tr>
						</template>
						</b-table>
					</div>
					<div>
						<p>
						{{
							selectedCompany.invoice_footer
							? selectedCompany.invoice_footer
							: "This is a customizable footer here"
						}}
						</p>
					</div>
					</div>
				</div>
				<b-button
					v-if="!printing"
					class="button-danger mb-4"
					@click="printPDF(selectedInvoice)"
					>Print</b-button
				>
				<b-button
					v-if="!printing"
					class="button-danger mb-4 float-right"
					@click="action = lastVisted"
					>Return</b-button
				>
			</div>

			<!-- Sales Invoice -->
			<div v-if="selectedInvoice.type === 'sales'">
				<div ref="document" class="card card-danger">
					<div class="card-header">
					<div class="card-title">
						<h3 style="text-align: right"><b>Sales Invoice</b></h3>
					</div>
					</div>
					<div class="card-body">
					<div class="row">
						<div class="col">
						<img class="w-75 h-75" :src="selectedCompany.logo" />
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<!-- COMPANY -->
						<div class="row">
							<address>
							<p class="h4">{{ selectedCompany.name }}</p>
							<br />
							{{ selectedCompany.address }}<br />
							P: {{ selectedCompany.phone }}
							</address>
						</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
						<!-- CUSTOMER -->
						<div class="row">
							<address>
							<p class="h4">{{ selectedCustomer.name }}</p>
							<br />
							{{ selectedCustomer.billing }}<br />
							P: {{ selectedCustomer.phone }}
							</address>
						</div>
						</div>
						<div
						class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4"
						>
						<div class="row text-align-center">
							<b-table
							:fields="[{ key: 'date' }, { key: 'invoice' }]"
							:items="[{ date: selectedInvoice.issued, invoice: selectedInvoice.id }]"
							>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
							<p>
								<b>Payment Type:</b><br />
								Account/Credit
							</p>
							</div>
							<div class="col">
							<p>
								<b>Customer ID:</b><br />
								{{ selectedCustomer.customerid }}
							</p>
							</div>
						</div>
						</div>
					</div>
					<div class="row border text-align-center mb-3">
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Order Number</b><br />
							{{ selectedInvoice.order }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Salesman</b><br />
							{{ selectedInvoice.salesman || "" }}
						</p>
						</div>
						<div class="col pt-3 border text-align-center">
						<p style="text-align: center">
							<b>Invoice Due By</b><br />
							{{ dueByDate }}
						</p>
						</div>
					</div>
					<div class="row">
						<b-table
						show-empty
						:fields="[
							{ key: 'itemid', label: 'Item ID' },
							{ key: 'itemdesc', label: 'Description' },
							{ key: 'qty', label: 'Qty' },
							{ key: 'sell', label: 'Price Each' },
							{ key: 'total', label: 'Total' },
						]"
						:items="selectedInvoice.data"
						class="table-bordered border-3"
						>
						<template #cell(total)="row">
							<div v-if="row.item.sell">
							${{ (row.item.sell * row.item.qty).toFixed(2) }}
							</div>
						</template>
						<template #cell(sell)="row">
							<div v-if="row.item.sell">${{ row.item.sell.toFixed(2) }}</div>
						</template>
						<template #custom-foot>
							<tr>
							<th></th>
							<th></th>
							<th></th>
							<th>
								<h5><b>Total: </b></h5>
							</th>
							<th>${{ selectedInvoice.total.toFixed(2) }}</th>
							</tr>
						</template>
						</b-table>
					</div>
					<div>
						<p>
						{{
							selectedCompany.invoice_footer
							? selectedCompany.invoice_footer
							: "This is a customizable footer here"
						}}
						</p>
					</div>
					</div>
				</div>
				<b-button
					v-if="!printing"
					class="button-danger mb-4"
					@click="printPDF(selectedInvoice)"
					>Print</b-button
				>
				<b-button
					v-if="!printing"
					class="button-danger mb-4 float-right"
					@click="action = lastVisted"
					>Return</b-button
				>
			</div>
		</div>

		<!-- Sales Order Display -->
		<div v-if="action === 'viewSalesOrder'">
			<div ref="document" class="card card-danger">
				<div class="card-header">
					<div class="card-title">
						<h3><b>Sales Order</b></h3>
					</div>
					<div class="card-tools my-1">
						<b-table
							class="light my-1 text-center"
							:fields="[{ key: 'date' }, { key: 'id', label: 'Order #' }]"
							:items="[
							{
								date: selectedSalesOrder.starts,
								id: selectedSalesOrder.localid,
							}]"
						>
						</b-table>
					</div>
				</div>
				<div class="card-body">
					<div class="row text-align-center">
						<div class="col"></div>
						<div class="col"></div>
						<div class="col"></div>
						<div class="col"></div>
					</div>
					<div class="row">
						<div class="col">
							<div class="row">
								<img class="img w-75 h-75" :src="selectedCompany.logo" />
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- COMPANY -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedCompany.name }}</p>
									<br />
									{{ selectedCompany.address }}<br />
									P: {{ selectedCompany.phone }}
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pt-3 mb-4">
							<!-- CUSTOMER -->
							<div class="row">
								<address>
									<p class="h4">{{ selectedSalesOrder.customer.name }}</p>
									<br />
									<div class="row">
										<div class="col">
											<label>Bill To:</label><br />
											{{ selectedSalesOrder.billto.title }}
											{{ selectedSalesOrder.billto.name }}<br />
											<hr class="w-75 my-1 ml-1" />
											{{ selectedSalesOrder.billto.address }}<br />
											<hr class="w-75 my-1 ml-1" />
											P: {{ selectedSalesOrder.billto.phone }}
										</div>
									</div>
								</address>
							</div>
						</div>
						<div class="col border border-danger rounded mr-4 pl-4 pr-4 pt-3 mb-4">
							<div class="row">
								<address>
									<p class="h4">{{ selectedSalesOrder.customer.name }}</p>
									<br />
									<label>Ship To:</label><br />
									{{ selectedSalesOrder.shipto.title }}
									{{ selectedSalesOrder.shipto.name }}<br />
									<hr class="w-75 my-1 ml-1" />
									{{ selectedSalesOrder.shipto.address }}<br />
									<hr class="w-75 my-1 ml-1" />
									P: {{ selectedSalesOrder.shipto.phone }}
								</address>
							</div>
						</div>
					</div>
					<div class="row text-center border">
						<div class="col pt-2 border">
							<h5><b>Salesman</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Valid Through</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Freight</b></h5>
						</div>
						<div class="col pt-2 border">
							<h5><b>Submitted By</b></h5>
						</div>
					</div>
					<div class="row text-center border mb-3">
						<div class="col border">
							{{ selectedSalesOrder.salesman }}
						</div>
						<div class="col border">
							{{ selectedSalesOrder.expires }}
						</div>
						<div class="col border">
							{{selectedSalesOrder.freight ? selectedSalesOrder.freight: "Not Included"}}
						</div>
						<div class="col border">
							{{ selectedSalesOrder.salesman }}
						</div>
					</div>

					<b-table
						:fields="[
							{ key: 'itemid', label: 'Item ID' },
							{ key: 'itemdesc', label: 'Description' },
							{ key: 'vendor', label: 'Vendor' },
							{ key: 'qty', label: 'Qty' },
							{ key: 'sell', label: 'Price Each' },
							{ key: 'total', label: 'Total' },
						]"
						:items="selectedSalesOrder.lineitems"
					>
						<template #cell(sell)="row">
							${{ row.item.sell.toFixed(2) }}
						</template>

						<template #cell(total)="row">
							${{ (row.item.sell * row.item.qty).toFixed(2) }}
						</template>

						<template #custom-foot>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>
									<h5><b>Total: </b></h5>
								</th>
								<th>${{ salesOrderTotal }}</th>
							</tr>
						</template>
					</b-table>

				</div>
			</div>
			<b-button
				v-if="!printing"
				class="button-danger mb-4"
				@click="printPDF(selectedSalesOrder)"
				>Print</b-button
			>
			<b-button
				v-if="!printing"
				class="button-danger mb-4 float-right"
				@click="action = lastVisted"
				>Return</b-button
			>
		</div>



    </div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import leaderboard from "./Leaderboard/leaderboard.vue"
import Multiselect from "vue-multiselect";
import jsPDF from "jspdf";
import printJS from "print-js";
import html2canvas from "html2canvas";


export default {
  name: "app",
  props:{
      user: Object,
      companyId: Number,

  },
  components: {
	  leaderboard,
	  Multiselect
  },
  data() {
    return {
		action:'',
        allInventory: [],
		allInvoiceData: [],
		allSalesmen:[],
		allSalesOrders: [],
        cogs: {
            merch: {},
            shipping: {},
            adjustments: {},
            parts: {}
        },
        cogsPeriod: '30d',
		dataType: 'invoice',
        firstCTX: '',
        leaderInvoices: [],
        leaderSalesOrders: [],
        leaderProposals: [],
        leaderPeriod: '30d',
		grossSalesChart:'',
        invTotal: {},
		lastVisted: '',
		months : [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		],
		operationYears: [],
		printing: false,
		selectedMonth:'',
		selectedYear:null,
		subaction:'',
		selectedDetails: [],
		selectedProposal: {},
		selectedProposalLeads:[],
		selectedSalesOrder: [],
		selectedCompany: {},
		selectedSalesman:'',
		selectedInvoice: null,
		serviceBarGraph:'',

      myFirstChart:'',
      outsideSalesMixedGraph:'',
        pracGraph: '',
    };
  },

  computed: {
    proposalTotal: function () {
      var total = 0;
      for (let item in this.selectedProposal.lineitems) {
        if (this.selectedProposal.lineitems[item].itemid) {
          total +=
            this.selectedProposal.lineitems[item].qty *
            this.selectedProposal.lineitems[item].sell;
        }
      }
      return total.toFixed(2);
    },
	dueByDate: function() {
		let month = (new Date(this.selectedInvoice.issued).getMonth()+2)%12
		month = month === 0 ? month = 12 : month

		let day = new Date(this.selectedInvoice.issued).getDate()

		let year = (new Date(this.selectedInvoice.issued).getFullYear())
		year = month < new Date(this.selectedInvoice.issued).getMonth()+1 ? year + 1 : year

		return `${year}-${month}-${day}`
	},
	salesOrderTotal: function() {
	  var total = 0;
	  for (let item in this.selectedSalesOrder.lineitems) {
		if (this.selectedSalesOrder.lineitems[item].itemid) {
		  total +=
			this.selectedSalesOrder.lineitems[item].qty *
			this.selectedSalesOrder.lineitems[item].sell;
		}
	  }
	  return total.toFixed(2);
	},
  },

	async mounted() {

		await axios.get(process.env.VUE_APP_API_TARGET + '/sales/invoices/'+this.$globals.companyId, {headers: {'Authorization':'Bearer '+ this.$globals.user.auth0Token}})
		.then((response) =>{
			this.allInvoiceData = response.data.queryResult;
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

		axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) => {
				this.selectedCompany = response.data.queryResult[0];
		})
		.catch((response)=>{
			this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		})

        this.getCOGS();
		this.getAllSalesmen();
        this.getInvEval();
		this.buildServiceBar('serviceBarGraph')
		// this.linePractice('linePractice');
		this.buildOutsideSales('outsideSalesMixedGraph')
		this.buildRevenueVSCost('revenueAndCost')
		this.buildGrossSalesChart('grossSalesChart')

		let tempYear = new Date().getFullYear()
		while(tempYear >= 2019){
			this.operationYears.push(tempYear)
			tempYear -=1;
		}

	},

    destroyed() {
        this.$globals.socket.emit("leaveRoom", `business${this.$globals.companyId}`);
        this.$globals.socket.off("newDataCreated")
        console.log("destroyed BI")
    },

	methods: {

		buildFirstChart(chart){
			this.firstCTX = document.getElementById(chart);

			this.myFirstChart = new Chart(this.firstCTX, {
				type: 'bar',
				data: {
					labels: ['John', 'Jacob', 'Sally', 'Bill', 'Geffory', 'Candice'],
					datasets: [{
						label: '# of Votes',
						data: [12000, 19000, 32000, 25000, 8000, 30000],
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
	                    	'rgba(255, 206, 86, 0.2)',
	                    	'rgba(75, 192, 192, 0.2)',
	                    	'rgba(153, 102, 255, 0.2)',
	                    	'rgba(255, 159, 64, 0.2)'
						],
						borderColor: [
							'rgba(255, 99, 132, 1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)'
						],
						borderWidth: 1
					}]
				},
				options: {
					scales: {
						y: {
							beginAtZero: true
						}
					}
				}
			});
		},

		async buildServiceBar(chart){
		  let ctx = document.getElementById(chart);
		  let monthlyTickets = [0,0,0,0,0,0,0,0,0,0,0,0];
		  let monthlyInvoiceTotals = [0,0,0,0,0,0,0,0,0,0,0,0];
		  let year = (this.selectedYear ?? new Date().getFullYear()) + '-'

		  let testMonths = Array.from({length:12}, (item,i)=>{
			  return new Date(0,i).toLocaleString('en-US', {month:'short'})
		  });


		await axios
			.get(`${process.env.VUE_APP_API_TARGET}/tickets/statistics/monthlyTickets/${year}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response) =>{
				//monthlyTickets = response.data.queryResult;
				for(let index in response.data.queryResult){
					let temp = new Date(response.data.queryResult[index].end_date).getMonth()
					monthlyTickets[temp]++;
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		await axios
			.get(`${process.env.VUE_APP_API_TARGET}/sales/service_invoices/${year}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				for(let index in response.data.queryResult){
					let temp = new Date(response.data.queryResult[index].issued).getMonth()
					monthlyInvoiceTotals[temp]+= response.data.queryResult[index].total;
				}
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

		if(this.action == 'serviceDetails'){
			this.serviceDetailsTable()
			this.selectedYear = this.selectedYear ?? new Date().getFullYear()
		}
		if(this.serviceBarGraph){
			this.serviceBarGraph.destroy()
		}
		  this.serviceBarGraph = new Chart(ctx,{
			  type: 'bar',
			  data: {
				  labels:testMonths,
				  datasets:[
					  {
						  label: 'Tickets Closed',
						  data: monthlyTickets,
						  borderColor: 'rgb(255, 99, 132)',
						  backgroundColor: 'rgba(217, 100, 11, 0.7)',
						  yAxisID: 'y-axis-0'

					  },
					  {
						  label: 'Sum of invoices',
						  data: monthlyInvoiceTotals,
						  borderColor: 'rgb(54, 162, 235)',
						  backgroundColor: 'rgb(173, 14, 40)',
						  yAxisID: 'y-axis-1'

					  }
				  ]

			  },
			  options: {
				  responsive: true,
				  interaction: {
					  mode: 'index',
					  intersect: false,
				  },
				  stacked: false,
				  scales: {
					  yAxes: [{
						  type: 'linear',
						  display: true,
						  position: 'left',
						  "id": "y-axis-0",
						  grid: {
							  drawOnChartArea: false, // only want the grid lines for one axis to show up
						  },
					  },
					  {
						  type: 'linear',
						  display: true,
						  position: 'right',
						  "id": "y-axis-1",
					  }],
					  grace: '1%',
					  beginAtZero: true,
				  }
			  }
		  })
	  },

		buildGrossSalesChart(chart){
		  let ctx = document.getElementById(chart);

		  let labelArray = ['Service', 'Sales']
		  let dataArray = [0,0]
		  for(let index in this.allInvoiceData){

			  if(this.allInvoiceData[index].type == 'service'){
				  dataArray[0] += parseFloat(this.allInvoiceData[index].total.toFixed(2))
			  }
			  else if(this.allInvoiceData[index].type == 'sales'){
				  dataArray[1] += parseFloat(this.allInvoiceData[index].total.toFixed(2))
			  }
			  else{
				  labelArray.push('Misc');
				  dataArray[2] += parseFloat(this.allInvoiceData[index].total.toFixed(2))
			  }
		  }

		  this.grossSalesChart = new Chart(ctx, {
			  type: 'doughnut',
			  data: {
				  labels:labelArray,
				  datasets:[
					  {
						  label:'Gross Sales',
						  data: dataArray,
						  backgroundColor:['rgb(54, 162, 235)','blue','green','purple']
					  }
				  ]
			  }
		  })

	  },

		buildOutsideSales(chart){
		  let ctx = document.getElementById(chart);

		  this.outsideSalesMixedGraph  = new Chart(ctx,{
			type: 'bar',
			data: {
			    labels:this.months,
			    datasets:[
			        {
			            label: 'Net Sales',
			            data: [1,3,5,7,9,2,4,6,8,10,2,1],
			            borderColor: 'red',
			            backgroundColor: 'red',
			        },
			        {
			            label: 'Net Profit',
			            data: [1,2,3,4,5,6,7,8,9,10,11,12],
			            borderColor: 'blue',
			            backgroundColor: 'blue',
			        },
			        {
			            label: 'Net Profit Margin',
			            data: [0,-1,-2,-3,-3,4,1,0,9,11],
			            type: 'line',
			            borderColor: 'rgb(184, 79, 164)'
			        }

			    ]

			},
			options: {
			    responsive: true,
				  animation: {
					  onComplete: () => {
						  let delayed = true;
					  },
					  delay: (context) => {
						  let delay = 0;
						  if (context.type === 'data' && context.mode === 'default' && !delayed) {
							  delay = context.dataIndex * 300 + context.datasetIndex * 100;
						  }
						  return delay;
					  },
				  },
			}
			})


	  },

		buildRevenueVSCost(chart){
		  let ctx = document.getElementById(chart);
		  let monthlyCost = [0,0,0,0,0,0,0,0,0,0,0,0];
		  let monthlySale = [0,0,0,0,0,0,0,0,0,0,0,0];
		  //TODO addjust for years and dates to be correct

		  for(let index in this.allInvoiceData){
			  this.allInvoiceData[index].data = typeof this.allInvoiceData[index].data === "object" ? this.allInvoiceData[index].data : JSON.parse(this.allInvoiceData[index].data)
			  let date = new Date(this.allInvoiceData[index].issued)
              let today = new Date()

			  for(let dataIndex in this.allInvoiceData[index].data){
				  if(this.allInvoiceData[index].data[dataIndex]?.sell !== undefined && this.allInvoiceData[index].data[dataIndex]?.cost !== undefined && date.getFullYear() === today.getFullYear()){
					  monthlyCost[date.getMonth()] += (this.allInvoiceData[index].data[dataIndex].qty * this.allInvoiceData[index].data[dataIndex].cost)
					  monthlySale[date.getMonth()] += (this.allInvoiceData[index].data[dataIndex].qty * this.allInvoiceData[index].data[dataIndex].sell)

				  }
			  }
		  }

		  for(let month in monthlyCost){
			  monthlyCost[month] = monthlyCost[month].toFixed(2)
			  monthlySale[month] = monthlySale[month].toFixed(2)
		  }


		  let testMonths = Array.from({length:12}, (item,i)=>{
			  return new Date(0,i).toLocaleString('en-US', {month:'short'})
		  });

		  if(this.pracGraph){
			  this.pracGraph.destroy()
		  }

		  this.pracGraph = new Chart(ctx,{
			  type: 'line',
			  data: {
				  labels:testMonths,
				  datasets:[
					  {
						  label: 'Cost',
						  data: monthlyCost,
						  borderColor: 'rgb(255, 99, 132)',
						  backgroundColor: 'rgba(255, 255, 255,0)',
					  },
					  {
						  label: 'Revenue',
						  data: monthlySale,
						  borderColor: 'rgb(0,0,0)',
						  backgroundColor: 'rgba(255, 255, 255,0)',
					  }

				  ]

			  },
			  options: {
				  responsive: true,
				  interaction: {
				  mode: 'index',
				  intersect: false,
				},
				stacked: false,
			},
		})
	  },

		calculateSales(info){
	  		  var sale = 0;
	  		  if(info.identifier == "Invoice"){
	  			  info.data = typeof info.data === "object"? info.data: JSON.parse(info.data);
	  			  for(let index in info.data){
	  				  if(info.data[index].sell >=0){
	  					  sale = parseFloat(sale) + (parseFloat(info.data[index].sell) * parseFloat(info.data[index].qty))
	  				  }
	  			  }
	  		  }
	  		  else if(info.identifier == "Proposal" || info.identifier == "Sales Order") {
	  			  info.lineitems = typeof info.lineitems === "object"? info.lineitems: JSON.parse(info.lineitems);
	  			  for(let index in info.lineitems){
	  				  if(info.lineitems[index].sell >=0){
	  					  sale = parseFloat(sale) + (parseFloat(info.lineitems[index].sell) * parseFloat(info.lineitems[index].qty))
	  				  }
	  			  }
	  		  }
	  		  else{
	  			  sale = -1;
	  		  }
	  		  return sale.toFixed(2)
	  	  },

		calculateCosts(info){
	  		  var cost = 0;
	  		  if(info.identifier == "Invoice"){
	  			  info.data = typeof info.data === "object"? info.data: JSON.parse(info.data);
	  			  for(let index in info.data){
	  				  if(info.data[index].cost >= 0){
	  					  cost = parseFloat(cost) + (parseFloat(info.data[index].cost) * parseFloat(info.data[index].qty))
	  				  }
	  			  }
	  		  }
	  		  else if(info.identifier == "Proposal" || info.identifier == "Sales Order") {
	  			  info.lineitems = typeof info.lineitems === "object"? info.lineitems: JSON.parse(info.lineitems);
	  			  for(let index in info.lineitems){
	  				  if(info.lineitems[index].cost >=0){
	  					  cost = parseFloat(cost) + (parseFloat(info.lineitems[index].cost) * parseFloat(info.lineitems[index].qty))
	  				  }
	  			  }
	  		  }
	  		  else{
	  			  cost = -1;
	  		  }
	  		  return cost.toFixed(2)
	  	  },

		clickedSalesman(row){
	  		  this.selectedSalesman = row;
	  		  this.salesmanDetails();
        },

        combineCostObjs(stockArray) {
            let resultArray = []
            let lastCost = 0
            let lastQty = 0
            for (let i = 0; i < stockArray.length; i++){
                if (stockArray[i].cost === lastCost) {
                    lastQty += stockArray[i].qty
                } else {
                    if ((lastCost > 0 && lastQty > 0)) {
                        resultArray.push({"cost": lastCost, "qty": lastQty})
                    }
                    lastCost = stockArray[i].cost
                    lastQty = stockArray[i].qty
                }
            }
            if (resultArray.length === 0) {
                resultArray.push({"cost": lastCost, "qty": lastQty})
            }
            return resultArray.map((entry) => {
                return `${entry.qty} @ $${parseFloat(entry.cost).toFixed(2)}`
            }).join(`, `)
        },

		async getAllSalesmen(){
			  await axios
			  .get(`${process.env.VUE_APP_API_TARGET}/sales/map/all/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then(async(response) => {
				  for (var salesman in response.data.queryResult) {
					  //total: leave it and add total sales to it (never changing) or change 'total' to 'showNumber' then swap in related $ (invoice, proposal, salesOrder)?
					  let invoice = {
						  count: 0,
						  sale: 0,
						  cost: 0
					  }
					  let proposal = {
						  count: 0,
						  sale: 0,
						  cost: 0
					  }
					  let salesOrder = {
						  count: 0,
						  sale: 0,
						  cost: 0
					  }
					  this.allSalesmen.push({...response.data.queryResult[salesman], total: 0, invoice, proposal, salesOrder});
					  await axios.get(process.env.VUE_APP_API_TARGET + '/users/profile/byId/'+ response.data.queryResult[salesman].auth0, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					  .then((response) =>{
						  this.allSalesmen[salesman] = {...this.allSalesmen[salesman], pic: response.data.queryResult.picture}
					  })
					  .catch((response)=>{
						  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					  })
				  }
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
		  },

        async getCOGS(){
            await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/cogs/${this.cogsPeriod}/${this.$globals.companyId}`, {
                headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response) => {
                this.cogs = response.data
                this.cogs.merch.total = this.cogs.merch?.total.toFixed(2)
                this.cogs.shipping.total = this.cogs.shipping?.total.toFixed(2)
                this.cogs.adjustments.total = this.cogs.adjustments?.total.toFixed(2)
                this.cogs.parts.total = this.cogs.parts?.total.toFixed(2)
                console.log("cogs merch:",this.cogs)
                this.$forceUpdate()
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        async getInvEval(){
            await axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/${this.$globals.companyId}`, {
                headers: {
                    'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                }
            }).then((response) => {
                this.allInventory = response.data.queryResult;
                this.invTotal = {
                    cost: 0,
                    sell: 0
                }
                this.allInventory.forEach((item) => {
                    item.otherLocations.forEach((location) => {
                        location.stock = typeof location.stock !== 'object' ? JSON.parse(location.stock) : location.stock;
                        location.stock.forEach((instance, index) => {
                            let localValCost = 0;
                            let localValSell = 0;
                            if(instance.length > 1){
                                instance.forEach((record) => {
                                    if (record.qty !== null && typeof record.cost !== 'undefined' && item.sell !== null) {
                                        localValCost += parseFloat(parseInt(record.qty) * parseFloat(record.cost))
                                        localValSell += parseFloat(parseInt(record.qty) * parseFloat(item.sell))
                                    }
                                })
                            } else {
                                instance.cost = typeof instance.cost === 'object' ? 0 : instance.cost
                                if (instance.qty !== null && typeof instance.cost !== 'undefined' && item.sell !== null) {
                                    localValCost += parseFloat(parseInt(instance.qty) * parseFloat(instance.cost))
                                    localValSell += parseFloat(parseInt(instance.qty) * parseFloat(item.sell))
                                }

                            }
                            this.invTotal.cost += parseFloat(localValCost)
                            this.invTotal.sell += parseFloat(localValSell)
                        })
                    })
                })
                this.invTotal.cost = this.invTotal.cost.toFixed(2)
                this.invTotal.sell = this.invTotal.sell.toFixed(2)
                this.$forceUpdate();
            })
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
        },

        getItemTotal(item){
            let totalCount = 0;
            let totalCost = 0;
            let totalSell = 0;
            item.otherLocations.forEach((location) => {
                location.stock.forEach((instance) => {
                    if(instance.length > 1){
                        instance.forEach((record) => {
                            totalCount += record.qty;
                            totalCost += record.qty*record.cost;
                            totalSell += record.qty*item.sell;
                        })
                    } else {
                        totalCount += instance.qty;
                        totalCost += instance.qty*instance.cost;
                        totalSell += instance.qty*item.sell;
                    }
                    if(isNaN(totalCount) || isNaN(totalCost) || isNaN(totalSell)){
                        console.log("values scuffed @ ", item, location, totalCount, totalCost, totalSell)
                        return [-1,-1,-1]
                    }
                })
            })
            return [totalCount, totalCost, totalSell]
        },

		async generateLeaderboardDetails(){
			  this.lastVisted = this.action
			  this.action = 'leaderboardDetails'

			  await axios.get(process.env.VUE_APP_API_TARGET + '/sales/invoices/'+this.$globals.companyId, {headers: {'Authorization':'Bearer '+ this.$globals.user.auth0Token}})
			  .then((response) =>{
                  this.allInvoiceData = response.data.queryResult;
                  this.leaderInvoices = this.leaderPeriod === '30d' ? this.allInvoiceData.filter((invoice) => new Date(invoice.issued).getMonth() === new Date().getMonth()) : this.leaderPeriod === '1y' ? this.allInvoiceData.filter((invoice) => new Date(invoice.issued).getFullYear() === new Date().getFullYear()) : this.allInvoiceData
			  })
			  .catch((response)=>{
				 this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })

			  await axios.get(process.env.VUE_APP_API_TARGET + '/sales/sales_orders/'+'open'+'/'+this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response) =>{
				  this.allSalesOrders = response.data.queryResult;
                  this.leaderSalesOrders = this.leaderPeriod === '30d' ? this.allSalesOrders.filter((order) => new Date(order.starts).getMonth() === new Date().getMonth()) : this.leaderPeriod === '1y' ? this.allSalesOrders.filter((order) => new Date(order.starts).getFullYear() === new Date().getFullYear()) : this.allSalesOrders
				  //get counts, $sales, $cost by steping through each 'lineitems'
				  //date is in 'starts'
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })

			  await axios.get(process.env.VUE_APP_API_TARGET + '/sales/props/open/'+this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response) =>{
				  this.allProposals = response.data.queryResult;
                  this.leaderProposals = this.leaderPeriod === '30d' ? this.allProposals.filter((order) => new Date(order.starts).getMonth() === new Date().getMonth()) : this.leaderPeriod === '1y' ? this.allProposals.filter((order) => new Date(order.starts).getFullYear() === new Date().getFullYear()) : this.allProposals
				  //date with 'starts'
				  //get counts, $sales, $cost by steping through each 'lineitems'
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })

			  //sort by salesmen and/or time?
			  for(let salesman in this.allSalesmen){
				  //TODO: account for markup?

				  //Zero out so data doesn't add up from multiple visits to leaderboardDetails
				  this.allSalesmen[salesman].salesOrder = {sale :0, cost:0, count:0}
				  this.allSalesmen[salesman].proposal = {sale :0, cost:0, count:0}
				  this.allSalesmen[salesman].invoice = {sale :0, cost:0, count:0}

				  for(let indexOne in this.leaderSalesOrders){
					  if(this.allSalesmen[salesman].name == this.leaderSalesOrders[indexOne].salesman){
						  this.allSalesmen[salesman].salesOrder.count++
						  this.leaderSalesOrders[indexOne].lineitems = JSON.parse(this.leaderSalesOrders[indexOne].lineitems)

						  for(let moneyIndex in this.leaderSalesOrders[indexOne].lineitems){
							  let tempCost = parseFloat(this.leaderSalesOrders[indexOne].lineitems[moneyIndex].cost) * parseFloat(this.leaderSalesOrders[indexOne].lineitems[moneyIndex].qty)
							  let tempSale = parseFloat(this.leaderSalesOrders[indexOne].lineitems[moneyIndex].sell) * parseFloat(this.leaderSalesOrders[indexOne].lineitems[moneyIndex].qty)
							  this.allSalesmen[salesman].salesOrder.sale = parseFloat(parseFloat(this.allSalesmen[salesman].salesOrder.sale) + tempSale).toFixed(2)
							  this.allSalesmen[salesman].salesOrder.cost = parseFloat(parseFloat(this.allSalesmen[salesman].salesOrder.cost) + tempCost).toFixed(2)
						  }
					  }
				  }

				  for(let indexTwo in this.leaderProposals){
					  if(this.allSalesmen[salesman].name == this.leaderProposals[indexTwo].salesman){
						  this.allSalesmen[salesman].proposal.count++
						  this.leaderProposals[indexTwo].lineitems = JSON.parse(this.leaderProposals[indexTwo].lineitems)

						  for(let moneyIndex in this.leaderProposals[indexTwo].lineitems){
							  let tempCost = parseFloat(this.leaderProposals[indexTwo].lineitems[moneyIndex].cost) * parseFloat(this.leaderProposals[indexTwo].lineitems[moneyIndex].qty)
							  let tempSale =parseFloat(this.leaderProposals[indexTwo].lineitems[moneyIndex].sell) * parseFloat(this.leaderProposals[indexTwo].lineitems[moneyIndex].qty)
							  this.allSalesmen[salesman].proposal.sale = parseFloat( parseFloat(this.allSalesmen[salesman].proposal.sale) + tempSale).toFixed(2)
							  this.allSalesmen[salesman].proposal.cost = parseFloat( parseFloat(this.allSalesmen[salesman].proposal.cost) + tempCost).toFixed(2)
						  }
					  }
				  }

				  for(let indexThree in this.leaderInvoices){
					  //Uses total not calculate sale.
					  if(this.allSalesmen[salesman].name == this.leaderInvoices[indexThree].salesman){
						  this.allSalesmen[salesman].invoice.count++
						  this.allSalesmen[salesman].invoice.sale = parseFloat( parseFloat(this.allSalesmen[salesman].invoice.sale) + this.leaderInvoices[indexThree].total).toFixed(2)
						  this.leaderInvoices[indexThree].data = typeof this.leaderInvoices[indexThree].data ==="object" ? this.leaderInvoices[indexThree].data : JSON.parse(this.leaderInvoices[indexThree].data)
						  for(let moneyIndex in this.leaderInvoices[indexThree].data){
							  if(this.leaderInvoices[indexThree].data[moneyIndex].cost >=0 && this.leaderInvoices[indexThree].data[moneyIndex].qty >=0){
								  let tempCost = parseFloat(this.leaderInvoices[indexThree].data[moneyIndex].cost) * parseFloat(this.leaderInvoices[indexThree].data[moneyIndex].qty)
								  this.allSalesmen[salesman].invoice.cost = parseFloat( parseFloat(this.allSalesmen[salesman].invoice.cost) + tempCost).toFixed(2)
							  }

						  }
					  }
				  }

			  }
              console.log("generate leaderboard:", this.leaderInvoices, this.leaderProposals, this.leaderSalesOrders, this.leaderPeriod)
		  },

		async instanceDetails(record, index){
				if(record.identifier == 'Proposal'){
					record.customer = typeof record.customer === "object" ? record.customer: JSON.parse(record.customer);
					record.billto = typeof record.billto === "object" ? record.billto: JSON.parse(record.billto);
					record.shipto = typeof record.shipto === "object" ? record.shipto: JSON.parse(record.shipto);
					record.lineitems = typeof record.lineitems === "object"? record.lineitems: JSON.parse(record.lineitems);
					this.selectedProposal = record;
					let temp = new Date(this.selectedProposal.starts).toISOString().split('T')
					this.selectedProposal.starts = temp[0]

					this.selectedProposalLeads = [];
					var item;
					for (item in this.leads) {
						if (this.leads[item].propid == this.selectedProposal.propid)
						this.selectedProposalLeads.push(this.leads[item]);
					}
					this.lastVisted = this.action;
					this.action = "viewProposal";
				}

				else if(record.identifier == 'Sales Order'){
					record.customer = typeof record.customer === "object"? record.customer: JSON.parse(record.customer);
					record.billto = typeof record.billto === "object"? record.billto: JSON.parse(record.billto);
					record.shipto = typeof record.shipto === "object"? record.shipto: JSON.parse(record.shipto);
					record.lineitems = typeof record.lineitems === "object"? record.lineitems: JSON.parse(record.lineitems);
					this.selectedSalesOrder = record;

					this.lastVisted = this.action;
					this.action = 'viewSalesOrder'

				}

				else if(record.identifier == 'Invoice'){

					record.vehicle = typeof record.vehicle === "object" ? record.vehicle : JSON.parse(record.vehicle)
					record.data = typeof record.data === "object"? record.data: JSON.parse(record.data);

					this.selectedInvoice = record
					this.selectedCustomer = typeof this.selectedInvoice.customer === "object"? this.selectedInvoice.customer : JSON.parse(this.selectedInvoice.customer);

					this.lastVisted = this.action;
					this.action = 'viewInvoice'

				}

				else{
					alert("Contact support, request failed")
				}
		  },

		linePractice(chart){
		  	let ctx = document.getElementById(chart);
		  	let yScale = '';
		  	let testMonths = Array.from({length:12}, (item,i)=>{
		  		return new Date(0,i).toLocaleString('en-US', {month:'short'})
		  	});

		  	this.pracGraph = new Chart(ctx,{
		  		type: 'line',
		  		data: {
		  			labels:testMonths,
		  			datasets:[
		  				{
		  					label: 'Tickets Closed',
		  					data: [1,2,3,4,5,5,6,7,8,8,10,5],
		  					borderColor: 'rgb(255, 99, 132)',
		  					backgroundColor: 'rgba(255, 255, 255,0)',
		  					yAxisID: 'y-axis-0'
		  				},
		  				{
		  					label: 'Sum of invoices',
		  					data: [10,20,30,6,18,24,15,11,19,13,14,11],
		  					borderColor: 'rgb(54, 162, 235)',
		  					backgroundColor: 'rgba(255, 255, 255,0)',
		  					yAxisID: 'y-axis-1'
		  				}

		  			]

		  		},
		  		options: {
		  			responsive: true,
		  			interaction: {
		  			mode: 'index',
		  			intersect: false,
		  		  },
		  		  stacked: false,
		  		  scales: {
		  			  yAxes: [{
		  				  type: 'linear',
		  				  display: true,
		  				  position: 'left',
		  				  grace: '10%',
		  				  "id": "y-axis-0"
		  			  },
		  			  {
		  				  type: 'linear',
		  				  display: true,
		  				  position: 'right',
		  				  "id": "y-axis-1",
		  				  grace: '10%',
		  				  grid: {
		  					  drawOnChartArea: false, // only want the grid lines for one axis to show up
		  				  },
		  			  }]
		  		  }
		  	  },
		    })
		  },

          async printInvEval(){
              this.printing = true;
              this.$nextTick(() => {
				html2canvas(document.getElementById('invEval'), {scale: 0.5}).then(async (canvas) => {
                    var imgData = canvas.toDataURL("image/png");
                    await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/photoComment/${this.$globals.companyId}`, {
                        pic: imgData
                    }, {
                        headers: {
                            'Authorization': `Bearer ${this.$globals.user.auth0Token}`
                        }
                    }).then((response) => {
                        console.log(response.data.queryResult)
                        window.open(response.data.queryResult, '_blank')
                        // axios.post(
                        // `${process.env.VUE_APP_API_TARGET}/twilio/email`,
                        // {
                        //     sgKey: this.subKey,
                        //     // to: this.selectedTicket.contact.email,
                        //     to: 'jakekowa@fleet4.net',
                        //     subject: `${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}`,
                        //     // title: this.emailTitle,
                        //     html: `<!DOCTYPE html><head><title>${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}</title></head><body><img style='max-width:8.35in' src='${response.data.queryResult}' alt="${this.selectedCompany.name} - Fleet 4 Fire Service Invoice ${this.qbid}"/></body></html>`,
                        //     text: `Can't see our email? Click the link below to view your invoice!\n\n\n${response.data.queryResult}`
                        // },
                        // {
                        //     headers: {
                        //         Authorization: `Bearer ${this.$globals.user.auth0Token}`,
                        //     }
                        // });
                    })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
                })
              })
          },

		async printPDF(dataSheet) {
			  this.printing = true;
			  let type = dataSheet.identifier
			  let number = ''
			  if(dataSheet.identifier == "Proposal"){
				  number = dataSheet.localid ?? dataSheet.id
			  }
			  else if (dataSheet.identifier == "Sales Order"){
				  number = dataSheet.localid ?? dataSheet.salesid
			  }
			  else if(dataSheet.identifier == "Invoice"){
				  number = dataSheet.qbID ?? dataSheet.id
			  }
			  this.$nextTick(() => {
				const doc = new jsPDF("p", "mm", "letter");
				const contentHtml = this.$refs.document;
				doc.html(contentHtml, {
				html2canvas: {
					scale: 0.13,
				},
				callback: function (doc) {
					doc.save(`${type} ${number}.pdf`);
				},
				x: 4,
				y: 2,
				});
			});
			this.$nextTick(() => {
				this.printing = false;
			});
		  },

		async salesmanDetails(){
		  let name = '';
		  if(this.selectedSalesman !== '' && this.selectedSalesman !== null){
			  name = this.selectedSalesman.name
		  }
		  //fake get route as a post
		  await axios.post(`${process.env.VUE_APP_API_TARGET}/sales/salesmanDetails/${this.$globals.companyId}`,
			{
				salesman : name ?? '',
				month : this.selectedMonth ?? '',
				year : this.selectedYear ?? '',
		  	},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			.then((response)=>{
				this.selectedDetails = response.data.queryResult
				this.subaction = 'salesmanDetails'
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
	  },

		async serviceDetailsTable() {
		  //customer, data, vehicle are need parsed.
		  await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/serviceDetails/${this.selectedYear}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
			  .then((response)=>{
				  this.selectedDetails = response.data.queryResult
			  })
			  .catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Business intelligence', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			  })
	  },



  },//END methods
};
</script>
<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>

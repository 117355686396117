<template>
	<div class="wrapper">
		<div v-show="action===''">
			<div class="row">
				<div class="col">
					<div class="card card-danger" style="width:100%">
						<div class="card-header">
							<div class="card-title">
								<h5><b>Service Totals</b></h5>
							</div>
							<div class="card-tools">
								<div id="buttons">
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -10%; font-size: 8pt" @click.prevent="getRevenueData( `${year}-${month}-${today}` ,'service')"><b>Day</b></b-button>
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; font-size: 8pt" @click.prevent="getRevenueData( `${year}-${month}-` ,'service')"><b>Month</b></b-button>
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; font-size: 8pt" @click.prevent="getRevenueData( `${year}-` ,'service')"><b>Year</b></b-button>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col">
									<b>Work Orders: </b> {{serviceData.length}}
								</div>
								<div class="col">
									<b>Revenue: </b> ${{calculateRevenue(serviceData).toFixed(2)}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger" style="width:100%;">
						<div class="card-header">
							<div class="card-title">
								<h5><b>Sales Totals</b></h5>
							</div>
							<div class="card-tools">
								<div id="buttons">
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -10%; font-size: 8pt" @click.prevent="getRevenueData( `${year}-${month}-${today}` ,'sales')"><b>Day</b></b-button>
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; font-size: 8pt" @click.prevent="getRevenueData(`${year}-${month}-` ,'sales')"><b>Month</b></b-button>
									<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%; font-size: 8pt" @click.prevent="getRevenueData( `${year}-` ,'sales')"><b>Year</b></b-button>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col">
									<b>Sales Orders: </b> {{salesData.length}}
								</div>
								<div class="col">
									<b>Revenue: </b> ${{calculateRevenue(salesData).toFixed(2)}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card card-danger" style="width:100%">
						<div class="card-header">
							<div class="card-title">
								<h5><b>Current Work Orders</b></h5>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col">
									<b>Unscheduled: </b> {{unscheduled}}
								</div>
								<div class="col">
									<b>Scheduled: </b> {{scheduled}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="card card-danger" style="width:100%;">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Approval Needed</b></h3>
							</div>
						</div>
						<div class="card-body" style="height: 400px">

							<b-tabs fill lazy v-model="selectedTab">

								<b-tab title="Price Changes" :title-link-class="'text-danger'">
									<b-table
										:busy="isBusy"
										striped
										sticky-header
										:items="priceChangeList"
										:fields="[
											{ key: 'ticketid', label:'Ticket Info'},
											{ key: 'salesman' ,sortable: true },
											{ key: 'customer', sortable: true },
											{ key: 'Data', label:'Description'},
											{ key: 'Action'},
										]"
									>

                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>

										<template #cell(ticketid)="row">
											{{row.item.identifier}} #{{row.item.ticketid}}<br>
											Created on {{row.item.starts}}
										</template>

										<template #cell(customer)="row">
											{{JSON.parse(row.item.customer).name}}
										</template>

										<template #cell(Data)="row">
											<h5>Proposed Price: ${{row.item.sell}}</h5>
											Item: {{row.item.itemdesc}} <br>
											Order Qty: {{row.item.qty}} <br>
											Normal Minimum of <b>${{parseFloat(row.item.minPrice).toFixed(2)}}</b>
										</template>

										<template #cell(Action)="row" style="vertical-align: middle">
											<i class="fas fa-check" style="color: green" @click="approvePriceChange(row)"></i>
											<i class="fas fa-times" style="margin-left: 10%; color: red" @click="rejectPriceChange(row)"></i>
										</template>

									</b-table>
								</b-tab>

								<b-tab title="PTO" :title-link-class="'text-danger'">
									<b-table
										:busy="isBusy"
										striped
										sticky-header
										:items="ptoList"
										:fields="[
											{ key: 'User'},
											{ key: 'Type'},
											{ key: 'Data', label:'Dates'},
											{ key: 'Action'},
										]"
									>

                                        <template #table-busy>
                                            <div class="text-center text-danger my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>

										<template #cell(User)="row">
											{{row.item.user.name}}
										</template>

										<template #cell(Type)="row">
											{{row.item.type}}
										</template>

										<template #cell(Data)="row">
											From: <b>{{row.item.requestedDates[0]}}</b>
											<br>
											To: <b>{{row.item.requestedDates[1]}}</b>
											<br>
											Created on: {{row.item.created}}
										</template>

										<template #cell(Action)="row" style="vertical-align: middle">
											<i class="fas fa-check" style="color: green" @click="approveEvent(row)"></i>
											<i class="fas fa-times" style="margin-left: 10%; color: red" @click="rejectEvent(row)"></i>
										</template>

									</b-table>
								</b-tab>

								<b-tab title="Announcements" :title-link-class="'text-danger'">
									<b-table
										:busy="isBusy"
										striped
										sticky-header
										:items="announcementList"
										:fields="[
											{ key: 'User'},
											{ key: 'message', label: 'Announcement'},
											{ key: 'created', label: 'Effective Date'},
											{ key: 'Action'},
										]"
									>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>

									<template #cell(User)="row">
										{{row.item.user.name}}
									</template>

									<template #cell(created)="row">
										<b>{{(row.item.created).split('T')[0]}}</b>
									</template>

									<template #cell(Action)="row" style="vertical-align: middle">
										<i class="fas fa-check" style="color: green" @click="approveEvent(row)"></i>
										<i class="fas fa-times" style="margin-left: 10%; color: red" @click="rejectEvent(row)"></i>
									</template>

									</b-table>
								</b-tab>

								<b-tab title="Vin Change Requests" :title-link-class="'text-danger'">
									<b-table
										:busy="isBusy"
										striped
										sticky-header
										:items="vinRequestList"
										:fields="[
											{ key: 'user', label: 'User'},
											{ key: 'wo_id', label: 'Work Order'},
											{ key: 'request', label: 'Request'},
											{ key: 'request_date', label: 'Date Requested'},
											{ key: 'Action'}
										]"
									>

                                    <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>

									<template #cell(user)="row">
										{{JSON.parse(row.item.user).name}}
									</template>

									<!-- <template #cell(request_date)="row">
										<b>{{(row.item.created).split('T')[0]}}</b>
									</template> -->

									<template #cell(Action)="row" style="vertical-align: middle">
										<i class="fas fa-check" style="color: green" @click="approveVinChange(row)"></i>
										<i class="fas fa-times" style="margin-left: 10%; color: red" @click="rejectVinChange(row)"></i>
									</template>

									</b-table>
								</b-tab>

							</b-tabs>


						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Fleet4 Subscription</b></h3>
							</div>
							<div class="card-tools">
								<b-button style="background-color: #fff4; border-color: #dc3545" @click="action='editSubscription'; editUserNum = subscriptionDetails.data.items.data.quantity"> Edit </b-button>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col">
									Started on: {{subscriptionDetails.data.billing_cycle_anchor}} {{new Date(subscriptionDetails.data.start_date).toISOString().split('T')[0]}}
									<br>
									Billing cycle: {{new Date(subscriptionDetails.data.billing_cycle_anchor).toISOString().split('T')[0]}}
								</div>
								<div class="col">
									Status: {{(subscriptionDetails.data.status).toUpperCase()}}
									<br>
									Price: ${{(parseFloat(subscriptionDetails.data.items.data.price.unit_amount) /100).toFixed(2)}}
								</div>
								<div class="col">
									Max Users: {{subscriptionDetails.data.items.data.quantity}}
									<br>
									Current Users: {{companyInfo.cur_users}}
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="col">
					<div class="card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Rollback Log</b></h3>
							</div>
						</div>
						<div class="card-body">
							Under Construction
						</div>
					</div>
				</div>
			</div>

				</div>
				<div v-if="action === 'editSubscription'">
					<div class="card card-danger">
						<div class="card-header">
							<div class="card-title">
								<h3><b>Edit Subscription</b></h3>
							</div>
							<div class="card-tools">
								<b-button style="background-color: #fff4; border-color: #dc3545" @click="changeSub()"> Submit </b-button>
								<b-button style="background-color: #fff4; border-color: #dc3545" @click="action=''"> Return </b-button>
							</div>
						</div>
						<div class="card-body">
							Number of Users:
							<input v-model="editUserNum" type="number" class=" w-25 form-control"/>
						</div>
					</div>
				</div>

		</div>



</template>

<script>
/*eslint-disable*/

	import axios from 'axios'
	import { Socket } from "socket.io-client";

	export default {
		name: "app",
		props: {
			socket: Socket,
			companyId: Number,
			user: Object,
			userRoles: Array,
		},
		data(){
			return {
				action: "",
				announcementList: [],
				companyInfo: {},
				editUserNum: 0,
				isBusy: true,
				month: String(new Date().getMonth()+1).padStart(2,'0'),
				ptoList: [],
				priceChangeList: [],
				scheduled: 0,
				selectedTab: null,
				serviceData: [],
				salesData: [],
				subscriptionDetails: {
					data: {
						start_date: 1632825739,
						billing_cycle_anchor: 1634035339,
						status: "active",
						items: {
							data:{
								price: {
									unit_amount: 444
								},
								quantity: 1,
							},
						},
					},
				},
				subPrice: -1,
				today: String(new Date().getDate()).padStart(2,'0'),
				unscheduled: 0,
				year: new Date().getFullYear(),
				vinRequestList: [],

			}
		},

		async mounted(){
			this.$globals.socket.emit('joinRoom', `adminDashboard${this.$globals.companyId}`);
			this.$globals.socket.on('newDataCreated', async (message, data)=>{
				await this.getApprovalLists();
				this.$forceUpdate()
			})
			await this.getRevenueData(`${this.year}-${this.month}-${this.today}`,'service' )
			await this.getRevenueData(`${this.year}-${this.month}-${this.today}`,'sales' )
			await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/filtered/unscheduled/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					this.unscheduled = response.data.queryResult.length
				}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/filtered/scheduled/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					this.scheduled = response.data.queryResult.length
				}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

			})

			await this.getApprovalLists();
			await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response=>{
					this.companyInfo = response.data.queryResult[0]
				}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

			await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/get/vin/change/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response=>{
					for(var item in response.data.queryResult){
						if(response.data.queryResult[item].approval === 'Pending'){
							this.vinRequestList.push(response.data.queryResult[item])
						}
					}
				}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})

            this.isBusy = false

			/**
			await axios.get(`${process.env.VUE_APP_API_TARGET}/stripe/subs/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response => {
					this.subscriptionDetails = response.data.queryResult;
					this.getPackageTotal(this.subscriptionDetails[0].plan.id, this.subscriptionDetails[0].quantity)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			**/

		},

        destroyed() {
            this.$globals.socket.emit("leaveRoom", `adminDashboard${this.$globals.companyId}`);
            this.$globals.socket.off("newDataCreated")
            console.log("destroyed adminDashboard")
        },

		methods: {
			async approveEvent(row){
				axios.patch(`${process.env.VUE_APP_API_TARGET}/companies/approval/${this.$globals.companyId}`, {
					identifier: row.item.identifier,
					approval: 'Approved',
					id: row.item.id,
				}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					if(row.item.identifier === 'PTO'){
						this.ptoList.splice(row.index, 1)
					}
					else if(row.item.identifier === 'Announcement'){
						this.announcementList.splice(row.index, 1)
					}
					this.$globals.socket.emit('newData', `dashboard${this.$globals.companyId}`);
					this.$globals.socket.emit("notifyrelevantUsers", this.$globals.companyId, [row.item.user], "PTO Approved", "PTO Approved by " + this.$globals.user.name);
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async approveVinChange(row){
				this.vinRequestList = []
				await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/update/vin/request/${this.$globals.companyId}`, {
					approval: "Approved",
					wo_id: row.item.wo_id,
					vehicle: row.item.vehicle,
					newVin: row.item.request.split(" to ")[1],
					localid: row.item.localid
				}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					console.log("THIS BE THE RESPONSE: ", response)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/get/vin/change/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response=>{
					for(var item in response.data.queryResult){
						if(response.data.queryResult[item].approval === 'Pending'){
							this.vinRequestList.push(response.data.queryResult[item])
						}
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				this.$forceUpdate()
			},

			async rejectVinChange(row){
				this.vinRequestList = []
				await axios.patch(`${process.env.VUE_APP_API_TARGET}/tickets/update/vin/request/${this.$globals.companyId}`, {
					approval: "Rejected",
					wo_id: row.item.wo_id,
					localid: row.item.localid
				}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					console.log("THIS BE THE RESPONSE: ", response)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/tickets/get/vin/change/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response=>{
					for(var item in response.data.queryResult){
						if(response.data.queryResult[item].approval === 'Pending'){
							this.vinRequestList.push(response.data.queryResult[item])
						}
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.$forceUpdate()
			},

			async approvePriceChange(row){
				let status = 'Open'
				for(let item in this.priceChangeList){
					if(this.priceChangeList[item].identifier === row.item.identifier && this.priceChangeList[item].ticketid === row.item.ticketid && parseInt(item) !== parseInt(row.index)){
						status = 'Pending'
						break;
					}
				}
				if(row.item.identifier === "Sales Order"){
					await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/sales_order/approval/${row.item.ticketid}/${this.$globals.companyId}`,
					{
						newPrice: row.item.sell,
						arrayIndex: row.item.arrayIndex,
						status: status,
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response =>{ this.priceChangeList.splice(row.index, 1) })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
				else if( row.item.identifier === "Proposal"){
					await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/proposal/approval/${row.item.ticketid}/${this.$globals.companyId}`,
					{
						newPrice: row.item.sell,
						arrayIndex: row.item.arrayIndex,
						status: status,
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token}})
					.then(response =>{ this.priceChangeList.splice(row.index, 1) })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
				else{
					alert("Unexpected identifier")
					return;
				}

			},

			calculateRevenue(data){
				let value = 0;
				for(let index in data){
					value = parseFloat(value) + parseFloat(data[index].revenue)
				}
				return value;
			},

			async changeSub(){
				//check if less users
				if(this.editUserNum < this.companyInfo.cur_users){
					alert("Please removed users before reducing maximum users")
					return;
				}
				else{
					await axios.patch(`${process.env.VUE_APP_API_TARGET}/companies/users/max/${this.$globals.companyId}`,{
						users: this.editUserNum
					}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}

			},

			async getRevenueData(time, type){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/revenue/${time}/${type}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response)=>{
					if(type == 'sales'){
						this.salesData = response.data.queryResult
					}
					else if(type == 'service'){
						this.serviceData = response.data.queryResult
					}
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async getApprovalLists(){
				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/announcements/approval/required/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response =>{
						this.announcementList = response.data.queryResult
						for(let index in this.announcementList){
							this.announcementList[index].user = typeof this.announcementList[index].user === 'object' ? this.announcementList[index].user : JSON.parse(this.announcementList[index].user)
							this.announcementList[index].identifier= 'Announcement'
						}
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/companies/pto/approval/required/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response =>{
						this.ptoList = response.data.queryResult
						for(let index in this.ptoList){
							this.ptoList[index].user = typeof this.ptoList[index].user === 'object' ? this.ptoList[index].user : JSON.parse(this.ptoList[index].user)
							this.ptoList[index].requestedDates = typeof this.ptoList[index].requestedDates === 'object' ? this.ptoList[index].requestedDates : JSON.parse(this.ptoList[index].requestedDates)
							this.ptoList[index].identifier = 'PTO'
						}
					}
				)
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.get(`${process.env.VUE_APP_API_TARGET}/sales/approval/required/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response=>{
						this.priceChangeList = response.data.queryResult
					})
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})

			},

			async getPackageTotal(pack, qty){
                var total = 0
                await axios.get(`${process.env.VUE_APP_API_TARGET}/stripe/price/${pack}/${qty}`).then(response => {
                    switch(response.data.queryResult.nickname){
                        case "Show Special":
                            total = (700+29.99*qty).toFixed(2);
                            break;
                        case "Show Special Annual":
                            total = (8000+360*qty).toFixed(2);
                            break;
                        case "Standard Rate":
                            total = (850+29.99*qty).toFixed(2);
                            break;
                        case "Standard Annual":
                            total = (9200+360*qty).toFixed(2);
                            break;
                    }
                })
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
            },

			async rejectEvent(row){
				axios.patch(`${process.env.VUE_APP_API_TARGET}/companies/approval/${this.$globals.companyId}`, {
					identifier: row.item.identifier,
					approval: 'Rejected',
					id: row.item.id,
				}, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					if(row.item.identifier === 'PTO'){
						this.ptoList.splice(row.index, 1)
					}
					else if(row.item.identifier === 'Announcement'){
						this.announcementList.splice(row.index, 1)
					}
					//this.$globals.socket.emit('newData', `dashboard${this.$globals.companyId}`);
					this.$globals.socket.emit('newData', `adminDashboard${this.$globals.companyId}`);
					this.$globals.socket.emit("notifyReleventUsers", this.$globals.companyId, [row.item.user], "PTO Rejected", "PTO Rejected by " + this.$globals.user.name);
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			},

			async rejectPriceChange(row){
				row.item.sell = row.item.minPrice
				let status = 'Open'
				for(let item in this.priceChangeList){
					if(this.priceChangeList[item].identifier === row.item.identifier && this.priceChangeList[item].ticketid === row.item.ticketid && parseInt(item) !== parseInt(row.index)){
						status = 'Pending'
						break;
					}
				}
				if( row.item.identifier === "Sales Order"){
					await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/sales_order/approval/${row.item.ticketid}/${this.$globals.companyId}`,
					{
						newPrice: row.item.sell,
						arrayIndex: row.item.arrayIndex,
						status: status,
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
					.then(response =>{ this.priceChangeList.splice(row.index, 1) })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}

				else if( row.item.identifier === "Proposal"){
					await axios.patch(`${process.env.VUE_APP_API_TARGET}/sales/proposal/approval/${row.item.ticketid}/${this.$globals.companyId}`,
					{
						newPrice: row.item.sell,
						arrayIndex: row.item.arrayIndex,
						status: status,
					},{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token}})
					.then(response =>{ this.priceChangeList.splice(row.index, 1) })
					.catch((response)=>{
						this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Admin Dashboard', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
					})
				}
				else{
					alert("Unexpected identifier")
					return;
				}


			},


		}

	}

</script>

<style>

</style>

<template>
	<div>
		<div class="card card-danger">
			<div class="card-header">
				<h3 class="card-title"><b>Part Returns</b></h3>
			</div>
			<div class="card-body">
				<form class="searchbar" id="searchFormParts">
					<!-- TODO FIX the search bars, lots has changed and they haven't
					<input
						type="text"
						class="form-control-inline"
						v-model="searchReturns"
						placeholder="Search Returns"
						style="text-align: center; margin-bottom: 10px;"
					/>
					-->
				</form>
				<b-table
					ref="returnsTable"
					class="new-styled-table"
					striped
					:busy="partReturnTableBusy"
					:items="getReturns"
					:currentPage="currentPageReturns"
					:per-page="5"
					:fields="[
						{ key: 'returnid', sortable: true, label: 'Return #' },
						{ key: 'ticketid', sortable: true, label: 'Work Order #' },
						{ key: 'return_parts', sortable: true, label: 'Parts'},
						{ key: 'user', sortable: true, label: 'Returnee'},
					]"
					@row-clicked="$emit('rowClickedEvent', $event)"
					responsive="lg"
					table-variant="red"
					:filter-included-fields="['ticketid']"
					>

					<template #table-busy>
						<div class="text-center text-danger my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Loading...</strong>
						</div>
					</template>

					<template #cell(ticketid)="row">
						<div>
							<b>{{row.item.ticketid}}</b>
							<br>
							{{row.item.task}}
						</div>
					</template>

					<template #cell(user)="row">
						<div>
							<b>{{row.item.user !== null ? row.item.user : "None"}}</b>
							<br>
							{{row.item.date}}
						</div>
					</template>

					<template #cell(return_parts)="row">
						<div style="display: inline">
							<p><span style="font-weight: 900">Item:</span>
								{{row.item.return_parts.itemid}}
								<br>
								<span style="font-weight: 900">Qty:</span>
								{{row.item.return_parts.returnAmount}}
							</p>
						</div>
					</template>
				</b-table>

			</div>
			<div class="card-footer">
				<b-pagination
					:class="{customPagination: true}"
					v-model="currentPageReturns"
					:per-page="5"
					limit = "3"
					:total-rows="parseInt(maxPagesReturns) * 5"
					aria-controls="returnsTable"
					style="margin-left: 38.5%"
					>
				</b-pagination>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
	import Vue from "vue";
	import axios from "axios";
	import * as https from "https";
	import { BPagination } from "bootstrap-vue";
	import { debounce } from 'lodash';


	export default{
		name: "PartReturns",
		data(){
			return{
				allPartReturns: [],
				currentPageReturns: 1,
				maxPagesReturns: 0,
				partReturnTableBusy : true,
				//searchReturns: "",


			}
		},
		created(){
			//this.debounceReturns = debounce(this.searchReturns, 600);

		},
		async mounted(){
			this.partReturnTableBusy = false;
		},
		methods: {

			async getReturns(){
				this.partReturnTableBusy = true;

			  await axios
			  .get(`${process.env.VUE_APP_API_TARGET}/tickets/get_returns/page/${this.currentPageReturns}/search_text/${null}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
				  response.data.queryResult.results.forEach((item, i) => {
				  	item.return_parts = typeof item.return_parts === 'object' ? item.return_parts : JSON.parse(item.return_parts)
				  });
				  this.allPartReturns[this.currentPageReturns] = response.data.queryResult.results
				  this.maxPagesReturns = response.data.queryResult.maxPages
				})
				.catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'partReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.partReturnTableBusy = false;
				this.$forceUpdate()

				return this.allPartReturns[this.currentPageReturns]

			},

			/*
			async partReturnsProvider(pageNumber){
			  this.partReturnTableBusy = true;
			  this.currentPageReturns = pageNumber;
			  let text = this.prevSearchReturns === "" ? null : this.prevSearchReturns
			   await axios
			  .get(`${process.env.VUE_APP_API_TARGET}/tickets/get_returns/page/${this.currentPageReturns}/search_text/${text}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then((response) => {
				  this.allPartReturns[this.currentPageReturns] = response.data.queryResult.results
				  this.maxPagesReturns = response.data.queryResult.maxPages
				})
				.catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'partReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			  this.partReturnTableBusy = false;
			},
			*/
			/*
			async searchReturns(searchValue){
				if(searchValue === "" || searchValue === null){
				  if(this.prevSearchReturns === "" || this.prevSearchReturns === null){
					return
				  }
				  searchValue = null
				}
				this.partReturnTableBusy = true;
				this.prevSearchReturns = searchValue
				this.currentPageReturns = 1
				this.allPartReturns = []
				await axios
				  .get(`${process.env.VUE_APP_API_TARGET}/tickets/get_returns/page/${1}/search_text/${searchValue}/${this.$globals.companyId}`, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				  .then((response) => {
					this.allPartReturns[this.currentPageReturns] = response.data.queryResult.results
					this.$refs.partReturnComponent.allPartReturns = this.allPartReturns[this.currentPageReturns]
					this.maxPagesReturns = response.data.queryResult.maxPages
				  })
				.catch((response)=>{
				  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'partReturn', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
				this.partReturnTableBusy = false;
			},
			*/
		}//END methods
	}
</script>

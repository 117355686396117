<template>
	<div>
		<div class="card card-danger" style="padding-bottom: 1%">
			<div class="card-header">
				<div class="card-title">
					<h3><b>New Firehouse for {{this.customer.name}}</b></h3>
				</div>
				<div class="card-tools">
					<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: -5%;" @click.prevent="pushHouse()">Submit</b-button>
					<b-button style="background-color: #fff4; border-color: #dc3545; margin-left: 1%;" @click.prevent="reject()">Return</b-button>
				</div>
			</div>
			<div class="card-body">
				<label for="h_name" class="w-75 text-left mt-2" style="margin-left: 13.5%">Name<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<input id="h_name" type="text" class="form-control w-75" style="margin-top: 1%; margin-left:12.5%" v-model="newHouse.name" placeholder="Name" required/>
				<label for="h_address" class="w-75 text-left mt-2" style="margin-left: 13.5%">Address<span style="color:#dc3545;" v-b-tooltip.hover title="Required Field">*</span></label>
				<input id="h_address" class="form-control w-75" type="text" v-model="newHouse.street" style="margin-left: 12.5%" placeholder="Street" required/>
				<input id="h_address2" class="form-control w-75" type="text" v-model="newHouse.city" style="margin-left: 12.5%" placeholder="City" required/>
				<multiselect class="w-75" style="margin-left:12.5%" :options="allStates" label="name" track-by="name" v-model="newHouse.state" placeholder="State" :show-labels="false" required></multiselect>
				<input id="h_address3" class="form-control w-75" type="text" v-model="newHouse.zip" style="margin-left: 12.5%" placeholder="Zip Code" required/>

				<label for="h_access" class="w-75 text-left mt-2" style="margin-left: 13.5%">Access</label>
				<input id="h_access" type="text" class="form-control w-75" style="margin-top: 1%; margin-left:12.5%" v-model="newHouse.access" placeholder="Access"/>
			</div>
		</div>
	</div>
</template>

<script>
/*eslint-disable*/
import axios from "axios";
import Multiselect from 'vue-multiselect'


export default {
	name: "app",
	props: {
	  customer: Object,
	},
	components: {
		Multiselect
	},
	data(){
		return{
			allStates: [
				{
					"name": "Alabama",
					"abbreviation": "AL"
				},
				{
					"name": "Alaska",
					"abbreviation": "AK"
				},
				{
					"name": "American Samoa",
					"abbreviation": "AS"
				},
				{
					"name": "Arizona",
					"abbreviation": "AZ"
				},
				{
					"name": "Arkansas",
					"abbreviation": "AR"
				},
				{
					"name": "California",
					"abbreviation": "CA"
				},
				{
					"name": "Colorado",
					"abbreviation": "CO"
				},
				{
					"name": "Connecticut",
					"abbreviation": "CT"
				},
				{
					"name": "Delaware",
					"abbreviation": "DE"
				},
				{
					"name": "District Of Columbia",
					"abbreviation": "DC"
				},
				{
					"name": "Federated States Of Micronesia",
					"abbreviation": "FM"
				},
				{
					"name": "Florida",
					"abbreviation": "FL"
				},
				{
					"name": "Georgia",
					"abbreviation": "GA"
				},
				{
					"name": "Guam",
					"abbreviation": "GU"
				},
				{
					"name": "Hawaii",
					"abbreviation": "HI"
				},
				{
					"name": "Idaho",
					"abbreviation": "ID"
				},
				{
					"name": "Illinois",
					"abbreviation": "IL"
				},
				{
					"name": "Indiana",
					"abbreviation": "IN"
				},
				{
					"name": "Iowa",
					"abbreviation": "IA"
				},
				{
					"name": "Kansas",
					"abbreviation": "KS"
				},
				{
					"name": "Kentucky",
					"abbreviation": "KY"
				},
				{
					"name": "Louisiana",
					"abbreviation": "LA"
				},
				{
					"name": "Maine",
					"abbreviation": "ME"
				},
				{
					"name": "Marshall Islands",
					"abbreviation": "MH"
				},
				{
					"name": "Maryland",
					"abbreviation": "MD"
				},
				{
					"name": "Massachusetts",
					"abbreviation": "MA"
				},
				{
					"name": "Michigan",
					"abbreviation": "MI"
				},
				{
					"name": "Minnesota",
					"abbreviation": "MN"
				},
				{
					"name": "Mississippi",
					"abbreviation": "MS"
				},
				{
					"name": "Missouri",
					"abbreviation": "MO"
				},
				{
					"name": "Montana",
					"abbreviation": "MT"
				},
				{
					"name": "Nebraska",
					"abbreviation": "NE"
				},
				{
					"name": "Nevada",
					"abbreviation": "NV"
				},
				{
					"name": "New Hampshire",
					"abbreviation": "NH"
				},
				{
					"name": "New Jersey",
					"abbreviation": "NJ"
				},
				{
					"name": "New Mexico",
					"abbreviation": "NM"
				},
				{
					"name": "New York",
					"abbreviation": "NY"
				},
				{
					"name": "North Carolina",
					"abbreviation": "NC"
				},
				{
					"name": "North Dakota",
					"abbreviation": "ND"
				},
				{
					"name": "Northern Mariana Islands",
					"abbreviation": "MP"
				},
				{
					"name": "Ohio",
					"abbreviation": "OH"
				},
				{
					"name": "Oklahoma",
					"abbreviation": "OK"
				},
				{
					"name": "Oregon",
					"abbreviation": "OR"
				},
				{
					"name": "Palau",
					"abbreviation": "PW"
				},
				{
					"name": "Pennsylvania",
					"abbreviation": "PA"
				},
				{
					"name": "Puerto Rico",
					"abbreviation": "PR"
				},
				{
					"name": "Rhode Island",
					"abbreviation": "RI"
				},
				{
					"name": "South Carolina",
					"abbreviation": "SC"
				},
				{
					"name": "South Dakota",
					"abbreviation": "SD"
				},
				{
					"name": "Tennessee",
					"abbreviation": "TN"
				},
				{
					"name": "Texas",
					"abbreviation": "TX"
				},
				{
					"name": "Utah",
					"abbreviation": "UT"
				},
				{
					"name": "Vermont",
					"abbreviation": "VT"
				},
				{
					"name": "Virgin Islands",
					"abbreviation": "VI"
				},
				{
					"name": "Virginia",
					"abbreviation": "VA"
				},
				{
					"name": "Washington",
					"abbreviation": "WA"
				},
				{
					"name": "West Virginia",
					"abbreviation": "WV"
				},
				{
					"name": "Wisconsin",
					"abbreviation": "WI"
				},
				{
					"name": "Wyoming",
					"abbreviation": "WY"
				}
			],
			newHouse: {
				name: '',
				address: '',
				street: '',
				city: '',
				state: null,
				zip: '',
				access: null
			}

		};
	},
	mounted(){
		if(this.customer === null || this.customer?.customerid === undefined){
			alert("No Customer was passed")
			this.reject();
		}
		if(this.$globals.companyId < 0){
			alert("Invlaid Company ID")
			this.reject();
		}
		if(this.$globals.user === null){
			alert("No User Passed")
			this.reject();
		}


	},
	methods: {
		async pushHouse() {
			if(this.newHouse?.name == undefined || this.newHouse.name == null || this.newHouse.name === ''){
				alert("House needs a name ex) House 1")
				return;
			}
			if(this.newHouse.street === null || this.newHouse.street === undefined || this.newHouse.street===''){
				alert("Street is required")
				return;
			}
			if(this.newHouse.city === null || this.newHouse.city === undefined || this.newHouse.city===''){
				alert("City is required")
				return;
			}
			if(this.newHouse.state === null || this.newHouse.state === undefined || this.newHouse.state===''){
				alert("State is required")
				return;
			}
			if(this.newHouse.zip === null || this.newHouse.zip === undefined || this.newHouse.zip===''){
				alert("Zip code is required")
				return;
			}


			let formattedAddress = `${this.newHouse.street}, ${this.newHouse.city}, ${this.newHouse.state.abbreviation} ${this.newHouse.zip}`
			this.newHouse.address = formattedAddress
			await axios.post(`${process.env.VUE_APP_API_TARGET}/customers/${this.customer.customerid}/house/${this.$globals.companyId}`,
				this.newHouse,
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			)
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Customers', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
			this.$emit('accept')

		},

		reject(){
			this.$emit('cancelHouse')
		},
	}

}

</script>

<style>

</style>

<template>
    <div class="card card-danger">
        <div class="card-header">
            <div class="card-title">
                <b>Technician Locator</b>
            </div>
            <div class="card-tools">
                <b-button style="background-color: #fff4; border-color: #dc3545; margin-right: 1%; margin-top: 1%" @click.prevent="$emit('closeMap')">Return</b-button>
            </div>
        </div>
        <div class="card-body">
            <div ref="map" id="map" class="map" style="height: 500px"></div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import MarkerClusterer from '@googlemaps/markerclustererplus'

export default {
    name: "techLocator",
    props: {
        allTechs: Array
    },
    components: {

    },

    data() {
        return {
            infoScreens: [],
            map: null,
            markers: [],
        }
    },

    mounted() {
        this.createMap()
    },

    methods: {
        async createMap(){
			//get tech locations
            console.log("createMap")
			var tempLocations = [];
			this.markers = [];
			var techIcon = [];
            for (let tech in this.allTechs) {
				if(this.allTechs[tech].location !== null && this.allTechs[tech].name !== 'Unassigned'){
                    var location = JSON.parse(this.allTechs[tech].location);
                    location = { lat: parseFloat(location.coords.latitude), lng: parseFloat(location.coords.longitude), speed: parseFloat(location.coords.speed) };
					tempLocations.push({name: this.allTechs[tech].name, location: location, picture: this.allTechs[tech].picture, timestamp: new Date(parseFloat(JSON.parse(this.allTechs[tech].location).timestamp)).toLocaleString()});
				}
			}

            this.$nextTick(() => {
                let center = {}
                if (this.allTechs.length > 1) {
                    center = {lat:38.503720,lng:-90.295510}
                } else {
                    center = {lat: tempLocations[0].location.lat,lng: tempLocations[0].location.lng}
                }
				this.map = new window.google.maps.Map(this.$refs["map"],{
					center: center,
					zoom: 12
				})
                for (let loc in tempLocations) {
                    console.log("TEMPLOCATIONS", tempLocations[loc])
                    
					techIcon[loc] = {
						url: tempLocations[loc].picture,
						scaledSize: new google.maps.Size(50, 50)//in pixels
                    };
                    
                    this.infoScreens[loc] = new window.google.maps.InfoWindow({
                        content: `<b>Last updated:</b> ${tempLocations[loc].timestamp}`
                    })

					this.markers[loc] = new window.google.maps.Marker({
						position: {lat:tempLocations[loc].location.lat,lng:tempLocations[loc].location.lng},
						map: this.map,
						icon: techIcon[loc],
                        title: tempLocations[loc].name,
                    });

                    this.markers[loc].addListener("click", (e) => {
                        console.log("click event", e)
                        this.infoScreens[loc].open({
                            anchor: this.markers[loc],
                            map: this.map,
                            shouldFocus: true
                        })
                    })

                    
				}
                // Add a marker clusterer to manage the markers.
                var markerCluster = new MarkerClusterer(this.map, this.markers, {
                    imagePath:
                    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
                });

			})
			//https://stackoverflow.com/questions/4416089/google-maps-api-v3-custom-cluster-icon
        },//END createMap()

        closeMap() {
            for(let mark in this.markers){
				this.markers[mark].setMap(null);
			}  
        },
    }
}
</script>

<style scoped>
    .marker-position {
        position: absolute;
        top: 1000;
        left: 0
    }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-3"},[(_vm.action === '')?_c('div',[_c('div',{staticClass:"card card-danger"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('b-table',{attrs:{"striped":"","current-page":_vm.currentPage.inShop,"items":_vm.shopTickets,"per-page":10,"busy":_vm.isBusy.inShop,"fields":[
						{key: 'localid', label: 'Work Order #'},
						{key: 'vehicle'},
						{key: 'technician'},
						{key: 'customer'},
						{key: 'deliveredOn', label: 'Asset Arrived'}
					]},on:{"row-clicked":_vm.viewTicket},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(technician)",fn:function(row){return [_c('p',_vm._l(((typeof row.item.technician ==='object' ?row.item.technician : JSON.parse(row.item.technician))),function(tech){return _c('span',{key:tech.name},[_vm._v(_vm._s(tech.name)+" ")])}),0)]}}],null,false,3299149987)})],1)]),_c('div',{staticClass:"card card-danger"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('input',{staticClass:"form-control",staticStyle:{"width":"95%","margin-top":"1vw"},attrs:{"type":"text","placeholder":"Search by Customer, VIN, Vehicle ID, Make, Model, or Year"},on:{"input":_vm.debounceVehicles}}),_c('b-table',{attrs:{"items":_vm.allVehicles,"busy":_vm.isBusy.searchVehicles,"fields":[
						{key: 'vehicleid', label: 'Vehicle ID'},
						{key: 'vin'},
						{key: 'customer'},
						{key: 'make'},
						{key: 'model'},
						{key: 'year'}
					],"striped":""},on:{"row-clicked":_vm.viewVehicle},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true}],null,false,2167337275)})],1)]),_c('div',{staticClass:"card card-danger"},[_c('div',{staticClass:"card-header"},[_vm._m(2),_c('div',{staticClass:"card-tools"},[(_vm.$globals.canAccess.admin)?_c('b-button',{staticStyle:{"background-color":"#fff4","border-color":"#dc3545"},on:{"click":function($event){_vm.action='newCompanyVehicle'}}},[_vm._v("New")]):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('div',[_c('b-table',{ref:"companyVehiclesTable",staticClass:"new-styled-table",attrs:{"striped":"","current-page":_vm.currentPage.companyVehicles,"items":_vm.allCompVehicles,"per-page":5,"fields":[
							{ key: 'vin', sortable: true },
							{ key: 'vehicleid', label: 'Vehicle Identifier'},
							{ key: 'license'},
							{ key: 'location', label: 'Located at'},
							{ key: 'responsibleUser', label: 'User'}
						],"busy":_vm.isBusy.companyVehicles,"responsive":"lg","table-variant":"red"},on:{"row-clicked":_vm.viewCompVehicle},scopedSlots:_vm._u([{key:"cell(license)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.license)+" ")]}}],null,false,1605452142)}),_c('b-pagination',{staticClass:"customPagination",staticStyle:{"margin-left":"45.5%","margin-right":"42.5%"},attrs:{"size":"sm","per-page":5,"limit":"5","total-rows":_vm.allCompVehicles.length,"aria-controls":"companyVehiclesTable"},model:{value:(_vm.currentPage.companyVehicles),callback:function ($$v) {_vm.$set(_vm.currentPage, "companyVehicles", $$v)},expression:"currentPage.companyVehicles"}})],1)])])]):_vm._e(),(_vm.action==='viewTicket')?_c('div',[_c('viewTicket',{attrs:{"selectedTicket":_vm.selectedTicket},on:{"return":function($event){_vm.action=''}}})],1):_vm._e(),(_vm.action === 'viewVehicle')?_c('div',[_c('viewVehicle',{attrs:{"passedVehicle":_vm.selectedVehicle,"selectedCustomer":_vm.selectedVehicle.customer},on:{"return":function($event){_vm.action = ''}}})],1):_vm._e(),(_vm.action ==='newCompanyVehicle')?_c('div',[_c('newCompanyVehicle',{on:{"return":function($event){_vm.getCompanyVehicles();_vm.action=''}}})],1):_vm._e(),(_vm.action === 'viewCompVehicle')?_c('div',[_c('viewCompanyVehicle',{attrs:{"targetVehicle":_vm.selectedVehicle},on:{"return":function($event){_vm.getCompanyVehicles();_vm.action = ''}}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',[_c('b',[_vm._v("Vehicles In Shop")])])]),_c('div',{staticClass:"card-tools"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_c('h3',[_c('b',[_vm._v("Vehicle Lookup")])])]),_c('div',{staticClass:"card-tools"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-title"},[_c('h3',[_c('b',[_vm._v("Company Vehicles")])])])}]

export { render, staticRenderFns }
<template>
      <div class="card card-danger">
            <div class="card-header">
              <div class="card-title">
                <h3><b>Document</b></h3>
              </div>
              <div class="card-tools">
                <b-button
                  class="mx-1 my-3"
                  style="background-color: #fff4; border-color: #dc3545"
                  @click="downloadCount === 1 ? downloadDocumentAgain() : downloadDocument()"
                  >Save
                </b-button>
				<b-button
				  class="mx-1 my-3"
				  style="background-color: #fff4; border-color: #dc3545"
				  @click.prevent="$emit('backFromViewDocument')"
				  >Return
				</b-button>
              </div>
            </div>
            <div class="card-body" ref="returnLabel">
              <div style="width: 100%; text-align: center;">
                <p style="font-size: 26px;"><b>RGA #: {{RGA}}</b></p>
              </div>
              <p>{{new Date().toDateString()}}</p>
              <br>
              <div style="display: flex;">
                <p><b>TO:</b></p>
                <p style="margin-left: 10px;">{{vendor.address}}</p>
              </div>
              <br>
              <div>
                <p><b>PRODUCT:</b></p>
                <p>{{product}}</p>
              </div>
              <br>
              <div>
                <p><b>QTY:</b></p>
                <p>{{returnToVendorQty}}</p>
              </div>
              <br>
              <div>
                <p><b>REASON:</b></p>
                <p>{{returnReason === "" ? "None" : returnReason}}</p>
              </div>
              <br>
              <div>
                <p><b>SERIAL NUMBER:</b></p>
                <p>{{serialNumber === "" ? "None" : serialNumber}}</p>
              </div>
              <br>
              <div>
                <p><b>CUSTOMER:</b></p>
                <p>{{customer.customer}}</p>
              </div>
              <br>
              <div>
                <p><b>Notes:</b></p>
                <p>{{returnNotes === "" ? "None" : returnNotes}}</p>
              </div>

              <div style="display: flex; width: 100%; justify-content: center; margin-top: 50px;">
                <div style="width: 33%;">
                  {{company.name}}<br>
                  {{`${company.address[0]}`}}<br>
                  {{`${company.address[1]}`}}<br>
                  {{`${company.address[2]}`}}
                </div>

                <div style="width: 33%;">
                  <p>Phone: <b>{{company.phone}}</b></p>
                </div>

                <div style="width: 33%;">
                  <p>Email: <b>{{$globals.user.email}}</b></p>
                </div>
              </div>
            </div>
      </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";


export default{
	name: "ViewDocument",
	components: {

	},
	props: {
	    RGA: String,
	    product: String,
	    returnReason: String,
	    returnNotes: String,
	    vendor: Object,
	    partReturn: Object,
	    customer: Object,
	    serialNumber: String,
	    returnToVendorQty: Number,
	},

  async created(){
    // console.log("HELLO: ", this.$globals.user)
    await axios
    .get(process.env.VUE_APP_API_TARGET + "/companies/" + this.$globals.companyId,
      {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
    .then((response) => {
        this.company = typeof response.data.queryResult[0] !== 'object' ? JSON.parse(response.data.queryResult[0]) : response.data.queryResult[0];
		this.company.address = this.company.address.split(',')
    })
    .catch((response)=>{
      this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewDocument', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
    })
  },
  data() {
    return {
      company: {
		  address: ''
	  },
      downloadCount: 0,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    async downloadDocument(){
      // Get index of selected item to reduce the return amount
      //let index = JSON.parse(this.partReturn.return_parts).findIndex(item => item.itemid === this.product || item.vendor === this.vendor)
      this.$el.dispatchEvent(new CustomEvent('returnDocument', { detail: { 'content': this.$refs.returnLabel, 'company': this.vendor, 'type' : 'Document', 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));

	  let part_returns = typeof this.partReturn.return_parts !== 'object' ? JSON.parse(this.partReturn.return_parts) : this.partReturn.return_parts

      part_returns.returnAmount = parseInt(part_returns.returnAmount) - parseInt(this.returnToVendorQty)
	  part_returns.fulfilledQty = part_returns.fulfilledQty - parseInt(this.returnToVendorQty)

      if(part_returns.returnAmount === 0){
        this.partReturn.completed = 1
      }

      // update part return
      await axios.patch(process.env.VUE_APP_API_TARGET + "/tickets/return_parts/"+this.$globals.companyId, {
        data: JSON.stringify(part_returns),
        returnid: this.partReturn.returnid,
        status: this.partReturn.completed,
		task: this.partReturn.task,
		ticketid: this.partReturn.ticketid
      }, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
      .then(response => {
        // console.log("RESULT: ", response)
      })
      .catch((response)=>{
        this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'viewDocument', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
      })

      this.downloadCount++;
    },

    downloadDocumentAgain(){
      this.$el.dispatchEvent(new CustomEvent('returnDocument', { detail: { 'content': this.$refs.returnLabel, 'company': this.vendor, 'type' : 'Document', 'component' : 'Parts', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
    }
  }
}

</script>

<template>
	<div>
	<div class="modal-backdrop">
			<div class="modal" ref="itemModal" id="itemModal" tabindex="-1" role="dialog" :style="{'background-color' : backgroundColor}">
				<div class="modal-header">
					<h4 class="modal-title" id="itemModalLabel">
						<b>Move Inventory {{item.itemdesc}}</b>
						<br>
						<label style="font-size: 14pt" for="warrantyCheckbox">Warranty Part:</label>
						<input id="warrantyCheckbox" type="checkbox" v-model="warrantyFlag"/>
					</h4>
							<button type="button" class="close" @click="reject()">
								<span>&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<b-row>
								<b-col>
									<h5>From: </h5>
									<multiselect
										id="selectFromLoc"
										v-model="fromLoc"
										:options="moveFromList"
										:max-height="200"
										:group-select="false"
										group-label = "name"
										group-values = "items"
										:custom-label="({vendor, bin, stock, warrantyStock}) => {
											if(location !== undefined && bin !== undefined){
												return `${bin} - ${vendor} - Qty:${warrantyFlag ? warrantyStock : stock}`
											}
											else{
												return 'Select From Location'
											}
										}"
										placeholder="From"
										:clearable="true"
										:show-labels="false"
										>
									</multiselect>
                                    <label class="mt-3">Amount:</label><br/>
                                    <input type="number" pattern="[0-9]{1,5}" class="form-control w-100" min="0" :max="warrantyFlag ? fromLoc.warrantyStock : fromLoc.stock" v-model="numberPool">
								</b-col>

								<b-col>
									<h5>To: </h5>
										<multiselect
											ref="locationSelector"
											label="name"
											track-by="name"
											:max-height="200"
											:clearable="true"
											:searchable="true"
											:options="allLocations"
											:show-labels="false"
											v-model="location"
											placeholder="Location"
											class="w-75"
											display: inline
											@select="getDistinctBins"
										>
										</multiselect>


									<label for="binLoc" class="w-75 text-left mt-3 mb-n1">Bin Location:</label>
									<multiselect
										v-model="newBin"
										class="w-75"
										:options = "binList"
										:show-labels="false"
										placeholder="Bin">
									</multiselect>

								</b-col>
							</b-row>
						</div>
						<div class="modal-footer">
							<b-row>
								<b-col>
									<button type="button" class="btn btn-danger" :disabled="item.qty < numberPool < 0" @click="accept()">Confirm</button>
								</b-col>
							</b-row>
						</div>
			</div>

		</div>

	</div>
</template>

<script>
/*eslint-disable*/

import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default{
	name: "app",
	props:{
		item: Object,
	},
	components: { Multiselect },
	data(){
		return{
			allLocations: [],
			backgroundColor: 'white',
			binList: [],
			fromLoc: {},
			location: '',
			locationOptions: [],
			moveFromList: [],
			numberPool: 0,
			newBin: '',
			toLoc: {},
			warrantyFlag: false
		};
	},
	async mounted(){
		if(this.$globals.companyId < 0){
			alert("Invlaid Company ID")
			this.reject();
		}
		if(this.$globals.user === null){
			alert("No User Passed")
			this.reject();
		}
		if(this.item === null){
			alert("Issue with selected Item")
			this.reject()
		}

		await axios
		  .get(process.env.VUE_APP_API_TARGET + "/locations/all/" + this.$globals.companyId, {headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
		  .then((response) => {this.allLocations = response.data.queryResult})
		  .catch((response)=>{
			  this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
		  })

		this.item.qty = parseInt(this.item.qty)
		this.backgroundColor = this.$globals.displayClass ? '#31373d' : 'white';

		let headerList = []
		this.moveFromList =[]

		for(let i in this.item.otherLocations){
			if(!headerList.includes(this.item.otherLocations[i].location)){
				headerList.push(this.item.otherLocations[i].location)
				this.moveFromList.push({name: this.item.otherLocations[i].location, items: []})
			}
			let currentIndex = this.moveFromList.findIndex((header)=> header.name === this.item.otherLocations[i].location)
			this.moveFromList[currentIndex].items.push(this.item.otherLocations[i])
		}

	},
	methods:{
		async accept(){
			//Safety checks
			if(this.fromLoc == null || this.fromLoc.location === undefined){
				alert("Must select a from location")
				return;
			}
			if(this.location == null || this.location === ''){
				alert("Must select a To location")
				return;
			}
			if(this.newBin === null || this.newBin === ''){
				alert("Must select a To Bin")
				return;
			}
			if(parseInt(this.numberPool) < 1){
				alert("Amount moved must be positive")
				return;
			}


			this.toLoc = {
				location: this.location.name,
				bin: this.newBin
			}

			//save time if moving from same to same or nothing is moved by not doing anything.
			if((this.toLoc.location === this.fromLoc.location && this.toLoc.bin === this.fromLoc.bin) || this.numberPool < 1){
				this.reject()
				return
			}

			let tempQtys = parseInt(this.numberPool)
			let holder = [];

			while(tempQtys > 0){
				//sets data.itemcount to appropate number
				let mathInfoThing = parseInt(this.warrantyFlag ? this.fromLoc.warranty_count[0].qty : this.fromLoc.itemcount[0].qty) - tempQtys

				//if negitive or zero it pops off the itemcount instance to bring next forward and retries with excess qty
				if(mathInfoThing < 0){
					tempQtys = Math.abs(mathInfoThing)
					//data.itemcount[0].qty = Math.abs(data.itemcount[0].qty)
					if(this.warrantyFlag){
						holder.push(this.fromLoc.warranty_count.shift())
					}
					else{
						holder.push(this.fromLoc.itemcount.shift())
					}
				}
				//else there isn't any left to subtract
				else{
					if(this.warrantyFlag){
						holder.push({...this.fromLoc.warranty_count[0], qty: Math.abs(tempQtys) })
					}
					else{
						holder.push({...this.fromLoc.itemcount[0], qty: Math.abs(tempQtys) })
					}
					tempQtys = 0
				}
			}
			//Updates the DB correctly.
			if(this.warrantyFlag){
				await axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(this.fromLoc.location)}/${encodeURIComponent(this.fromLoc.bin)}/${this.$globals.companyId}`,
				{
					qty: this.numberPool,
					vendor: this.fromLoc.vendor
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/use/warranty/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(this.toLoc.location)}/${encodeURIComponent(this.toLoc.bin)}/${this.$globals.companyId}`,
				{
					qty: parseInt(-1 * this.numberPool),
					vendor: this.fromLoc.vendor,
					addBack: holder
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					this.$emit('accept',null)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}
			else{
				await axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(this.fromLoc.location)}/${encodeURIComponent(this.fromLoc.bin)}/${this.$globals.companyId}`,
				{
					qty: this.numberPool,
					vendor: this.fromLoc.vendor
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})

				await axios.patch(`${process.env.VUE_APP_API_TARGET}/inventory/update/target/${encodeURIComponent(this.item.itemid)}/${encodeURIComponent(this.toLoc.location)}/${encodeURIComponent(this.toLoc.bin)}/${this.$globals.companyId}`,
				{
					qty: parseInt(-1 * this.numberPool),
					vendor: this.fromLoc.vendor,
					addBack: holder
				},
				{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }})
				.then(response =>{
					this.$emit('accept',null)
				})
				.catch((response)=>{
					this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
				})
			}

		},

		getDistinctBins(locObj){
			axios.get(`${process.env.VUE_APP_API_TARGET}/inventory/bins/from/${encodeURIComponent(locObj.name)}/${this.$globals.companyId}`,{headers: {'Authorization':'Bearer ' + this.$globals.user.auth0Token }}
			).then((response)=>{
				this.newBin = ""
				this.binList = []
				this.binList = response.data.queryResult
			})
			.catch((response)=>{
				this.$el.dispatchEvent(new CustomEvent('errorOccured', { detail: { 'error' : response, 'component' : 'Inventory Move', 'user' : this.$globals.user, 'time' : new Date().toLocaleString() }, bubbles: true, composed: true }));
			})
		},

        reject(){
            this.$emit('reject',null)
        }

	},
}


</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
	opacity: 1;
	width: 100vw;
	height: 100vh;
  }

  .modal {
    display: flex;
	box-shadow: 2px 2px 20px 1px;
	opacity: 1;
	background-color: white;
	width: 45vw;
    max-height: 40vh;
	left: 33vw;
	top: 15vh;
	display: block;
	position: fixed;
    border-radius: 20px
  }

  .modal-header {
	position: relative;
	border-bottom: 1px solid #eeeeee;
	color: #DC3545;
  }

  .modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
  }

  ::-webkit-scrollbar{
	  width: 0px
  }
</style>
